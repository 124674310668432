import moment from 'moment';
import { Modal, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Icon } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { modalsSelector } from 'data/selectors/modals';
import { SUBSCRIPTION_STATUS } from 'data/types/subscription.types';
import { hasDiscountSelector, subscriptionsSelector } from 'data/selectors/subscriptions';
import { updateSubscriptionStatus } from 'data/actions/subscriptions';
import {
  CloseIcon,
  ModalTitle,
} from '../baseModal/styles';
import {
  Title,
  RadioGroupContainer,
  PeriodRadio,
  Footer,
  Description,
  BenefitsContainer,
  Benefit,
} from './styles';

import { InfoImg } from '../saveFromSearchModal/styles';

export const BasePauseSubscriptionModal = ({
  onClose,
  onSuccess,
}) => {
  const { visible } = useSelector(modalsSelector);
  const subscription = useSelector(subscriptionsSelector);
  const hasDiscount = useSelector(hasDiscountSelector);

  const [period, setPeriod] = useState({ label: '1 month', value: 1 });

  const pauseOptions = [
    { label: '1 month', value: 1 },
    { label: '2 months', value: 2 },
    { label: '3 months', value: 3 },
  ];

  const periodValue = period.value;

  const renewalDate = moment(subscription.subscriptionExpiration)?.add(periodValue, 'month')?.format('MMMM DD, YYYY');
  const startFreePlanDate = moment(subscription.subscriptionExpiration)?.format('DD.MM.YYYY');
  const endFreePlanDate = moment(renewalDate)?.format('DD.MM.YYYY');

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Pause my subscription
          <CloseIcon
            onClick={onClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={420}
      footer={false}
      onCancel={onClose}
    >
      <div>
        <Title>Period</Title>
        <RadioGroupContainer value={period.value} onChange={(e) => setPeriod(e.target)} defaultValue="a">
          {pauseOptions.map((reasonItem) => (
            <PeriodRadio key={reasonItem.value} value={reasonItem.value}>
              {reasonItem.label}
            </PeriodRadio>
          ))}
        </RadioGroupContainer>
        <Description>
          Next renewal on:
          <span>
            {` ${renewalDate} ` }
          </span>
          <Tooltip placement="bottom" title={`Free plan will be active from ${startFreePlanDate} till ${endFreePlanDate}`}>
            <InfoImg />
          </Tooltip>
        </Description>
        <Title>Details</Title>
        <Description>You can pause your subscription not more than once per 3 months.</Description>
        <BenefitsContainer>
          <Benefit>
            <Icon type={hasDiscount ? 'ic-close' : 'ic-check'} fill={hasDiscount ? 'danger' : 'primary'} />
            {
              hasDiscount ? "You don't get discount, because you have discount" : 'Get 30% discount after 1 month'
            }
          </Benefit>
          <Benefit>
            <Icon type="ic-check" fill="primary" />
            We will notify you 1 week before renewal
          </Benefit>
          <Benefit>
            <Icon type="ic-check" fill="primary" />
            Reactivate any time
          </Benefit>
          <Benefit>
            <Icon type="ic-check" fill="primary" />
            Use free plan during the pause
          </Benefit>
        </BenefitsContainer>
        <Footer>
          <Button
            data-form="true"
            type="primary"
            disabled={!periodValue}
            onClick={() => onSuccess({ period: periodValue })}
          >
            Pause my subscription
          </Button>
        </Footer>
      </div>
    </Modal>
  );
};

const PauseSubscriptionModal = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(toggleModal('pause_subscription', false));
  };

  const onSuccess = () => {
    dispatch(toggleMessage('success', { header: 'Your subscription has been paused successfully' }));
    handleClose();
  };

  const handlePauseSubscription = ({ period }) => {
    dispatch(updateSubscriptionStatus({ status: SUBSCRIPTION_STATUS.PAUSE, reason: '', period }, onSuccess));
  };

  return (
    <BasePauseSubscriptionModal
      onClose={handleClose}
      onSuccess={handlePauseSubscription}
    />
  );
};

export default PauseSubscriptionModal;
