export const discountCount = (price, discount, period, isBlackFriday) => {
  let currentPrice = price;

  if (period === 'year') {
    currentPrice *= 12;
    if (isBlackFriday) {
      return Math.round(currentPrice / 2);
    }
  }
  return Math.round((currentPrice * discount) / 100);
};

export const getSavedDates = (discountAmount, price) => (discountAmount / price).toFixed(1).replace('.', ',');

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};
