export default () => {
  /*  Structure of listeners
  *   id - unique field
  *   callback - function which get an action
  *   actions - array of string of actions or string *(means all)
  */
  let listeners = [];
  if (process.env.NODE_ENV === 'development') {
    window.__SUBSCRIBE_LISTENERS__ = listeners;
  }

  return (store) => (next) => (action) => {
    if (!action) return;
    if (action.type === 'SUBSCRIBE_ACTIONS') {
      listeners.push(action.data);
    } else if (action.type === 'UNSUBSCRIBE_ACTIONS') {
      listeners = listeners.filter((listener) => listener.id !== action.data.id);
      if (process.env.NODE_ENV === 'development') {
        window.__SUBSCRIBE_LISTENERS__ = listeners;
      }
    } else if (listeners.length > 0) {
      listeners.forEach((listener) => {
        if (listener.actions.includes(action.type) || listener.actions === '*') {
          listener.callback(action, store);
        }
      });
    }
    next(action);
  };
};
