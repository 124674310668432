import React from 'react';

import { CustomPassword as Password } from 'components/commonStyles';
import CustomInput from '../customInput';

const CustomPassword = ({ ...props }) => (
  <CustomInput InputField={Password} {...props} /> // eslint-disable-line
);

export default CustomPassword;
