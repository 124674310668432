import styled from 'styled-components';

export const Container = styled.div`
   .ant-tree-switcher-noop {
    display: none;
  }

  .ant-tree-switcher {
    display: none !important;
  }
`;
