import { DatePicker } from 'antd';
import styled from 'styled-components';

import * as CONSTANTS from 'constants/styles';

export const ContactPropertyContainer = styled.div`
  margin-top: 15px;

  &:first-child {
    margin-top: 5px;
  }
`;

export const PropertyLabel = styled.span`
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
  color: #000;
`;

export const Footer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #f5f8fa;
  border-top: 1px solid #cbd6e2;
  padding: 20px;
  height: ${CONSTANTS.SIDEBAR_FOOTER_HEIGHT}px;
`;

export const ButtonStyle = {
  marginRight: 10,
};

export const ExistedContact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: row;
  background-color: rgb(255, 255, 255);
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(223, 227, 235);
  width: 300px;
  padding: 10px;

  img[style*='display: block'] + div {
    display: none;
  }

  &:hover {
    border: 1px solid ${CONSTANTS.COLORS.PRIMARY};
    background-color: ${CONSTANTS.COLORS.BLUE_B2};
    cursor: pointer;
  }
`;

export const ContactName = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeadName = styled.div`
  color: #33475b;
  font-size: 12px;
`;

export const ContactMessage = styled.div`
  padding: 15px 30px;
  text-align: center;
`;

export const BodyStyle = {
  maxHeight: 'calc(100% - 140px)',
  overflow: 'auto',
  flexDirection: 'column',
};

export const DateComponent = styled(DatePicker)`
  width: 100%;

  input {
    height: 40px;
  }
`;
