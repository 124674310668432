import isEmpty from 'lodash/isEmpty';
import { transliterate as tr } from 'transliteration';

import { asianCharsRegExp } from './regularExpressions';

export const getFullName = (contact) => {
  if (!isEmpty(contact)) {
    if (contact.properties) {
      return `${contact.properties.firstName?.value || ''} ${contact.properties.lastName?.value || ''}`;
    }
    if (contact.firstName || contact.lastName) {
      return `${contact.firstName || ''} ${contact.lastName || ''}`;
    }
  }
  return '';
};

export const getClearedFullName = (contact) => {
  const fullName = getFullName(contact);
  let [firstName = '', lastName = ''] = fullName.split(' ');

  const checkFNAsianChars = firstName.search(asianCharsRegExp);
  const checkLNAsianChars = lastName.search(asianCharsRegExp);

  if ([checkFNAsianChars, checkLNAsianChars].includes(-1)) {
    firstName = tr(firstName);
    lastName = tr(lastName);
  }
  const clearFirstName = firstName.trim().replace(/\W+/g, ' ').trim();
  const clearLastName = lastName.trim().replace(/\W+/g, ' ').trim();
  return `${clearFirstName} ${clearLastName}`;
};

export const getAbbreviationOfName = (contact) => {
  const [clearedFirstName, clearedLastName] = getClearedFullName(contact).split(' ');
  return `${clearedFirstName?.[0]?.toUpperCase() ?? ''}${clearedLastName?.[0]?.toUpperCase() ?? ''}`.trim();
};
