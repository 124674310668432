import get from 'lodash/get';

export const getAllIntegrations = (
  {
    pageSize = 30, page = 1, q = '', category = 'all',
  }, components = [],
) => (dispatch) => {
  const params = {
    pageSize,
    pageNumber: page,
    q,
    category,
    includes: ['slug', 'image', 'title', 'images'],
  };
  dispatch({
    type: 'GET_ALL_INTEGRATIONS',
    payload: {
      endpoint: 'api/v1/integrations',
      method: 'GET',
      params,
    },
    components,
  });
};

export const getAllIntegrationsCategories = () => (dispatch) => {
  dispatch({
    type: 'GET_ALL_INTEGRATIONS_CATEGORIES',
    payload: {
      endpoint: 'api/v1/integrations/categories',
      method: 'GET',
    },
  });
};

export const connectGoogleAccount = (code, scope) => (dispatch) => {
  const connectedAppsChannel = new BroadcastChannel('connected_apps_channel');
  dispatch({
    type: 'CONNECT_GOOGLE_ACCOUNT',
    payload: {
      endpoint: 'api/v1/connected-accounts/google',
      method: 'POST',
      body: {
        code,
        scope,
      },
      delayLoad: 0,
    },
    components: ['googleOAuthVerification'],
    onSuccess: (data) => {
      connectedAppsChannel.postMessage({ status: 'success', data });
      window.close();
    },
    onFailed: (err) => {
      const message = (err?.response?.status === 409) ? 'This account already connected' : 'Connect account error. Please, try again';
      connectedAppsChannel.postMessage({ status: 'error', data: message });
      window.close();
    },
  });
};

export const connectCustomAccount = ({
  email,
  password,
  onSuccess,
}) => (dispatch) => {
  dispatch({
    type: 'CONNECT_CUSTOM_ACCOUNT',
    payload: {
      endpoint: 'api/v1/connected-accounts/custom',
      method: 'POST',
      body: {
        email,
        password,
      },
      delayLoad: 0,
      showResponseError: true,
    },
    components: ['customAccountVerification'],
    onSuccess,
  });
};

export const getCountActiveCampaigns = (accountIds) => (dispatch) => {
  dispatch({
    type: 'GET_COUNT_ACTIVE_CAMPAIGNS',
    payload: {
      endpoint: 'api/v1/connected-accounts/count-active-campaigns',
      method: 'POST',
      body: {
        accountIds,
      },
      delayLoad: 0,
    },
    components: ['countActiveCampaignsLoading'],
  });
};

export const getSumSendingLimits = (accountIds) => (dispatch) => {
  dispatch({
    type: 'GET_SUM_SENDING_LIMITS',
    payload: {
      endpoint: 'api/v1/connected-accounts/sending-limits',
      method: 'POST',
      body: {
        accountIds,
      },
      delayLoad: 0,
    },
    components: ['sumSendingLimitsLoading'],
  });
};

export const getConnectedAccounts = () => (dispatch) => {
  dispatch({
    type: 'GET_CONNECTED_ACCOUNTS',
    payload: {
      endpoint: 'api/v1/connected-accounts',
      method: 'GET',
      delayLoad: 0,
    },
    onSuccess: (accounts) => {
      const accountIds = accounts.map((account) => account._id);
      dispatch(getCountActiveCampaigns(accountIds));
      dispatch(getSumSendingLimits(accountIds));
    },
    components: ['connectedAccountsLoading'],
  });
};

export const pushConnectedAccount = (account) => (dispatch) => {
  dispatch({
    type: 'PUSH_CONNECTED_ACCOUNT',
    data: account,
  });
};

export const deleteConnectedAccount = (accountId) => (dispatch) => {
  dispatch({
    type: 'DELETE_CONNECTED_ACCOUNT',
    payload: {
      endpoint: `api/v1/connected-accounts/${accountId}`,
      method: 'DELETE',
      showResponseError: true,
    },
  });
};

export const refreshConnectedAccount = (accountId) => (dispatch) => {
  dispatch({
    type: 'REFRESH_CONNECTED_ACCOUNT',
    payload: {
      endpoint: `api/v1/connected-accounts/${accountId}/refresh`,
      method: 'POST',
      showResponseError: true,
    },
    accountId,
  });
};

export const updateConnectedAccount = ({
  accountId,
  body,
  onSuccess = () => {},
  onFailed = () => {},
}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_CONNECTED_ACCOUNT',
    payload: {
      endpoint: `api/v1/connected-accounts/${accountId}`,
      method: 'PATCH',
      body,
      showResponseError: (err) => {
        if (typeof get(err, 'response.data.message') === 'object') {
          return false;
        }
        return true;
      },
      delayLoad: 0,
    },
    body,
    accountId,
    onSuccess,
    onFailed,
    components: ['updateConnectedAccountLoading'],
  });
};

export const disableConnectedAccount = (accountId, body, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'DISABLE_CONNECTED_ACCOUNT',
    payload: {
      endpoint: `api/v1/connected-accounts/${accountId}/disable`,
      method: 'PATCH',
      body,
      showResponseError: (err) => {
        if (typeof get(err, 'response.data.message') === 'object') {
          return false;
        }
        return true;
      },
    },
    disabled: body.disabled,
    accountId,
    onFailed,
  });
};

export const connectHubspot = (code, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'CONNECT_HUBSPOT',
    payload: {
      endpoint: `/oauth2/authorize-client/hubspot?code=${code}`,
      method: 'GET',
    },
    onSuccess,
    onFailed,
  });
};

export const getHubspotConnection = (callback = () => {}) => (dispatch) => {
  dispatch({
    type: 'GET_HUBSPOT_CONNECTION',
    payload: {
      endpoint: '/api/v1/hubspot/connection',
      method: 'GET',
      delayLoad: 0,
    },
    components: ['hubspotConnectionLoading'],
    onSuccess: callback,
    onFailed: callback,
  });
};

export const updateHubspotConnection = (data, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_HUBSPOT_CONNECTION',
    payload: {
      endpoint: '/api/v1/hubspot/connection',
      method: 'PATCH',
      delayLoad: 0,
      body: {
        ...data,
      },
    },
    components: ['updateHubspotConnectionLoading'],
    onSuccess,
    onFailed,
  });
};

export const updateIntegrationStatus = (integration, status) => (dispatch) => {
  dispatch({
    type: 'UPDATE_INTEGRATION_STATUS',
    payload: {
      endpoint: `/api/v1/${integration}/status/${status}`,
      method: 'PATCH',
      delayLoad: 0,
    },
    components: [
      'updateHubspotConnectionLoading',
      'updateSalesforceConnectionLoading',
      'updateZohoConnectionLoading',
      'updatePipedriveConnectionLoading',
      'updateSheetsConnectionLoading',
    ],
    integration: {
      name: integration,
    },
  });
};

export const unlinkIntegration = (integration, onSuccess = () => {}) => (dispatch) => {
  dispatch({
    type: 'UNLINK_INTEGRATION',
    payload: {
      endpoint: `/api/v1/${integration}/unlink`,
      method: 'DELETE',
      delayLoad: 0,
    },
    integration: {
      name: integration,
    },
    onSuccess,
  });
};

export const syncHubspotUser = () => (dispatch) => {
  dispatch({
    type: 'SYNC_HUBSPOT_USER',
    payload: {
      endpoint: '/api/v1/hubspot/user/sync',
      method: 'PATCH',
      delayLoad: 0,
    },
    components: ['syncHubspotUserLoading'],
  });
};

export const syncSalesforceUser = () => (dispatch) => {
  dispatch({
    type: 'SYNC_SALESFORCE_USER',
    payload: {
      endpoint: '/api/v1/salesforce/user/sync',
      method: 'PATCH',
      delayLoad: 0,
    },
    components: ['syncSalesforceUserLoading'],
  });
};

export const connectSalesforce = (code, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'CONNECT_SALESFORCE',
    payload: {
      endpoint: `/oauth2/authorize-client/salesforce?code=${code}`,
      method: 'GET',
    },
    onSuccess,
    onFailed,
  });
};

export const getSalesforceConnection = (callback = () => {}) => (dispatch) => {
  dispatch({
    type: 'GET_SALESFORCE_CONNECTION',
    payload: {
      endpoint: '/api/v1/salesforce/connection',
      method: 'GET',
      delayLoad: 0,
    },
    components: ['salesforceConnectionLoading'],
    onSuccess: callback,
    onFailed: callback,
  });
};

export const updateSalesforceConnection = (data, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SALESFORCE_CONNECTION',
    payload: {
      endpoint: '/api/v1/salesforce/connection',
      method: 'PATCH',
      delayLoad: 0,
      body: {
        ...data,
      },
    },
    components: ['updateSalesforceConnectionLoading'],
    onSuccess,
    onFailed,
  });
};

export const syncIntegration = (name) => (dispatch) => {
  dispatch({
    type: 'SYNC_INTEGRATION',
    payload: {
      endpoint: `/api/v1/${name}/sync`,
      method: 'POST',
      delayLoad: 0,
    },
    integration: {
      name,
    },
    components: ['integrationTaskLoading'],
  });
};

export const getIntegrationCurrentTask = (name) => (dispatch) => {
  dispatch({
    type: 'GET_INTEGRATION_TASK',
    payload: {
      endpoint: `/api/v1/${name}/task`,
      method: 'GET',
      delayLoad: 0,
    },
    integration: {
      name,
    },
    components: ['integrationTaskLoading'],
  });
};

export const getZohoConnection = (callback = () => {}) => (dispatch) => {
  dispatch({
    type: 'GET_ZOHO_CONNECTION',
    payload: {
      endpoint: '/api/v1/zoho/connection',
      method: 'GET',
      delayLoad: 0,
    },
    components: ['zohoConnectionLoading'],
    onSuccess: callback,
    onFailed: callback,
  });
};

export const updateZohoConnection = (data, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ZOHO_CONNECTION',
    payload: {
      endpoint: '/api/v1/zoho/connection',
      method: 'PATCH',
      delayLoad: 0,
      body: {
        ...data,
      },
    },
    components: ['updateZohoConnectionLoading'],
    onSuccess,
    onFailed,
  });
};

export const syncZohoUser = () => (dispatch) => {
  dispatch({
    type: 'SYNC_ZOHO_USER',
    payload: {
      endpoint: '/api/v1/zoho/user/sync',
      method: 'PATCH',
      delayLoad: 0,
    },
    components: ['syncZohoUserLoading'],
  });
};

export const connectZoho = ({ code, location }, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'CONNECT_ZOHO',
    payload: {
      endpoint: `/oauth2/authorize-client/zoho?code=${code}&location=${location}`,
      method: 'GET',
    },
    onSuccess,
    onFailed,
  });
};

export const getPipedriveConnection = (callback = () => {}) => (dispatch) => {
  dispatch({
    type: 'GET_PIPEDRIVE_CONNECTION',
    payload: {
      endpoint: '/api/v1/pipedrive/connection',
      method: 'GET',
      delayLoad: 0,
    },
    components: ['pipedriveConnectionLoading'],
    onSuccess: callback,
    onFailed: callback,
  });
};

export const updatePipedriveConnection = (data, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_PIPEDRIVE_CONNECTION',
    payload: {
      endpoint: '/api/v1/pipedrive/connection',
      method: 'PATCH',
      delayLoad: 0,
      body: {
        ...data,
      },
    },
    components: ['updatePipedriveConnectionLoading'],
    onSuccess,
    onFailed,
  });
};

export const syncPipedriveUser = () => (dispatch) => {
  dispatch({
    type: 'SYNC_PIPEDRIVE_USER',
    payload: {
      endpoint: '/api/v1/pipedrive/user/sync',
      method: 'PATCH',
      delayLoad: 0,
    },
    components: ['syncPipedriveUserLoading'],
  });
};

export const connectPipedrive = (code, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'CONNECT_PIPEDRIVE',
    payload: {
      endpoint: `/oauth2/authorize-client/pipedrive?code=${code}`,
      method: 'GET',
    },
    onSuccess,
    onFailed,
  });
};

export const getGoogleSheetsConnection = (callback = () => {}) => (dispatch) => {
  dispatch({
    type: 'GET_SHEETS_CONNECTION',
    payload: {
      endpoint: '/api/v1/sheets/connection',
      method: 'GET',
      delayLoad: 0,
    },
    components: ['sheetsConnectionLoading'],
    onSuccess: callback,
    onFailed: callback,
  });
};

export const connectGoogleSheets = (code, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'CONNECT_SHEETS',
    payload: {
      endpoint: `/oauth2/authorize-client/sheets?code=${code}`,
      method: 'GET',
    },
    onSuccess,
    onFailed,
  });
};

export const syncGoogleSheetsData = () => (dispatch) => {
  dispatch({
    type: 'SYNC_SHEETS_DATA',
    payload: {
      endpoint: '/api/v1/sheets/data/sync',
      method: 'PATCH',
      delayLoad: 0,
    },
    components: ['syncSheetsDataLoading'],
  });
};

export const updateGoogleSheetsConnection = (data, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SHEETS_CONNECTION',
    payload: {
      endpoint: '/api/v1/sheets/connection',
      method: 'PATCH',
      delayLoad: 0,
      body: {
        ...data,
      },
    },
    components: ['updateSheetsConnectionLoading'],
    onSuccess,
    onFailed,
  });
};

export const searchSpreadsheets = ({
  query,
  limit = 10,
  onSuccess = () => {},
  onFailed = () => {},
}) => (dispatch) => {
  dispatch({
    type: 'SEARCH_SHEETS',
    payload: {
      endpoint: '/api/v1/sheets/search',
      method: 'GET',
      delayLoad: 0,
      params: {
        q: query,
        limit,
      },
    },
    components: ['searchSpreadsheetsLoading'],
    onSuccess,
    onFailed,
  });
};

export const getGoogleSheetsToken = (onSuccess = () => {}) => (dispatch) => {
  dispatch({
    type: 'GET_SHEETS_TOKEN',
    payload: {
      endpoint: '/api/v1/sheets/token',
      method: 'GET',
      delayLoad: 0,
    },
    onSuccess,
  });
};
