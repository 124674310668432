import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
} from 'antd';
import get from 'lodash/get';

import { Input, Button } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { SOURCE_TYPE } from 'data/types/source.types';
import getFilterActions from 'helpers/getFilterActionsBySourceType';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import {
  CloseIcon,
  ModalTitle,
  Label,
  Warning,
  InputStyle,
  Footer,
} from '../baseModal/styles';

const CloneFilterModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const history = useHistory();

  const { filterId, sourceType = SOURCE_TYPE.CONTACT } = useSelector(modalOptionsSelector);
  const {
    filterSelector,
    actionToggleShowAllSavedFilters,
    actionClone,
    titleAll,
  } = getFilterActions(sourceType);
  const savedFilters = useSelector(filterSelector);

  const currentFilterName = get(savedFilters.find((item) => item._id === filterId), 'name', titleAll);
  const currentFilters = get(savedFilters.find((item) => item._id === filterId), 'filters', []);
  const [name, updateName] = useState('');
  const isExist = () => {
    if (savedFilters.findIndex((item) => item.name === name) > -1 || name === 'All contacts'
    || name === 'All companies') return true;
    return false;
  };

  useEffect(() => updateName(`${currentFilterName} clone`), [currentFilterName]);

  const navigate = (id) => {
    history.push(`/${sourceType === SOURCE_TYPE.CONTACT ? 'contacts' : 'companies'}/filter/${id}?existed=true`);
  };

  const onSuccessCreateList = (data) => {
    const id = get(data, '_id');
    navigate(id);
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Clone view
          <CloseIcon
            onClick={() => dispatch(toggleModal('clone_filter', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={() => dispatch(toggleModal('clone_filter', false))}
    >
      <>
        <Label>Name*</Label>
        <Input
          autoFocus
          onChange={(e) => {
            updateName(e.target.value);
          }}
          value={name}
          placeholder="Type the view name..."
          style={InputStyle}
        />

        {isExist() && (
          <Warning>
            {`${name} already exists. Please choose a new name.`}
          </Warning>
        )}
      </>
      <Footer>
        <Button
          data-form={!name || isExist() ? 'false' : 'true'}
          type="primary"
          onClick={() => {
            const newFilters = currentFilters;
            dispatch(toggleModal('clone_filter', false));
            dispatch(actionToggleShowAllSavedFilters(false));
            dispatch(actionClone(name, newFilters, onSuccessCreateList));
          }}
          disabled={!name || isExist()}
        >
          Save
        </Button>
        <Button type="normal" onClick={() => dispatch(toggleModal('clone_filter', false))}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default CloneFilterModal;
