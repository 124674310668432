import React from 'react';
import icon from 'assets/images/no-tasks.svg';

export default () => (
  <img
    src={icon}
    alt="NoTasks icon"
    style={{
      display: 'block',
      margin: '15px auto',
      width: '167px',
      height: '108px',
    }}
  />
);
