import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import {
  Drawer,
  Tabs,
} from 'antd';

import { Button, Icon } from 'components/common';
import getDomainFromEmail from 'helpers/getDomainFromEmail';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { companyPropertiesSelector } from 'data/selectors/companies';
import { contactSelector } from 'data/selectors/contacts';
import { addCompany, findSuggestedCompanies, searchCompany } from 'data/actions/companies';
import { addCompaniesToContact } from 'data/actions/contacts';
import Loader from 'components/loading';
import * as CONSTANTS from 'constants/styles';
import AddExistingCompany from './tabs/addExistingCompany';
import CreateNewCompany from './tabs/createCompanyBar';
import {
  Footer,
  DrawerWrapper,
  Text,
  WhiteButtonStyle,
  BlueButtonStyle,
  BodyStyle,
} from './styles';

import {
  ModalTitle,
  CloseIcon,
  HeaderStyle,
} from '../modals/baseModal/styles';
import { searchSimilarCompany } from '../../helpers/companyHelpers';

const { TabPane } = Tabs;

const AddCompaniesToContact = () => {
  const dispatch = useDispatch();

  const { contactId, openContactPreview = true, hasBackArrow = true } = useSelector(modalOptionsSelector);
  const properties = get(useSelector(companyPropertiesSelector), 'data', []);
  const currentContact = useSelector(contactSelector);
  const { visible } = useSelector(modalsSelector);

  const [tab, setTab] = useState('addExisting');
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [company, updateCompany] = useState({});
  const [animationDone, setAnimationDone] = useState(false);
  const [similarCompany, setExistedCompany] = useState(null);

  const isValid = properties
    .filter((item) => item.required)
    .every((item) => company[item.name] && company[item.name].length > 0);

  const flushForm = () => {
    setInputValue('');
    updateCompany({});
    setSelectedCompanies([]);
  };

  const searchSimilarCompanyDebounce = useCallback(debounce((name, domain) => {
    searchSimilarCompany({ name, domain }, (filters) => dispatch(searchCompany({
      filters,
      components: ['addCompaniesToContact'],
      onSuccess: (data) => {
        if (data?.data?.length) setExistedCompany(data.data[0]);
        else setExistedCompany(null);
      },
    })));
  }, 500), [dispatch, setExistedCompany]);

  useEffect(() => {
    if (visible && tab === 'addExisting') {
      const query = inputValue.length ? inputValue : getDomainFromEmail(get(currentContact, 'properties.email.value', ''));

      dispatch(findSuggestedCompanies({ query }));
    }
  }, [dispatch, visible, currentContact, inputValue, tab]);

  useEffect(() => {
    if (tab === 'createNewCompany' && (company.domain || company.name)) {
      searchSimilarCompanyDebounce(company.name, company.domain);
    }
  }, [dispatch, company.domain, company.name, tab, searchSimilarCompanyDebounce]);

  const handleClose = () => {
    dispatch(toggleModal('add_company_to_contact_preview', false));
  };

  const handleErrorCreateCompany = () => setIsLoading(false);

  const openContactPreviewOrClose = () => {
    if (openContactPreview) {
      dispatch(toggleModal('contact_preview', true, { contactId }));
    } else {
      handleClose();
    }
  };

  const handleCreateCompany = () => {
    setIsLoading(true);
    const companyProperties = Object.keys(company)
      .map((key) => ({ property: key, value: company[key] }));
    dispatch(addCompany({
      properties: companyProperties,
      onSuccess: (data) => {
        dispatch(addCompaniesToContact(contactId, [{ companyId: data.data._id }], () => {
          flushForm();
          openContactPreviewOrClose();
        }));
      },
      onFailed: handleErrorCreateCompany,
    }));
  };

  const handleSaveCompany = () => {
    setIsLoading(true);
    dispatch(addCompaniesToContact(contactId, (selectedCompanies ?? []).map((companyId) => ({ companyId })), () => {
      flushForm();
      openContactPreviewOrClose();
    }, handleErrorCreateCompany));
  };

  return (
    <DrawerWrapper>
      <Drawer
        title={(
          <ModalTitle>
            {
              hasBackArrow && (
                <Icon
                  style={{ marginRight: '10px', cursor: 'pointer' }}
                  type="ic-arrow-back"
                  fill="white"
                  onClick={() => {
                    dispatch(toggleModal('contact_preview', true, { contactId }));
                  }}
                  className="ic_arrow_back"
                />
              )
            }
            Save
            <CloseIcon
              data-close="true"
              onClick={handleClose}
              className="ic_close"
            />
          </ModalTitle>
        )}
        bodyStyle={BodyStyle}
        closable={false}
        placement="right"
        headerStyle={HeaderStyle}
        visible={visible}
        onClose={handleClose}
        width={CONSTANTS.DRAWER_WIDTH}
        afterVisibleChange={(currentVisible) => {
          setAnimationDone(currentVisible);
        }}
      >
        <div>
          <Text>
            Keep track of which companies are related to this contact.
          </Text>
          <Tabs onChange={setTab} type="card">
            <TabPane tab="Add existing company" key="addExisting">
              <AddExistingCompany
                selectedCompanies={selectedCompanies}
                onChange={setSelectedCompanies}
                inputValue={inputValue}
                setInputValue={setInputValue}
                animationDone={animationDone}
              />
            </TabPane>
            <TabPane tab="Create a new company" key="createNewCompany">
              <CreateNewCompany
                company={company}
                updateCompany={updateCompany}
                properties={properties}
                existedCompany={similarCompany}
              />
            </TabPane>
          </Tabs>
        </div>
        { tab === 'createNewCompany' && (
        <Footer>
          <Button
            data-form={!isValid || similarCompany || isLoading ? 'false' : 'true'}
            type="primary"
            size="large"
            style={{ ...BlueButtonStyle, minWidth: '138px' }}
            disabled={!isValid || similarCompany || isLoading}
            onClick={handleCreateCompany}
          >
            {isLoading ? <Loader loading={isLoading} /> : 'Create company'}
          </Button>
          <Button
            type="normal"
            size="large"
            style={WhiteButtonStyle}
            onClick={handleClose}
          >
            Cancel
          </Button>

        </Footer>
        )}
        {
        tab === 'addExisting' && (
          <Footer>
            <Button
              data-form={!selectedCompanies.length || isLoading ? 'false' : 'true'}
              type="primary"
              size="large"
              style={{ ...BlueButtonStyle, minWidth: '64px' }}
              disabled={!selectedCompanies.length || isLoading}
              onClick={handleSaveCompany}
            >
              {isLoading ? <Loader loading={isLoading} /> : 'Save'}
            </Button>
            <Button
              type="normal"
              size="large"
              style={WhiteButtonStyle}
              onClick={() => {
                openContactPreviewOrClose();
              }}
            >
              Cancel
            </Button>
          </Footer>
        )
      }
      </Drawer>
    </DrawerWrapper>
  );
};

export default AddCompaniesToContact;
