import { Icon } from 'antd';
import styled from 'styled-components';
import * as CONSTANTS from 'constants/styles';

export const ContactPropertyContainer = styled.div`
  margin-top: 15px;
`;

export const PropertyLabel = styled.span`
  margin-bottom: 5px;
  display: block;
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4f5964;
`;

export const BlueButtonStyle = {
  marginRight: '10px',
};

export const WhiteButtonStyle = {
  minWidth: '90px',
};

export const SearchPropertiesForm = {
  width: 330,
};

export const DrawerWrapper = styled.div`
  div > .ant-drawer-title {
    color: #ffffff !important;
  }
  .ant-drawer-close {
    height: 76px;
  }
`;

export const CustomHeaderStyle = {
  background: '#2565c7',
  margin: '-16px -24px',
  padding: CONSTANTS.DRAWER_PADDING,
  fontSize: '20px',
  fontWeight: '600',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.5',
  letterSpacing: 'normal',
  color: '#ffffff',
  height: '80px',
};

export const Text = styled.div`
  padding-bottom: 25px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.65);
`;

export const Footer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #f5f8fa;
  border-top: 1px solid #cbd6e2;
  padding: 20px;
  button:disabled {
    background-color: #f7f8fa!important;
    color: #bec4ca!important;
  }

  .ant-btn-primary {
    box-shadow: none !important;
    text-shadow: none !important;
  }  
`;

export const ButtonStyle = {
  marginRight: 10,
};

export const CheckboxWrapper = styled.div`
  .ant-checkbox-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 320px;
    min-width: 24px;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-checkbox-group {
    position: relative;
  }

  .ant-checkbox-group .ant-checkbox-group-item .ant-checkbox {
    margin: 5px 15px;
  }

  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #bed1ee;
  position: fixed;
  width: ${CONSTANTS.DRAWER_WIDTH - CONSTANTS.DRAWER_PADDING * 2}px;
`;

export const Label = styled.div`
  font-family: AvenirNext;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #4f5964;
  padding: 15px 15px 0 15px;
`;

export const CheckboxGroupStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 15px',
};

export const InputWrapper = styled.div`
  input:focus {
    box-shadow: none;
  }
  position: relative;
`;

export const SearchInputStyle = {
  cursor: 'pointer',
};

export const SearchForm = {
  height: 40,
  background: '#f5f8fa',
  marginBottom: '15px',
  fontFamily: 'AvenirNext!important',
  fontSize: '16px!important',
  fontWeight: 'normal!important',
  fontStretch: 'normal!important',
  fontStyle: 'normal!important',
  lineHeight: '1.5!important',
  letterSpacing: 'normal!important',
  color: '#071427!important',
};

export const ModalIcon = styled.img`
`;

export const ExistedContact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContactMessage = styled.div`
  padding: 15px 30px;
  text-align: center;
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: row;
  background-color: rgb(255, 255, 255);
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(223, 227, 235);
  width: 300px;
  padding: 10px;

  img[style*='display: block'] + div {
    display: none;
  }

  ${(props) => props.enabled && `
    &:hover {
      border: 1px solid ${CONSTANTS.COLORS.PRIMARY};
      background-color: ${CONSTANTS.COLORS.BLUE_B2};
      cursor: pointer;
    }
  `}
`;

export const CompanyName = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyLabel = styled.div`
  color: #33475b;
  font-size: 12px;
`;

export const Photo = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #7c98b6;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  margin-right: 10px;
`;

export const BodyStyle = {
  maxHeight: 'calc(100% - 200px)',
  overflow: 'auto',
  flexDirection: 'column',
};

export const InfoIcon = styled(Icon)`
  margin-left: 10px;
  margin-top: ${(props) => (props.long ? '2px' : 'unset')};
  position: ${(props) => (props.long ? 'absolute' : 'unset')};
  right: 15px;
`;

export const InfoIconStyleDetails = { position: 'relative', left: 1, top: 1 };

export const NotRequiredPropertyContainer = styled.div`
  position: relative;
`;
export const BlockScreen = styled.div`
  animation: ${(props) => (props.visible ? 'fadeIn ease .5s' : 'fadeOut ease .5s')};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
      background-color: ${CONSTANTS.COLORS.WHITE_W1_OPACITY1};
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  ${(props) => props.visible && `
    z-index: 1;
    cursor: not-allowed;
    background-color: ${CONSTANTS.COLORS.WHITE_W1_OPACITY1};
    
    :before {
      content: 'Start entering a domain name, an account, or both.';
      display: block;
      color: ${CONSTANTS.COLORS.PRIMARY};
      text-align: center;
      width: 200px;
      position: absolute;
      top: 25px;
      left: calc(50% - 100px);
      line-height: 25px;
      font-weight: 600;
    }
  `}
  ${(props) => (props.visible && props.existedCompany ? `
    :before {
      content: '';
    }
  ` : '')}
`;

export const CompanyContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  
  & > span {
    max-width: 265px;
  }
`;
