import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../baseModal/styles';

const SelectAllSequenceLeadsModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { selectAll } = useSelector(modalOptionsSelector);

  const handleClose = () => dispatch(toggleModal('select_all_sequence_leads', false));

  const handleSelectAll = () => {
    selectAll();
    handleClose();
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Warning
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        All sequence leads have been selected.
      </div>
      <div>Are you sure, you want to make an operation on your entire collection?</div>
      <Footer>
        <Button
          data-form="true"
          type="danger"
          size="large"
          onClick={handleSelectAll}
        >
          Yes, I&#39;m sure
        </Button>
        <Button
          size="large"
          type="default"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Footer>
    </Modal>
  );
};

export default SelectAllSequenceLeadsModal;
