import React from 'react';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import icSearchBigPath from 'assets/images/ic-search-big.svg';
import { modalsSelector } from 'data/selectors/modals';
import {
  ButtonsContainer,
  ImageSearch,
  ImageClose,
  Title,
  Description,
} from './styles';

const NotSupportPhoneNumbersModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { visible } = useSelector(modalsSelector);

  const handleClose = () => dispatch(toggleModal('not_support_phone_numbers', false));

  const handleMoreCredits = () => {
    handleClose();
    history.push('/settings/subscriptions');
  };

  return (
    <Modal
      visible={visible}
      title={null}
      closable={false}
      width={345}
      footer={false}
      onCancel={handleClose}
    >
      <ImageClose
        onClick={handleClose}
        className="ic_close"
      />
      <ImageSearch src={icSearchBigPath} alt="icon search big" />
      <Title>
        Oops... your
        <span> Free plan </span>
        doesn’t support phone numbers
      </Title>
      <Description>
        Premium users are 219% more likely to connect with prospects via the phone when they call the direct line.
      </Description>
      <ButtonsContainer>
        <Button
          data-form="true"
          type="primary"
          size="large"
          onClick={handleMoreCredits}
        >
          Buy more credits
        </Button>
        <Button
          size="large"
          type="normal"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </ButtonsContainer>
    </Modal>
  );
};

export default NotSupportPhoneNumbersModal;
