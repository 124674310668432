import get from 'lodash/get';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// import { toggleMessage } from 'data/actions/message';
import { updateSubscription, setRedirectAfterFirstPay } from 'data/actions/subscriptions';
// import useSubscriptionMessage from 'hooks/messageTemplates/useSubscriptionMessage';
import { toggleModal } from 'data/actions/modals';
// import { PAYMENT_DEFAULT_MESSAGE_OPTION } from 'constants/styles';
import { bulkInvoice, setBulkInProgress } from 'data/actions/verifications';
import useBulkMessage from 'hooks/messageTemplates/useBulkMessage';
import { sendAddToCartAnalytic } from 'helpers/googleAnalytics';
import { toggleMessage } from 'data/actions/message';

const usePayHelper = ({
  setIsProcessing,
  plan,
  checkoutCycle,
  quantity,
  bulkId,
  selectedCard,
  bulk,
  isBulkVerification,
  phoneSearchPrice,
  phoneSearchQuantity,
  phoneNewCredits,
}) => {
  const dispatch = useDispatch();
  // const showSubscriptionMessage = useSubscriptionMessage();
  const showBulkMessage = useBulkMessage();

  const handlePaySubscription = useCallback(() => {
    setIsProcessing(true);
    sendAddToCartAnalytic(plan, checkoutCycle, quantity);
    const newSubscriptionData = {
      plan: get(plan, '_id'),
      billingCycle: get(checkoutCycle, '_id'),
      subscriptionQuantity: quantity,
      phoneSearchPrice,
      phoneSearchQuantity,
    };
    dispatch(updateSubscription(newSubscriptionData, () => {
      // TODO: remove ?
      // showSubscriptionMessage({ subscription: newSubscriptionData });
      dispatch(setRedirectAfterFirstPay(true));
      dispatch(toggleModal('billing', false));
      setIsProcessing(false);
      if (phoneNewCredits > 5) {
        dispatch(toggleMessage('success', { header: `Congratulations! ${phoneNewCredits} phone number credits have successfully been added.` }));
      }
    }, () => {
      // TODO: remove ?
      // dispatch(toggleMessage('error', { ...PAYMENT_DEFAULT_MESSAGE_OPTION, text: `${get(error, 'response.data.details', get(error, 'response.data.message.header'))}` }));
      dispatch(toggleModal('billing', false));
      setIsProcessing(false);
    }));
  }, [dispatch, plan, phoneNewCredits, quantity, checkoutCycle, setIsProcessing, phoneSearchPrice, phoneSearchQuantity]);

  const handlePayBulkVerification = useCallback(() => {
    setIsProcessing(true);
    dispatch(setBulkInProgress(bulkId, true));

    const bulkVerificationData = {
      bulkId,
      card: get(selectedCard, '_id'),
    };

    dispatch(bulkInvoice(bulkVerificationData, (data) => {
      showBulkMessage({ fileName: bulk?.fileName, ...data });
      dispatch(toggleModal('billing', false));
      setIsProcessing(false);
    }, () => {
      // TODO: remove ?
      // dispatch(toggleMessage('error', { ...PAYMENT_DEFAULT_MESSAGE_OPTION, text: `${get(error, 'response.data.details')}` }));
      dispatch(toggleModal('billing', false));
      setIsProcessing(false);
    }));
  }, [dispatch, bulkId, selectedCard, showBulkMessage, bulk, setIsProcessing]);

  const handlePay = useCallback(() => {
    if (isBulkVerification) handlePayBulkVerification();
    else handlePaySubscription();
  }, [handlePaySubscription, isBulkVerification, handlePayBulkVerification]);

  return { handlePay };
};

export default usePayHelper;
