import get from 'lodash/get';
import { createSelector } from 'reselect';

const baseStoreTriggersSelector = (state) => get(state, 'storeTriggers', {});
export const storeTriggerSelector = createSelector(
  baseStoreTriggersSelector,
  (storeTriggers) => storeTriggers,
);

export const storeTriggersOperationsSelector = createSelector(
  baseStoreTriggersSelector,
  (storeTriggers) => get(storeTriggers, 'operations', []),
);

export const storeTriggersFireSelector = createSelector(
  baseStoreTriggersSelector,
  (storeTriggers) => get(storeTriggers, 'fireTrigger'),
);
