import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  addFolderToParent,
  deleteFolderFromParent,
  getFolderById,
} from 'helpers/folderTree';
import { PageSizes } from 'data/types/tableMeta.types';
import { ACCOUNT_EVENTS } from 'data/types/event.types';

const initialState = {
  currentList: {
    dynamic: true,
    filters: [],
  },
  lists: {
    data: [],
    page: 1,
    pageSize: PageSizes.DEFAULT_LISTS_PAGE_SIZE,
    filters: [],
    sort: {},
    selectAll: false,
    initialRequest: true,
    search: {},
    totalItems: 0,
    totalPages: 1,
  },
  recentLists: [],
  dropdownLists: {
    data: [],
    totalPages: 0,
    page: 1,
    filters: [],
    search: {},
  },
  listsSizes: [],
  folders: {},
  foldersRelation: [],
  selectedLists: [],
  initialRequestFoldersRelation: true,
  initialRequest: true,
  deletedLists: {
    data: [],
    totalItems: 0,
    pageSize: PageSizes.DEFAULT_TRASH_BIN_PAGE_SIZE,
    page: 1,
    search: {},
    filters: [],
    sort: {},
    selectAll: false,
    isChanged: false,
    initialRequest: true,
  },
  dashboardLists: [],
  contactLists: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SEARCH_CONTACT_LISTS': {
      return {
        ...state,
        contactLists: {
          ...action.payload,
          data: action.payload.data.filter((list) => !action.listRelations.includes(list._id)),
        },
      };
    }
    case 'CREATE_LIST': {
      if (get(action, 'payload.isExist', false)) {
        return state;
      }

      const newRecent = [...state.recentLists];
      newRecent.unshift(action.payload);
      if (newRecent.length > 10) newRecent.pop();

      const newLists = {
        data: [{ contactsCount: 0, ...action.payload }, ...state.lists.data],
        totalPages: Math.round(state.lists.totalItems + 1 / state.lists.PageSizes),
        totalItems: state.lists.totalItems + 1,
      };

      return {
        ...state,
        lists: {
          ...state.lists,
          ...newLists,
        },
        recentLists: newRecent,
        dashboardLists: [{ ...action.payload, contactsCount: 0 }, ...state.dashboardLists],
        contactLists: isEmpty(state.contactLists) ? {} : {
          ...state.contactLists,
          data: [action.payload, ...state.contactLists.data],
        },
      };
    }

    case 'GET_CONTACTS_LISTS': {
      const newLists = get(action, 'payload').sort((prev, next) => (prev?.updatedAt > next?.updatedAt ? -1 : 1));
      return {
        ...state,
        lists: {
          data: newLists,
          initialRequest: false,
        },
      };
    }

    case 'GET_LISTS_SIZES': {
      const listData = action.payload;
      return {
        ...state,
        listsSizes: [...listData],
      };
    }

    case 'UPDATE_LISTS_CONDITIONS': {
      const { listsIds, operationType } = action;
      const { page: deletedListsPage, data: deletedLists } = state.deletedLists;
      let newDeletedListsPage;

      if (operationType !== 'delete') {
        if (deletedListsPage > 1) {
          newDeletedListsPage = (!(deletedLists.length - listsIds.length)) ? (deletedListsPage - 1) : deletedListsPage;
        }
        if (!listsIds.length) newDeletedListsPage = (deletedListsPage > 1) ? (deletedListsPage - 1) : 1;
      }

      if (operationType === 'restore') {
        return {
          ...state,
          deletedLists: {
            ...state.deletedLists,
            data: listsIds.length ? state.deletedLists.data.filter((list) => !listsIds.includes(list._id)) : [],
            totalItems: listsIds.length ? (state.deletedLists.totalItems - listsIds.length) : 0,
            page: newDeletedListsPage,
            isChanged: true,
          },
        };
      }

      const newFoldersRelations = listsIds.length ? state.foldersRelation.filter((item) => !listsIds.includes(get(item, 'list._id'))) : [];

      return {
        ...state,
        selectAll: false,
        foldersRelation: newFoldersRelations,
        deletedLists: {
          ...state.deletedLists,
          isChanged: true,
          selectAll: false,
          page: newDeletedListsPage,
        },
      };
    }

    case 'UPDATE_LIST': {
      const newLists = [...state.lists.data];
      const newRecentLists = [...state.recentLists];
      const newFoldersRelation = [...state.foldersRelation];
      const listId = get(action, 'payload._id');
      const listIndex = newLists.findIndex((item) => item._id === listId);
      const folderRelationIndex = newFoldersRelation.findIndex((item) => get(item, 'list._id') === listId);

      if (listIndex > -1) newLists[listIndex] = action.payload;
      if (folderRelationIndex > -1) newFoldersRelation[folderRelationIndex].list = action.payload;

      const recentListIndex = newRecentLists.findIndex((list) => list._id === action.payload._id);
      if (recentListIndex > -1) newRecentLists.splice(recentListIndex, 1, action.payload);

      return {
        ...state,
        currentList: { ...action.payload },
        foldersRelation: newFoldersRelation,
        lists: {
          ...state.lists,
          data: newLists,
        },
        recentLists: newRecentLists,
        dashboardLists: state.dashboardLists.map((list) => {
          if (list._id === listId) {
            return {
              ...list,
              ...action.payload,
            };
          }
          return list;
        }),
      };
    }

    case 'UPDATE_CURRENT_LIST': {
      const { name, value } = action.data;
      return {
        ...state,
        currentList: { ...state.currentList, [name]: value },
      };
    }

    case 'SET_CURRENT_LIST': {
      const { list } = action.data;
      return {
        ...state,
        currentList: list ? { ...state.currentList, ...list }
          : {
            dynamic: true,
            filters: [],
          },
      };
    }

    case 'CLONE_LIST': {
      return {
        ...state,
        lists: {
          ...state.lists,
          data: [...state.lists.data, { contactsCount: 0, ...action.payload }],
        },
        recentLists: [action.payload, ...state.recentLists].slice(0, 10),
      };
    }

    case 'GET_CONTACTS_FOLDERS': {
      return {
        ...state,
        folders: action.payload,
      };
    }

    case 'CREATE_FOLDER': {
      if (action.kind !== 'list') return state;
      const parent = get(action.payload, 'parent', '');
      const isRoot = get(action, 'parent', 'root') === 'root';
      const newFolders = !isRoot
        ? addFolderToParent(parent, state.folders, action.payload)
        : {
          name: 'root',
          children: [...get(state, 'folders.children', []), action.payload],
        };
      return {
        ...state,
        folders: newFolders,
      };
    }

    case 'DELETE_FOLDERS': {
      const { folderId } = action;
      const newFolders = deleteFolderFromParent(folderId, state.folders);
      return {
        ...state,
        folders: folderId === 'all' ? {} : newFolders,
      };
    }

    case 'GET_FOLDERS_RELATION': {
      return {
        ...state,
        initialRequestFoldersRelation: false,
        foldersRelation: action.payload,
      };
    }

    case 'MOVE_LISTS_TO_FOLDER': {
      const { folderId, entityIds, kind } = action;

      if (kind !== 'list') return state;

      if (folderId === 'All lists') {
        const newRelations = state.foldersRelation.filter((item) => !entityIds.includes(get(item, 'list._id')));
        return {
          ...state,
          foldersRelation: newRelations,
        };
      }

      const payload = get(action, 'payload', []);
      const newFoldersRelation = [...state.foldersRelation];

      payload.forEach((element) => {
        const index = newFoldersRelation.findIndex((item) => get(item, 'list._id') === get(element, 'list._id'));
        if (index > -1) newFoldersRelation[index].folder = get(element, 'folder');
        else newFoldersRelation.push(element);
      });

      return {
        ...state,
        foldersRelation: newFoldersRelation,
      };
    }

    case 'MOVE_FOLDER': {
      const folderId = get(action, 'payload._id', '');
      const parent = get(action, 'payload.parent', '');
      const tree = getFolderById(folderId, state.folders);
      tree.parent = parent;
      const newDeletedFolders = deleteFolderFromParent(folderId, state.folders);
      const newFolders = addFolderToParent(parent, newDeletedFolders, tree);
      return {
        ...state,
        folders: newFolders,
      };
    }

    case 'MOVE_LISTS_WITH_FOLDERS_TO_FOLDER': {
      if (action.kind !== 'list') return state;
      const [folders = [], listRelations = []] = action.payload;
      const { root, entityIds } = action.requestData;
      let newFolders = state.folders;
      let newFoldersRelation = [...state.foldersRelation];

      if (folders.length) {
        newFolders = folders.reduce((prev, current) => {
          const folderId = get(current, '_id', '');
          const parent = get(current, 'parent', '');
          const tree = getFolderById(folderId, prev);
          tree.parent = parent;
          const newDeletedFolders = deleteFolderFromParent(folderId, prev);
          return addFolderToParent(parent, newDeletedFolders, tree);
        }, state.folders);
      }

      if (root) {
        newFoldersRelation = state.foldersRelation.filter((item) => !entityIds.includes(get(item, 'list._id')));

        return {
          ...state,
          folders: newFolders,
          foldersRelation: newFoldersRelation,
        };
      }

      if (listRelations.length) {
        listRelations.forEach((element) => {
          const index = newFoldersRelation.findIndex((item) => get(item, 'list._id') === get(element, 'list._id'));
          if (index > -1) newFoldersRelation[index].folder = get(element, 'folder');
          else newFoldersRelation.push(element);
        });
      }

      return {
        ...state,
        folders: newFolders,
        foldersRelation: newFoldersRelation,
      };
    }

    case 'GET_CONTACTS_LIST': {
      const lists = get(state, 'lists.data', []);
      const { listId } = action.data;

      return {
        ...state,
        selectedList: lists.find((item) => item._id === listId),
      };
    }

    case 'SET_CONTACTS_LIST_INITIAL_REQUEST': {
      return {
        ...state,
        lists: {
          ...state.lists,
          initialRequest: action.data.initialRequest,
        },
      };
    }

    case 'ADD_LIST_LOCAL': {
      return {
        ...state,
        lists: {
          ...state.lists,
          data: [...state.lists.data, { ...action.data }],
        },
      };
    }

    case 'SET_SELECTED_LISTS': {
      return {
        ...state,
        selectedLists: action.data,
      };
    }

    case 'INCREMENT_LIST': {
      return {
        ...state,
        lists: {
          ...state.lists,
          data: state.lists.data.map((list) => {
            if (list._id === action.data.listId) {
              return {
                ...list,
                contactsCount: get(list, 'contactsCount', 0) + 1,
              };
            }
            return list;
          }),
        },
      };
    }

    case 'DECREMENT_LIST': {
      return {
        ...state,
        lists: {
          ...state.lists,
          data: state.lists.data.map((list) => {
            if (list._id === action.data.listId) {
              const contactsCount = get(list, 'contactsCount', 0);
              return {
                ...list,
                contactsCount: contactsCount < 1 ? 0 : contactsCount - action.data.count,
              };
            }
            return list;
          }),
        },
        recentLists: state.recentLists.map((list) => {
          if (list._id === action.data.listId) {
            const contactsCount = get(list, 'contactsCount', 0);
            return {
              ...list,
              contactsCount: contactsCount < 1 ? 0 : contactsCount - action.data.count,
            };
          }
          return list;
        }),
      };
    }

    case 'SET_DELETED_LISTS_PARAMS': {
      return {
        ...state,
        deletedLists: {
          ...state.deletedLists,
          ...action.data,
        },
      };
    }

    case 'SET_LISTS_PARAMS': {
      return {
        ...state,
        lists: { ...state.lists, ...action.data },
      };
    }

    case 'SEARCH_DASHBOARD_LISTS': {
      const {
        payload: { data },
        searchListType,
      } = action;

      if (searchListType === 'sizes') {
        const newLists = state.dashboardLists.map((list) => {
          const [newListData] = data.filter((listSize) => listSize._id === list._id);
          return Object.assign(list, newListData);
        });
        return {
          ...state,
          dashboardLists: newLists,
        };
      }

      return {
        ...state,
        dashboardLists: data,
      };
    }

    case 'SEARCH_LISTS': {
      const {
        deleted,
        payload: { data, meta },
        searchListType,
        searchValue = '',
      } = action;

      if (searchListType === 'sizes') {
        if (deleted) {
          const newLists = state.deletedLists.data.map((list) => {
            const [newListData] = data.filter((listSize) => listSize._id === list._id);
            return Object.assign(list, newListData);
          });
          return {
            ...state,
            deletedLists: {
              ...state.deletedLists,
              data: newLists,
            },
          };
        }
        const newLists = state.lists.data.map((list) => {
          const [newListData] = data.filter((listSize) => listSize._id === list._id);
          return Object.assign(list, newListData);
        });
        return {
          ...state,
          lists: {
            ...state.lists,
            data: newLists,
          },
        };
      }

      if (searchListType === 'scroll') {
        let newLists;
        if (get(meta, 'page', 0) < 2) {
          newLists = [...state.recentLists, ...data];
        } else newLists = [...state.dropdownLists.data, ...data];

        return {
          ...state,
          dropdownLists: {
            ...state.dropdownLists,
            data: searchValue.length ? data : newLists,
            totalPages: get(meta, 'totalPages', 0),
          },
        };
      }

      if (searchListType === 'recent') {
        if (!get(state, 'lists.data.length', 0)) {
          return {
            ...state,
            lists: {
              ...state.lists,
              data,
            },
            recentLists: data.slice(0, 10),
          };
        }
        return {
          ...state,
          recentLists: data.slice(0, 10),
        };
      }

      if (deleted) {
        return {
          ...state,
          deletedLists: {
            ...state.deletedLists,
            data,
            initialRequest: false,
            ...meta,
          },
        };
      }

      return {
        ...state,
        lists: {
          ...state.lists,
          data,
          ...meta,
          initialRequest: false,
        },
      };
    }

    case 'TOGGLE_SELECT_ALL_LISTS': {
      const { value, deleted } = action.data;

      if (deleted) {
        return {
          ...state,
          deletedLists: { ...state.deletedLists, selectAll: value },
        };
      }

      return {
        ...state,
        lists: {
          ...state.lists,
          selectAll: value,
        },
      };
    }

    case 'SET_DROPDOWN_LISTS_PARAMS': {
      return {
        ...state,
        dropdownLists: { ...state.dropdownLists, ...action.data },
      };
    }

    case 'UNSET_DROPDOWN_LISTS': {
      const { saveFilters } = action.data;
      return {
        ...state,
        dropdownLists: {
          data: state.recentLists,
          page: 1,
          totalPages: 0,
          filters: saveFilters ? state.dropdownLists.filters : [],
        },
      };
    }

    case 'MOVE_CONTACTS_TO_STATIC_LIST':
    case 'LIST_ADD_CONTACTS': {
      const { listIds } = action;
      const updatedLists = [...state.recentLists].filter((list) => listIds.includes(list._id));
      let newLists = [...state.selectedLists].filter((list) => (!updatedLists.find((listItem) => listItem._id === list._id) && listIds.includes(list._id)));
      if (!newLists.length) {
        newLists = [...state.lists.data.filter((list) => (!updatedLists.find((listItem) => listItem._id === list._id) && listIds.includes(list._id)))];
      }

      return {
        ...state,
        recentLists: [...updatedLists, ...newLists, ...state.recentLists.filter((list) => !listIds.includes(list._id))].slice(0, 10),
      };
    }

    case 'SET_PREVIEW_CONTACT_RELATIONS': {
      const { listRelations, toDelete, populatedLists } = action.data;
      if (toDelete) {
        return {
          ...state,
          contactLists: isEmpty(state.contactLists) ? {} : {
            ...state.contactLists,
            data: [...populatedLists, ...state.contactLists.data],
          },
        };
      }
      return {
        ...state,
        contactLists: isEmpty(state.contactLists) ? {} : {
          ...state.contactLists,
          data: state.contactLists.data.filter((list) => !listRelations.includes(list._id)),
        },
      };
    }

    case 'SEARCH_RECENT_LISTS': {
      return {
        ...state,
        recentLists: action.payload.data,
      };
    }

    case 'SET_LIST_SIZE': {
      return {
        ...state,
        recentLists: [...state.recentLists].map((list) => {
          if (list._id === action.data.listId) list.contactsCount = action.data.newSize;
          return list;
        }),
      };
    }

    case 'GET_CURRENT_LIST_SIZE': {
      return {
        ...state,
        currentList: { ...state.currentList, contactsCount: get(action, 'payload[0].contactsCount', 0) },
        recentLists: [...state.recentLists].map((list) => {
          if (list._id === get(action, 'payload[0].listId', '')) list.contactsCount = get(action, 'payload[0].contactsCount', 0);
          return list;
        }),
      };
    }

    case ACCOUNT_EVENTS.RECENT_LIST_SIZE_EVENT: {
      const { listSize: { listId, contactsCount } } = JSON.parse(action.payload);
      return {
        ...state,
        recentLists: [...state.recentLists].map((list) => {
          if (list._id === listId) list.contactsCount = contactsCount;
          return list;
        }),
      };
    }

    case 'SIGN_OUT':
    case 'SWITCH_WORKSPACE': {
      return initialState;
    }

    default:
      return state;
  }
};
