import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from '@newlab-io/getprospect-react-components/lib/ui';
import {
  Modal,
  Button,
} from 'antd';

import { TextArea } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { modalsSelector } from 'data/selectors/modals';
import { addOptOutContacts } from 'data/actions/sequences';

import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../baseModal/styles';

const AddUnsubscriptionsModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);

  const [emails, setEmails] = useState('');

  const disabled = !emails.split('\n').some(Boolean);

  const handleClose = () => dispatch(toggleModal('add_unsubscriptions', false));

  const addUnsubscriptions = () => {
    const _emails = emails.split('\n').filter(Boolean);
    dispatch(addOptOutContacts(_emails));
    handleClose();
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          New unsubscriptions
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      width={450}
      closable={false}
      footer={false}
      onCancel={handleClose}
    >
      <>
        <Label as="div" size="small" style={{ marginBottom: 10 }} kind="tertiary" fontWeight={500}>GetProspect won&lsquo;t send emails to those recipients.</Label>
        <Label as="div" size="small" kind="secondary" style={{ marginBottom: 5 }} fontWeight={500}>Email addresses or domains to unsubscribe</Label>
        <TextArea
          rows={10}
          style={{ resize: 'none' }}
          value={emails}
          onChange={(e) => setEmails(e.target.value)}
          placeholder="Enter email address or domain per line"
        />
      </>
      <Footer>
        <Button
          data-form="true"
          type="primary"
          size="large"
          onClick={addUnsubscriptions}
          disabled={disabled}
        >
          Submit
        </Button>
        <Button size="large" type="normal" onClick={handleClose}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default AddUnsubscriptionsModal;
