import React, { useState } from 'react';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
} from 'antd';

import { Input, Button } from 'components/common';

import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { userWorkspacesSelector } from 'data/selectors/user';
import { renameWorkspace } from 'data/actions/user';
import {
  CloseIcon,
  ModalTitle,
  Label,
  Warning,
  InputStyle,
  Footer,
} from '../baseModal/styles';

const RenameWorkspaceModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const modalOptions = useSelector(modalOptionsSelector);
  const userWorkspaces = useSelector(userWorkspacesSelector);

  const workspaceId = get(modalOptions, 'workspaceId', '');
  const currentWorkspace = userWorkspaces.find((userWorkspace) => userWorkspace.workspaceId === workspaceId);

  const [name, updateName] = useState(get(currentWorkspace, 'name', ''));
  const isExist = userWorkspaces.findIndex((item) => item.name === name);

  const handleRenameWorkspace = () => {
    dispatch(toggleModal('rename_workspace', false));
    dispatch(renameWorkspace({ workspaceId, name }));
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Rename the workspace
          <CloseIcon
            onClick={() => dispatch(toggleModal('rename_workspace', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={() => dispatch(toggleModal('rename_workspace', false))}
    >
      <>
        <Label>Name*</Label>
        <Input
          autoFocus
          onChange={(e) => {
            updateName(e.target.value);
          }}
          value={name}
          placeholder="Type the workspace name..."
          style={InputStyle}
        />

        {isExist >= 0 && name.length && (name !== get(currentWorkspace, 'name', '')) && (
          <Warning>
            {`${name} already exists. Please choose a new name.`}
          </Warning>
        )}
      </>
      <Footer>
        <Button
          data-form={!name || isExist >= 0 ? 'false' : 'true'}
          type="primary"
          onClick={handleRenameWorkspace}
          disabled={!name || isExist >= 0}
        >
          Save
        </Button>
        <Button type="normal" onClick={() => dispatch(toggleModal('rename_workspace', false))}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default RenameWorkspaceModal;
