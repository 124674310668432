import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/loading';
import Icon from 'components/common/icon';
import { updateLoading } from 'data/actions/loading';
import { googleAuthLoadingSelector } from 'data/selectors/loading';
import { extensionAuthActiveSelector } from 'data/selectors/user';
import useIncognitoMessage from 'hooks/messageTemplates/useIncognitoMessage';

import {
  GoogleIconStyle, SignUpTextWrapper, ButtonTextWrapper, GoogleSignButton,
} from './styles';

const GoogleAuthBtn = ({
  title = '',
  className = '',
  loaderStyle = {},
  state,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(googleAuthLoadingSelector);
  const isExtensionAuthActive = useSelector(extensionAuthActiveSelector);
  const showIncognitoMessage = useIncognitoMessage();

  const signIn = useGoogleLogin({
    text: title,
    onError: (error) => {
      if (error?.details && error.details.includes('Cookies are not enabled in current environment.')) {
        sessionStorage.setItem('incognito', 'true');
      }
      dispatch(updateLoading('googleAuth', false));
    },
    ux_mode: 'redirect',
    redirect_uri: `${process.env.REACT_APP_WEBSITE_APP_URL}/oauth/google/verify`,
    flow: 'auth-code',
    state: isExtensionAuthActive ? 'extensionAuth' : state,
  });

  return (
    <Loader style={loaderStyle} loading={isLoading}>
      <GoogleSignButton
        className={className}
        size="large"
        type="default"
        onClick={() => {
          if (sessionStorage.getItem('incognito')) {
            showIncognitoMessage();
          } else {
            dispatch(updateLoading('googleAuth', true));
            signIn();
          }
        }}
      >
        <SignUpTextWrapper>
          <Icon type="ic-google" style={GoogleIconStyle} />
          <ButtonTextWrapper>
            {title}
          </ButtonTextWrapper>
        </SignUpTextWrapper>
      </GoogleSignButton>
    </Loader>
  );
};

export default GoogleAuthBtn;
