export const HistoryType = Object.freeze({
  ACTIVITY: 'activity',
  ENTITY: 'entity',
  NOTE: 'note',
  EMAIL: 'email',
  TASK: 'task',
  DEAL: 'deal',
});

export const ActivityType = Object.freeze({
  CREATED_ENTITY: 'createdEntity',
  UPDATED_ENTITY: 'updatedEntity',
  DELETED_ENTITY: 'deletedEntity',
  RESTORED_ENTITY: 'restoredEntity',
  WROTE_NOTE: 'wroteNote',
  SENT_MESSAGE: 'sentMessage',
  OPENED_MESSAGE: 'openedMessage',
  REPLIED_MESSAGE: 'repliedMessage',
  CLICKED_MESSAGE: 'clickedMessage',
  UNSUBSCRIBED_SEQUENCE: 'unsubscribedSequence',
  BOUNCED_MESSAGE: 'bouncedMessage',
  CHANGE_SEQUENCE_LEAD_STATUS: 'changeSequenceLeadStatus',
});
