import React,
{
  useState,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Progress,
  Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

import ListSelect from 'components/listSelect';
import Loader from 'components/loading';
import { COLORS } from 'constants/styles';
import { CREDITS_TYPE } from 'data/types/user.types';
import { upperCaseFirst } from 'helpers/upperCaseFirst';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import {
  setSelectedLists,
  createList,
} from 'data/actions/lists';
import { selectedInsightContactsSelector } from 'data/selectors/contacts';
import { selectedInsightCompaniesSelector } from 'data/selectors/companies';
import { SOURCE_TYPE } from 'data/types/source.types';
import { isNumber } from 'helpers/regularExpressions';
import {
  setSelectedInsightContacts,
  addDemoContacts,
} from 'data/actions/contacts';
import { bulkInsightsSearchLoadingSelector, companiesLimitLoaderSelector, insightsSearchLoadingSelector } from 'data/selectors/loading';
import {
  setSelectedInsightCompanies,
} from 'data/actions/companies';
import {
  createInsightsSearch,
  getCompaniesLimit,
  saveSearch,
} from 'data/actions/search';
import {
  demoUserSelector,
  userCreditsSelector,
} from 'data/selectors/user';
import {
  selectedListsSelector,
  listsSelector,
} from 'data/selectors/lists';
import { tourCurrentNameSelector } from 'data/selectors/tour';
import { setOperation } from 'data/actions/storeTriggers';
import usePaidOperation from 'hooks/usePaidOperation';
import { companiesLimitSelector } from 'data/selectors/search';
import { SEARCH_CONTENT } from 'constants/searchContent';
import {
  AmountInput,
  Label,
  OptionsContainer,
  OptionsItem,
  Border,
  OptionCheckbox,
  SaveSearchContainer,
  InputNameWrapper,
  ModalInput,
  DropdownWrapper,
  InfoImg,
  SecondaryButton,
  PrimaryButton,
  Modal,
  ModalTitle,
  ListSelectWrapper,
  InfoBlock,
} from './styles';
import {
  CloseIcon,
  Footer,
} from '../baseModal/styles';

const SaveFromSearchModal = () => {
  const dispatch = useDispatch();

  const lists = useSelector(listsSelector);
  const isDemoUser = useSelector(demoUserSelector);
  const { visible } = useSelector(modalsSelector);
  const {
    total,
    selectedAll,
    selectedItems,
    setSelectedItems,
    sourceType,
    customFilters,
    onSave,
  } = useSelector(modalOptionsSelector);
  const selectedLists = useSelector(selectedListsSelector);
  const selectedInsightContacts = useSelector(selectedInsightContactsSelector);
  const selectedInsightCompanies = useSelector(selectedInsightCompaniesSelector);
  const insightsLoading = useSelector(insightsSearchLoadingSelector);
  const bulkInsightsLoading = useSelector(bulkInsightsSearchLoadingSelector);
  const tourName = useSelector(tourCurrentNameSelector);
  const companiesLimit = useSelector(companiesLimitSelector);
  const companiesLimitLoading = useSelector(companiesLimitLoaderSelector);
  const { current: currentEmailSearch } = useSelector(userCreditsSelector);

  const loading = insightsLoading || bulkInsightsLoading || companiesLimitLoading;

  const [saveAdded, setSaveAdded] = useState(false);
  const [totalValue, setTotalValue] = useState(total);
  const [perCompany, setPerCompany] = useState();
  const [isOpened, setIsOpened] = useState(false);
  const [searchName, setSearchName] = useState();
  const [isSaveSearch, setIsSaveSearch] = useState(false);
  const [isSavePhoneNumbers, setIsSavePhoneNumbers] = useState(false);
  const [selectedList, setSelectedList] = useState(selectedLists.map((list) => list._id));
  const [isValidInput, setIsValidInput] = useState(true);
  const [maximumLeadsToSave, setMaximumLeadsToSave] = useState(0);

  let selectedInsights = selectedItems;
  if (!selectedItems) {
    selectedInsights = sourceType === SOURCE_TYPE.CONTACT ? selectedInsightContacts : selectedInsightCompanies;
  }
  const setSelectedRowKeys = sourceType === SOURCE_TYPE.CONTACT ? setSelectedInsightContacts : setSelectedInsightCompanies;
  const { tour } = Object.fromEntries(new URLSearchParams(window.location.search));

  useEffect(() => {
    dispatch(getCompaniesLimit());
  }, [dispatch]);

  useEffect(() => {
    setMaximumLeadsToSave(totalValue);
  }, [totalValue]);

  const getPluralSource = () => {
    if (totalValue <= 1) return sourceType === SOURCE_TYPE.CONTACT ? 'lead' : 'company';
    return sourceType === SOURCE_TYPE.CONTACT ? 'leads' : 'companies';
  };

  const saveToList = () => {
    if (selectedList && !selectedList.includes('no-list')) {
      dispatch(setSelectedLists(selectedList));
    }
  };

  const handleClose = () => dispatch(toggleModal('save_from_search', false));

  const onSuccessSaveInsight = (type, header) => {
    saveToList();
    dispatch(setOperation({
      type: 'insight',
      source: sourceType,
      from: 'search',
    }));
    dispatch(toggleMessage(type, { header }));
    handleClose();
    if (setSelectedItems) {
      setSelectedItems([]);
    } else {
      dispatch(setSelectedRowKeys([]));
    }
    if (!tour) dispatch(toggleModal('searches_preview', true, { show: SEARCH_CONTENT.RECENT_ACTIVITY }));
    if (onSave) onSave();
  };

  const handleSaveDemoClick = () => {
    handleClose();
    dispatch(addDemoContacts());
    onSuccessSaveInsight('success', `${upperCaseFirst(getPluralSource())} saved`);
  };

  const typePaidOperation = isSavePhoneNumbers ? CREDITS_TYPE.PHONE_SEARCH : CREDITS_TYPE.EMAIL_SEARCH;
  const handleSaveClick = usePaidOperation(sourceType === SOURCE_TYPE.CONTACT ? typePaidOperation : CREDITS_TYPE.SAVE_COMPANIES, () => {
    const limits = {
      total: totalValue,
    };

    if (isSaveSearch) {
      dispatch(saveSearch({
        name: searchName,
        sourceType,
      }));
    }
    const needToProcess = selectedInsights.length === 0;
    if (perCompany) limits.perCompany = perCompany;
    const data = {
      selected: totalValue,
      saveAdded,
      source: sourceType,
      isSavePhoneNumbers,
      listIds: (selectedList || []).filter((list) => list !== 'no-list'),
      limits,
      customFilters,
      selectedAll,
      savePhoneNumbers: isSavePhoneNumbers,
      onSuccess: () => {
        if (!needToProcess) {
          onSuccessSaveInsight('success', `${upperCaseFirst(getPluralSource())} saved`);
        } else {
          onSuccessSaveInsight('info', `${upperCaseFirst(getPluralSource())} save in progress`);
        }
      },
    };
    if (tourName) data.isDemo = true;
    dispatch(createInsightsSearch(data));
  }, undefined, totalValue);

  useEffect(() => {
    let value;
    if (selectedAll) value = total;
    else value = selectedInsights.length;
    setTotalValue(value);
    setIsValidInput(sourceType === SOURCE_TYPE.COMPANY ? parseInt(value, 10) <= companiesLimit.availableCompanies : true);
  }, [total, selectedInsights.length, selectedAll, sourceType, companiesLimit.availableCompanies]);

  useEffect(() => {
    if (tourName) {
      const exitsDemoList = lists?.data?.find((item) => item.name === 'Demo list');

      if (!exitsDemoList) {
        dispatch(createList({ name: 'Demo list', dynamic: false }, (createdList) => {
          setSelectedList([createdList?._id]);
        }));
      } else setSelectedList([exitsDemoList?._id]);
    }
  }, [tourName, dispatch, lists]);

  useEffect(() => {
    if (selectedList?.length === 0) {
      setSaveAdded(false);
    }
  }, [selectedList]);

  const changeTotalValue = (props) => {
    const value = get(props, 'target.value');
    if (!value) setTotalValue('');
    if (isNumber(value) && (total >= parseInt(value, 10))) {
      setTotalValue(parseInt(value, 10));
      setIsValidInput(sourceType === SOURCE_TYPE.COMPANY ? parseInt(value, 10) <= companiesLimit.availableCompanies : true);
    }
  };

  const changePerCompanyValue = (props) => {
    const value = get(props, 'target.value');
    if (!value) setPerCompany('');
    if (isNumber(value) && (total >= parseInt(value, 10))) {
      setPerCompany(parseInt(value, 10));
    }
  };

  const isSaveDisabled = () => {
    const countLists = get(selectedList, 'length', 0);
    if (!isSaveSearch) return !isValidInput || !totalValue || loading || (sourceType === SOURCE_TYPE.CONTACT && countLists === 0);
    return !isValidInput || !totalValue || !searchName || loading || (sourceType === SOURCE_TYPE.CONTACT && countLists === 0);
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          {`Save ${getPluralSource()}${SOURCE_TYPE.CONTACT === sourceType ? ' to list' : ''}`}
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      width={450}
      closable={false}
      footer={false}
      onCancel={handleClose}
    >
      {sourceType === SOURCE_TYPE.CONTACT ? (
        <ListSelectWrapper>
          <ListSelectWrapper>
            <Label>Select List</Label>
            <ListSelect
              size="large"
              maxTagCount={2}
              isOpened={isOpened}
              setIsOpened={setIsOpened}
              value={selectedList}
              onSelectedListsChange={setSelectedList}
              hasNoList
            />
          </ListSelectWrapper>
          <Border />
        </ListSelectWrapper>
      ) : <DropdownWrapper className="save-from-search" />}
      <OptionsContainer>
        <OptionsItem
          style={{ flex: sourceType === SOURCE_TYPE.CONTACT ? 0.5 : 1 }}
        >
          <Label>{`Maximum ${getPluralSource()} to save?`}</Label>
          <AmountInput
            value={maximumLeadsToSave}
            onChange={changeTotalValue}
            size="large"
            disabled={!selectedAll}
            error={!isValidInput && 'Invalid count (according to company limit)'}
            suffix={(
              <Tooltip placement="bottomLeft" title={`Enter amount of ${getPluralSource()} to save`}>
                <InfoImg />
              </Tooltip>
            )}
          />
        </OptionsItem>
        {sourceType === SOURCE_TYPE.CONTACT && (
          <OptionsItem>
            <Label>Leads per each company?</Label>
            <AmountInput
              value={perCompany}
              onChange={changePerCompanyValue}
              size="large"
              suffix={(
                <Tooltip overlayStyle={{ maxWidth: 220 }} placement="bottomLeft" title="Enter amount of leads for each company">
                  <InfoImg />
                </Tooltip>
              )}
            />
          </OptionsItem>
        )}
      </OptionsContainer>
      {
        sourceType === SOURCE_TYPE.CONTACT && (
          <InfoBlock>
            <span>
              {'Remaining '}
              <b>{currentEmailSearch}</b>
              {' credits. '}
              <Link to="/settings/subscriptions">Add more.</Link>
            </span>
            <span>The actual number of saved leads can be less because the email might not be found for the lead</span>
          </InfoBlock>
        )
      }
      {
        sourceType === SOURCE_TYPE.COMPANY ? (
          <Loader loading={companiesLimitLoading}>
            <OptionsItem style={{ marginTop: 10 }}>
              <Label>{`Available companies to save ${companiesLimit.availableCompanies}/${companiesLimit.limitCompanies}`}</Label>
              <Progress percent={companiesLimit.availableCompanies === 0 ? 100 : (((companiesLimit.limitCompanies - companiesLimit.availableCompanies) * 100) / companiesLimit.limitCompanies)} strokeWidth={5} showInfo={false} strokeColor={COLORS.PRIMARY} trailColor="#dfe2e5" strokeLinecap="square" />
            </OptionsItem>
          </Loader>
        ) : null
      }
      <Border />
      {sourceType === SOURCE_TYPE.CONTACT && (
        <>
          {
            selectedList?.length > 0 && (
              <OptionCheckbox checked={saveAdded} onChange={(e) => setSaveAdded(e.target.checked)}>
                <div style={{ display: 'inline-flex' }}>
                  <span>Save existing leads</span>
                  <Tooltip placement="bottomLeft" title="Save leads that were already saved before">
                    <InfoImg style={{ marginLeft: 5 }} />
                  </Tooltip>
                </div>
              </OptionCheckbox>
            )
          }
          <OptionCheckbox checked={isSaveSearch} onChange={(e) => setIsSaveSearch(e.target.checked)}>Save existing search</OptionCheckbox>
          <OptionCheckbox bottomSpace={isSaveSearch} checked={isSavePhoneNumbers} onChange={(e) => setIsSavePhoneNumbers(e.target.checked)}>Save phone numbers</OptionCheckbox>
        </>
      )}
      {isSaveSearch && (
        <>
          <Border />
          <SaveSearchContainer>
            <InputNameWrapper>
              <Label>Name</Label>
              <ModalInput
                autoFocus
                onChange={(e) => {
                  setSearchName(e.target.value);
                }}
                value={searchName}
                placeholder="Enter name"
                size="large"
              />
            </InputNameWrapper>
          </SaveSearchContainer>
          <Border />
        </>
      )}
      <Footer style={{ marginTop: saveSearch ? 20 : 0 }}>
        <PrimaryButton
          data-form={isSaveDisabled() ? 'false' : 'true'}
          size="large"
          type="primary"
          onClick={isDemoUser ? handleSaveDemoClick : handleSaveClick}
          disabled={isSaveDisabled()}
          loading={!isDemoUser && loading}
          data-tour="save-leads-tour-step-4"
        >
          {`Save ${getPluralSource()}`}
        </PrimaryButton>
        <SecondaryButton size="large" type="normal" onClick={handleClose}>Cancel</SecondaryButton>
      </Footer>
    </Modal>
  );
};

export default SaveFromSearchModal;
