import styled from 'styled-components';
import * as CONSTANTS from '../../../constants/styles';

export const PhoneInputWrapper = styled.div`
  margin-bottom: 20px;
  position: relative;

  .phone-suffix {
    display: none;
  }

  .arrow {
    display: none;
  }

  :focus, :hover {
    .phone-suffix {
      display: block;
    }
  }
  
  div {
    font-family: AvenirNext;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }

  ${(props) => (props.error ? `.form-control {
        border-color: ${CONSTANTS.COLORS.RED_R1} !important;
        + div {
          border-color: ${CONSTANTS.COLORS.RED_R1} !important;
        }
      }` : `
    &:hover {
      .form-control {
        border-color: ${CONSTANTS.COLORS.BLUE_B1} !important;
        + div {
          border-color: ${CONSTANTS.COLORS.BLUE_B1} !important;
        }
      }
    }
  `)}

  .react-tel-input {
  
    .invalid-number-message {
      left: 0;
      top: 40px;
      color: ${CONSTANTS.COLORS.RED_R1};
      background: transparent;
      
      + .form-control  {
        border-color: ${CONSTANTS.COLORS.RED_R1};
        box-shadow: ${CONSTANTS.SHADOWS.RED_R1};
        background-color: ${CONSTANTS.COLORS.WHITE};
        
        :focus, :hover {
          border-color: ${CONSTANTS.COLORS.RED_R1};
          
          + div {
            border-color: ${CONSTANTS.COLORS.RED_R1};
          }
        }
    
        :focus {
          box-shadow: ${CONSTANTS.SHADOWS.RED_R1};
        }
       }
      
      ~ .flag-dropdown {
        border-color: ${CONSTANTS.COLORS.RED_R1};
      }
    }
   
    .form-control {
      width: 100%;
      height: 40px;
      border-color: ${CONSTANTS.COLORS.GREY_G8};
      color: ${CONSTANTS.COLORS.BLACK_WITH_OPACITY};
      transition: all 0.3s;
    
      :focus, :hover {
        border-color: ${CONSTANTS.COLORS.BLUE_B1};
        .phone-suffix {
          display: block;
        }
        
        + div {
          border-color: ${CONSTANTS.COLORS.BLUE_B1};
        }
      }
    
      :focus {
        box-shadow: ${CONSTANTS.SHADOWS.BLUE_B1};
      }
    }
    
    .flag-dropdown {
      border: 1px solid ${CONSTANTS.COLORS.GREY_G8};
      border-right: none;
      background-color: white;
      transition: all 0.3s;

      .country-list {
        text-align: left;
        border-radius: 0;

        .search {
          padding-right: 10px;

          .search-box {
            height: ${CONSTANTS.ComponentsConfig.input.default.height}px;
            font-size: ${CONSTANTS.ComponentsConfig.input.default.fontSize}px;
            width: 100%;
            margin-left: 0;
          }
        }
      }
    
      .selected-flag {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        .flag {
          width: 25px;
          height: 20px;
          margin-left: -7px;
        }
        
        :hover, :focus {
          background-color: ${CONSTANTS.COLORS.GREY_DISABLED};
        }
      }
    }
  }
`;

export const CheckContainer = styled.div`
  width: 98%;
  margin-top: -33px;
  text-align: right;
  position: relative;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: all 0.5s;
`;

export const SuffixContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 12px;
`;
