import styled from 'styled-components';

import * as CONSTANTS from 'constants/styles';
import { Button, Checkbox } from 'components/common';

export const Wrapper = styled.div`
  padding: 4px 0;
  align-items: center;
  display: flex;
  min-height: 68px;
`;

export const Header = styled.header`
  align-items: center;
  background-color: #fff;
  background-image: none;
  color: inherit;
  min-height: 76px;
  display: flex;
  padding: 22px 35px;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  color: #33475b;
  line-height: 24px;
  width: 100%;
`;

export const StepsWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  
  .ant-steps-small .ant-steps-item-icon {
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
  
  .ant-steps-small .ant-steps-item-title {
    font-size: 16px;
  }
  
  .ant-steps-label-vertical .ant-steps-item-icon, .ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
    margin-left: 0;
  }
  
  .ant-steps-label-vertical .ant-steps-item-content {
    margin: 0;
    width: 100%;
    max-width: 55px;
    text-align: left;
  }
  
  .ant-steps-item-container {
    display: flex;
    align-items: center;
  }
  
  .ant-steps-label-vertical .ant-steps-item-tail {
    margin: 0;
    padding: 0;
    width: 39px;
    position: initial;
    order: 1;
    margin: 0 8px;
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      background: ${CONSTANTS.COLORS.PRIMARY};

      .ant-steps-icon {
        color: ${CONSTANTS.COLORS.WHITE};
      }
    }
  }
  
  .ant-steps-item {
    flex: 1;
    margin-right: 10px;
    
    :last-child {
      margin: 0;
      max-width: 120px;
    } 
  }
`;

export const Footer = styled.footer`
  background-color: ${CONSTANTS.COLORS.GREY_G5};
  border-top: 1px solid ${CONSTANTS.COLORS.GREY_G4};
  padding: 20px 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const StepButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  div:first-child {
    margin-right: 30px;
  }
`;

export const CancelImport = styled.div`
  position: absolute;
  top: 28px;
  left: 30px;
`;

export const CloseImportManager = {
  height: 'auto',
  width: 'auto',
  padding: 0,
  margin: 0,
};

export const ImportHeaderTitle = styled.h2`
  margin: 0;
  font-size: 24px;
`;

export const ImportContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const ImportBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${CONSTANTS.COLORS.WHITE};
`;

export const ImportWrapper = styled.div`
  width: 100%;
  padding: 25px 35px 0 35px;
`;

export const ImportContent = styled.div`
  max-width: 1370px;
  margin: 0 auto;
  box-shadow: ${CONSTANTS.SHADOWS.BLACK_B1};
  border-radius: 4px;
`;

export const StepButton = styled(Button)`
  width: 150px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
`;

export const CancelImportText = styled.b`
  font-size: 14px;
`;

export const ImportManagerComponentContainer = styled.div`
  padding: 30px 0;
`;

export const ImportManagerTitle = styled.h2`
  text-align: center;
  margin-bottom: 30px;
`;

export const HelpText = styled.b`
  color: ${CONSTANTS.COLORS.PRIMARY};
  cursor: pointer;
`;

export const CheckboxContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NotificationCheckbox = styled(Checkbox)``;
