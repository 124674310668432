import React from 'react';
import { useSelector } from 'react-redux';

import { isDiscountAvailableSelector } from 'data/selectors/subscriptions';
import Choose from '../../components/choose';
import Connect from '../../components/connect';
import Discount from '../../components/discount';
import SpecifyInformation from '../../components/specifyInformation';

const AnotherTool = ({
  sharedInformation,
  discount,
  tool,
  onFinish,
  setNextStep,
  stepNumber,
  onClose,
}) => {
  const isDiscountAvailable = useSelector(isDiscountAvailableSelector);

  const steps = {
    1: {
      component: Choose,
      params: {
        title: 'Which tool did you choose?',
        type: 'radio',
        array: [
          'Apollo',
          'FindThatLead',
          'Lusha',
          'Pipileads',
          'RocketReach',
          'SalesQL',
          'SignalHire',
          'Skrapp',
          'Snov.io',
          'Wiza',
          'Zoominfo',
          'Other',
        ],
        minOtherSymbols: 3,
        field: 'tool',
        onNext: setNextStep,
        onClose,
      },
    },
    2: {
      component: SpecifyInformation,
      params: {
        field: 'descriptionToolFeature',
        description: 'Please help us improve our product by sharing your opinion. We appreciate and take into consideration all feedback to become better for our customers.',
        title: `Why is ${tool} better than GetProspect?`,
        onNext: setNextStep,
        onClose,
      },
    },
    3: {
      component: Connect,
      params: {
        description: 'We are very sorry you are leaving. We would like to discuss this situation with you personally if it is convenient for you. Maybe we can solve your problem or make an exclusive offer.',
        onNext: setNextStep,
        onClose,
      },
    },
    4: !sharedInformation && isDiscountAvailable ? {
      component: Discount,
      params: {
        percent: 30,
        onNext: setNextStep,
        onClose,
      },
    } : null,
  };

  const step = steps[stepNumber];

  if (!step) {
    const needToAct = !sharedInformation && !discount;
    onFinish({ needToAct });
    return null;
  }

  const { component: Component, params = {} } = step;

  // eslint-disable-next-line
  return <Component {...params} />;
};

export default AnotherTool;
