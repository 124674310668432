import React from 'react';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';

import {
  Input,
  Select,
  Option,
} from 'components/common';
import PersonIcon from 'components/personIcon';
import { toggleModal } from 'data/actions/modals';
import { isFloat } from 'helpers/regularExpressions';
import {
  DateComponent,
  ExistedContact,
  ContactInfo,
  ContactName,
  ContactMessage,
  LeadName,
} from './styles';

const Component = ({
  item,
  data,
  defaultInputValue,
  existedContact,
  handleChange,
  focusInputRef,
  errors = {},
  visibleBlock,
}) => {
  const dispatch = useDispatch();

  const showExistedContact = () => dispatch(toggleModal('contact_preview', true, { contactId: existedContact._id }));
  const nameExistedContact = `${get(existedContact, 'properties.firstName.value')} ${get(existedContact, 'properties.lastName.value')}`;
  const companyRelationExistedContact = get(existedContact, 'companyRelations', []).find((companyRelation) => get(companyRelation, 'email.value', '') === data.email);

  if (item.fieldType === 'select') {
    return (
      <Select
        size="large"
        name={item.name}
        style={{ width: '100%' }}
        value={data[item.name]}
        onChange={(value) => {
          handleChange({
            target: {
              name: item.name,
              value,
            },
          });
        }}
        error={errors[item.name]}
      >
        { item.options.map((option) => <Option value={option.value} key={get(option, '_id')}>{option.label}</Option>) }
      </Select>
    );
  }

  if (item.fieldType === 'booleancheckbox') {
    return (
      <Select
        size="large"
        name={item.name}
        style={{ width: '100%' }}
        value={data[item.name]}
        onChange={(value) => {
          handleChange({
            target: {
              name: item.name,
              value,
            },
          });
        }}
        error={errors[item.name]}
      >
        <Option value="yes">Yes</Option>
        <Option value="no">No</Option>
      </Select>
    );
  }

  if (item.fieldType === 'date') {
    return (
      <DateComponent
        placeholder=""
        onChange={(date) => {
          handleChange({
            target: {
              name: item.name,
              value: date && date.utc().toISOString(),
            },
          });
        }}
      />
    );
  }

  if (item.fieldType === 'number') {
    return (
      <Input
        size="large"
        name={item.name}
        value={defaultInputValue || data[item.name]}
        onChange={(e) => {
          const targetValue = isFloat(e.target.value);

          if (targetValue) {
            handleChange({
              target: {
                name: item.name,
                value: Number(e.target.value.trim()),
              },
            });
          }
        }}
        ref={focusInputRef}
        error={errors[item.name]}
      />
    );
  }

  return (
    <>
      <Input
        size="large"
        name={item.name}
        value={defaultInputValue || data[item.name]}
        onChange={handleChange}
        ref={focusInputRef}
        error={errors[item.name]}
        tabIndex={visibleBlock ? -1 : 1}
      />
      {
        existedContact && item.name === 'email' && (
          <ExistedContact>
            <ContactMessage>
              Hold the phone! It looks like a contact with the email address
              {' '}
              <b>{get(companyRelationExistedContact, 'email.value', '')}</b>
              {' '}
              already exists.
            </ContactMessage>
            <ContactInfo onClick={showExistedContact}>
              <PersonIcon
                name={`${get(existedContact, 'properties.firstName.value[0]', 'G').toUpperCase()}${get(existedContact, 'properties.lastName.value[0]', 'P').toUpperCase()}`}
                code={existedContact._id}
              />
              <ContactName>
                <LeadName>
                  {nameExistedContact}
                </LeadName>
                <LeadName>{get(companyRelationExistedContact, 'position', '')}</LeadName>
              </ContactName>
            </ContactInfo>
          </ExistedContact>
        )
      }
    </>
  );
};

export default Component;
