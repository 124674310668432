import React from 'react';
import {
  Button,
} from 'antd';
import { nanoid } from 'nanoid';
import Divider from 'antd/es/divider';

import { Select } from 'components/common';
import {
  ButtonsContainer,
  SelectButton,
  SelectDivider,
  SelectContainer,
  PropertyOption,
} from 'components/mapContactProperties/styles';

const generateColumns = ({ fileHeaders, setMatchedHeader = () => {}, matchedHeader }) => [
  {
    title: 'Filter name',
    dataIndex: 'filterName',
    key: 'filterName',
    width: '50%',
  },
  {
    title: 'Column header',
    width: '50%',
    render: () => {
      const selectedValue = fileHeaders.includes(matchedHeader) ? matchedHeader : 'Choose header';
      return (
        <Select
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={SelectDivider} />
              <ButtonsContainer>
                <Button
                  type="link"
                  style={SelectButton}
                  size="default"
                  onMouseDown={() => {
                    setMatchedHeader(null);
                  }}
                >
                  Clear column
                </Button>
              </ButtonsContainer>
            </div>
          )}
          value={selectedValue}
          onChange={setMatchedHeader}
          style={SelectContainer}
        >
          {fileHeaders.map((item) => (
            <PropertyOption key={nanoid()} value={item}>
              { item }
            </PropertyOption>
          ))}
        </Select>
      );
    },
  },
];

export default generateColumns;
