const getFolderChildren = (folderId, tree, defaultFolderId = 'all') => {
  if (folderId === defaultFolderId || tree._id === folderId) {
    return tree.children || [];
  }
  if (tree.children) {
    let result;
    for (let i = 0; !result && i < tree.children.length; i += 1) {
      result = getFolderChildren(folderId, tree.children[i]);
    }
    return result;
  }
  return false;
};

export default getFolderChildren;
