import styled from 'styled-components';

import * as CONSTANTS from 'constants/styles';
import quotes from 'assets/images/quotes.svg';
import rightBlocks from 'assets/images/right_blocks.svg';
import sticksAndStars from 'assets/images/sticks_and_stars.svg';
import greyGridPath from 'assets/images/grey_grid.svg';

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 100px;
  padding-bottom: 20px;

  ${(props) => (!props.finish && `
    height: 100%;
  `)}

  @media (max-width: ${CONSTANTS.TABLET_MAX_WIDTH}px) and (min-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    align-items: center;
  }

  @media (max-width: ${CONSTANTS.TABLET_MAX_WIDTH}px) {
    margin: ${(props) => (props.finish ? 0 : '0 20px')};
    padding-bottom: ${(props) => (props.finish ? 30 : 20)}px;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${CONSTANTS.COLORS.WHITE};
  display: flex;

  & > * {
    width: 50%;
  }

  @media (max-width: ${CONSTANTS.TABLET_MAX_WIDTH}px) {
    flex-direction: ${(props) => (props.finish ? 'column' : 'row')};
    & > * {
      width: 100%;
    }
  }
`;

export const ChildrenContainer = styled.div`
  @media (max-width: ${CONSTANTS.TABLET_MAX_WIDTH}px) {
    display: flex;
    justify-content: center;
    ${(props) => (props.finish && `
      margin: 0 20px;
    `)}
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 20px;

  @media (max-width: ${CONSTANTS.TABLET_MAX_WIDTH}px) {
    margin: ${(props) => (props.finish ? '20px 20px' : '20px 0')};
  }
`;

export const Footer = styled.footer`
  color: ${CONSTANTS.COLORS.GREY_G1};
  font-size: 14px;
  margin-left: 0px;
  max-width: 400px;
`;

export const RightsContainer = styled.div`
  margin-top: 5px;
`;

export const ProgressWrapper = styled.div`
  margin-bottom: ${(props) => (typeof props?.percent === 'number' ? '85px' : '126px')};
  margin-left: -100px;

  .ant-progress-inner {
    border-radius: 0;
  }

  @media (max-width: ${CONSTANTS.MACBOOK_AIR_MAX_WIDTH}px) {
    margin-bottom: 30px;
  }

  @media (max-width: ${CONSTANTS.TABLET_MAX_WIDTH}px) {
    visibility: hidden;
    margin-bottom: 0px;
  }
`;

export const RightContainer = styled.div`
  background: ${CONSTANTS.COLORS.GREY_G5};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 40px;
  position: relative;

  &:after {
    z-index: 1;
    position: absolute;
    margin-right: 0;
    margin-top: calc(100vh - 450px);
    margin-left: calc(100% - 300px);
    width: 350px;
    height: 350px;
    content: '';
    background-image: url(${greyGridPath});

    @media (max-width: ${CONSTANTS.MACBOOK_AIR_MAX_WIDTH}px) {
      margin-top: calc(100vh - 350px);
      width: 300px;
      height: 300px;
    }
  }

  ${(props) => {
    if (props.background === 'quotes') {
      return `
        &:before {
          position: absolute;
          margin-right: 200px;
          margin-top: 81px;
          width: 300px;
          height: 235px;
          content: '';
          background-image: url(${quotes});
        }
      `;
    }
    return '';
  }}

  @media (max-width: ${CONSTANTS.TABLET_MAX_WIDTH}px) {
    display: ${(props) => (props.finish ? 'flex' : 'none')};
    padding-top: ${(props) => (props.finish ? 30 : 0)}px;
  }

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const BaseCite = styled.div`
  margin-top: 38px;
  font-weight: 700;
  font-size: 24px;
  color: ${CONSTANTS.COLORS.DARKEST_BLUE};
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 30px;
  margin-bottom: 83px;

  ${(props) => (props.stars && `
    &:after {
      content: '';
      width: 250px;
      position: absolute;
      margin-left: 240px;
      margin-top: -70px;
      height: 123px;
      background-image: url(${sticksAndStars});
      background-repeat: no-repeat;
      @media (max-width: ${CONSTANTS.TABLET_MAX_WIDTH}px) {
        display: none;
      }
    }
  `)}
`;

export const BackToSiteContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 85px;
  width: 100%;
  text-align: right;
  padding-right: 60px;

  ${(props) => (props.finish && `
      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        left: calc(100vw / 2 + 50px);
        width: 90px;
        height: 500px;
        background: url(${rightBlocks});
        transform: rotate(180deg);
        @media (max-width: 1500px) {
          display: none;
        }
      }
  `)}

  @media (max-width: ${CONSTANTS.MACBOOK_AIR_MAX_WIDTH}px) {
    margin-bottom: 30px;
  }

  @media (max-width: ${CONSTANTS.TABLET_MAX_WIDTH}px) {
    display: ${(props) => (props.finish ? 'none' : 'block')};
  }
`;

export const BackToSiteTabletContainer = styled.div`
  display: none;

  @media (max-width: ${CONSTANTS.TABLET_MAX_WIDTH}px) {
    display: block;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${CONSTANTS.COLORS.GREY_G1};
  margin-bottom: 20px;

  ${(props) => (props.finish && `
    &:after {
      content: '';
      position: absolute;
      right: 0;
      margin-top: -180px;
      width: 90px;
      height: 500px;
      background: url(${rightBlocks});

      @media (max-width: 1500px) {
        display: none;
      }
    }
  `)}
`;

export const BaseSubCite = styled.div`
  font-weight: 500;
  text-align: center;
  font-size: 18px;
  line-height: 25px;
  color: ${CONSTANTS.COLORS.DARKEST_BLUE};
  margin-bottom: 20px;

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    margin-bottom: 15px;
  }
`;

export const FeaturesWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 104px;
  width: 530px;
  border-bottom: 1px solid #e0e3e9;
  margin-bottom: 30px;

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    width: 100%;
    height: 171px;
  }
`;

export const FeaturesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 377px;
  height: 80px;

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    width: 199px;
    height: 141px;
  }
`;

export const FeatureContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FeatureText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-left: 14px;
  color: ${(props) => props.fill};
`;

export const BaseContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin-bottom: 120px;
  margin-top: 45px;

  @media (max-width: ${CONSTANTS.MACBOOK_AIR_MAX_WIDTH}px) {
    margin-bottom: 0;
  }
`;
