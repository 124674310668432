import styled, { css } from 'styled-components';

import {
  HEADER_HEIGHT,
  DRAWER_PADDING,
  MOBILE_MAX_WIDTH,
  COLORS,
} from 'constants/styles';
import { Icon } from 'components/common';

const rightMixin = css`
  display: flex;
  justify-content: flex-end;
`;

const betweenMixin = css`
  display: flex;
  justify-content: space-between;
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: ${(props) => (props.onlyFooter ? 0 : 20)}px;

  ${(props) => props.right && rightMixin}
  ${(props) => props.between && betweenMixin}

  button {
    margin-right: 10px;
  }
`;

export const InputStyle = {
  margin: '5px 0 10px 0',
};

export const Warning = styled.div`
  color: #f2545b !important;
  font-size: 12px;
`;

export const ModalTitle = styled.div`
  font-family: 'AvenirNext';
  background: ${(props) => {
    if (props.danger) return COLORS.RED;
    if (props.white) return COLORS.WHITE;
    return COLORS.BLUE;
  }}; 
  margin: -16px -24px;
  padding: 25px ${DRAWER_PADDING}px;
  font-size: 18px;
  font-weight: ${(props) => (props.isRecent ? 400 : 600)};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${(props) => (props.white ? COLORS.BLACK_G1 : COLORS.WHITE)};
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT}px;
  border-radius: 4px 4px 0 0;
  
  @media(max-width: ${MOBILE_MAX_WIDTH}px) {
    padding: 20px;
  }

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: initial;
  }
`;

export const CloseIcon = styled(Icon).attrs(() => ({
  type: 'ic-close',
}))`
  cursor: pointer;
  border-radius: 50%;
  padding: 5px;
  margin-right: -5px;
  margin-left: auto;
  transition: all 0.2s;
  opacity: 0.5;
  margin-top: 5px;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }

  &:active {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const CreditsBarClose = {
  position: 'absolute',
  right: 10,
  padding: 2,
};

export const Label = styled.label`
  font-family: 'AvenirNext';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4f5964;
  margin-bottom: 5px;
`;

export const ModalTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderStyle = {
  backgroundColor: '#2565c7',
  color: '#ffffff',
  fontFamily: 'AvenirNext',
  fontSize: '18px',
  fontWeight: '600',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.67',
  letterSpacing: 'normal',
  borderRadius: '0px',
  height: HEADER_HEIGHT + 1,
};

export const WorkspaceUsersSelectStyle = {
  width: '100%',
};

export const InputHeader = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: ${COLORS.BLACK_WITH_OPACITY_B1};
`;

export const HeaderLabel = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${COLORS.BLACK_G1};
  margin: 0;
  padding: 0;
  ${(props) => props.center && `
    text-align: center;
  `}
`;

export const SubHeaderLabel = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.GREY_G1};
  ${(props) => props.center && `
    text-align: center;
  `}
  
  b {
    color: ${COLORS.BLACK_G1};
  }
`;

export const ColumnFooter = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const ProperCloseIcon = styled(CloseIcon)`
  opacity: 1;
`;
