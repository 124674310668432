import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { toggleMessage } from 'data/actions/message';

const useContactsSavedMessage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const showContactsSavedMessage = useCallback(({
    pending,
    countUpdatedItems,
    countDuplicates,
    countInBlackList,
    openSequence,
    sequenceId,
    selectedContactsLength,
    selectedSequenceId,
  }) => {
    if (openSequence || sequenceId) {
      dispatch(toggleMessage('success', {
        header: 'Recipients updated',
        text: (
          <div>
            <div>
              <strong>
                {`${countUpdatedItems} new`}
              </strong>
              &nbsp;
              leads successfully added.
            </div>
            {
              countDuplicates > 0 && !pending && (
                <div>
                  <strong>
                    {`${countDuplicates} skipped,`}
                  </strong>
                  &nbsp;
                  as already on the recipient list.
                </div>
              )
            }
            {
              countInBlackList > 0 && !pending && (
                <div>
                  <strong>
                    {`${countInBlackList} skipped,`}
                  </strong>
                  &nbsp;
                  as on the opt-out list.
                </div>
              )
            }
          </div>
        ),
      }));
    } else {
      dispatch(toggleMessage('success', {
        header: 'Sequences created',
        text: `You added your ${selectedContactsLength} new leads to your first sequence. Start your sequence now.`,
        primaryButtonOptions: {
          text: 'Open sequence',
          action: () => {
            history.push(`/sequences/${selectedSequenceId}/steps`);
          },
          closeMessage: true,
        },
      }));
    }
  }, [dispatch, history]);

  return showContactsSavedMessage;
};

export default useContactsSavedMessage;
