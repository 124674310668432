import get from 'lodash/get';

import { clearStep } from '../../helpers/clearStep';
import { ACCOUNT_EVENTS } from '../types/event.types';
import { PageSizes } from '../types/tableMeta.types';
import { IMPORT_TYPE } from '../types/import.types';

const initialState = {
  matchedProperties: [],
  preprocess: false,
  settings: {
    name: '',
    fileName: '',
    source: 'contact',
    type: 'list_of_enrichment',
    userContactId: false,
    unmatchedImport: false,
    ignoreDuplicates: false,
  },
  imports: {
    data: [],
    totalItems: 0,
    pageSize: PageSizes.DEFAULT_IMPORTS_PAGE_SIZE,
    page: 1,
    filters: [],
    sort: {},
    search: {},
  },
  currentImport: {},
  suggestedImports: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'IMPORT_FILE_PREPROCESS': {
      const settings = { ...state.settings };
      const preprocess = { ...action.payload };
      const originalName = get(action, 'originalName', '');

      settings.name = originalName;
      preprocess.originalName = originalName;
      preprocess.fileUrl = `${get(action, 'fileUrl', '')}/${get(action, 'key', '')}`;
      return {
        ...state,
        preprocess,
        settings,
      };
    }
    case 'ADD_PROPERTY_MATCHING': {
      const { source, getProspect } = action.data;
      const matched = [...state.matchedProperties];
      const matchingIndex = matched.findIndex((matchedProp) => matchedProp.source === source);
      if (matchingIndex !== -1) {
        matched[matchingIndex].getProspect = getProspect;
      } else {
        matched.push({ source, getProspect });
      }
      return {
        ...state,
        matchedProperties: matched,
      };
    }
    case 'ADD_AUTO_MATCHED_PROPERTIES': {
      const matchedProperties = [...action.data];

      return {
        ...state,
        matchedProperties,
        settings: {
          ...state.settings,
          unmatchedImport: state.settings.type === IMPORT_TYPE.OPT_OUT_LIST ? matchedProperties.some((property) => property.getProspect === 'email') : state.settings?.unmatchedImport,
        },
      };
    }
    case 'REMOVE_PROPERTY_MATCHING': {
      const { source } = action.data;
      const matched = [...state.matchedProperties].filter((matchedProp) => matchedProp.source !== source);
      return {
        ...state,
        matchedProperties: matched,
      };
    }
    case 'UPDATE_PROPERTY_MATCHING': {
      const { matched } = action.data;
      return {
        ...state,
        matchedProperties: matched,
      };
    }
    case 'UPDATE_IMPORT_SETTINGS': {
      const { field, value } = action.data;
      const settings = { ...state.settings };
      if (Array.isArray(action.data)) {
        action.data.forEach(({ field: newField, value: newValue }) => {
          settings[newField] = newValue;
        });
      } else {
        settings[field] = value;
      }

      return {
        ...state,
        settings,
      };
    }
    case 'CREATE_IMPORT': {
      const importData = action.payload
        ? [action.payload, ...state.imports.data]
        : [...state.imports.data];

      return {
        ...state,
        imports: {
          ...state.imports,
          data: importData,
        },
      };
    }
    case 'CLEAN_IMPORT': {
      return initialState;
    }

    case 'GET_ALL_IMPORTS': {
      const { data, meta } = action.payload;

      return {
        ...state,
        imports: {
          ...state.imports,
          data,
          ...meta,
        },
      };
    }

    case 'GET_SINGLE_IMPORT': {
      const data = action.payload;

      if (!data) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        imports: {
          ...state.imports,
          data: [...state.imports.data, data],
          totalItems: state.imports.totalItems + 1,
        },
      };
    }

    case 'SET_DEFAULT_IMPORT_SETTING': {
      const step = action.data;
      const updatedState = clearStep(step, state, initialState);

      return {
        ...updatedState,
      };
    }

    case ACCOUNT_EVENTS.IMPORT_EVENT: {
      try {
        const data = JSON.parse(action.payload);

        if (!Object.keys(data).length) {
          return {
            ...state,
          };
        }
        const oldImportIndex = state.imports.data.findIndex((importItem) => importItem._id === data.importId);

        if (oldImportIndex === -1) {
          return {
            ...state,
          };
        }
        const updatedImport = {
          ...state.imports.data[oldImportIndex],
          newRecords: data.newRecords,
          errorsCount: data.errorsCount,
          errorFileUrl: data.errorFileUrl,
          updatedRecords: data.updatedRecords,
          status: get(data, 'status', state.imports.data[oldImportIndex].status),
        };

        const importsData = [...state.imports.data];
        importsData.splice(oldImportIndex, 1, updatedImport);

        return {
          ...state,
          imports: {
            ...state.imports,
            data: importsData,
          },
        };
      } catch (e) {
        return {
          ...state,
        };
      }
    }

    case 'GET_SUGGESTED_IMPORTS': {
      return {
        ...state,
        suggestedImports: action.payload,
      };
    }

    case 'GET_IMPORT': {
      return {
        ...state,
        currentImport: action.payload || action.data,
      };
    }

    case 'SIGN_OUT':
    case 'SWITCH_WORKSPACE': {
      return initialState;
    }

    default:
      return state;
  }
};
