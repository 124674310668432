import styled from 'styled-components';
import { Radio } from 'antd';
import { COLORS } from 'constants/styles';
import {
  RadioGroup,
} from 'components/common';

export const Title = styled.div`
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.BLACK_G1};
`;

export const Description = styled.div`
  color: ${COLORS.GREY_G2};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0 20px 0;
  display: flex;
  align-items: center;

  span {
    color: ${COLORS.BLACK_G1};
    margin: 0 6px;
  }
`;

export const RadioGroupContainer = styled(RadioGroup)`
`;

export const PeriodRadio = styled(Radio.Button)`
`;

export const Footer = styled.div`
  background-color: ${COLORS.GREY_G5};
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  margin: 0 -24px -24px -24px;
  padding: 20px;

  button {
    height: 42px;
    width: 100%;
    font-size: 13px !important;
  }
`;

export const BenefitsContainer = styled.div`
  margin-bottom: 30px;
`;

export const Benefit = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.GREY_G1};

  span {
    font-size: 20px !important;
    margin-right: 12.5px;
  }
`;

export const InputNumberContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  padding-right: 20px;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: flex-end;

  .ant-input-number {
    margin-left: 10px;
    min-width: 115px;
  }
`;
