export const getAllTeammates = () => (dispatch) => {
  dispatch({
    type: 'GET_ALL_TEAMMATES',
    payload: {
      endpoint: 'api/v1/team/all',
      method: 'GET',
      delayLoad: 0,
    },
    components: ['getTeammatesLoader'],
  });
};

export const getTeammates = ({
  onSuccess = () => {},
  onFailed = () => {},
} = {}) => (dispatch, getState) => {
  const state = getState();
  const { page, pageSize } = state.teammates.teammates;
  const params = { pageNumber: page, pageSize };

  dispatch({
    type: 'GET_TEAMMATES',
    payload: {
      endpoint: 'api/v1/team',
      method: 'GET',
      params,
    },
    onSuccess,
    onFailed,
  });
};

export const setTeammatesParams = ({
  onSuccess = () => {},
  onFailed = () => {},
  page, pageSize,
} = {}) => (dispatch, getState) => {
  const state = getState();
  const currentPage = state.teammates.teammates.page;
  const currentPageSize = state.teammates.teammates.pageSize;

  const params = {
    page: page || currentPage,
    pageSize: pageSize || currentPageSize,
  };

  dispatch({
    type: 'SET_TEAMMATES_PARAMS',
    data: params,
    onSuccess,
    onFailed,
  });
  dispatch(getTeammates());
};

export const updateTeammate = ({
  onSuccess = () => {},
  onFailed = () => {},
  relationId = '',
  options,
}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_TEAMMATE',
    payload: {
      endpoint: `api/v1/team/relation/${relationId}`,
      method: 'PUT',
      body: options,
    },
    relationId,
    options,
    onSuccess,
    onFailed,
  });
};

export const updateRelationByUser = ({
  onSuccess = () => {},
  onFailed = () => {},
  options,
} = {}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_USER_RELATION',
    payload: {
      endpoint: 'api/v1/team/relation',
      method: 'PUT',
      body: options,
    },
    options,
    onSuccess,
    onFailed,
  });
};

export const confirmRelation = ({
  onSuccess = () => {},
  onFailed = () => {},
  relationId,
} = {}) => (dispatch) => {
  dispatch({
    type: 'CONFIRM_RELATION',
    payload: {
      endpoint: `api/v1/team/relation/confirm/${relationId}`,
      method: 'PUT',
    },
    onSuccess,
    onFailed,
  });
};

export const getRelationByUser = ({
  onSuccess = () => {},
  onFailed = () => {},
} = {}) => (dispatch) => {
  dispatch({
    type: 'GET_RELATION',
    payload: {
      endpoint: 'api/v1/team/relation',
      method: 'GET',
      delayLoad: 0,
    },
    components: ['relation'],
    onSuccess,
    onFailed,
  });
};

export const addTeammate = ({
  onSuccess = () => {},
  onFailed = () => {},
  teammate,
} = {}) => (dispatch) => {
  const handleOnSuccess = (val) => {
    dispatch(setTeammatesParams({ page: 1 }));
    onSuccess(val);
  };

  dispatch({
    type: 'ADD_TEAMMATE',
    payload: {
      endpoint: 'api/v1/team',
      method: 'POST',
      body: {
        teammate,
      },
    },
    onSuccess: handleOnSuccess,
    onFailed,
  });
};

export const deleteTeammate = ({
  onSuccess = () => {},
  onFailed = () => {},
  relationId = '',
} = {}) => (dispatch, getState) => {
  const state = getState();
  const { data, page: currentPage } = state.teammates.teammates;

  const onSuccessDefault = () => {
    const page = (data.length - 1) ? currentPage : currentPage - 1;
    dispatch(setTeammatesParams({ page }));
    onSuccess();
  };

  dispatch({
    type: 'DELETE_TEAMMATE',
    payload: {
      endpoint: `api/v1/team/${relationId}`,
      method: 'DELETE',
    },
    relationId,
    onSuccess: onSuccessDefault,
    onFailed,
  });
};

export const deleteAllTeammates = ({
  onSuccess = () => {},
  onFailed = () => {},
} = {}) => (dispatch) => {
  const defaultOnSuccess = () => {
    dispatch(setTeammatesParams({ page: 1 }));
    onSuccess();
  };

  dispatch({
    type: 'DELETE_ALL_TEAMMATES',
    payload: {
      endpoint: 'api/v1/team/all',
      method: 'DELETE',
    },
    onSuccess: defaultOnSuccess,
    onFailed,
  });
};
