import get from 'lodash/get';
import { nanoid } from 'nanoid';

const initialState = {
  operations: [],
  fireTrigger: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_OPERATION': {
      const newOperation = action.data;
      newOperation.id = nanoid();
      const alreadyCreatedOperation = state.operations.find(({ type, source, from }) => type === get(newOperation, 'type')
        && source === get(newOperation, 'source')
        && from === get(newOperation, 'from'));

      if (alreadyCreatedOperation) {
        return state;
      }

      return {
        ...state,
        operations: [...state.operations, { ...action.data }],
      };
    }
    case 'REMOVE_OPERATION': {
      const { data } = action;
      const updatedOperation = state.operations.filter(({ id }) => id !== get(data, 'id'));

      return {
        ...state,
        operations: updatedOperation,
      };
    }
    case 'FIRE_TRIGGER': {
      return {
        ...state,
        fireTrigger: action.data,
      };
    }
    case 'CLEAR_TRIGGER': {
      const updatedOperations = state.operations.filter(({ id }) => id !== state.fireTrigger);
      return {
        ...state,
        fireTrigger: initialState.fireTrigger,
        operations: updatedOperations,
      };
    }
    default: return state;
  }
};
