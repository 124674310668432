import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';

import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import {
  selectedAllContactsSelector, selectedContactsSelector, totalContactsSelector, contactsSelector,
} from 'data/selectors/contacts';
import {
  setSelectedContacts,
  setContactsParams,
} from 'data/actions/contacts';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { deleteContactsFromStaticList } from 'data/actions/lists';
import {
  ModalTitle, CloseIcon, Footer,
} from '../baseModal/styles';

const DeleteFromStaticList = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const contacts = useSelector(selectedContactsSelector);
  const totalContacts = useSelector(totalContactsSelector);
  const selectAllContacts = useSelector(selectedAllContactsSelector);
  const { listId, setSelectedRowKeys } = useSelector(modalOptionsSelector);
  const tableData = useSelector(contactsSelector);

  const refreshContacts = () => {
    dispatch(setContactsParams({
      listId,
      page: 1,
      findCount: true,
      pageSize: tableData?.pageSize ?? 25,
      components: ['contactsListTable'],
    }));
  };

  const handleClose = () => dispatch(toggleModal('delete_from_static_list', false));

  const handleDelete = () => {
    const contactsIds = contacts;
    dispatch(deleteContactsFromStaticList({
      objectIdList: contactsIds,
      listIds: [listId],
      onSuccess: (data) => {
        if (!data.pending) {
          setSelectedContacts([]);
          refreshContacts();
        }
        if (setSelectedRowKeys) setSelectedRowKeys([]);
      },
      onFailed: () => dispatch(toggleMessage('error', { header: 'Something went wrong' })),
    }));
    handleClose();
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Bulk delete contacts from the list
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        You&#39;re about to delete
        {' '}
        {selectAllContacts ? totalContacts : contacts.length}
        {' '}
        contacts from the list.
        &nbsp;
      </div>
      <Footer>
        <Button
          data-form="true"
          type="danger"
          size="large"
          onClick={handleDelete}
        >
          Delete
        </Button>
        <Button
          size="large"
          type="default"
          onClick={handleClose}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default DeleteFromStaticList;
