export const CancelBtnStyles = {
  left: 0,
};

export const CloseImportSearch = {
  height: 'auto',
  width: 'auto',
  padding: 0,
  margin: 0,
  border: 'none',
};

export const FooterContainerStyles = {
  borderTop: 'none',
  backgroundColor: 'transparent',
};
