import get from 'lodash/get';
import jwt from 'jsonwebtoken';

import { AWS_FILE_TYPES } from 'data/types/aws.types';
import { getFingerPrintPro } from 'helpers/getFingerPrint';
import { setLastWorkspaceId } from 'helpers/setLastWorkspaceId';
import { createEmailSearchFilter } from 'helpers/filterFactory';
import { EMAIL_FILTER_STATUS } from 'data/types/search.filters.types';
import { CLOSE_EVENT_SOURCE_CONNECTION } from 'data/types/event.types';
import { demoUserSelector, workspaceIdSelector } from 'data/selectors/user';
import { sendEventLoginAnalytic, sendEventSignUpAnalytic } from 'helpers/googleAnalytics';
import checkChromeExtension from 'helpers/checkChromeExtension';
import { logoutPlugin } from 'helpers/pluginAuthorization';
import { ASYNC_BOOLEAN } from 'data/types/progress.types';

import { checkFSInvoice, getInvoices } from './invoices';
import { toggleMessage } from './message';
import { setSavedSearchFilter } from './search';

export const getUserSettings = (components) => (dispatch) => {
  dispatch({
    type: 'GET_USER_SETTINGS',
    payload: {
      endpoint: 'api/v1/user/settings',
      method: 'GET',
    },
    components,
  });
};

export const updateUserSettings = (settings, onSuccess = () => {}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_USER_SETTINGS',
    payload: {
      endpoint: 'api/v1/user/settings',
      method: 'PATCH',
      body: settings,
    },
    onSuccess,
  });
};

export const getWorkspaceActivity = ({ onSuccess = () => {}, onFailed = () => {} }) => (dispatch) => {
  dispatch({
    type: 'GET_WORKSPACE_ACTIVITY',
    payload: {
      endpoint: 'api/v1/user/account/activity',
      method: 'GET',
    },
    onSuccess,
    onFailed,
  });
};

export const updateWorkspaceActivity = (body) => (dispatch) => {
  dispatch({
    type: 'UPDATE_WORKSPACE_ACTIVITY',
    payload: {
      endpoint: 'api/v1/user/account/activity',
      method: 'POST',
      body,
    },
  });
};

export const addGroupsToPreviewConfiguration = (groups, sourceType) => (dispatch) => {
  dispatch({
    type: 'ADD_GROUPS_TO_PREVIEW_CONFIGURATION',
    data: {
      groups,
      sourceType,
    },
  });
};

export const deleteGroupsFromPreviewConfiguration = (groupNames, sourceType) => (dispatch) => {
  dispatch({
    type: 'DELETE_GROUPS_FROM_PREVIEW_CONFIGURATION',
    data: {
      groupNames,
      sourceType,
    },
  });
};

export const updateWorkspaceSettings = (settings, onSuccess = () => {}, onFailed = () => {}) => (dispatch, getStore) => {
  const { columnsConfiguration, previewConfiguration } = settings;

  if (columnsConfiguration) {
    settings.columnsConfiguration = {
      contacts: columnsConfiguration.contacts,
      companies: columnsConfiguration.companies,
    };
  }

  if (previewConfiguration) {
    const store = getStore();
    const contacts = get(previewConfiguration, 'contacts', get(store, 'user.settings.previewConfiguration.contacts'));
    const companies = get(previewConfiguration, 'companies', get(store, 'user.settings.previewConfiguration.companies'));
    if (!contacts || !companies) onFailed(new Error("Some settings aren't found"));
    settings.previewConfiguration = {
      contacts,
      companies,
    };
  }

  dispatch({
    type: 'UPDATE_USER_SETTINGS',
    payload: {
      endpoint: 'api/v1/user/settings/workspace',
      method: 'PATCH',
      body: settings,
      showResponseError: true,
    },
    onFailed,
    onSuccess,
  });
};

export const updateWorkspaceNotifications = (settings, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_USER_SETTINGS',
    payload: {
      endpoint: 'api/v1/user/settings/workspace/notifications',
      method: 'PATCH',
      body: settings,
      showResponseError: true,
    },
    onFailed,
    onSuccess,
  });
};

export const signIn = ({
  email, password, isRememberMe, components, workspaceId,
  onFailed = () => {},
  onSuccess = () => {},
} = {}) => async (dispatch) => {
  const fingerPrint = await getFingerPrintPro();
  const handleOnSuccess = (data) => {
    localStorage.setItem('accessToken', get(data, 'accessToken'));
    localStorage.setItem('refreshToken', get(data, 'refreshToken'));
    sessionStorage.removeItem('closedCreditsBar');
    onSuccess(data);
    sendEventLoginAnalytic('email');
  };

  dispatch({
    type: 'SIGN_IN',
    payload: {
      endpoint: 'auth/sign-in',
      method: 'POST',
      skipAuth: true,
      delayLoad: 0,
      body: {
        email: email.trim().toLowerCase(),
        password,
        workspaceId,
        isRememberMe,
        fingerPrint,
      },
      ignore401: true,
      showResponseError: (err) => (err?.response?.data?.statusCode === 401),
    },
    components,
    onSuccess: handleOnSuccess,
    onFailed,
  });
};

export const signUp = ({
  email,
  token,
  password,
  firstName,
  lastName,
  phone,
  affiliate,
  onFailed,
  utmCampaign,
  utmTerm,
  utmSource,
  utmMedium,
  relationToken,
  pluginInstalled,
  gaClientId,
  utmPage,
  captchaToken,
  gp_group, // eslint-disable-line
}) => async (dispatch) => {
  const signUpFingerPrint = await getFingerPrintPro();
  dispatch({
    type: 'SIGN_UP',
    payload: {
      endpoint: 'auth/sign-up',
      method: 'POST',
      skipAuth: true,
      body: {
        relationToken,
        email: email.trim().toLowerCase(),
        password,
        firstName,
        lastName,
        token,
        phone,
        affiliate,
        utmCampaign,
        utmTerm,
        utmSource,
        utmMedium,
        signUpFingerPrint,
        pluginInstalled,
        gaClientId,
        utmPage,
        gp_group,
        captchaToken,
      },
      delayLoad: 0,
      ignore401: true,
      showResponseError: true,
    },
    components: ['signUp'],
    onSuccess: (data) => {
      localStorage.setItem('accessToken', get(data, 'accessToken'));
      localStorage.removeItem('GP_group');
      const tokenData = jwt.decode(get(data, 'accessToken'));
      const showLeadTour = get(tokenData, 'showLeadTour');
      sendEventSignUpAnalytic('email', tokenData?._id);

      if (showLeadTour) {
        localStorage.setItem('showLeadTour', showLeadTour);
      }
    },
    onFailed,
  });
};

export const checkEmail = ({ email, onSuccess, onFailed }) => (dispatch) => {
  dispatch({
    type: 'CHECK_EMAIL',
    payload: {
      endpoint: `auth/email/${email.trim().toLowerCase()}`,
      method: 'HEAD',
      skipAuth: true,
      delayLoad: 0,
    },
    components: ['emailCheck'],
    onSuccess: () => onSuccess?.(),
    onFailed: (err) => onFailed?.(err),
  });
};

export const checkPhone = ({
  phone, onSuccess, onFailed,
}) => (dispatch) => {
  dispatch({
    type: 'CHECK_PHONE_EXIST',
    payload: {
      endpoint: 'api/v1/user/account/check-phone-exist',
      method: 'POST',
      skipAuth: true,
      body: {
        phone,
      },
      delayLoad: 0,
    },
    components: ['verifyToken'],
    onSuccess: () => onSuccess?.(),
    onFailed: (err) => onFailed?.(err),
  });
};

export const verifyPhone = ({
  phone, onSuccess, onFailed,
}) => (dispatch, getState) => {
  const state = getState();
  const accessToken = get(state, 'user.auth.token');
  dispatch({
    type: 'VERIFY_PHONE',
    payload: {
      endpoint: 'api/v1/user/account/verify-phone',
      method: 'POST',
      body: {
        phone,
        email: accessToken ? jwt.decode(accessToken)?.email : undefined,
      },
      delayLoad: 0,
    },
    components: ['verifyPhone'],
    onSuccess: (data) => onSuccess?.(data),
    onFailed: (err) => onFailed?.(err),
  });
};

export const getUserInfo = ({
  data,
  onSuccess = () => {},
  onFailed = () => {},
}) => async (dispatch) => {
  const fingerPrint = await getFingerPrintPro();

  dispatch({
    type: 'GET_USER_INFO',
    payload: {
      endpoint: 'auth/info',
      method: 'POST',
      body: {
        ...data,
        fingerPrint,
      },
    },
    onSuccess,
    onFailed,
  });
};

export const verifyToken = ({
  token, verificationCode, onSuccess, onFailed,
}) => (dispatch) => {
  dispatch({
    type: 'VERIFY_TOKEN',
    payload: {
      endpoint: 'api/v1/user/account/verify-token',
      method: 'POST',
      body: {
        token,
        verificationCode,
      },
      delayLoad: 0,
      showResponseError: (err) => get(err, 'response.data.statusCode', '') === 429,
    },
    components: ['verifyToken'],
    onSuccess: (data) => onSuccess?.(data),
    onFailed: (err) => onFailed?.(err),
  });
};

export const verifyTokenUnauthorized = ({
  token, verificationCode, onSuccess, onFailed,
}) => (dispatch) => {
  dispatch({
    type: 'VERIFY_TOKEN_UNAUTHORIZED',
    payload: {
      endpoint: 'api/v1/user/account/verify-token-unauthorized',
      method: 'POST',
      body: {
        token,
        verificationCode,
      },
      delayLoad: 0,
      showResponseError: (err) => get(err, 'response.data.statusCode', '') === 429,
    },
    components: ['verifyToken'],
    onSuccess: (data) => onSuccess?.(data),
    onFailed: (err) => onFailed?.(err),
  });
};

export const signOut = (onEmptyRefreshToken = () => {}, onSuccess = () => {}) => (dispatch) => {
  const removeTokens = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('adminTempSession');
    localStorage.removeItem('closeChromeBlackFridayBanner');
    localStorage.removeItem('closeChromeExtensionNotification');
    onSuccess();
  };

  const exitPlugin = async () => {
    const isInstalled = await checkChromeExtension();
    if (isInstalled === ASYNC_BOOLEAN.TRUE) {
      logoutPlugin();
    }
  };

  const closeEventSourceConnections = new Event(CLOSE_EVENT_SOURCE_CONNECTION);
  window.dispatchEvent(closeEventSourceConnections);

  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    dispatch({
      type: 'SIGN_OUT',
      data: {},
    });
    removeTokens();
    onEmptyRefreshToken();
    exitPlugin();
    return;
  }

  dispatch({
    type: 'SIGN_OUT',
    payload: {
      endpoint: 'auth/sign-out',
      method: 'POST',
      body: {
        refreshToken,
      },
    },
    onSuccess: () => {
      removeTokens();
      exitPlugin();
    },
    onFailed: () => {
      dispatch({
        type: 'SIGN_OUT',
        data: {},
      });
      removeTokens();
      exitPlugin();
    },
  });
};

export const getAccount = (delayLoad = 1000, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'GET_ACCOUNT',
    payload: {
      endpoint: 'api/v1/user/account',
      method: 'GET',
      delayLoad,
    },
    onSuccess,
    onFailed,
    components: ['accountLoader', 'unconfirmedPhonePage'],
  });
};

export const emailResetPassword = ({ email, onSuccess, onFailed }) => (dispatch) => {
  dispatch({
    type: 'EMAIL_RESET_PASSWORD',
    payload: {
      endpoint: 'api/v1/user/account/password/reset/send',
      method: 'POST',
      skipAuth: true,
      body: {
        email,
      },
      delayLoad: 0,
    },
    components: ['resetPasswordPage'],
    onSuccess,
    onFailed,
  });
};

export const updateAccount = (fields, onSuccess = () => {}, onFailed = () => {}, updateState = true) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ACCOUNT',
    payload: {
      endpoint: 'api/v1/user/account',
      method: 'PATCH',
      body: { fields },
    },
    updateState,
    onSuccess: (data) => onSuccess(data),
    onFailed: (data) => onFailed(data),
  });
};

export const setSignUpFingerPrint = () => async (dispatch) => {
  const signUpFingerPrint = await getFingerPrintPro();
  dispatch(updateAccount([{ signUpFingerPrint }]));
};

export const changePassword = (passwords) => (dispatch) => {
  dispatch({
    type: 'CHANGE_PASSWORD',
    payload: {
      endpoint: 'api/v1/user/account/password/change',
      method: 'POST',
      body: passwords,
    },
    // TODO: remove?
    onSuccess: () => dispatch(toggleMessage('success', { header: 'Your password updated' })),
    onFailed: () => dispatch(toggleMessage('error', { header: 'Change password error', text: 'Please try again' })),
  });
};

export const resetPasswordWithToken = ({
  newPassword, token, onSuccess, onFailed,
}) => (dispatch) => {
  dispatch({
    type: 'RESET_PASSWORD_WITH_TOKEN',
    payload: {
      skipAuth: true,
      endpoint: `api/v1/user/account/password/reset/${token}`,
      method: 'PATCH',
      body: { newPassword },
    },
    onSuccess,
    onFailed,
  });
};

export const checkPasswordWithToken = ({
  token, onSuccess, onFailed,
}) => (dispatch) => {
  dispatch({
    type: 'CHECK_PASSWORD_WITH_TOKEN',
    payload: {
      skipAuth: true,
      endpoint: `api/v1/user/account/password/reset/${token}`,
      method: 'HEAD',
    },
    onSuccess,
    onFailed,
  });
};

export const resendVerifyEmail = (email) => (dispatch) => {
  dispatch({
    type: 'RESEND_VERIFY_EMAIL',
    payload: {
      endpoint: `api/v1/user/account/verify/resend/${email}`,
      method: 'GET',
    },
  });
};

export const skipLastStep = (showLeadTour = false) => (dispatch) => {
  dispatch({
    type: 'SKIP_LAST_STEP',
    data: {
      showLeadTour,
    },
  });
};

export const verifyAccountByCode = ({ code, onSuccess = () => {}, onFailed = () => {} }) => (dispatch) => {
  dispatch({
    type: 'VERIFY_ACCOUNT_BY_CODE',
    payload: {
      endpoint: `api/v1/user/account/verify/code/${code}`,
      method: 'GET',
      delayLoad: 0,
    },
    components: ['verifyAccountByCodeLoader'],
    onSuccess,
    onFailed,
  });
};

export const userAccountSessions = ({ components }) => (dispatch) => {
  dispatch({
    type: 'USER_ACCOUNT_SESSIONS',
    payload: {
      endpoint: 'api/v1/user/account/sessions',
      method: 'GET',
    },
    components,
  });
};

export const userSessionDisconnect = (sessionId) => (dispatch) => {
  dispatch({
    type: 'USER_SESSION_DISCONNECT',
    payload: {
      endpoint: 'auth/sessions/disconnect',
      method: 'POST',
      body: {
        sessionId,
      },
    },
    sessionId,
  });
};

export const userAffiliates = () => (dispatch) => {
  dispatch({
    type: 'GET_USER_AFFILIATES',
    payload: {
      endpoint: 'api/v1/user/account/affiliates',
      method: 'GET',
    },
    components: ['referredUsers'],
  });
};

export const userUploadAvatar = ({ file }) => (dispatch) => {
  const fileType = get(file, 'meta.type', '');

  dispatch({
    type: 'GET_AVATAR_PRE_SIGNED',
    payload: {
      endpoint: 'aws/pre-signed',
      method: 'POST',
      body: {
        contentType: fileType,
        preSignedType: AWS_FILE_TYPES.AVATAR,
      },
    },
    file,
  });
};

export const loadAvatarToS3 = ({
  file, bucketUrl, fields,
}) => (dispatch) => {
  const form = new FormData();
  Object.keys(fields).forEach((key) => {
    form.append(key, fields[key]);
  });

  form.append('file', get(file, 'data', null), get(file, 'meta.name', ''));

  dispatch({
    type: 'LOAD_AVATAR_TO_S3',
    payload: {
      custom: true,
      endpoint: bucketUrl,
      method: 'POST',
      body: form,
    },
  });
};

export const removeAvatarAction = () => (dispatch) => {
  dispatch({
    type: 'REMOVE_USER_AVATAR',
    data: 'remove avatar',
  });
};

export const deleteUser = (onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'DELETE_USER',
    payload: {
      endpoint: 'api/v1/user/account',
      method: 'DELETE',
      showResponseError: true,
      delayLoad: 0,
    },
    onFailed,
    onSuccess,
    components: ['deleteUserLoader'],
  });
};

export const updateLocalSettings = (data) => (dispatch) => {
  dispatch({
    type: 'UPDATE_LOCAL_SETTINGS',
    data,
  });
};

export const getUserCredits = ({
  components = ['userCredits'],
}) => (dispatch) => {
  dispatch({
    type: 'GET_USER_CREDITS',
    payload: {
      endpoint: 'api/v1/user/credits',
      method: 'GET',
      retries: 2,
    },
    components,
  });
};

export const toggleCollapse = ({ step, group }) => (dispatch) => {
  dispatch({
    type: 'TOGGLE_COLLAPSE',
    data: { step, group },
  });
};

export const getUserCreditsHistory = ({
  components = [], params = { page: 1, pageSize: 10 }, type, startDate, endDate, workspaces = [], users = [],
}) => (dispatch) => {
  dispatch({
    type: 'GET_USER_CREDITS_HISTORY',
    payload: {
      endpoint: 'api/v1/user/credits/history',
      method: 'POST',
      params,
      body: {
        type, startDate, endDate, workspaces, users,
      },
    },
    components,
  });
};

export const getUserWorkspaces = () => (dispatch, getState) => {
  const state = getState();
  const isDemoUser = demoUserSelector(state);
  const workspaceId = workspaceIdSelector(state);

  dispatch({
    type: 'GET_USER_WORKSPACES',
    payload: {
      endpoint: 'api/v1/user/account/workspaces',
      method: 'GET',
      delayLoad: 0,
    },
    onSuccess: (workspaces) => {
      if (isDemoUser) { return; }
      const foundWorkspace = workspaces.find((item) => item.workspaceId === workspaceId);
      const validOnly = !foundWorkspace?.showAcceptAllEmails;
      const filters = [];
      if (validOnly) {
        filters.push(createEmailSearchFilter(EMAIL_FILTER_STATUS.VALID));
      } else {
        filters.push(createEmailSearchFilter(EMAIL_FILTER_STATUS.ALL_CONTACTS));
      }
      dispatch(setSavedSearchFilter({
        filters, initRecentActivities: false, saveExisting: true,
      }));
      dispatch(getInvoices({ pageNumber: 1 }));
      dispatch(checkFSInvoice());
    },
    components: ['userWorkspacesLoading'],
  });
};

export const addNewWorkspace = ({ name, onSuccess = () => {}, onFailed = () => {} }) => (dispatch) => {
  dispatch({
    type: 'ADD_NEW_WORKSPACE',
    payload: {
      endpoint: 'api/v1/user/account/workspaces',
      method: 'POST',
      body: { name },
    },
    onSuccess,
    onFailed,
  });
};

export const switchWorkspace = ({ workspaceId, onSuccess = () => {}, onFailed }) => (dispatch, getState) => {
  const { user: { account: { email } } } = getState();
  if (email?.length && workspaceId) setLastWorkspaceId(email, workspaceId);
  dispatch({
    type: 'SWITCH_WORKSPACE',
    payload: {
      endpoint: `api/v1/user/account/workspaces/${workspaceId || 0}`,
      method: 'PATCH',
    },
    onSuccess: (data) => {
      localStorage.setItem('accessToken', data.token);
      onSuccess(data);
      dispatch(getAccount());
      dispatch(getUserSettings(['userSettingsLoader']));
    },
    onFailed,
  });
};

export const addWorkspace = (workspace) => (dispatch) => {
  dispatch({
    type: 'ADD_NEW_WORKSPACE',
    data: workspace,
  });
};

export const setShowCreditsBar = (value) => (dispatch) => dispatch({
  type: 'SET_SHOW_CREDITS_BAR',
  data: { value },
});

export const setQuestionAnswer = (answer, type) => (dispatch) => dispatch({
  type: 'SET_QUESTION_ANSWER',
  data: { answer, type },
});

export const saveQualification = (onSuccess) => (dispatch, getState) => {
  const state = getState();
  const questions = get(state, 'user.questions');

  dispatch({
    type: 'SAVE_QUALIFICATION',
    payload: {
      endpoint: 'api/v1/user/qualification',
      method: 'POST',
      body: { questions },
    },
    onSuccess,
  });
};

export const getCreditsAnalytics = (body = {}, components = ['usagePageAnalytics']) => (dispatch) => dispatch({
  type: 'GET_CREDITS_ANALYTICS',
  payload: {
    endpoint: 'api/v1/user/credits/analytics',
    method: 'POST',
    body,
  },
  components,
});

export const getUsersWorkspaces = () => (dispatch) => {
  dispatch({
    type: 'GET_USERS_WORKSPACES',
    payload: {
      endpoint: 'api/v1/user/account/workspaces/users',
      method: 'GET',
    },
    components: ['usersWorkspaces'],
  });
};
export const getWorkSpaceAnalytics = (workspaceIds) => (dispatch) => {
  dispatch({
    type: 'GET_WORKSPACE_ANALYTICS',
    payload: {
      endpoint: 'api/v1/user/account/workspace-analytics',
      method: 'POST',
      body: {
        workspaceIds,
      },
    },
    components: ['workspaceAnalytics'],
  });
};

export const renameWorkspace = ({
  workspaceId,
  name,
  onSuccess = () => {},
  onFailed = () => {},
}) => (dispatch) => {
  dispatch({
    type: 'RENAME_WORKSPACE_BY_ID',
    payload: {
      endpoint: `api/v1/user/account/workspaces/update/${workspaceId}`,
      method: 'PATCH',
      body: {
        name,
      },
    },
    onSuccess,
    onFailed,
  });
};

export const showAcceptAllEmailsWorkspace = (showAcceptAllEmails) => (dispatch, getState) => {
  const state = getState();
  const workspaceId = workspaceIdSelector(state);
  dispatch({
    type: 'SHOW_ACCEPT_ALL_EMAILS_WORKSPACE',
    payload: {
      endpoint: `api/v1/user/account/workspaces/update/${workspaceId}`,
      method: 'PATCH',
      body: {
        showAcceptAllEmails,
      },
    },
  });
};

export const deleteWorkspace = (workspaceId, onSuccess = () => {}, onError = () => {}) => (dispatch) => {
  dispatch({
    type: 'DELETE_SINGLE_WORKSPACE',
    payload: {
      endpoint: `api/v1/user/account/workspaces/${workspaceId}`,
      method: 'DELETE',
    },
    workspaceId,
    onSuccess,
    onError,
  });
};

export const getWorkspaceBillingInfo = () => (dispatch) => {
  dispatch({
    type: 'GET_WORKSPACE_BILLING_INFO',
    payload: {
      endpoint: 'api/v1/user/account/workspaces/billing',
      method: 'GET',
    },
  });
};

export const updateWorkspaceBillingInfo = (data) => (dispatch) => {
  dispatch({
    type: 'UPDATE_Workspace_BILLING_INFO',
    payload: {
      endpoint: 'api/v1/user/account/workspaces/billing/update',
      method: 'POST',
      body: data,
    },
  });
};

export const applyAffiliateCode = (couponCode, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: 'APPLY_AFFILIATE_CODE',
    payload: {
      endpoint: `api/v1/user/account/apply/${couponCode}`,
      method: 'GET',
    },
    onSuccess,
    onFailed,
  });
};

export const changeUserStatus = (status) => (dispatch) => {
  dispatch({
    type: 'CHANGE_USER_STATUS',
    data: { status },
  });
};

export const checkUserPayment = (paymentInfo, onAllowed, showVerifyAccount, onSuccess = () => {}) => (dispatch) => {
  dispatch({
    type: 'CHECK_USER_PAYMENT',
    payload: {
      endpoint: 'api/v1/user/account/payment',
      method: 'POST',
      body: paymentInfo,
      showResponseError: (err) => get(err, 'response.status', '') !== 403,
    },
    onSuccess: (allowed) => {
      onSuccess();
      if (allowed) onAllowed();
      else showVerifyAccount();
    },
    onFailed: () => dispatch(toggleMessage('error', { header: 'Failed to get purchase limit info' })),
  });
};

export const requireVerification = (paymentInfo) => (dispatch) => {
  dispatch({
    type: 'REQUIRE_USER_VERIFICATION',
    payload: {
      endpoint: 'api/v1/user/account/verification',
      method: 'POST',
      body: paymentInfo,
      showResponseError: true,
    },
  });
};

export const changeWorkspaceAdmin = ({
  workspaceId,
  email,
  saveBillingInfo,
  onSuccess = () => {},
  onFailed = () => {},
}) => (dispatch) => {
  dispatch({
    type: 'CHANGE_WORKSPACE_ADMIN',
    payload: {
      endpoint: `api/v1/user/account/workspaces/update/${workspaceId}`,
      method: 'PATCH',
      body: {
        email,
        saveBillingInfo,
      },
    },
    onSuccess,
    onFailed,
  });
};

export const sendGoogleAnalyticsSignup = ({
  utmSource, gaClientId, userId,
}) => (dispatch) => {
  const params = {
    v: 1,
    t: 'event',
    tid: 'UA-80458829-1',
    cid: gaClientId,
    ec: 'User',
    ea: 'signup',
    el: utmSource || 'direct',
    uid: userId,
    ds: 'crm',
  };

  const hitPayload = Object.keys(params).map((param) => `${param}=${params[param]}`).join('&');

  dispatch({
    type: 'SEND_GOOGLE_ANALYTICS_MESSAGE',
    payload: {
      custom: true,
      endpoint: 'https://www.google-analytics.com/collect',
      method: 'POST',
      body: hitPayload,
    },
  });
};

export const googleAuth = ({
  code,
  tokenId,
  affiliate,
  utmCampaign,
  utmTerm,
  utmSource,
  utmMedium,
  gaClientId,
  phone,
  token,
  utmPage,
  gp_group, // eslint-disable-line
  redirectUrl,
  callback = () => {},
}) => async (dispatch) => {
  const signUpFingerPrint = await getFingerPrintPro();
  const signInChannel = new BroadcastChannel('sign_in_channel');
  dispatch({
    type: 'GOOGLE_AUTH',
    payload: {
      endpoint: 'auth/google',
      method: 'POST',
      skipAuth: true,
      body: {
        code,
        tokenId,
        signUpFingerPrint,
        affiliate,
        utmCampaign,
        utmTerm,
        utmSource,
        utmMedium,
        gaClientId,
        phone,
        token,
        utmPage,
        gp_group,
        redirectUrl,
      },
      delayLoad: 0,
      ignore401: true,
      showResponseError: true,
    },
    components: ['googleAuth', 'accountLoader'],
    onSuccess: (data) => {
      if (get(data, 'passwordResetToken')) {
        localStorage.setItem('passwordResetToken', data.passwordResetToken);
      }
      const tokenData = jwt.decode(get(data, 'accessToken'));
      const showLeadTour = get(tokenData, 'showLeadTour');

      if (showLeadTour) {
        localStorage.setItem('showLeadTour', showLeadTour);
      }
      localStorage.setItem('accessToken', get(data, 'accessToken'));
      localStorage.setItem('refreshToken', get(data, 'refreshToken'));
      const isSignUp = data?.signUp || tokenData?.googleSignUp;

      if (isSignUp) sendEventSignUpAnalytic('Google', tokenData?._id);
      else sendEventLoginAnalytic('Google');
      signInChannel.postMessage({ status: 'success' });
      callback(data);
    },
    onFailed: (err) => {
      if (err?.response?.data?.statusCode === 400) {
        let { message } = err.response.data;
        if (!message) message = 'Something went wrong';
        signInChannel.postMessage({ status: 'error', data: message });
      }
    },
  });
};

export const externalGoogleAuth = ({
  code,
  tokenId,
  affiliate,
  utmCampaign,
  utmTerm,
  utmSource,
  utmMedium,
  gaClientId,
  phone,
  token,
  utmPage,
  gp_group, // eslint-disable-line
  redirectUrl,
  callback = () => {},
}) => async (dispatch) => {
  const signUpFingerPrint = await getFingerPrintPro();

  dispatch({
    type: 'EXTERNAL_GOOGLE_AUTH',
    payload: {
      endpoint: 'auth/google',
      method: 'POST',
      skipAuth: true,
      body: {
        code,
        tokenId,
        signUpFingerPrint,
        affiliate,
        utmCampaign,
        utmTerm,
        utmSource,
        utmMedium,
        gaClientId,
        phone,
        token,
        utmPage,
        gp_group,
        redirectUrl,
      },
      delayLoad: 0,
      ignore401: true,
      showResponseError: true,
    },
    components: ['googleAuth', 'accountLoader'],
    onSuccess: (data) => {
      const tokenData = jwt.decode(get(data, 'accessToken'));
      const isSignUp = data?.signUp || tokenData?.googleSignUp;

      if (isSignUp) sendEventSignUpAnalytic('Google', tokenData?._id);
      else sendEventLoginAnalytic('Google');
      callback(data);
    },
  });
};

export const updateWorkspaceLimit = (limit) => (dispatch) => {
  dispatch({
    type: 'UPDATE_WORKSPACE_LIMIT',
    data: {
      limit,
    },
  });
};

export const getWorkspaceLimits = () => (dispatch) => {
  dispatch({
    type: 'GET_WORKSPACE_LIMITS',
    payload: {
      endpoint: 'api/v1/user/account/workspace-limits',
      method: 'GET',
    },
  });
};

export const restoreAccount = (email, token) => (dispatch) => {
  dispatch({
    type: 'RESTORE_ACCOUNT',
    payload: {
      endpoint: `api/v1/user/account/restore/${email}/${token}`,
      method: 'GET',
      skipAuth: true,
    },
    onSuccess: () => dispatch(toggleMessage('success', { header: 'Your account was restored' })),
    onFailed: (err) => dispatch(toggleMessage('error', { header: 'Account restore failed', text: `${get(err, 'response.data.message')}` })),
  });
};

export const setDemoUser = (isDemoUser) => (dispatch) => {
  dispatch({
    type: 'SET_IS_DEMO_USER',
    data: {
      isDemoUser,
    },
  });
};

export const transferTruemailUser = (email) => async (dispatch) => {
  const signUpFingerPrint = await getFingerPrintPro();
  dispatch({
    type: 'TRANSFER_TRUEMAIL_USER',
    payload: {
      endpoint: 'auth/transfer-truemail-user',
      method: 'POST',
      body: {
        email,
        signUpFingerPrint,
      },
      skipAuth: true,
    },
    onSuccess: () => {
      localStorage.removeItem('accessToken');
      localStorage.setItem('refreshToken');
      dispatch(signOut());
    },
  });
};

export const setPluginInformation = ({ installed }) => (dispatch) => {
  dispatch({
    type: 'SET_PLUGIN_INFORMATION',
    data: {
      installed,
    },
  });
};

export const createApiKey = () => (dispatch) => {
  dispatch({
    type: 'CREATE_API_KEY',
    payload: {
      endpoint: 'api/v1/applications',
      method: 'POST',
      delayLoad: 0,
    },
    components: ['createApiKeyLoader'],
  });
};

export const getApiKeys = () => (dispatch) => {
  dispatch({
    type: 'GET_API_KEYS',
    payload: {
      endpoint: 'api/v1/applications',
      method: 'GET',
    },
    components: ['apiKeysLoader'],
  });
};

export const deleteApiKey = (applicationId) => (dispatch) => {
  dispatch({
    type: 'DELETE_API_KEY',
    payload: {
      endpoint: `api/v1/applications/${applicationId}`,
      method: 'DELETE',
      delayLoad: 0,
    },
    applicationId,
    components: ['deleteApiKeyLoader'],
  });
};

export const clearDemoErrorAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_DEMO_ERROR',
    data: {},
  });
};

export const getUserIp = () => (dispatch) => {
  dispatch({
    type: 'GET_USER_IP',
    payload: {
      endpoint: 'api/v1/user/account/request-ip',
      method: 'GET',
    },
  });
};

export const setPricingVisited = () => (dispatch) => {
  dispatch({
    type: 'SET_IS_PRICING_VISITED',
    payload: {
      endpoint: 'api/v1/user/account/pricing-visited',
      method: 'PUT',
    },
  });
};

export const setDeleteAccount = (data = {}) => (dispatch) => {
  dispatch({
    type: 'SET_DELETE_ACCOUNT',
    data,
  });
};

export const resetDeleteAccount = () => (dispatch) => {
  dispatch({
    type: 'RESET_DELETE_ACCOUNT',
    data: {},
  });
};

export const getUserExtension = () => (dispatch) => {
  dispatch({
    type: 'GET_USER_EXTENSION',
    payload: {
      endpoint: 'api/v1/user/account/extension',
      method: 'GET',
    },
    components: ['userExtensionLoader'],
  });
};

export const updateSignUpReport = (body = {}, components = ['']) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SIGN_UP_REPORT',
    payload: {
      endpoint: 'api/v1/analytics/sign-up/report',
      method: 'POST',
      body,
      skipAuth: true,
    },
    components,
  });
};

export const updateSignUpShowLastStep = (isShowLastStep) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SIGN_UP_SHOW_LAST_STEP',
    data: {
      isShowLastStep,
    },
  });
};

export const updateSignUpAdditionalData = (additionalData) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SIGN_UP_ADDITIONAL_DATA',
    data: {
      additionalData,
    },
  });
};

export const checkGoogleSheetsPlugin = () => (dispatch) => {
  dispatch({
    type: 'CHECK_GOOGLE_SHEETS_PLUGIN',
    payload: {
      endpoint: 'google/sheet/plugin',
      method: 'HEAD',
      delayLoad: 0,
    },
    components: ['checkGoogleSheetsPluginLoader'],
    onFailed: () => {
      dispatch({
        type: 'CHECK_GOOGLE_SHEETS_PLUGIN',
        data: {
          error: true,
        },
      });
    },
  });
};

export const getRecentActivity = () => (dispatch) => {
  dispatch({
    type: 'GET_RECENT_ACTIVITY',
    payload: {
      endpoint: 'api/v1/user/recent-activity',
      method: 'GET',
      delayLoad: 0,
    },
  });
};

export const setRecentActivity = (data, source) => (dispatch) => {
  dispatch({
    type: 'SET_RECENT_ACTIVITY',
    data: {
      data,
      source,
    },
  });
};

export const createOrUpdateUserRecentActivity = (data) => (dispatch) => {
  dispatch({
    type: 'CREATE_OR_UPDATE_RECENT_ACTIVITY',
    payload: {
      endpoint: 'api/v1/user/recent-activity',
      method: 'POST',
      delayLoad: 0,
      body: data,
    },
  });
};

export const setExtensionAuth = (active, options = {}) => (dispatch) => {
  dispatch({
    type: 'EXTENSION_AUTH',
    data: {
      active,
      options,
    },
  });
};

export const saveFeedback = (data, onSuccess = () => {}) => (dispatch) => {
  dispatch({
    type: 'SAVE_FEEDBACK',
    payload: {
      endpoint: '/api/v1/feedback/save',
      method: 'POST',
      delayLoad: 0,
      body: data,
    },
    onSuccess,
  });
};

export const updateFeedback = (feedbackId, data) => (dispatch) => {
  dispatch({
    type: 'UPDATE_FEEDBACK',
    payload: {
      endpoint: `/api/v1/feedback/save/${feedbackId}`,
      method: 'PATCH',
      delayLoad: 0,
      body: data,
    },
  });
};

export const getLastNspScoreFeedback = () => (dispatch) => {
  dispatch({
    type: 'GET_LAST_NPS_SCORE_FEEDBACK',
    payload: {
      endpoint: '/api/v1/feedback/nps-score',
      method: 'GET',
    },
    components: ['lastNspScoreFeedback'],
  });
};

export const updateBlackFridayReport = (body = {}, components = ['']) => (dispatch) => {
  dispatch({
    type: 'UPDATE_BLACK_FRIDAY_REPORT',
    payload: {
      endpoint: 'api/v1/analytics/black-friday/report',
      method: 'POST',
      body,
    },
    components,
  });
};

export const closeBlackFridayBanner = () => (dispatch) => {
  dispatch({
    type: 'CLOSE_BLACK_FRIDAY_BANNER',
    data: {},
  });
};
