import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
  Steps,
  Popover,
  Icon,
} from 'antd';

import * as CONSTANTS from 'constants/styles';

import { toggleModal } from 'data/actions/modals';
import { modalsSelector } from 'data/selectors/modals';
import { Checkbox, Button, Input } from 'components/common';
import {
  Footer,
  ButtonStyle,
  StepsContainer,
  SettingsContainer,
  SettingsDescription,
  PopoverStyle,
} from './styles';
import {
  ModalTitle,
  CloseIcon,
} from '../baseModal/styles';
import {
  IconCaretDown,
  MenuItem,
  PopoverTitle,
} from '../../listsOptions/filterOptions/styles';

const { Step } = Steps;
const CreateUser = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);

  const [currentStep, setCurrentStep] = useState(0);
  const [email, setEmail] = useState('');
  const [bulkDelete, setBulkDelete] = useState(false);
  const [importData, setImportData] = useState(true);
  const [exportData, setExportData] = useState(false);
  const [editPropertySettings, setEditPropertySettings] = useState(false);
  const [shareLimits, setShareLimits] = useState(false);
  const [shareLists, setShareLists] = useState(false);
  const [popover, togglePopover] = useState(false);
  const [superUser, setSuperUser] = useState(false);

  const content = [
    <MenuItem
      onClick={
        () => {
          togglePopover(false);
          setSuperUser(!superUser);
        }
      }
    >
      {superUser ? 'Remove super admin' : 'Make super admin'}
    </MenuItem>,
  ];

  const contentWithKeys = content.map((item, index) => ({
    ...item,
    key: `list-options-${index}`,
  }));

  return (
    <Drawer
      title={(
        <ModalTitle>
          Create user
          <CloseIcon
            onClick={() => dispatch(toggleModal('create_user', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      placement="right"
      onClose={() => dispatch(toggleModal('create_user', false))}
      visible={visible}
      width={CONSTANTS.DRAWER_WIDTH}
    >
      <Steps size="default" current={currentStep}>
        <Step title="Email" />
        <Step title="Permissions" />
        <Step title="Invite" />
      </Steps>

      <StepsContainer>
        {currentStep === 0 && (
        <div>
          <h3>
            Create new user, one at a time.
          </h3>
          <p>
            Add a new user to your GetProspect account with an email address.
          </p>
          <Input value={email} placeholder="Enter email" onChange={(event) => { setEmail(event.target.value); }} />
        </div>
        )}

        {currentStep === 1 && (
        <div>
          <PopoverStyle>
            <Popover
              onVisibleChange={togglePopover}
              visible={popover}
              placement="bottom"
              content={contentWithKeys}
              trigger="click"
            >
              <PopoverTitle>
                Options
                <Icon type="caret-down" style={IconCaretDown} />
              </PopoverTitle>
            </Popover>
          </PopoverStyle>

          { superUser && (
          <div>
            <h3>
              Super admins can manage all users, tools, and settings.
            </h3>
            <p>
              Users in this role can access everything except some subscription features.
            </p>
          </div>
          )}
          { !superUser && (
            <>

              <h3>
                Your share options
              </h3>
              <p>
                Your share options is here
              </p>
              <SettingsContainer>
                <div>
                  <b>Share your subscription limits</b>
                  <SettingsDescription>Share your subscription limits.</SettingsDescription>
                </div>
                <Checkbox
                  checked={shareLimits}
                  onChange={(e) => {
                    setShareLimits(e.target.checked);
                  }}
                />
              </SettingsContainer>

              <SettingsContainer>
                <div>
                  <b>Share your lists</b>
                  <SettingsDescription>Share your lists.</SettingsDescription>
                </div>
                <Checkbox
                  checked={shareLists}
                  onChange={(e) => {
                    setShareLists(e.target.checked);
                  }}
                />
              </SettingsContainer>
              <br />
              <br />
              <h3>
                CRM Settings Access
              </h3>
              <p>
                The permissions below apply to contacts, companies, deals, and tickets.
              </p>
              <SettingsContainer>
                <div>
                  <b>Bulk delete</b>
                  <SettingsDescription>Grant the ability to bulk delete contacts, companies, deals, tickets, and tasks.</SettingsDescription>
                </div>
                <Checkbox
                  checked={bulkDelete}
                  onChange={(e) => {
                    setBulkDelete(e.target.checked);
                  }}
                />
              </SettingsContainer>

              <SettingsContainer>
                <div>
                  <b>Import</b>
                  <SettingsDescription>Grant the ability to import contacts, companies, and deals into your GetProspect account.</SettingsDescription>
                </div>
                <Checkbox
                  checked={importData}
                  onChange={(e) => {
                    setImportData(e.target.checked);
                  }}
                />
              </SettingsContainer>

              <SettingsContainer>
                <div>
                  <b>Export</b>
                  <SettingsDescription>Grant the ability to export contacts, companies, and deals from your GetProspect account.</SettingsDescription>
                </div>
                <Checkbox
                  checked={exportData}
                  onChange={(e) => {
                    setExportData(e.target.checked);
                  }}
                />
              </SettingsContainer>

              <SettingsContainer>
                <div>
                  <b>Edit property settings</b>
                  <SettingsDescription>Grant the ability to create or edit properties in your GetProspect account, including lead scoring.</SettingsDescription>
                </div>
                <Checkbox
                  checked={editPropertySettings}
                  onChange={(e) => {
                    setEditPropertySettings(e.target.checked);
                  }}
                />
              </SettingsContainer>
            </>
          )}
        </div>
        )}

        {currentStep === 2 && (
        <div>
          <h3>
            Invite your teammate to GetProspect
          </h3>
          <p>
            Send them a friendly invitation with everything they need to get started.
          </p>
        </div>
        )}
      </StepsContainer>

      <Footer>

        {currentStep === 0 && (
          <>
            <Button
              type="normal"
              size="large"
              style={ButtonStyle}
              onClick={() => dispatch(toggleModal('create_user', false))}
            >
              Cancel
            </Button>
            <Button
              data-form={!email ? 'false' : 'true'}
              type="primary"
              size="large"
              style={ButtonStyle}
              disabled={!email}
              onClick={() => {
                setCurrentStep(currentStep + 1);
              }}
            >
              Next
            </Button>
          </>
        )}

        {currentStep === 1 && (
          <>
            <Button
              type="normal"
              size="large"
              style={ButtonStyle}
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Back
            </Button>
            <Button
              data-form="true"
              type="primary"
              size="large"
              style={ButtonStyle}
              disabled={!email}
              onClick={() => {
                setCurrentStep(currentStep + 1);
              }}
            >
              Next
            </Button>
          </>
        )}

        {currentStep === 2 && (
          <>
            <Button
              type="normal"
              size="large"
              style={ButtonStyle}
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Back
            </Button>
            <Button
              data-form={email ? 'true' : 'false'}
              type="primary"
              size="large"
              style={ButtonStyle}
              disabled={!email}
              onClick={() => {

              }}
            >
              Send
            </Button>
          </>
        )}
      </Footer>
    </Drawer>
  );
};

export default CreateUser;
