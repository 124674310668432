const initialState = {
  bulkOperations: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FIND_ALL_BULK_OPERATIONS':
      return {
        ...state,
        bulkOperations: action.payload,
      };
    case 'CREATE_BULK_OPERATION':
      return {
        ...state,
        bulkOperations: [...state.bulkOperations, action.data.bulkOperation],
      };
    case 'DELETE_BULK_OPERATION':
      return {
        ...state,
        bulkOperations: state.bulkOperations.filter((bulkOperation) => bulkOperation._id !== action.data.bulkOperationId),
      };
    case 'CANCEL_BULK_OPERATION':
      return {
        ...state,
        bulkOperations: state.bulkOperations.filter((bulkOperation) => bulkOperation._id !== action.bulkOperationId),
      };
    case 'SIGN_OUT':
    case 'SWITCH_WORKSPACE': {
      return initialState;
    }
    default:
      return state;
  }
};
