import { Popover, Tooltip } from 'antd';
import startCase from 'lodash/startCase';
import capitalize from 'lodash/capitalize';
import escapeRegExp from 'lodash/escapeRegExp';
import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/loading';
import { Icon } from 'components/common';
import { COLORS } from 'constants/styles';
import { getValueOfInput } from 'helpers/dom';
import { toggleModal } from 'data/actions/modals';
import EllipsisText from 'components/ellipsisText';
import { SOURCE_TYPE } from 'data/types/source.types';
import { sortArrayBySpecificNames } from 'helpers/arrays';
import { propertiesSelector } from 'data/selectors/properties';
import { insertTokenIntoEditor } from 'data/actions/sequences';
import useShowPauseWarningIfNeeded from 'hooks/sequences/useShowPauseWarningIfNeeded';
import {
  ActionButton,
  FindPropertyContainer,
  FindPropertyInput,
  FindPropertyGroupContainer,
  PropertyMenuItem,
  NotFoundMenuItem,
} from '../styles';

const InsertToken = ({ loadingEditor }) => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [visible, toggleVisible] = useState(false);
  const [tooltipVisible, toggleTooltipVisible] = useState(false);

  const properties = useSelector(propertiesSelector);

  const showPauseWarningIfNeeded = useShowPauseWarningIfNeeded();

  const filterProperty = (property) => ((!property.custom || (property.sourceType === SOURCE_TYPE.COMPANY && property.name === 'name') || property.name === 'position') && property.name !== 'technologies');

  const tokens = useMemo(() => sortArrayBySpecificNames([{ name: 'fullName', label: 'Full Name', sourceType: SOURCE_TYPE.CONTACT }, { name: 'country', label: 'Country' }, ...properties].filter(filterProperty).map((property) => {
    const text = startCase(property.label);
    const value = property.sourceType === SOURCE_TYPE.COMPANY ? `company${capitalize(property.name)}` : property.name;
    return { text, value, sourceType: property.sourceType };
  }).sort((propertyA, propertyB) => {
    if (propertyA.value.includes('company') && !propertyB.value.includes('company')) return 1;
    if (!propertyA.value.includes('company') && propertyB.value.includes('company')) return -1;
    return 0;
  }), 'value', ['fullName', 'firstName', 'lastName', 'location', 'country', 'position', 'linkedinUrl']), [properties]);

  const handleVisibleChange = showPauseWarningIfNeeded((value) => {
    if (!loadingEditor) {
      toggleVisible(value);
      toggleTooltipVisible(false);
    }
  });

  const filterBySearchAndSource = (sourceType) => (token) => {
    if (token.sourceType === sourceType) {
      if (search) return new RegExp(`.*${escapeRegExp(search)}.*`, 'i').test(token.text);
      return true;
    }
    return false;
  };

  const handleInsertToken = (token) => {
    dispatch(insertTokenIntoEditor(token));
    toggleVisible(false);
    toggleTooltipVisible(false);
  };

  const contactTokens = tokens.filter(filterBySearchAndSource(SOURCE_TYPE.CONTACT)).map((token, index, array) => (
    <PropertyMenuItem
      needBorder={index === array.length - 1}
      key={token.value}
      onClick={
        () => {
          handleInsertToken(token.value);
        }
      }
    >
      {token.text}
    </PropertyMenuItem>
  ));

  const companyTokens = tokens.filter(filterBySearchAndSource(SOURCE_TYPE.COMPANY)).map((token, index, array) => (
    <PropertyMenuItem
      needBorder={index === array.length - 1}
      key={token.value}
      onClick={
        () => {
          handleInsertToken(token.value);
        }
      }
    >
      {token.text}
    </PropertyMenuItem>
  ));

  const contactTokensWithHeader = contactTokens.length > 0 ? [
    <FindPropertyGroupContainer
      key={SOURCE_TYPE.CONTACT}
    >
      Contact
    </FindPropertyGroupContainer>,
    ...contactTokens,
  ] : [];

  const companyTokensWithHeader = companyTokens.length > 0 ? [
    <FindPropertyGroupContainer
      key={SOURCE_TYPE.COMPANY}
    >
      Company
    </FindPropertyGroupContainer>,
    ...companyTokens,
  ] : [];

  const entityTokens = contactTokens.length === 0 && companyTokens.length === 0 ? [
    <NotFoundMenuItem
      key="not_found"
      onClick={() => {
        toggleVisible(false);
        dispatch(toggleModal('create_property_preview', true, { label: search, onCreateProperty: ({ name }) => handleInsertToken(name) }));
      }}
    >
      <EllipsisText title={search} style={{ display: 'block', width: '120px' }}>{`Create "${search}"`}</EllipsisText>
      <Icon type="ic-add" fill={COLORS.PRIMARY} />
    </NotFoundMenuItem>,
  ] : [...contactTokensWithHeader, ...companyTokensWithHeader];

  const contentWithKeys = [
    <FindPropertyContainer
      key="find_property"
      style={{ padding: '6px 12px 0 12px' }}
    >
      <Icon size="smallest" type="ic-search" />
      <FindPropertyInput
        value={search}
        placeholder="Find property"
        onChange={getValueOfInput(setSearch)}
      />
      {
        search && (
          <Icon onClick={() => setSearch('')} type="ic-close" />
        )
      }
    </FindPropertyContainer>,
    ...entityTokens,
  ];

  return (
    <Popover
      onVisibleChange={handleVisibleChange}
      visible={visible}
      placement="topLeft"
      content={contentWithKeys}
      trigger="click"
      arrowPointAtCenter={false}
      autoAdjustOverflow={false}
      overlayClassName="overlay-popover hide-popover-arrow"
      align={{
        offset: ['-138px', '-10px'],
      }}
    >
      <Tooltip
        visible={tooltipVisible}
        onVisibleChange={toggleTooltipVisible}
        title={visible ? '' : 'Properties'}
      >
        <ActionButton hasDot loading={loadingEditor ? 'true' : ''}>
          <Loader loading={loadingEditor} />
          <Icon fill={COLORS.GREY_G1} size="small" type="ic-data-object" />
        </ActionButton>
      </Tooltip>
    </Popover>
  );
};

export default InsertToken;
