import { useEffect } from 'react';

const useOpenPreview = (type, visible) => {
  useEffect(() => {
    const getIntercomDOM = () => document.querySelector('#intercom-container') ?? document.querySelector('.intercom-lightweight-app');

    const toggleIntercom = () => {
      const intercom = getIntercomDOM();

      if (intercom) {
        intercom.style.display = !type.includes('preview') || !visible ? 'block' : 'none';
      }
    };
    toggleIntercom();

    let observer = null;
    const intercom = getIntercomDOM();
    if (visible === true && !intercom) {
      const callback = (mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (mutation.type === 'childList' && [...mutation.addedNodes].some((node) => node.id === 'intercom-container' || node.classList?.contains('intercom-lightweight-app'))) {
            toggleIntercom();
          }
        });
      };

      const config = {
        childList: true,
      };

      observer = new MutationObserver(callback);
      observer.observe(window.document.body, config);
    }
    return () => {
      if (observer) observer.disconnect();
    };
  }, [type, visible]);
};

export default useOpenPreview;
