import { propertyFields as fields } from 'data/types/propertyFields.types';

const propertyFields = {
  firstStep: [
    {
      name: 'sourceType',
      label: 'Object Type',
      description: 'The data source type that the property will use.',
      options: ['contact', 'company'],
      required: true,
      placeholder: 'Select Object Type',
    },
    {
      name: 'group',
      label: 'Group',
      description: 'The group of the property.',
      required: false,
      placeholder: 'Select group',
    },
    {
      name: 'label',
      label: 'Label',
      description: 'A human readable label for the property. The label is used to display the property inside the app.',
      required: true,
      placeholder: 'Property label',
    },
    {
      name: 'description',
      label: 'Description',
      description: 'A description of the property.',
      required: false,
      placeholder: 'Optional',
    },
  ],
  secondStep: [
    {
      name: 'fieldType',
      label: 'Field Type',
      description: 'Sets the way that the property appears when used as a form field.',
      options: [...fields],
      required: true,
      placeholder: 'Select field type',
    },
  ],
};

export default propertyFields;
