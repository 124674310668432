export const setOperation = (data) => (dispatch) => {
  dispatch({
    type: 'SET_OPERATION',
    data,
  });
};

export const removeOperation = (data) => (dispatch) => {
  dispatch({
    type: 'REMOVE_OPERATION',
    data,
  });
};

export const fireTriggerAction = (data) => (dispatch) => {
  dispatch({
    type: 'FIRE_TRIGGER',
    data,
  });
};

export const clearFireTrigger = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_TRIGGER',
    data: {},
  });
};
