import { Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getValueOfInput } from 'helpers/dom';
import { validateEmail } from 'helpers/validate';
import { Button, Input } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { sendTestMessage } from 'data/actions/sequences';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import {
  sequenceAccountEmailSelector,
} from 'data/selectors/sequences';
import { workspaceIdSelector } from 'data/selectors/user';
import { removeSignature } from 'helpers/sequences/signature';
import { sendTestMessageLoadingSelector } from 'data/selectors/loading';
import {
  ModalTitle, CloseIcon, Footer,
} from '../baseModal/styles';
import { Label } from '../sendTestCampaignModal/styles';

const SendTestEmailModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const { subject, content } = useSelector(modalOptionsSelector);
  const workspaceId = useSelector(workspaceIdSelector);
  const email = useSelector(sequenceAccountEmailSelector);
  const loading = useSelector(sendTestMessageLoadingSelector);

  const [sendTo, setSendTo] = useState(localStorage.getItem(`testEmail_${workspaceId}`) || email);

  const handleClose = () => dispatch(toggleModal('send_test_email', false));
  const sendTestEmail = () => {
    if (sendTo !== email) {
      localStorage.setItem(`testEmail_${workspaceId}`, sendTo);
    }
    const contentWithoutSignature = removeSignature(content);
    dispatch(sendTestMessage({
      subject,
      content: contentWithoutSignature,
      sendTo,
    }, () => {
      handleClose();
      dispatch(toggleMessage('success', { header: `The preview of your email has been sent to ${sendTo}` }));
    }));
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Send test email
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={420}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        <Label>Email</Label>
        <Input
          error={validateEmail(sendTo) ? undefined : 'Email is incorrect'}
          value={sendTo}
          onChange={getValueOfInput(setSendTo)}
        />
      </div>
      <Footer>
        <Button
          data-form="true"
          type="primary"
          size="large"
          disabled={!validateEmail(sendTo)}
          loading={loading}
          onClick={sendTestEmail}
        >
          Send
        </Button>
        <Button
          size="large"
          type="normal"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Footer>
    </Modal>
  );
};

export default SendTestEmailModal;
