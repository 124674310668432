import React, { useEffect } from 'react';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMessage } from 'data/actions/message';

import Loader from 'components/loading';
import { signIn } from 'data/actions/user';
import useTinyForm from 'hooks/useTinyForm';
import { validateEmail } from 'helpers/validate';
import { Input, Button } from 'components/common';
import { useSetSignUpQuery } from 'hooks/useSignUpQuery';
import GoogleAuthBtn from 'components/googleAuth/googleAuthBtn';
import { getLastWorkspaceId } from 'helpers/setLastWorkspaceId';
import { loginPageLoadingSelector } from 'data/selectors/loading';
import {
  linkStyle,
  Header,
  AuthForm,
  InputWrapper,
  FormWrapper,
  loginBtnStyle,
  SubHeader,
  ForgotPassContainer,
} from '../styles';
import AuthContainer from '../AuthContainer';
import {
  FormDataFooter,
  FormDivider,
  HeaderWrapper,
} from '../signUp/styles';

const LoginPage = ({ emailFormData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(loginPageLoadingSelector);
  useSetSignUpQuery();

  const {
    data,
    errors,
    handleChange,
    handleSubmit,
    isDisabledForm,
  } = useTinyForm({
    schema: {
      email: {
        value: '',
        conditions: [
          {
            label: 'Incorrect email',
            condition: validateEmail,
          },
        ],
      },
      password: {
        value: '',
      },
      isRememberMe: {
        value: true,
      },
      workspaceId: {
        value: null,
      },
    },
    onSubmit: ({ data: _data, setErrors }) => {
      _data.workspaceId = getLastWorkspaceId(get(_data, 'email'));
      const signInChannel = new BroadcastChannel('sign_in_channel');
      dispatch(signIn({
        ..._data,
        components: ['loginPage'],
        onFailed: (err) => {
          if (err?.response?.data?.statusCode === 400) {
            let { message } = err.response.data;
            if (!message) message = 'Something went wrong';
            setErrors('password', message);
            signInChannel.postMessage({ status: 'error', data: message });
          }
        },
        onSuccess: () => {
          signInChannel.postMessage({ status: 'success' });
        },
      }));
    },
  });

  const setEmailFormData = () => {
    emailFormData(data.email);
  };

  useEffect(() => {
    const signInChannel = new BroadcastChannel('sign_in_channel');
    signInChannel.onmessage = (event) => {
      if (event?.data?.status === 'error') dispatch(toggleMessage('error', { text: event?.data?.data }));
      else {
        window.location.reload();
      }
    };

    return () => {
      signInChannel.close();
    };
  }, [dispatch]);

  return (
    <AuthContainer>
      <AuthForm
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <HeaderWrapper>
          <Header>Sign in to Getprospect</Header>
          <SubHeader>
            Enter your credentials to access your account.
          </SubHeader>
        </HeaderWrapper>
        <FormWrapper>
          <InputWrapper>
            <Input
              label="Email address"
              type="text"
              name="email"
              placeholder="you@company.com"
              value={data.email}
              onChange={handleChange}
              error={errors.email}
              autoFocus
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label="Password"
              type="password"
              name="password"
              autocomplete="current-password"
              placeholder="password"
              value={data.password}
              onChange={handleChange}
              error={errors.password}
            />
            <ForgotPassContainer>
              <Link to="/password/reset" style={linkStyle} onClick={setEmailFormData}>Forgot password?</Link>
            </ForgotPassContainer>
          </InputWrapper>
        </FormWrapper>
        <Loader loading={isLoading}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleSubmit}
            disabled={isDisabledForm}
            style={loginBtnStyle}
          >
            {isLoading ? '' : 'Sign in'}
          </Button>
        </Loader>
        <FormDivider>or</FormDivider>
        <GoogleAuthBtn
          loaderStyle={{ maxHeight: '40px' }}
          className="google-button-sign-up"
          title="Sign in with Google"
          state="sign-in"
        />
        <FormDataFooter>
          <SubHeader>
            Don&apos;t have account?&nbsp;
            <Link to="/sign-up" style={{ ...linkStyle, fontWeight: 600 }}>Sign up</Link>
          </SubHeader>
        </FormDataFooter>
      </AuthForm>
    </AuthContainer>
  );
};

export default LoginPage;
