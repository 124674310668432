import React from 'react';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { getSequences, removeSequences } from 'data/actions/sequences';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { Container } from '../deleteStep/styles';
import {
  ModalTitle, CloseIcon, Footer,
} from '../baseModal/styles';

const DeleteSequenceModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { visible } = useSelector(modalsSelector);
  const { sequenceIds, setSelectedRowKeys } = useSelector(modalOptionsSelector);

  const handleClose = () => dispatch(toggleModal('delete_sequence', false));
  const handleDelete = () => {
    dispatch(removeSequences(sequenceIds, (result) => {
      if (!result.pending) {
        history.push('/sequences');
        dispatch(getSequences({ pageNumber: 1 }));
        dispatch(toggleMessage('info', { header: `The ${sequenceIds.length === 1 ? 'sequence has' : 'sequences have'} been removed` }));
      } else {
        dispatch(toggleMessage('info', { header: `Deleting the ${sequenceIds.length === 1 ? 'sequence' : 'sequences'} is in progress` }));
      }
    }));
    dispatch(toggleModal('delete_sequence', false));
    if (setSelectedRowKeys) setSelectedRowKeys([]);
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Delete
          {' '}
          {sequenceIds.length === 1 ? 'sequence' : 'sequences'}
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <Container>
        <div>
          You&#39;re about to delete the
          {' '}
          {sequenceIds.length === 1 ? 'sequence' : 'sequences'}
          . Deleted
          {' '}
          {sequenceIds.length === 1 ? 'sequence' : 'sequences'}
          {' '}
          can&#39;t be restored
        </div>

      </Container>
      <Footer>
        <Button
          data-form="true"
          type="danger"
          size="large"
          onClick={handleDelete}
        >
          Delete
        </Button>
        <Button
          size="large"
          type="default"
          onClick={handleClose}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default DeleteSequenceModal;
