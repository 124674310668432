import React, { useState, forwardRef, useEffect } from 'react';

import { CustomSelect as Select, IconRotateArrow, ValidationStatus } from 'components/commonStyles';

const CustomSelect = forwardRef(({
  size = 'large',
  suffixIcon,
  routatedSuffixIcon,
  children,
  type = 'default',
  error,
  success,
  onDropdownVisibleChange = () => {},
  containerStyle = {},
  ...props
}, ref) => {
  const { open } = props;
  const [visible, setVisible] = useState(open);

  const onDropdownVisibleChangeProxy = (val) => {
    setVisible(val);
    onDropdownVisibleChange(val);
  };

  const renderSuffixIcon = () => {
    if (suffixIcon) {
      if (routatedSuffixIcon && visible) {
        return routatedSuffixIcon;
      }
      return suffixIcon;
    }
    return <IconRotateArrow from={0} to={-180} type={type} isActive={visible ? 'true' : ''} />;
  };

  useEffect(() => {
    if (typeof open !== 'undefined') {
      setVisible(open);
    }
  }, [open]);

  return (
    <div style={{ width: '100%', ...containerStyle }}>
      <Select
        ref={ref}
        size={size}
        error={error}
        success={success}
        suffixIcon={renderSuffixIcon()}
        onDropdownVisibleChange={onDropdownVisibleChangeProxy}
        {...props} // eslint-disable-line
      >
        {children}
      </Select>
      <ValidationStatus error={error} success={success}>
        {error || success}
      </ValidationStatus>
    </div>
  );
});

export default CustomSelect;
