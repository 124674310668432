import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { clearMessage } from 'data/actions/message';
import { COLORS } from 'constants/styles';
import audioPath from 'assets/audio/notification.mp3';
import DefaultMessageTemplate from './templates/default';
import RightImageMessageTemplate from './templates/rightImage';
import ShortMessage from './templates/short';

const dictionaryCustomTemplates = {
  'right-image': RightImageMessageTemplate,
  'instal-plugin': RightImageMessageTemplate,
  short: ShortMessage,
};

const MessageItem = ({
  id,
  type,
  text,
  isHTML,
  header,
  duration,
  link,
  primaryButtonOptions,
  secondaryButtonOptions,
  tabIsActive,
  customIcon,
  sound,
  image,
}) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const [progress, setProgress] = useState(0);
  const [pauseProgress, togglePauseProgress] = useState(false);
  const [visible, toggleVisible] = useState(false);

  useEffect(() => {
    togglePauseProgress(!tabIsActive);
  }, [tabIsActive]);

  let typeColor = COLORS.PRIMARY;
  let icon = customIcon || 'ic-info';
  let onClose = () => {};

  useEffect(() => {
    if (type) {
      toggleVisible(true);
      if (sound) {
        const audio = new Audio(audioPath);
        audio.play();
      }
    } else setProgress(0);
  }, [type, sound]);

  switch (type) {
    case 'success':
      icon = 'ic-check-circle';
      typeColor = COLORS.GREEN_G5;
      break;
    case 'info':
      typeColor = COLORS.PRIMARY;
      break;
    case 'error':
    case 'failed':
      icon = 'ic-cancel';
      typeColor = COLORS.RED;
      break;
    case 'warning':
      icon = 'ic-warning';
      typeColor = COLORS.YELLOW_Y1;
      break;
    case 'plugin':
      icon = 'ic-chrome';
      onClose = () => localStorage.setItem('closeChromeExtensionNotification', 'true');
      break;
    case 'instal-plugin':
      onClose = () => localStorage.setItem('closeChromeExtensionNotification', 'true');
      break;
    case 'right-image': {
      typeColor = COLORS.PRIMARY;
      onClose = () => localStorage.setItem('extension-demo-popup', true);
      break;
    }
    default:
      typeColor = COLORS.PRIMARY;
      break;
  }

  const close = useCallback(() => {
    onClose();
    toggleVisible(false);
    dispatch(clearMessage(id));
  }, [dispatch, id]);

  const handleButtonAction = useCallback(({ action, closeMessage = false }) => {
    action();
    if (closeMessage) dispatch(clearMessage(id));
  }, [dispatch, id]);

  useEffect(() => {
    let timeout;
    if (type && !pauseProgress && duration) {
      if (progress < 100) timeout = setTimeout(() => setProgress(progress + (1 / duration)), 10);
      else close();
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [dispatch, progress, duration, type, pauseProgress, close]);

  const data = {
    type,
    text,
    header,
    close,
    link,
    primaryButtonOptions,
    secondaryButtonOptions,
    ref,
    typeColor,
    icon,
    visible,
    progress,
    togglePauseProgress,
    handleButtonAction,
    image,
    isHTML,
  };
  const Template = dictionaryCustomTemplates[type] || DefaultMessageTemplate;

  return <Template data={data} />;
};

export default MessageItem;
