import styled from 'styled-components';
import * as CONSTANTS from 'constants/styles';
import { Icon } from 'antd';
import { subscriptionStatusColors } from 'data/types/subscription.types';
import { Badge } from 'components/commonStyles';

export const PlanHeader = styled.h3`
  transition: all 0.5s;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  max-width: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const PlanText = styled.p`
  font-size: 14px;
  margin: 0;
`;

export const PlanBadgeSubscription = styled(Badge)`
  background-color: ${CONSTANTS.COLORS.PRIMARY};
  border-radius: 6px;
  padding: 2.5px 4px;
  font-size: 12px;
  display: flex;                   
  align-items: center;
  line-height: 15px;
  transition: all 0.5s;
  font-weight: 600;
  height: 20px;
  border: none;
  margin-left: 3px;

  ${(props) => {
    switch (props.status) {
      case 'custom': {
        return `
          color: ${CONSTANTS.COLORS.WHITE};
          background-color: ${CONSTANTS.COLORS.PRIMARY};
        `;
      }
      case 'pause': {
        return `
          color: ${CONSTANTS.COLORS.WHITE};
          background-color: ${CONSTANTS.COLORS.YELLOW_Y1};
        `;
      }
      case 'active': {
        return `
          color: ${CONSTANTS.COLORS.WHITE};
          background-color: ${CONSTANTS.COLORS.GREEN_G5};
        `;
      }
      case 'disabled': {
        return `
          color: ${CONSTANTS.COLORS.WHITE};
          background-color: ${CONSTANTS.COLORS.RED};
        `;
      }
      default: {
        return `
          color: ${CONSTANTS.COLORS.GREY_G1};
          background-color: ${CONSTANTS.COLORS.GREY_G4};
        `;
      }
    }
  }}
`;

export const PlanHeaderContainer = styled.div`
  display: flex;
  align-items: center;

  ${PlanHeader} {
    margin-right: 3.5px;
  }
`;

export const PlanBadge = styled(Badge)`
  border: none;
  border-radius: 6px;
  background-color: ${CONSTANTS.COLORS.PRIMARY};
  color: ${CONSTANTS.COLORS.WHITE};
`;

export const BadgeText = styled.span`
  opacity: ${(props) => (props.isCurrent ? 1 : 0.7)};
  white-space: nowrap;
`;

export const BenefitItem = styled.div`
  display: flex;
  align-items: center;

  .material-icons {
    margin-right: 7.5px;
    font-size: 22px !important;
    height: 18px;
    display: flex;
    align-items: center;
  }

  ${PlanText} {
    padding-top: 1px;
  }
`;

export const CardWrapper = styled.div`
  border: 1px solid ${CONSTANTS.COLORS.GREY_G14};
  border-radius: 4px;
  transition: all 0.5s;
  color: ${CONSTANTS.COLORS.GREY_G1};
  width: 100%;
  max-width: 220px;
  background-color: ${(props) => (props.isCurrent ? CONSTANTS.COLORS.WHITE : CONSTANTS.COLORS.GREY_G14)};
`;

export const PlanHeaderWrapper = styled.div`
  display: flex;
  padding: 20px 20px 0 20px;
  justify-content: space-between;

  ${PlanBadge} {
    margin-left: 4px;
  }
`;

export const DescriptionBlock = styled.div`
  display: flex;
  margin: 5px 20px 20px 20px;
  flex-direction: column;
`;
export const QuotasBlock = styled.div`
  margin-top: 20px;
  margin-bottom: 25px;

  ${BenefitItem}:last-child {
    margin-bottom: 0;
  }
`;
export const PriceBlock = styled.div`
  color: ${CONSTANTS.COLORS.GREY_G1};
`;

export const SubHeader = styled.p`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
`;

export const PlanBtnStyles = {
  width: '100%',
  height: 32,
};

export const NextPlanBtnStyles = {
  width: '100%',
  height: 32,
  borderColor: '#DFE2E5',
};

export const SubscriptionStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const SubscriptionStatusIcon = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 8px;
  background-color: ${(props) => subscriptionStatusColors[props.status] ?? CONSTANTS.COLORS.RED};
  margin-right: 5px;
`;
export const StatusText = styled.span`
  font-size: 14px;
  font-weight: 600;
  color:  ${(props) => (props?.status === 'active' ? CONSTANTS.COLORS.GREEN_G5 : CONSTANTS.COLORS.RED)};
`;

export const StatusLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${CONSTANTS.COLORS.GREY_G2};
  width: 150px;
  margin-right: 25px;
`;

export const SubscriptionConfigContainer = styled.div`
  margin-left: 30px;
  margin-bottom: 30px;

  h3 {
    margin-bottom: 20px;
  }
`;

export const PaymentInfo = styled.div``;
export const SubscriptionText = styled.span`
  font-size: 14px;
  color: ${CONSTANTS.COLORS.GREY_G1};
  font-weight: 500;

  a {
    text-decoration: underline;
  }
`;
export const TextGrey = styled.span`
  color: ${CONSTANTS.COLORS.GREY_G2};
  font-size: 14px;
  font-weight: 500;
  width: 150px;
  margin-right: 25px;
  margin-bottom: 5px;
  display: inline-block;
`;

export const ContainerBtn = styled.span`
  text-decoration: underline;
  color: ${CONSTANTS.COLORS.PRIMARY};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  transition: opacity .2s;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  display: flex;
  align-items: center;

  :hover {
    opacity: .7;
  }
`;
export const SubscriptionControlContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  .start-button {
    height: 32px;
    width: 80px;
    min-width: auto;
    margin-right: 10px;
  }
`;

export const BillingCycleContainer = styled.div`
  margin: 30px 0 30px 30px;
`;

export const BillingCycleDescription = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;
export const HeaderSub = styled.p`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
`;
export const TextRed = styled.span`
  color: ${CONSTANTS.COLORS.RED};
  font-size: 14px;
  font-weight: 500;
`;

export const PaymentCardDescriptionWrapper = styled.div`
  margin: 10px 0 15px 0;
  font-weight: 500;
`;

export const CardBadge = styled(PlanBadge)`
  background-color: ${CONSTANTS.COLORS.GREY_G14};
  color: ${CONSTANTS.COLORS.GREY_G1};
  font-size: 12px;
  font-weight: 500;
`;

export const PaymentCardDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${CardBadge} {
    margin-right: 50px;
  }
`;
export const PaymentIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32.5px;
  height: 22.5px;
  border: 1px solid ${CONSTANTS.COLORS.GREY_G15};
  margin-right: 15px;
`;
export const PaymentIcon = styled.img`
  width: 100%;
`;
export const PaymentCardDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
`;
export const CardInfo = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${CONSTANTS.COLORS.GREY_G1};
`;
export const CardDate = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${CONSTANTS.COLORS.GREY_G2};
`;

export const HistoryDescription = styled.div`
  margin: 10px 0 20px 0;
  font-size: 14px;
  font-weight: 500;
`;

export const BillingEmail = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

export const BillingTableContainer = styled.div`
  max-width: 845px;
  border: 1px solid ${CONSTANTS.COLORS.GREY_G4};
  border-bottom: none;
  border-radius: 2px;

  th {
    background-color: transparent !important;
  }

  .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th, 
  .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
    padding: 11px 10px;

    :first-child {
      padding-left: 20px;
    }
    :last-child {
      padding-right: 20px;
    }
  }

  span.ant-table-column-title {
    font-weight: 600;
  }
`;

export const HistoryBadge = styled(PlanBadge)`
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 600;
  width: fit-content;
  word-break: normal;
`;

export const GreyText = styled.span`
  color: ${CONSTANTS.COLORS.GREY_G2};
  font-weight: 500;
`;
export const PlanDescription = styled.span`
  color: ${CONSTANTS.COLORS.GREY_G1};
  font-weight: 600;
`;

export const InfoIcon = styled(Icon)`
  margin-left: 4px;
  font-size: 17px;
  color: ${CONSTANTS.COLORS.PRIMARY};
  cursor: pointer;
`;

export const PlanBadgeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;
`;

export const PauseTooltipStyle = {
  width: 140,
  backgroundColor: CONSTANTS.COLORS.BLACK,
  borderRadius: 4,
  padding: 0,
  fontWeight: 500,
  fontSize: 12,
};

export const NewPlanWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 20px 0 20px;
`;

export const NewPlanHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  color: ${CONSTANTS.COLORS.BLACK_G1};
  margin-bottom: 20px;
`;

export const NewPlanDescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    flex-direction: column;
  }
`;

export const ChangePlanBtnStyles = {
  width: '107px',
  height: 32,
};

export const NewPlanTopic = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: ${CONSTANTS.COLORS.BLACK_G1};
  margin-bottom: 5px;
`;

export const NewPlanValueLabelContainer = styled.div`
  margin-right: 25px;
`;

export const NewPlanDescriptionBlock = styled.div`
  display: flex;

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    margin-bottom: 15px;
  }
`;

export const NewPlanValueLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: ${CONSTANTS.COLORS.GREY_G2};
  margin-bottom: 5px;
`;

export const NewPlanValue = styled.div`
  font-size: 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: ${CONSTANTS.COLORS.GREY_G1};
  margin-bottom: 5px;

  strong {
    color: ${CONSTANTS.COLORS.BLACK_G1};
  }
`;

export const NewPlanValuesContainer = styled.div`
  margin-top: 23px;
`;
