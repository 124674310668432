import React from 'react';
import { Modal } from 'antd';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';

import useTinyForm from 'hooks/useTinyForm';
import { validateEmail } from 'helpers/validate';
import { Button, Input } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { connectCustomAccount } from 'data/actions/integrations';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { customAccountVerificationLoadingSelector } from 'data/selectors/loading';
import { updateSequenceAccount, updateSequenceSettingsRemotely } from 'data/actions/sequences';
import {
  ModalTitle,
  CloseIcon,
  Footer,
} from '../baseModal/styles';
import { InputContainer, Label, Hint } from './styles';

const AddAppPasswordModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const { sequenceId } = useSelector(modalOptionsSelector);

  const loading = useSelector(customAccountVerificationLoadingSelector);

  const handleClose = () => dispatch(toggleModal('add_app_password', false));

  const {
    data,
    errors,
    handleChange,
    handleSubmit,
    isDisabledForm,
  } = useTinyForm({
    schema: {
      email: {
        value: '',
        conditions: [
          {
            label: 'Must be filled',
            condition: Boolean,
          },
          {
            label: 'Must be email',
            condition: validateEmail,
          },
        ],
      },
      password: {
        value: '',
        conditions: [
          {
            label: 'Must be filled',
            condition: Boolean,
          },
        ],
      },
    },
    onSubmit: ({ data: { email, password } }) => {
      dispatch(connectCustomAccount({
        email,
        password,
        onSuccess: (account) => {
          dispatch(toggleMessage('short', { text: 'Email account saved' }));
          const hasError = get(account, 'smtp.error') || get(account, 'imap.error');
          if (!hasError) {
            dispatch(updateSequenceSettingsRemotely({
              sequenceId,
              data: { account: account._id },
              onSuccess: () => {
                dispatch(updateSequenceAccount({ sequenceId, account }));
              },
            }));
          }
          dispatch(toggleModal('email_account_preview_bar', true, { accountId: account._id, waitForEmailAccount: true, sequenceId }));
        },
      }));
    },
  });

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Connect Your Google Account
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={420}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        <InputContainer style={{ marginBottom: 20 }}>
          <Label required>Email</Label>
          <Input
            name="email"
            onChange={handleChange}
            value={data.email}
            size="default"
            error={errors.email}
          />
        </InputContainer>
        <InputContainer>
          <Label required>App Password</Label>
          <Input
            type="password"
            name="password"
            onChange={handleChange}
            value={data.password}
            size="default"
            error={errors.password}
          />
        </InputContainer>
        <Hint>16-character app password</Hint>
        <Footer>
          <Button
            data-form="true"
            loading={loading}
            disabled={isDisabledForm}
            type="primary"
            onClick={handleSubmit}
          >
            Connect
          </Button>
          <Button type="normal" onClick={handleClose}>Cancel</Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default AddAppPasswordModal;
