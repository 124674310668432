import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { toggleMessage } from 'data/actions/message';
import { useHistory } from 'react-router-dom';

const useInsufficientCreditsMessage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const showMessage = useCallback(() => {
    dispatch(toggleMessage('warning', { header: 'Insufficient credits for this action', duration: 15, primaryButtonOptions: { action: () => history.push('/settings/subscriptions'), text: 'Upgrade now' } }));
  }, [dispatch, history]);

  return showMessage;
};

export default useInsufficientCreditsMessage;
