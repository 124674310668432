import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const Container = styled.div``;

export const NameSequence = styled.p`
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.GREY_G1};
`;

export const Info = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.GREY_G1};
`;

export const Variable = styled.span`
  color: ${COLORS.BLACK_G1};
`;
