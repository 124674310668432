import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadersSelector } from 'data/selectors/loading';
import { getFilterOptionsSelector } from 'data/selectors/search';

const useLoadOptions = (filter) => {
  const dispatch = useDispatch();

  const loaders = useSelector(loadersSelector(filter.components));
  const optionsSelector = getFilterOptionsSelector(filter.property);
  const options = useSelector(optionsSelector);

  const [wasLoaded, setWasLoaded] = useState(options.length > 0);

  useEffect(() => {
    if (filter.loadDefault && !wasLoaded) {
      if (!filter.components || loaders.every((component) => !component)) {
        dispatch(filter.loadDefault());
        setWasLoaded(true);
      }
    }
  }, [filter, dispatch, wasLoaded, loaders]);
};

export default useLoadOptions;
