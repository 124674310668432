import styled, { css } from 'styled-components';

import {
  COLORS,
} from 'constants/styles';

const errorMixin = css`
  border: 1px solid ${COLORS.RED};
`;

export const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: ${(props) => (props.bordered ? `1px solid ${COLORS.GREY_G4}` : 'none')};
  border-top: none;
  padding: 0 20px;
  ${(props) => props.error && errorMixin}
`;

export const PanelContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid ${COLORS.GREY_G4};
`;

export const PanelHeader = styled.span`
  color: ${COLORS.GREY_G2};
  font-weight: 500;
`;

export const PanelInput = styled.input`
  border: 0;
  outline: none;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${COLORS.BLACK_G1};

  &:disabled {
    background: white;
  }
`;

const heightMixin = css`
  height: ${(props) => `${typeof props.height === 'string' ? props.height : `${props.height}px`}`};
`;

const borderedMixin = css`
  border: 1px solid ${COLORS.GREY_G4};
`;

const roundedMixin = css`
  border-radius: 4px;
`;

export const TextContainer = styled.div`
  ${(props) => props.height && heightMixin}
  ${(props) => props.bordered && borderedMixin}
  ${(props) => props.rounded && roundedMixin}
`;
