export const PageSizes = Object.freeze({
  DEFAULT_COMPANIES_PAGE_SIZE: 25,
  DEFAULT_COMPANIES_INSIGHTS_PAGE_SIZE: 25,
  DEFAULT_BLOCKED_COMPANIES_PAGE_SIZE: 25,
  DEFAULT_TRASH_BIN_PAGE_SIZE: 25,
  DEFAULT_CONTACTS_PAGE_SIZE: 25,
  DEFAULT_CONTACTS_INSIGHTS_PAGE_SIZE: 25,
  DEFAULT_BLOCKED_CONTACTS_PAGE_SIZE: 25,
  DEFAULT_IMPORTS_PAGE_SIZE: 10,
  DEFAULT_INTEGRATIONS_PAGE_SIZE: 100,
  DEFAULT_LISTS_PAGE_SIZE: 25,
  DEFAULT_FOLDERS_PAGE_SIZE: 25,
  DEFAULT_NOTIFICATIONS_PAGE_SIZE: 50,
  DEFAULT_PROPERTIES_PAGE_SIZE: 10,
  DEFAULT_SEARCHES_PAGE_SIZE: 25,
  DEFAULT_COUNTRIES_PAGE_SIZE: 10,
  DEFAULT_SEQUENCES_PAGE_SIZE: 25,
  DEFAULT_SEQUENCE_FOLDERS_PAGE_SIZE: 10,
  DEFAULT_SEQUENCE_LEADS_PAGE_SIZE: 25,
  DEFAULT_TEAMMATES_PAGE_SIZE: 25,
  DEFAULT_AFFILIATE_REFERRED_PAGE_SIZE: 25,
  DEFAULT_USAGE_PAGE_SIZE: 10,
  DEFAULT_SEARCHES_PAGE_HISTORY: 10,
  DEFAULT_WORKSPACES_PAGE_SIZE: 10,
  DEFAULT_VERIFICATIONS_PAGE_SIZE: 11,
});

export const UsageTableTypes = Object.freeze({
  EMAIL_VERIFICATION: 'Email Verification',
  EMAIL_SEARCH: 'Email Search',
  PHONE_SEARCH: 'Phone Search',
});

export const MaxItems = Object.freeze({
  MAX_ITEMS_CONTACTS_PREVIEW: 100,
  MAX_ITEMS_CONTACTS_PREVIEW_PAID: 2500,
});
