import { Modal } from 'antd';
import React, { useState } from 'react';

import {
  Button,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  Input,
} from 'components/common';
import { countSymbolsWithoutSpaces } from 'helpers/strings';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../../../../modals/baseModal/styles';

const Choose = ({
  type,
  array,
  title,
  field,
  onNext,
  onClose,
  minOtherSymbols = 1,
}) => {
  const [value, setValue] = useState();
  const [dynamicValue, setDynamicValue] = useState('');

  const ComponentGroup = type === 'radio' ? RadioGroup : CheckboxGroup;
  const ComponentItem = type === 'radio' ? Radio : Checkbox;

  const DYNAMIC_ITEM = 'Other';

  const isDisabled = () => {
    if (Array.isArray(value)) {
      return !value.length || (value.includes(DYNAMIC_ITEM) && countSymbolsWithoutSpaces(dynamicValue) < minOtherSymbols);
    }
    return !value || (value === DYNAMIC_ITEM && countSymbolsWithoutSpaces(dynamicValue) < minOtherSymbols);
  };

  const isInputVisible = (item) => {
    if (item !== DYNAMIC_ITEM) return null;
    if (Array.isArray(value)) {
      return value.includes(DYNAMIC_ITEM);
    }
    return value === DYNAMIC_ITEM;
  };

  const handleClick = () => {
    if (Array.isArray(value)) {
      return onNext({ [field]: value.map((item) => (item === DYNAMIC_ITEM ? dynamicValue : item)) });
    }
    return onNext({ [field]: value === DYNAMIC_ITEM ? dynamicValue : value });
  };

  return (
    <Modal
      visible
      title={(
        <ModalTitle>
          {title}
          <CloseIcon
            onClick={onClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={onClose}
    >
      <div>
        <ComponentGroup value={value} onChange={(props) => setValue(props?.target ? props.target.value : props)}>
          {
            array.map((item) => (
              <div key={item}>
                <ComponentItem style={{ marginBottom: '10px' }} value={item}>{item}</ComponentItem>
                {
                  isInputVisible(item) && (
                    <Input
                      placeholder={`Minimum ${minOtherSymbols} symbols`}
                      value={dynamicValue}
                      size="default"
                      onChange={(e) => {
                        setDynamicValue(e.target.value);
                      }}
                    />
                  )
                }
              </div>
            ))
          }
        </ComponentGroup>
        <Footer>
          <Button
            data-form="true"
            type="primary"
            disabled={isDisabled()}
            onClick={handleClick}
          >
            Next
          </Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default Choose;
