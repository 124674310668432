import styled from 'styled-components';
import {
  Button,
} from 'antd';
import * as CONSTANTS from 'constants/styles';

export const ActionButton = styled(Button)`
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: 40px;
  border-radius: 4px;
  border: solid 1px ${(props) => (props.border ? props.border : 'none')};
  background-color: ${(props) => (props.background ? props.background : '#ffffff')};
  color: ${(props) => (props.color ? props.color : 'rgba(0, 0, 0, 0.65)')};
  box-shadow: none;
  margin-right: 10px;
  margin-top: 10px;
  font-weight: 500;
`;

export const PropertyItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  input {
    border-radius: 4px;
    border: solid 1px #dfe2e5;
    background-color: #ffffff;
  }
  
  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    padding: 7.5px 0;
    
    :first-child {
      padding-top: 0;
    }
  }
`;

export const EditAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12.5px;
  
  ${ActionButton} {
    flex: 1;
    margin: 0;
  }
  
  ${ActionButton}:first-child {
    margin-right: 15px;
  }
`;
