import React from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

import { Button } from 'components/common';
import { analyticsAndDeleteUserLoaderSelector } from 'data/selectors/loading';

import { modalsSelector } from 'data/selectors/modals';
import {
  ModalTitle,
  CloseIcon,
  Footer,
  Label,
} from '../../modals/baseModal/styles';

const ConfirmationModal = ({
  title = 'Confirmation',
  type = 'primary',
  onClose = () => {},
  onSubmit = () => {},
  submitButtonText,
  closeButtonText,
  label = 'Are you sure?',
}) => {
  const { visible } = useSelector(modalsSelector);
  const loading = useSelector(analyticsAndDeleteUserLoaderSelector);

  const onCloseWithReason = (reason) => () => onClose(reason);

  const handleSubmit = () => {
    onSubmit();
    onClose('success');
  };

  const btnText = {
    danger: 'Delete',
    primary: 'Yes',
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger={type === 'danger'}>
          {title}
          <CloseIcon
            onClick={onCloseWithReason('close')}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={450}
      footer={false}
      onCancel={onCloseWithReason('close')}
      closeIcon={<div />}
    >
      <Label>{label}</Label>
      <Footer onlyFooter={!label}>
        <Button
          data-form="true"
          type={type}
          size="large"
          onClick={handleSubmit}
          loading={loading}
        >
          {submitButtonText || btnText[type]}
        </Button>
        <Button
          size="large"
          type="default"
          onClick={onCloseWithReason('selectedAnotherOption')}
        >
          {closeButtonText || 'No'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default ConfirmationModal;
