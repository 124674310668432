import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Icon } from 'components/common';
import { COLORS } from 'constants/styles';
import { SOURCE_TYPE } from 'data/types/source.types';
import { setAdditingLeads } from 'data/actions/sequences';
import { updateSequenceImportSettings } from 'data/actions/import';

import {
  Container,
  Header,
  MethodContainer,
  MethodsContainer,
  MethodHeader,
  MethodSubText,
} from './styles';

const AddRecipientsToSequence = ({ onSelect = () => {}, sequenceId }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleAddLead = (value) => () => {
    onSelect(value);
    dispatch(setAdditingLeads(true));
    if (value === 'search') {
      history.push(`/search/${SOURCE_TYPE.CONTACT}`);
    } else if (value === 'import') {
      dispatch(updateSequenceImportSettings(sequenceId));
      history.push('/import/data');
    } else if (value === 'contacts') {
      history.push('/contacts/filter/all');
    }
  };

  return (
    <Container>
      <Header>Add recipients</Header>
      <MethodsContainer>
        <MethodContainer onClick={handleAddLead('search')}>
          <Icon fill={COLORS.PRIMARY} type="ic-person-search" />
          <MethodHeader>Search</MethodHeader>
          <MethodSubText>
            Find the leads in database
          </MethodSubText>
        </MethodContainer>
        <MethodContainer onClick={handleAddLead('import')}>
          <Icon fill={COLORS.PRIMARY} type="ic-upload-file" />
          <MethodHeader>Import</MethodHeader>
          <MethodSubText>
            Import file with a list of leads
          </MethodSubText>
        </MethodContainer>
        <MethodContainer onClick={handleAddLead('contacts')}>
          <Icon fill={COLORS.PRIMARY} type="ic-contacts" />
          <MethodHeader>Contacts</MethodHeader>
          <MethodSubText>
            Select saved leads
          </MethodSubText>
        </MethodContainer>
      </MethodsContainer>
    </Container>
  );
};

export default AddRecipientsToSequence;
