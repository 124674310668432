import React, { useState } from 'react';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
} from 'antd';

import { Input, Button } from 'components/common';

import { renameSavedSearch } from 'data/actions/search';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { searchesSelector } from 'data/selectors/search';
import {
  CloseIcon,
  ModalTitle,
  Label,
  Warning,
  InputStyle,
  Footer,
} from '../baseModal/styles';

const RenameSavedSearchModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const {
    savedSearchId,
    oldName,
    backModal,
    backOptions,
  } = useSelector(modalOptionsSelector);
  const savedSearches = get(useSelector(searchesSelector), 'data', []);

  const [name, updateName] = useState(oldName);

  const isExist = savedSearches.findIndex((item) => item.name === name);

  const handleClose = () => {
    if (backModal) {
      dispatch(toggleModal(backModal, true, backOptions));
    } else {
      dispatch(toggleModal('rename_saved_search', false));
    }
  };
  const handleRenameSavedSearch = () => {
    dispatch(renameSavedSearch(savedSearchId, name));
    handleClose();
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Rename the saved search
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <>
        <Label>Name*</Label>
        <Input
          autoFocus
          onChange={(e) => {
            updateName(e.target.value);
          }}
          value={name}
          placeholder="Type the search name..."
          style={InputStyle}
        />

        {isExist >= 0 && name !== oldName && (
          <Warning>
            {`${name} already exists. Please choose a new name.`}
          </Warning>
        )}
      </>
      <Footer>
        <Button
          data-form={!name || isExist >= 0 ? 'false' : 'true'}
          type="primary"
          onClick={handleRenameSavedSearch}
          disabled={!name || isExist >= 0}
        >
          Save
        </Button>
        <Button type="normal" onClick={handleClose}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default RenameSavedSearchModal;
