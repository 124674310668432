import get from 'lodash/get';
import { createSelector } from 'reselect';

const basePropertiesSelector = (state) => get(state, 'properties.properties', []);
export const propertiesSelector = createSelector(
  basePropertiesSelector,
  (properties) => properties || [],
);

export const userPropertiesSelector = createSelector(
  basePropertiesSelector,
  (properties) => properties.filter((property) => !property.custom),
);

export const propertiesSourceTypeSelector = (sourceType, includedDefault = false) => createSelector(
  propertiesSelector,
  (properties) => properties.filter((property) => {
    if (includedDefault) {
      return property.sourceType === sourceType;
    }
    return property.sourceType === sourceType && !property.default;
  }),
);

const baseGroupsSelector = (state) => get(state, 'properties.groups', []);
export const groupsSelector = createSelector(
  baseGroupsSelector,
  (groups) => groups.filter((group) => !group.default),
);

export const allGroupsSelector = createSelector(
  baseGroupsSelector,
  (groups) => groups,
);

export const groupsSourceTypeSelector = (sourceType, includedDefault = false) => createSelector(
  allGroupsSelector,
  (groups) => groups.filter((group) => {
    if (includedDefault) {
      return group.sourceType === sourceType;
    }
    return group.sourceType === sourceType && !group.default;
  }),
);

const baseSelectedPropertiesSelector = (state) => get(state, 'properties.selectedProperties', []);
export const selectedPropertiesSelector = createSelector(
  baseSelectedPropertiesSelector,
  (selectedProperties) => selectedProperties,
);

const baseSelectedPropertyGroupsSelector = (state) => get(state, 'properties.selectedPropertyGroups', []);
export const selectedPropertyGroupsSelector = createSelector(
  baseSelectedPropertyGroupsSelector,
  (selectedPropertyGroups) => selectedPropertyGroups,
);

const baseDeletedPropertiesSelector = (state) => get(state, 'properties.deletedProperties', {});
export const deletedPropertiesSelector = createSelector(
  baseDeletedPropertiesSelector,
  (properties) => properties || [],
);

export const deletedPropertiesSourceTypeSelector = (sourceType) => createSelector(
  deletedPropertiesSelector,
  (properties) => properties.filter((property) => property.sourceType === sourceType),
);

export const contactPropertiesSelector = createSelector(
  propertiesSelector,
  (properties) => {
    const filtered = properties.filter((item) => item.sourceType === 'contact');
    const modified = [];
    // for hubspot settings
    filtered.forEach((el) => {
      switch (el.name) {
        case 'countryCode': {
          modified.push(el,
            {
              name: 'country',
              label: 'Country',
              fieldType: 'text',
            });
          break;
        }
        case 'location': {
          el.label = 'Region';
          modified.push(el);
          break;
        }
        default:
          modified.push(el);
          break;
      }
    });

    modified.push({
      name: 'list',
      label: 'List',
      fieldType: 'text',
    });

    return modified;
  },
);

export const companyPropertiesSelector = createSelector(
  propertiesSelector,
  (properties) => properties.filter((item) => item.sourceType === 'company'),
);

export const filterPropertiesSelector = (sourceType, activeType, hasDefault = false) => {
  if (activeType === 'all') {
    return propertiesSourceTypeSelector(sourceType, hasDefault);
  }
  return deletedPropertiesSourceTypeSelector(sourceType);
};

const baseInitRequestPropertiesSelector = (state) => get(state, 'properties.initRequest', false);
export const initRequetPropertiesSelector = createSelector(
  baseInitRequestPropertiesSelector,
  (initRequest) => initRequest,
);
