import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';

import 'antd/dist/antd.css';
import 'material-icons/iconfont/filled.css';
import 'material-icons/iconfont/outlined.css';
import './common.css';

import configureStore from './data/store';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://111f5c11708a49a7993bc2da6fdfc39b@o560704.ingest.sentry.io/5696495',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
