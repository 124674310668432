import styled from 'styled-components';

import { MOBILE_MAX_WIDTH } from 'constants/styles';
import { HeaderWrapper } from '../signUp/styles';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  height: 96px;

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    width: 100%;
  }

  button {
    span {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const HeaderContainer = styled(HeaderWrapper)`
  margin-bottom: 0;

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    margin-bottom: 0;
  }
`;

export const Image = styled.img`
  display: flex;
  justify-content: center;
  width: 400px;
  height: 248px;
  margin: 30px 0;

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    width: 100%;
  }
`;

export const OrContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 400px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  margin: 10px 0;
`;

export const ChromeImageStyle = {
  marginRight: '5px',
  width: '19px',
  height: '19px',
};

export const LinkedinImageStyle = {
  marginRight: '5px',
  width: '18px',
  height: '18px',
  marginBottom: '2px',
};
