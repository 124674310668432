import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CREDITS_TYPE } from 'data/types/user.types';
import {
  userCreditsSelector,
  userCreditsVerificationSelector,
  demoUserSelector,
  userCreditsPhoneSearchSelector,
} from 'data/selectors/user';
import { toggleModal } from 'data/actions/modals';
import { companiesLimitSelector } from 'data/selectors/search';
import { currentPlanSelector } from 'data/selectors/subscriptions';
import useInsufficientCreditsMessage from './messageTemplates/useInsufficientCreditsMessage';

const usePaidOperation = (type, callbackSuccess, callbackError, minCredits) => {
  const dispatch = useDispatch();
  const { current: currentEmailSearch } = useSelector(userCreditsSelector);
  const { current: currentEmailVerification } = useSelector(userCreditsVerificationSelector);
  const { current: currentPhoneSearch } = useSelector(userCreditsPhoneSearchSelector);
  const { availableCompanies: companiesLimit } = useSelector(companiesLimitSelector);
  const currentPlan = useSelector(currentPlanSelector);
  const isDemoUser = useSelector(demoUserSelector);

  const currentDictionary = {
    [CREDITS_TYPE.EMAIL_SEARCH]: currentEmailSearch,
    [CREDITS_TYPE.EMAIL_VERIFICATION]: currentEmailVerification,
    [CREDITS_TYPE.PHONE_SEARCH]: currentPhoneSearch,
    [CREDITS_TYPE.SAVE_COMPANIES]: companiesLimit,
  };
  const current = currentDictionary[type];

  const showMessage = useInsufficientCreditsMessage();

  const pay = useCallback((params) => {
    if (!isDemoUser && (current <= 0 || (minCredits && current < minCredits))) {
      if (callbackError) callbackError(params);
      else if (type === CREDITS_TYPE.PHONE_SEARCH && currentPlan?.price <= 0) dispatch(toggleModal('not_support_phone_numbers', true));
      else showMessage();
    } else {
      callbackSuccess(params);
    }
  }, [current, showMessage, callbackSuccess, callbackError, minCredits, isDemoUser, currentPlan, dispatch, type]);

  return pay;
};

export default usePaidOperation;
