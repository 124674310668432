import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
} from 'antd';

import { Input, Button } from 'components/common';

import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { renameGroup } from 'data/actions/properties';
import { groupsSelector } from 'data/selectors/properties';
import {
  CloseIcon,
  ModalTitle,
  Label,
  Warning,
  InputStyle,
  Footer,
} from '../baseModal/styles';

const RenamePropertyGroupModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { groupName } = useSelector(modalOptionsSelector);
  const groups = useSelector(groupsSelector);

  const [name, updateName] = useState('');

  const isExist = groups.findIndex((item) => item.name === name);

  const handleRenamePropertyGroup = () => {
    dispatch(toggleModal('rename_property_group', false));
    dispatch(renameGroup(groupName, { name }));
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Rename the property group
          <CloseIcon
            onClick={() => dispatch(toggleModal('rename_property_group', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={() => dispatch(toggleModal('rename_property_group', false))}
    >
      <>
        <Label>Name*</Label>
        <Input
          autoFocus
          onChange={(e) => {
            updateName(e.target.value);
          }}
          value={name}
          placeholder="Type the property group name..."
          style={InputStyle}
        />

        {isExist >= 0 && (
          <Warning>
            {`${name} already exists. Please choose a new name.`}
          </Warning>
        )}
      </>
      <Footer>
        <Button
          data-form={!name || isExist >= 0 ? 'false' : 'true'}
          type="primary"
          onClick={handleRenamePropertyGroup}
          disabled={!name || isExist >= 0}
        >
          Save
        </Button>
        <Button type="normal" onClick={() => dispatch(toggleModal('rename_property_group', false))}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default RenamePropertyGroupModal;
