import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import get from 'lodash/get';
import { Input, TextArea } from 'components/common';

import { billingSelector } from 'data/selectors/user';
import { updateWorkspaceBillingInfo } from 'data/actions/user';
import { modalsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import * as CONSTANTS from 'constants/styles';

import {
  ModalTitle,
  Label,
  CloseIcon,
} from '../baseModal/styles';

import {
  ActionButton, EditAddressWrapper,
  PropertyItem,
} from './styles';
import { SystemModal } from '../../commonStyles';

const EditAddressModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const billing = useSelector(billingSelector);

  const [companyName, setCompanyName] = useState();
  const [address, setAddress] = useState();
  const [billingInfo, setBillingInfo] = useState();

  useEffect(() => {
    setCompanyName(get(billing, 'companyName'));
    setAddress(get(billing, 'companyAddress'));
    setBillingInfo(get(billing, 'billingInfo'));
  }, [billing]);

  const isEqual = get(billing, 'companyName') === companyName
  && get(billing, 'companyAddress') === address
  && get(billing, 'billingInfo') === billingInfo;

  const closeModal = () => {
    dispatch(toggleModal('edit_address', false));
  };

  const update = () => {
    const data = {
      companyName,
      companyAddress: address,
      billingInfo,
    };
    dispatch(updateWorkspaceBillingInfo(data));
    dispatch(toggleMessage('success', { header: 'Address information is updated' }));
    closeModal();
  };

  return (
    <SystemModal
      visible={visible}
      title={(
        <ModalTitle>
          Edit address
          <CloseIcon
            onClick={closeModal}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={520}
      footer={false}
    >
      <>
        <PropertyItem>
          <Label>
            Company name
          </Label>
          <Input
            size="large"
            placeholder="Your company name"
            value={companyName}
            onChange={(props) => setCompanyName(props.target.value)}
          />
        </PropertyItem>
        <PropertyItem>
          <Label>
            Address
          </Label>
          <Input
            placeholder="Address line"
            size="large"
            value={address}
            onChange={(props) => setAddress(props.target.value)}
          />
        </PropertyItem>
        <PropertyItem>
          <Label>
            Additional information
          </Label>
          <TextArea
            placeholder="VAC number etc ..."
            size="large"
            value={billingInfo}
            onChange={(props) => setBillingInfo(props.target.value)}
          />
        </PropertyItem>
        <EditAddressWrapper>
          <ActionButton
            data-form={isEqual ? 'false' : 'true'}
            color={CONSTANTS.COLORS.WHITE}
            background={CONSTANTS.COLORS.PRIMARY}
            disabled={isEqual}
            onClick={update}
          >
            Update
          </ActionButton>
          <ActionButton
            color={CONSTANTS.COLORS.GREY_G1}
            border={CONSTANTS.COLORS.GREY_G4}
            background={CONSTANTS.COLORS.WHITE}
            onClick={closeModal}
          >
            Cancel
          </ActionButton>
        </EditAddressWrapper>
      </>
    </SystemModal>
  );
};

export default EditAddressModal;
