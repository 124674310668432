import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useStepManager from 'hooks/useStepManager';

const StepManager = ({
  stepsComponent: StepsComponent,
  details,
  onClose,
  onFinish,
  onStep,
  deleteAccount,
}) => {
  const {
    stepNumber,
    setNextStep,
    setPreviousStep,
  } = useStepManager({
    stepFrom: 1,
    onStep,
  });
  const location = useLocation();
  const [isHiddenContent, setIsHiddenContent] = useState(false);

  const onFinishProxy = (params) => {
    setIsHiddenContent(true);
    if (onFinish) onFinish(params);
  };
  // Should be changed in future
  useEffect(() => {
    if (location?.pathname === '/login') {
      setIsHiddenContent(true);
    }
  }, [location]);

  if (isHiddenContent) return null;

  return (
    <StepsComponent
      {...details} // eslint-disable-line
      onClose={onClose}
      setNextStep={setNextStep}
      setPreviousStep={setPreviousStep}
      stepNumber={stepNumber}
      onFinish={onFinishProxy}
      deleteAccount={deleteAccount}
    />
  );
};

export default StepManager;
