import styled from 'styled-components';

import { TABLET_MAX_WIDTH } from 'constants/styles';

export const EditorContainer = styled.div`
  display: flex;

  & > :first-child {
    flex-grow: 9;
  }

  & > :nth-child(2) {
    flex-grow: 1;
    margin-left: 10px;
  }

  @media (max-width: ${TABLET_MAX_WIDTH}px) {
    flex-direction: column;

    & > :nth-child(2) {
      margin-left: 0px;
      margin-top: 10px;
    }
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;
