import React, {
  useState, useEffect, useCallback, useRef, useMemo,
} from 'react';
import {
  Modal, List,
} from 'antd';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

import { Search, Button } from 'components/common';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { moveListsToFolder, createFolder, moveListsWithFoldersToFolder } from 'data/actions/lists';

import getFolderChildren from 'helpers/getFolderChildren';
import getFolderBreadcrumbs from 'helpers/getFolderBreadcrumbs';
import { getFolderById } from 'helpers/folderTree';
import {
  Footer,
  ModalTitle,
  CloseIcon,
} from '../baseModal/styles';
import {
  Container,
  FooterActionTest,
  FooterMoveListButtonsGroup,
  FooterMoveListWrapper, HeaderListContainer,
  ListItem,
  ListItemText,
  ListWrapper, ModalTitleWrapper, MoveListSearch,
  ListFolderItem,
} from './styles';

import Icon from '../../common/icon';
import BlankFolder from './blankFolder';
import Breadcrumbs from './breadCrumbs';
import EmptyFolderPlaceholder from './emptyFolderPlaceholder';

const MoveListToFolder = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const {
    kind,
    folders,
    entityId,
    folderIds = [],
    folderRelations,
    entityIds = [],
    setSelectedRowKeys,
    excludedRowKeys = [],
  } = useSelector(modalOptionsSelector);

  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [currentFolderEntity, setCurrentFolderEntity] = useState([]);
  const [folderId, setFolderId] = useState('all');
  const [creatingNewFolder, setCreatingNewFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [searchRequest, setSearchRequest] = useState('');
  const [folderToMove, setFolderToMove] = useState();
  const [folderFrom, setFolderFrom] = useState();
  const inputRef = useRef({});
  const currentFolderData = getFolderById(folderId, folders);
  const existIds = [entityId, ...entityIds, ...folderIds].filter((item) => !!item);

  useEffect(() => {
    const entityFolderIds = [];

    if (folderIds.length) {
      const folderData = getFolderById(get(folderIds, '0'), folders);
      const folderParentId = get(folderData, 'parent');

      if (folderParentId) {
        entityFolderIds.push(folderParentId);
      }
    }

    folderRelations.forEach((item) => {
      if (get(item, `${kind}._id`, '') === entityId) entityFolderIds.push(get(item, 'folder._id'));
      if (entityIds.includes(get(item, `${kind}._id`, ''))) entityFolderIds.push(get(item, 'folder._id'));
    });
    setFolderFrom(get(entityFolderIds, '0', 'all'));
    setFolderId(get(entityFolderIds, '0', 'all'));
  }, [kind]); // eslint-disable-line

  useEffect(() => {
    const result = [...getFolderChildren(folderId, folders) || []];

    if (folderId !== 'all') {
      result.sort(() => -1);
    }

    folderRelations.forEach((folderRelation) => {
      const isExistList = result.findIndex((item) => item._id === get(folderRelation, `${kind}._id`));

      if (isExistList < 0 && folderRelation?.folder?._id === folderId) {
        result.push({ ...folderRelation[kind], entityId: get(folderRelation, `${kind}._id`), isList: true });
      }
    });
    setFolderToMove(folderId || 'all');
    setCurrentFolderEntity(searchRequest ? result.filter((item) => get(item, 'name', '').includes(searchRequest)) : result);
    setBreadCrumbs(getFolderBreadcrumbs(folderId, folders, [], 'Home'));
  }, [folderId, folderRelations, folders, kind, searchRequest]);

  const createFolderHandler = useCallback(() => {
    if (!creatingNewFolder) {
      setCurrentFolderEntity((prevState) => {
        const updateState = [...prevState];

        if (currentFolderEntity.every((folder) => !folder.isList)) {
          updateState.push({ blank: true });
        } else {
          const indexFirstList = currentFolderEntity.findIndex((folder) => folder.isList);
          updateState.splice(indexFirstList !== -1 ? indexFirstList : 0, 0, { blank: true });
        }
        return updateState;
      });
      setCreatingNewFolder(true);
    }
  }, [currentFolderEntity, creatingNewFolder]);

  useEffect(() => {
    if (creatingNewFolder && inputRef.current) {
      inputRef.current.focus();
    }
  }, [currentFolderEntity, creatingNewFolder]);

  const handleCreateFolder = useCallback(() => {
    if (newFolderName.length) {
      dispatch(createFolder({
        kind,
        name: newFolderName,
        parent: folderId === 'all' ? 'root' : folderId,
        onSuccess: (newFolder) => {
          setCurrentFolderEntity((prev) => {
            const items = prev.filter((item) => !item.blank);
            return [...items, newFolder, { blank: true }];
          });
        },
      }));
      setCreatingNewFolder(false);
      setNewFolderName('');
    }
  }, [newFolderName, folderId, kind, dispatch]);

  const handleDeleteBlankFolder = useCallback(() => {
    setCurrentFolderEntity((prevState) => prevState.filter((item) => !item.blank));
    setCreatingNewFolder(false);
    setNewFolderName('');
  }, []);

  const modalTitle = useMemo(() => {
    const type = entityIds.length || entityId ? 'entity' : 'folder';

    return (
      <ModalTitleWrapper>
        Moving
        {' '}
        {type}
      </ModalTitleWrapper>
    );
  }, [entityIds, entityId]);

  const changeInputHandler = useCallback((e) => {
    setNewFolderName(e.target.value);
  }, []);

  const handleClose = () => dispatch(toggleModal('move_list_to_folder', false));

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          {modalTitle}
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      width={520}
      footer={false}
      closable={false}
      onCancel={handleClose}
    >
      <Container>
        <ListWrapper showShadow={currentFolderEntity.length > 7}>
          <List
            locale={{ emptyText: <EmptyFolderPlaceholder folderName={get(currentFolderData, 'name', 'Home')} searchValue={searchRequest} /> }}
            header={(
              <HeaderListContainer>
                <Breadcrumbs
                  breadCrumbs={breadCrumbs}
                  folderId={folderId}
                  setCreatingNewFolder={setCreatingNewFolder}
                  setFolderId={setFolderId}
                />

                <Search
                  placeholder="Search lists"
                  style={MoveListSearch}
                  onChange={(e) => setSearchRequest(e.target.value)}
                />
              </HeaderListContainer>
            )}
            dataSource={currentFolderEntity.filter((item) => !existIds.includes(item._id))}
            renderItem={(item) => (
              <ListFolderItem
                onClick={(() => {
                  if (!item.isList && !item.blank) {
                    setCreatingNewFolder(false);
                    setFolderId(item._id);
                  }
                })}
              >
                {item.blank ? (
                  <BlankFolder
                    inputRef={inputRef}
                    newFolderName={newFolderName}
                    handleCreateFolder={handleCreateFolder}
                    changeInputHandler={changeInputHandler}
                    handleDeleteBlankFolder={handleDeleteBlankFolder}
                  />
                ) : (
                  <ListItem>
                    <Icon outlined={item.isList} type={item.isList ? 'ic-description' : 'ic-folder'} fill={item.isList ? 'default' : 'primary'} />
                    <ListItemText>
                      {item.name}
                    </ListItemText>
                    {
                    !item.isList && <Icon type="ic-chevron-right" className="list-chevron" />
                  }
                  </ListItem>
                )}
              </ListFolderItem>
            )}
          />
        </ListWrapper>
      </Container>
      <Footer>
        <FooterMoveListWrapper>
          <FooterActionTest disableAction={creatingNewFolder} onMouseDown={createFolderHandler}>Create folder</FooterActionTest>
          <FooterMoveListButtonsGroup>
            <Button type="normal" onClick={handleClose}>Cancel</Button>
            <Button
              data-form={folderFrom === folderToMove || creatingNewFolder || folderToMove === 'all' ? 'false' : 'true'}
              type="primary"
              onClick={() => {
                const ids = entityId ? [entityId] : entityIds;
                const idsFolders = folderIds;

                if (setSelectedRowKeys) {
                  setSelectedRowKeys([]);
                }
                handleClose();

                if (idsFolders.length) {
                  let root = false;
                  const rootId = get(folders, '_id');

                  if (folderId === 'all') {
                    root = true;
                  }
                  dispatch(moveListsWithFoldersToFolder({
                    kind,
                    data: {
                      kind,
                      entityIds: ids,
                      folderIds: idsFolders,
                      excludedIds: excludedRowKeys,
                      root,
                    },
                    folder: folderId === 'all' ? rootId : folderId,
                  }));
                } else {
                  dispatch(moveListsToFolder({
                    kind,
                    folderId: folderId === 'all' ? 'All lists' : folderId,
                    entityIds: ids,
                    excludedIds: excludedRowKeys,
                  }));
                }
              }}
              disabled={folderFrom === folderToMove || creatingNewFolder}
            >
              Move
            </Button>
          </FooterMoveListButtonsGroup>
        </FooterMoveListWrapper>
      </Footer>
    </Modal>
  );
};

export default MoveListToFolder;
