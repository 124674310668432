import { Modal, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Icon, Input } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { USER_STATUS } from 'data/types/user.types';
import { accountSelector } from 'data/selectors/user';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { requireVerification } from 'data/actions/user';

import {
  CloseIcon,
  ModalTitle,
  Footer,
  Warning,
} from '../baseModal/styles';
import { Wrapper } from './styles';

const VerifyAccountModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const paymentInfo = useSelector(modalOptionsSelector);
  const account = useSelector(accountSelector);

  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [incorrectFormat, toggleIncorrectFormat] = useState(false);

  const isPendingReview = account.status === USER_STATUS.PENDING_REVIEW;

  const handleInputChange = (value) => {
    if (incorrectFormat) {
      toggleIncorrectFormat(false);
    }
    setLinkedinUrl(value);
  };

  const handleClose = () => dispatch(toggleModal('verify_account', false));

  const requestVerification = () => {
    if (linkedinUrl.startsWith('https://www.linkedin.com/in/')) {
      dispatch(requireVerification({ ...paymentInfo, linkedinUrl }));
      handleClose();
    } else {
      toggleIncorrectFormat(true);
    }
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Verify your account
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      width={500}
      footer={false}
      onCancel={handleClose}
      closable={false}
    >
      {
        account.status !== USER_STATUS.PENDING_REVIEW ? (
          <>
            <Input
              value={linkedinUrl}
              placeholder="Please, add your personal LinkedIn account"
              onChange={(e) => handleInputChange(e.target.value)}
            />
            {incorrectFormat && (
              <Warning>
                wrong format
              </Warning>
            )}
            <Wrapper>
              <p style={{ padding: 0, margin: 0 }}>
                To make purchase, your account needs additional review.
                <Tooltip title="The link to your LinkedIn profile should be in this format: https://www.linkedin.com/in/yournickname" mouseLeaveDelay={0}>
                  <div style={{ display: 'inline' }}>
                    <Icon
                      type="ic-info"
                      size={18}
                      fill="default"
                      style={{
                        cursor: 'default',
                        position: 'absolute',
                        marginLeft: 2,
                        marginTop: 1,
                      }}
                    />
                  </div>
                </Tooltip>
              </p>
            </Wrapper>
          </>
        ) : <div>Your review is still in progress. We will try to process it as soon as possbile.</div>
      }
      <Footer>
        <Button
          data-form={isPendingReview || (!linkedinUrl && account.status === USER_STATUS.NOT_ALLOWED_PAYMENT) ? 'false' : 'true'}
          type="primary"
          size="large"
          loading={isPendingReview}
          disabled={!linkedinUrl && account.status === USER_STATUS.NOT_ALLOWED_PAYMENT}
          onClick={requestVerification}
        >
          {
            isPendingReview ? 'Pending review' : 'Request review'
          }
        </Button>
        <Button
          size="large"
          type="normal"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Footer>
    </Modal>
  );
};

export default VerifyAccountModal;
