import { Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getValueOfInput } from 'helpers/dom';
import { validateEmail } from 'helpers/validate';
import { Button, Input } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { modalsSelector } from 'data/selectors/modals';
import { sendTestCampaign } from 'data/actions/sequences';
import {
  leadReviewIdSelector,
  sequenceAccountEmailSelector,
} from 'data/selectors/sequences';
import { sendTestCampaignLoaderSelector } from 'data/selectors/loading';
import {
  ModalTitle, CloseIcon, Footer,
} from '../baseModal/styles';
import { Label, Description } from './styles';

const SendTestCampaignModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);

  const email = useSelector(sequenceAccountEmailSelector);
  const loading = useSelector(sendTestCampaignLoaderSelector);
  const leadReviewId = useSelector(leadReviewIdSelector);

  const [testEmail, setTestEmail] = useState(email);

  const handleClose = () => dispatch(toggleModal('send_test_campaign', false));
  const handleSendTestCampaign = () => {
    dispatch(sendTestCampaign({ sendTo: testEmail, contactId: leadReviewId }, () => {
      dispatch(toggleMessage('success', { header: `Please, go to ${testEmail} inbox to check the sequence` }));
      handleClose();
    }));
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Send test campaign
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={420}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        <Label>Email</Label>
        <Input
          error={validateEmail(testEmail) ? undefined : 'Email is incorrect'}
          value={testEmail}
          onChange={getValueOfInput(setTestEmail)}
        />
        <Description>The email version will be sent randomly. You will receive all emails during the 2 minutes.</Description>
      </div>
      <Footer>
        <Button
          data-form="true"
          type="primary"
          size="large"
          disabled={!validateEmail(testEmail)}
          loading={loading}
          onClick={handleSendTestCampaign}
        >
          Send
        </Button>
        <Button
          size="large"
          type="normal"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Footer>
    </Modal>
  );
};

export default SendTestCampaignModal;
