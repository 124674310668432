import axios from 'axios';
import get from 'lodash/get';

import { CANCEL_REQUESTS } from 'data/types/event.types';
import triggerLoadingAction from '../../helpers/loader';

export default () => () => (next) => async (action) => {
  if (!action) return false;
  const endpoint = get(action, 'payload.endpoint');
  const isCustom = get(action, 'payload.custom', false);
  const method = get(action, 'payload.method');
  const headers = get(action, 'payload.headers', null);
  const body = get(action, 'payload.body');
  const params = get(action, 'payload.params', null);
  const onSuccess = get(action, 'onSuccess', () => {});
  const onFailed = get(action, 'onFailed', () => {});
  const components = get(action, 'components', null);
  const cancelToken = get(action, 'payload.cancelToken');
  const cancelEvent = get(action, 'payload.cancelEvent');

  if (!isCustom) {
    return next(action);
  }

  let loading = true;
  setTimeout(() => {
    if (loading) {
      triggerLoadingAction(components, true, next);
    }
  }, 1000);

  try {
    const res = await axios({
      url: endpoint,
      method,
      headers,
      data: body,
      params,
      cancelToken,
    });

    if (cancelEvent) window.removeEventListener(CANCEL_REQUESTS, cancelEvent);

    const data = get(res, 'data');
    if (onSuccess) onSuccess(data);
    loading = false;
    triggerLoadingAction(components, false, next);

    return next({
      ...action,
      payload: data,
    });
  } catch (err) {
    loading = false;
    triggerLoadingAction(components, false, next);
    if (onFailed) return onFailed(err);
    return false;
  }
};
