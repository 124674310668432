import styled from 'styled-components';
import * as CONSTANTS from 'constants/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
`;

export const Half = styled.div`
  width: 49%;

  &:first-child {
    border-right: 1px solid #dfe3eb;
  }
`;

export const ModalWrapper = styled.div`
  background-color: red;
`;

export const BlueOutlinedButtonStyle = {
  fontFamily: 'AvenirNext',
  fontSize: '14px',
  fontWeight: '600',
  fontStretch: 'normal',
  fontstyle: 'normal',
  lineHeight: '1.57',
  letterSpacing: 'normal',
  color: '#1890ff',
  textDecoration: 'underline',
};
export const PropertyItem = styled.div`
  padding: 8px 0;
  font-size: 5px;
  margin-left: 15px;
  width: 345px;
  span {
    font-size: 15px;
  }
`;

export const PropertiesContainer = styled.div`
  margin: 15px 0;
`;

export const SelectedPropertiesContainer = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 25px;
    background: linear-gradient(white, rgba(255, 255, 255, 0.001));
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25px;
    background: linear-gradient(rgba(255, 255, 255, 0.001), white);
  }
`;

export const ScrollContainer = styled.div`
    overflow-y: scroll;
    background: white;
    width: 100%;
    height: 282px;
    padding-right: 15px;
    box-sizing: content-box;
`;

export const PropertiesWrapper = styled.div`
  height: 250px;
  width: 330px;
  overflow-y: scroll;
  border-radius: 2px;
  border: solid 1px #dfe2e5;
  background-color: #ffffff;
  margin-top: 20px;
`;

export const PropertyGroup = styled.span`
  padding-left: 20px;
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
`;

export const ParagraphText = styled.p`
  padding-top: 20px;
  font-family: AvenirNext;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #4f5964;
`;
export const Label = styled.span`
  font-family: AvenirNext;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
`;

export const SelectedItem = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 10px 10px 10px 6px;
  margin: 10px 0;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #dfe2e5;
  background-color: #f7f8fa;
  cursor: ${(props) => (props.isDraggable ? 'pointer' : '')};
  align-items: center;
  justify-content: space-between;
  user-select: none;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.25px;
  color: #4f5964;

  &:hover, &:focus {
    border: 1px solid ${CONSTANTS.COLORS.PRIMARY};

    .selected-item-close {
      opacity: 1 !important;
    }
  }

  span.selected-item-close:hover {
    cursor: pointer !important;
  }

  &:first-child {
    &:before { content: none }
  }
`;

export const CheckboxText = {
  fontFamily: 'AvenirNext',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.71',
  letterSpacing: '0.25px',
  color: '#4f5964',
  borderColor: '#bec4ca',
};

export const Footer = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;

  button {
    margin-right: 10px;
  }
`;

export const CloseIconStyle = {
  fontSize: 11,
  color: 'rgb(124, 152, 182)',
  padding: '15px 10px',
  textAlign: 'center',
  marginRight: '-10px',
  opacity: 0,
  transition: '0.3s all',
};

export const CreatePropertyLink = styled.span`
  font-weight: 600;
  color: #1890ff;
  cursor: pointer;

  transition: 0.2s all;

  &:hover {
    text-decoration: underline;
  }
`;
