import { Modal } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { isNumber } from 'helpers/regularExpressions';
import { isDisabledPauseSelector } from 'data/selectors/subscriptions';
import {
  Button,
  InputNumber,
  Radio,
  RadioGroup,
} from 'components/common';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../../../../modals/baseModal/styles';
import { RadioContainer } from './styles';

const PauseQuestion = ({
  onClose,
  onNext,
}) => {
  const isDisabledPause = useSelector(isDisabledPauseSelector);

  const MINIMUM_MONTHS = 1;
  const MAXIMUM_MONTHS = 3;
  const PERIOD_MONTHS = '1-3 months';
  const MORE_MONTHS_VALUE = 'more than 3 months';

  const [pausingPeriod, setPausingPeriod] = useState(PERIOD_MONTHS);
  const [customPausingPeriod, setCustomPausingPeriod] = useState(MINIMUM_MONTHS);

  return (
    <Modal
      visible
      title={(
        <ModalTitle>
          When do you plan to return to lead search?
          <CloseIcon
            onClick={onClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={onClose}
    >
      <div>
        <RadioGroup value={pausingPeriod} onChange={(e) => setPausingPeriod(e.target.value)}>
          <RadioContainer>
            <Radio value={PERIOD_MONTHS}>{`${PERIOD_MONTHS} ${isDisabledPause ? '' : '(safe 30%)'}`}</Radio>
            {
              isDisabledPause && pausingPeriod === PERIOD_MONTHS && (
                <InputNumber
                  size="small"
                  value={customPausingPeriod}
                  onChange={(value) => {
                    if (!value || !isNumber(value)) setCustomPausingPeriod(MINIMUM_MONTHS);
                    else if (value > MAXIMUM_MONTHS) setCustomPausingPeriod(MAXIMUM_MONTHS);
                    else setCustomPausingPeriod(value);
                  }}
                  min={MINIMUM_MONTHS}
                  max={MAXIMUM_MONTHS}
                />
              )
            }
          </RadioContainer>
          <RadioContainer>
            <Radio value={MORE_MONTHS_VALUE}>{`${MORE_MONTHS_VALUE} ${isDisabledPause ? '' : '(miss 30% discount)'}`}</Radio>
          </RadioContainer>
        </RadioGroup>
        <Footer>
          <Button
            data-form="true"
            type="primary"
            disabled={!pausingPeriod || (isDisabledPause && pausingPeriod === PERIOD_MONTHS && !customPausingPeriod)}
            onClick={() => {
              let period = pausingPeriod;
              if (pausingPeriod === MORE_MONTHS_VALUE) period = null;
              else if (customPausingPeriod) period = customPausingPeriod;
              onNext({ pausingPeriod: period });
            }}
          >
            Next
          </Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default PauseQuestion;
