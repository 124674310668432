import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';

import { toggleModal } from 'data/actions/modals';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { getSequenceLeads, removeSequenceLeads } from 'data/actions/sequences';
import { Container } from '../deleteStep/styles';
import {
  ModalTitle, CloseIcon, Footer,
} from '../baseModal/styles';

const DeleteSequenceContactsModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const {
    sequenceId,
    contactIds,
    setSelectedRowKeys,
    onSuccess,
  } = useSelector(modalOptionsSelector);

  const handleClose = () => dispatch(toggleModal('delete_sequence_contacts', false));
  const handleDelete = () => {
    dispatch(removeSequenceLeads(sequenceId, contactIds, (result) => {
      if (!result.pending) {
        dispatch(getSequenceLeads({ pageNumber: 1 }));
      }
      handleClose();
      if (onSuccess) onSuccess(result);
    }));
    if (setSelectedRowKeys) setSelectedRowKeys([]);
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Delete
          {' '}
          {contactIds.length === 1 ? 'contact' : 'contacts'}
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <Container>
        <div>
          You&#39;re about to delete the
          {' '}
          {contactIds.length === 1 ? 'contact' : 'contacts'}
          . Deleted
          {' '}
          {contactIds.length === 1 ? 'contact' : 'contacts'}
          {' '}
          can&#39;t be restored
        </div>

      </Container>
      <Footer>
        <Button
          data-form="true"
          type="danger"
          size="large"
          onClick={handleDelete}
        >
          Delete
        </Button>
        <Button
          size="large"
          type="default"
          onClick={handleClose}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default DeleteSequenceContactsModal;
