import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.span`
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: ${COLORS.BLACK_G1};
`;

export const MethodsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  height: 100%;
  gap: 15px;
`;

export const MethodContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 2px;
  background-color: ${COLORS.GREY_G5};
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.WHITE};
    border: 1px solid ${COLORS.PRIMARY};
  }
`;

export const MethodHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 5px;
  color: ${COLORS.BLACK_G1};
`;

export const MethodSubText = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: ${COLORS.GREY_G2};
  padding: 0 20px;
  text-align: center;
`;
