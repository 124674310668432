import {
  Radio as RadioA,
  Checkbox as CheckboxA,
  Input as InputA,
  Select as SelectA,
  AutoComplete as AutoCompleteA,
} from 'antd';
import Border from './border';

const { TextArea } = InputA;
const { Option } = SelectA;
const { OptGroup } = SelectA;
const { Group: CheckboxGroup } = CheckboxA;
const { Option: AutoCompleteOption } = AutoCompleteA;
const { Group: RadioGroup, Button: RadioButton } = RadioA;

export {
  Option,
  RadioGroup,
  CheckboxGroup,
  TextArea,
  RadioButton,
  AutoCompleteOption,
  OptGroup,
  Border,
};

export { default as Icon } from './icon';
export { default as Tag } from './customTag';
export { default as Radio } from './customRadio';
export { default as Input } from './customInput';
export { default as Select } from './customSelect';
export { default as Search } from './customSearch';
export { default as Button } from './customButton';
export { default as PhoneInput } from './phoneInput';
export { default as Password } from './customPassword';
export { default as Checkbox } from './customCheckbox';
export { default as TimerButton } from './timerButton';
export { default as RangeCalendar } from './rangeCalendar';
export { default as TimezoneSelect } from './timezoneSelect';
export { default as AutoComplete } from './customAutoComplete';
export { default as ConfirmationModal } from './confirmationModal';
export { default as InputNumber } from './customInputNumber';
