import styled from 'styled-components';
import { transparentize } from 'polished';
import { Tree, Select } from 'antd';

import {
  Button, Icon, Tag, Checkbox,
} from 'components/common';
import * as CONSTANTS from 'constants/styles';

const { TreeNode } = Tree;
const { Option } = Select;
const TOP_BAR_AND_HEADER_HEIGHT = CONSTANTS.TOP_BAR_HEIGHT + CONSTANTS.HEADER_HEIGHT;

export const Container = styled.div`
  width: ${CONSTANTS.SIDEBAR_WIDTH}px;
  position: inherit;
  left: ${CONSTANTS.SIDEBAR_LEFT}px;
  margin-top: ${CONSTANTS.CONTENT_MARGIN_TOP}px;
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.25px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - ${TOP_BAR_AND_HEADER_HEIGHT}px - 10px - ${(props) => (props.showBar ? CONSTANTS.CREDIT_BAR_HEIGHT : 0)}px);
  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    display: none;
  }
`;

export const ScrolledWrapper = styled.div`
  overflow:visible;
  width: ${CONSTANTS.SIDEBAR_WIDTH}px;
  padding-bottom: 10px;
`;

export const SearchContainer = styled(Container)`
  width: ${CONSTANTS.SIDEBAR_SEARCH_WIDTH + 15}px;
  min-height: 80%;
`;

export const SearchScrolledWrapper = styled(ScrolledWrapper)`
  width: ${CONSTANTS.SIDEBAR_SEARCH_WIDTH}px;
`;

export const ListItem = styled.div`
    border: none;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 42px;
    display: flex;
    font-weight: 500;
    align-items: center;

    will-change: transform;
    transform: scale3d(0.999, 0.999, 1);
    transition: transform 200ms ease;
    
    color: ${(props) => (props.active ? CONSTANTS.COLORS.PRIMARY : CONSTANTS.COLORS.BLACK_G1)};
    background-color: ${(props) => (props.active ? CONSTANTS.COLORS.PRIMARY_P4 : 'none')};
    border-radius: ${(props) => (props.active ? '4px' : 0)};
    
    &:focus {
        outline: 0;
    }

    &:hover {
      background-color: ${CONSTANTS.COLORS.PRIMARY_P4};
      color: ${CONSTANTS.COLORS.PRIMARY};
      border-radius: 4px;
    }

    &:active {
      transform: scale3d(0.95, 0.95, 1);
    }

    transition: all .15s ease-out;

    &:not(:hover) {
      transition: all .2s ease-out;
    }
`;

export const ListItemText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AddFilter = styled.button`
  background: none;
  border: none;  
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.25px;
  color: ${CONSTANTS.COLORS.PRIMARY};

  img {
    width: 22px;
  }
    
  span {
    line-height: 1;
  }

  &:hover {
    span:first-child {
      text-decoration: underline;
    }
  }

  &:focus {
    outline: 0;
  }
`;

export const ContactAddFilter = styled(AddFilter)`
`;

export const AllSavedFilters = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-items: center;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${CONSTANTS.COLORS.PRIMARY};

  &:hover {
    & > :first-child {
      text-decoration: underline;
    }
  }

  &:focus {
    outline: 0;
  }
`;

export const GroupName = styled.span`
  padding: 10px 7px;
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.25px;
  color: ${CONSTANTS.COLORS.PRIMARY};
  display: flex;
`;

export const PropertiesGroup = styled.div`
  margin-top: 10px;
`;

export const PropertyItem = styled.div`
  text-align: left;
  padding: 8px 7px;
  box-sizing: border-box;
  cursor: pointer;
  color: rgb(51, 71, 91);
  background-color: ${(props) => (props.disabled ? 'rgb(234, 240, 246) !important' : 'none')};
  color: ${(props) => (props.disabled ? 'rgb(176, 193, 212) !important' : 'rgb(51, 71, 91)')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
    
  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: rgb(234, 240, 246);
    border-radius: 3px;
  }

  transition: all .15s ease-out;
`;

export const FilterEditorContainer = styled.div`

  .ant-radio-wrapper {
    margin: 5px 0;
  }
`;

export const FilterAddItemLink = styled.span`
  color: ${CONSTANTS.COLORS.PRIMARY};
  font-size: 12px;
  
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const FilterAddFromFile = styled.span`
  color: ${CONSTANTS.COLORS.PRIMARY};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;

  :hover {
    opacity: .7;
  }
`;

export const FilterAddItemContainer = styled.div`
  display: flex;

  ${FilterAddItemLink}:first-child {
    flex: 1;
  }
`;

export const ListsContainer = styled.div`
  margin-top: 9.5px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  margin-bottom: 10px;
  padding-left: 10px;
  margin-right: 3px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
`;

export const ListTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.25px;
  color: #071427;
  width: auto;
  max-width: 150px;
`;

export const CreateFilterStyle = {
  borderRadius: '4px',
  backgroundColor: CONSTANTS.COLORS.PRIMARY,
  fontWeight: '600',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.71',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: CONSTANTS.COLORS.WHITE,
};

export const CancelFilterStyle = {
  borderRadius: '4px',
  backgroundColor: CONSTANTS.COLORS.WHITE,
  fontWeight: '600',
  border: 'solid 1px #dfe2e5',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.71',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: CONSTANTS.COLORS.GREY_G1,
};

export const ListCount = styled.div`
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.25px;
  text-align: right;
  color: ${CONSTANTS.COLORS.GREY_G2};
  margin-left: auto;
  padding-right: 7px;
`;

export const ContactsListCountStyles = {
  color: CONSTANTS.COLORS.GREY_G1,
  opacity: 0.5,
};

export const ListContainer = styled.div`
  display: flex;
  padding: 10px;
  padding-right: 3px;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.active ? transparentize(0.8, CONSTANTS.COLORS.PRIMARY) : 'none')};
  border-radius: ${(props) => (props.active ? '4px' : 0)};
  cursor: pointer;
  user-select: none;

  ${ListTitle} {
    font-weight: ${(props) => (props.active ? '600' : 'normal')};
    color: ${(props) => (props.active ? CONSTANTS.COLORS.PRIMARY : CONSTANTS.COLORS.BLACK_G1)};
  }

  .recent-lists-popover {
    display: none;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: ${transparentize(0.8, CONSTANTS.COLORS.PRIMARY)};
    border-radius: 4px;

    .recent-lists-popover {
      display: flex;
    }
    ${ListCount} {
      display: none;
    }
  }

  ${(props) => props.activeActions && `
    background-color: ${transparentize(0.8, CONSTANTS.COLORS.PRIMARY)};
    border-radius: 4px;

    .recent-lists-popover {
      display: flex;
    }

    ${ListCount} {
      display: none;
    }
  `}

  &:active {
    transform: scale3d(0.95, 0.95, 1);
  }

  transition: all .15s ease-out;

  &:not(:hover) {
    transition: all .3s ease-out;
  }
`;

export const ControlGroup = styled.div`
  display: flex;
  padding-top: 10px;
  button {
    margin-right: 10px;
  }
`;

export const CloseIcon = { fontSize: 12 };

export const BackIcon = { fontSize: 10 };

export const ApplyFilterButton = { marginTop: 20 };

export const CurrentListContainer = styled.div`
  margin: 20px 0 8px 0;
  padding-left: 10px;
`;

export const ContactsCurrentListContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const CurrentListTitle = styled.div`
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.25px;
  color: ${CONSTANTS.COLORS.GREY_G1};
`;

export const CurrentListDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 21px;
  padding-right: 8px;
`;

export const ContactsCount = styled.small`
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.25px;
  color: ${CONSTANTS.COLORS.GREY_G1};
  opacity: .5;
`;

export const CheckboxItem = { width: '100%', margin: 0, fontSize: '13px' };

export const InputNumberItem = { margin: '5px 0', width: '70%' };

export const FilterTag = styled(Tag)`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 55px;
  max-width: 220px;
  margin-bottom: 5px;
  position: relative;
  padding-right: 23px;
  flex-basis: 100%;
  margin-right: 5px;

  :last-child {
    margin-bottom: 10px;
  }

  > p {
    align-self: flex-start;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
    margin: 0;
    max-width: 100%;
  }
  
  .icon-tag {
    display: none;
    height: 100%;
    width: fit-content;
    padding: 0 5px;
    justify-content: center;
    position: absolute;
    flex-direction: column;
    right: 23px;
    object-fit: contain;
    border-right: 1px solid ${CONSTANTS.COLORS.WHITE};
    border-left: 1px solid ${CONSTANTS.COLORS.WHITE};
    cursor: pointer;
  }

  &:hover {
    p {
      width: calc(100% - 26px);
    }
    .icon-tag {
      display: inline-flex;
      svg {
        width: 15px;
        height: 15px;
        color: ${CONSTANTS.COLORS.WHITE};
      }
    }
  } 
  .anticon-close {
    position: absolute;
    right: 5px;
    object-fit: contain;
    svg {
      width: 15px;
      height: 15px;
      color: ${CONSTANTS.COLORS.WHITE};
    }
  }
`;

export const TagsContainer = styled.div`
  margin-top: 10px;
`;

export const FilterOperatorTitle = styled.span`
  display: block;
  height: 15px;
  font-family: AvenirNext;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: ${CONSTANTS.COLORS.GREY_G2};
  margin-bottom: 10px;
`;

export const FilterSuggestOptions = styled.div`
  color: ${CONSTANTS.COLORS.PRIMARY};
`;

export const FilterSuggestOptionText = styled.div`
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  cursor: pointer;
  width: fit-content;
`;

export const FilterOptionItem = styled.div`
  min-height: 28px;
  padding: 4px 8px;
  color: ${CONSTANTS.COLORS.PRIMARY};

  :hover {
    color: ${CONSTANTS.COLORS.BLUE};
    background-color: ${CONSTANTS.COLORS.PRIMARY_P4};
    cursor: pointer;
    border-radius: 4px;
  }
`;

export const FilterOptionItemsWrapper = styled.div``;

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .ant-tree-treenode-switcher-close {
    min-height: 32px;
    margin: 0;
    transition: .2 all;

    :hover {
      position: relative;
      z-index: 1;

      :before {
        content: '';
        position: absolute;
        width: 250px;
        height: 100%;
        background-color: red;
        top: 0;
        left: 0;
        background-color: ${CONSTANTS.COLORS.PRIMARY_P4};
        z-index: -1;
      }
      .ant-tree-node-content-wrapper {
        :hover {
          > span {
            color: ${CONSTANTS.COLORS.PRIMARY};
          }
        }
      }
    }
  }

  .ant-tree-treenode-switcher-open {
    > span {
      display: inline-block;
      min-height: 32px;      
    }

    > .ant-tree-checkbox {
      padding: 8px 4px 9px 2px !important;
    }

    > .ant-tree-node-content-wrapper {
      padding-top: 0px;
      
      > .ant-tree-title {
        margin-bottom: 0px;
        padding-top: 3px;
      }
    }

    :has(> span:hover) {
      background-color: ${CONSTANTS.COLORS.PRIMARY_P4};

      .ant-tree-node-content-wrapper {
        :hover {
          > span {
            color: ${CONSTANTS.COLORS.PRIMARY};
          }
        }
      }

      > ul {
        background-color: ${CONSTANTS.COLORS.WHITE};
      }
    }
  }

  .ant-tree li,
  .ant-tree > li:first-child {
    /* margin: 3px 0; */
    padding: 0;
  }

  .ant-tree {
    max-height: ${(props) => (props.radioGroupExists ? 210 : 275)}px;
    overflow-y: scroll;
    overflow-x: hide;

    ${(props) => {
    if (props.isIndustry) {
      return 'max-height: 275px;';
    }
    return '';
  }}

    .ant-tree-title {
      color: ${CONSTANTS.COLORS.GREY_G1};
      transition: .2s color;
    }
  }

  .ant-tree li span.ant-tree-checkbox {
    height: 15px;
    padding: 0 4px 0 2px;
    margin: 0;
    vertical-align: -webkit-baseline-middle;
  }

  .ant-tree-checkbox-inner {
    width: 15px;
    height: 15px;
    border-width: 1.25px;

    ::after {
      left: 2.1px;
      top: 5px;
    }
  }

  .ant-tree-checkbox-indeterminate {
    .ant-tree-checkbox-inner {
      border-color: ${CONSTANTS.COLORS.PRIMARY};
      background-color: ${CONSTANTS.COLORS.PRIMARY};

      ::after {
        top: 50%;
        left: 50%;
        width: 7px;
        height: 2px;
        background-color: ${CONSTANTS.COLORS.WHITE};
      }
    }
  }

  span.ant-tree-checkbox.ant-tree-checkbox-checked {
    :after {
      border: none;
    }
  }

  .ant-tree-checkbox {
    padding-bottom: 19px;
  }

  .ant-tree-node-content-wrapper {
    font-size: 14px;
  }

  .ant-tree li {
    transition: none;
    white-space: break-spaces;

    :hover {
      cursor: pointer;
    }

    .ant-tree-node-content-wrapper {
      display: inline;
      line-height: 20px;
      vertical-align: text-top;

      :hover, &.ant-tree-node-selected {
        background-color: transparent;
      }
    }
    ul {
      padding: 0;

      li {
        padding-left: 42px;
        /* display: flex;
        white-space: break-spaces; */
        width: 245px;
        /* align-items: center; */

        .ant-tree-checkbox {
          height: 20px;
          padding: 5.5px 4px 4px 2px;
          margin: 0;
          vertical-align: -webkit-baseline-middle;
          margin-bottom: 4px;
        }

        .ant-tree-node-content-wrapper {
          display: inline-block;
          padding-top: 4.5px;
          padding-bottom: 4px;
          line-height: 20px;
          height: auto;
          width: 100%;
          max-width: 175px;

          :hover, &.ant-tree-node-selected {
            background-color: transparent;
          }
        }
      }
    }
  }

  .ant-tree li span.ant-tree-switcher {
    width: auto;
    padding-right: 3px;
    margin-right: 0;
    padding-left: 3px;
    padding-top: 2px;

    .ant-tree-switcher_open {
      > span {
     }
    }
    
     > span {
       margin-top: 4px;
     }
  }
  .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close > span {
    transform: rotate(0deg);
    transition: 0.3s all;
  }
  .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open > span {
    transform: rotate(90deg);
    transition: 0.3s all;
    color: ${CONSTANTS.COLORS.GREY_G2} !important;

    ${(props) => (props.forceExpanded ? `
      cursor: not-allowed;
    ` : '')}
  }

  .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon, :root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
    font-size: 10px;

    :hover {
      color: ${CONSTANTS.COLORS.GREY_G2} !important;
    }
  }

  ${(props) => (props.isList ? `
    .ant-tree {
      li {
        padding-left: 10px !important;
      }

      ${props.radioGroupExists ? `
        .ant-tree-node-content-wrapper {
          line-height: 28px !important;
        }
      ` : ''}

      .ant-tree-title {
        max-width: 200px;
        display: inline-block;
      }
    }
  ` : '')}

`;

export const ControlsWrapper = styled.div`
  margin-bottom: 10px;
  padding-top: 2px;
  position: relative;
  z-index: 1;
`;

export const ContentWrapper = styled.div`
  padding-left: 15px;
  padding-right: 10px;
  padding-bottom: 10px;
`;

export const FilterItemWrapper = styled.div`
  border: none !important;
  border-radius: 0 !important;
  border-top: ${(props) => (props.active ? `1px solid ${CONSTANTS.COLORS.PRIMARY} !important` : `1px solid ${CONSTANTS.COLORS.GREY_G4} !important`)};
  text-align: left;
  color: rgb(51, 71, 91);
    
  &:focus {
    outline: 0;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 10px 10px 10px 16px;
    user-select: none;
    font-size: 13px;
    font-weight: 500;
    color: ${CONSTANTS.COLORS.BLACK_G1} !important;
    background: ${(props) => (props.active ? CONSTANTS.COLORS.PRIMARY_P4 : 'inherit')};
    transition: none;

    ${(props) => (props.areOptionExists ? `
      padding-bottom: 0;
    ` : '')}

    &:hover .ic_plus {
      background-color: ${CONSTANTS.COLORS.PRIMARY_P4};
      border-radius: 50%;
    }
  }
`;

export const ControlWrapper = styled.div`
  display: flex;
  align-items: center;

  .ic_minus {
    background-color: ${CONSTANTS.COLORS.PRIMARY_P4};
    border-radius: 50%;
  }
`;

export const ClearFilterTag = styled(Tag)`
  position: relative;
  color: ${CONSTANTS.COLORS.PRIMARY};
  border: 1px solid ${CONSTANTS.COLORS.PRIMARY};
  background-color: ${CONSTANTS.COLORS.WHITE};
  height: 20px;
  min-width: 34px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 3px 0 6px;
  transition: .2s all;
  margin-right: 10px;

  .anticon-close {
    object-fit: contain;
    vertical-align: 0;
    margin-left: 1px;

    svg {
      transition: .2s all;
      width: 14px;
      height: 14px;
      color: ${CONSTANTS.COLORS.PRIMARY};
    }
  }

  :hover {
    color: ${CONSTANTS.COLORS.WHITE};
    background-color: ${CONSTANTS.COLORS.PRIMARY};

    .anticon-close {
      svg {
        color: ${CONSTANTS.COLORS.WHITE};
      }
    }
  }
`;

export const InputClearImg = styled(Icon).attrs(() => ({
  type: 'ic-close',
}))`
  cursor: pointer;
`;

export const SettingMenuWrapper = styled.div`
  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    display: none;
  }
`;

export const BackButton = styled(Button)`
  margin: 10px 0 0 10px;
  width: ${(props) => (props.width ? props.width : 135)}px;
`;

export const Wrapper = styled.div``;

export const SkeletonTitleStyle = {
  margin: '10px 0',
};

export const TextAreaStyles = {
  resize: 'none',
  height: 32,
  overflow: 'hidden',
  zIndex: 1,
  backgroundColor: 'transparent',
};

export const InputDatesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .ant-select-focused {
    .ant-select-selection--single {
      .ant-select-arrow {
        color: ${CONSTANTS.COLORS.PRIMARY};
      }
    }
  }
`;

export const InputDatesDivider = styled.div`
  border: 1px solid ${CONSTANTS.COLORS.GREY_G3};
  height: 1px;
  width: 11.67px;
  margin: 0 9px 0 9px;

  .ant-select-open .ant-select-selection {
    border-color: ${CONSTANTS.COLORS.PRIMARY};
  }
`;

export const AbsoluteWrapper = styled.div`
  border-radius: 4px;
  display: ${(props) => (props.showTree ? 'inline-block' : 'none')};
  position: absolute;
  top: ${(props) => props?.top + 39 ?? 0}px;
  width: 100%;
  max-height: 380px;
  max-width: 250px;
  background-color: ${CONSTANTS.COLORS.WHITE};
  z-index: 1;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06), 0px 3px 5px rgba(0, 0, 0, 0.1);

  > ul > li > span > .ant-tree-title {
      display: inline-block;
      width: 185px;
      padding-top: 3.5px;
      padding-bottom: 4px;
    }
`;

export const FilterTree = styled(Tree)``;
export const FilterTreeNode = styled(TreeNode)``;

export const AutoCompliteItem = styled.div`
  width: 250px;
  height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  padding: 4px 11px;
  font-size: 14px;
  position: absolute;
  top: 2px;
  color: ${CONSTANTS.COLORS.GREY_G3};
  z-index: -1;
  letter-spacing: initial;
  left: 0;
  border: 1px solid transparent;
  white-space: nowrap;
  overflow: hidden;
`;
export const RadioGroupWrapper = styled.div`
  .ant-radio-button-wrapper {
    line-height: 24px
  }
`;
export const RadioBtnStyle = {
  fontWeight: 500,
  fontSize: 12,
  textAlign: 'center',
  width: 115,
  height: 24,
};
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 18px 10px 12px 10px;
`;
export const TreeContainer = styled.div`
  border-top: 1px solid ${CONSTANTS.COLORS.GREY_G14};
`;
export const NotFoundOptions = styled.div`
  height: 44px;
  color: ${CONSTANTS.COLORS.GREY_G2};
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding-left: 10px;
`;
export const OptionListWrapper = styled.div``;
export const FilterCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    width: 15px;
    height: 15px;
  }

  .ant-checkbox + span {
    font-size: 14px;
    font-weight: 500;
    padding-left: 6.5px;
    vertical-align: middle;
  }
`;

export const FilterDateSelect = styled(Select)`
  .ant-select-arrow {
    margin-top: -11px;
    height: 20px;
    width: 20px;
    right: 8px;

    > span {
      position: absolute;
      right: 0;
    }
  }

  .ant-select-selection__rendered {
    margin-left: 8px;
    margin-right: 11px;

    .ant-select-selection__placeholder, .ant-select-selection-selected-value {
      padding-left: 5px;
    }
  }
`;
export const FilterDateOption = styled(Option)``;
