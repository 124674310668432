import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const ShortContainer = styled.div`
  height: 40px;
  border-radius: 4px;
  padding: 8px 16px;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.DARKEST_BLUE};
  display: flex;
  align-items: center;
  position: fixed;
  top: calc(100vh - 100px);
  left: 45%;
`;
