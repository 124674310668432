import get from 'lodash/get';
import { createSelector } from 'reselect';

const baseVerificationsSelector = (state) => get(state, 'verifications', {});
export const verificationsSelector = createSelector(
  baseVerificationsSelector,
  (verifications) => verifications,
);

const baseVerificationsPageSelector = (state) => get(state, 'verifications.page', {});
export const verificationsPageSelector = createSelector(
  baseVerificationsPageSelector,
  (page) => page,
);

const baseVerificationsPageSizeSelector = (state) => get(state, 'verifications.pageSize', {});
export const verificationsPageSizeSelector = createSelector(
  baseVerificationsPageSizeSelector,
  (pageSize) => pageSize,
);

const baseVerificationsTotalCountSelector = (state) => get(state, 'verifications.totalCount', {});
export const verificationsPageTotalCountSelector = createSelector(
  baseVerificationsTotalCountSelector,
  (totalCount) => totalCount,
);

const baseVerificationsEmailsSelector = (state) => get(state, 'verifications.emails', {});
export const verificationsEmailsSelector = createSelector(
  baseVerificationsEmailsSelector,
  (emails) => emails,
);

const baseVerificationsBulksSelector = (state) => get(state, 'verifications.bulks', {});
export const verificationsBulksSelector = createSelector(
  baseVerificationsBulksSelector,
  (bulks) => bulks,
);

const baseVerificationsBulkSelector = (state) => get(state, 'verifications.bulks', {});
export const verificationsBulkSelector = (bulkId) => createSelector(
  baseVerificationsBulkSelector,
  (bulks) => bulks.find((file) => file._id === bulkId),
);

const baseVerificationsPreProcessEmailColumnsSelector = (state) => get(state, 'verifications.preprocess.emailColumns', {});
export const verificationsPreprocessEmailColumnsSelector = createSelector(
  baseVerificationsPreProcessEmailColumnsSelector,
  (emailColumns) => emailColumns,
);

const baseVerificationsPreprocessSelector = (state) => get(state, 'verifications.preprocess', {});
export const verificationsPreprocessSelector = createSelector(
  baseVerificationsPreprocessSelector,
  (preprocess) => preprocess,
);

const baseVerificationsPageParamsSelector = (state) => get(state, 'verifications.pageParams', {});
export const verificationsPageParamsSelector = createSelector(
  baseVerificationsPageParamsSelector,
  (pageParams) => pageParams,
);

export const verificationEventsSelector = createSelector(
  baseVerificationsSelector,
  (verifications) => get(verifications, 'events', []),
);
