import get from 'lodash/get';
import React, { useState } from 'react';
import { Menu, DatePicker } from 'antd';

import { Select, Option, Input } from 'components/common';

const FilterTypes = ({
  filter,
  handleAddValue,
}) => {
  const [option, setOption] = useState('');

  const fieldType = get(filter, 'fieldType', 'text');
  const options = get(filter, 'options', []);

  const handleAddOption = (array) => {
    handleAddValue(array.map((item) => item.key));
    setOption('');
  };

  const menu = () => {
    switch (fieldType) {
      case 'radio':
      case 'checkbox':
      case 'select':
        return options && ('map' in options) ? options.map((item, index) => ((
          <Option value={item.value} key={`select-filter-option-${index + 1}`}>
            {item.label}
          </Option>
        ))) : <></>;
      case 'booleancheckbox': {
        const booleanCheckboxOptions = [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ];
        return booleanCheckboxOptions.map((item, index) => ((
          <Option value={item.value} key={`select-filter-option-${index + 1}`}>
            {item.label}
          </Option>
        )));
      }
      default:
        return (
          <Menu>
            <Menu.Item disabled={!option} key="0">
              {option ? `Create option: '${option}'` : 'Add an option'}
            </Menu.Item>
          </Menu>
        );
    }
  };

  const handleChange = (newOption) => {
    setOption(newOption);
  };

  const getCurrentOption = () => {
    switch (fieldType) {
      case 'number':
        return <Input size="default" onChange={(e) => handleAddValue(e.target.value)} />;
      case 'date':
        return <DatePicker onChange={(date, dateString) => handleAddValue([dateString])} />;
      case 'checkbox':
      case 'radio':
      case 'booleancheckbox':
      case 'select': {
        return (
          <Select
            size="default"
            mode="multiple"
            onSearch={handleChange}
            onChange={(value) => handleAddValue(value)}
            placeholder="Select options"
            style={{ width: '260px' }}
          >
            {menu()}
          </Select>
        );
      }
      default:
        return (
          <Select
            size="default"
            onBlur={() => setOption('')}
            onSearch={handleChange}
            onChange={(val, array) => handleAddOption(array)}
            mode="tags"
            dropdownRender={menu}
            placeholder="Add option"
            style={{ width: '260px' }}
          />
        );
    }
  };

  return getCurrentOption();
};

export default FilterTypes;
