import React from 'react';

import { Icon } from 'components/common';
import { AntIcon } from 'components/commonStyles';

const OptionBtnIcon = ({ type, onClick }) => {
  switch (type) {
    case 'add':
      return <Icon type="ic-add" fill="primary" onClick={onClick} />;

    case 'save':
      return <Icon type="ic-save" fill="primary" onClick={onClick} />;

    default:
      return <AntIcon onClick={onClick} />;
  }
};

export default OptionBtnIcon;
