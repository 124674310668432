import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';

import Loader from 'components/loading';
import { Button } from 'components/common';
import CodeInput from 'components/codeInput';
import { toggleModal } from 'data/actions/modals';
import TimerText from 'components/common/timerText';
import { TIME_RELOAD_PHONE } from 'constants/timers';
import { signOut, verifyPhone, verifyToken } from 'data/actions/user';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { verifyPhoneLoadingSelector, verifyTokenLoadingSelector } from 'data/selectors/loading';

import {
  CloseIcon,
  ModalTitle,
} from '../baseModal/styles';
import {
  CodeWrapper,
  SubHeader,
  Header,
  SubHeaderAction,
  Container,
  Footer,
} from './styles';

const VerifyPhoneModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const loading = useSelector(verifyPhoneLoadingSelector);
  const loadingVerifyingToken = useSelector(verifyTokenLoadingSelector);
  const {
    disableCallInRender,
    phone,
    onSuccess,
    onFailed,
    signOutOnFailedClose = true,
  } = useSelector(modalOptionsSelector);

  const [code, setCode] = useState('');
  const [token, setToken] = useState();
  const [resetCode, setResetCode] = useState(false);
  const [errorCode, setErrorCode] = useState('');

  const handleClose = useCallback(() => dispatch(toggleModal('verify_phone', false)), [dispatch]);
  const handleSignOut = useCallback(() => dispatch(signOut()), [dispatch]);

  const handleSetCode = useCallback((value) => {
    setErrorCode();
    setResetCode(false);
    setCode(value);
  }, []);

  const checkVerifyToken = () => {
    dispatch(verifyToken({
      token,
      verificationCode: code,
      onSuccess: (data) => {
        handleClose();
        if (onSuccess) onSuccess(data?.isTmpPhoneCode ? '' : token);
      },
      onFailed: (err) => {
        if (err?.response?.data?.statusCode !== 429) {
          setErrorCode(err?.response?.data?.message || err?.message);
        }
      },
    }));
  };

  const getVerifyPhone = useCallback(() => {
    dispatch(verifyPhone({
      phone: !phone?.includes('+') ? `+${phone}` : phone,
      onFailed: (err) => {
        if (err?.response?.data?.statusCode !== 429) {
          if (onFailed) onFailed(err?.response?.data?.message || err?.message);
        }
      },
      onSuccess: (data) => {
        setToken(data.token);
      },
    }));
  }, [dispatch, phone, onFailed]);

  useEffect(() => {
    if (!disableCallInRender) getVerifyPhone();
  }, [getVerifyPhone, disableCallInRender]);

  const handleCallMeAgain = () => {
    setToken('');
    setResetCode(true);
    getVerifyPhone();
  };

  if (loading) return null;

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle white>
          <CloseIcon
            onClick={signOutOnFailedClose ? handleSignOut : handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      width={405}
      bodyStyle={{ padding: '36px 0 30px 0', paddingTop: 0 }}
      footer={false}
      className="empty-borders verify-phone-modal"
      onCancel={signOutOnFailedClose ? handleSignOut : handleClose}
      closable={false}
      headerStyle={{ padding: '0px 24px', background: 'red' }}
    >
      <Loader loading={loading}>
        <Container>
          <Header>Verify Phone Number</Header>
          <SubHeader>Enter the 4 digits code from the incoming SMS.</SubHeader>
          <TimerText
            timeReload={TIME_RELOAD_PHONE}
            onClick={handleCallMeAgain}
            Component={SubHeaderAction}
          >
            Send again
          </TimerText>
          <CodeWrapper>
            <CodeInput resetCode={resetCode} codeValue={code} error={errorCode} size={4} updateCode={handleSetCode} />
          </CodeWrapper>
          <Footer>
            <Button
              style={{ width: '295px' }}
              data-form="true"
              type="primary"
              size="large"
              disabled={code?.split('')?.length !== 4}
              onClick={checkVerifyToken}
              loading={loadingVerifyingToken}
            >
              Confirm
            </Button>
          </Footer>
        </Container>
      </Loader>
    </Modal>
  );
};

export default VerifyPhoneModal;
