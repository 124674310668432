import { GP_GROUP } from 'data/types/user.types';
import { getCookie, setCookie } from 'hooks/useSignUpQuery';

export const updateGpGroup = ({
  groupNumber,
  randomGroupNumbers = [GP_GROUP.DEFAULT, GP_GROUP.NEW_PRICING_3],
  forceUpdate,
}) => {
  let gpGroup = getCookie('gp_group');
  if (!gpGroup || forceUpdate) {
    if (randomGroupNumbers) {
      const randomIndex = new Date().getUTCSeconds() % randomGroupNumbers.length;
      gpGroup = randomGroupNumbers[randomIndex];
    } if (groupNumber) {
      gpGroup = groupNumber;
    }
    const threeMonths = 7776000;
    const options = {
      domain: 'getprospect.com',
      'max-age': threeMonths,
    };
    setCookie('gp_group', gpGroup, options);
  }
  return +gpGroup;
};
