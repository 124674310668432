import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ConfirmationContainer = styled.div`
  margin-top: 10px;
  Input {
    margin-top: 5px;
  }
`;
