export const MAXIMUM_MEGABYTES_FOR_IMAGE = 5242880;
export const MAXIMUM_IMAGES_FOR_STEP = 20;
export const MAXIMUM_MEGABYTES_FOR_FILE = 26214400;
export const MAXIMUM_FILES_FOR_STEP = 10;

export const SEQUENCE_STATUS_TYPE = Object.freeze({
  ACTIVE: 'active',
  PAUSED: 'paused',
  DRAFT: 'draft',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
});

export const SEQUENCE_LEAD_STATUS_TYPE = Object.freeze({
  SCHEDULED: 'scheduled',
  ACTIVE: 'active',
  REPLIED: 'replied',
  BOUNCED: 'bounced',
  INVALID: 'invalid',
  FINISHED: 'finished',
  PAUSED: 'paused',
  'OPT-OUT': 'opt-out',
  INTERESTED: 'interested',
  'AUTO-REPLIED': 'auto-replied',
});

export const SEQUENCE_MESSAGE_STATUS_TYPE = Object.freeze({
  SENT: 'sent',
  OPENED: 'opened',
  CLICKED: 'clicked',
  REPLIED: 'replied',
  BOUNCED: 'bounced',
  'OPT-OUT': 'opt-out',
  INTERESTED: 'interested',
  INVALID: 'invalid',
  SCHEDULED: 'scheduled',
});

export const SEQUENCE_DRAFT_STATUS = Object.freeze({
  STEPS: 'steps',
  LEADS: 'leads',
  SETTINGS: 'settings',
  SCHEDULE: 'schedule',
  REVIEW: 'review',
  DONE: 'done',
});

export const SEQUENCE_DRAFT_ORDER = Object.freeze({
  [SEQUENCE_DRAFT_STATUS.STEPS]: 1,
  [SEQUENCE_DRAFT_STATUS.LEADS]: 2,
  [SEQUENCE_DRAFT_STATUS.SETTINGS]: 3,
  [SEQUENCE_DRAFT_STATUS.SCHEDULE]: 4,
  [SEQUENCE_DRAFT_STATUS.REVIEW]: 5,
});

export const EDITOR_SKIN = Object.freeze({
  STEP: '/skins/step',
  SIGNATURE: '/skins/signature',
});

export const STEP_STATUS = Object.freeze({
  DONE: 'done',
  IN_PROGRESS: 'in_progress',
  TO_SEND: 'to_send',
});

export const UNSUBSCRIBE_STATUS = Object.freeze({
  UNSUBSCRIBED: 'unsubscribed',
  BOUNCED: 'bounced',
  DOMAINS: 'domains',
});
