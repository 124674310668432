const triggerLoadingAction = (components, loading, next) => {
  if (components) {
    next(
      {
        type: 'UPDATE_LOADING_COMPONENT',
        data: {
          components,
          loading,
        },
      },
    );
  }
};

export default triggerLoadingAction;
