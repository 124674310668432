import get from 'lodash/get';
import { createSelector } from 'reselect';

const rootCompaniesSelector = (state) => get(state, 'companies', []);
const baseCompaniesSelector = (state) => get(state, 'companies.companies', {});
export const companiesSelector = createSelector(
  baseCompaniesSelector,
  (companies) => companies,
);

const baseSelectedFilterSelector = (state) => get(state, 'companies.selectedFilter', {});
export const selectedCompanyFilterSelector = createSelector(
  baseSelectedFilterSelector,
  (selectedFilter) => selectedFilter,
);

export const totalCompaniesSelector = createSelector(
  baseCompaniesSelector,
  (companies) => get(companies, 'totalItems', 0),
);

export const sortCompaniesSelector = createSelector(
  baseCompaniesSelector,
  (companies) => get(companies, 'sort', {}),
);

const baseShowFiltersSelector = (state) => get(state, 'companies.showAllSavedFilters', false);
export const companiesIsShowAllSavedFilters = createSelector(
  baseShowFiltersSelector,
  (showAllSavedFilters) => showAllSavedFilters,
);

const baseCompaniesFiltersSelector = (state) => get(state, 'companies.companies.filters', []);
export const companiesFiltersSelector = createSelector(
  baseCompaniesFiltersSelector,
  (filters) => filters,
);

const baseCompanyPropertiesSelector = (state) => get(state, 'properties.properties', {});
export const companyPropertiesSelector = createSelector(
  baseCompanyPropertiesSelector,
  (properties) => ({
    ...properties,
    data: properties.filter((item) => item.sourceType === 'company'),
  }),
);

const baseCompaniesFilterSelector = (state) => get(state, 'companies.savedFilters', []);
export const companiesFilterSelector = createSelector(
  baseCompaniesFilterSelector,
  (companies) => companies,
);

export const baseCompaniesFavoriteFilters = (state) => get(state, 'user.settings.companiesFavoriteFilters', []);
export const companiesFavoriteFiltersSelector = createSelector(
  baseCompaniesFavoriteFilters,
  (favoriteFilters) => favoriteFilters,
);

export const baseCompaniesFilterConfigurationSelector = (state) => get(state, 'user.settings.companiesFilterConfiguration', []);
export const companiesFilterConfigurationSelector = createSelector(
  baseCompaniesFilterConfigurationSelector,
  (selectedColumns) => selectedColumns,
);

const baseSelectedCompaniesSelector = (state) => get(state, 'companies.selectedCompanies', []);
export const selectedCompaniesSelector = createSelector(
  baseSelectedCompaniesSelector,
  (companies) => companies,
);

const baseExcludedCompaniesSelector = (state) => get(state, 'companies.excludedCompanies', []);
export const excludedCompaniesSelector = createSelector(
  baseExcludedCompaniesSelector,
  (companies) => companies,
);

export const companiesSearchSelector = createSelector(
  baseCompaniesSelector,
  (companies) => companies.search,
);

const baseBlockedCompaniesSelector = (state) => get(state, 'companies.blockedCompanies', {});
export const blockedCompaniesSelector = createSelector(
  baseBlockedCompaniesSelector,
  (companies) => companies,
);

export const blockedCompaniesSearchSelector = createSelector(
  baseBlockedCompaniesSelector,
  (companies) => companies.search,
);

const baseCompanyIdSelector = (state) => get(state, 'companies.companyId', null);
export const companyIdSelector = createSelector(
  baseCompanyIdSelector,
  (companyId) => companyId,
);

const baseCompaniesInsightsSelector = (state) => get(state, 'companies.insights', []);
export const companiesInsightsSelector = createSelector(
  baseCompaniesInsightsSelector,
  (insights) => insights,
);

const baseCurrentCompanySelector = (state) => get(state, 'companies.currentCompany', {});
export const currentCompanySelector = createSelector(
  baseCurrentCompanySelector,
  (currentCompany) => currentCompany,
);

const baseSuggestedCompaniesSelector = (state) => get(state, 'companies.suggestedCompanies', []);
export const suggestedCompaniesSelector = createSelector(
  baseSuggestedCompaniesSelector,
  (suggestedCompanies) => suggestedCompanies,
);

export const companiesSelectAllContacts = (state) => get(state, 'companies.selectAllCompanies', {});

export const selectedAllCompaniesSelector = createSelector(
  companiesSelectAllContacts,
  (options) => get(options, 'selectAll', false),
);

const baseSelectedInsightCompaniesSelector = (state) => get(state, 'companies.selectedInsightCompanies', []);
export const selectedInsightCompaniesSelector = createSelector(
  baseSelectedInsightCompaniesSelector,
  (insightCompanies) => insightCompanies,
);

const baseSelectorSearchedByName = (state) => get(state, 'companies.searchedByName', []);
export const searchedByNameCompaniesSelector = createSelector(
  baseSelectorSearchedByName,
  (searchedByName) => searchedByName,
);

export const initialRequestCompaniesSelector = createSelector(
  baseCompaniesSelector,
  (companies) => get(companies, 'initialRequest', false),
);

export const initialRequestInsightCompaniesSelector = createSelector(
  baseCompaniesInsightsSelector,
  (insights) => get(insights, 'initialRequest', false),
);

export const savingCompaniesPreviewSelector = createSelector(
  rootCompaniesSelector,
  (data) => get(data, 'savingCompaniesPreview'),
);

const baseDeletedCompaniesSelector = (state) => get(state, 'companies.deletedCompanies', {});
export const deletedCompaniesSelector = createSelector(
  baseDeletedCompaniesSelector,
  (deletedCompanies) => deletedCompanies,
);

export const fastSearchDatabaseCompaniesSelector = (state) => get(state, 'companies.fastSearchDatabaseCompanies', []);
export const popularInsightsCompaniesSelector = (state) => get(state, 'companies.popularInsightsCompanies', { data: [], meta: {} });
export const inaccurateDomainsSelector = (state) => get(state, 'companies.inaccurateDomains', []);

export const currentCompanyHistorySelector = createSelector(
  currentCompanySelector,
  (currentCompany) => get(currentCompany, 'history', []),
);

export const currentCompanyCompetitorsSelector = createSelector(
  currentCompanySelector,
  (currentCompany) => get(currentCompany, 'competitors', []),
);

export const currentCompanyContactCountriesSelector = createSelector(
  currentCompanySelector,
  (currentCompany) => get(currentCompany, 'contactCountries', []),
);

export const currentCompanyContactDepartmentsSelector = createSelector(
  currentCompanySelector,
  (currentCompany) => get(currentCompany, 'contactDepartments', []),
);

export const currentCompanyContactPositionsSelector = createSelector(
  currentCompanySelector,
  (currentCompany) => get(currentCompany, 'contactPositions', []),
);

export const currentCompanyTechnologiesSelector = createSelector(
  currentCompanySelector,
  (currentCompany) => get(currentCompany, 'technologies', []),
);

export const currentCompanySavedContactsSelector = createSelector(
  currentCompanySelector,
  (currentCompany) => get(currentCompany, 'savedContacts', {}),
);

export const currentCompanyEmployeesSelector = createSelector(
  currentCompanySelector,
  (currentCompany) => get(currentCompany, 'employees', {}),
);

export const companyKeywordsSelector = createSelector(
  rootCompaniesSelector,
  (companies) => get(companies, 'keywords', []),
);

export const companyTechnologiesSelector = createSelector(
  rootCompaniesSelector,
  (companies) => get(companies, 'technologies.data', []),
);

const baseCurrentCompanySidebarSelector = (state) => get(state, 'companies.currentCompanySidebar', {});
export const currentCompanySidebarSelector = createSelector(
  baseCurrentCompanySidebarSelector,
  (currentCompanySidebar) => currentCompanySidebar,
);

export const currentCompanySidebarContactsSelector = createSelector(
  currentCompanySidebarSelector,
  (currentCompanySidebar) => get(currentCompanySidebar, 'contacts', []),
);

export const currentCompanySidebarContactsCountSelector = createSelector(
  currentCompanySidebarSelector,
  (currentCompanySidebar) => get(currentCompanySidebar, 'contactsCount', 0),
);

export const currentCompanySidebarCompetitorsSelector = createSelector(
  currentCompanySidebarSelector,
  (currentCompanySidebar) => get(currentCompanySidebar, 'competitors', []),
);

export const currentCompanySidebarSavedContactsSelector = createSelector(
  currentCompanySidebarSelector,
  (currentCompanySidebar) => get(currentCompanySidebar, 'savedContacts', {}),
);

export const currentCompanySidebarEmployeesSelector = createSelector(
  currentCompanySidebarSelector,
  (currentCompanySidebar) => get(currentCompanySidebar, 'employees', {}),
);

export const currentCompanySidebarHistorySelector = createSelector(
  currentCompanySidebarSelector,
  (currentCompanySidebar) => get(currentCompanySidebar, 'history', []),
);

export const currentCompanySidebarTechnologiesSelector = createSelector(
  currentCompanySidebarSelector,
  (currentCompanySidebar) => get(currentCompanySidebar, 'technologies', []),
);
