import queryString from 'query-string';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';

import Suspense from 'components/common/suspense';
import WhiteLoading from 'components/whiteLoading';
import { toggleMessage } from 'data/actions/message';
import {
  connectGoogleAccount,
  connectHubspot,
  connectSalesforce,
  connectZoho,
  connectPipedrive,
  connectGoogleSheets,
} from 'data/actions/integrations';
import useGoogleGetProspectLogin from 'hooks/useGoogleLogin';
import { updateGpGroup } from 'helpers/pricing/dynamicPricing';

import { Container } from './styles';

const OAuthPage = () => {
  const location = useLocation();
  const { source } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const signInGoogle = useGoogleGetProspectLogin();

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const hashParams = queryString.parse(location.hash);

    switch (source) {
      case 'google': {
        const tokenId = hashParams.id_token || queryParams.tokenId;
        const { code, scope, state } = queryParams;

        switch (state) {
          case 'extensionAuth':
            if (code) {
              signInGoogle({
                googleData: { code },
                type: 'code',
                source: 'extension',
              });
            }
            break;
          case 'sign-up':
          case 'sign-in':
            if (code) {
              const gpGroup = updateGpGroup({});
              signInGoogle({
                googleData: { code },
                type: 'code',
                gpGroup,
              });
            }
            break;
          case 'connect-gmail':
            if (code) {
              dispatch(connectGoogleAccount(code, scope));
            }
            break;
          default:
            if (tokenId) {
              signInGoogle({
                googleData: { tokenId },
                type: 'tokenId',
              });
            }
            break;
        }
        break;
      }
      case 'hubspot': {
        const { code } = queryParams;
        dispatch(connectHubspot(code, () => {
          history.push('/settings/integrations/hubspot');
        }, () => {
          history.push('/settings/integrations');
        }));
        break;
      }
      case 'salesforce': {
        const { code } = queryParams;
        dispatch(connectSalesforce(code, () => {
          history.push('/settings/integrations/salesforce');
        }, () => {
          history.push('/settings/integrations');
        }));
        break;
      }
      case 'zoho': {
        const { code, location: l } = queryParams;
        dispatch(connectZoho({ code, location: l }, () => {
          history.push('/settings/integrations/zoho');
        }, () => {
          history.push('/settings/integrations');
        }));
        break;
      }
      case 'pipedrive': {
        const { code } = queryParams;
        dispatch(connectPipedrive(code, () => {
          history.push('/settings/integrations/pipedrive');
        }, () => {
          history.push('/settings/integrations');
        }));
        break;
      }
      case 'sheets': {
        const { code, error } = queryParams;

        if (error) {
          dispatch(toggleMessage('error', { text: 'Access denied.' }));
          history.push('/');
          break;
        }

        if (code) {
          dispatch(connectGoogleSheets(code, () => {
            history.push('/settings/integrations/sheets');
          }, (err) => {
            if (get(err, 'response.data.message') === 'No access.') {
              dispatch(toggleMessage('error', { text: 'No required access provided.' }));
            }
            history.push('/settings/integrations');
          }));
        }
        break;
      }
      default:
        history.push('/');
        break;
    }
  }, [history, location, dispatch, signInGoogle, source]);

  return <Container><Suspense loading fallback={<WhiteLoading height="100vh" />} /></Container>;
};

export default OAuthPage;
