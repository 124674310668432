import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import React, {
  useEffect,
  useState,
} from 'react';

import Step from 'components/step';
import { Button } from 'components/common';
import engineProxy from 'helpers/liquid.engine';
import { toggleModal } from 'data/actions/modals';
import { updateStep } from 'data/actions/sequences';
import { sequenceStepsSelector } from 'data/selectors/sequences';
import { sequenceRemotelyLoaderSelector } from 'data/selectors/loading';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import StepVersions from 'components/sequenceInfo/tabs/steps/stepEditor/versions';
import { ActionsContainer } from 'components/sequenceInfo/tabs/steps/stepEditor/styles';
import AttachFile from 'components/sequenceInfo/tabs/steps/stepEditor/actions/attachFile';
import InsertToken from 'components/sequenceInfo/tabs/steps/stepEditor/actions/insertToken';
import ShowTemplates from 'components/sequenceInfo/tabs/steps/stepEditor/actions/showTemplates';
import InsertUnsubscribe from 'components/sequenceInfo/tabs/steps/stepEditor/actions/insertUnsubscribe';

import {
  Container,
} from '../createEmailTemplate/styles';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../baseModal/styles';
import {
  EditorContainer,
} from './styles';

const PreviewSequenceStepModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const {
    stepId,
    version,
    content,
    subject,
  } = useSelector(modalOptionsSelector);
  const loadingUpdating = useSelector(sequenceRemotelyLoaderSelector);

  const [activeVersion, setActiveVersion] = useState(version);

  const steps = useSelector(sequenceStepsSelector);
  const foundStep = steps.find(({ _id }) => _id === stepId);
  const { content: versionContent, subject: versionSubject, attachments = [] } = foundStep.versions[activeVersion];

  const [error, setError] = useState();
  const [editor, setEditor] = useState();
  const [preview, setPreview] = useState({
    content: content || versionContent,
    subject: subject || versionSubject,
  });

  const handleClose = () => {
    dispatch(toggleModal('preview_sequence_step', false));
  };

  const update = () => {
    dispatch(updateStep({
      step: { _id: stepId, ...preview },
      checkErrors: false,
      version: activeVersion,
      onSuccess: handleClose,
    }));
  };

  useEffect(() => {
    Promise.all([
      engineProxy.parseAndRender(preview.content),
      engineProxy.parseAndRender(preview.subject),
    ])
      .then(() => setError())
      .catch((_error) => setError(_error.message));
  }, [preview.content, preview.subject]);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle white>
          Email preview
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={1050}
      footer={false}
      wrapClassName="ant-tablet-width ant-white-modal"
      onCancel={handleClose}
    >
      <Container noShadow>
        <EditorContainer>
          <StepVersions
            readOnly
            marginSides={false}
            stepId={stepId}
            versions={foundStep.versions}
            activeVersion={activeVersion}
            onSelect={(_, selectedVersion) => {
              setActiveVersion(selectedVersion);
              const {
                content: selectedContent,
                subject: selectedSubject,
              } = foundStep.versions[selectedVersion];
              setPreview({ content: selectedContent, subject: selectedSubject });
            }}
          />
          <Step
            id="preview_sequence"
            error={error}
            bordered={false}
            height={260}
            setEditor={(editorRef) => {
              if (!editorRef) setEditor(editorRef);
            }}
            stepId={stepId}
            version={activeVersion}
            attachments={attachments}
            content={preview.content}
            subject={preview.subject}
            onChange={(step) => {
              setPreview((prev) => ({ ...prev, ...step }));
            }}
            style={{ padding: '0', borderBottom: 0 }}
            previousModal="preview_sequence_step"
          />
        </EditorContainer>
      </Container>
      <Footer between>
        <div>
          <Button disabled={error} loading={loadingUpdating} onClick={update} type="primary">Update</Button>
          <Button type="normal" onClick={handleClose}>Cancel</Button>
        </div>
        <div>
          <ActionsContainer>
            <InsertUnsubscribe
              loadingEditor={!!editor}
            />
            <InsertToken
              loadingEditor={!!editor}
            />
            <ShowTemplates
              stepId={stepId}
              subject={subject}
              content={content}
              version={activeVersion}
              previousModal="preview_sequence_step"
            />
            <AttachFile
              version={activeVersion}
              stepId={stepId}
              countFiles={attachments.length}
            />
          </ActionsContainer>
        </div>
      </Footer>
    </Modal>
  );
};

export default PreviewSequenceStepModal;
