export const demoContacts = [
  {
    listRelations: [],
    _id: '63ca4cb558eaa6026bc7643e',
    source: 'insights',
    properties: {
      firstName: {
        value: 'Alexander',
      },
      lastName: {
        value: 'Gizis',
      },
      email: {
        value: 'agizis@connectify.me',
      },
      createdAt: {
        value: '2020-06-17T13:10:50.000Z',
      },
      linkedinUrl: {
        value: 'https://www.linkedin.com/in/alexander-gizis-230a8310/',
      },
      location: {
        value: 'Philadelphia, Pennsylvania, United States',
      },
    },
    owner: {},
    companyRelations: [{
      email: {
        value: 'agizis@connectify.me',
        isDefault: false,
        status: 'valid',
      },
      _id: '63ca4cb658eaa6026bc76451',
      position: 'CEO and co-founder',
      company: {
        _id: '63ca4cb558eaa6026bc76444',
        properties: {
          name: {
            value: 'Connectify',
          },
          domain: {
            value: 'connectify.me',
          },
          industry: {
            value: 'Internet',
          },
          size: {
            value: 24,
          },
          headquarters: {
            value: '1429 Walnut Street PA Philadelphia',
          },
          postalCode: {
            value: '19102',
          },
          description: {
            value: 'Better Internet, for everyone.\n\nAt Connectify we make complex networking tasks easy by developing apps that deliver faster, more reliable, and more secure Internet to users all across the globe. Our suite of products empowers users to analyze, share, combine, encrypt, and ultimately unlock the full potential of their Internet connections.\n\nConnectify is located in sunny Philadelphia, PA. \n\nFeel free to contact us directly at sales@connectify.me.',
          },
          technologies: {
            value: [
              '628defc8bdd46f5a39fd6922',
              '628defc8bdd46f5a39fd646b',
              '628defc8bdd46f5a39fd651a',
              '628defc8bdd46f5a39fd6926',
              '628defc8bdd46f5a39fd64a5',
              '628defc8bdd46f5a39fd6218',
            ],
          },
          linkedin: {
            value: [],
          },
          search: {
            value: 'undefined',
          },
          countryCode: {
            value: 'US',
          },
          pattern: {
            value: '{f}{last}',
          },
        },
        source: 'insights',
        linkedin: [],
      },
    }],
    createdAt: '2023-01-20T08:11:33.367Z',
    updatedAt: '2023-01-20T08:11:35.041Z',
  },
  {
    listRelations: [],
    _id: '63ca4cb558eaa6026bc7643f',
    source: 'insights',
    properties: {
      firstName: {
        value: 'Sara',
      },
      lastName: {
        value: 'Mauskopf',
      },
      email: {
        value: 'sara@winnie.com',
      },
      createdAt: {
        value: '2019-11-07T12:13:00.000Z',
      },
      linkedinUrl: {
        value: 'https://www.linkedin.com/in/smausk/',
      },
      location: {
        value: 'United States',
      },
    },
    owner: {},
    companyRelations: [{
      email: {
        value: 'sara@winnie.com',
        isDefault: false,
        status: 'valid',
      },
      _id: '63ca4cb658eaa6026bc76450',
      position: 'CEO & Co-founder',
      company: {
        _id: '63ca4cb558eaa6026bc76445',
        properties: {
          name: {
            value: 'Winnie',
          },
          domain: {
            value: 'winnie.com',
          },
          industry: {
            value: 'Internet',
          },
          size: {
            value: 49,
          },
          headquarters: {
            value: '2811 Mission St California San Francisco',
          },
          postalCode: {
            value: '94110',
          },
          description: {
            value: 'Winnie is a marketplace for child care built on powerful data systems and backed by a trusted community of parents and providers. Parents use Winnie to discover high-quality local daycares and preschools and learn all about their programs including detailed descriptions, photos, tuition information, licensing status, availability data and more. Child care providers use Winnie to fill their open spaces, build their wait lists, and get support and resources to run their business efficiently.\n\nWinnie was started in early 2016 by tech veterans Sara Mauskopf and Anne Halsall. Working moms of two kids each, Sara and Anne became frustrated with the long search to find child care for their young children. Now Winnie is used throughout the United States and is helping connect millions of parents with high quality child care.',
          },
          technologies: {
            value: [
              '628defc8bdd46f5a39fd5ebf',
              '628defc8bdd46f5a39fd64a5',
              '628defc8bdd46f5a39fd60af',
              '628defc8bdd46f5a39fd5e3b',
            ],
          },
          linkedin: {
            value: [
              {
                _id: '63c26ad6964bda001f08c9d2',
                id: 'winnie',
                type: 'common',
              },
              {
                _id: '63c26ad6964bda001f08c9d3',
                id: '7933038',
                type: 'sales',
              },
            ],
          },
          search: {
            value: 'undefined',
          },
          countryCode: {
            value: 'US',
          },
          pattern: {
            value: '{first}',
          },
        },
        source: 'insights',
        linkedin: [
          {
            _id: '63c26ad6964bda001f08c9d2',
            id: 'winnie',
            type: 'common',
          },
          {
            _id: '63c26ad6964bda001f08c9d3',
            id: '7933038',
            type: 'sales',
          },
        ],
      },
    }],
    createdAt: '2023-01-20T08:11:33.367Z',
    updatedAt: '2023-01-20T08:11:35.023Z',
  },
  {
    listRelations: [],
    _id: '63ca4cb558eaa6026bc7643d',
    source: 'insights',
    properties: {
      firstName: {
        value: 'J J ',
      },
      lastName: {
        value: 'Kardwell',
      },
      email: {
        value: 'jkardwell@constant.com',
      },
      createdAt: {
        value: '2019-08-13T18:36:26.000Z',
      },
      linkedinUrl: {
        value: 'https://www.linkedin.com/in/kardwell/',
      },
      location: {
        value: 'United States',
      },
    },
    owner: {},
    companyRelations: [{
      email: {
        value: 'jkardwell@constant.com',
        isDefault: false,
        status: 'valid',
      },
      _id: '63ca4cb658eaa6026bc7644f',
      position: 'Chief Executive Officer',
      company: {
        _id: '63ca4cb558eaa6026bc7644b',
        properties: {
          name: {
            value: 'Constant',
          },
          domain: {
            value: 'constant.com',
          },
          industry: {
            value: 'Internet',
          },
          size: {
            value: 79,
          },
          headquarters: {
            value: '319 Clematis St Florida West Palm Beach',
          },
          postalCode: {
            value: '33401',
          },
          description: {
            value: 'Our flagship product, Vultr® is a favorite with the developer community, serving over 1.3 million customers with flexible, scalable, global bare metal, dedicated cloud, and storage solutions.\n\nKnown for supporting today\'s most critical business applications, we help developers, business leaders, and enterprise brands experience the high performance of bare metal, with the enhanced orchestration of virtual servers.\n\nWe deliver continuous uptime, cost-effective cloud infrastructure, and 24x7 global support to help developers build more, faster, and accessible from any location, worldwide.',
          },
          technologies: {
            value: [],
          },
          linkedin: {
            value: [
              {
                _id: '63c9d4efeb7dae00523aaad0',
                id: 'constantllc',
                type: 'common',
              },
              {
                _id: '63c9d4efeb7dae00523aaad1',
                id: '71501562',
                type: 'sales',
              },
            ],
          },
          search: {
            value: 'undefined',
          },
          countryCode: {
            value: 'US',
          },
          pattern: {
            value: '{f}{last}',
          },
        },
        source: 'insights',
        linkedin: [
          {
            _id: '63c9d4efeb7dae00523aaad0',
            id: 'constantllc',
            type: 'common',
          },
          {
            _id: '63c9d4efeb7dae00523aaad1',
            id: '71501562',
            type: 'sales',
          },
        ],
      },
    }],
    createdAt: '2023-01-20T08:11:33.367Z',
    updatedAt: '2023-01-20T08:11:35.021Z',
  },
  {
    listRelations: [],
    _id: '63ca4cb558eaa6026bc76440',
    source: 'insights',
    properties: {
      firstName: {
        value: 'Ken',
      },
      lastName: {
        value: 'Bond',
      },
      email: {
        value: 'ken@adrizer.com',
      },
      createdAt: {
        value: '2019-11-07T02:36:54.000Z',
      },
      linkedinUrl: {
        value: 'https://www.linkedin.com/in/kenbondjr/',
      },
      location: {
        value: 'Sarasota, Florida, United States',
      },
    },
    owner: {},
    companyRelations: [{
      email: {
        value: 'ken@adrizer.com',
        isDefault: false,
        status: 'valid',
      },
      _id: '63ca4cb658eaa6026bc76452',
      position: 'Co-Founder / CEO',
      company: {
        _id: '63ca4cb558eaa6026bc76448',
        properties: {
          name: {
            value: 'AdRizer',
          },
          domain: {
            value: 'adrizer.com',
          },
          industry: {
            value: 'Internet',
          },
          size: {
            value: 24,
          },
          headquarters: {
            value: '1570 Boulevard of the Arts Florida Sarasota',
          },
          postalCode: {
            value: '34236',
          },
          description: {
            value: 'AdRizer is a full-service tracking platform for advertisers seeking ROI optimization and digital analytics. Founded by publishers who saw a need to understand the ROI for each piece of content marketed through various partners, AdRizer has proven itself a must-have for publishers looking for the most effective way to market their content to the most valuable audience.\n\nFounded in 2013, AdRizer started on a kitchen counter as a small business. Since then, we’ve built one of the top 100 sites in the USA, partnered with the world’s leading advertising companies, and have developed a propriety analytics and monetization platform for publishers.\n\nIn the fast-paced world of digital marketing, speed, scale, efficiency, and reliability are paramount. AdRizer is able to deliver lightning fast results through our massive, global cloud infrastructure, driven by a suite of powerful applications.\n\nA Note to Applicants:\n\nAt AdRizer, we value dedication over degrees and hard work over everything else. We want rockstars. If you’ve got what we’re looking for, you’re hungry to prove yourself and you’re looking for a company that will let you do just that, then step on up and apply to one of our open careers now.\n\nWe love what we do, and we keep our work fun. As part of the AdRizer family, expect to be part of a team-centric environment where you can improve your ping pong game in between improving ROI.\n',
          },
          technologies: {
            value: [
              '628defc8bdd46f5a39fd68ed',
              '628defc8bdd46f5a39fd6898',
              '628defc8bdd46f5a39fd64a5',
              '628defc8bdd46f5a39fd64e9',
              '628defc8bdd46f5a39fd6337',
              '628defc8bdd46f5a39fd6224',
              '628defc8bdd46f5a39fd6223',
              '628defc8bdd46f5a39fd632d',
              '628defc8bdd46f5a39fd622e',
              '628defc8bdd46f5a39fd622b',
              '628defc8bdd46f5a39fd6218',
              '628defc8bdd46f5a39fd616c',
              '628defc8bdd46f5a39fd6097',
              '628defc8bdd46f5a39fd617a',
            ],
          },
          linkedin: {
            value: [
              {
                _id: '6399cdcda9bb850012c4cede',
                id: 'ad-rizer',
                type: 'common',
              },
              {
                _id: '6399cdcda9bb850012c4cedf',
                id: '5225743',
                type: 'sales',
              },
            ],
          },
          search: {
            value: 'undefined',
          },
          countryCode: {
            value: 'US',
          },
          pattern: {
            value: '{first}',
          },
        },
        source: 'insights',
        linkedin: [
          {
            _id: '6399cdcda9bb850012c4cede',
            id: 'ad-rizer',
            type: 'common',
          },
          {
            _id: '6399cdcda9bb850012c4cedf',
            id: '5225743',
            type: 'sales',
          },
        ],
      },
    }],
    createdAt: '2023-01-20T08:11:33.368Z',
    updatedAt: '2023-01-20T08:11:35.020Z',
  },
  {
    listRelations: [],
    _id: '63ca4cb558eaa6026bc7643c',
    source: 'insights',
    properties: {
      firstName: {
        value: 'Jae',
      },
      lastName: {
        value: 'Choi',
      },
      email: {
        value: 'j@pledgecamp.com',
      },
      createdAt: {
        value: '2019-08-13T21:04:56.000Z',
      },
      linkedinUrl: {
        value: 'https://www.linkedin.com/in/realjhc/',
      },
      location: {
        value: 'United States',
      },
    },
    owner: {},
    companyRelations: [{
      email: {
        value: 'j@pledgecamp.com',
        isDefault: false,
        status: 'valid',
      },
      _id: '63ca4cb658eaa6026bc7644e',
      position: 'CEO & Co-Founder',
      company: {
        _id: '63ca4cb558eaa6026bc76441',
        properties: {
          name: {
            value: 'Pledgecamp',
          },
          domain: {
            value: 'pledgecamp.com',
          },
          industry: {
            value: 'Internet',
          },
          size: {
            value: 7,
          },
          headquarters: {
            value: 'S Church St, Cayman Islands George Town',
          },
          postalCode: {
            value: 'KY1-1104',
          },
          description: {
            value: 'Pledgecamp is securing the future of crowdfunding\nwith blockchain protected investments.\n\nOur token sale begins February 18, 2019 for 30 days only. See more at https://pledgecamp.com',
          },
          technologies: {
            value: [
              '628defc8bdd46f5a39fd696e',
              '628defc8bdd46f5a39fd68be',
              '628defc8bdd46f5a39fd684a',
              '628defc8bdd46f5a39fd6397',
              '628defc8bdd46f5a39fd622e',
              '628defc8bdd46f5a39fd6218',
              '628defc8bdd46f5a39fd616c',
              '628defc8bdd46f5a39fd6097',
              '628defc8bdd46f5a39fd66aa',
              '628defc8bdd46f5a39fd6499',
              '628defc8bdd46f5a39fd6934',
            ],
          },
          linkedin: {
            value: [
              {
                _id: '63c445995a7a4d00114e2b6c',
                id: 'pledgecamp',
                type: 'common',
              },
              {
                _id: '63c445995a7a4d00114e2b6d',
                id: '28640644',
                type: 'sales',
              },
            ],
          },
          search: {
            value: 'undefined',
          },
          countryCode: {
            value: 'KY',
          },
          pattern: {
            value: '{first}',
          },
        },
        source: 'insights',
        linkedin: [
          {
            _id: '63c445995a7a4d00114e2b6c',
            id: 'pledgecamp',
            type: 'common',
          },
          {
            _id: '63c445995a7a4d00114e2b6d',
            id: '28640644',
            type: 'sales',
          },
        ],
      },
    }],
    createdAt: '2023-01-20T08:11:33.366Z',
    updatedAt: '2023-01-20T08:11:34.993Z',
  },
];
