import { COLORS } from 'constants/styles';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

const statusMixin = css`
  ${(props) => {
    if (props.status === 'success') return `background-color: ${COLORS.SUCCESS}`;
    if (props.status === 'error') return `background-color: ${COLORS.RED}`;
    return '';
  }}
`;

const errorTextMixin = css`
  color: ${COLORS.RED};
`;

export const Circle = styled.div`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-right: 5px;
  ${(props) => props.status && statusMixin}
`;

export const Text = styled.span`
  ${(props) => props.error && errorTextMixin}
`;
