import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleModal } from 'data/actions/modals';
import { isSendingAnyStepSelector, isSendingCurrentStepSelector } from 'data/selectors/sequences';

const useShowPauseWarningIfNeeded = (isCurrentStep = true) => {
  const dispatch = useDispatch();

  const isSendingAnyStep = useSelector(isSendingAnyStepSelector);
  const isSendingCurrentStep = useSelector(isSendingCurrentStepSelector);

  const isProcessing = isCurrentStep ? isSendingCurrentStep : isSendingAnyStep;

  const showPauseWarningIfNeeded = useCallback((callback) => (...params) => {
    if (isProcessing) {
      dispatch(toggleModal('pause_editing_step', true));
    } else if (callback) {
      callback(...params);
    }
  }, [dispatch, isProcessing]);

  return showPauseWarningIfNeeded;
};

export default useShowPauseWarningIfNeeded;
