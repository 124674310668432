import get from 'lodash/get';
import { REFRESH_TOKEN_PROCESS_DONE } from 'data/types/event.types';

// eslint-disable-next-line
export default () => () => (next) => (action) => {
  const skipAuth = get(action, 'payload.skipAuth', false);
  if (skipAuth) return next(action);

  if (localStorage.getItem('refreshTokenProcessing')) {
    const listener = window.addEventListener(REFRESH_TOKEN_PROCESS_DONE, () => {
      window.removeEventListener(REFRESH_TOKEN_PROCESS_DONE, listener);
      next(action);
    });
  } else {
    next(action);
  }
};
