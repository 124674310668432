import Compressor from 'compressorjs';
import ReactDOMServer from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import React, {
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { Editor as TinymceEditor } from '@tinymce/tinymce-react';

import { ReactComponent as IconCode } from '@material-design-icons/svg/filled/code.svg';
import { ReactComponent as IconLink } from '@material-design-icons/svg/filled/link.svg';
import { ReactComponent as IconLock } from '@material-design-icons/svg/filled/lock.svg';
import { ReactComponent as IconClose } from '@material-design-icons/svg/filled/close.svg';
import { ReactComponent as IconImage } from '@material-design-icons/svg/filled/image.svg';
import { ReactComponent as IconBadge } from '@material-design-icons/svg/outlined/badge.svg';
import { ReactComponent as IconUnlock } from '@material-design-icons/svg/filled/lock_open.svg';
import { ReactComponent as IconBold } from '@material-design-icons/svg/filled/format_bold.svg';
import { ReactComponent as IconClear } from '@material-design-icons/svg/filled/format_clear.svg';
import { ReactComponent as IconItalic } from '@material-design-icons/svg/filled/format_italic.svg';
import { ReactComponent as IconVisibility } from '@material-design-icons/svg/filled/visibility.svg';
import { ReactComponent as IconAlignLeft } from '@material-design-icons/svg/filled/format_align_left.svg';
import { ReactComponent as IconColorText } from '@material-design-icons/svg/filled/format_color_text.svg';
import { ReactComponent as IconColorFill } from '@material-design-icons/svg/filled/format_color_fill.svg';
import { ReactComponent as IconUnderlined } from '@material-design-icons/svg/filled/format_underlined.svg';
import { ReactComponent as IconAlignRight } from '@material-design-icons/svg/filled/format_align_right.svg';
import { ReactComponent as IconArrowDropDown } from '@material-design-icons/svg/filled/arrow_drop_down.svg';
import { ReactComponent as IconAlignCenter } from '@material-design-icons/svg/filled/format_align_center.svg';
import { ReactComponent as IconListBulleted } from '@material-design-icons/svg/filled/format_list_bulleted.svg';
import { ReactComponent as IconAlignJustify } from '@material-design-icons/svg/filled/format_align_justify.svg';
import { ReactComponent as IconListNumbered } from '@material-design-icons/svg/filled/format_list_numbered.svg';

import Loader from 'components/loading';
import { COLORS } from 'constants/styles';
import { toggleModal } from 'data/actions/modals';
import { formatBytes } from 'helpers/calcHelpers';
import { toggleMessage } from 'data/actions/message';
import { uploadImageToCDN } from 'data/actions/common';
import { workspaceIdSelector } from 'data/selectors/user';
import useAddEmailAccount from 'hooks/sequences/useAddEmailAccount';
import { selectedSequenceSelector, sequenceAccountSelector } from 'data/selectors/sequences';
import { EDITOR_SKIN, MAXIMUM_IMAGES_FOR_STEP, MAXIMUM_MEGABYTES_FOR_IMAGE } from 'data/types/sequences.types';
import {
  TextContainer,
} from '../step/styles';
import { Container } from './styles';

const Editor = forwardRef(({
  id,
  content,
  disabled,
  bordered,
  height,
  rounded,
  placeholder,
  toolBarLocation,
  onLoaded,
  bodyMarginLeft = 0,
  iconSize = 24,
  toolbar = `${process.env.NODE_ENV === 'development' ? 'code' : ''} bold italic underline forecolor alignleft aligncenter alignright alignjustify numlist bullist link removeformat image fontselect fontsizeselect`,
  onChange = () => {},
  onFocus = () => {},
  processing,
  skin = EDITOR_SKIN.STEP,
  showPauseWarningIfNeeded = () => () => {},
}, ref) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [localDisabled, setLocalDisabled] = useState(false);

  const account = useSelector(sequenceAccountSelector);
  const workspaceId = useSelector(workspaceIdSelector);
  const { _id: sequenceId } = useSelector(selectedSequenceSelector);

  const { showModal: addEmailAccount } = useAddEmailAccount();

  useEffect(() => {
    let observerFontSizeText;
    function clickOutsideDialog(e) {
      const dialog = document.querySelector('.tox-dialog');
      if (dialog && !e.target.classList.contains('tox-dialog-wrap__backdrop')) {
        return;
      }
      const closeButton = document.querySelector('.tox-button--secondary');
      if (closeButton) closeButton.click();
    }
    function setFontSizeText() {
      const labels = document.querySelectorAll('.tox-tbtn__select-label');
      labels.forEach((label) => {
        if (label.innerText === '13px') {
          label.innerText = 'Normal';
        }
      });
    }
    if (!loading) {
      observerFontSizeText = new MutationObserver(setFontSizeText);
      const toolbarPrimaryDOM = document.querySelector('.tox-toolbar__primary');
      if (toolbarPrimaryDOM) {
        observerFontSizeText.observe(toolbarPrimaryDOM, { childList: true, subtree: true });
      }
      window.addEventListener('click', clickOutsideDialog);
    }
    return () => {
      if (observerFontSizeText) observerFontSizeText.disconnect();
      window.removeEventListener('click', clickOutsideDialog);
    };
  }, [loading]);

  useEffect(() => {
    function editSignature(e) {
      if (e?.target?.classList?.contains('signature-container__content') || e?.target?.parentNode?.classList?.contains('signature-container__content')) {
        if (account) {
          dispatch(toggleModal('email_account_preview_bar', true, { accountId: account._id, sequenceId }));
        } else {
          addEmailAccount({ sequenceId });
        }
      }
    }
    if (!loading && ref.current) {
      ref.current.on('click', editSignature);
    }
    return () => ref.current && ref.current.off('click', editSignature);
  }, [dispatch, ref, loading, account, sequenceId, addEmailAccount]);

  useEffect(() => {
    // fonts in disabled mode are empty
    const timer = setTimeout(() => {
      setLocalDisabled(disabled);
    }, 500);
    return () => clearTimeout(timer);
  }, [disabled]);

  return (
    <Container>
      <Loader loading={loading} size="large">
        <TextContainer height={height} rounded={rounded} bordered={bordered}>
          <TinymceEditor
            id={id || skin.replace(/\//g, '_')}
            apiKey={process.env.REACT_APP_TINY_MCE_TOKEN}
            value={content}
            onEditorChange={(value) => {
              if (!processing && !disabled) {
                onChange(value);
              }
            }}
            onFocus={(e) => {
              if (processing) {
                e.preventDefault();
                showPauseWarningIfNeeded()();
              } else {
                onFocus(e);
              }
            }}
            disabled={localDisabled}
            onInit={(_, editor) => {
              if (ref) {
                ref.current = editor;
              }
              setLoading(false);
              if (onLoaded) onLoaded();
            }}
            init={{
              skin_url: skin,
              width: '100%',
              height: '100%',
              content_style: `
                @import url('/fonts.css');
                body {
                  font-family: 'AvenirNext';
                  font-size: small;
                  margin-left: ${bodyMarginLeft}px;
                }
                .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
                  color: ${COLORS.GREY_G3};
                }

                .mce-offscreen-selection {
                  display: none !important;
                }
                
                .coordinates-tooltip {
                  background: ${COLORS.BLACK_G1};
                  border-radius: 4px;
                  color: ${COLORS.WHITE};
                  font-weight: 500;
                  font-size: 12px;
                  padding: 6px;
                  position: absolute;
                }

                .signature-container {
                  display: flex;
                  width: 330px;
                  justify-content: space-between;
                  cursor: pointer;
                }

                .signature-container[disabled] {
                  pointer-events: none;
                }

                .signature-container[disabled] .signature-container__content {
                  color: ${COLORS.GREY_G1};
                }

                .signature-container:hover {
                  background-color: ${COLORS.GREY_G5};
                }

                .signature-icon {
                  visibility: hidden;
                  fill: ${COLORS.GREY_G3};
                  height: 24px;
                  width: 24px;
                  min-width: 24px;
                  margin-top: 10px;
                }

                .signature-container:hover .signature-icon {
                  visibility: visible;
                }

                .signature-container__content {
                  width: 100%;
                  color: ${COLORS.GREY_G2};
                  font-weight: 400;
                  font-size: 14px;
                  margin-right: 50px;
                }
              `,
              menubar: false,
              extended_valid_elements: 'svg[class],path[d]',
              placeholder,
              branding: false,
              statusbar: false,
              contextmenu: null,
              toolbar_location: toolBarLocation,
              target_list: false,
              plugins: 'code image link lists',
              fontsize_formats: 'Small=x-small Normal=small Large=large Huge=xx-large',
              toolbar,
              setup: (editor) => {
                editor.on('ExecCommand', (e) => {
                  if (e.command === 'RemoveFormat') {
                    try {
                      const dom = new DOMParser().parseFromString(editor.getContent(), 'text/html');
                      const tagsToReplace = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
                      const tagsElements = dom.querySelectorAll(tagsToReplace.join(', '));
                      tagsElements.forEach((element) => {
                        const paragraphElement = document.createElement('p');
                        paragraphElement.innerHTML = element.innerHTML;
                        element.parentNode.replaceChild(paragraphElement, element);
                      });
                      editor.setContent(dom.body.innerHTML);
                    } catch (err) {
                      // eslint-disable-next-line
                      console.error('Error', err);
                    }
                  }
                });
                editor.ui.registry.addIcon('align-left', ReactDOMServer.renderToStaticMarkup(<IconAlignLeft style={{ width: iconSize, height: iconSize }} />));
                editor.ui.registry.addIcon('align-center', ReactDOMServer.renderToStaticMarkup(<IconAlignCenter style={{ width: iconSize, height: iconSize }} />));
                editor.ui.registry.addIcon('align-justify', ReactDOMServer.renderToStaticMarkup(<IconAlignJustify style={{ width: iconSize, height: iconSize }} />));
                editor.ui.registry.addIcon('align-right', ReactDOMServer.renderToStaticMarkup(<IconAlignRight style={{ width: iconSize, height: iconSize }} />));
                editor.ui.registry.addIcon('highlight-bg-color', ReactDOMServer.renderToStaticMarkup(<IconColorFill style={{
                  width: iconSize, height: iconSize,
                }}
                />));
                editor.ui.registry.addIcon('text-color', ReactDOMServer.renderToStaticMarkup(<IconColorText style={{
                  width: iconSize, height: iconSize,
                }}
                />));
                editor.ui.registry.addIcon('underline', ReactDOMServer.renderToStaticMarkup(<IconUnderlined style={{ width: iconSize, height: iconSize }} />));
                editor.ui.registry.addIcon('italic', ReactDOMServer.renderToStaticMarkup(<IconItalic style={{ width: iconSize, height: iconSize }} />));
                editor.ui.registry.addIcon('bold', ReactDOMServer.renderToStaticMarkup(<IconBold style={{ width: iconSize, height: iconSize }} />));
                editor.ui.registry.addIcon('link', ReactDOMServer.renderToStaticMarkup(<IconLink style={{ width: iconSize, height: iconSize }} />));
                editor.ui.registry.addIcon('image', ReactDOMServer.renderToStaticMarkup(<IconImage style={{ width: iconSize, height: iconSize }} />));
                editor.ui.registry.addIcon('remove-formatting', ReactDOMServer.renderToStaticMarkup(<IconClear style={{ width: iconSize, height: iconSize }} />));
                editor.ui.registry.addIcon('preview', ReactDOMServer.renderToStaticMarkup(<IconVisibility style={{ width: iconSize, height: iconSize }} />));
                editor.ui.registry.addIcon('chevron-down', ReactDOMServer.renderToStaticMarkup(<IconArrowDropDown style={{ width: iconSize, height: iconSize }} />));
                editor.ui.registry.addIcon('sourcecode', ReactDOMServer.renderToStaticMarkup(<IconCode style={{ width: iconSize, height: iconSize }} />));
                editor.ui.registry.addIcon('list-num-default', ReactDOMServer.renderToStaticMarkup(<IconListNumbered style={{ width: iconSize, height: iconSize }} />));
                editor.ui.registry.addIcon('list-bulleted-default', ReactDOMServer.renderToStaticMarkup(<IconListBulleted style={{ width: iconSize, height: iconSize }} />));
                editor.ui.registry.addIcon('badge', ReactDOMServer.renderToStaticMarkup(<IconBadge id="tokens-icon" style={{ width: iconSize, height: iconSize }} />));
                editor.ui.registry.addIcon('close', ReactDOMServer.renderToStaticMarkup(<IconClose style={{
                  opacity: 0.5, width: iconSize, height: iconSize, fill: COLORS.GREY_G3,
                }}
                />));
                editor.ui.registry.addIcon('lock', ReactDOMServer.renderToStaticMarkup(<IconLock style={{ width: iconSize, height: iconSize }} />));
                editor.ui.registry.addIcon('unlock', ReactDOMServer.renderToStaticMarkup(<IconUnlock style={{ width: iconSize, height: iconSize }} />));
              },
              file_picker_types: 'image',
              image_description: false,
              file_picker_callback: (cb) => {
                const closeButton = document.querySelector('.tox-button');
                let countImages = 0;
                const _content = ref.current.getContent();
                if (_content && _content[0] === '<') {
                  const document = new DOMParser().parseFromString(_content, 'text/html');
                  const countImagesDOM = document.querySelectorAll('img');
                  countImages = countImagesDOM.length;
                }

                if (countImages + 1 > MAXIMUM_IMAGES_FOR_STEP) {
                  if (closeButton) closeButton.click();
                  dispatch(toggleMessage('error', {
                    text: (
                      <div>
                        <span>
                          You can&lsquo;t upload more than
                        </span>
                        &nbsp;
                        <strong>
                          {MAXIMUM_IMAGES_FOR_STEP}
                        </strong>
                        &nbsp;
                        <span>
                          for a step
                        </span>
                      </div>
                    ),
                  }));
                  return;
                }

                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');

                input.onchange = (inputEvent) => {
                  const file = inputEvent.target.files[0];

                  if (file.size > MAXIMUM_MEGABYTES_FOR_IMAGE) {
                    if (closeButton) closeButton.click();
                    dispatch(toggleMessage('error', {
                      text: (
                        <div>
                          <span>
                            You can&lsquo;t upload an image more than
                          </span>
                          &nbsp;
                          <strong>
                            {formatBytes(MAXIMUM_MEGABYTES_FOR_IMAGE, 0)}
                          </strong>
                        </div>
                      ),
                    }));
                    return;
                  }

                  // eslint-disable-next-line
                  new Compressor(file, {
                    quality: 0.6,
                    success(result) {
                      dispatch(uploadImageToCDN({
                        file: {
                          data: new Blob([result], { type: file.type }),
                          meta: file,
                        },
                        prefix: `${workspaceId}/sequences/${sequenceId}/`,
                        onSuccess: ({ fields }) => {
                          cb(`${process.env.REACT_APP_CDN_URL}/${fields.Key}`, { title: file.name });
                        },
                        onFailed: () => {
                          if (closeButton) closeButton.click();
                          dispatch(toggleMessage('error', { text: 'Something went wrong' }));
                        },
                      }));
                    },
                    error() {
                      if (closeButton) closeButton.click();
                      dispatch(toggleMessage('error', { text: 'Something went wrong' }));
                    },
                  });
                };
                input.click();
              },
            }}
          />
        </TextContainer>
      </Loader>
    </Container>
  );
});

export default Editor;
