import React, { useCallback, useRef } from 'react';
import useOnClickOutside from 'helpers/clickOutside';
import {
  BlankButtonControls, BlankDataContainer, BlankFolderInput, BlankListItem,
} from './styles';
import Icon from '../../common/icon';

const BlankFolder = ({
  inputRef, newFolderName, handleCreateFolder, changeInputHandler, handleDeleteBlankFolder,
}) => {
  const newFolderRef = useRef({});

  const escapeKeyHandler = useCallback((e) => {
    e.stopPropagation();
    if (e.key === 'Escape') {
      handleDeleteBlankFolder();
    }
  }, [handleDeleteBlankFolder]);

  useOnClickOutside([newFolderRef], handleDeleteBlankFolder);

  return (
    <BlankListItem
      ref={newFolderRef}
    >
      <BlankDataContainer>
        <Icon type="ic-folder" fill="primary" />
        <BlankFolderInput
          ref={inputRef}
          value={newFolderName}
          placeholder="Enter folder name"
          onPressEnter={handleCreateFolder}
          onChange={changeInputHandler}
          onKeyDown={escapeKeyHandler}
          autoFocus
        />
      </BlankDataContainer>
      <BlankButtonControls>
        <Icon onClick={handleCreateFolder} type="ic-check-circle" fill="success" />
      </BlankButtonControls>
    </BlankListItem>
  );
};

export default BlankFolder;
