import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useState } from 'react';
import get from 'lodash/get';

import Loader from 'components/loading';
import {
  checkEmail,
  updateAccount,
} from 'data/actions/user';
import { Button, Input } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { modalsSelector } from 'data/selectors/modals';
import { verifyPhoneLoadingSelector, verifyTokenLoadingSelector } from 'data/selectors/loading';
import {
  validateEmail,
} from 'helpers/validate';

import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../baseModal/styles';
import {
  SubHeader,
  Header,
  InputWrapper,
} from './styles';

const ChangeEmailModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const loading = useSelector(verifyPhoneLoadingSelector);
  const loadingVerifyingToken = useSelector(verifyTokenLoadingSelector);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleClose = useCallback(() => dispatch(toggleModal('change_email', false)), [dispatch]);

  const handleCheckEmail = () => {
    if (validateEmail(email)) {
      dispatch(checkEmail({
        email,
        onSuccess: () => {
          setError('');
        },
        onFailed: (err) => {
          let text = get(err, 'response.data.message', get(err, 'response.data'));
          if (!text) text = 'User already exists';
          dispatch(toggleMessage('warning', { header: text }));
          setError(text);
        },
      }));
    }
  };

  const handleChangeEmail = () => {
    dispatch(updateAccount(
      [{ email }],
      () => {
        localStorage.setItem('tempEmail', email);
        dispatch(toggleMessage('success', { header: "Please confirm your new email address by clicking the link in the email we've just sent you!" }));
        handleClose();
      },
      (err) => {
        let text = get(err, 'response.data.message', get(err, 'response.data'));
        if (!text) text = 'Something went wrong';
        dispatch(toggleMessage('warning', { header: text }));
        setError(text);
      },
    ));
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle white>
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      width={350}
      bodyStyle={{ padding: 35, paddingTop: 0 }}
      footer={false}
      className="empty-borders"
      onCancel={handleClose}
      closable={false}
    >
      <Loader loading={loading}>
        <Header>Change sign up email</Header>
        <SubHeader>Enter new email address</SubHeader>
        <InputWrapper>
          <Input
            name="email"
            value={email}
            placeholder="Enter your work email address"
            onChange={(e) => setEmail(e.target.value)}
            error={error}
            autoFocus
            onBlur={handleCheckEmail}
          />
        </InputWrapper>
        <Footer>
          <Button
            style={{ width: '100%' }}
            data-form="true"
            type="primary"
            size="large"
            disabled={!email || error}
            onClick={handleChangeEmail}
            loading={loadingVerifyingToken}
          >
            Confirm
          </Button>
        </Footer>
      </Loader>
    </Modal>
  );
};

export default ChangeEmailModal;
