const employmentOption = {
  name: 'Employment',
  data: [
    {
      label: 'Full time',
      value: 'Full time',
    },
    {
      label: 'Part time',
      value: 'Part time',
    },
    {
      label: 'Self employed',
      value: 'Self employed',
    },
    {
      label: 'Student',
      value: 'Student',
    },
    {
      label: 'Homemaker',
      value: 'Homemaker',
    },
    {
      label: 'Retired',
      value: 'Retired',
    },
    {
      label: 'Disabled',
      value: 'Disabled',
    },
    {
      label: 'Unemployed',
      value: 'Unemployed',
    },
  ],
};

export default employmentOption;
