import React, {
  useCallback, useState, useRef, memo, useMemo, useEffect,
} from 'react';
import get from 'lodash/get';
import * as SearchFiltersTypes from 'data/types/search.filters.types';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterSettingsSelector } from 'data/selectors/filters';
import {
  TextArea, RadioGroup, RadioButton,
} from 'components/common';
import { getFiltersSelector, getFilterOptionsSelector, getExistedDefaultFilterOptions } from 'data/selectors/search';
import useResetParams from 'hooks/useResetParams';
import useInputMutex from 'hooks/useInputMutex';
import { FILTERS_INPUT_LIMITS } from 'constants/limitsSearch';
import { updateFilterSettings } from 'data/actions/filters';
import { addOptionToIncludedFilters, updateFiltersCheckboxes } from 'data/actions/search';
import { tourCurrentNameSelector } from 'data/selectors/tour';
import { incrementUpdateTour } from 'data/actions/tour';
import { TOUR } from 'data/types/tours.types';
import SuggestOptions from './suggestOptions';
import {
  ControlsContainer,
  InputClearImg,
  ControlsWrapper,
  TextAreaStyles,
  AutoCompliteItem,
  RadioBtnStyle,
  RadioGroupWrapper,
  FilterCheckbox,
} from '../../../sidebar/styles';

const FiltersInput = ({
  filter, showOptionsList, hasIncluded,
}) => {
  const {
    autocompleteOptions, checkboxOptions, input, property, includedOp, splittable, innerItemsDataTour = [], updateOnly,
  } = filter;

  const dispatch = useDispatch();
  const inputRef = useRef();
  const containerRefForDropdown = useRef();
  const tourName = useSelector(tourCurrentNameSelector);
  const filtersSelector = getFiltersSelector(property);
  const filters = useSelector(filtersSelector);
  const optionsSelector = getFilterOptionsSelector(property);
  const options = useSelector(optionsSelector);
  const isExistDefaultOptionsSelector = getExistedDefaultFilterOptions(property);
  const isExistDefaultOptions = useSelector(isExistDefaultOptionsSelector);
  const [showTree, setShowTree] = useState(false);
  const [predictedItemMultipleValues, setPredictedMulitpeItem] = useState();

  const [checkboxes, setCheckboxes] = useState(filters?.checkboxes || (checkboxOptions?.map((el) => el.default && el.value).filter((el) => el) ?? []));
  const resetParams = useResetParams((element) => addOptionToIncludedFilters({
    property, includedOp, element, splittable, checkboxes,
  }));
  const filterKey = `filter_item_${property}_key`;
  const filterSettings = useSelector(getFilterSettingsSelector(filterKey));
  const [radioValue, setRadioValue] = useState(filterSettings?.radioValue ?? get(autocompleteOptions?.find((e) => e.default), 'value'));
  const radioDataRef = useRef(radioValue);
  const focusInput = useCallback((showTreeFlag = true) => {
    if (showTreeFlag) {
      setShowTree(true);
    }
    return inputRef.current && inputRef.current.focus();
  }, []);
  const [isFirstAutocompleteRequest, setFirstAutocompleteRequest] = useState(true);
  const [wasFirstInputChanged, setFirstInputChanged] = useState(false);
  const inputWithDataRef = useRef();

  const updateCheckboxes = useResetParams((item) => {
    const newArr = [...checkboxes];
    const index = checkboxes.indexOf(item);
    if (index === -1) {
      newArr.push(item);
    } else {
      newArr.splice(index, 1);
    }

    setCheckboxes(newArr);
    dispatch(updateFiltersCheckboxes({ property, checkboxes: newArr }));
  });

  const setRadio = useCallback((e) => {
    const filterId = `filter_item_${property}_key`;
    dispatch(updateFilterSettings({ filterId, name: 'radioValue', value: e.target.value }));
    setRadioValue(e.target.value);
    radioDataRef.current = e.target.value;
    focusInput();
  }, [dispatch, property, focusInput]);

  const handleSearch = useCallback((val) => {
    if (input?.autoComplete) {
      const { autoComplete } = input;

      if ((val || (!val && !hasIncluded)) || [SearchFiltersTypes.SEARCH_FILTER_TYPES.TREE, SearchFiltersTypes.SEARCH_FILTER_TYPES.LIST].includes(filter.type)) {
        if (isFirstAutocompleteRequest && !val && filter.showDefaultBeforeFirstType) {
          if (wasFirstInputChanged) {
            setFirstAutocompleteRequest(false);
          }
          if (!isExistDefaultOptions) {
            dispatch(autoComplete(''));
          }
          return false;
        }
        const includedFilters = get(filters, 'included.value', []) ?? [];
        const excludedFilters = get(filters, 'excluded.value', []) ?? [];
        const selectedOptions = [...includedFilters, ...excludedFilters];

        // fixed problem for suggested options in response.
        if ((selectedOptions.length && !val && filter.showLastSearchWhenEmptyInput) || (!val && filter.showLastSearchWhenEmptyInput)) return false;
        dispatch(autoComplete(val, radioDataRef?.current));
      }
    }
    return true;
  }, [input, dispatch, hasIncluded, filter.showDefaultBeforeFirstType, filter.showLastSearchWhenEmptyInput, filter.type, isFirstAutocompleteRequest, wasFirstInputChanged, filters, isExistDefaultOptions]);

  const [value, setValue] = useInputMutex({
    callback: handleSearch,
    minSearchValueLength: FILTERS_INPUT_LIMITS.MIN_SEARCH_VALUE_LENGTH,
    maxRequestRate: FILTERS_INPUT_LIMITS.MAX_REQUEST_RATE,
    compareLast: false,
  });

  const clearInput = useCallback(() => setValue(''), [setValue]);

  const submitValue = (e) => {
    if (tourName !== TOUR.DEMO_SEARCH_LEADS) {
      e.preventDefault();
    }
    e.stopPropagation();
    const data = e.target.value?.trim();

    if (SearchFiltersTypes.SEARCH_FILTER_TYPES.TREE === filter?.type && !predictedItemMultipleValues) {
      const matchedOption = options.some((option) => {
        let result = option.name === data;

        if (!result) {
          result = get(option, 'children', []).some((child) => get(child, 'name', child) === data);
        }
        return result;
      });

      if (!matchedOption) return;
    }

    if (data?.length) {
      resetParams(predictedItemMultipleValues?.label === data && predictedItemMultipleValues?.value?.length ? predictedItemMultipleValues?.value : data);

      if (tourName === TOUR.DEMO_SEARCH_LEADS) {
        dispatch(incrementUpdateTour());
      }
    }
    if (tourName !== TOUR.DEMO_SEARCH_LEADS) {
      clearInput();
    }
  };

  const autoComplitePrediction = useMemo(() => {
    if (!showOptionsList || !options?.length) return '';
    let optionToComplite = '';

    options.some((option) => {
      const item = get(option, 'children', option);
      let result;
      const isCategoryMatch = new RegExp(`^${value}.*`).test(get(option, 'name', ''));

      if (isCategoryMatch && option?.name) {
        result = option.name;
      } else if (Array.isArray(item)) {
        result = item.find((subItem) => {
          const subItemName = get(subItem, 'name', subItem);
          return new RegExp(`^${value}.*`).test(subItemName);
        }) ?? '';

        if (result?.name) {
          result = result.name;
        }
      } else if (typeof (item?.label ?? item) === 'string') {
        const isCorrectComplite = new RegExp(`^${value}.*`).test((item?.label ?? item));

        if (isCorrectComplite) {
          result = (item?.label ?? item);
        }
      }

      if (result) {
        optionToComplite = result;

        if (SearchFiltersTypes.multipleValProps.some((prop) => prop === property) && item?.value?.length) {
          const includedFilters = get(filters, 'included.value', []) ?? [];
          const excludedFilters = get(filters, 'excluded.value', []) ?? [];
          const selectedOptions = [...includedFilters, ...excludedFilters];

          if (!selectedOptions.some((selectedOption) => (Array.isArray(selectedOption) ? selectedOption[0] === result : false))) {
            setPredictedMulitpeItem(item);
          } else {
            optionToComplite = '';
            result = false;
          }
        }
      }
      return result;
    });

    return optionToComplite;
  }, [showOptionsList, options, value, filters, property]);

  const inputProps = {
    size: 'default',
    'data-ignore': 'true',
    onChange: (props) => {
      if (!wasFirstInputChanged) {
        setFirstInputChanged(true);
      }
      const delimiters = ['\n', '\r', '\r\n'];
      if (delimiters.some((delimiter) => props.target.value.includes(delimiter))) {
        submitValue(props);
      } else {
        setPredictedMulitpeItem(null);
        inputWithDataRef.current = props.target.value;
        setValue(props.target.value);
      }
    },
    onKeyDown: (e) => {
      if (!showTree) {
        setShowTree(true);
      }
      if ([9, 39].includes(e?.which) && autoComplitePrediction && autoComplitePrediction !== value) {
        e.preventDefault();
        setValue(autoComplitePrediction);
      }
    },
    value,
    placeholder: input?.placeholder,
    autoFocus: true,
    onPressEnter: submitValue,
    suffix: value ? <InputClearImg onClick={clearInput} /> : <span />,
    ref: inputRef,
    onClick: () => setShowTree((prevState) => !prevState),
  };

  useEffect(() => {
    setShowTree(true);
  }, []);

  const radioGroup = useMemo(() => (autocompleteOptions ? (
    <RadioGroupWrapper>
      <RadioGroup value={radioValue} onChange={setRadio}>
        {autocompleteOptions.map((option) => (
          <RadioButton key={`search_radio_filter_${option.value}`} value={option.value} size="default" style={RadioBtnStyle}>
            {option.label}
          </RadioButton>
        ))}
      </RadioGroup>
    </RadioGroupWrapper>
  ) : null), [autocompleteOptions, radioValue, setRadio]);

  const suggestedOptions = useMemo(() => (
    <SuggestOptions
      updateOnly={updateOnly}
      checkboxes={checkboxes}
      processUniqueStrategy={filter.processUniqueStrategy}
      renderOption={filter.renderOption}
      clearInput={clearInput}
      property={filter.property}
      includedOp={filter.includedOp}
      splittable={filter.splittable}
      type={filter.type}
      showTree={showTree}
      setShowTree={setShowTree}
      radioGroup={radioGroup}
      input={input}
      dataRef={containerRefForDropdown}
      focusInput={focusInput}
      hideDropdownWhenDefaultListEmpty={filter.hideDropdownWhenDefaultListEmpty}
      wasFirstInputChanged={wasFirstInputChanged}
      inputWithDataRef={inputWithDataRef}
      disableNotFoundDropdown={filter.disableNotFoundDropdown}
    />
  ), [updateOnly, checkboxes, filter, clearInput, focusInput, containerRefForDropdown, input, radioGroup, showTree, wasFirstInputChanged]);

  return (
    <ControlsContainer ref={containerRefForDropdown} id="check" data-tour={innerItemsDataTour.join(' ')}>
      {input && showOptionsList && (
        <>
          <ControlsWrapper>
            <TextArea
          {...inputProps} // eslint-disable-line
              wrap="off"
              style={TextAreaStyles}
              id="parent-option-list"
            />
            {value && value !== autoComplitePrediction ? (
              <AutoCompliteItem>
                {autoComplitePrediction}
              </AutoCompliteItem>
            ) : null}
          </ControlsWrapper>
        </>
      )}
      {checkboxOptions && checkboxOptions.map((option, index) => (
        <FilterCheckbox onChange={() => updateCheckboxes(option.value)} checked={checkboxes.includes(option.value)} size="small" key={+index} defaultChecked={option.default}>
          {option.label}
        </FilterCheckbox>
      ))}
      { ((showOptionsList && filter?.count === SearchFiltersTypes.COUNT_OPTIONS_TYPES.SINGLE && !hasIncluded) || (showOptionsList && !filter?.count))
      && (
        suggestedOptions
      ) }
    </ControlsContainer>
  );
};

export default memo(FiltersInput);
