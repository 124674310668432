import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { accountSelector } from 'data/selectors/user';

const useHubspot = () => {
  const account = useSelector(accountSelector);

  const openHubspotCalendar = useCallback(({
    calendarPath,
    cancelReason,
  }) => {
    const params = new URLSearchParams();
    const PREFILLED_PROPERTIES = ['firstName', 'lastName', 'email', 'phone'];
    Object.keys(account).forEach((property) => {
      if (PREFILLED_PROPERTIES.includes(property) && account[property]) {
        params.append(property, account[property]);
      }
    });
    if (cancelReason) {
      params.append('cancel_reason', cancelReason);
    }
    window.open(`https://meetings.hubspot.com/${calendarPath || 'getprospect/demo'}?${params.toString()}`);
  }, [account]);

  const openCancelReasonHubspotCalendar = useCallback(({
    cancelReason,
  }) => {
    openHubspotCalendar({
      calendarPath: '',
      cancelReason,
    });
  }, [openHubspotCalendar]);

  const openDeleteAccountHubspotCalendar = useCallback(({
    cancelReason,
  }) => {
    openHubspotCalendar({
      calendarPath: '',
      cancelReason,
    });
  }, [openHubspotCalendar]);

  return {
    openHubspotCalendar,
    openCancelReasonHubspotCalendar,
    openDeleteAccountHubspotCalendar,
  };
};

export default useHubspot;
