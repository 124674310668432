import React from 'react';
import { Tooltip } from 'antd';
import { useDispatch } from 'react-redux';

import { COLORS } from 'constants/styles';
import { Icon } from 'components/common';
import { insertUnsubscribeLinkIntoEditor } from 'data/actions/sequences';
import {
  ActionButton,
} from '../styles';

const InsertUnsubscribe = ({
  loadingEditor,
}) => {
  const dispatch = useDispatch();

  const insertUnsubscribeLink = () => {
    dispatch(insertUnsubscribeLinkIntoEditor());
  };

  return (
    <Tooltip title="Insert unsubscribe link">
      <ActionButton loading={loadingEditor} onClick={insertUnsubscribeLink}>
        <Icon style={{ marginTop: 3 }} outlined fill={COLORS.GREY_G1} size="small" type="ic-unsubscribe" />
      </ActionButton>
    </Tooltip>
  );
};

export default InsertUnsubscribe;
