import get from 'lodash/get';

import { contactListsSelector, listsSelector } from 'data/selectors/lists';
import { contactPopulatedListsSelector, contactSelector } from 'data/selectors/contacts';
import { toggleMessage } from './message';
import { createBulkOperation } from './bulkOperations';
import { setDeletedContactsParams, updatePreviewContactListsRelations } from './contacts';

export const createList = (body, onSuccessCreateList) => (dispatch) => {
  dispatch({
    type: 'CREATE_LIST',
    payload: {
      endpoint: 'api/v1/contacts/lists',
      method: 'POST',
      body,
    },
    components: ['listsLoading'],
    onSuccess: onSuccessCreateList,
  });
};

export const updateList = (listId, body) => (dispatch) => {
  dispatch({
    type: 'UPDATE_LIST',
    payload: {
      endpoint: `api/v1/contacts/lists/${listId}`,
      method: 'PATCH',
      body,
    },
    components: ['listsLoading'],
  });
};

export const updateCurrentList = (name, value) => (dispatch) => {
  dispatch({
    type: 'UPDATE_CURRENT_LIST',
    data: {
      name,
      value,
    },
  });
};

export const getCurrentListSize = (listId) => (dispatch) => {
  dispatch({
    type: 'GET_CURRENT_LIST_SIZE',
    payload: {
      endpoint: 'api/v1/contacts/lists/lists-sizes',
      body: { listIds: [listId] },
      method: 'POST',
    },
  });
};

export const setCurrentList = (list, getSize = false) => (dispatch) => {
  dispatch({
    type: 'SET_CURRENT_LIST',
    data: {
      list,
    },
  });

  if (getSize && list?._id) dispatch(getCurrentListSize(list._id));
};

export const toggleSelectAllLists = (value, deleted = false) => (dispatch) => {
  dispatch({
    type: 'TOGGLE_SELECT_ALL_LISTS',
    data: {
      value,
      deleted,
    },
  });
};

export const moveContactsToStaticList = ({
  listIds,
  contactsIds,
  onSuccess = () => {},
  onFailed = () => {},
}) => (dispatch, getState) => {
  const state = getState();
  const filters = get(state, 'contacts.contacts.filters', []);
  const search = get(state, 'contacts.contacts.search');

  const params = {
    selectAll: get(state, 'contacts.selectAllContacts.selectAll', false),
  };

  const onSuccessProxy = (data) => {
    if (data._id) {
      dispatch(createBulkOperation({ _id: data._id, sourceType: 'contacts', type: 'move to static list' }));
    }
    onSuccess(data);
  };

  dispatch({
    type: 'MOVE_CONTACTS_TO_STATIC_LIST',
    payload: {
      endpoint: 'api/v1/bulk-operations',
      method: 'POST',
      body: {
        type: 'move_to_static_list',
        sourceType: 'contacts',
        listIds,
        search,
        itemIds: params.selectAll ? [] : contactsIds,
        filters,
      },
    },
    listIds,
    onSuccess: onSuccessProxy,
    onFailed,
  });
};

export const deleteContactsFromStaticList = ({
  objectIdList,
  listIds,
  onSuccess = () => {},
  onFailed = () => {},
  fromPreviewBar = false,
}) => (dispatch, getState) => {
  const state = getState();
  const filters = get(state, 'contacts.contacts.filters', []);
  const search = get(state, 'contacts.contacts.search');

  const lists = contactPopulatedListsSelector(state);
  const populatedLists = lists.filter((list) => listIds.includes(list._id));

  const params = {
    selectAll: get(state, 'contacts.selectAllContacts.selectAll', false),
  };

  const onSuccessProxy = (data) => {
    if (data._id) {
      dispatch(createBulkOperation({ _id: data._id, sourceType: 'contacts', type: 'delete from static list' }));
    }
    onSuccess(data);
  };

  dispatch({
    type: 'DELETE_CONTACTS_FROM_STATIC_LIST',
    payload: {
      endpoint: 'api/v1/bulk-operations',
      method: 'POST',
      body: {
        type: 'delete_from_static_list',
        itemIds: params.selectAll ? [] : objectIdList,
        sourceType: 'contacts',
        filters,
        search,
        listIds,
      },
    },
    onSuccess: onSuccessProxy,
    onFailed,
  });

  if (fromPreviewBar) {
    const newContact = get(state, 'contacts.contactPreview');
    if (newContact) {
      const listRelations = (newContact.listRelations || []).filter((list) => !listIds.includes(list));
      dispatch(updatePreviewContactListsRelations({
        listRelations,
        populatedLists,
        toDelete: true,
      }));
    }
    const newContactSidebar = get(state, 'contacts.contactPreviewSidebar');
    if (newContactSidebar) {
      const listRelations = (newContactSidebar.listRelations || []).filter((list) => !listIds.includes(list));
      dispatch(updatePreviewContactListsRelations({
        listRelations,
        populatedLists,
        toDelete: true,
        isSidebar: true,
      }));
    }
  }
};

export const cloneList = ({ name, listId }, onSuccess) => (dispatch) => {
  dispatch({
    type: 'CLONE_LIST',
    payload: {
      endpoint: 'api/v1/contacts/lists/clone',
      method: 'POST',
      body: {
        name,
        listId,
      },
    },
    onSuccess,
    onFailed: (err) => dispatch(toggleMessage('error', { header: get(err, 'message', 'Failed to clone List') })),
  });
};

export const setContactsListInitialRequest = (initialRequest) => (dispatch) => {
  dispatch({
    type: 'SET_CONTACTS_LIST_INITIAL_REQUEST',
    data: { initialRequest },
  });
};

export const getListsSizes = ({
  lists = [], onSuccess = () => {}, onFailed = () => {},
}) => (dispatch) => {
  const listIds = lists.map((list) => list._id);

  if (listIds.length) {
    dispatch({
      type: 'GET_LISTS_SIZES',
      payload: {
        endpoint: 'api/v1/contacts/lists/lists-sizes',
        method: 'POST',
        body: {
          listIds,
        },
        delayLoad: 0,
      },
      components: ['listsSizesLoading'],
      onSuccess,
      onFailed,
    });
  }
};

export const deleteFolder = (folderIds, all) => (dispatch) => {
  dispatch({
    type: 'DELETE_FOLDERS',
    payload: {
      endpoint: 'api/v1/contacts/folders',
      method: 'DELETE',
      body: {
        folderIds,
      },
    },
    folderId: all ? 'all' : get(folderIds, '0', ''),
  });
};

export const getContactsFolders = () => (dispatch) => {
  dispatch({
    type: 'GET_CONTACTS_FOLDERS',
    payload: {
      endpoint: 'api/v1/contacts/folders/tree/list',
      method: 'GET',
      delayLoad: 0,
    },
    components: ['foldersTable'],
  });
};

export const createFolder = ({
  name,
  kind,
  parent,
  onSuccess,
}) => (dispatch) => {
  dispatch({
    type: 'CREATE_FOLDER',
    payload: {
      endpoint: 'api/v1/contacts/folders',
      method: 'POST',
      body: {
        name,
        kind,
        parent,
      },
    },
    kind,
    parent,
    onSuccess,
  });
};

export const getFoldersRelation = () => (dispatch) => {
  dispatch({
    type: 'GET_FOLDERS_RELATION',
    payload: {
      endpoint: 'api/v1/contacts/folders/lists',
      method: 'GET',
    },
  });
};

export const moveListsToFolder = ({
  kind,
  folderId,
  entityIds,
  excludedIds,
}) => (dispatch) => {
  dispatch({
    type: 'MOVE_LISTS_TO_FOLDER',
    payload: {
      endpoint: `api/v1/contacts/folders/${folderId}/${kind}`,
      method: 'POST',
      body: {
        entityIds,
        excludedIds,
      },
    },
    onSuccess: () => dispatch(toggleSelectAllLists(false)),
    folderId,
    kind,
    entityIds,
  });
};

export const moveFolder = (folderId, parent) => (dispatch) => {
  dispatch({
    type: 'MOVE_FOLDER',
    payload: {
      endpoint: `api/v1/contacts/folders/${folderId}`,
      method: 'PATCH',
      body: {
        parent,
      },
    },
  });
};

export const getContactsList = (listId) => (dispatch) => {
  dispatch({
    type: 'GET_CONTACTS_LIST',
    data: {
      listId,
    },
  });
};

export const setSelectedLists = (data) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_LISTS',
    data,
  });
};

export const moveListsWithFoldersToFolder = ({
  kind,
  data,
  folderId,
}) => (dispatch) => {
  dispatch({
    type: 'MOVE_LISTS_WITH_FOLDERS_TO_FOLDER',
    payload: {
      endpoint: `api/v1/contacts/folders/${folderId}/all`,
      method: 'POST',
      body: data,
    },
    kind,
    requestData: data,
  });
};

export const incrementList = (listId) => (dispatch) => {
  dispatch({
    type: 'INCREMENT_LIST',
    data: {
      listId,
    },
  });
};

export const decrementList = (listId, count = 1) => (dispatch) => {
  dispatch({
    type: 'DECREMENT_LIST',
    data: {
      listId,
      count,
    },
  });
};

export const searchDashboardLists = () => (dispatch) => {
  dispatch({
    type: 'SEARCH_DASHBOARD_LISTS',
    payload: {
      endpoint: 'api/v1/contacts/lists/search',
      method: 'POST',
      body: {
        deleted: false,
        searchListType: 'all',
      },
      params: {
        pageNumber: 1,
        pageSize: 4,
      },
      delayLoad: 0,
    },
    components: ['listsSizesLoading'],
    onSuccess: () => {
      dispatch({
        type: 'SEARCH_DASHBOARD_LISTS',
        payload: {
          endpoint: 'api/v1/contacts/lists/search',
          method: 'POST',
          body: {
            deleted: false,
            searchListType: 'sizes',
          },
          params: {
            pageNumber: 1,
            pageSize: 4,
          },
          delayLoad: 0,
        },
        searchListType: 'sizes',
        components: ['listsSizesLoading'],
      });
    },
  });
};

export const searchLists = ({
  withParentFolder = true,
  onSuccess = () => {},
  onFailed = () => {},
  deleted = false,
  searchListType = 'all',
  customParams = null,
  customFilters = null,
  withSizes = false,
  recentSearch = {},
  dropDownSearch = {},
  dropDownFilters = [],
} = {}) => (dispatch, getState) => {
  const state = getState();
  const {
    pageSize, page, sort, search, filters,
  } = deleted ? get(
    state, 'lists.deletedLists',
  ) : get(
    state, 'lists.lists',
  );
  const params = customParams || {
    pageSize,
    pageNumber: page,
    sort: sort?.column,
    order: sort?.order,
  };

  const customSearch = recentSearch?.value ? recentSearch : dropDownSearch;
  const body = ['scroll', 'recent'].includes(searchListType) ? { filters: dropDownFilters, search: customSearch, searchListType } : {
    filters: customFilters || filters, search, withParentFolder, deleted, searchListType,
  };

  const onFailedDefault = (error) => {
    dispatch(setContactsListInitialRequest(false));
    onFailed(error);
  };

  let components = [];

  if (searchListType !== 'sizes') components = deleted ? ['deletedItemsTable'] : ['listContactsTable', 'contactsListTable'];
  else components = customFilters ? ['recentListsSizesLoading'] : ['listsSizesLoading'];
  if (searchListType === 'recent') components = ['recentLists'];
  if (searchListType === 'scroll') components = ['listSelect'];

  dispatch({
    type: (recentSearch?.value) ? 'SEARCH_RECENT_LISTS' : 'SEARCH_LISTS',
    payload: {
      endpoint: 'api/v1/contacts/lists/search',
      method: 'POST',
      body,
      params,
      delayLoad: (searchListType === 'sizes') ? 0 : 200,
      cancelPrevRequests: !['recent', 'sizes'].includes(searchListType),
    },
    deleted,
    searchListType,
    searchValue: dropDownSearch?.value || recentSearch?.value || '',
    components,
    onSuccess: (res) => {
      onSuccess(res);
      if (withSizes) {
        dispatch({
          type: 'SEARCH_LISTS',
          payload: {
            endpoint: 'api/v1/contacts/lists/search',
            method: 'POST',
            body,
            params,
            delayLoad: 0,
            cancelPrevRequests: true,
          },
          deleted,
          searchListType: 'sizes',
          components: ['listsSizesLoading'],
        });
      }
    },
    onFailed: onFailedDefault,
  });
};

export const getNewRecentListsSizes = (listsIds) => (dispatch) => {
  dispatch(searchLists({
    searchListType: 'sizes',
    customFilters: [{
      property: '_id',
      included: {
        operator: 'CONTAINS_ANY',
        value: listsIds,
      },
    }],
  }));
};

export const listAddContacts = ({
  listIds,
  contactsIds,
  onSuccess,
  onFailed = () => {},
  fromPreviewBar = false,
  filters,
  search,
  selectAll,
}) => (dispatch, getState) => {
  const state = getState();

  let { data: lists } = contactListsSelector(state);

  if (!lists?.length) {
    const listsData = listsSelector(state);
    lists = listsData?.data;
  }
  const populatedLists = lists?.filter((list) => listIds.includes(list._id));

  const params = {
    selectAll,
  };

  const onSuccessProxy = (data) => {
    if (data._id) {
      dispatch(createBulkOperation({ _id: data._id, sourceType: 'contacts', type: 'add to static list' }));
    }
    if (onSuccess) {
      onSuccess(data);
    }
  };

  dispatch({
    type: 'LIST_ADD_CONTACTS',
    payload: {
      endpoint: 'api/v1/bulk-operations',
      method: 'POST',
      body: {
        type: 'add_to_static_list',
        sourceType: 'contacts',
        listIds,
        search,
        itemIds: params.selectAll ? [] : contactsIds,
        filters,
      },
    },
    listIds,
    onSuccess: onSuccessProxy,
    onFailed,
  });

  if (fromPreviewBar) {
    const newContact = get(state, 'contacts.contactPreview');
    if (newContact) {
      dispatch(updatePreviewContactListsRelations({
        listRelations: [...listIds, ...(newContact.listRelations || [])],
        populatedLists,
      }));
    }
    const newContactSidebar = get(state, 'contacts.contactPreviewSidebar');
    if (newContactSidebar) {
      dispatch(updatePreviewContactListsRelations({
        listRelations: [...listIds, ...(newContact.listRelations || [])],
        populatedLists,
        isSidebar: true,
      }));
    }
  }
};

export const setDropdownListsParams = ({
  page,
  searchValue = '',
  disableSearch = false,
  filters = [],
  onSuccess = () => {},
  onFailed = () => {},
}) => (dispatch, getState) => {
  const state = getState();
  const { page: currentPage, filters: currentFilters } = state.lists.dropdownLists;
  let search = {};
  if (searchValue.length) {
    search = { property: 'name', value: searchValue, operator: 'CONTAINS' };
  }
  dispatch({
    type: 'SET_DROPDOWN_LISTS_PARAMS',
    data: {
      page: (searchValue.length ? 1 : page) || currentPage,
      search,
      filters: filters.length ? filters : currentFilters,
    },
  });

  if (!disableSearch) {
    dispatch(searchLists({
      customParams: {
        pageNumber: page,
        pageSize: 10,
        sort: 'updatedAt',
        order: 'DESC',
      },
      withParentFolder: true,
      searchListType: 'scroll',
      dropDownSearch: search,
      dropDownFilters: filters.length ? filters : currentFilters,
      onSuccess,
      onFailed,
    }));
  }
};

export const getRecentLists = () => (dispatch) => {
  dispatch(searchLists({
    searchListType: 'recent',
    customParams: {
      pageSize: 10,
    },
  }));
};

export const unsetDropdownLists = (saveFilters = false) => (dispatch) => {
  dispatch({
    type: 'UNSET_DROPDOWN_LISTS',
    data: {
      saveFilters,
    },
  });
};

export const setDeletedListsParams = ({
  pageSize,
  page,
  sort,
  search,
  filters,
  disableSearch = true,
  isChanged = false,
  withSizes = false,
  onSuccess = () => {},
  onFailed = () => {},
} = {}) => (dispatch, getState) => {
  const state = getState();
  const currentPageSize = get(state, 'lists.deletedLists.pageSize');
  const currentPage = get(state, 'lists.deletedLists.page');
  const currentFilters = get(state, 'lists.deletedLists.filters', []);
  const currentSearch = get(state, 'lists.deletedLists.search');
  const currentSort = get(state, 'lists.deletedLists.sort');

  const data = {
    pageSize: pageSize || currentPageSize,
    page: pageSize > currentPageSize ? 1 : page || currentPage,
    sort: sort || currentSort,
    filters: filters || currentFilters,
    search: search || currentSearch,
    isChanged,
  };
  dispatch({
    type: 'SET_DELETED_LISTS_PARAMS',
    data,
  });

  if (!disableSearch) {
    const searchListsSizes = withSizes ? () => {
      dispatch(searchLists({
        deleted: true,
        searchListType: 'sizes',
      }));
    } : () => {};

    const handleSuccess = () => {
      onSuccess();
      searchListsSizes();
    };

    dispatch(searchLists({
      deleted: true,
      onSuccess: () => handleSuccess(),
      onFailed,
    }));
  }
};

export const setListsParams = ({
  pageSize,
  page,
  sort,
  search,
  filters,
  disableSearch = false,
  withSizes = true,
  onSuccess = () => {},
  onFailed = () => {},
} = {}) => (dispatch, getState) => {
  const state = getState();
  const currentPageSize = get(state, 'lists.lists.pageSize');
  const currentPage = get(state, 'lists.lists.page');
  const currentFilters = get(state, 'lists.lists.filters', []);
  const currentSearch = get(state, 'lists.lists.search');
  const currentSort = get(state, 'lists.lists.sort');

  const data = {
    pageSize: pageSize || currentPageSize,
    page: ((pageSize > currentPageSize) || ((search?.value && currentSearch?.value) ? (search.value !== currentSearch.value) : false)) ? 1 : page || currentPage,
    sort: {
      column: sort?.columnKey || currentSort?.column || '',
      order: sort?.order || (sort?.columnKey ? null : currentSort?.order) || '',
    },
    filters: filters || currentFilters,
    search: search || currentSearch,
  };
  dispatch({
    type: 'SET_LISTS_PARAMS',
    data,
  });

  if (!disableSearch) {
    const searchListsSizes = withSizes ? () => {
      dispatch(searchLists({
        searchListType: 'sizes',
        components: ['listsSizesLoading'],
      }));
    } : () => {};

    const handleSuccess = () => {
      onSuccess();
      searchListsSizes();
    };

    dispatch(searchLists({
      customParams: {
        pageSize: data.pageSize,
        pageNumber: data.page,
        sort: data?.sort?.column,
        order: data?.sort?.order,
      },
      customFilters: data.filters,
      onSuccess: () => handleSuccess(),
      onFailed,
    }));
  }
};

export const updateListsConditions = ({
  listsIds,
  updateContactsStatus = false,
  onSuccess = () => {},
  operationType = 'delete',
  excludedRowKeys,
}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_LISTS_CONDITIONS',
    payload: {
      endpoint: 'api/v1/contacts/lists',
      method: 'PATCH',
      body: {
        listsIds,
        updateContactsStatus,
        operationType,
        excludedIds: excludedRowKeys,
      },
    },
    components: (operationType !== 'delete') ? ['deletedItemsTable'] : [],
    listsIds,
    excludedRowKeys,
    operationType,
    onSuccess: (res) => {
      onSuccess(res);
      dispatch(searchLists());
      dispatch(searchDashboardLists());
      dispatch(searchLists({ searchListType: 'recent' }));
      dispatch(setDeletedContactsParams({ page: 1, disableSearch: true, isChanged: true }));
      dispatch(dispatch(toggleMessage('success', { header: `List${listsIds.length === 1 ? ' was' : 's were'} ${(operationType === 'restore') ? 'restored' : 'deleted'}` })));
    },
  });
};

export const searchRecentLists = ({
  search,
  onSuccess = () => {},
  onFailed = () => {},
}) => (dispatch, getState) => {
  const state = getState();
  const viewedLists = get(state, 'lists.viewedLists', []);
  dispatch(searchLists({
    customParams: {
      pageNumber: 1,
      pageSize: 10,
    },
    recentSearch: search,
    searchListType: 'recent',
    viewedLists,
    onSuccess,
    onFailed,
  }));
};

export const setListSize = (listId, newSize) => (dispatch) => {
  dispatch({
    type: 'SET_LIST_SIZE',
    data: {
      listId,
      newSize,
    },
  });
};

export const searchContactLists = (searchValue) => (dispatch, getState) => {
  const state = getState();

  const contact = contactSelector(state);
  const countContactLists = contact?.listRelations?.length || 0;

  dispatch({
    type: 'SEARCH_CONTACT_LISTS',
    payload: {
      endpoint: 'api/v1/contacts/lists/search',
      method: 'POST',
      body: {
        deleted: false,
        searchListType: 'all',
        search: {
          value: searchValue,
          properties: ['name'],
        },
      },
      params: {
        pageNumber: 1,
        pageSize: countContactLists + 4,
      },
    },
    listRelations: contact?.listRelations || [],
    components: ['searchContactListsLoading'],
  });
};
