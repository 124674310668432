import { validateUrl } from './validate';

export const domainPrintedDataMap = (item) => {
  if (item?.file) return item;
  const containHTTP = validateUrl(item);
  let { hostname } = new URL(containHTTP ? item : `http://${item.replace(' ', '')}`);
  hostname = hostname.replace(/^www\./g, '');

  return { domain: hostname, printedData: item };
};
