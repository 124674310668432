import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../baseModal/styles';

const SelectAllListsModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { selectAll, deleted = false } = useSelector(modalOptionsSelector);

  const handleSelectAll = () => {
    selectAll();
    dispatch(toggleModal('select_all_lists', false));
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Warning
          <CloseIcon
            onClick={() => dispatch(toggleModal('select_all_lists', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={() => dispatch(toggleModal('select_all_lists', false))}
    >
      <div>
        All
        {' '}
        {deleted ? 'deleted ' : ''}
        contacts lists
        {' '}
        {deleted ? '' : 'in your profile'}
        {' '}
        have been selected.
      </div>
      <div>Are you sure, you want to make an operation on your entire collection?</div>
      <Footer>
        <Button
          data-form="true"
          type="danger"
          size="large"
          onClick={handleSelectAll}
        >
          Yes, I&#39;m sure
        </Button>
        <Button
          size="large"
          type="default"
          onClick={() => dispatch(toggleModal('select_all_lists', false))}
        >
          Cancel
        </Button>
      </Footer>
    </Modal>
  );
};

export default SelectAllListsModal;
