import React, { useMemo } from 'react';
import moment from 'moment';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { COLORS } from 'constants/styles';
import { Button, Icon } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { modalsSelector } from 'data/selectors/modals';
import { updateSequenceDraftStatus } from 'data/actions/sequences';
import { SEQUENCE_DRAFT_STATUS } from 'data/types/sequences.types';
import { updateSequenceDraftStatusLoaderSelector } from 'data/selectors/loading';
import {
  dailyLimitToEnrollSelector,
  selectedSequenceSelector,
  sequenceAccountEmailSelector,
  sequenceActiveScheduleSelector,
  sequenceLeadsSelector,
} from 'data/selectors/sequences';
import {
  ModalTitle, CloseIcon, Footer,
} from '../baseModal/styles';
import {
  Container,
  NameSequence,
  Info,
  Variable,
} from './styles';

const LaunchSequenceModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);

  const email = useSelector(sequenceAccountEmailSelector);
  const currentSchedule = useSelector(sequenceActiveScheduleSelector);
  const dailyLimitToEnroll = useSelector(dailyLimitToEnrollSelector);
  const loading = useSelector(updateSequenceDraftStatusLoaderSelector);
  const { totalItems: totalLeads } = useSelector(sequenceLeadsSelector);
  const { _id: sequenceId, name, steps: { length: totalSteps } } = useSelector(selectedSequenceSelector);

  const starts = useMemo(() => {
    const [currentDay, currentTime] = moment().format('dddd$HH:mm').split('$');
    const { days } = currentSchedule;
    const currentDayIndex = days.findIndex(({ name: dayName }) => dayName === currentDay);
    if (days[currentDayIndex].active) {
      const { times } = days[currentDayIndex];
      const [hours, minutes] = currentTime.split(':');
      let findNow = false;
      const foundTime = times.find(({ from, to }) => {
        const [fromHours, fromMinutes] = from.split(':');
        const [toHours, toMinutes] = to.split(':');
        findNow = true;
        if (fromHours === hours && fromMinutes <= minutes) {
          return true;
        } if (toHours === hours && toMinutes >= minutes) {
          return true;
        } if (fromHours < hours && hours < toHours) {
          return true;
        } if (fromHours > hours) {
          findNow = false;
          return true;
        }
        findNow = false;
        return false;
      });
      if (foundTime) {
        return findNow ? 'Now' : `Today at ${foundTime.from}`;
      }
    } if (days[currentDayIndex + 1].active) {
      const [{ from }] = days[currentDayIndex + 1].times;
      return `Tomorrow at ${from}`;
    }
    let firstActiveDayIndex = days.findIndex(({ active }, index) => active && currentDayIndex < index);
    if (firstActiveDayIndex === -1) {
      firstActiveDayIndex = days.findIndex(({ active }) => active);
    }
    const firstActiveDay = days[firstActiveDayIndex];
    const [{ from }] = firstActiveDay.times;
    if (firstActiveDayIndex === 0 && currentDayIndex === days.length - 1) {
      return `Tomorrow at ${from}`;
    }
    return `on ${firstActiveDay.name} at ${from}`;
  }, [currentSchedule]);

  const handleClose = () => dispatch(toggleModal('launch_sequence', false));

  const handleLaunch = () => {
    dispatch(updateSequenceDraftStatus(SEQUENCE_DRAFT_STATUS.DONE, () => {
      handleClose();
      if (starts === 'Now') {
        setImmediate(() => {
          if (starts === 'Now') {
            history.push(`/sequences/${sequenceId}/leads`);
          }
        });
      } else {
        history.push('/sequences');
      }
    }));
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Confirm launch
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={420}
      footer={false}
      onCancel={handleClose}
    >
      <Container>
        <NameSequence>
          <span>Please confirm that you launch</span>
          &nbsp;
          “
          <Variable>{name}</Variable>
          ”
        </NameSequence>
        <Info>
          <Icon fill={COLORS.PRIMARY} size="smallest" outlined type="ic-forward-to-inbox" style={{ marginRight: '16px' }} />
          <span>
            Send from
          </span>
          &nbsp;
          <Variable>{email}</Variable>
        </Info>
        <Info>
          <Icon fill={COLORS.PRIMARY} size="smallest" type="ic-list" style={{ marginRight: '16px' }} />
          <span>
            Steps
          </span>
          &nbsp;
          <Variable>{totalSteps}</Variable>
        </Info>
        <Info>
          <Icon fill={COLORS.PRIMARY} size="smallest" outlined type="ic-people" style={{ marginRight: '16px' }} />
          <span>
            Sent to
          </span>
          &nbsp;
          <Variable>{`${totalLeads} recipients`}</Variable>
        </Info>
        <Info>
          <Icon fill={COLORS.PRIMARY} size="smallest" type="ic-timelapse" style={{ marginRight: '16px' }} />
          <span>
            Limits per day
          </span>
          &nbsp;
          <Variable>{dailyLimitToEnroll}</Variable>
        </Info>
        <Info>
          <Icon fill={COLORS.PRIMARY} size="smallest" outlined type="ic-not-started" style={{ marginRight: '16px' }} />
          <span>
            Starts
          </span>
          &nbsp;
          <Variable>{starts}</Variable>
        </Info>
      </Container>
      <Footer>
        <Button
          loading={loading}
          data-form="true"
          type="primary"
          size="large"
          onClick={handleLaunch}
        >
          Confirm
        </Button>
        <Button
          size="large"
          type="normal"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Footer>
    </Modal>
  );
};

export default LaunchSequenceModal;
