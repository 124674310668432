import get from 'lodash/get';
import { Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as CONSTANTS from 'constants/styles';

import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import useCheckProperty from 'hooks/useCheckProperty';
import { updateProperty } from 'data/actions/properties';
import { allGroupsSelector } from 'data/selectors/properties';
import { addGroupsToPreviewConfiguration } from 'data/actions/user';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';

import PropertyType from 'components/createPropertyBar/steps/propertyType';
import {
  ModalTitle,
  CloseIcon,
  HeaderStyle,
} from '../../modals/baseModal/styles';
import {
  ModalsBody,
  DrawerWrapper,
  Footer,
  BlueButton,
  WhiteButton,
} from '../../createPropertyBar/styles';
import fields from '../../createPropertyBar/propertyFields';
import BasicInformation from '../../createPropertyBar/steps/basicInformation';

const EditPropertyPreviewBar = () => {
  const dispatch = useDispatch();

  const groups = useSelector(allGroupsSelector);
  const { visible } = useSelector(modalsSelector);
  const {
    group,
    label,
    description,
    name,
    sourceType,
    fieldType,
    options,
    _id,
  } = useSelector(modalOptionsSelector);

  const [errors, setErrors] = useState({});
  const [propertyEnumerableData, setPropertyEnumerableData] = useState(options.length ? options.map((option, index) => ({ key: index, label: option.label, internalValue: option.value })) : [{ key: 0, label: '', internalValue: '' }]);

  const [property, setProperty] = useState({
    group: get(group, 'name'),
    label,
    description,
    name,
    sourceType,
    fieldType,
    options: propertyEnumerableData,
  });

  const existedProperty = useCheckProperty({ sourceType: property.sourceType, label: property.label });

  const isValid = fields.firstStep
    .filter((item) => item.required)
    .every((item) => property[item.name] && property[item.name].length > 0)
    && Object.keys(errors).length === 0;

  const handleUpdate = () => {
    const onSuccess = ({ settings }) => {
      if (settings) {
        dispatch(addGroupsToPreviewConfiguration(settings, property.sourceType));
      }
      dispatch(toggleMessage('success', { header: 'Property was updated!' }));
    };
    const onFailed = () => dispatch(toggleMessage('error', { header: 'Property was updated with error!' }));

    if (['select', 'checkbox'].includes(property.fieldType)) {
      property.options = propertyEnumerableData.map((option) => ({ label: option.label, value: option.internalValue }));
    } else delete property.options;

    dispatch(updateProperty({
      propertyId: _id,
      property,
      onSuccess,
      onFailed,
    }));
    dispatch(toggleModal('update_property', false));
  };

  useEffect(() => {
    if (existedProperty && property.label !== label) {
      setErrors((prev) => ({ ...prev, label: 'Property is existed' }));
    } else {
      setErrors((prev) => {
        delete prev.label;
        return { ...prev };
      });
    }
  }, [existedProperty, property.label, label]);

  return (
    <DrawerWrapper>
      <Drawer
        title={(
          <ModalTitle>
            Update property
            <CloseIcon
              data-close="true"
              onClick={() => dispatch(toggleModal('edit_property_preview', false))}
              className="ic_close"
            />
          </ModalTitle>
        )}
        placement="right"
        onClose={() => dispatch(toggleModal('edit_property_preview', false))}
        closable={false}
        bodyStyle={{
          padding: 0,
          maxHeight: 'calc(100% - 180px)',
          overflow: 'auto',
          flexDirection: 'column',
        }}
        headerStyle={HeaderStyle}
        visible={visible}
        width={CONSTANTS.DRAWER_WIDTH}
      >
        <ModalsBody>
          <BasicInformation
            errors={errors}
            sourceType={sourceType || null}
            property={property}
            updateProperty={setProperty}
            groups={groups}
            fields={fields.firstStep.filter((field) => field.name !== 'sourceType')}
          />
          <PropertyType
            property={property}
            updateProperty={setProperty}
            setPropertyEnumerableData={setPropertyEnumerableData}
            propertyEnumerableData={propertyEnumerableData}
          />
        </ModalsBody>
        <Footer>
          <WhiteButton
            type="normal"
            size="large"
            onClick={() => {
              dispatch(toggleModal('edit_property_preview', false));
            }}
          >
            Cancel
          </WhiteButton>
          <BlueButton
            data-form={!isValid ? 'false' : 'true'}
            type="primary"
            size="large"
            disabled={!isValid}
            onClick={handleUpdate}
          >
            Update
          </BlueButton>
        </Footer>
      </Drawer>
    </DrawerWrapper>
  );
};

export default EditPropertyPreviewBar;
