import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';

import { deleteApiKey } from 'data/actions/user';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import {
  ModalTitle, CloseIcon, Footer,
} from '../baseModal/styles';

const DeleteApiKey = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { applicationId } = useSelector(modalOptionsSelector);

  const handleClose = () => dispatch(toggleModal('delete_api_key', false));

  const handleDelete = () => {
    dispatch(deleteApiKey(applicationId));
    handleClose();
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Delete the api key
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        Are you sure you want to delete this api key?
      </div>
      <Footer>
        <Button
          type="danger"
          size="large"
          data-form="true"
          onClick={handleDelete}
        >
          Delete
        </Button>
        <Button
          size="large"
          type="default"
          onClick={handleClose}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default DeleteApiKey;
