import styled from 'styled-components';
import * as CONSTANTS from 'constants/styles';

export const InputInOneLineContainer = styled.div`
  display: flex;

  & > * {
    flex: 1;
  }
`;

export const FirstNameWrapper = styled.div`
  margin-right: 20px;
`;

export const MissedCallNumber = styled.span`
  min-width: 75px;
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const FormDivider = styled.div`
  margin: 15px 0;
  position: relative;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  text-align: center;
  color: ${CONSTANTS.COLORS.GREY_G2};
  
  :before, :after {
    content: '';
    width: calc(100% - 220px);
    height: 1px;
    background-color: ${CONSTANTS.COLORS.GREY_G4};
    display: block;
    position: absolute;
    top: 7px;
  }
  
  :after {
    right: 0;
  }
`;

export const FormDividerSignUp = styled(FormDivider)`
  margin: 15px 0;
`;

export const FormDataFooter = styled.div`

`;

export const FormDataItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const AlreadyAccountWrapper = styled.div`
  margin-bottom: 30px;
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${CONSTANTS.MACBOOK_AIR_MAX_WIDTH}px) {
    margin-bottom: 20px;
  }
`;

export const BtnGetStartedStyle = {
  width: '100%',
  margin: 0,
};
