import React from 'react';
import {
  Input,
} from 'antd';

const columns = (data, setData) => [
  {
    title: 'Label',
    key: 'label',
    dataIndex: 'label',
    render: (field, parentObject) => (
      <Input
        value={field || ''}
        placeholder="Enter label"
        onChange={(props) => {
          const changedData = [...data];
          const index = changedData.findIndex((element) => element.key === parentObject.key);
          changedData[index].label = props.target.value;
          changedData[index].internalValue = props.target.value;
          setData(changedData);
        }}
      />
    ),
  },
  {
    title: 'Internal Value',
    key: 'internalValue',
    dataIndex: 'internalValue',
    render: (field, parentObject) => (
      <Input
        value={field || ''}
        placeholder="Enter value"
        onChange={(props) => {
          const changedData = [...data];
          const index = changedData.findIndex((element) => element.key === parentObject.key);
          changedData[index].internalValue = props.target.value;
          setData(changedData);
        }}
      />
    ),
  },
];

export default columns;
