export const CONTACT_NAME = 'contact.name';
export const CONTACT_LOCATION = 'contact.location';
export const CONTACT_POSITION = 'company.position';
export const CONTACT_DEPARTMENTS = 'company.departments';
export const CONTACT_SENIORITY = 'company.seniority';
export const CONTACT_EMAIL = 'email';
export const CONTACT_UPDATED_AT = 'contact.updatedAt';

export const COMPANY_NAME = 'company.name';
export const COMPANY_DOMAIN = 'company.domain';
export const COMPANY_INDUSTRY = 'company.industry';
export const COMPANY_SIZE = 'company.size';
export const COMPANY_LOCATION = 'company.location';
export const COMPANY_UPDATED_AT = 'company.updatedAt';
export const COMPANY_HEADQUARTERS = 'company.headquarters';
export const COMPANY_TECHNOLOGIES = 'company.technologies';
export const COMPANY_KEYWORDS = 'company.keywords';
export const COMPANY_FOUNDED_AT = 'company.foundedAt';
export const COMPANY_TYPE = 'company.companyType';

export const KEYWORDS = 'keywords';

export const COUNT_OPTIONS_TYPES = Object.freeze({
  SINGLE: 'single',
  MULTIPLE: 'multiple',
});

export const SEARCH_FILTER_TYPES = Object.freeze({
  RADIO: 'radio',
  LIST: 'list',
  DATE_RANGE: 'date_range',
  TREE: 'tree',
  CHECKBOX: 'checkbox',
});

export const EMAIL_FILTER_STATUS = Object.freeze({
  ALL: 'all',
  VALID: 'valid',
  UNVERIFIABLE: 'unverifiable',
  ALL_CONTACTS: 'all_contacts',
});

export const CONTACT_SEARCH_FILTERS = Object.freeze([
  KEYWORDS,
  CONTACT_NAME,
  CONTACT_POSITION,
  CONTACT_SENIORITY,
  CONTACT_LOCATION,
  CONTACT_EMAIL,
  COMPANY_NAME,
  COMPANY_DOMAIN,
  COMPANY_INDUSTRY,
  COMPANY_SIZE,
  COMPANY_LOCATION,
  CONTACT_UPDATED_AT,
  COMPANY_UPDATED_AT,
  COMPANY_TECHNOLOGIES,
  COMPANY_KEYWORDS,
  COMPANY_FOUNDED_AT,
  COMPANY_TYPE,
  CONTACT_DEPARTMENTS,
]);

export const COMPANY_SEARCH_FILTERS = Object.freeze([
  KEYWORDS,
  COMPANY_NAME,
  COMPANY_DOMAIN,
  COMPANY_INDUSTRY,
  COMPANY_SIZE,
  COMPANY_LOCATION,
  COMPANY_UPDATED_AT,
  COMPANY_HEADQUARTERS,
  COMPANY_TECHNOLOGIES,
  COMPANY_KEYWORDS,
  COMPANY_FOUNDED_AT,
  COMPANY_TYPE,
]);

export const FILTER_SPLIT_LIMIT = 500;
export const SPLIT_TOKENS_REGEXP = /\r\n|\n|\r|;|,/;
export const FILTERS_MAX_COUNT = 50000;

export const SEARCH_TYPE = Object.freeze({
  RECENT: 'recent',
  SAVED: 'saved',
});
export const multipleValProps = ['contact.location', 'company.location', 'company.technologies', 'company.foundedAt'];
