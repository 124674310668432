import { COLORS } from 'constants/styles';
import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.BLACK_G1};

  ${(props) => (props.required && `
    &::after {
      content: ' *';
      color: ${COLORS.RED};
    }
  `)}

`;

export const Hint = styled.div`
  color: ${COLORS.GREY_G2};
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 12px;
`;
