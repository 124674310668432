import styled from 'styled-components';
import * as CONSTANTS from 'constants/styles';

export const Container = styled.div`
  height: 100%;
  margin-top: 10px;

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    display: none;
  };
`;

export const Label = styled.h1`
  color: #000;
  margin: 0;
  font-weight: 300;
`;

export const SelectContainer = styled.div`
  width: 290px;
  padding: 16px 20px;
  display: flex;
  flex-direction: row;
`;

export const SelectStyle = {
  width: '100%',
};

export const OptionStyle = {
  padding: '8px 20px',
};

export const IconInfoStyle = {
  margin: '12px 0 0 8px',
  color: '#1890ff',
  fontSize: '15px',
};

export const AddFilter = styled.button`
  background: none;
  border: none;
  color: #0091ae;
  cursor: pointer;
  display: flex;
  padding: 10px 7px;
  align-items: center;
  font-weight: 500;

  font-family: AvenirNext;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #1890ff;

  span {
    margin-left: 5px;
    line-height: 1;
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }

  &:focus {
    outline: 0;
  }
`;

export const Back = styled.div`
  color: #1890ff;
  cursor: pointer;
  display: flex;
  padding: 10px 7px;
  font-weight: 500;
  margin: 10px 0 0 14px;

  i {
    margin-top: 2px;
  }

  span {
    margin-left: 5px;
    line-height: 1;
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;

export const AllSavedFilters = styled.button`
  background: none;
  border: none;
  color: #0091ae;
  cursor: pointer;
  display: flex;
  padding: 10px 7px;
  align-items: center;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 0;
  }
`;

export const GroupName = styled.span`
  font-weight: 600;
  padding: 10px 7px;
  color: rgb(51, 71, 91);
  font-size: 14px;
  display: block;
`;

export const GroupTitle = styled.span`
  font-weight: 600;
  padding: 5px 23px;
  color: rgb(51, 71, 91);
  font-size: 16px;
  display: block;

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    margin: 15px 0;
    padding: 0;
  };
`;

export const PropertiesGroup = styled.div`
  margin-top: 10px;
  padding: 0px 16px;

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    padding: 0;
  };
`;

export const PropertyItem = styled.div` 
  text-align: left;
  padding: 8px 7px;
  box-sizing: border-box;
  cursor: pointer;
  color: rgb(51, 71, 91);
  background-color: ${(props) => (props.disabled ? 'rgb(234, 240, 246) !important' : 'none')};
  color: ${(props) => (props.disabled ? 'rgb(176, 193, 212) !important' : 'rgb(51, 71, 91)')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
    
  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: rgb(234, 240, 246);
    border-radius: 3px;
  }

  transition: all .15s ease-out;
`;

export const FilterEditorContainer = styled.div`
  padding: 0px 7px;

  .ant-radio-group {
    display: flex;
    flex-direction: column;
  }

  .ant-radio-wrapper {
    margin: 5px 0;  
    font-family: 'AvenirNext';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.25px;
    color: #4f5964;
  }

  .ant-radio-inner {
    width: 20px;
    height: 20px;
    border-width: 2px;

    &:after {
      width: 10px;
      height: 10px;
    }
  }
`;

export const ControlGroup = styled.div`
  display: flex;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid rgb(234, 240, 246);

  button {
    margin-right: 10px;
  }
`;

export const CloseIcon = { fontSize: 12 };

export const BackIcon = { fontSize: 12 };

export const ApplyFilterButton = {
  marginTop: 20,
  maxWidth: '100px',
};

export const AddFilterButton = {
  margin: '5px 14px',
  width: '92px',
};

export const CurrentListContainer = styled.div`
  margin: 20px 0 8px 0;
  padding-left: 6px;
`;

export const CurrentListTitle = styled.div`
  font-weight: 600;
`;

export const CurrentListDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ContactsCount = styled.small`
  margin-top: 6px;
  color: #516f90;
`;

export const SearchForm = {
  width: '100%',
  background: '#f5f8fa',
};

export const SearchFormContainer = styled.div`
  margin-top: 8px;
  padding: 0 22px;

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    padding: 0;
  };
`;

export const ListType = styled.span`
  font-weight: 400;
  font-size: 24px;
  width: 360px;
`;

export const FiltersContainer = styled.div`
  padding: 14px;
  margin: 10px;
  min-height: 110px;
  border-radius: 1px;
  border: solid 1px #dfe2e5;
  background-color: #ffffff;
`;

export const StaticFilterTitle = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: 3px;
  border: 1px solid #7fd1de;
  padding: 8px 50px 8px 20px;
  margin: 10px;
`;

export const FilterItem = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: 3px;
  border: 1px solid rgb(223, 227, 235);
  padding: 14px;
  position: relative;
  font-size: 12px;

  img {
    display: none;
    position: absolute;
    right: 12px;
  }

  &:hover {
    img {
      display: inline-block;
    }

    background-color: rgba(24, 144, 255, 0.1);
    cursor: pointer;
    border: solid 1px #dfe2e5;
  }
`;

export const FilterName = styled.span`
  margin-right: 5px;
  height: 20px;
  font-family: 'AvenirNext';
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #1890ff;
`;

export const FilterOperator = styled.span`
  margin-right: 5px;
  font-family: 'AvenirNext';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #4f5964;
`;

export const FilterHeaderOption = styled.div`
  padding: 0px 5px;
  font-family: 'AvenirNext';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: right;
  color: #1890ff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
  i {
    margin: 0 5px;
  }
`;

export const FilterHeaderContainer = styled.div`
  padding: 0 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px 0;
`;
