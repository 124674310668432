import React from 'react';

import Loader from 'components/loading';

const WhiteLoading = ({ height, width }) => (
  <Loader loading size="large">
    <div style={{ backgroundColor: 'white', height, width }} />
  </Loader>
);

export default WhiteLoading;
