import debounce from 'lodash/debounce';
import { useEffect, useState, useCallback } from 'react';

const useInputMutex = ({
  defaultValue = null,
  callback,
  minSearchValueLength,
  maxRequestRate,
  compareLast = true,
}) => {
  const [value, setValue] = useState(defaultValue || '');
  const [lastValue, setLastValue] = useState('');

  const debounceCallback = useCallback(debounce((data) => {
    if (callback) callback(data);
  }, maxRequestRate), [callback]);

  useEffect(() => {
    let isSearch = (value.length >= minSearchValueLength || value.length === 0)
      && value !== defaultValue;

    if (compareLast) {
      isSearch = value !== lastValue;
      if (isSearch) setLastValue(value);
    }

    if (isSearch) {
      debounceCallback(value);
    }
  }, [value, defaultValue, minSearchValueLength, maxRequestRate, debounceCallback, lastValue, compareLast]);

  return [value, setValue];
};

export default useInputMutex;
