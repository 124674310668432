import React from 'react';
import { Flex, Tag } from '@newlab-io/getprospect-react-components/lib/ui';

import { Checkbox, Icon } from 'components/common';
import {
  HeaderLabel,
} from '../../baseModal/styles';
import { Container, Hint } from './styles';

const Connection = ({
  name,
  hints,
  selected,
  onSelect,
  recommended,
}) => (
  <Container onClick={onSelect} selected={selected}>
    <Flex style={{ marginBottom: 15 }} justify="space-between" align="center">
      <HeaderLabel>{name}</HeaderLabel>
      <Flex align="center" gap={15}>
        {
          recommended && (
            <Tag kind="primary">Recommended</Tag>
          )
        }
        <Checkbox checked={selected} />
      </Flex>
    </Flex>
    <Flex direction="column" gap={5}>
      {
        hints.map((hint) => (
          <Flex key={hint.name} gap={5} align="center">
            <Icon fill={recommended ? 'green' : 'yellow'} size={16} type={recommended ? 'ic-check' : 'ic-error'} />
            <Hint>
              {hint.name}
            </Hint>
          </Flex>
        ))
      }
    </Flex>
  </Container>
);

export default Connection;
