import React, { useState } from 'react';
import { CONTACT_ICON_COLORS } from 'constants/styles';
import { Icon, Text, Image } from './styles';

const CompanyIcon = ({
  name,
  code,
  domain,
  size,
  borderRadius,
  textSize,
  loadBorderRadius,
  marginRight,
  style,
  className,
  onClick = () => {},
  animated = true,
}) => {
  const length = CONTACT_ICON_COLORS.length - 1;
  const colorIndex = code ? code.split('').reduce((accumulator, item) => accumulator + item.charCodeAt(), 0) % length : 0;
  const [showText, toggleShowText] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [onLoadBorderRadius, setOnLoadBorderRadius] = useState(null);
  const { backgroundColor, color } = CONTACT_ICON_COLORS[colorIndex];

  return (
    <Icon
      className={className}
      style={style}
      onClick={onClick}
      backgroundColor={(isLoading || !showText) ? '#ffffff' : backgroundColor}
      size={size || '32px'}
      marginRight={marginRight || '10px'}
      borderRadius={onLoadBorderRadius ?? borderRadius ?? '6px'}
    >
      <Image
        animated={animated}
        xlinkHref={`//logo.clearbit.com/${domain}?size=${size || '64'}`}
        onLoad={(e) => {
          e.target.style.display = 'block';
          toggleShowText(false);
          if (loadBorderRadius) setOnLoadBorderRadius(loadBorderRadius);
          setIsLoading(false);
        }}
        onError={(e) => {
          e.target.style.display = 'none';
          toggleShowText(true);
          if (loadBorderRadius) setOnLoadBorderRadius(loadBorderRadius);
          setIsLoading(false);
        }}
        size={size || '32px'}
        onAnimationEnd={(e) => e.stopPropagation()}
      />
      {showText && (
        <Text
          x="50%"
          y="55%"
          translate="no"
          fill={color}
          dominantBaseline="middle"
          textAnchor="middle"
          textSize={textSize || '12px'}
        >
          {isLoading ? '' : name}
        </Text>
      )}
    </Icon>
  );
};
export default CompanyIcon;
