import React, { Fragment } from 'react';

import { ReactComponent as IconEdit } from '@material-design-icons/svg/filled/edit.svg';

import { getSignature } from 'helpers/sequences/signature';
import { Container, SignatureContainer } from './styles';

const SignatureMail = ({ account, onlyChildren }) => {
  const html = getSignature(account);

  const ConditionalContainer = onlyChildren ? Fragment : Container;

  return (
    <ConditionalContainer title="Edit signature" contentEditable="false" className="signature-container">
      <SignatureContainer className="signature-container__content" dangerouslySetInnerHTML={{ __html: html }} />
      <IconEdit className="signature-icon" />
    </ConditionalContainer>
  );
};

export default SignatureMail;
