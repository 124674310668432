import styled from 'styled-components';

export const Footer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #f5f8fa;
  border-top: 1px solid #cbd6e2;
  padding: 20px;
`;

export const ButtonStyle = {
  fontWeight: '600',
  marginRight: 10,
  width: 'auto',
};
