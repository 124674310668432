const initialState = {
  message: '',
  uninstallReason: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SEND_UNINSTALL_REASON': {
      return {
        ...state,
        ...action.data,
      };
    }
    default: return state;
  }
};
