import React, { forwardRef, useState } from 'react';

import {
  Label, ValidationStatus, CustomInput as Input, InputContainer,
} from 'components/commonStyles';

const CustomInput = forwardRef(({
  value = '',
  placeholder = '',
  name = '',
  type = 'text',
  size = 'large',
  onChange = () => {},
  onBlur = () => {},
  suffix = '',
  label = '',
  error = '',
  success = '',
  autoFocus = false,
  inputStyle = {},
  disabled = false,
  maxLength,
  onPressEnter = () => {},
  addonBefore = '',
  autocomplete = '',
  addonAfter = '',
  dataTour = '',
  InputField = Input,
  style = {},
  LabelComponent = Label,
  relativeStatus = false,
  onFocus = () => {},
  renderSuffixIfHovered,
  renderSuffixIfFocused,
  onClick,
}, ref) => {
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(autoFocus);

  const onBlurProxy = (e) => {
    setFocus(false);
    onBlur(e);
  };

  const onFocusProxy = (e) => {
    setFocus(true);
    onFocus(e);
  };

  const onMouseEnterProxy = () => {
    setHover(true);
  };

  const onMouseLeaveProxy = () => {
    setHover(false);
  };

  const getSuffix = () => {
    if (renderSuffixIfFocused || renderSuffixIfHovered) {
      if (hover || focus) {
        return suffix;
      }
      return <></>;
    }
    return suffix;
  };

  return (
    <InputContainer
      style={style}
      onMouseEnter={onMouseEnterProxy}
      onMouseLeave={onMouseLeaveProxy}
    >
      {label && <LabelComponent>{label}</LabelComponent>}
      <InputField
        onClick={onClick}
        ref={ref}
        type={type}
        suffix={getSuffix()}
        maxLength={maxLength}
        name={name}
        placeholder={placeholder}
        value={value}
        size={size}
        error={error}
        success={success}
        addonAfter={addonAfter}
        addonBefore={addonBefore}
        onPressEnter={onPressEnter}
        onChange={onChange}
        onBlur={onBlurProxy}
        style={{
          ...inputStyle,
        }}
        autoFocus={autoFocus}
        disabled={disabled}
        autoComplete={autocomplete}
        onFocus={onFocusProxy}
        data-tour={dataTour}
      />
      <ValidationStatus style={{ position: relativeStatus ? 'relative' : 'absolute' }} error={error} success={success}>
        {error || success}
      </ValidationStatus>
    </InputContainer>
  );
});

export default CustomInput;
