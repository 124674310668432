import styled from 'styled-components';

export const Container = styled.div`
  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;
    }
  }
`;
