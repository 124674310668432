import React from 'react';

import { Container, Circle, Text } from './styles';

const StatusLabel = ({ status, children }) => (
  <Container>
    <Circle status={status} />
    <Text error={status === 'error'}>{children}</Text>
  </Container>
);

export default StatusLabel;
