import { Modal, Tooltip } from 'antd';
import React, { useState } from 'react';

import useWorkTime from 'hooks/useWorkTime';
import { Button } from 'components/common';
import { SHARED_INFORMATION } from 'data/types/subscription.types';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../../../../modals/baseModal/styles';

const Connect = ({
  title = 'Share your feedback with us',
  description,
  onClose,
  onNext,
}) => {
  const [sharedInformation] = useState(SHARED_INFORMATION.CHAT);

  const {
    areWorkersAvailable,
    localWorksFrom,
    localWorksUntil,
    localTimeZone,
  } = useWorkTime();

  return (
    <Modal
      visible
      title={(
        <ModalTitle>
          {title}
          <CloseIcon
            onClick={onClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={onClose}
    >
      <div>
        {/* eslint-disable-next-line */}
        <div style={{ marginBottom: 20 }} dangerouslySetInnerHTML={{ __html: description }} />
        <Tooltip title={!areWorkersAvailable ? `You can't chat now, because we work from ${localWorksFrom} until ${localWorksUntil} daily by your time zone ${localTimeZone}. Try later or choose another option` : ''}>
          Click
          <strong> Next </strong>
          to chat with our support team.
        </Tooltip>
        <Footer>
          <Button
            data-form="true"
            type="normal"
            onClick={() => onNext({ sharedInformation: null })}
          >
            No, thanks
          </Button>
          <Button
            data-form="true"
            type="primary"
            disabled={!sharedInformation}
            onClick={() => onNext({ sharedInformation })}
          >
            Next
          </Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default Connect;
