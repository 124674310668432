import { COLORS } from 'constants/styles';
import styled from 'styled-components';

export const OrderedList = styled.ol`
  padding-left: 15px;
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.GREY_G1};

  li {
    margin-bottom: 10px;

    b {
      color: ${COLORS.BLACK_G1};
    }
  }
`;

export const HowLink = styled.a`
  cursor: pointer;
  color: ${COLORS.GREY_G2};
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;

  &:hover {
    span {
      color: ${COLORS.BLUE_B1} !important;
    }
  }
`;
