import get from 'lodash/get';
import axios from 'axios';
import jwt from 'jsonwebtoken';

import { getFingerPrint } from './getFingerPrint';

const getAccessToken = async (refreshTokenEvent) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const currentDate = Math.round(new Date() / 1000);

    const decodedToken = jwt.decode(accessToken);
    const tokenExpDate = get(decodedToken, 'exp');
    const workspaceId = get(decodedToken, 'workspaceId');

    if (tokenExpDate && currentDate < (tokenExpDate - 10)) {
      return localStorage.getItem('accessToken');
    }

    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) return false;

    localStorage.removeItem('refreshToken');
    localStorage.setItem('refreshTokenProcessing', true);

    const fingerPrint = await getFingerPrint();
    const apiHost = process.env.REACT_APP_API_URL;

    const res = await axios({
      url: `${apiHost}/auth/refresh-tokens`,
      method: 'POST',
      data: {
        refreshToken,
        fingerPrint,
        workspaceId,
      },
    });

    localStorage.setItem('accessToken', get(res, 'data.accessToken'));
    localStorage.setItem('refreshToken', get(res, 'data.refreshToken'));
    localStorage.removeItem('refreshTokenProcessing');
    window.dispatchEvent(refreshTokenEvent);
    return get(res, 'data.accessToken');
  } catch (err) {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshTokenProcessing');
    localStorage.removeItem('closeChromeBlackFridayBanner');
    window.dispatchEvent(refreshTokenEvent);
    return false;
  }
};

export default getAccessToken;
