import get from 'lodash/get';
import { Drawer } from 'antd';
import debounce from 'lodash/debounce';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { CompanySidebar } from '@newlab-io/getprospect-react-components/lib/pages';

import useFetch from 'hooks/useFetch';
import { createNote } from 'data/actions/notes';
import * as CONSTANTS from 'constants/styles';
import { getRelevantLinkedinUrl } from 'helpers/url';
import { toggleMessage } from 'data/actions/message';
import { SOURCE_TYPE } from 'data/types/source.types';
import { getEntityHistory } from 'data/actions/common';
import { generateContactFilters } from 'helpers/filterFactory';
import { closeAnyModal, toggleModal } from 'data/actions/modals';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { generateContactFilters as generateInsightFilters } from 'helpers/insightHelpers';
import { companySidebarLoadingSelector, getCompanyKeywordsLoadingSelector } from 'data/selectors/loading';
import {
  getCompanyById, getCompanyCompetitors,
  getCompanyKeywords, getCurrentCompanyTechnologies,
  saveCompanyFromInsights,
  searchCompanyEmployers,
  updateCompany,
} from 'data/actions/companies';
import Loader from 'components/loading';
import { getInsightById } from 'data/actions/search';
import useSubscribeActions from 'hooks/useSubscribeActions';
import {
  companyKeywordsSelector,
  currentCompanySidebarCompetitorsSelector,
  currentCompanySidebarEmployeesSelector,
  currentCompanySidebarHistorySelector,
  currentCompanySidebarSavedContactsSelector,
  currentCompanySidebarSelector,
  currentCompanySidebarTechnologiesSelector,
  // inaccurateDomainsSelector,
} from 'data/selectors/companies';
import {
  PreviewLoader,
} from '../styles';

const NewCompanyPreviewBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const company = useSelector(currentCompanySidebarSelector);
  const activities = useSelector(currentCompanySidebarHistorySelector);
  // const inaccurateDomains = useSelector(inaccurateDomainsSelector);
  const {
    data: allContacts = [],
  } = useSelector(currentCompanySidebarEmployeesSelector);
  const {
    data: savedContacts = [],
  } = useSelector(currentCompanySidebarSavedContactsSelector);
  const competitors = useSelector(currentCompanySidebarCompetitorsSelector);
  const technologies = useSelector(currentCompanySidebarTechnologiesSelector);
  const { visible } = useSelector(modalsSelector);
  const {
    isInsight: defaultIsInsight,
    companyId: defaultCompanyId,
    insightId,
    defaultNavigation = 'company',
    defaultContactsTab = 'allContacts',
    defaultInsightsTab = 'technologies',
    backHistory = [],
  } = useSelector(modalOptionsSelector);

  const loading = useSelector(companySidebarLoadingSelector);

  const [isInsight, setIsInsight] = useState(defaultIsInsight);
  const [companyId, setCompanyId] = useState(defaultCompanyId);
  const [localLoading, setLocalLoading] = useState(true);

  const [navigation, setNavigation] = useState(defaultNavigation);
  const [contactsTab, setContactsTab] = useState(defaultContactsTab);
  const [insightsTab, setInsightsTab] = useState(defaultInsightsTab);

  const companyForSidebar = useMemo(() => {
    const keywords = get(company, 'properties.keywords.value');
    const domain = get(company, 'properties.domain.value', '');
    const companyName = get(company, 'properties.name.value', '');
    const social = get(company, 'properties.social.value', {});
    const location = get(company, 'properties.location.value', '');
    const postalCode = get(company, 'properties.postalCode.value', '');
    const type = get(company, 'properties.companyType.value', '');
    const foundedAt = get(company, 'properties.foundedAt.value', '');
    const description = get(company, 'properties.description.value', '');
    const headquarters = get(company, 'properties.headquarters.value', '');
    const industry = get(company, 'properties.industry.value', '');
    const size = get(company, 'properties.size.value', '');
    let linkedin = get(company, 'properties.linkedin.value', '');

    if (Array.isArray(linkedin)) {
      linkedin = getRelevantLinkedinUrl(linkedin, SOURCE_TYPE.COMPANY);
    }

    return {
      _id: companyId,
      name: companyName,
      domain,
      location,
      keywords,
      postalCode,
      type,
      description,
      foundedAt,
      industry: typeof industry === 'object' ? get(industry, 'en_US', '') : industry,
      size,
      linkedin: company.linkedin,
      headquarters,
      saved: !isInsight,
      saving: company.isSaving,
      competitors: competitors.map((competitor) => ({
        ...competitor,
        industry: get(competitor, 'industry.en_US', ''),
      })),
      social: {
        ...social,
        linkedin: social?.linkedin || linkedin,
      },
      activities,
      technologies,
      allContacts: (allContacts || []).map((contact) => ({
        _id: contact._id || contact.id,
        firstName: get(contact, 'properties.firstName.value', ''),
        lastName: get(contact, 'properties.lastName.value', ''),
        location: get(contact, 'properties.location.value', ''),
        companyRelations: contact.companyRelations,
        saved: contact.saved,
        savedWorkspaceId: contact.savedWorkspaceId,
      })),
      savedContacts: (savedContacts || []).map((contact) => ({
        _id: contact._id,
        firstName: get(contact, 'properties.firstName.value', ''),
        lastName: get(contact, 'properties.lastName.value', ''),
        location: get(contact, 'properties.location.value', ''),
        companyRelations: contact.companyRelations,
      })),
    };
  }, [companyId, company, isInsight, competitors, allContacts, savedContacts, activities, technologies]);

  const handleSearchCompany = useCallback(debounce((searchValue, saved) => {
    if (saved) {
      dispatch(searchCompanyEmployers({
        pageSize: 100,
        filters: generateContactFilters({ companyId }),
        search: { properties: ['firstName', 'lastName', 'email'], value: searchValue },
        saved: true,
        isSidebar: true,
      }));
    } else {
      const domain = get(company, 'properties.domain.value', '');
      if (domain) {
        dispatch(searchCompanyEmployers({
          pageSize: 100,
          filters: generateInsightFilters({ domain, search: searchValue }),
          isSidebar: true,
        }));
      }
    }
  }, 1000), [dispatch, company, companyId]);

  const handleClose = () => dispatch(toggleModal('company_preview', false));
  const handleShowCompany = () => {
    history.push(`/companies/${companyId}`);
    dispatch(closeAnyModal());
  };

  const handleSaveCompany = () => {
    dispatch(saveCompanyFromInsights(insightId));
  };

  const handleBack = () => {
    const lastPage = backHistory.pop();
    if (lastPage.source === SOURCE_TYPE.COMPANY) {
      if (lastPage.options.navigation) {
        setNavigation(lastPage.options.navigation);
      } if (lastPage.options.contactsTab) {
        setContactsTab(lastPage.options.contactsTab);
      } if (lastPage.options.insightsTab) {
        setInsightsTab(lastPage.options.insightsTab);
      }
    }
    dispatch(toggleModal(`${lastPage.source}_preview`, true, {
      ...lastPage.options,
      defaultNavigation: lastPage.options.navigation,
      defaultContactsTab: lastPage.options.contactsTab,
      defaultInsightsTab: lastPage.options.insightsTab,
      backHistory,
    }));
  };

  const {
    data: foundKeywords,
    refresh: handleSearchKeywords,
  } = useFetch({
    selector: companyKeywordsSelector,
    action: getCompanyKeywords,
    loadingSelector: getCompanyKeywordsLoadingSelector,
  });

  useEffect(() => {
    setLocalLoading(true);
    const onSuccess = (foundCompany) => {
      const _domain = foundCompany.properties?.domain?.value;
      if (_domain) {
        dispatch(getCompanyCompetitors({ domain: _domain, isSidebar: true }));
        dispatch(searchCompanyEmployers({ pageSize: 100, filters: generateInsightFilters({ domain: _domain }), isSidebar: true }));
      }
      dispatch(searchCompanyEmployers({
        pageSize: 100,
        filters: generateContactFilters({ companyId: foundCompany._id }),
        saved: true,
        isSidebar: true,
      }));
      const { value } = foundCompany.properties?.technologies ?? [];
      if (value?.length) {
        dispatch(getCurrentCompanyTechnologies({ technologyIds: value, isSidebar: true }));
      }
      dispatch(getEntityHistory({
        source: SOURCE_TYPE.COMPANY,
        entityId: foundCompany._id,
        onSuccess: () => setLocalLoading(false),
        onFailed: () => setLocalLoading(false),
        isSidebar: true,
      }));
      setLocalLoading(false);
    };
    const onFailed = () => {
      dispatch(toggleMessage('error', { text: 'The company is not found' }));
      history.push('/companies');
      setLocalLoading(false);
    };
    if (defaultCompanyId) {
      dispatch(getCompanyById({
        companyId: defaultCompanyId,
        isSidebar: true,
        onSuccess,
        onFailed,
      }));
    } else if (insightId) {
      dispatch(getInsightById({
        insightId,
        sourceType: SOURCE_TYPE.COMPANY,
        components: ['companySidebarLoading'],
        onSuccess: (foundCompany) => {
          if (foundCompany) {
            const _domain = foundCompany.domain;
            if (_domain) {
              dispatch(getCompanyCompetitors({ domain: _domain, isSidebar: true }));
              dispatch(searchCompanyEmployers({ pageSize: 100, filters: generateInsightFilters({ domain: _domain }), isSidebar: true }));
            }
            if (foundCompany.technologies) {
              dispatch(getCurrentCompanyTechnologies({ technologyIds: foundCompany.technologies, isSidebar: true }));
            }
          }
          setLocalLoading(false);
        },
        onFailed: () => setLocalLoading(false),
      }));
    }
  }, [dispatch, history, defaultCompanyId, insightId, defaultIsInsight]);

  useEffect(() => {
    setCompanyId(defaultCompanyId);
  }, [defaultCompanyId]);

  useEffect(() => {
    setIsInsight(defaultIsInsight);
    if (insightId && defaultIsInsight) {
      setCompanyId();
    }
  }, [insightId, defaultIsInsight]);

  useSubscribeActions({
    actions: ['SAVE_COMPANY_FROM_INSIGHTS'],
    callback: ({ insightId: savedInsightId, payload: { _id: newCompanyId } }) => {
      if (savedInsightId === insightId) {
        setIsInsight(false);
        setCompanyId(newCompanyId);
      }
    },
  }, [insightId]);

  return (
    <Drawer
      className="ant-drawer-default"
      placement="right"
      mask={false}
      closable={false}
      visible={visible}
      bodyStyle={{ padding: 0, overflow: 'hidden' }}
      width={CONSTANTS.DRAWER_WIDTH}
    >
      {
        (loading || localLoading) ? <PreviewLoader><Loader size="large" loading /></PreviewLoader>
          : (
            <CompanySidebar
              navigation={navigation}
              setNavigation={setNavigation}
              contactsTab={contactsTab}
              setContactsTab={setContactsTab}
              insightsTab={insightsTab}
              setInsightsTab={setInsightsTab}
              company={companyForSidebar}
              suggestedKeywords={foundKeywords.map((keyword) => keyword.name)}
              onClose={handleClose}
              onSearchKeyword={handleSearchKeywords}
              onCreateNote={(text) => dispatch(createNote({
                text,
                source: SOURCE_TYPE.COMPANY,
                entityId: companyId,
                isSidebar: true,
              }))}
              onView={handleShowCompany}
              onBack={backHistory.length ? handleBack : undefined}
              onSearchCompany={handleSearchCompany}
              onClickCompany={(_companyId) => {
                const foundCompetitor = competitors.find((item) => item._id === _companyId);
                if (foundCompetitor) {
                  backHistory.push({
                    options: {
                      isInsight,
                      companyId,
                      insightId,
                      navigation,
                      contactsTab,
                      insightsTab,
                    },
                    source: SOURCE_TYPE.COMPANY,
                  });
                  setNavigation('company');
                  setContactsTab('allContacts');
                  setInsightsTab('technologies');
                  dispatch(toggleModal('company_preview', true, {
                    companyId: foundCompetitor.saved ? foundCompetitor.savedWorkspaceId : undefined,
                    isInsight: !foundCompetitor.saved,
                    insightId: !foundCompetitor.saved ? _companyId : undefined,
                    backHistory,
                  }));
                }
              }}
              onClickContact={(_contactId, saved) => {
                backHistory.push({
                  options: {
                    isInsight,
                    companyId,
                    insightId,
                    navigation,
                    contactsTab,
                    insightsTab,
                  },
                  source: SOURCE_TYPE.COMPANY,
                });
                let updatedContactId = _contactId;
                let updatedSaved = saved;
                if (!saved) {
                  const foundContact = companyForSidebar.allContacts.find((contact) => contact._id === _contactId);
                  if (foundContact?.savedWorkspaceId) {
                    updatedContactId = foundContact.savedWorkspaceId;
                    updatedSaved = true;
                  }
                }
                dispatch(toggleModal('contact_preview', true, {
                  contactId: updatedSaved ? updatedContactId : undefined,
                  insightId: !updatedSaved ? updatedContactId : undefined,
                  isInsight: !updatedSaved,
                  backHistory,
                }));
              }}
              onUpdate={(formCompany) => {
                dispatch(updateCompany(
                  companyId,
                  Object.keys(formCompany).map((key) => ({
                    property: key === 'type' ? 'companyType' : key,
                    value: formCompany[key],
                  })),
                ));
              }}
              onSave={handleSaveCompany}
              onDelete={() => {
                dispatch(toggleModal('delete_companies', true, {
                  companyId,
                  name: companyForSidebar.name,
                  onClickYes: () => history.push('/companies'),
                }));
              }}
            />
          )
      }
    </Drawer>
  );
};

export default NewCompanyPreviewBar;
