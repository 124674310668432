export const SEARCH_CONTENT = Object.freeze({
  RECENT_ACTIVITY: 'recent_activity',
  SAVED_FILTERS: 'saved_filters',
});

export const SEARCH_KIND = Object.freeze({
  SEARCH: 'search',
  SEARCH_HISTORY: 'search_history',
  USER_SEARCH: 'user_search',
});
