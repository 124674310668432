import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const Container = styled.div`
  height: 339px;

  .ant-fullcalendar {
    border-top: none;
  }

  .ant-fullcalendar-column-header-inner {
    font-size: 12px;
    font-weight: 500;
    color: ${COLORS.GREY_G2};
  }

  .ant-fullcalendar-calendar-body {
    padding: 8px 0px;
  }

  .ant-fullcalendar td {
    height: 20px;

  }
`;

export const CalendarContainer = styled.div`
  display: flex;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  margin-left: -16px;
  margin-right: -16px;
  background-color: ${COLORS.GREY_G5};
`;

export const InputView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  min-width: 160px;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
  border: 1px solid ${COLORS.GREY_G4};

  &:focus, &:hover {
    border: 1px solid ${COLORS.PRIMARY};
  }
`;

export const Value = styled.span`
  font-size: 15px;
  color: ${COLORS.BLACK_G1};
  margin-right: 10px;
  white-space: nowrap;
`;

export const DifferenceLabel = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: ${COLORS.GREY_G2};
`;

export const DifferenceValue = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: ${COLORS.BLACK_G1};
`;

export const Cell = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  width: auto;
  margin: 0 5px;
  padding: 2.5px;
  color: ${COLORS.BLACK_G1};
  border-radius: 4px;
  background-color: ${COLORS.WHITE};

  &:hover {
    color: ${COLORS.PRIMARY};
  }
`;

export const ActiveCell = styled(Cell)`
  color: ${COLORS.WHITE};
  background-color: ${COLORS.PRIMARY};
  margin: 0 5px;

  ${(props) => (props.first && `
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `)}

  ${(props) => (props.last && `
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  `)}

  ${(props) => (props.firstLong && `
    margin-right: -1px;
  `)}

  ${(props) => (props.lastLong && `
    margin-left: -1px;
  `)}

  &:hover {
    color: ${COLORS.WHITE};
  }
`;

export const BetweenCell = styled(Cell)`
  color: ${COLORS.GREY_G1};
  background-color: ${COLORS.GREY_G14};
  border-radius: 0;
  margin: 0;
  margin-left: -2px;

  ${(props) => (props.first && `
    margin-left: -7px;
  `)}

  ${(props) => (props.last && `
    margin-right: -7px;
  `)}

  &:hover {
    color: ${COLORS.GREY_G1};
  }
`;

export const DisabledCell = styled(Cell)`
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  margin: 0;
  margin-left: -2px;
  color: ${COLORS.GREY_G3};

  &:hover {
    color: ${COLORS.GREY_G3};
  }
`;

export const MonthContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const MonthLabel = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: ${COLORS.BLACK_G1};
`;

export const LastDaysContainer = styled.div`
  padding-left: 20px;
  padding-right: 15px;
  height: 285px;
  margin-left: 20px;
  border-left: 1px solid ${COLORS.GREY_G14};
`;

export const LastDaysHeader = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: ${COLORS.GREY_G2};
  margin-bottom: 15px;
  margin-top: 2px;
`;

export const LastDays = styled.div`
  display: flex;
  align-items: center;
  height: 27px;
  width: 110px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  padding-left: 10px;
  color: ${COLORS.BLACK_G1};
  margin-top: 10px;

  &:hover {
    color: ${COLORS.PRIMARY};
    background-color: ${COLORS.GREY_G5};
  }
`;
