import isArray from 'lodash/isArray';

export const updateLoading = (component, loading) => (dispatch) => {
  dispatch({
    type: 'UPDATE_LOADING_COMPONENT',
    data: {
      components: isArray(component) ? component : [component],
      loading,
    },
  });
};

export const updateForceLoading = (source, loading) => (dispatch) => {
  dispatch({
    type: 'UPDATE_FORCE_LOADING_NO_RESULT',
    data: {
      source,
      loading,
      components: ['listContactsTable', 'contactsListTable', 'searchContacts'],
    },
  });
};
