import React from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Flex, Label } from '@newlab-io/getprospect-react-components/lib/ui';

import { COLORS } from 'constants/styles';
import { toggleModal } from 'data/actions/modals';
import { Border, Button, Icon } from 'components/common';
import { selectedSequenceIdSelector } from 'data/selectors/sequences';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import {
  ModalTitle,
  HeaderLabel,
  SubHeaderLabel,
  CloseIcon,
  Footer,
} from '../baseModal/styles';
import { HowLink, OrderedList } from './styles';

const ImapInfoModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const { sequenceId: sequenceIdOption } = useSelector(modalOptionsSelector);

  const selectedSequenceId = useSelector(selectedSequenceIdSelector);

  const sequenceId = sequenceIdOption || selectedSequenceId;

  const handleClose = () => dispatch(toggleModal('imap_info', false));
  const handleAddAccount = () => dispatch(toggleModal('add_email_account', true, { sequenceId }));

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Connect Your Google Account
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={420}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        <HeaderLabel style={{ marginBottom: 10 }}>IMAP Access</HeaderLabel>
        <SubHeaderLabel style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
          First let&lsquo;s&nbsp;
          <b>enable IMAP&nbsp;</b>
          access for your
          <Icon style={{ height: 18, width: 18, margin: '0 2px' }} type="ic-google" />
          Google Account
        </SubHeaderLabel>
        <Border style={{ marginBottom: 20 }} />
        <OrderedList style={{ marginBottom: 20 }}>
          <li>On your computer, open Gmail</li>
          <li>
            <Flex align="center">
              Click the
              <Icon fill={COLORS.BLACK_G1} size={16} style={{ margin: '0 2px' }} type="ic-settings" />
              gear icon in the top right corner
            </Flex>
          </li>
          <li>
            Click the&nbsp;
            <Label as="a" target="_blank" href="https://mail.google.com/mail/u/0/#settings/fwdandpop">Forwarding and POP/IMAP&nbsp;</Label>
            tab
          </li>
          <li>
            In the “IMAP access” section, select&nbsp;
            <b>Enable IMAP</b>
          </li>
          <li>
            Click&nbsp;
            <b>Save Changes</b>
          </li>
        </OrderedList>
        <Border style={{ marginBottom: 20 }} />
        <HowLink>
          Show me how
          <Icon style={{ marginTop: 2 }} type="ic-chevron-right" size="smallest" />
        </HowLink>
        <Footer>
          <Button
            data-form="true"
            type="primary"
            onClick={handleAddAccount}
          >
            Yes, IMAP has been enabled
          </Button>
          <Button type="normal" onClick={handleClose}>Cancel</Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default ImapInfoModal;
