import axios from 'axios';
import get from 'lodash/get';

import { CANCEL_REQUESTS } from 'data/types/event.types';

export default () => () => (next) => (action) => {
  if (!action) return;

  const cancelPrevRequests = get(action, 'payload.cancelPrevRequests', false);

  if (cancelPrevRequests) {
    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    const cancelTokenEvent = new CustomEvent(CANCEL_REQUESTS, { detail: { type: `${action.type}_CANCEL` } });
    window.dispatchEvent(cancelTokenEvent);
    const cancelEvent = (e) => {
      if (e.detail.type === `${action.type}_CANCEL`) {
        window.removeEventListener(CANCEL_REQUESTS, cancelEvent);
        source.cancel();
      }
    };
    window.addEventListener(CANCEL_REQUESTS, cancelEvent);
    delete action.payload.cancelPrevRequests;
    action.payload.cancelToken = cancelToken;
    action.payload.cancelEvent = cancelEvent;
    next(action);
  } else {
    next(action);
  }
};
