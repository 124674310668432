const calculatePrice = ({
  planPrice,
  period,
  discount,
  quantity,
  phoneSearchPrice = 0,
  phoneSearchQuantity = 1,
  isBlackFriday = false,
  isCustom = false,
}) => {
  let price = planPrice;
  if (period === 'year') price *= 12;
  if (discount) {
    if (isBlackFriday && !isCustom) price = Math.round(price / 2);
    else price = Math.round((price * (100 - discount)) / 100);
    // price = Math.round((price * (100 - discount)) / 100);
  }

  if (quantity > 1) {
    price *= quantity;
  }

  if (phoneSearchPrice > 0) {
    price += phoneSearchPrice * phoneSearchQuantity;
  }

  return Math.round(price) > 10 ? Math.round(price) : 10;
};

export default calculatePrice;
