import { PageSizes } from 'data/types/tableMeta.types';
import fileDownloadByUrl from 'helpers/fileDownloadByUrl';
import { get } from 'lodash';
import moment from 'moment';
import { toggleMessage } from './message';

export const verificationEventsSubscription = () => (dispatch) => {
  dispatch({
    type: 'VERIFICATION_EVENTS_SUBSCRIPTION',
    payload: {
      event: 'api/v1/events',
    },
  });
};

export const setEmailsProperty = (emails) => (dispatch) => {
  dispatch({
    type: 'SET_EMAILS_PROPERTY',
    data: emails,
  });
};

export const syncEmailsProperty = (emails) => (dispatch) => {
  dispatch({
    type: 'SYNC_EMAILS_PROPERTY',
    data: emails,
  });
};

export const getVericationBulks = ({
  onSuccess = () => {},
  onFailed = () => {},
} = {}) => (dispatch, getState) => {
  const state = getState();
  const { page, pageSize } = get(state, 'verifications', { page: 1, pageSize: PageSizes.DEFAULT_VERIFICATIONS_PAGE_SIZE });
  const params = { pageNumber: page, pageSize };

  dispatch({
    type: 'GET_VERIFICATIONS_BULKS',
    payload: {
      endpoint: 'api/v1/verification/bulks',
      method: 'GET',
      params,
    },
    onSuccess,
    onFailed,
  });
};

export const setVerificationsParams = ({
  onSuccess = () => {},
  onFailed = () => {},
  page, pageSize,
} = {}) => (dispatch, getState) => {
  const state = getState();
  const currentPageSize = get(state, 'verifications.pageSize', PageSizes.DEFAULT_VERIFICATIONS_PAGE_SIZE);
  const currentPage = get(state, 'verifications.page', 1);

  const params = {
    page: page || currentPage,
    pageSize: pageSize || currentPageSize,
  };

  dispatch({
    type: 'SET_VERIFICATIONS_PARAMS',
    data: params,
    onSuccess,
    onFailed,
  });
  dispatch(getVericationBulks());
};

export const deleteBulk = (bulkId) => (dispatch, getState) => {
  const state = getState();
  const { page: currentPage } = state.verifications;
  const onSuccessDelete = () => {
    const page = state.verifications.bulks.length - 1 ? currentPage : currentPage - 1;
    dispatch(setVerificationsParams({ page }));
  };

  dispatch({
    type: 'VERIFICATIONS_DELETE_BULK',
    payload: {
      endpoint: `api/v1/verification/${bulkId}`,
      method: 'DELETE',
    },
    onSuccess: () => onSuccessDelete(),
  });
};

export const bulkInvoice = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: 'BULK_INVOICE',
    payload: {
      endpoint: 'api/v1/verification/invoice',
      method: 'POST',
      body: data,
    },
    onSuccess: (invoice) => onSuccess(invoice),
    onFailed,
  });
};

export const singleEmailsVerification = (emails) => (dispatch) => {
  dispatch({
    type: 'SINGLE_EMAILS_VERIFICATIONS',
    payload: {
      endpoint: 'api/v1/verification/verify',
      method: 'POST',
      body: { tasks: emails.map((email) => ({ email })) },
    },
    onSuccess: (data) => {
      if (data.length) {
        data.map((item) => {
          item.date = moment().format();
          return item;
        });
        dispatch(setEmailsProperty(data));
      } else {
        dispatch(toggleMessage('error', { header: 'Something went wrong' }));
      }
    },
    onFailed: (err) => dispatch(toggleMessage('error', { header: (err?.response?.data?.message || 'Something went wrong') })),
  });
};

export const exportVerificationsTable = (verificatedItems, exportType) => (dispatch) => {
  const emailsData = (exportType === 'all') ? verificatedItems : verificatedItems.filter((item) => item.status === exportType);
  emailsData.forEach((item) => {
    delete item.id;
    item.date = moment(item.date).format('LLL');
  });
  dispatch({
    type: 'EXPORT_VERIFICATIONS_TABLE',
    payload: {
      endpoint: 'api/v1/verification/export',
      method: 'POST',
      body: emailsData,
    },
    onSuccess: (data) => {
      fileDownloadByUrl(URL.createObjectURL(new Blob([data], { type: 'text/csv' })), `GetProspect-Verify_${moment(Date.now()).format('YYYY-MM-DD_h_mm')}.csv`);
    },
    onFailed: () => dispatch(toggleMessage('error', { header: 'Something went wrong' })),
  });
};

const addBulk = (bulk) => (dispatch) => {
  dispatch({
    type: 'VERIFICATIONS_ADD_BULK',
    data: bulk,
  });
};

export const setBulkPreprocess = (preprocess) => (dispatch) => {
  dispatch({
    type: 'SET_BULK_PREPROCESS',
    data: preprocess,
  });
};

export const unsetBulkPreprocess = () => (dispatch) => {
  dispatch({
    type: 'SET_BULK_PREPROCESS',
    data: {
      loading: false,
      loadedFile: {},
      inProgress: false,
      fileUrl: '',
      fileName: '',
      emailColumns: [],
    },
  });
};

export const setBulkInProgress = (bulkId, status) => (dispatch) => {
  dispatch({
    type: 'SET_BULK_IN_PROGRESS',
    data: {
      bulkId,
      status,
    },
  });
};

export const startBulkVerification = (bulkId, type) => (dispatch) => {
  dispatch({
    type: 'START_BULK_VERIFICATION',
    payload: {
      endpoint: 'api/v1/verification/bulk/start',
      method: 'POST',
      body: { bulkId, type },
    },
    onSuccess: (data) => {
      if (!data) {
        dispatch(setBulkInProgress(bulkId, true));
      } else { // when payment declined at endpoint
        dispatch(toggleMessage('error', { text: data.toString() }));
      }
    },
    onFailed: (err) => {
      if (err?.response?.status === 403) dispatch(toggleMessage('error', { text: err.response.data.message }));
      else dispatch(toggleMessage('error', { header: 'Something went wrong' }));
    },
  });
};

export const bulkPreprocess = (file) => (dispatch) => {
  const form = new FormData();
  form.append('file', file.originFileObj, file.name);
  dispatch(setBulkPreprocess({ loading: true, inProgress: true }));
  dispatch({
    type: 'BULK_VERIFICATION_PREPROCESS',
    payload: {
      endpoint: 'api/v1/verification/bulk/preprocess',
      method: 'POST',
      body: form,
    },
    onSuccess: (data) => {
      if (data.bulk) {
        dispatch(unsetBulkPreprocess());
        dispatch(addBulk(data.bulk));
      } else if (data.emailColumns) {
        dispatch(setBulkPreprocess({
          loading: false,
          emailColumns: data.emailColumns,
          fileName: data.fileName,
          fileUrl: data.fileUrl,
        }));
      } else {
        dispatch(setBulkPreprocess({ loading: false }));
      }
    },
    onFailed: () => {
      dispatch(toggleMessage('error', { header: 'Something went wrong' }));
      dispatch(setBulkPreprocess({ loading: false }));
    },
  });
};

export const confirmColumnSelect = ({
  column,
  fileName,
  fileUrl,
}) => (dispatch) => {
  dispatch(setBulkPreprocess({ loading: true }));
  dispatch({
    type: 'VERIFICATIONS_CONFIRM_COLUMN',
    payload: {
      endpoint: 'api/v1/verification/column/confirm',
      method: 'POST',
      body: {
        column,
        fileName,
        fileUrl,
      },
    },
    onSuccess: (bulk) => {
      dispatch(addBulk(bulk));
      dispatch(unsetBulkPreprocess());
    },
    onFailed: () => {
      dispatch(toggleMessage('error', { header: 'Something went wrong' }));
      dispatch(setBulkPreprocess({ loading: true }));
    },
  });
};

export const downloadFilteredVerification = ({
  filterType,
  bulkId,
  fileName,
  isArchive,
}) => (dispatch) => {
  dispatch({
    type: 'GET_FILTERED_VERIFICATION',
    payload: {
      endpoint: 'api/v1/verification/bulk/filter',
      method: 'POST',
      body: {
        filterType,
        bulkId,
      },
    },
    onSuccess: (res) => {
      if (!isArchive) {
        if (res?.buffer) fileDownloadByUrl(URL.createObjectURL(new Blob([Buffer.from(res.buffer).toString()], { type: 'text/csv' })), `${fileName.replace('.csv', '')}-verified.csv`);
        else dispatch(toggleMessage('error', { header: 'No emails for this option' }));
      } else dispatch(toggleMessage('info', { header: 'Your file is preparing', text: 'You will receive notification with download link' }));
    },
    onFailed: () => {
      dispatch(toggleMessage('error', { header: 'Something went wrong' }));
    },
  });
};

export const removeVerificationsEvents = (items) => (dispatch) => {
  dispatch({
    type: 'REMOVE_VERIFICATIONS_EVENTS',
    data: {
      items,
    },
  });
};
