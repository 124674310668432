import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { validateAffiliateLink } from '../helpers/validate';
import { accountSelector, isAuthSelector } from '../data/selectors/user';

export const useAffiliate = () => {
  const link = window.location.href;
  const isAuth = useSelector(isAuthSelector);
  const { emailVerified } = useSelector(accountSelector);

  useEffect(() => {
    if ((!isAuth || !localStorage.getItem('affiliate')) && validateAffiliateLink(link)) {
      const { search } = new URL(link);
      const [, affiliateCode] = search.split('=');
      localStorage.setItem('affiliate', affiliateCode);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!!localStorage.getItem('affiliate') && emailVerified) {
      localStorage.removeItem('affiliate');
    }
  }, [emailVerified]);
};
