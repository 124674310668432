import React from 'react';
import { useDispatch } from 'react-redux';

import { Redirect, useParams } from 'react-router-dom';
import { restoreAccount } from 'data/actions/user';

const AccountRestore = () => {
  const dispatch = useDispatch();
  const { email, token } = useParams();

  dispatch(restoreAccount(email, token));

  return <Redirect to="/login" />;
};

export default AccountRestore;
