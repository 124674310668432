import { SOURCE_TYPE } from 'data/types/source.types';

export const addProtocol = (url) => (url ? `https://${url.replace(/http(s?):\/{2}/, '')}` : url);

export const getRelevantLinkedinUrl = (array, sourceType) => {
  if (!array) return array;
  if (Array.isArray(array)) {
    if (!array.length || !sourceType) return '';
    const commonUrl = array.find((item) => item.type === 'common');
    const salesUrl = array.find((item) => item.type === 'sales');
    const commonPrefix = sourceType === SOURCE_TYPE.CONTACT ? 'in' : 'company';
    const salesPrefix = sourceType === SOURCE_TYPE.CONTACT ? 'lead' : 'company';
    if (commonUrl) {
      return `https://www.linkedin.com/${commonPrefix}/${commonUrl.id}`;
    }
    if (salesUrl) {
      return `https://www.linkedin.com/sales/${salesPrefix}/${salesUrl.id}`;
    }
    return '';
  }
  return array;
};
