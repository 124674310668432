import React from 'react';
import { Progress } from 'antd';

import { COLORS } from 'constants/styles';
import { signOut } from 'data/actions/user';
import { useDispatch } from 'react-redux';
import logo from 'assets/images/logo-with-label.svg';
import {
  Footer,
  Container,
  LeftContainer,
  Header,
  ProgressWrapper,
  ChildrenContainer,
} from './styles';
import FinishPart from './parts/finish';
import BasePart from './parts/base';
import SignUpPart from './parts/signUp';
import ConfirmEmail from './parts/confirmEmail';

const partsDictionary = {
  base: BasePart,
  finish: FinishPart,
  signUp: SignUpPart,
  confirmEmail: ConfirmEmail,
};

const AuthContainer = ({
  percent,
  children,
  finish,
  content = 'base',
}) => {
  const RightContent = partsDictionary[content];

  const dispatch = useDispatch();

  const handleBackToSite = () => {
    dispatch(signOut());
  };

  return (
    <Container finish={finish}>
      <LeftContainer finish={finish}>
        <div>
          <Header finish={finish}>
            <a href={process.env.REACT_APP_WEBSITE_URL} onClick={content === 'confirmEmail' && handleBackToSite ? handleBackToSite : () => {}}>
              <img src={logo} alt="Logo" />
            </a>
          </Header>
          <ProgressWrapper percent={percent}>
            {
              typeof percent === 'number' ? (
                <Progress percent={percent} strokeWidth={5} showInfo={false} strokeColor={COLORS.PRIMARY} trailColor="white" strokeLinecap="square" />
              ) : null
            }
          </ProgressWrapper>
          <ChildrenContainer finish={finish}>
            {children}
          </ChildrenContainer>
        </div>
        {content === 'signUp' && (
        <Footer>
          <span>By signing up, you agree to our </span>
          <a href={`${process.env.REACT_APP_WEBSITE_URL}/terms`} target="_blank" rel="noopener noreferrer">Terms of use </a>
          <span>and our </span>
          <a href={`${process.env.REACT_APP_WEBSITE_URL}/privacy`} target="_blank" rel="noopener noreferrer">Privacy Policy </a>
        </Footer>
        )}
      </LeftContainer>

      <RightContent />
    </Container>
  );
};

export default AuthContainer;
