import { emojiExpression } from './regularExpressions';

export const replaceItem = (array, item, structure = []) => {
  const copiedArray = [...array];
  const contactInDataIndex = copiedArray.findIndex((arrayItem) => arrayItem?._id === item?._id);

  if (contactInDataIndex === -1) {
    return copiedArray;
  }
  const insertItem = structure.length ? structure.reduce((prev, current) => ({
    ...prev,
    [current]: item[current],
  }), {}) : item;
  copiedArray.splice(contactInDataIndex, 1, insertItem);

  return copiedArray;
};

export const emojiClear = (text) => (text ? text?.replace(emojiExpression, '') : text);
