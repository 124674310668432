import { Modal } from 'antd';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { modalsSelector } from 'data/selectors/modals';
import {
  AskText,
  BlueLabel,
} from '../cancelSubscriptionModal/styles';
import {
  Title,
} from '../pauseSubscriptionModal/styles';
import {
  CloseIcon,
  ModalTitle,
} from '../baseModal/styles';
import {
  Footer,
  FooterOr,
  Description,
} from './styles';

const PreCancelSubscriptionModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);

  const handleClose = () => {
    dispatch(toggleModal('pre_cancel_subscription', false));
  };

  const openIntercom = () => {
    if (window.Intercom) {
      window.Intercom('showNewMessage');
    }
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Сancel my subscription
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={420}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        <Title>
          Great news!
        </Title>
        <Description>
          You can suspend your subscription for 1 to 3 months and
          <strong> get a 30% discount </strong>
          for the next payment for any plan.
        </Description>
        <AskText>
          If you have any inquiries,
          {' '}
          <BlueLabel onClick={openIntercom}>
            please contact us via chat.
          </BlueLabel>
        </AskText>
        <Footer>
          <Button
            data-form="true"
            type="primary"
            onClick={() => dispatch(toggleModal('pause_subscription', true))}
          >
            Pause
          </Button>
          <FooterOr>or</FooterOr>
          <Button
            data-form="true"
            type="normal"
            onClick={() => dispatch(toggleModal('cancel_subscription', true))}
          >
            Cancel
          </Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default PreCancelSubscriptionModal;
