export const sendUninstallResponse = (data) => (dispatch) => {
  dispatch({
    type: 'SEND_UNINSTALL_REASON',
    payload: {
      endpoint: 'api/v1/feedback/uninstall-extension',
      method: 'POST',
      body: data,
    },
  });
};
