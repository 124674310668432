import { Tooltip } from 'antd';
import React, { useState } from 'react';

import { Icon } from 'components/common';
import { COLORS } from 'constants/styles';
import { stopPropagation } from 'helpers/dom';
import { IconContainer } from './styles';

const CopyProperty = ({ value }) => {
  const [copied, setCopied] = useState(false);
  const [visibleCopy, toggleVisibleCopy] = useState(false);

  return (
    <Tooltip
      title={copied ? 'Copied' : ''}
      visible={visibleCopy}
      onVisibleChange={(visible) => {
        toggleVisibleCopy(visible);
        if (!visible) setCopied(false);
      }}
      placement="bottom"
      align={{
        offset: [0, -5],
      }}
    >
      <IconContainer
        clickable
        hideOnMobile
        onClick={stopPropagation(() => {
          setCopied(true);
          toggleVisibleCopy(true);
          navigator.clipboard.writeText(value);
        })}
        style={{ marginTop: 3 }}
      >
        <Icon size="small" type="ic-content-copy" outlined fill={COLORS.PRIMARY} />
      </IconContainer>
    </Tooltip>
  );
};

export default CopyProperty;
