import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Icon,
} from 'antd';

import { Radio, Button, RadioGroup } from 'components/common';
import { updateCurrentList } from 'data/actions/lists';
import {
  GroupName,
  FilterEditorContainer,
  ApplyFilterButton,
  AddFilter,
  BackIcon,
  PropertiesGroup,
} from './styles';

import FilterTypes from './filterTypes';

const FilterEditor = ({
  filter, back, filters, toggleShowFilters,
}) => {
  const dispatch = useDispatch();

  const [option, setOption] = useState(1);
  const [value, setValue] = useState('');

  const getCurrentFilter = () => {
    const currentFilter = { property: filter.name };

    switch (option) {
      case 1:
        currentFilter.operator = 'CONTAINS_ANY';
        currentFilter.value = value;
        break;
      case 2:
        currentFilter.operator = 'NOT_CONTAINS_ANY';
        currentFilter.value = value;
        break;
      case 3:
        currentFilter.operator = 'EQ';
        currentFilter.value = value;
        break;
      case 4:
        currentFilter.operator = 'NEQ';
        currentFilter.value = value;
        break;
      case 5:
        currentFilter.operator = 'IS_NOT_EMPTY';
        break;
      case 6:
        currentFilter.operator = 'IS_EMPTY';
        break;
      default:
        currentFilter.operator = 'IS_NOT_EMPTY';
        break;
    }

    return currentFilter;
  };

  return (
    <PropertiesGroup>
      <AddFilter onClick={back}>
        <Icon type="left" style={BackIcon} />
        <span>Back</span>
      </AddFilter>
      <GroupName>{filter.label}</GroupName>
      <GroupName>{filter.description}</GroupName>
      <FilterEditorContainer>
        <RadioGroup onChange={(props) => setOption(props.target.value)} value={option}>

          <Radio value={1}>
            is equal to any
          </Radio>
          {(option === 1) && (
            <FilterTypes handleAddValue={(val) => setValue(val)} filter={filter} />
          )}

          <Radio value={2}>
            is not equal to any of
          </Radio>
          {(option === 2) && (
            <FilterTypes handleAddValue={(val) => setValue(val)} filter={filter} />
          )}

          <Radio value={3}>
            contains any of
          </Radio>
          {(option === 3) && (
            <FilterTypes handleAddValue={(val) => setValue(val)} filter={filter} />
          )}

          <Radio value={4}>
            doesn&apos;t contains any of
          </Radio>
          {(option === 4) && (
            <FilterTypes handleAddValue={(val) => setValue(val)} filter={filter} />
          )}
          <Radio value={5}>
            Is known
          </Radio>
          <Radio value={6}>
            Is unknown
          </Radio>
        </RadioGroup>
        <Button
          type="primary"
          size="default"
          block
          style={ApplyFilterButton}
          disabled={(option < 3 && value.length === 0)}
          onClick={() => {
            const currentFilter = getCurrentFilter();
            dispatch(updateCurrentList('filters', [...filters, currentFilter]));
            back();
            toggleShowFilters(true);
          }}
        >
          Apply filter
        </Button>
      </FilterEditorContainer>
    </PropertiesGroup>
  );
};

export default FilterEditor;
