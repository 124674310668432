import { useEffect } from 'react';

const useOnClickOutside = (refs, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        if (refs?.every((ref) => !ref?.current) || refs?.some((ref) => ref?.current && ref?.current?.contains(event.target))) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [refs, handler],
  );
};

export default useOnClickOutside;
