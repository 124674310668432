import get from 'lodash/get';
import { Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Input, Button } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { createContactsFilter } from 'data/actions/contacts';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { contactsFiltersSelector, contactsFilterSelector } from 'data/selectors/contacts';
import useWorkspacePath from 'hooks/useWorkspacePath';

import { TOUR } from 'data/types/tours.types';
import {
  CloseIcon,
  ModalTitle,
  Label,
  InputStyle,
  Footer,
  Warning,
} from '../baseModal/styles';

const CreateFilterModal = () => {
  const dispatch = useDispatch();
  const filters = useSelector(contactsFiltersSelector);
  const savedFilters = useSelector(contactsFilterSelector);
  const { visible, options } = useSelector(modalsSelector);
  const { history } = useSelector(modalOptionsSelector);
  const filterName = get(options, 'filterName');
  const getWorkspaceUrl = useWorkspacePath();

  const [name, updateName] = useState('');
  useEffect(() => updateName(''), [visible]);

  useEffect(() => {
    if (filterName) updateName(filterName);
  }, [filterName]);

  const navigate = (id) => {
    if (filterName) {
      window.location = getWorkspaceUrl(`/contacts/filter/${id}?existed=true&tour=${TOUR.ORGANIZE_FILTERS}&step=5`);
    } else {
      history.push(`/contacts/filter/${id}?existed=true`);
    }
  };

  const onSuccessCreateList = (data) => {
    const id = get(data, '_id');
    navigate(id);
    dispatch(toggleModal('create_filter', false));
  };

  const isExist = () => {
    if (savedFilters.findIndex((item) => item.name === name) > -1 || name === 'All contacts'
    || name === 'All companies') return true;
    return false;
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Create a new view
          <CloseIcon
            onClick={() => dispatch(toggleModal('create_filter', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={() => dispatch(toggleModal('create_filter', false))}
    >
      <>
        <Label>Name*</Label>
        <Input
          autoFocus
          onChange={(e) => {
            updateName(e.target.value);
          }}
          value={name}
          placeholder="Type the view name..."
          style={InputStyle}
        />
        {isExist() && (
          <Warning>
            {`${name} already exists. Please choose a new name.`}
          </Warning>
        )}
      </>
      <Footer>
        <Button
          type="primary"
          onClick={() => {
            dispatch(createContactsFilter(name, filters, onSuccessCreateList));
          }}
          disabled={!name || !filters.length || isExist()}
          data-tour="create-filter-tour-step-5"
          data-form={!name || !filters.length || isExist() ? 'false' : 'true'}
        >
          Save
        </Button>
        <Button type="normal" onClick={() => dispatch(toggleModal('create_filter', false))}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default withRouter(CreateFilterModal);
