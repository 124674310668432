import styled from 'styled-components';

import { Option } from 'components/common';
import * as CONSTANTS from 'constants/styles';

export const Title = styled.h2`
  color: inherit;
  font-weight: 400;
  font-size: 24px;
  margin-top: 0;
  display: block;
  line-height: normal;
  text-transform: none;
  margin-bottom: 40px;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #33475b;
  line-height: 24px;
  margin-bottom: 20px;
  text-align: center;
  max-width: 804px;
`;

export const SelectButton = {
  padding: '10px 5px',
};

export const SelectContainer = {
  minWidth: '180px',
  width: '100%',
};

export const SelectDivider = {
  margin: '0px 0px',
};

export const MatchIcon = styled.div`
  color: green;
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  padding: 8px;
  display: flex;
  gap: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 905px;
  margin: 0 auto;
`;

export const TooltipIcon = styled.span`
  position: absolute;
  right: 25px;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  background-color: ${CONSTANTS.COLORS.GREY_G6};
  color: ${CONSTANTS.COLORS.WHITE};
  text-align: center;
  line-height: 15px;
  font-size: 10px;
  font-weight: 600;
`;

export const PropertyOption = styled(Option)`
  position: relative;

  &.ant-select-selection-selected-value {
    ${TooltipIcon} {
      display: none;
    }
  }
`;

export const MapTableWrapper = styled.div`
  width: 100%;
  border: 1px solid ${CONSTANTS.COLORS.GREY_G4};
  border-radius: 4px;
  
  tr:last-child td {
    border: none;
  }
  
  span.ant-table-column-title {
    font-weight: 600;
  }
  
  .ant-table-thead > tr:first-child > th:first-child {
    padding-left: 30px;
  }
  
  .ant-table-tbody > tr > td:first-child {
    padding-left: 30px;
  }
  
  .ant-table-wrapper {
    overflow-y: scroll;
    width: 100%;
  }
  
  .ant-table-scroll table {
    width: 905px !important;
  }
  
  .ant-select-selection--single {
    height: 40px;
  }
  
  .ant-select-selection__rendered {
    height: 40px;
    display: flex;
    align-items: center;
    
    ${TooltipIcon} {
      display: none;
    }
  }
  
  .ant-select-selection-selected-value {
    font-size: 16px;
  }
`;

export const PreviewInfo = styled.div`
  font-size: 12px;
  line-height: 16px;
`;

export const UnmatchedContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const UnmatchedTitle = styled.b`
  margin: 0;
  font-size: 14;
  line-height: 20px;
`;

export const EditImport = styled.span`

`;

export const DontImportContainer = styled.div`
  p {
    margin: 0;
  }

  ${EditImport} {
    display: block;
    color: ${CONSTANTS.COLORS.PRIMARY};
    cursor: pointer;
  }
`;
