import get from 'lodash/get';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useLoginPlugin from 'hooks/useLoginPlugin';
import { googleAuth } from 'data/actions/user';
import {
  getCookieDataByDestination,
  getQueryData,
  getCookie,
} from './useSignUpQuery';

const useGoogleLogin = () => {
  const dispatch = useDispatch();
  const loginPlugin = useLoginPlugin();

  const signInGoogle = useCallback(({
    googleData,
    type,
    redirectUrl = `${process.env.REACT_APP_WEBSITE_APP_URL}/oauth/google/verify`,
    source = '',
    gpGroup,
  }) => {
    const callback = () => {
      switch (source) {
        case 'extension': {
          loginPlugin(true);
          break;
        }
        default:
          break;
      }
    };

    const queryData = getQueryData();

    dispatch(googleAuth({
      [type]: googleData[type],
      utmCampaign: getCookieDataByDestination('utmCampaign'),
      utmTerm: getCookieDataByDestination('utmTerm'),
      utmSource: get(queryData, 'utmSource', get(queryData, 'utm_source')) ?? getCookieDataByDestination('utmSource'),
      utmMedium: get(queryData, 'utmMedium', get(queryData, 'utm_medium')) ?? getCookieDataByDestination('utmMedium'),
      utmPage: getCookie('utmPage'),
      gp_group: gpGroup,
      redirectUrl,
      callback,
    }));
  }, [dispatch, loginPlugin]);

  return signInGoogle;
};

export default useGoogleLogin;
