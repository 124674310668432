import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { workspaceIdSelector } from 'data/selectors/user';

const useWorkspacePath = () => {
  const userWorkspaceId = useSelector(workspaceIdSelector);

  return useCallback((path) => `/${userWorkspaceId}${path}`, [userWorkspaceId]);
};

export default useWorkspacePath;
