import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
} from 'antd';

import { Button, Input } from 'components/common';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { updateCompaniesConditions, setDeletedCompaniesParams, searchCompanies } from 'data/actions/companies';
import { toggleModal } from 'data/actions/modals';
import {
  selectedCompaniesSelector,
  totalCompaniesSelector,
  companiesSelectAllContacts,
  deletedCompaniesSelector,
} from 'data/selectors/companies';
import { ConfirmationContainer } from 'components/deleteContacts/styles';
import { Container } from './styles';
import {
  ModalTitle,
  CloseIcon,
  Footer,
} from '../modals/baseModal/styles';
import { setOperation } from '../../data/actions/storeTriggers';

const DeleteCompanies = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const companies = useSelector(selectedCompaniesSelector);
  const totalCompanies = useSelector(totalCompaniesSelector);
  const { totalItems: totalDeletedCompanies, selectAll: selectAllDeletedCompanies } = useSelector(deletedCompaniesSelector);
  const {
    companyId,
    name,
    setSelectedRowKeys,
    operationType = 'delete',
    excludedRowKeys = [],
    onClickYes,
  } = useSelector(modalOptionsSelector);
  const { selectAll: selectAllCompanies } = useSelector(companiesSelectAllContacts);
  const [inputValue, setInputValue] = useState();
  const [companiesCount, setCompaniesCount] = useState(0);

  const handleClose = () => dispatch(toggleModal('delete_companies', false));

  useEffect(() => {
    if (operationType === 'delete') {
      if (selectAllCompanies) setCompaniesCount(totalCompanies - excludedRowKeys.length);
      else setCompaniesCount(companies.length);
    } else if (selectAllDeletedCompanies) setCompaniesCount(totalDeletedCompanies - excludedRowKeys.length);
    else setCompaniesCount(companies.length);
  }, [totalCompanies, totalDeletedCompanies, selectAllCompanies, selectAllDeletedCompanies, companies, operationType, excludedRowKeys.length]);

  const refreshCompanies = () => {
    if (operationType !== 'delete') dispatch(setDeletedCompaniesParams({ page: 1, findCount: true }));
    dispatch(searchCompanies());
  };

  const isButtonDisabled = () => {
    if (name || (operationType === 'restore')) return false;
    return inputValue !== companiesCount;
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger={(operationType !== 'restore')}>
          {name ? `Delete the company ${name} ?` : `Bulk ${(operationType === 'restore') ? 'restore' : 'delete'} companies`}
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <Container>
        <div>
          You&#39;re about to
          {' '}
          {(operationType === 'permanent_delete') && 'permanently'}
          {' '}
          {(operationType === 'restore') ? 'restore' : 'delete'}
          {name ? ` ${name} company.` : ` ${companiesCount} companies`}
          {'. '}
          {(operationType !== 'restore') && ((operationType === 'permanent_delete') ? "This action can't be undone." : "Deleted companies can't be restored after 60 days.")}
        </div>
        {!name && (operationType !== 'restore') && (
          <ConfirmationContainer>
            <b>Type the number of companies below to delete</b>
            <Input
              value={inputValue}
              placeholder={companiesCount}
              onChange={(event) => setInputValue(parseInt(event.target.value, 10) > 0 ? parseInt(event.target.value, 10) : '')}
            />
          </ConfirmationContainer>
        )}
      </Container>
      <Footer>
        <Button
          disabled={isButtonDisabled()}
          data-form="true"
          type={(operationType === 'restore') ? 'primary' : 'danger'}
          size="large"
          onClick={() => {
            if (onClickYes) onClickYes();
            const companiesIds = name && companyId ? [companyId] : companies;
            dispatch(updateCompaniesConditions(companiesIds, excludedRowKeys, (operationType !== 'delete'), operationType, (data) => {
              if (!data.pending) {
                refreshCompanies();
              }
              if (setSelectedRowKeys) setSelectedRowKeys([]);
            }));
            dispatch(setOperation({
              type: 'insight',
              source: 'company',
              from: 'companies',
              data: {
                selectAll: selectAllCompanies,
                ids: companiesIds,
              },
            }));
            handleClose();
          }}
        >
          {(operationType === 'restore') ? 'Restore' : 'Delete'}
        </Button>
        <Button
          size="large"
          type="default"
          onClick={() => {
            if (name && companyId) {
              dispatch(toggleModal('company_preview', true, { companyId }));
              return true;
            }
            return handleClose();
          }}
        >
          Cancel
        </Button>
      </Footer>
    </Modal>
  );
};

export default DeleteCompanies;
