import React from 'react';
import { Button } from 'components/common';
import { upperCaseFirst } from 'helpers/upperCaseFirst';
import chromeDownloadImage from 'assets/images/ic-download-chrome.svg';
import searchDatabaseImage from 'assets/images/ic-database-search.svg';
import chromeImage from 'assets/images/ic-chrome.svg';
import {
  CloseMessageImg,
  HeaderWrapper,
  Container,
  MessageTextWrapper,
  ButtonWrapper,
  ContentWrapper,
  TextWrapper,
} from './styles';

const dictionaryImages = {
  downloadChrome: chromeDownloadImage,
  chrome: chromeImage,
  searchDatabase: searchDatabaseImage,
};

const RightImageMessageTemplate = ({ data }) => {
  const {
    type,
    text,
    header,
    close,
    primaryButtonOptions,
    ref,
    image,
    visible,
    handleButtonAction,
  } = data;

  return (
    <Container
      className="allow-demo-click"
      visible={visible}
      ref={ref}
    >
      <MessageTextWrapper>
        <HeaderWrapper>
          <span>{header || upperCaseFirst(type)}</span>
          <CloseMessageImg onClick={close} />
        </HeaderWrapper>
        {text && (
          <ContentWrapper>
            <TextWrapper style={{ marginTop: dictionaryImages[image] ? 0 : '15px' }}>{text}</TextWrapper>
            {dictionaryImages[image] && <img src={dictionaryImages[image]} alt="" />}
          </ContentWrapper>
        )}
        {primaryButtonOptions && (
          <ButtonWrapper>
            {primaryButtonOptions && <Button size="medium" type="primary" onClick={() => handleButtonAction(primaryButtonOptions)}>{primaryButtonOptions.text}</Button>}
          </ButtonWrapper>
        )}
      </MessageTextWrapper>
    </Container>
  );
};

export default RightImageMessageTemplate;
