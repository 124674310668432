import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
} from 'antd';

import {
  Select,
  Search,
  Button,
  Option,
} from 'components/common';
import { toggleModal } from 'data/actions/modals';

import useFetch from 'hooks/useFetch';
import AddFilter from 'components/addFilter';
import * as CONSTANTS from 'constants/styles';
import useInputMutex from 'hooks/useInputMutex';
import { getFullName } from 'helpers/getFullName';
import { toggleMessage } from 'data/actions/message';
import { uploadImageToCDN } from 'data/actions/common';
import { workspaceIdSelector } from 'data/selectors/user';
import downloadBlobByUrl from 'helpers/downloadBlobByUrl';
import { selectedSequenceIdSelector, stepTemplatesSelector, templateTeammatesSelector } from 'data/selectors/sequences';
import {
  addAttachmentsToStep,
  getStepTemplates,
  getTemplateTeammates,
  updateStep,
} from 'data/actions/sequences';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import Loader from '../../loading';
import {
  BodyStyle,
  Body,
  HeaderStyle,
} from '../styles';
import {
  ModalTitle,
  CloseIcon,
} from '../../modals/baseModal/styles';
import {
  CreateTemplateContainer,
  Footer,
  CreateTemplateWrapper,
  FilterTemplateWrapper,
  TemplateText,
  Wrapper,
  ButtonWrapper,
  CreateNewTemplateStyles,
} from './styles';
import StepTemplateList from './stepTemplateList';

const AddTemplateStepPreviewBar = () => {
  const dispatch = useDispatch();

  const workspaceId = useSelector(workspaceIdSelector);
  const sequenceId = useSelector(selectedSequenceIdSelector);
  const { visible } = useSelector(modalsSelector);
  const {
    stepId,
    version,
    content,
    subject,
    previousModal,
  } = useSelector(modalOptionsSelector);

  const { data: stepTemplates } = useFetch({
    action: getStepTemplates,
    selector: stepTemplatesSelector,
  });
  const { data: templateTeammates } = useFetch({
    action: getTemplateTeammates,
    selector: templateTeammatesSelector,
  });

  const [name, setName] = useInputMutex({
    minSearchValueLength: 2,
    maxRequestRate: 500,
    callback: useCallback((result) => {
      dispatch(getStepTemplates({
        name: result,
      }));
    }, [dispatch]),
  });
  const [templateId, setTemplateId] = useState();
  const [filter, setFilter] = useState(null);

  const handleFilter = (value) => {
    dispatch(getStepTemplates({ owner: value }));
    setFilter(value);
  };

  const handleClose = () => {
    dispatch(toggleModal('add_template_step_preview', false));
  };

  const handleUpdateStep = async () => {
    const foundTemplate = stepTemplates.find((template) => template._id === templateId);
    if (previousModal) {
      dispatch(toggleModal(previousModal, true, {
        stepId,
        version,
        subject: foundTemplate.subject || subject,
        content: foundTemplate.content || content,
        attachments: foundTemplate.attachments,
      }));
    } else {
      dispatch(updateStep({
        step: {
          _id: stepId,
          subject: foundTemplate.subject,
          content: foundTemplate.content,
        },
        version,
        checkErrors: false,
        onSuccess: foundTemplate.attachments?.length > 0 ? undefined : handleClose,
      }));
      if (foundTemplate.attachments?.length > 0) {
        const promises = await Promise.allSettled(foundTemplate.attachments.map(async (attachment) => {
          const blob = await downloadBlobByUrl(attachment.url);
          const blobWithType = new Blob([blob], { type: attachment.fileType });
          return new Promise((res, rej) => {
            dispatch(uploadImageToCDN({
              file: {
                meta: {
                  name: attachment.name,
                  type: attachment.fileType,
                },
                data: blobWithType,
              },
              prefix: `${workspaceId}/sequences/${sequenceId}`,
              onSuccess: ({ fields }) => {
                const newFile = {
                  fileType: attachment.fileType,
                  name: attachment.name,
                  url: `${process.env.REACT_APP_CDN_URL}/${fields.Key}`,
                  size: attachment.size,
                };
                res(newFile);
              },
              onFailed: () => rej(new Error(`File ${attachment.name} hasn't been uploaded. Something went wrong`)),
            }));
          });
        }));
        const newFiles = [];

        promises.forEach((promise) => {
          if (promise.status === 'rejected') {
            dispatch(toggleMessage('error', { text: promise.reason.message }));
          } else {
            newFiles.push(promise.value);
          }
        });
        dispatch(addAttachmentsToStep({
          stepId,
          version,
          resetAttachments: true,
          attachments: newFiles,
          onSuccess: handleClose,
        }));
      }
    }
  };
  const handleCreateTemplate = () => {
    dispatch(toggleModal('create_email_template', true, {
      stepId,
      version,
      content,
      subject,
      previousModal,
    }));
  };

  return (
    <Drawer
      title={(
        <ModalTitle>
          Select template
          <CloseIcon
            data-close="true"
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      placement="right"
      onClose={handleClose}
      closable={false}
      headerStyle={HeaderStyle}
      visible={visible}
      bodyStyle={BodyStyle(81 + 52)}
      width={CONSTANTS.DRAWER_WIDTH}
      destroyOnClose
    >
      <Body>
        <Loader size="large" loading={false}>
          <Wrapper>
            <CreateTemplateWrapper>
              <CreateTemplateContainer>
                <TemplateText>Existing email templates</TemplateText>
              </CreateTemplateContainer>
            </CreateTemplateWrapper>
            <FilterTemplateWrapper>
              <Select value={filter} onChange={handleFilter} placeholder="All templates">
                <Option value={null}>All templates</Option>
                {
                  templateTeammates.map((teammate) => <Option value={teammate._id} key={teammate._id}>{getFullName(teammate)}</Option>)
                }
              </Select>
            </FilterTemplateWrapper>
            <CreateTemplateWrapper>
              <CreateTemplateContainer>
                <TemplateText>Search template</TemplateText>
              </CreateTemplateContainer>
            </CreateTemplateWrapper>
            <FilterTemplateWrapper marginBottom={25}>
              <Search value={name} onChange={(e) => setName(e.target.value)} placeholder="Find template" />
            </FilterTemplateWrapper>
            <StepTemplateList onChange={(e) => setTemplateId(e.target.value)} value={templateId} />
          </Wrapper>
        </Loader>
      </Body>
      <Footer white>
        <div style={{ display: 'flex' }}>
          <ButtonWrapper>
            <Button type="primary" data-form={!templateId ? 'false' : 'true'} disabled={!templateId} onClick={handleUpdateStep}>
              Apply
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              type="normal"
              size="large"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </ButtonWrapper>
        </div>
        <AddFilter style={CreateNewTemplateStyles} label="Create template" onClick={handleCreateTemplate} />
      </Footer>
    </Drawer>
  );
};

export default AddTemplateStepPreviewBar;
