import { Modal } from 'antd';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import close from 'assets/images/ic-close.svg';
import { Input, Button, Radio } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { addSequenceSchedule, resetSequenceSchedule, updateSequenceSchedule } from 'data/actions/sequences';
import {
  CloseIcon,
  ModalTitle,
  Label,
  Footer,
} from '../baseModal/styles';

import {
  InputNameWrapper,
  Container,
  inputStyles,
} from '../saveSearchModal/styles';

const CreateSequenceScheduleModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { scheduleId, hasCancel } = useSelector(modalOptionsSelector);

  const OPTIONS = Object.freeze({
    CREATE_NEW: 'create_new',
    UPDATE_EXISTING: 'update_existing',
    CANCEL: 'cancel',
  });

  const [name, setName] = useState('');
  const [option, setOption] = useState(OPTIONS.CREATE_NEW);

  const handleClose = () => dispatch(toggleModal('create_sequence_schedule', false));
  const handleCreate = () => {
    if (option === OPTIONS.CREATE_NEW) {
      dispatch(addSequenceSchedule(name, scheduleId));
    } else if (option === OPTIONS.UPDATE_EXISTING) {
      dispatch(updateSequenceSchedule({ _id: scheduleId }, true));
    } else {
      dispatch(resetSequenceSchedule());
    }
    handleClose();
  };
  const getActionWord = () => {
    switch (option) {
      case OPTIONS.CANCEL:
        return 'Yes';
      case OPTIONS.UPDATE_EXISTING:
        return 'Update';
      case OPTIONS.CREATE_NEW:
      default:
        return 'Create';
    }
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Save your schedule
          <CloseIcon
            src={close}
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <Container>
        <InputNameWrapper>
          <Label>
            <Radio checked={option === OPTIONS.CREATE_NEW} onChange={() => setOption(OPTIONS.CREATE_NEW)}>
              Create new
            </Radio>
          </Label>
          {
            option === OPTIONS.CREATE_NEW ? (
              <Input
                inputStyle={inputStyles}
                autoFocus
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                placeholder="Enter name"
              />
            ) : null
          }
        </InputNameWrapper>
        <InputNameWrapper>
          <Label>
            <Radio checked={option === OPTIONS.UPDATE_EXISTING} onChange={() => setOption(OPTIONS.UPDATE_EXISTING)}>
              Update existing
            </Radio>
          </Label>
        </InputNameWrapper>
        {
          hasCancel ? (
            <InputNameWrapper>
              <Label>
                <Radio checked={option === OPTIONS.CANCEL} onChange={() => setOption(OPTIONS.CANCEL)}>
                  Cancel changes
                </Radio>
              </Label>
            </InputNameWrapper>
          ) : null
        }
      </Container>
      <Footer>
        <Button
          type="primary"
          onClick={handleCreate}
          disabled={option === OPTIONS.CREATE_NEW && !name}
          data-form={option === OPTIONS.CREATE_NEW && !name ? 'false' : 'true'}
        >
          {getActionWord()}
        </Button>
        <Button type="normal" onClick={handleClose}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default CreateSequenceScheduleModal;
