import styled from 'styled-components';
import * as CONSTANTS from 'constants/styles';

export const FormWrapper = styled.div`
  margin-top: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const linkTimerStyles = {
  color: CONSTANTS.COLORS.PRIMARY,
  height: 20,
  width: 10,
  padding: 0,
  minWidth: 50,
  border: 'none',
  backgroundColor: 'transparent',
  outline: 'none',
  cursor: 'pointer',
};

export const TimerWrapper = styled.div`
  display: flex;
`;
