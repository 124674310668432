import styled from 'styled-components';

import { Icon } from 'components/common';

export const IconContainer = styled(Icon).attrs(() => ({
  type: 'ic-close',
  fill: 'primary',
}))`
  cursor: pointer;
  border-radius: 50%;
  padding: 5px;
  margin-right: -5px;
  margin-left: auto;
  transition: all 0.1s;

  &:hover {
    background: #e8ebed;
  }
  &:active {
    transform: scale(0.9);
    background: #f5f7f7;
  }
`;

export const VideoContainerStyle = {
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  paddingLeft: 0,
  paddingTop: 60,
  paddingRight: 0,
  paddingBottom: 0,
  outline: 0,
  lineHeight: 0,
};
