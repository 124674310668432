import React from 'react';
import { useSelector } from 'react-redux';

import { EXPENSIVE_PLANS_ARRAY } from 'data/types/subscription.types';
import { cancelSubscriptionSelector, currentPlanSelector, isDiscountAvailableSelector } from 'data/selectors/subscriptions';
import Discount from '../../common/components/discount';

const isEven = new Date().valueOf() % 2 === 0;

const ExpensiveSubscription = ({
  onFinish,
  setNextStep,
  stepNumber,
  onClose,
}) => {
  const currentPlan = useSelector(currentPlanSelector);
  const { discount } = useSelector(cancelSubscriptionSelector);
  const isDiscountAvailable = useSelector(isDiscountAvailableSelector);

  const isExpensivePlan = EXPENSIVE_PLANS_ARRAY.includes(currentPlan.planId);

  const steps = {
    1: isDiscountAvailable ? {
      component: Discount,
      params: {
        percent: (isEven || isExpensivePlan) ? 30 : 50,
        discountCountMonths: isExpensivePlan ? 3 : 1,
        onNext: setNextStep,
        onClose,
      },
    } : null,
  };

  const step = steps[stepNumber];

  if (!step) {
    const needToAct = !discount;
    onFinish({ needToAct });
    return null;
  }

  const { component: Component, params = {} } = step;

  // eslint-disable-next-line
  return <Component {...params} />;
};

export default ExpensiveSubscription;
