import get from 'lodash/get';
import { Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Input, Select, Option } from 'components/common';
import * as CONSTANTS from 'constants/styles';

import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { createGroup } from 'data/actions/properties';
import { addGroupsToPreviewConfiguration } from 'data/actions/user';
import { groupsSourceTypeSelector } from 'data/selectors/properties';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import {
  CloseIcon,
  ModalTitle,
  HeaderStyle,
} from '../../modals/baseModal/styles';
import {
  ModalsBody,
  DrawerWrapper,
  Footer,
  BlueButton,
  WhiteButton,
  PropertyItem,
  Label,
} from '../../createPropertyBar/styles';

const CreatePropertyGroupPreviewBar = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const { changeFilter } = useSelector(modalOptionsSelector);

  const [group, setGroup] = useState({});
  const [nameInputRef, setNameInputRef] = useState(null);

  const groups = useSelector(groupsSourceTypeSelector(group.sourceType, true));
  const existedGroup = groups.some((item) => item.name === group.name);

  useEffect(() => {
    if (nameInputRef) {
      nameInputRef.input.focus();
    }
  }, [nameInputRef]);

  const handleCreate = () => {
    const onSuccess = ({ settings }) => {
      changeFilter(group.sourceType);
      dispatch(toggleMessage('success', { header: 'Group has been updated!' }));
      if (settings) {
        dispatch(addGroupsToPreviewConfiguration(settings, group.sourceType));
      }
    };
    const onFailed = () => dispatch(toggleMessage('error', { header: 'Group has been updated with error!' }));

    dispatch(createGroup({ name: group.name, sourceType: group.sourceType }, { onSuccess, onFailed }));
    dispatch(toggleModal('create_property_group_preview', false));
  };

  const isValid = group.name && group.sourceType && !existedGroup;

  const options = ['contact', 'company'];

  return (
    <DrawerWrapper>
      <Drawer
        title={(
          <ModalTitle>
            Create property group
            <CloseIcon
              data-close="true"
              onClick={() => dispatch(toggleModal('create_property_group_preview', false))}
              className="ic_close"
            />
          </ModalTitle>
        )}
        placement="right"
        onClose={() => dispatch(toggleModal('create_property_group_preview', false))}
        closable={false}
        bodyStyle={{
          padding: 0,
          maxHeight: 'calc(100% - 180px)',
          overflow: 'auto',
          flexDirection: 'column',
        }}
        headerStyle={HeaderStyle}
        visible={visible}
        width={CONSTANTS.DRAWER_WIDTH}
      >
        <ModalsBody>
          <div>
            <PropertyItem>
              <Label>
                Object Type*
              </Label>
              <Select
                style={{ textTransform: 'capitalize' }}
                placeholder="Group source"
                size="large"
                value={get(group, 'sourceType')}
                onChange={(value) => {
                  setGroup({ ...group, sourceType: value });
                }}
              >
                {options.map((option, index) => <Option style={{ textTransform: 'capitalize' }} value={option} key={`property_item_${index + 1}_option_${option}`}>{option}</Option>)}
              </Select>
            </PropertyItem>
          </div>
          <div>
            <PropertyItem>
              <Label>
                Name*
              </Label>
              <Input
                error={existedGroup ? 'Group is existed' : ''}
                ref={setNameInputRef}
                placeholder="Group name"
                size="large"
                value={get(group, 'name')}
                onChange={(e) => {
                  setGroup({ ...group, name: e.target.value });
                }}
              />
            </PropertyItem>
          </div>
        </ModalsBody>
        <Footer>
          <WhiteButton
            type="normal"
            size="large"
            onClick={() => {
              dispatch(toggleModal('create_property_group_preview', false));
            }}
          >
            Cancel
          </WhiteButton>
          <BlueButton
            data-form={!isValid ? 'false' : 'true'}
            type="primary"
            size="large"
            disabled={!isValid}
            onClick={handleCreate}
          >
            Create
          </BlueButton>
        </Footer>
      </Drawer>
    </DrawerWrapper>
  );
};

export default CreatePropertyGroupPreviewBar;
