import React from 'react';

import Connect from '../../components/connect';
import SpecifyInformation from '../../components/specifyInformation';

const Other = ({
  sharedInformation,
  onFinish,
  setNextStep,
  stepNumber,
  onClose,
}) => {
  const steps = {
    1: {
      component: SpecifyInformation,
      params: {
        field: 'descriptionReason',
        title: 'Share your feedback with us',
        description: 'Please help us improve our product by sharing your feedback. We appreciate and take into consideration all opinions to become better for our customers.',
        onNext: setNextStep,
        onClose,
      },
    },
    2: {
      component: Connect,
      params: {
        title: 'Thank you very much for the information!',
        description: "We'd like to discuss your suggestions to see how we can implement them into our product. Choose the best way to contact you.",
        onClose,
        onNext: setNextStep,
      },
    },
  };

  const step = steps[stepNumber];

  if (!step) {
    const needToAct = !sharedInformation;
    onFinish({ needToAct });
    return null;
  }

  const { component: Component, params = {} } = step;

  // eslint-disable-next-line
  return <Component {...params} />;
};

export default Other;
