import { Drawer } from 'antd';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';

import { modalsSelector } from 'data/selectors/modals';
import {
  Button,
} from 'components/common';
import { propertiesSelector } from 'data/selectors/properties';
import { tourCurrentNameSelector } from 'data/selectors/tour';
import {
  addContact, searchContact,
} from 'data/actions/contacts';
import {
  searchLists,
} from 'data/actions/lists';
import useTinyForm from 'hooks/useTinyForm';
import * as CONSTANTS from 'constants/styles';
import { validateEmail } from 'helpers/validate';
import { toggleModal } from 'data/actions/modals';
import { CONTACT_LIMITS } from 'constants/limitsSearch';
import { addContactConfirmationLoaderSelector } from 'data/selectors/loading';
import { TOUR } from 'data/types/tours.types';
import {
  ContactPropertyContainer,
  PropertyLabel,
  Footer,
  ButtonStyle,
  BodyStyle,
} from './styles';
import Component from './component';
import { ModalTitle, CloseIcon } from '../modals/baseModal/styles';
import { HeaderStyle } from '../previewBars/styles';

const propsNameAfterRequired = ['email'];

const CreateContactBar = () => {
  const dispatch = useDispatch();

  const tourName = useSelector(tourCurrentNameSelector);
  const properties = useSelector(propertiesSelector)
    .filter((item) => (item.sourceType === 'contact' && !item.custom) || (propsNameAfterRequired.includes(item.name) && item.default));
  const { visible } = useSelector(modalsSelector);
  const loading = useSelector(addContactConfirmationLoaderSelector);

  const [existedContact, setExistedContact] = useState(null);
  const [animationDone, setAnimationDone] = useState(false);
  const requiredProps = properties.filter((prop) => prop.required);
  const propsAfterRequired = properties.filter((prop) => propsNameAfterRequired.includes(prop.name));
  const otherProps = properties.filter((prop) => ![...requiredProps.map((item) => item.name), ...propsAfterRequired.map((item) => item.name)].includes(prop.name));
  const orderedProps = [...requiredProps, ...propsAfterRequired, ...otherProps];

  const focusInputRef = useRef();

  const validateEmailOrEmpty = useCallback((value) => !value || validateEmail(value), []);

  const dynamicSchema = useMemo(() => {
    const getConditions = (item) => {
      if (item.required) {
        return [
          {
            label: 'Must contain value',
            condition: Boolean,
          },
        ];
      } if (item.name === 'email') {
        return [
          {
            label: 'Incorrect email',
            condition: validateEmailOrEmpty,
          },
        ];
      }
      return [];
    };
    const initialObject = tourName === TOUR.ENRICH_DATA ? {
      firstName: 'Bill',
      lastName: 'Gates',
      linkedinUrl: 'https://www.linkedin.com/in/williamhgates/',
    } : {};
    return properties.reduce((first, second) => ({
      ...first,
      [second.name]: {
        value: first[second.name] || '',
        conditions: getConditions(second),
      },
    }), initialObject);
  }, [tourName, properties, validateEmailOrEmpty]);

  const {
    data,
    errors,
    handleChange,
    handleSubmit,
    isDisabledForm,
  } = useTinyForm({
    schema: dynamicSchema,
    onSubmit: (result) => {
      const contactProperties = Object.keys(result.data).filter((key) => result.data[key]).map((key) => ({ property: key, value: result.data[key] }));
      dispatch(addContact({
        properties: contactProperties,
        onSuccess: () => {
          dispatch(searchLists());
          dispatch(toggleModal('create_contact_preview', false));
        },
      }));
    },
  });

  const searchContactDebounce = useCallback(debounce((email) => {
    dispatch(searchContact({
      filters: [{ property: 'companyRelations.email.value', operator: 'EQ', value: email }],
      onSuccess: (result) => {
        if (result?.data?.length) setExistedContact(result.data[0]);
        else setExistedContact(null);
      },
    }));
  }, CONTACT_LIMITS.MAX_REQUEST_RATE), [dispatch]);

  useEffect(() => {
    if (data.email) {
      searchContactDebounce(data.email);
    }
  }, [data.email, searchContactDebounce]);

  useEffect(() => {
    if (animationDone && focusInputRef?.current) {
      focusInputRef.current.focus();
    }
  }, [animationDone]);

  return (
    <Drawer
      title={(
        <ModalTitle>
          Create contact
          <CloseIcon
            data-close="true"
            onClick={() => dispatch(toggleModal('create_contact_preview', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      headerStyle={HeaderStyle}
      bodyStyle={BodyStyle}
      placement="right"
      onClose={() => dispatch(toggleModal('create_contact_preview', false))}
      visible={visible}
      width={CONSTANTS.DRAWER_WIDTH}
      closable={false}
      afterVisibleChange={(currentVisible) => {
        setAnimationDone(currentVisible);
      }}
    >
      <div>
        {orderedProps.map((item, index) => (
          <ContactPropertyContainer key={item._id}>
            <PropertyLabel>
              {item.label}
              {(item.required) ? '*' : ''}
            </PropertyLabel>
            <Component
              item={item}
              data={data}
              existedContact={existedContact}
              handleChange={handleChange}
              focusInputRef={(index === 0) ? focusInputRef : null}
              errors={errors}
            />
          </ContactPropertyContainer>
        ))}
      </div>
      <Footer>
        <Button
          data-form="true"
          type="primary"
          size="large"
          style={ButtonStyle}
          loading={loading}
          disabled={isDisabledForm || existedContact}
          onClick={handleSubmit}
          data-tour="enrich-data-tour-step-2"
        >
          Create contact
        </Button>
        <Button
          type="normal"
          size="large"
          style={ButtonStyle}
          onClick={() => dispatch(toggleModal('create_contact_preview', false))}
        >
          Cancel
        </Button>
      </Footer>
    </Drawer>
  );
};

export default CreateContactBar;
