import { Modal } from 'antd';
import get from 'lodash/get';
import queryString from 'query-string';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input } from 'components/common';
import { createFolder } from 'data/actions/lists';
import { toggleModal } from 'data/actions/modals';
import { contactsFoldersSelector } from 'data/selectors/lists';
import getFolderBreadcrumbs from 'helpers/getFolderBreadcrumbs';
import { sequenceFoldersSelector } from 'data/selectors/sequences';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';

import {
  BreadCrumbsContainer,
  BreadCrumbsItem,
} from './styles';

import {
  CloseIcon,
  ModalTitle,
  Label,
  InputStyle,
  Footer,
} from '../baseModal/styles';

const CreateFolderModal = () => {
  const dispatch = useDispatch();
  const queryData = queryString.parse(window.location.search);
  const { visible } = useSelector(modalsSelector);
  const { kind = 'list' } = useSelector(modalOptionsSelector);
  const listFolders = useSelector(contactsFoldersSelector);
  const sequenceFolders = useSelector(sequenceFoldersSelector);

  const folders = kind === 'sequence' ? sequenceFolders : listFolders;

  const [currentFolderId, setCurrentFolderId] = useState('all');
  const [name, updateName] = useState('');
  const [breadCrumbs, setBreadCrumbs] = useState([]);

  const parentId = currentFolderId === 'all' ? get(folders, '_id', 'root') : currentFolderId;

  const handleClose = () => dispatch(toggleModal('create_folder', false));

  useEffect(() => updateName(''), [visible]);

  useEffect(() => {
    setCurrentFolderId(get(queryData, 'folderId', 'all'));
  }, [queryData]);

  useEffect(() => {
    setBreadCrumbs(getFolderBreadcrumbs(currentFolderId, folders));
  }, [currentFolderId, folders]);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Create folder
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      width={500}
      footer={false}
      closable={false}
      onCancel={handleClose}
    >
      <>
        <BreadCrumbsContainer>
          {(breadCrumbs && breadCrumbs.length > 1) && breadCrumbs.map((item) => (
            <BreadCrumbsItem
              disabled={item._id === currentFolderId}
              to={`/${kind === 'sequence' ? 'sequences/folders' : 'lists'}?folderId=${item._id}`}
              key={`folder-breadcrumbs-${item._id}`}
            >
              {`${item.name} / `}
            </BreadCrumbsItem>
          ))}
        </BreadCrumbsContainer>

        <Label>Folder name *</Label>
        <Input
          autoFocus
          onChange={(e) => {
            updateName(e.target.value);
          }}
          value={name}
          placeholder="Type the folder name..."
          style={InputStyle}
        />
      </>
      <Footer>
        <Button
          data-form={!name || !parentId ? 'false' : 'true'}
          type="primary"
          onClick={() => {
            dispatch(createFolder({
              name,
              kind,
              parent: parentId,
            }));
            handleClose();
          }}
          disabled={!name || !parentId}
        >
          Save
        </Button>
        <Button type="normal" onClick={handleClose}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default CreateFolderModal;
