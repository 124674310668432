import styled from 'styled-components';
import * as CONSTANTS from 'constants/styles';

export const PropertyItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  input {
    border-radius: 4px;
    border: solid 1px #dfe2e5;
    background-color: #ffffff;
  }
  
   @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    padding: 7.5px 0;
    
    :first-child {
      padding-top: 0;
    }   
   }
`;

export const ModalButtonGroup = styled.div`
  display: flex;
  margin-top: 20px;
  
  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    justify-content: center;
    margin-top: 12.5px;
    
    .ant-btn.ant-btn-lg:first-child {
      margin-right: 15px !important;
    }
    
    .ant-btn.ant-btn-lg {
      flex: 1;
      margin: 0;
      padding: 0 !important;
    }
  }
`;
