const get = require('lodash/get');

export const addFolderToParent = (folderId, tree, data) => {
  if (tree._id === folderId) {
    // eslint-disable-next-line no-param-reassign
    tree.children = tree.children ? [data, ...tree.children] : [data];
  }
  if (tree.children) {
    let result;
    for (let i = 0; !result && i < tree.children.length; i += 1) {
      addFolderToParent(folderId, tree.children[i], data);
    }
  }
  return { ...tree };
};

export const deleteFolderFromParent = (folderId, tree) => {
  if (tree.children) {
    const isExist = tree.children.findIndex((item) => item._id === folderId);

    // eslint-disable-next-line no-param-reassign
    if (isExist > -1) tree.children = tree.children.filter((item) => item._id !== folderId);
    else {
      let result;
      for (let i = 0; !result && i < tree.children.length; i += 1) {
        deleteFolderFromParent(folderId, tree.children[i]);
      }
    }
  }
  return { ...tree };
};

export const getFolderById = (folderId, tree) => {
  if (tree._id === folderId) {
    return tree || {};
  }
  if (tree.children) {
    let result;
    for (let i = 0; !result && i < tree.children.length; i += 1) {
      result = getFolderById(folderId, tree.children[i]);
    }
    return result;
  }
  return false;
};

export const getFolderTreeIds = (folderId, tree, path = []) => {
  if (folderId === 'all') return [tree._id];
  if (tree._id === folderId) {
    path.push(tree._id);
    return path;
  }
  if (tree.children) {
    let result;
    for (let i = 0; !result && i < tree.children.length; i += 1) {
      result = getFolderTreeIds(folderId, tree.children[i], [...path, tree._id]);
    }
    return result;
  }
  return false;
};

export const getAllChildrenIds = (tree, folderIds = []) => {
  const children = get(tree, 'children', []);
  children.forEach((item) => {
    folderIds.push(item._id);

    if (item.children) {
      getAllChildrenIds(item, folderIds);
    }
  });
  return folderIds;
};
