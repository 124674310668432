import get from 'lodash/get';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
} from 'antd';

import {
  Input,
  Button,
  Select,
  Option,
} from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { updateProperties } from 'data/actions/properties';
import { addGroupsToPreviewConfiguration } from 'data/actions/user';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { selectedPropertiesSelector, groupsSourceTypeSelector } from 'data/selectors/properties';
import { ButtonStyle } from './styles';
import {
  CloseIcon,
  ModalTitle,
  Label,
  Footer,
} from '../baseModal/styles';

const generateOptions = (groups = []) => groups.map((group) => (
  <Option key={`AddPropertiesToGroupModal-option-${group._id}`} value={group.name}>{group.name}</Option>
));

const AddPropertiesToGroupModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { sourceType } = useSelector(modalOptionsSelector);
  const selectedProperties = useSelector(selectedPropertiesSelector);
  const groups = useSelector(groupsSourceTypeSelector(sourceType, true));

  const [selectedGroupName, selectGroupName] = useState(null);
  const [name, setName] = useState('');

  useEffect(() => {
    selectGroupName(null);
    setName('');
  }, [visible]);

  const onChange = (value) => {
    selectGroupName(value);
  };

  const onUpdatedProperties = ({ settings }) => {
    if (settings) {
      dispatch(addGroupsToPreviewConfiguration(settings, sourceType));
    }
  };

  const handleAdd = () => {
    dispatch(updateProperties(selectedProperties, { group: name || selectedGroupName, sourceType }, onUpdatedProperties, (err) => {
      dispatch(toggleMessage('error', { header: get(err, 'response.data.message', 'An unknown error occured') }));
    }));
    dispatch(toggleModal('add_properties_to_group', false));
  };

  const selectedPropertiesLength = get(selectedProperties, 'length', 0);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Choose new property group
          <CloseIcon
            onClick={() => dispatch(toggleModal('add_properties_to_group', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={450}
      footer={false}
      onCancel={() => dispatch(toggleModal('add_properties_to_group', false))}
      closeIcon={<div />}
    >
      <>
        <Label>
          {
            selectedPropertiesLength > 1
              ? `Add ${selectedPropertiesLength} properties to this group:`
              : 'Add selected property to this group:'
          }
        </Label>
        <Select
          allowClear={!!selectedGroupName}
          disabled={!groups.length}
          showSearch
          style={{ width: '100%', margin: '10px 0' }}
          size="large"
          placeholder="Search"
          optionFilterProp="children"
          onChange={onChange}
          value={selectedGroupName}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {generateOptions(groups)}
        </Select>
        <span>{!groups.length ? 'Not exists any groups, create a one:' : 'Or:'}</span>
        <Input
          value={name}
          placeholder="New group name"
          size="large"
          onChange={(e) => setName(e.target.value)}
          style={{ margin: '10px 0' }}
          disabled={!!selectedGroupName}
        />
      </>
      <Footer>
        <Button
          data-form={!selectedGroupName && (!name) ? 'false' : 'true'}
          type="primary"
          size="large"
          style={ButtonStyle}
          onClick={handleAdd}
          disabled={!selectedGroupName && (!name)}
        >
          Add
        </Button>
        <Button
          size="large"
          type="normal"
          style={ButtonStyle}
          onClick={() => dispatch(toggleModal('add_properties_to_group', false))}
        >
          Cancel
        </Button>
      </Footer>
    </Modal>
  );
};

export default AddPropertiesToGroupModal;
