import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
} from 'antd';

import { Button as CustomButton } from 'components/common';

import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { stepsData } from 'components/modals/importSearchFilter/steps';

import { clearSearchImportPreprocess, addImportIntoFilter } from 'data/actions/search';
import { s3SelectFromFileForSearch } from 'data/actions/import';
import { toggleMessage } from 'data/actions/message';
import Suspense from 'components/common/suspense';
import WhiteLoading from 'components/whiteLoading';
import {
  StepButtons,
  Footer,
} from 'pages/importManager/styles';
import { searchImportFilterPreprocess, searchFiltersTotalCount } from 'data/selectors/search';
import { FILTERS_MAX_COUNT } from 'data/types/search.filters.types';
import {
  CloseIcon,
  ModalTitle,
} from '../baseModal/styles';
import { FooterContainerStyles } from './styles';

const ImportSearchFilterModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const preprocessResult = useSelector(searchImportFilterPreprocess);
  const filtersTotalCount = useSelector(searchFiltersTotalCount);
  const { filterLabel, property } = useSelector(modalOptionsSelector);

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const cancelImportHandler = useCallback(() => {
    dispatch(clearSearchImportPreprocess());
    dispatch(toggleModal('import_search_filter', false));
  }, [dispatch]);

  const getPrevAction = useCallback(() => {
    switch (step) {
      case 0: {
        return { label: 'Cancel', action: cancelImportHandler };
      }
      case 1: {
        return { label: 'Back', action: () => setStep(step - 1) };
      }
      default: {
        return { label: 'Cancel', action: cancelImportHandler };
      }
    }
  }, [cancelImportHandler, setStep, step]);

  const getNextAction = useCallback(() => {
    switch (step) {
      case 0: {
        return { label: 'Next', action: () => setStep(step + 1), disable: !preprocessResult };
      }
      case 1: {
        return {
          label: 'Save',
          action: () => {
            setLoading(true);

            dispatch(s3SelectFromFileForSearch({
              preprocessedData: preprocessResult,
              onSuccess: (res) => {
                const { matchedHeader, delimiter } = preprocessResult;
                const headerCountData = res[matchedHeader];
                const countWithFileData = headerCountData + filtersTotalCount;
                setLoading(false);

                if (countWithFileData > FILTERS_MAX_COUNT) {
                  dispatch(toggleMessage('warning', { header: "Can't import more than 50,000 records" }));
                } else {
                  dispatch(addImportIntoFilter({
                    property, delimiter, filterLabel, count: headerCountData,
                  }));
                  dispatch(toggleModal('import_search_filter', false));
                }
              },
              onFailed: () => {
                setLoading(false);
                dispatch(toggleMessage('error', { header: 'Something wrong' }));
              },
            }));
          },
          disable: !preprocessResult?.matchedHeader,
        };
      }
      default: {
        return { label: 'Save' };
      }
    }
  }, [setStep, step, preprocessResult, dispatch, property, filterLabel, filtersTotalCount]);

  const renderControls = useCallback(() => {
    const prevBtnData = getPrevAction();
    const nextBtnData = getNextAction();
    const prevLabel = prevBtnData.label;
    const nextLabel = nextBtnData.label;

    return (
      <StepButtons>
        <div>
          <CustomButton type="normal" onClick={() => prevBtnData?.action()}>{prevLabel}</CustomButton>
        </div>
        <div>
          <CustomButton type="normal" onClick={() => nextBtnData?.action()} disabled={nextBtnData?.disable}>
            {nextLabel}
          </CustomButton>
        </div>
      </StepButtons>
    );
  }, [getPrevAction, getNextAction]);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Import file
          <CloseIcon
            onClick={cancelImportHandler}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={450}
      footer={false}
      onCancel={cancelImportHandler}
    >
      <Suspense loading={loading} fallback={<WhiteLoading height={207} />}>
        <>
          {stepsData({ filterLabel, property })[step].content}
        </>
        <Footer style={FooterContainerStyles}>
          {renderControls()}
        </Footer>
      </Suspense>
    </Modal>
  );
};

export default ImportSearchFilterModal;
