export const addSeparators = (number, separator = ',') => {
  const rx = /(\d+)(\d{3})/;
  return String(number).replace(/^\d+/, (w) => {
    let copyW = w;
    while (rx.test(copyW)) {
      copyW = copyW.replace(rx, `$1${separator}$2`);
    }
    return copyW;
  });
};
