import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Button } from 'components/common';
import { validateEmail } from 'helpers/validate';
import { emailResetPassword } from 'data/actions/user';
import { resetPasswordPageLoadingSelector } from 'data/selectors/loading';
import AuthContainer from '../AuthContainer';
import EnterPasswordPage from './EnterPasswordPage';
import {
  formBtnStyle,
  Header,
  AuthForm,
  SubHeader,
} from '../styles';
import { HeaderWrapper } from '../signUp/styles';

const PasswordResetPage = ({ match, history, emailRstValue }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(emailRstValue);
  const [error, setError] = useState();
  const [wasSubmitPressed, setWasSubmitPressed] = useState(false);

  const loading = useSelector(resetPasswordPageLoadingSelector);

  const token = match?.params?.token || false;

  const onSuccess = () => history.push({ pathname: '/reset-letter', state: { email } });
  const onFailed = (data) => {
    const status = data?.response?.status;
    if (status === 400) return setError('Incorrect email');
    if (status === 404) return setError('User not found');
    return setError('Something went wrong');
  };

  const sendResetLink = () => {
    setWasSubmitPressed(true);
    if (!error) {
      dispatch(emailResetPassword({ email, onSuccess, onFailed }));
    }
  };

  const onChange = (value) => {
    if (!validateEmail(value)) {
      setError('Incorrect email');
    } else {
      setError('');
    }
    setEmail(value);
  };

  return (
    <AuthContainer>
      {token
        ? <EnterPasswordPage token={token} />
        : (
          <AuthForm>
            <HeaderWrapper>
              <Header> Forgot your password? </Header>
              <SubHeader>
                <div> No worries! Enter your email below and</div>
                <p>we&apos;ll send you instructions in email </p>
              </SubHeader>
            </HeaderWrapper>
            <div>
              <Input
                label="Email address"
                type="text"
                suffix={<Icon type="mail" />}
                name="email"
                placeholder="name@company.com"
                value={email}
                onChange={(e) => onChange(e.target.value.trim())}
                error={wasSubmitPressed ? error : ''}
                autoFocus
              />
              <Button
                type="primary"
                style={{ ...formBtnStyle, marginTop: 20 }}
                onClick={sendResetLink}
                loading={loading}
              >
                Send reset link
              </Button>
              <Button
                type="normal"
                style={formBtnStyle}
              >
                <Link to="/login">Back to sign in</Link>
              </Button>
            </div>
          </AuthForm>
        )}
    </AuthContainer>
  );
};

export default PasswordResetPage;
