import * as Fingerprint2 from 'fingerprintjs2';
import * as UAParser from 'ua-parser-js';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

export const getFingerPrint = async () => {
  const options = {
    excludes: {
      plugins: true,
      localStorage: true,
      adBlock: true,
      screenResolution: true,
      availableScreenResolution: true,
      enumerateDevices: true,
      pixelRatio: true,
      doNotTrack: true,
    },
    preprocessor: (key, value) => {
      if (key === 'userAgent') {
        const parser = new UAParser(value);
        return `${parser.getOS().name} :: ${parser.getBrowser().name} :: ${parser.getEngine().name}`;
      }
      return value;
    },
  };

  try {
    const components = await Fingerprint2.getPromise(options);
    const values = components.map((component) => component.value);
    return String(Fingerprint2.x64hash128(values.join(''), 31));
  } catch (e) {
    return false;
  }
};

export const getFingerPrintPro = () => new Promise((resolve) => {
  if (process.env.NODE_ENV !== 'development') {
    FingerprintJS.load({ token: process.env.REACT_APP_FINGERPRINT_TOKEN, endpoint: 'https://fingerprint.getprospect.com' })
      .then((fp) => fp.get())
      .then((result) => resolve(result.visitorId))
      .catch((e) => {
        console.log('Fingerprint error', e);
        resolve(null);
      });
  } else {
    getFingerPrint()
      .then((hash) => resolve(hash));
  }
});
