import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { changeStatusSequence } from 'data/actions/sequences';
import { SEQUENCE_STATUS_TYPE } from 'data/types/sequences.types';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { Container } from '../deleteStep/styles';
import {
  ModalTitle, CloseIcon, Footer,
} from '../baseModal/styles';

const LaunchSequenceSimpleModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { sequenceName, sequenceId } = useSelector(modalOptionsSelector);

  const handleClose = () => dispatch(toggleModal('launch_sequence_simple', false));
  const handleLaunch = () => {
    dispatch(changeStatusSequence({
      sequenceId,
      status: SEQUENCE_STATUS_TYPE.ACTIVE,
      onSuccess: handleClose,
    }));
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Launch sequence
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={420}
      footer={false}
      onCancel={handleClose}
    >
      <Container>
        <div>
          <span>
            Do you want to launch the sequence“
          </span>
          <strong>{sequenceName}</strong>
          ”?
        </div>
      </Container>
      <Footer>
        <Button
          data-form="true"
          type="primary"
          size="large"
          onClick={handleLaunch}
        >
          Launch
        </Button>
        <Button
          size="large"
          type="normal"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Footer>
    </Modal>
  );
};

export default LaunchSequenceSimpleModal;
