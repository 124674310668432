import { Popover } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { COLORS } from 'constants/styles';
import AddFilter from 'components/addFilter';
import { Tag, Icon } from 'components/common';
import { stopPropagation } from 'helpers/dom';
import { toggleModal } from 'data/actions/modals';
import { PanelHeader } from 'components/step/styles';
import { customizeEmailSelector } from 'data/selectors/sequences';
import { SEQUENCE_DRAFT_STATUS } from 'data/types/sequences.types';
import useShowPauseWarningIfNeeded from 'hooks/sequences/useShowPauseWarningIfNeeded';
import {
  addStepVersion,
  copyStepVersion,
} from 'data/actions/sequences';
import {
  VersionsContainer,
  HeaderWrapper,
  VersionContainer,
  VersionActionsContainer,
  DeleteVersionContainer,
  VersionMenuItem,
} from './styles';

const StepVersions = ({
  stepId,
  activeVersion,
  versions,
  readOnly,
  onSelect,
  marginSides = true,
}) => {
  const dispatch = useDispatch();
  const { tab } = useParams();

  const { editMode } = useSelector(customizeEmailSelector);
  const showPauseWarningIfNeeded = useShowPauseWarningIfNeeded();

  const [visibleMenu, setVisibleMenu] = useState(false);

  const versionLetters = Object.keys(versions);
  const canEdit = !readOnly && (tab !== SEQUENCE_DRAFT_STATUS.REVIEW || editMode);

  const handleCloseMenu = (callback) => (...params) => {
    setVisibleMenu(false);
    callback(...params);
  };

  const handleSelect = handleCloseMenu((letter) => {
    onSelect(stepId, letter);
  });

  const handleDelete = handleCloseMenu(showPauseWarningIfNeeded((letter) => {
    dispatch(toggleModal('delete_step_version', true, { stepId, letter }));
  }));

  const handleAdd = handleCloseMenu(showPauseWarningIfNeeded(() => {
    dispatch(addStepVersion(stepId));
  }));

  const handleCopy = handleCloseMenu(showPauseWarningIfNeeded(() => {
    dispatch(copyStepVersion(stepId, versionLetters[versionLetters.length - 1]));
  }));

  return (
    <VersionsContainer style={{ paddingTop: '30px' }} marginSides={marginSides}>
      <HeaderWrapper>
        <PanelHeader>Versions</PanelHeader>
      </HeaderWrapper>
      <VersionActionsContainer>
        <div>
          {
            versionLetters.map((letter) => (
              <Tag
                key={`step-version-${letter}`}
                style={{ cursor: 'pointer' }}
                type={letter === activeVersion ? 'primary' : 'default'}
                onClick={() => handleSelect(letter)}
              >
                <VersionContainer>
                  <span>
                    Version
                    {' '}
                    {letter}
                  </span>
                  {
                    letter !== 'A' && versionLetters.length > 1 && canEdit && (
                      <DeleteVersionContainer onClick={stopPropagation(() => handleDelete(letter))}>
                        <Icon size={15} fill={COLORS.GREY_G14} type="ic-close" />
                      </DeleteVersionContainer>
                    )
                  }
                </VersionContainer>
              </Tag>
            ))
          }
        </div>
        {
          Object.keys(versions).length < 4 && canEdit && (
            <Popover
              placement="bottomLeft"
              visible={visibleMenu}
              onVisibleChange={setVisibleMenu}
              trigger="click"
              overlayStyle={{ width: '112px' }}
              arrowPointAtCenter={false}
              autoAdjustOverflow={false}
              overlayClassName="hide-popover-arrow versions-inner"
              content={[
                <VersionMenuItem key="add_new" onClick={handleAdd}>Add new</VersionMenuItem>,
                <VersionMenuItem key="duplicate" onClick={handleCopy}>Duplicate</VersionMenuItem>,
              ]}
            >
              <AddFilter
                style={{
                  width: 24, height: 24, display: 'flex', justifyContent: 'center',
                }}
                hasGrayHover
                label=""
              />
            </Popover>
          )
        }
      </VersionActionsContainer>
    </VersionsContainer>
  );
};

export default StepVersions;
