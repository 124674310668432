import { EMAIL_FILTER_STATUS } from 'data/types/search.filters.types';
import { uniqBy } from 'lodash';

export const validateSearchFilters = (filters, validOnly) => {
  if (!filters.find((filter) => filter.property === 'email')) {
    filters.push({
      property: 'email',
      included: {
        operator: 'EMAIL_STATUS',
        value: [validOnly ? EMAIL_FILTER_STATUS.VALID : EMAIL_FILTER_STATUS.ALL],
      },
    });
  }
  return uniqBy(filters, 'property');
};
