import React from 'react';
import { Card } from 'antd';
import get from 'lodash/get';
import { htmlToText } from 'html-to-text';
import capitalize from 'lodash/capitalize';
import { useSelector, useDispatch } from 'react-redux';

import Loader from 'components/loading';
import { Icon } from 'components/common';
import * as CONSTANTS from 'constants/styles';
import { dateFormat, FORMAT_FOR_TODAY_ONLY_TIME } from 'helpers/dateFormat';
import { toggleModal } from 'data/actions/modals';
import { sortDatasetByMonths } from 'helpers/arrays';
import { TYPE } from 'data/types/storeTriggers.types';
import useWorkspacePath from 'hooks/useWorkspacePath';
import { EmptySection } from 'components/emptySection/emptySection';
import { notificationListLoadingSelector } from 'data/selectors/loading';
import { modalsSelector } from 'data/selectors/modals';
import { getAllNotifications, updateNotifications } from 'data/actions/notifications';
import { metaNotificationsSelector, notificationsSelector } from 'data/selectors/notifications';
import {
  NotificationContainer,
  Container,
  NotificationContent,
  DrawerStyle,
  Circle,
  NotificationType,
  DrawerWrapper,
  Data,
  Divider,
  CreatedDate,
  NotificationHeader,
  ButtonShowMore,
  Drawer,
  NotificationWrapper,
  Dot,
  CountItem,
} from './styles';
import {
  CloseIcon,
  HeaderStyle,
  ModalTitle,
} from '../../modals/baseModal/styles';

const NotificationBar = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(notificationsSelector);
  const loading = useSelector(notificationListLoadingSelector);
  const { totalPages, page, unreadCount } = useSelector(metaNotificationsSelector);
  const { visible } = useSelector(modalsSelector);
  const getWorkspacePath = useWorkspacePath();
  const sortedNotifications = sortDatasetByMonths(notifications, 'sendDate');
  const sortedNotificationsKeys = Object.keys(sortedNotifications);

  const close = () => {
    dispatch(toggleModal('show_notifications_preview', false));
    dispatch(updateNotifications(notifications));
  };

  const renderNotificationContent = (notification) => {
    const {
      type, title, content, sendDate, read,
    } = { ...notification };
    let icon = '';
    const text = htmlToText(get(content, 'content.text', ''));
    const ref = get(content, 'content.ref');
    const header = get(content, 'header', title);

    switch (type) {
      case 'import':
      case 'export':
      case 'filtered verification': {
        icon = 'ic-cloud-download';
        break;
      }
      case 'assign': {
        icon = 'ic-edit';
        break;
      }
      case 'find emails':
      case 'emails verification':
      case 'enrich':
      case 'search':
      case 'reminder': {
        icon = 'ic-event-note';
        break;
      }
      case 'permanent delete':
      case 'delete': {
        icon = 'ic-delete';
        break;
      }
      case 'delete from static list':
      case 'move to static list':
      case 'add to static list':
      case 'add to list': {
        icon = 'ic-list';
        break;
      }
      case 'join team invitation':
      case 'reset credits':
      case 'update': {
        icon = 'ic-check-circle';
        break;
      }
      case 'restore': {
        icon = 'ic-restore';
        break;
      }
      case 'blocked': {
        icon = 'ic-block';
        break;
      }
      case 'invoice': {
        icon = 'ic-receipt';
        break;
      }
      case 'password reset': {
        icon = 'ic-lock-open';
        break;
      }
      case 'send new email': {
        icon = 'ic-email';
        break;
      }
      case 'refund': {
        icon = 'ic-assignment-return';
        break;
      }
      case 'cancel subscription': {
        icon = 'ic-cancel';
        break;
      }
      case 'allow payment': {
        icon = 'ic-monetization-on';
        break;
      }
      case 'card expire': {
        icon = 'ic-credit-card';
        break;
      }
      case 'reached quota': {
        icon = 'ic-report';
        break;
      }
      case 'integration':
      case 'almost reached quota': {
        icon = 'ic-info';
        break;
      }
      case 'integration error':
      case 'payment error': {
        icon = 'ic-error';
        break;
      }
      default: {
        icon = 'ic-check-circle';
        break;
      }
    }

    const makeTypeFriendly = () => {
      switch (type) {
        case TYPE.ADD_TO_STATIC_LIST:
          return type.replace('add', 'copy').replace('static', '');
        case TYPE.DELETE_FROM_STATIC_LIST:
        case TYPE.MOVE_TO_STATIC_LIST:
          return type.replace('static', '');
        default:
          return type;
      }
    };

    const notificationOnClick = (link) => {
      switch (link.type) {
        case 'link':
          window.open(link.url, '_blank');
          break;
        case 'react-link':
          dispatch(toggleModal('show_notifications_preview', false));
          window.location.pathname = getWorkspacePath(link.url);
          break;
        case 'file-link':
          window.open(link.url, '_self');
          break;
        default:
          break;
      }

      if (!notification.read) {
        dispatch(updateNotifications([notification]));
      }
    };

    return (
      <NotificationContainer isLink={!!ref} onClick={() => ref && notificationOnClick(ref)}>
        <Circle>
          <Icon type={icon} size="smallest" fill="primary" />
        </Circle>
        <NotificationContent>
          <NotificationHeader>
            <NotificationType>
              {header ?? capitalize(makeTypeFriendly())}
            </NotificationType>
            <div>
              <CreatedDate>
                { dateFormat(sendDate, true, FORMAT_FOR_TODAY_ONLY_TIME) }
              </CreatedDate>
              {!read && <Dot />}
            </div>
          </NotificationHeader>
          <Data>
            <div>
              <span>
                {text}
              </span>
            </div>
          </Data>
        </NotificationContent>
      </NotificationContainer>
    );
  };

  return (
    <DrawerWrapper>
      <Drawer
        title={(
          <ModalTitle>
            Notifications
            {unreadCount !== 0 && <CountItem>{unreadCount}</CountItem>}
            <CloseIcon
              data-close="true"
              onClick={() => close()}
              className="ic_close"
            />
          </ModalTitle>
        )}
        placement="right"
        closable={false}
        onClose={() => close()}
        headerStyle={HeaderStyle}
        bodyStyle={DrawerStyle}
        visible={visible}
        width={CONSTANTS.DRAWER_WIDTH}
      >
        <Container>
          {sortedNotificationsKeys?.length ? (
            <>
              {sortedNotificationsKeys.map((key, index) => (
                <Card
                  type="inner"
                  key={`notification-card-${key}`}
                >
                  { sortedNotifications[key].map((notification, i) => (
                    <NotificationWrapper key={`notification-item-${notification._id}`}>
                      <>
                        { renderNotificationContent(notification) }
                      </>
                      {index === (sortedNotificationsKeys.length - 1) && (sortedNotifications[key].length - 1) === i ? null : <Divider />}
                    </NotificationWrapper>
                  )) }
                </Card>
              ))}
              {
                (totalPages !== page && totalPages !== 0) && (
                  <ButtonShowMore data-form="true" type="primary" onClick={() => dispatch(getAllNotifications({ page: page + 1 }))}>
                    <span>Show more</span>
                    <Icon type="ic-expand-more" className="ic_add" />
                    <Loader loading={loading} />
                  </ButtonShowMore>
                )
              }
            </>
          ) : (
            <EmptySection
              style={{
                margin: 0, boxShadow: 'none', border: 'none', height: '100%',
              }}
              text="You'll find right here notifications about imports, bulk operations, payments and etc."
              header="There aren't any notifications"
            />
          )}
        </Container>
      </Drawer>
    </DrawerWrapper>
  );
};

export default NotificationBar;
