export const EVENTS_DATA = 'events.data';
export const CANCEL_REQUESTS = 'events.cancel_requests';
export const CLOSE_EVENT_SOURCE_CONNECTION = 'events.close_event_source_connection';
export const REFRESH_TOKEN_PROCESS_DONE = 'events.refresh_token_proccess_done';
export const FASTSPRING_ERROR_EVENT = 'events.fastspring.error';
export const FASTSPRING_POPUP_CLOSE_EVENT = 'events.fastspring.popup.close';

export const ACCOUNT_EVENTS = Object.freeze({
  VERIFICATION_EVENT: 'VERIFICATION_EVENT',
  SUBSCRIPTION_EVENT: 'SUBSCRIPTION_EVENT',
  SUBSCRIPTION_FAST_SPRING_EVENT: 'SUBSCRIPTION_FAST_SPRING_EVENT',
  SUBSCRIPTION_ONLY_EVENT: 'SUBSCRIPTION_ONLY_EVENT',
  DISCONNECTION_EVENT: 'DISCONNECTION_EVENT',
  NOTIFICATION_EVENT: 'NOTIFICATION_EVENT',
  NEW_CONTACT_EVENT: 'NEW_CONTACT_EVENT',
  NEW_COMPANY_EVENT: 'NEW_COMPANY_EVENT',
  IMPORT_EVENT: 'IMPORT_EVENT',
  CREDITS_EVENT: 'CREDITS_EVENT',
  CONTACT_EMAIL_STATUS_EVENT: 'CONTACT_EMAIL_STATUS_EVENT',
  EMAIL_STATUS_EVENT: 'EMAIL_STATUS_EVENT',
  ENRICHMENT_CONTACT_EVENT: 'ENRICHMENT_CONTACT_EVENT',
  ENRICHMENT_COMPANY_EVENT: 'ENRICHMENT_COMPANY_EVENT',
  SEARCHES_EVENT: 'SEARCHES_EVENT',
  ALLOW_PAYMENT_EVENT: 'ALLOW_PAYMENT_EVENT',
  VERIFICATION_FILE_EVENT: 'VERIFICATION_FILE_EVENT',
  RECENT_LIST_SIZE_EVENT: 'RECENT_LIST_SIZE_EVENT',
  FAST_SPRING_VERIFICATIONS_EVENT: 'FAST_SPRING_VERIFICATIONS_EVENT',
  UPDATE_INTEGRATION_CONNECTION_EVENT: 'UPDATE_INTEGRATION_CONNECTION_EVENT',
  UPDATE_INTEGRATION_TASK_EVENT: 'UPDATE_INTEGRATION_TASK_EVENT',
});
