import styled from 'styled-components';

import { Icon } from 'components/common';
import { COLORS } from 'constants/styles';
import {
  Footer as BaseFooter,
} from '../../createPropertyBar/styles';

export const Wrapper = styled.div`
  margin-top: 25px;
`;

export const CreateTemplateWrapper = styled.div`
  margin: 0 25px;
  margin-bottom: 15px;
`;

export const CreateTemplateContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IconAdd = styled(Icon)`
  height: 22px;
  width: 22px;
`;

export const TemplateText = styled.span`
  height: 20px;
  font-weight: 600;
  font-size: 14px;
  color: ${COLORS.GREY_G1};
`;

export const CreateTemplateText = styled.span`
  height: 22px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.25px;
  color: ${COLORS.PRIMARY};
`;

export const FilterTemplateWrapper = styled.div`
  margin: 0 25px;
  margin-bottom: ${(props) => (props.marginBottom || 10)}px;
`;

export const TemplateList = styled.div`
  height: calc(100vh - 52px - 81px - 200px);
  padding: 25px;
  border-radius: 2px;
  border: 1px solid ${COLORS.GREY_G4};
  background: ${COLORS.GREY_G5};

  & .ant-radio-group {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
`;

export const IconContainer = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 28px;
  height: 28px;
  background: #F7F8FA;
  border-radius: 2px;
`;

export const TemplateItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border: ${(props) => (props.active ? `1.5px solid ${COLORS.PRIMARY}` : `1.5px solid ${COLORS.GREY_G4}`)};
  height: 60px;
  background-color: ${COLORS.WHITE};
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 5px;

  &:hover {
    border: 1.5px solid ${COLORS.PRIMARY};
    ${IconContainer} {
      display: flex;
    }
  }
`;

export const RadioWrapper = styled.div`
  line-height: 35px;
`;

export const TitleItemText = styled.span`
  height: 20px;
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.GREY_G1};
`;

export const InfoItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoItemText = styled.span`
  height: 20px;
  font-weight: 500;
  font-size: 12px;
  color: ${COLORS.GREY_G2};
`;

export const Footer = styled(BaseFooter)``;

export const ButtonWrapper = styled.div`
  margin-right: 10px;
`;

export const CreateNewTemplateStyles = {
  fontSize: 13.5,
};
