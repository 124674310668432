const initialState = {
  name: '',
  step: 0,
  options: {},
  retryStep: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_TOUR': {
      return {
        ...state,
        ...action.data,
      };
    }

    case 'SIGN_OUT': return initialState;

    default: return state;
  }
};
