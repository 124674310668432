import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  accountSelector,
  isAuthSelector,
  userCreditsSelector,
  userCreditsVerificationSelector,
  workspaceIdSelector,
} from 'data/selectors/user';

const useIntercom = (isDemoUser) => {
  const isAuth = useSelector(isAuthSelector);
  const { total: totalSearch, current: currentSearch } = useSelector(userCreditsSelector);
  const { total: totalVerifications, current: currentVerifications } = useSelector(userCreditsVerificationSelector);
  const {
    _id,
    userId,
    firstName,
    lastName,
    email,
    createdAt,
    timezone,
    riskScore,
    userIntercomHash,
    linkedInProfileHash,
    emailVerified,
    profilePhoto,
    phone,
  } = useSelector(accountSelector);
  const workspaceId = useSelector(workspaceIdSelector);
  const path = window.location.pathname;

  const isRegisteredUser = useCallback(
    () => process.env.NODE_ENV !== 'development' && window.Intercom && isAuth && !localStorage.getItem('adminTempSession') && emailVerified,
    [emailVerified, isAuth],
  );
  const userData = {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    user_id: userId || _id,
    workspaceId,
    name: `${firstName ?? ''} ${lastName ?? ''}`.trim(),
    email,
    created_at: createdAt,
    user_hash: userIntercomHash,
    EmailSearchEmails: `${totalSearch - currentSearch}`,
    EmailSearchLimit: `${totalSearch}`,
    EmailVerificationEmails: `${totalVerifications - currentVerifications}`,
    EmailVerificationLimit: `${totalVerifications}`,
    Timezone: timezone,
    'Plugin Used': linkedInProfileHash !== 0,
    'Risk Score': riskScore,
    avatar: {
      type: 'avatar',
      image_url: profilePhoto || '',
    },
    phone,
    gp_group: localStorage.getItem('GP_group'),
    Emails: totalSearch - currentSearch,
    Limit: totalSearch,
  };

  useEffect(() => {
    if (!isDemoUser) {
      const isTempAdminSession = localStorage.getItem('adminTempSession');

      if (window.Intercom && !isTempAdminSession) {
        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        });
      }
    }
  }, [isDemoUser]);

  useEffect(() => {
    if (isRegisteredUser() && !isDemoUser) {
      const intercomNewData = { 'Last Viewed': path };
      window.Intercom('update', intercomNewData);
    }
  }, [path, isRegisteredUser, isDemoUser]);

  useEffect(() => {
    if (isRegisteredUser() && userIntercomHash && !isDemoUser) {
      window.Intercom('update', userData);
    }
  }, [userData, isRegisteredUser, userIntercomHash, isDemoUser]);

  return true;
};

export default useIntercom;
