import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setBulkInProgress } from 'data/actions/verifications';

const useInitBulkVerification = (bulkId) => {
  const dispatch = useDispatch();

  const executeBulkVerification = useCallback(() => {
    dispatch(setBulkInProgress(bulkId, true));
  }, [bulkId, dispatch]);

  return { executeBulkVerification };
};

export default useInitBulkVerification;
