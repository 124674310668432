import React from 'react';
import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/loading';
import { Icon } from 'components/common';
import { COLORS } from 'constants/styles';
import { formatBytes } from 'helpers/calcHelpers';
import { toggleMessage } from 'data/actions/message';
import { updateLoading } from 'data/actions/loading';
import { uploadImageToCDN } from 'data/actions/common';
import { workspaceIdSelector } from 'data/selectors/user';
import { uploadImagesToCDNLoaderSelector } from 'data/selectors/loading';
import useShowPauseWarningIfNeeded from 'hooks/sequences/useShowPauseWarningIfNeeded';
import { MAXIMUM_FILES_FOR_STEP, MAXIMUM_MEGABYTES_FOR_FILE } from 'data/types/sequences.types';
import {
  ActionButton,
} from '../../styles';

const BaseAttachFile = ({
  countFiles,
  loading,
  prefix,
  onUploadFiles,
}) => {
  const dispatch = useDispatch();

  const workspaceId = useSelector(workspaceIdSelector);
  const loadingFiles = useSelector(uploadImagesToCDNLoaderSelector);

  const showPauseWarningIfNeeded = useShowPauseWarningIfNeeded();

  const isDisabled = countFiles >= MAXIMUM_FILES_FOR_STEP;

  const handleAttachFile = showPauseWarningIfNeeded(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', '');
    input.onchange = async (inputEvent) => {
      dispatch(updateLoading('uploadImagesToCDNLoader', true));
      const { files: uploadedFiles } = inputEvent.target;

      let willBeUploadedFiles = [...uploadedFiles].filter(({ name, size }) => {
        if (size > MAXIMUM_MEGABYTES_FOR_FILE) {
          dispatch(toggleMessage('warning', {
            header: (
              <div>
                <span>
                  File
                </span>
                &nbsp;
                <strong>
                  {name}
                </strong>
                &nbsp;
                <span>
                  won&lsquo;t be uploaded because its size
                </span>
                <strong>
                  {`(${formatBytes(size)})`}
                </strong>
                &nbsp;
                <span>
                  is greater than
                </span>
                &nbsp;
                <strong>
                  {formatBytes(MAXIMUM_MEGABYTES_FOR_FILE, 0)}
                </strong>
              </div>
            ),
          }));
          return false;
        }
        return true;
      });

      if ((willBeUploadedFiles.length + countFiles) > MAXIMUM_FILES_FOR_STEP) {
        const newWillBeUploadedFiles = willBeUploadedFiles.slice(0, MAXIMUM_FILES_FOR_STEP - countFiles);
        const wontBeUploadedFiles = willBeUploadedFiles.slice(MAXIMUM_FILES_FOR_STEP - countFiles);
        willBeUploadedFiles = newWillBeUploadedFiles;
        dispatch(toggleMessage('warning', {
          header: `Files ${wontBeUploadedFiles.map(({ name }) => name).join(', ')} won't be uploaded because of the limit ${MAXIMUM_FILES_FOR_STEP} files for a step`,
        }));
      }

      if (willBeUploadedFiles.length === 0) {
        dispatch(updateLoading('uploadImagesToCDNLoader', false));
        dispatch(toggleMessage('error', { header: 'No one file has been uploaded' }));
        return;
      }

      const promises = await Promise.allSettled(willBeUploadedFiles.map((file) => new Promise((res, rej) => {
        dispatch(uploadImageToCDN({
          file: {
            data: new Blob([file], { type: file.type }),
            meta: file,
          },
          prefix: `${workspaceId}/${prefix}`,
          onSuccess: ({ fields }) => {
            const newFile = {
              fileType: file.type,
              name: file.name,
              url: `${process.env.REACT_APP_CDN_URL}/${fields.Key}`,
              size: file.size,
            };
            res(newFile);
          },
          onFailed: () => rej(new Error(`File ${file.name} hasn't been uploaded. Something went wrong`)),
        }));
      })));

      const newFiles = [];

      promises.forEach((promise) => {
        if (promise.status === 'rejected') {
          dispatch(toggleMessage('error', { header: promise.reason.message }));
        } else {
          newFiles.push(promise.value);
        }
      });

      if (newFiles.length === 0) {
        dispatch(toggleMessage('error', { header: 'No one file has been uploaded' }));
      } else {
        onUploadFiles(newFiles);
      }
      dispatch(updateLoading('uploadImagesToCDNLoader', false));
    };
    input.click();
  });

  return (
    <Tooltip trigger="hover" placement="top" title={isDisabled ? `Count files is limited to ${MAXIMUM_FILES_FOR_STEP}` : 'Attach file'}>
      <div>
        <ActionButton loading={(loading || loadingFiles) ? 'true' : ''} disabled={isDisabled} onClick={handleAttachFile}>
          <Loader loading={loading || loadingFiles} />
          <Icon fill={COLORS.GREY_G1} size="smallest" type="ic-attach-file" />
        </ActionButton>
      </div>
    </Tooltip>
  );
};

export default BaseAttachFile;
