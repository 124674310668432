export default (url, name) => {
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.download = name;
  tempLink.href = url;

  document.body.appendChild(tempLink);
  tempLink.click();

  setTimeout(() => {
    document.body.removeChild(tempLink);
  }, 200);
};
