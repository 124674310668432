import React from 'react';

import { Icon } from 'components/common';
import addGrey from 'assets/images/ic-add-grey.svg';
import { AddFilter as AddFilterStyle } from './styles';

const AddFilter = ({
  icon,
  hasPlus = true,
  label = 'Add filter',
  ...props
}) => (
  <AddFilterStyle
    // eslint-disable-next-line
    {...props}
  >
    {
      icon && <Icon size={20} style={{ marginRight: 5 }} type={icon} />
    }
    <span>{label}</span>
    {
      hasPlus && <img src={addGrey} alt="" className="ic_add" />
    }
  </AddFilterStyle>
);

export default AddFilter;
