import styled, { keyframes } from 'styled-components';

import { COLORS } from 'constants/styles';

const getBackgroundAnimation = (backgroundColor1, backgroundColor2) => keyframes`
  from {
    background-color: ${backgroundColor1};
  }
  to {
    background-color: ${backgroundColor2};
  }
`;

export const ImagesContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

export const Point = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;

  &:not(:last-child) {
    margin-right: 6px;
  }

  &:nth-child(odd) {
    animation-name: ${getBackgroundAnimation(COLORS.GREY_G14, COLORS.GREY_G4)};
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &:nth-child(even) {
    animation-name: ${getBackgroundAnimation(COLORS.GREY_G4, COLORS.GREY_G14)};
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;

export const PointsContainer = styled.div`
  margin: 0 8px;
  display: flex;
  align-items: center;
`;
