import styled, { keyframes } from 'styled-components';
import { slideInDown } from 'react-animations';

import { Icon } from 'components/common';
import * as CONSTANTS from 'constants/styles';

const slideInDownFrame = keyframes`${slideInDown}`;

export const MessageContainer = styled.div`
  display: flex;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  flex-direction: column;
  justify-content: center;
  transition: all .5s ease-in-out;
  margin-top: 30px;
  right: 30px;
  width: 400px;
  height: ${(props) => (!props.visible ? '0px' : 'fit-content')};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: ${CONSTANTS.COLORS.WHITE};
  z-index: 9999;
  animation: .5s ${slideInDownFrame};
  transform-origin: top;

  .ant-progress {
    height: 5px;
  }

  .ant-progress-inner {
    margin-top: -15px;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`;

export const MessageTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 15px 10px;
  font-size: 14px;
  border: 4px;
`;

export const MessageHeaderWrapper = styled.div`
  font-size: 18px;
  font-weight: 600;
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`;

export const MessageButtonWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
`;

export const MessageContentWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  margin-top: 15px;
`;

export const MessageAction = styled.span`
  cursor: pointer;
  text-decoration: underline;
  margin-top: 5px;
`;

export const MessageHeader = styled.span`
  max-width: 85%;
  overflow-wrap: break-word;
`;

export const CloseMessageImg = styled(Icon).attrs(() => ({
  type: 'ic-close',
  size: 'small',
  fill: CONSTANTS.COLORS.BLACK_WITH_OPACITY_B2,
}))`
  cursor: pointer;
  right: 16px;
  margin-left: auto;
  margin-top: 4px;
`;
