import styled from 'styled-components';
import { COLORS, MOBILE_MAX_WIDTH } from 'constants/styles';

export const RatingsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
  width: 530px;
  z-index: 2;

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    width: 100%;
  }
`;

export const ImageCompanyRating = styled.img`
  width: ${(props) => (props.widthRatingCompany ?? '130px')};
  height: 30px;

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    height: 25px;
    width: ${(props) => props.mobileWidthRatingCompany};
  }
`;

export const StarsContainer = styled.div`
  margin-top: 6px;
`;

export const RatingsText = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${COLORS.DARKEST_BLUE};
`;

export const ReviewsText = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${COLORS.DARKEST_BLUE};
  opacity: 0.5;
`;
