import React from 'react';
import {
  FileName, UploadContainer, UploadIconWrapper, UploadText, UploadTypes,
} from './styles';
import Loader from '../loading';
import Icon from '../common/icon';

const UploadFileLoading = ({ fileName }) => (
  <UploadContainer>
    <div className="file-loading">
      <Loader loading />
      <FileName>{fileName}</FileName>
    </div>
  </UploadContainer>
);

const UploadFileResult = ({ fileName }) => (
  <UploadContainer>
    <div>
      <Icon type="ic-attach-file" size="smallest" style={{ transform: 'rotate(45deg)' }} />
    </div>
    <div>
      <FileName>{fileName}</FileName>
    </div>
  </UploadContainer>
);

const UploadFileBar = ({ renderData }) => (
  <UploadContainer>
    <UploadIconWrapper>
      <Icon style={{ height: 42, width: 42 }} type="ic-import-upload" />
    </UploadIconWrapper>
    {renderData ? renderData() : (
      <>
        <UploadText>
          Drag and drop or click to choose a CSV file for upload.
        </UploadText>
        <UploadTypes>
          Note: file should be in .csv format and include a header row.
        </UploadTypes>
      </>
    )}
  </UploadContainer>
);

export { UploadFileLoading, UploadFileResult, UploadFileBar };
