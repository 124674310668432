import { ACCOUNT_EVENTS } from '../types/event.types';

const initialState = {
  subscription: {},
  redirectAfterFirstPay: false,
  fastSpringData: {},
  cancelSubscription: {},
  selectSubscriptionQuantity: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_CANCEL_SUBSCRIPTION': {
      return {
        ...state,
        cancelSubscription: { ...initialState.cancelSubscription },
      };
    }
    case 'CREATE_QUIT_REPORT_CANCEL_SUBSCRIPTION': {
      if (action.payload) {
        return {
          ...state,
          subscription: action.payload,
        };
      }
      return state;
    }
    case 'SET_CANCEL_SUBSCRIPTION': {
      return {
        ...state,
        cancelSubscription: {
          ...state.cancelSubscription,
          ...action.data,
        },
      };
    }
    case 'GET_SUBSCRIPTION': {
      return {
        ...state,
        subscription: action.payload,
      };
    }
    case 'UPDATE_SUBSCRIPTION': {
      return {
        ...state,
        subscription: action.payload,
      };
    }
    case ACCOUNT_EVENTS.SUBSCRIPTION_FAST_SPRING_EVENT:
    case ACCOUNT_EVENTS.SUBSCRIPTION_ONLY_EVENT:
    case ACCOUNT_EVENTS.SUBSCRIPTION_EVENT: {
      try {
        const data = JSON.parse(action.payload);
        const newState = {
          ...state,
          subscription: data.subscription,
        };
        return newState;
      } catch (err) { break; }
    }
    case 'REDIRECT_AFTER_FIRST_PAY': {
      return {
        ...state,
        redirectAfterFirstPay: action?.data?.redirectStatus ?? initialState.redirectAfterFirstPay,
      };
    }
    case 'UPDATE_SUBSCRIPTION_STATUS': {
      if (action.payload) {
        return {
          ...state,
          subscription: action.payload,
        };
      }
      return state;
    }
    case 'SET_FAST_SPRING_DATA': {
      return {
        ...state,
        fastSpringData: action.data,
      };
    }

    case 'UPDATE_SELECT_SUBSCRIPTION_QUANTITY': {
      return {
        ...state,
        selectSubscriptionQuantity: action.data.value,
      };
    }
    default:
      return state;
  }
  return state;
};
