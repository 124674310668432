import cloneDeep from 'lodash/cloneDeep';
import { SOURCE_TYPE } from 'data/types/source.types';

const initialState = {
  addCompaniesToContact: false,
  addCompanyLoader: false,
  addContactLoader: false,
  accountLoader: true,
  bulkInsightsSearchLoading: false,
  bulkOperationsLoader: true,
  cardsLoading: true,
  companiesCount: true,
  companiesFilters: true,
  companiesLimitLoader: true,
  contactsCount: true,
  contactsFilters: true,
  contactsLists: true,
  contactsListTable: true,
  contactsTable: false,
  createApiKeyLoader: false,
  createUserSearchLoading: false,
  currentCompanyTechnologiesLoader: false,
  deleteContacts: false,
  deletedItemsTable: false,
  deleteBlockedCompanyLoader: false,
  deleteBlockedContactLoader: false,
  emailCheck: false,
  exportDataLoader: false,
  foldersTable: true,
  googleAuth: false,
  insightsSearchLoading: false,
  insightContactLoader: false,
  insightContactLinkedinLoader: false,
  insightContactEmailLoader: false,
  insightCompanyLoader: false,
  insightContactsCountLoader: false,
  invoiceLoader: true,
  listsTable: true,
  listSelect: false,
  listsSizesLoading: false,
  loginPage: false,
  mapContactProperties: false,
  notificationList: false,
  oauth2pageLoading: false,
  premadeSequencesList: true,
  propertiesLoading: false,
  recentLists: true,
  recentListsSizesLoading: false,
  referredUsers: true,
  relation: false,
  resetPasswordPage: false,
  sessions: true,
  sequencesTable: false,
  stepList: true,
  signIn: false,
  signUp: false,
  searchCompanyInsights: false,
  userSettingsLoader: true,
  usersWorkspaces: true,
  workspaceAnalytics: true,
  searchHistoryLoading: false,
  searchLoading: false,
  googleOAuthVerification: false,
  searchContactInsights: false,
  subscriptionsLoader: true,
  searchPreviewLoading: false,
  searchCompanies: false,
  searchDeletedCompanies: false,
  searchContacts: false,
  searchDeletedContacts: false,
  searchDeletedLists: false,
  searchContactLoader: false,
  savedSearchOptionsLoader: false,
  shortSearchesHistoryLoader: true,
  searchTechnologiesLoader: false,
  shortSearchesLoader: true,
  schedulesLoading: true,
  sequenceLeadsLoading: true,
  sequenceEngagedDetailsLoading: true,
  sequenceAudienceDetailsLoading: true,
  sequenceStepStatsLoading: true,
  connectedAccountsLoading: true,
  countActiveCampaignsLoading: true,
  optOutContactsLoading: true,
  unsubscribeSequenceLoading: false,
  unconfirmedPhonePage: false,
  updateSubscription: false,
  usage: true,
  usagePageAnalytics: true,
  userCredits: true,
  userExtensionLoader: true,
  userWorkspacesLoading: true,
  verifyAccountByCodeLoader: false,
  updateSubscriptionStatusLoader: false,
  createQuitReportLoader: false,
  deleteUserLoader: false,
  verifyPhone: false,
  verifyToken: false,
  deleteFilesFromStepLoader: false,
  updateSequenceDraftStatusLoader: false,
  createSequenceLoader: false,
  updateStepRemotelyLoader: false,
  sendTestCampaignLoader: false,
  sumSendingLimitsLoading: false,
  updateSequenceSettingsRemotelyLoader: false,
  getSequenceSentMailsLoader: false,
  uploadImagesToCDNLoader: false,
  enrollmentContactsLoader: false,
  previewContactsLoader: false,
  getTeammatesLoader: true,
  checkGoogleSheetsPluginLoader: true,
  contactsAnalyticsLoader: false,
  createStepTemplateLoader: false,
  populateCompetitorsLoader: false,
  getSequencesByNameLoader: false,
  addContactsToSequenceLoader: false,
  fastSearchInput: true,
  inaccurateFieldLoading: false,
  getContactListsLoading: false,
  getContactSequencesLoading: false,
  createNoteLoader: false,
  getHistoryLoading: true,
  searchPreviewBar: true,
  contactsForceNoResults: false,
  companiesForceNoResults: false,
  hubspotConnectionLoading: false,
  updateHubspotConnectionLoading: false,
  syncHubspotUserLoading: false,
  searchContactListsLoading: false,
  searchContactSequencesLoading: false,
  getCompanyCompetitorsLoading: false,
  getLastThreeInsightContactsLoading: false,
  searchCompanyContactInsights: false,
  searchCompanySavedContactInsights: false,
  getCompanyKeywordsLoading: false,
  updatePreviewBarButton: false,
  salesforceConnectionLoading: false,
  updateSalesforceConnectionLoading: false,
  integrationTaskLoading: true,
  zohoConnectionLoading: false,
  updateZohoConnectionLoading: false,
  syncZohoUserLoading: false,
  syncPipedriveUserLoading: false,
  updatePipedriveConnectionLoading: false,
  lastNspScoreFeedback: true,
  contactCell: false,
  pipedriveConnectionLoading: false,
  sheetsConnectionLoading: false,
  syncSheetsDataLoading: false,
  updateSheetsConnectionLoading: false,
  searchSpreadsheetsLoading: false,
  searchSavedContactColleaguesSidebarLoading: false,
  searchSavedContactColleaguesLoading: false,
  searchContactColleaguesSidebarLoading: false,
  searchContactColleaguesLoading: false,
  getContactSidebarLoader: false,
  updateConnectedAccountLoading: false,
  customAccountVerification: false,
  fastSpringUpdateLoading: false,
};

export default (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'UPDATE_LOADING_COMPONENT': {
      const { components, loading } = action.data;

      components.forEach((item) => {
        state[item] = loading;
      });

      return {
        ...state,
      };
    }

    case 'UPDATE_FORCE_LOADING_NO_RESULT': {
      const property = action?.data?.source === SOURCE_TYPE.CONTACT ? 'contactsForceNoResults' : 'companiesForceNoResults';
      return {
        ...state,
        [property]: action?.data?.loading,
      };
    }

    case 'SIGN_OUT':
    case 'SWITCH_WORKSPACE': {
      return cloneDeep(initialState);
    }

    default:
      return state;
  }
};
