import { combineReducers } from 'redux';
import contacts from './contacts';
import user from './user';
import modals from './modals';
import Import from './import';
import notifications from './notifications';
import properties from './properties';
import companies from './companies';
import lists from './lists';
import search from './search';
import loading from './loading';
import billingCycles from './billingCycles';
import plans from './plans';
import subscriptions from './subscriptions';
import cards from './cards';
import message from './message';
import teammates from './teammates';
import invoices from './invoices';
import sequences from './sequences';
import tour from './tour';
import storeTriggers from './storeTriggers';
import bulkOperations from './bulkOperations';
import enrich from './enrich';
import Export from './export';
import verifications from './verifications';
import integrations from './integrations';
import filters from './filters';
import plugin from './plugin';
import browserHistory from './browserHistory';

export default combineReducers({
  contacts,
  user,
  modals,
  notifications,
  import: Import,
  export: Export,
  properties,
  companies,
  lists,
  search,
  loading,
  billingCycles,
  plans,
  subscriptions,
  cards,
  message,
  teammates,
  invoices,
  sequences,
  tour,
  bulkOperations,
  storeTriggers,
  enrich,
  verifications,
  integrations,
  filters,
  plugin,
  browserHistory,
});
