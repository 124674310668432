import { COLORS } from 'constants/styles';
import styled from 'styled-components';

export const Button = styled.button`
  height: 38px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  cursor: pointer;

  &[disabled] {
    opacity: 0.5;
    svg {
      filter: grayscale(100%);
    }
  }
`;

export const Label = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.BLACK_G1};
`;
