import { Tooltip } from 'antd';
import React, { useState } from 'react';

import isMobile from 'helpers/isMobile';
import { Icon } from 'components/common';
import { IconContainer } from './styles';

const DislikeProperty = ({
  style,
  inaccurate,
  defaultValue,
  handleVisible,
  dislikeProperty,
}) => {
  const [visible, toggleVisible] = useState(false);

  const handleDislikeProperty = () => {
    dislikeProperty({ value: defaultValue });
  };

  const onVisibleChange = (value) => {
    toggleVisible(value);
    if (handleVisible) handleVisible(value);
  };

  return (
    <Tooltip
      placement="bottomRight"
      title={inaccurate ? 'Marked as inaccurate' : 'Flag as inaccurate'}
      visible={visible && !isMobile()}
      onVisibleChange={onVisibleChange}
      align={{
        offset: [0, 5],
      }}
    >
      <IconContainer
        clickable
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleDislikeProperty();
        }}
        style={style}
      >
        <Icon size="small" type="ic-thumb-down" outlined={!inaccurate} fill="primary" />
      </IconContainer>
    </Tooltip>
  );
};

export default DislikeProperty;
