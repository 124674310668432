import { useState, useEffect } from 'react';

let cachedScripts = [];
let createdStorefront;

const useFastSpringScript = (src, attributes) => {
  const [state, setState] = useState({
    error: false,
    loaded: false,
  });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const storefront = attributes['data-storefront'];
    const dropPrevScript = createdStorefront && createdStorefront !== storefront;

    if (dropPrevScript) {
      const oldScript = document.body.querySelector('#fsc-api');

      if (oldScript) {
        oldScript.remove();
        cachedScripts = [];
        createdStorefront = null;
      }
    }

    if (cachedScripts.includes(src)) {
      setState({
        error: false,
        loaded: true,
      });
    } else {
      cachedScripts.push(src);
      createdStorefront = storefront;

      const script = document.createElement('script');
      script.src = src;
      script.async = true;

      if (attributes) {
        const attributeKeys = Object.keys(attributes);
        attributeKeys.forEach((key) => {
          const value = attributes[key];
          if (value) {
            script.setAttribute(key, value);
          }
        });
      }

      const onScriptLoad = () => {
        setState({
          error: false,
          loaded: true,
        });
      };

      const onScriptError = () => {
        const index = cachedScripts.indexOf(src);
        if (index >= 0) {
          cachedScripts.splice(index, 1);
        }
        createdStorefront = null;
        script.remove();

        setState({
          error: true,
          loaded: true,
        });
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      document.body.appendChild(script);

      return () => {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
      };
    }
  },
  [src, attributes['data-storefront']]); // eslint-disable-line

  return [state.loaded, state.error];
};

export default useFastSpringScript;
