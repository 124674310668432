import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';

import { toggleModal } from 'data/actions/modals';
import { cloneSequence } from 'data/actions/sequences';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { Container } from '../deleteStep/styles';
import {
  ModalTitle, CloseIcon, Footer,
} from '../baseModal/styles';

const CloneSequenceModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { sequenceId } = useSelector(modalOptionsSelector);

  const handleClose = () => dispatch(toggleModal('clone_sequence', false));
  const handleClone = () => {
    dispatch(cloneSequence(sequenceId, ({ _id }) => {
      window.location.href = `/sequences/${_id}/steps`;
    }));
    handleClose();
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Clone sequence
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <Container>
        <div>
          You&#39;re about to clone the sequence?
        </div>

      </Container>
      <Footer>
        <Button
          data-form="true"
          type="primary"
          size="large"
          onClick={handleClone}
        >
          Clone
        </Button>
        <Button
          size="large"
          type="normal"
          onClick={handleClose}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default CloneSequenceModal;
