import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import {
  Modal,
} from 'antd';

import {
  Button,
  Select,
  Option,
} from 'components/common';
import { companyPropertiesSelector, selectedCompaniesSelector } from 'data/selectors/companies';
import { editCompaniesProperty } from 'data/actions/companies';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import EditableProperty from '../editableProperty';

import {
  Container,
  Footer,
  InputContainer,
  wide,
} from './styles';

import {
  ModalTitle,
  CloseIcon,
} from '../modals/baseModal/styles';

const EditCompaniesProperty = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { setSelectedRowKeys, excludedRowKeys } = useSelector(modalOptionsSelector);
  const properties = get(useSelector(companyPropertiesSelector), 'data', []).filter((property) => !property.custom);
  const companies = useSelector(selectedCompaniesSelector);

  const [property, updateProperty] = useState(null);
  const [value, updateValue] = useState(null);

  useEffect(() => {
    updateProperty(null);
    updateValue(null);
  }, [visible]);

  useEffect(() => {
    updateValue(null);
  }, [property]);

  const handleClose = () => dispatch(toggleModal('companies_edit_columns', false));

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Bulk edit companies
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <Container>
        <InputContainer>
          <Select
            style={wide}
            defaultValue="Select property to edit"
            onChange={(propertyValue) => {
              updateProperty(properties.find(({ name }) => name === propertyValue));
            }}
          >
            {properties.filter((item) => !item.unique).map((item) => <Option key={item._id} value={item.name}>{ item.label }</Option>)}
          </Select>
        </InputContainer>

        { /* eslint-disable-next-line */ }
        { property && <EditableProperty {...property} value={value} updateValue={updateValue} />}

      </Container>
      <Footer>
        <Button
          data-form={!value ? 'false' : 'true'}
          type="primary"
          disabled={!value}
          onClick={() => {
            dispatch(editCompaniesProperty([{ property: property.name, value }], companies, excludedRowKeys, () => {
              if (setSelectedRowKeys) setSelectedRowKeys([]);
              handleClose();
            }));
          }}
        >
          Save
        </Button>
        <Button type="normal" onClick={handleClose}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default EditCompaniesProperty;
