export const convertInsightToEntity = (insight = {}, properties) => {
  const clonedProperties = properties.reduce((obj, property) => {
    obj[property] = {
      value: insight[property],
    };
    delete insight[property];
    return obj;
  }, {});
  Object.assign(insight, {
    properties: clonedProperties,
    isInsight: true,
  });
  return insight;
};

export const convertInsightToCompany = (insight = {}) => {
  const properties = ['domain', 'name', 'headquarters', 'countryCode', 'postalCode', 'keywords', 'technologies', 'size', 'companyType', 'industry', 'description', 'foundedAt', 'social'];
  convertInsightToEntity(insight, properties);
  return insight;
};

export const convertInsightToContact = (insight = {}) => {
  const properties = ['firstName', 'lastName', 'linkedinUrl', 'location', 'contactInfo', 'countryCode', 'postalCode', 'summary', 'notes'];
  convertInsightToEntity(insight, properties);
  Object.assign(insight, {
    companyRelations: (insight.companies || []).map((relation) => ({
      ...relation,
      company: convertInsightToCompany(relation.company),
    })),
  });
  delete insight.companies;
  return insight;
};

export const formatFileSize = (bytes) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  let unitIndex = 0;
  let fileSize = bytes;

  while (fileSize >= 1024 && unitIndex < units.length - 1) {
    fileSize /= 1024;
    unitIndex += 1;
  }

  return `${fileSize.toFixed(2)} ${units[unitIndex]}`;
};
