import React, { useState } from 'react';
import get from 'lodash/get';
import useResetParams from 'hooks/useResetParams';
import {
  Icon,
  Tooltip,
} from 'antd';
import { addOptionToIncludedFilters, removeOptionFromFilters } from 'data/actions/search';
import {
  FilterTag,
  FilterOperatorTitle,
  TagsContainer,
} from '../../../sidebar/styles';

const ExcludedFilters = ({
  property, options, includedOp, splittable,
}) => {
  const [showTooltip, setShowTooltip] = useState();
  const resetParamsAfterRemoveFilter = useResetParams((element) => removeOptionFromFilters({ property, element }));
  const resetParamsAfterAddFilter = useResetParams((element) => addOptionToIncludedFilters({
    property, includedOp, element, splittable,
  }));

  return (
    <TagsContainer>
      <FilterOperatorTitle>
        Excluded:
      </FilterOperatorTitle>
      {options && ('map' in options)
        ? options.map((item) => {
          const element = get(item, 'printedData', item);
          const filterLabel = get(element, 'label', `${Array.isArray(element) ? element[0] : element}`);
          const isCategory = Array.isArray(get(item, 'matching'));

          const filterTag = (
            <FilterTag
              closable
              type="danger"
              onClose={() => {
                const elementToRemove = isCategory ? { value: get(element, 'value', element), isCategory: true } : get(element, 'value', element);
                resetParamsAfterRemoveFilter(elementToRemove);
              }}
            >
              <p>
                {filterLabel}
              </p>
              <Icon onClick={() => resetParamsAfterAddFilter(get(element, 'value', element))} className="icon-tag" type="plus" />
            </FilterTag>
          );
          const tooltipKey = `excluded_filter_tag_${get(element, 'label', element)}`;

          return (
            <Tooltip
              visible={showTooltip === tooltipKey}
              onVisibleChange={(showed) => {
                if (filterLabel?.length > 31 && showed) {
                  setShowTooltip(tooltipKey);
                } else {
                  setShowTooltip(false);
                }
              }}
              onClick={(e) => e.stopPropagation()}
              placement="right"
              title={filterLabel}
              key={tooltipKey}
            >
              {filterTag}
            </Tooltip>
          );
        }) : <></>}
    </TagsContainer>
  );
};

export default ExcludedFilters;
