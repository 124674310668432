export const getPlans = () => (dispatch) => {
  dispatch({
    type: 'GET_ALL_PLANS',
    payload: {
      endpoint: 'api/v1/plans',
      method: 'GET',
      delayLoad: 0,
    },
    components: ['plansLoader'],
  });
};

export const getBulkPlan = ({ quantity, onSuccess }) => (dispatch) => {
  dispatch({
    type: 'GET_BULK_PLAN',
    payload: {
      endpoint: `api/v1/plans/bulk/${quantity}`,
      method: 'GET',
    },
    onSuccess: (data) => onSuccess(data),
  });
};
