export const CONTACT_LIMITS = Object.freeze({
  MIN_SEARCH_VALUE_LENGTH: 3,
  MAX_REQUEST_RATE: 500,
});

export const COMPANY_LIMITS = Object.freeze({
  MIN_SEARCH_VALUE_LENGTH: 3,
  MAX_REQUEST_RATE: 500,
});

export const SEARCHES_HISTORY_LIMITS = Object.freeze({
  MIN_SEARCH_VALUE_LENGTH: 1,
  MAX_REQUEST_RATE: 500,
});

export const LIST_LIMITS = Object.freeze({
  MIN_SEARCH_VALUE_LENGTH: 1,
  MAX_REQUEST_RATE: 500,
});

export const FILTERS_INPUT_LIMITS = Object.freeze({
  MIN_SEARCH_VALUE_LENGTH: 1,
  MAX_REQUEST_RATE: 500,
});
