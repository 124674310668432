import get from 'lodash/get';
import { Tooltip, Divider } from 'antd';
import { nanoid } from 'nanoid';
import React, { useMemo, useState } from 'react';
import lowerCase from 'lodash/lowerCase';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Loader from 'components/loading';
import { Icon, Button } from 'components/common';
import { dateFormat, FORMAT_FOR_TODAY_ONLY_TIME } from 'helpers/dateFormat';
import { Progress } from 'data/types/progress.types';
import { SEARCH_KIND } from 'constants/searchContent';
import { sortArrayBySpecificNames } from 'helpers/arrays';
import { setSavedSearchFilter } from 'data/actions/search';
import FiltersStructure from 'components/searchFilters/filtersStructure';
import { getSearchIcon } from 'helpers/getSearchIcon';
import SearchHistoryOptions from 'components/listsOptions/searchHistoryOptions';
import SavedSearchOptions from 'components/listsOptions/savedSearchOptions';
import SimpleEllipsisText from 'components/common/simpleEllipsisText';
import { modalOptionsSelector } from 'data/selectors/modals';
import {
  SearchActivityContainer,
  ActivityHeader,
  HeaderIconContainer,
  HeaderTitleContainer,
  HeaderControls,
  NotificationContent,
  ActivityCircle,
  CreatedDate,
  OptionsWrapper,
  ActivityLoadBtn,
} from '../../notificationsPreviewBar/styles';
import {
  SearchHistoryType,
  DividerStyle,
} from '../styles';
import SearchData from './searchData';

const SearchContent = ({ search }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const allFilters = get(search, 'filters', []);
  const iconData = getSearchIcon({ search });
  const { show } = useSelector(modalOptionsSelector);

  const [isShowedOptions, setIsShowedOptions] = useState(false);

  const filterStructures = useMemo(() => FiltersStructure.find((structure) => [search.sourceType, search.source].includes(structure.source)), [search.source, search.sourceType]);
  const filters = useMemo(() => sortArrayBySpecificNames((search.filters || []).map((filter) => {
    const filterStructure = filterStructures.filters.find(({ property }) => property === filter.property);
    return {
      ...filter,
      label: filterStructure?.label,
      count: filterStructure?.count,
      included: filter?.included?.value ? {
        ...filter.included,
        value: filter.included.value.filter(Boolean).map((value) => ({ value, entry: 'included', _id: nanoid() })),
      } : undefined,
      excluded: filter?.excluded?.value ? {
        ...filter.excluded,
        value: filter.excluded.value.filter(Boolean).map((value) => ({ value, entry: 'excluded', _id: nanoid() })),
      } : undefined,
    };
  }).filter((filter) => filterStructures.filters.some(({ property }) => property === filter.property) && filter.property !== 'workspaceRelations' && (filter.included?.value?.length || filter.excluded?.value?.length)), 'property', filterStructures.filters.map((structure) => structure.property)), [search.filters, filterStructures]);

  let tooltip;
  if (search.saved || search.kind === SEARCH_KIND.SEARCH_HISTORY) {
    tooltip = `Search is ${search.status === Progress.DONE ? 'completed' : lowerCase(search.status)}`;
  } else if (search.kind === SEARCH_KIND.USER_SEARCH) {
    tooltip = 'Search is created';
  } else {
    tooltip = 'Search filters are saved';
  }

  const renderOptions = useMemo(() => {
    if (search.kind === SEARCH_KIND.SEARCH_HISTORY) {
      return (
        <SearchHistoryOptions
          name={search.name}
          searchId={search._id}
          filters={search.filters}
          status={search.status}
          sourceType={search.source}
          titleExists={false}
          backModal="searches_preview"
          backOptions={{ show, loadSearches: false }}
          backScrollListId="searches-list"
          setIsShowedOptions={setIsShowedOptions}
          align={{
            offset: ['0px', '0px'],
          }}
        />
      );
    }
    if (search.kind === SEARCH_KIND.SEARCH) {
      return (
        <SavedSearchOptions
          name={search.name}
          savedSearchId={search._id}
          sourceType={search.sourceType}
          titleExists={false}
          backModal="searches_preview"
          backOptions={{ show, loadSearches: false }}
          backScrollListId="searches-list"
          setIsShowedOptions={setIsShowedOptions}
          align={{
            offset: ['0px', '0px'],
          }}
        />
      );
    }

    return (
      <SearchHistoryOptions
        filters={search.filters}
        sourceType={search.source || search.sourceType}
        titleExists={false}
        backModal="searches_preview"
        backOptions={{ show, loadSearches: false }}
        backScrollListId="searches-list"
        setIsShowedOptions={setIsShowedOptions}
        align={{
          offset: ['0px', '0px'],
        }}
      />
    );
  }, [search, show]);

  if (filters.length === 0) return null;
  return (
    <SearchActivityContainer>
      <ActivityHeader>
        <HeaderIconContainer>
          <Tooltip placement="top" title={tooltip}>
            {
            search.status === Progress.IN_PROGRESS ? (
              <div style={{ margin: '0 10px 0 5px' }}>
                <Loader loading />
              </div>
            ) : (
              <ActivityCircle fill={iconData.background}>
                <Icon type={iconData?.icon?.type} size="smallest" fill={iconData?.icon?.fill} />
              </ActivityCircle>
            )
          }
          </Tooltip>
        </HeaderIconContainer>
        <HeaderTitleContainer>
          <SearchHistoryType>
            <SimpleEllipsisText
              className="activity-title"
              title={search?.name}
              showCondition={() => search?.name?.length >= 22}
            >
              {search.name}
            </SimpleEllipsisText>
          </SearchHistoryType>
          <CreatedDate
            isActivity
          >
            {dateFormat(search.createdAt, true, FORMAT_FOR_TODAY_ONLY_TIME)}
          </CreatedDate>
        </HeaderTitleContainer>
        <HeaderControls>
          <Button
            type="normal"
            size="default"
            style={ActivityLoadBtn}
            onClick={() => {
              dispatch(setSavedSearchFilter({
                filters: allFilters, initRecentActivities: true, searchId: search._id,
              }));
              if ([SEARCH_KIND.SEARCH_HISTORY, SEARCH_KIND.USER_SEARCH].includes(search.kind)) {
                history.push(`/search/${search.source || search.sourceType}${search._id ? `/${search._id}` : ''}`);
              } else if (search.kind === SEARCH_KIND.SEARCH) {
                history.push(`/search/${search.sourceType}/${search._id}`);
              }
            }}
          >
            Load Search
          </Button>
          <OptionsWrapper showing={isShowedOptions}>
            {renderOptions}
          </OptionsWrapper>
        </HeaderControls>
      </ActivityHeader>
      <Divider style={DividerStyle} />
      <NotificationContent>
        <SearchData filters={filters} search={search} />
      </NotificationContent>
    </SearchActivityContainer>
  );
};

export default SearchContent;
