import React, { useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
  Select,
} from 'antd';

import { Button, Checkbox } from 'components/common';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { changeWorkspaceAdmin, getUsersWorkspaces } from 'data/actions/user';
import { usersWorkspacesSelector, accountSelector } from 'data/selectors/user';
import { nanoid } from 'nanoid';
import { toggleMessage } from 'data/actions/message';
import {
  CloseIcon,
  ModalTitle,
  Label,
  Footer, WorkspaceUsersSelectStyle,
} from '../baseModal/styles';

const { Option } = Select;

const ChangeWorkspaceAdminModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const modalOptions = useSelector(modalOptionsSelector);
  const usersWorkspaces = useSelector(usersWorkspacesSelector);
  const userAccount = useSelector(accountSelector);
  const workspaceId = get(modalOptions, 'workspaceId', '');
  const workspaceName = get(modalOptions, 'name', workspaceId);
  const { users = [] } = usersWorkspaces.find((usersWorkspace) => usersWorkspace.workspaceId === workspaceId) ?? {};
  const usersWithoutCurrentUser = users.filter((user) => {
    const currentUserEmail = get(userAccount, 'email');
    return user?.email !== currentUserEmail && user?.isConfirmed;
  });
  const [email, setEmail] = useState('');
  const [saveBillingInfo, toggleSaveBillingInfo] = useState(true);

  useEffect(() => {
    if (!usersWorkspaces.length) {
      dispatch(getUsersWorkspaces());
    }
  }, [usersWorkspaces, dispatch]);

  const handleChangeWorkspaceAdmin = useCallback(() => {
    dispatch(toggleModal('change_workspace_admin', false));
    dispatch(changeWorkspaceAdmin({
      workspaceId,
      email,
      saveBillingInfo,
      onSuccess: () => {
        dispatch(toggleMessage('success', { header: `Admin for workspace ${workspaceName} was changed successfully` }));
      },
      onFailed: () => {
        dispatch(toggleMessage('error', { header: 'Failed to change the admin' }));
      },
    }));
  }, [dispatch, workspaceId, email, workspaceName, saveBillingInfo]);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Change workspace admin
          <CloseIcon
            onClick={() => dispatch(toggleModal('change_workspace_admin', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={() => dispatch(toggleModal('change_workspace_admin', false))}
    >
      <>
        <Label>Select user*</Label>
        <Select
          style={WorkspaceUsersSelectStyle}
          placeholder="Select user from this workspace"
          onChange={setEmail}
          value={email}
        >
          {usersWithoutCurrentUser.map((user) => (
            <Option value={get(user, 'email')} key={`change_workspace_user_${get(user, '_id', nanoid())}`}>{`${get(user, 'firstName', '')} ${get(user, 'lastName', '')} - ${get(user, 'email', '')}`}</Option>
          ))}
        </Select>
        <Checkbox
          style={{ marginTop: 10 }}
          checked={saveBillingInfo}
          onChange={(e) => {
            toggleSaveBillingInfo(e.target.checked);
          }}
        >
          Move the card and billing details to the new admin
        </Checkbox>
      </>
      <Footer>
        <Button
          data-form={!email ? 'false' : 'true'}
          type="primary"
          onClick={handleChangeWorkspaceAdmin}
          disabled={!email}
        >
          Save
        </Button>
        <Button type="normal" onClick={() => dispatch(toggleModal('change_workspace_admin', false))}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default ChangeWorkspaceAdminModal;
