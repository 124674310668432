import React from 'react';
import { DatePicker } from 'antd';

import { isFloat } from 'helpers/regularExpressions';
import {
  Select,
  Input,
  Option,
} from 'components/common';
import {
  Wide,
  InputContainer,
} from '../editContactsProperty/styles';

const EditableProperty = ({
  fieldType,
  value,
  updateValue,
  options,
}) => {
  switch (fieldType) {
    case 'booleancheckbox':
      return (
        <Select value={value} onChange={updateValue}>
          <Option value="yes">Yes</Option>
          <Option value="no">No</Option>
        </Select>
      );
    case 'select':
    case 'checkbox':
      return (
        <Select mode={fieldType === 'checkbox' ? 'multiple' : null} onChange={updateValue}>
          {
            (options || []).map((option) => <Option key={option.value} value={option.value}>{option.label}</Option>)
          }
        </Select>
      );
    case 'date':
      return (
        <DatePicker size="large" onChange={(_, dateString) => updateValue([dateString])} />
      );
    case 'number':
    case 'textarea':
    case 'url':
    case 'text':
    default:
      return (
        <InputContainer>
          <Input
            value={value}
            style={Wide}
            onChange={(event) => {
              const newValue = event.target.value;
              if (fieldType === 'number' && !isFloat(newValue)) {
                return;
              }
              updateValue(newValue);
            }}
          />
        </InputContainer>
      );
  }
};

export default EditableProperty;
