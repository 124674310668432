import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
  Button,
} from 'antd';

import { modalsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { authorizeUser } from 'data/actions/oauth2';

import {
  CloseIcon,
  ModalTitle,
  Label,
  Footer,
} from '../baseModal/styles';

const AppConnectionModal = () => {
  const dispatch = useDispatch();
  const { visible, options } = useSelector(modalsSelector);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Connecting Zapier to GetProspect
          <CloseIcon
            onClick={() => dispatch(toggleModal('app_connection', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={() => dispatch(toggleModal('app_connection', false))}
    >
      <Label>Access to features for the Zapier integration</Label>
      <Footer>
        <Button
          type="primary"
          onClick={() => {
            dispatch(authorizeUser(options));
            dispatch(toggleModal('app_connection', false));
          }}
        >
          Connect app
        </Button>
        <Button type="default" onClick={() => dispatch(toggleModal('app_connection', false))}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default AppConnectionModal;
