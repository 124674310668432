import { useState } from 'react';

const useStepManager = ({
  startFrom = 1,
  onStep,
}) => {
  const [stepNumber, setStepNumber] = useState(startFrom);

  const setParamsWrapper = (callback) => (params, step) => {
    if (onStep) onStep(params, step);
    callback(step);
  };

  const setNextStep = setParamsWrapper((step) => {
    setStepNumber(typeof step === 'number' ? step : stepNumber + 1);
  });

  const setPreviousStep = setParamsWrapper((step) => {
    const prevStep = (stepNumber - 1 < 1 ? 1 : stepNumber - 1);
    setStepNumber(typeof step === 'number' ? step : prevStep);
  });

  return { stepNumber, setNextStep, setPreviousStep };
};

export default useStepManager;
