import isEqual from 'react-fast-compare';
import { useState, useEffect, useCallback } from 'react';

const useTinyForm = ({
  schema,
  onSubmit,
  stepData,
}) => {
  const [newSchema, setNewSchema] = useState(schema);
  const [previousSchema, setPreviousSchema] = useState(schema);
  const [data, setData] = useState(Object.keys(schema).reduce((prev, next) => ({ ...prev, [next]: schema[next].value }), {}));
  const [wasSubmitPressed, setWasSubmitPressed] = useState(false);
  const [errors, setErrors] = useState({});
  const [lastStep, setLastStep] = useState(stepData?.step);

  useEffect(() => {
    if (stepData?.step !== lastStep) {
      setLastStep(stepData?.step);
      setWasSubmitPressed(false);
    }
  }, [stepData, lastStep]);

  useEffect(() => {
    if (!isEqual(schema, newSchema)) {
      setErrors({});
      setPreviousSchema(newSchema);
      setNewSchema(schema);
      setData(Object.keys(schema).reduce((prev, next) => ({ ...prev, [next]: schema[next].value }), {}));
    }
  }, [schema, newSchema]);

  const findFirstError = (property, value, relevantData) => {
    const schemaProperty = schema[property];
    if (schemaProperty?.conditions) {
      let customMessage;
      const firstError = schemaProperty.conditions.find((item) => {
        const message = item.condition(value, relevantData);
        if (typeof message === 'string') customMessage = message;
        return customMessage || message === false;
      });
      if (firstError) {
        return { label: customMessage || firstError.label };
      }
    }
    return null;
  };

  const validateValue = (property, value, relevantData) => {
    setErrors((prev) => {
      const firstError = findFirstError(property, value, relevantData);
      const schemaProperty = schema[property];
      if (schemaProperty) {
        if (firstError) {
          prev[property] = firstError.label;
        } else {
          delete prev[property];
        }
        if (schemaProperty.dependencies) {
          schemaProperty.dependencies.forEach((dependency) => validateValue(dependency, data[dependency], relevantData));
        }
      }
      return { ...prev };
    });
  };

  const getErrors = (fields = [], _data) => {
    const _errors = {};
    (fields || []).forEach((key) => {
      const firstError = findFirstError(key, _data[key], _data);
      if (firstError) {
        _errors[key] = firstError.label;
      }
    });
    return _errors;
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if (schema[name]?.validator && !schema[name]?.validator(value)) {
      return data;
    }
    const updatedData = { ...data };
    if (name) {
      if (name === 'email') {
        updatedData[name] = value.trim();
      } else if (name === 'phone') {
        updatedData[name] = value?.phone;
        updatedData.countryCode = value?.countryCode;
      } else {
        updatedData[name] = value ?? checked;
      }
      if (wasSubmitPressed) {
        validateValue(name, value ?? checked, updatedData);
      }
    }
    setData(updatedData);
    return updatedData;
  };

  const setErrorsProxy = useCallback((error, message) => {
    setErrors((prev) => {
      if (!message) {
        delete prev[error];
        return { ...prev };
      }
      return {
        ...prev, [error]: message,
      };
    });
  }, []);

  const reset = useCallback(() => {
    setErrors({});
    setNewSchema(previousSchema);
    setData(Object.keys(previousSchema).reduce((prev, next) => ({ ...prev, [next]: previousSchema[next].value }), {}));
  }, [previousSchema]);

  const handleSubmit = (event) => {
    let _data = data;
    if (event) {
      _data = handleChange(event);
    }
    setWasSubmitPressed(true);
    const fields = stepData ? stepData.propertyKeys[stepData.step - 1] ?? [] : Object.keys(schema);
    const _errors = getErrors(fields, _data);
    setErrors(_errors);
    if (Object.keys(_errors).length === 0) {
      const convertedData = Object.keys(_data).reduce((obj, key) => {
        if (schema[key]?.type) {
          obj[key] = schema[key].type(_data[key]);
        } else {
          obj[key] = _data[key];
        }
        return obj;
      }, {});
      onSubmit({ data: convertedData, setData: handleChange, setErrors: setErrorsProxy });
    }
    return _errors;
  };

  return {
    data,
    errors,
    reset,
    setErrors: setErrorsProxy,
    isDisabledForm: Object.keys(errors).length !== 0,
    handleChange,
    handleSubmit,
  };
};

export default useTinyForm;
