import { useEffect } from 'react';

const useKeyHandler = ({ keys = [], dataAttr, blackList = [] }) => {
  useEffect(() => {
    function handleKey(e) {
      if (document.activeElement) {
        const isAvailable = !blackList?.includes(document?.activeElement?.tagName);
        if (keys.includes(e.key) && isAvailable && !document.activeElement.dataset.ignore) {
          const element = document.querySelector(`[data-${dataAttr}='true']`);
          const activeElements = element?.dataset?.activeElements;
          if (element && (!activeElements || activeElements.includes(document.activeElement.tagName.toLowerCase()))) {
            element.click();
          }
        }
      }
    }
    window.document.addEventListener('keypress', handleKey);
    return () => window.document.removeEventListener('keypress', handleKey);
  }, [keys, dataAttr, blackList]);
};

export default useKeyHandler;
