import React from 'react';
import { Tooltip } from 'antd';
import { useDispatch } from 'react-redux';

import { Icon } from 'components/common';
import { COLORS } from 'constants/styles';
import { toggleModal } from 'data/actions/modals';
import useShowPauseWarningIfNeeded from 'hooks/sequences/useShowPauseWarningIfNeeded';
import {
  ActionButton,
} from '../styles';

const ShowTemplates = ({
  stepId,
  subject,
  content,
  version,
  previousModal,
}) => {
  const dispatch = useDispatch();

  const showPauseWarningIfNeeded = useShowPauseWarningIfNeeded();

  const showTemplatesModal = showPauseWarningIfNeeded(() => {
    dispatch(toggleModal('add_template_step_preview', true, {
      stepId,
      subject,
      content,
      version,
      previousModal,
    }));
  });

  return (
    <Tooltip title="Show templates">
      <ActionButton onClick={showTemplatesModal}>
        <Icon outlined fill={COLORS.GREY_G1} size="smallest" type="ic-article" />
      </ActionButton>
    </Tooltip>
  );
};

export default ShowTemplates;
