import capitalize from 'lodash/capitalize';

const labelDictionary = [
  { from: 'size', to: 'Employees' },
  { from: 'leads', to: 'Leads' },
  { from: 'position', to: 'Job title' },
];

export const renameLabel = (item, isDefault = true) => {
  const foundedItem = labelDictionary.find((label) => label.from === item?.toLowerCase());

  if (foundedItem) {
    return foundedItem.to;
  }

  if (item === 'linkedIn') {
    return 'LinkedIn';
  }

  if (item === 'LinkedIn') {
    return item;
  }

  return isDefault ? capitalize(item.toLowerCase()) : item;
};
