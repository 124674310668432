import { modalOptionsSelector } from 'data/selectors/modals';
import React from 'react';
import { useSelector } from 'react-redux';

import FullScreenMobileModal from '../fullScreenMobileModal';
import { MenuItem } from '../searchFiltersMobileModal/styles';

const ContactsFiltersMobileMenu = () => {
  const {
    handleEditProperties,
    handleImport,
    handleCreateProperty,
    handleExport,
  } = useSelector(modalOptionsSelector);

  const content = (
    <>
      <MenuItem onClick={handleEditProperties}>Edit columns</MenuItem>
      <MenuItem onClick={handleCreateProperty}>Create property</MenuItem>
      <MenuItem onClick={handleImport}>Import</MenuItem>
      <MenuItem onClick={handleExport}>Export</MenuItem>
    </>
  );

  return (
    <FullScreenMobileModal type="contacts_filters_mobile" content={content} />
  );
};

export default ContactsFiltersMobileMenu;
