import styled from 'styled-components';

export const Label = styled.div`
  font-family: 'AvenirNext';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.65);
  margin: 0 0 20px 0;
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  button {
    margin-right: 10px;
  }
`;

export const QuestionIcon = {
  margin: '20px 0',
  display: 'inline-block',
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ListName = styled.span`
  font-weight: 600;
`;
