import React from 'react';

import imagePath from 'assets/images/emptyState.svg';
import Loader from '../loading';
import {
  EmptyContainer,
  EmptyContainerIcon,
  EmptyContainerLabel,
  EmptyContainerText,
  Wrapper,
} from './style';

export const EmptySection = ({
  loading, icon = imagePath, header, text, style, children, textWidth = 0,
  resetStyles,
}) => (
  <Wrapper resetStyles={resetStyles} style={style}>
    <Loader size="large" loading={loading}>
      {!loading ? (
        <EmptyContainer>
          <div>
            <EmptyContainerIcon src={icon} alt="empty-state-charts" className="empty-icon" />
          </div>
          <div>
            {header && (
              <EmptyContainerLabel className="empty-header">
                {header}
              </EmptyContainerLabel>
            )}

            {text && (
              <EmptyContainerText textWidth={textWidth} className="empty-text">
                {text}
              </EmptyContainerText>
            )}
            {
              children && (
                <div>
                  {children}
                </div>
              )
            }
          </div>
        </EmptyContainer>
      ) : null}
    </Loader>
  </Wrapper>
);
