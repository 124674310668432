import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useHubspot from 'hooks/url/useHubspot';
import { toggleModal } from 'data/actions/modals';
import { createQuitReport } from 'data/actions/common';
import { deleteAccountSelector } from 'data/selectors/user';
import { modalOptionsSelector } from 'data/selectors/modals';
import { SHARED_INFORMATION } from 'data/types/subscription.types';
import useDeleteAccountMessage from 'hooks/messageTemplates/useDeleteAccountMessage';
import transformCancelReasonFlowToText from 'helpers/transformCancelReasonFlowToText';
import {
  deleteUser,
  resetDeleteAccount,
  setDeleteAccount,
  updateWorkspaceLimit,
} from 'data/actions/user';
import { REASON_TYPE } from 'data/types/user.types';
import AnotherTool from '../common/modals/anotherTool';
import Other from '../common/modals/other';
import HardApp from './hardApp';
import NotFoundContacts from './notFoundContacts';
import StepManager from '..';

const ManagerDeleteAccountModal = () => {
  const dispatch = useDispatch();

  const { reason, deleteAccount } = useSelector(modalOptionsSelector);
  const details = useSelector(deleteAccountSelector);

  const deleteAccountMessage = useDeleteAccountMessage();
  const { openDeleteAccountHubspotCalendar } = useHubspot();

  const reasonMap = {
    [REASON_TYPE.HARD_APP]: HardApp,
    [REASON_TYPE.NOT_FOUND_CONTACTS]: NotFoundContacts,
    [REASON_TYPE.ANOTHER_TOOL]: AnotherTool,
    [REASON_TYPE.OTHER]: Other,
  };

  const ReasonComponent = reasonMap[reason] || Fragment;

  const handleClose = () => {
    dispatch(toggleModal('manager_delete_account', false));
    dispatch(resetDeleteAccount());
  };

  const handleDeleteAccount = (params) => {
    if (reason) {
      const {
        needToAct,
      } = params;

      if (deleteAccount) {
        details.deleteAccount = deleteAccount;
      }
      dispatch(createQuitReport({
        type: reason,
        action: 'deleteAccount',
        details,
        onSuccess: () => {
          const isDeleteAccountLimit = !!details.sharedInformation;
          if (isDeleteAccountLimit) {
            dispatch(updateWorkspaceLimit({ key: 'isDeleteAccountLimit', value: isDeleteAccountLimit }));
          }
          if (needToAct) {
            dispatch(deleteUser(() => {
              deleteAccountMessage();
              handleClose();
            }));
          } else if (details.sharedInformation === SHARED_INFORMATION.BOOKING_DEMO) {
            openDeleteAccountHubspotCalendar({
              cancelReason: transformCancelReasonFlowToText(details),
            });
            handleClose();
          } else if (details.sharedInformation === SHARED_INFORMATION.CHAT) {
            window.Intercom('showMessages');
            handleClose();
          }
        },
      }));
    }
  };

  return (
    <StepManager
      stepsComponent={ReasonComponent}
      details={details}
      onClose={handleClose}
      onFinish={handleDeleteAccount}
      onStep={(params) => dispatch(dispatch(setDeleteAccount(params)))}
    />
  );
};

export default ManagerDeleteAccountModal;
