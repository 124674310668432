import { ASYNC_BOOLEAN } from 'data/types/progress.types';

const storageKey = 'is_plugin_installed';

const checkChromeExtension = async () => {
  let isInstalled = ASYNC_BOOLEAN.LOADING;

  if (window.chrome?.runtime?.sendMessage) {
    const promises = [];
    const ids = process.env.REACT_APP_PLUGIN_ID.split(' ');
    if (process.env.REACT_APP_PLUGIN_TEST && ids.length > 1) {
      ids.map((id) => promises.push(new Promise((resolve) => {
        window.chrome.runtime.sendMessage(id, { message: 'version' }, resolve);
      })));
    } else {
      promises.push(new Promise((resolve) => {
        window.chrome.runtime.sendMessage(process.env.REACT_APP_PLUGIN_ID, { message: 'version' }, resolve);
      }));
    }

    let timer;
    const checkPlugin = Promise.all(promises).then((results) => {
      const installed = results.some((result) => !!result?.version);
      return installed;
    }).catch(() => {
      console.log('Something went wrong [plugin checker]');
      return ASYNC_BOOLEAN.ERROR;
    });

    const setPluginNotInstalled = new Promise((resolve) => {
      timer = setTimeout(() => {
        resolve(ASYNC_BOOLEAN.FALSE);
      }, 5000);
    });

    isInstalled = await Promise.race([checkPlugin, setPluginNotInstalled]);
    clearTimeout(timer);
  } else {
    isInstalled = ASYNC_BOOLEAN.ERROR;
    // console.log('Browser isn\'t Chrome');
  }

  if (!localStorage.getItem(storageKey) && isInstalled === ASYNC_BOOLEAN.TRUE) {
    localStorage.setItem(storageKey, isInstalled);
  }

  return isInstalled;
};

export default checkChromeExtension;
