export const sendAddToCartAnalytic = (plan, billingCycle, quantity) => {
  if (process.env.NODE_ENV !== 'development') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        items: [
          {
            item_id: plan?._id,
            item_name: plan?.name,
            currency: plan?.currency,
            item_brand: 'Subscription',
            item_category: billingCycle?.name,
            price: plan?.price,
            quantity,
          },
        ],
      },
    });
  }
};

export const sendViewItemAnalytic = ({
  plan,
  price,
  billingCycle,
  quantity = 1,
}) => {
  if (process?.env?.NODE_ENV !== 'development' && price > 0) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_id: plan?._id,
            item_name: plan?.name,
            currency: plan?.currency,
            item_brand: 'Subscription',
            item_category: billingCycle?.name,
            price,
            quantity,
          },
        ],
      },
    });
  }
};

export const sendLoginAnalytic = ({ loggedUserId, utmSource, createdAt }) => {
  if (process.env.NODE_ENV !== 'development' && loggedUserId) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      userId: loggedUserId,
      signUpDate: new Date(createdAt).getTime(),
      utmSource: utmSource || 'direct',
    });
  }
};

export const sendEventLoginAnalytic = (authMethod) => {
  if (process.env.NODE_ENV !== 'development') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'login', authMethod });
  }
};

export const sendEventSignUpAnalytic = (authMethod, userId) => {
  if (process.env.NODE_ENV !== 'development') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'sign_up',
      authMethod,
      userId,
    });
  }
};
