import get from 'lodash/get';
import { useSelector } from 'react-redux';
import React, { useRef, useEffect } from 'react';
import {
  Tooltip,
} from 'antd';

import {
  Input,
  Checkbox,
  CheckboxGroup,
  Icon,
} from 'components/common';
import { contactSelector } from 'data/selectors/contacts';
import { suggestedCompaniesSelector } from 'data/selectors/companies';
import { addToContactCompaniesLoadingSelector } from 'data/selectors/loading';
import CompanyIcon from 'components/companyIcon';
import EllipsisText from 'components/ellipsisText';

import {
  SearchForm,
  CheckboxWrapper,
  InputWrapper,
  SearchInputStyle,
  Label,
  InfoIcon,
  CheckboxGroupStyle,
  CompanyContainer,
} from '../styles';

import Loader from '../../loading';

const AddExistingCompany = ({
  setInputValue,
  selectedCompanies,
  inputValue,
  onChange,
  animationDone,
}) => {
  const suggestedCompanies = useSelector(suggestedCompaniesSelector);
  const isLoading = useSelector(addToContactCompaniesLoadingSelector);
  const currentContact = useSelector(contactSelector);

  const contactCompanyRelations = get(currentContact, 'companyRelations', []);

  const focusInputRef = useRef();

  useEffect(() => {
    if (animationDone && focusInputRef?.current) focusInputRef.current.focus();
  }, [animationDone]);

  return (
    <InputWrapper>
      <Input
        value={inputValue}
        suffix={(
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
          <Icon
            type="ic-close"
            style={SearchInputStyle}
            onClick={() => setInputValue('')}
            className="ic_clear"
          />
        )}
        placeholder="Search companies"
        style={SearchForm}
        ref={focusInputRef}
        onChange={(event) => setInputValue(event.target.value)}
      />
      <Loader loading={isLoading}>
        {(suggestedCompanies.length > 0) && (
          <CheckboxWrapper>
            <Label>Results</Label>
            { suggestedCompanies.length > 0 && (
              <CheckboxGroup
                value={selectedCompanies}
                style={CheckboxGroupStyle}
                onChange={onChange}
              >
                {suggestedCompanies.map((item) => {
                  const id = get(item, '_id');
                  const domain = get(item, 'properties.domain.value', '');
                  const name = get(item, 'properties.name.value', '');
                  const disabled = !!contactCompanyRelations.find((relation) => item._id === get(relation, 'company._id'));
                  const isInsight = get(item, 'isInsight', false);
                  const text = `${name} ${domain ? `(${domain})` : domain}`;
                  const isLong = text.length >= 45;

                  if (text.trim().length === 0) {
                    return null;
                  }

                  const onClickCompany = () => {
                    if (selectedCompanies.includes(id)) {
                      onChange(selectedCompanies.filter((companyId) => companyId !== id));
                    } else {
                      onChange([...selectedCompanies, id]);
                    }
                  };

                  return (
                    <CompanyContainer onClick={onClickCompany} key={id}>
                      <Checkbox
                        size="default"
                        disabled={disabled}
                        value={get(item, '_id', '-')}
                      />
                      <CompanyIcon
                        animated={false}
                        size="24px"
                        style={{ minWidth: 24, borderRadius: 4 }}
                        name={get(name, '[0]', '').toUpperCase()}
                        domain={domain}
                        code={item._id}
                      />
                      <EllipsisText title={text}>{text}</EllipsisText>
                      {
                        (isInsight && !disabled) && (
                          <Tooltip placement="top" title="This property was filled from the GetProspect database.">
                            <InfoIcon long={isLong ? 1 : 0} type="info-circle" />
                          </Tooltip>
                        )
                      }
                    </CompanyContainer>
                  );
                })}
              </CheckboxGroup>
            )}
          </CheckboxWrapper>
        )}
      </Loader>
    </InputWrapper>
  );
};

export default AddExistingCompany;
