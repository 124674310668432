import { Drawer } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as CONSTANTS from 'constants/styles';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { addProperty } from 'data/actions/properties';
import useCheckProperty from 'hooks/useCheckProperty';
import { allGroupsSelector } from 'data/selectors/properties';
import { addGroupsToPreviewConfiguration } from 'data/actions/user';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';

import {
  ModalTitle,
  CloseIcon,
  HeaderStyle,
} from '../modals/baseModal/styles';
import {
  ModalsBody,
  DrawerWrapper,
  Footer,
  StepsContainer,
  StepNumber,
  StepTitle,
  Circle,
  CirclesContainer,
  BlueButton,
  WhiteButton,
} from './styles';

import fields from './propertyFields';
import BasicInformation from './steps/basicInformation';
import PropertyType from './steps/propertyType';

const CreatePropertyBar = () => {
  const dispatch = useDispatch();

  const groups = useSelector(allGroupsSelector);
  const { visible } = useSelector(modalsSelector);
  const { sourceType, label, onCreateProperty } = useSelector(modalOptionsSelector);
  const [property, updateProperty] = useState({
    sourceType,
    label,
  });
  const existedProperty = useCheckProperty({ sourceType: property.sourceType, label: property.label });
  const [errors, setErrors] = useState({});
  const [step, changeStep] = useState(1);

  const propertyFields = step === 1 ? fields.firstStep : [...fields.firstStep, ...fields.secondStep];

  const isValid = propertyFields
    .filter((item) => item.required)
    .every((item) => property[item.name] && property[item.name].length > 0)
    && Object.keys(errors).length === 0;

  const [propertyEnumerableData, setPropertyEnumerableData] = useState([{ internalValue: null, label: null, key: 0 }]);

  const handleCreate = () => {
    const onSuccess = ({ settings, ...rest }) => {
      if (settings) {
        dispatch(addGroupsToPreviewConfiguration(settings, property.sourceType));
      }
      dispatch(toggleMessage('info', { header: 'Property was successfully created!' }));
      if (onCreateProperty) onCreateProperty(rest);
    };
    if (['radio', 'select', 'checkbox'].includes(property.fieldType)) property.options = propertyEnumerableData.map((item) => ({ label: item.label, value: item.internalValue }));
    if (property.fieldType === 'booleancheckbox') {
      property.options = [
        { label: 'true', value: 'true' },
        { label: 'false', value: 'false' },
      ];
    }
    dispatch(addProperty(property, onSuccess));
    dispatch(toggleModal('create_property_preview', false));
  };

  useEffect(() => {
    if (existedProperty) {
      setErrors((prev) => ({ ...prev, label: 'We found similar existing properties for you.' }));
    } else {
      setErrors((prev) => {
        delete prev.label;
        return { ...prev };
      });
    }
  }, [existedProperty]);

  return (
    <DrawerWrapper>
      <Drawer
        title={(
          <ModalTitle>
            Create new property
            <CloseIcon
              data-close="true"
              onClick={() => dispatch(toggleModal('create_property_preview', false))}
              className="ic_close"
            />
          </ModalTitle>
        )}
        placement="right"
        onClose={() => dispatch(toggleModal('create_property_preview', false))}
        closable={false}
        bodyStyle={{
          padding: 0,
          maxHeight: 'calc(100% - 180px)',
          overflow: 'auto',
          flexDirection: 'column',
        }}
        headerStyle={HeaderStyle}
        visible={visible}
        width={CONSTANTS.DRAWER_WIDTH}
      >
        <ModalsBody>
          <StepsContainer>
            <div>
              <StepNumber>
                {`Step ${step}`}
              </StepNumber>
              <StepTitle>{step === 1 ? 'Basic information' : 'Property type'}</StepTitle>
            </div>
            <CirclesContainer>
              <Circle active={step === 1} />
              <Circle active={step === 2} />
            </CirclesContainer>
          </StepsContainer>
          {step === 1 ? (
            <BasicInformation
              sourceType={sourceType}
              property={property}
              updateProperty={updateProperty}
              groups={groups}
              fields={fields.firstStep}
              errors={errors}
            />
          ) : (
            <PropertyType
              property={property}
              updateProperty={updateProperty}
              setPropertyEnumerableData={setPropertyEnumerableData}
              propertyEnumerableData={propertyEnumerableData}
            />
          )}
        </ModalsBody>
        <Footer>
          <WhiteButton
            type="normal"
            size="large"
            onClick={() => {
              dispatch(toggleModal('create_property_preview', false));
            }}
          >
            Cancel
          </WhiteButton>

          {step === 1 ? (
            <BlueButton
              data-form={!isValid ? 'false' : 'true'}
              type="primary"
              size="large"
              disabled={!isValid}
              onClick={() => changeStep(2)}
            >
              Next
            </BlueButton>
          ) : (
            <div>
              <WhiteButton
                type="normal"
                size="large"
                onClick={() => changeStep(1)}
              >
                Back
              </WhiteButton>
              <BlueButton
                data-form={!isValid ? 'false' : 'true'}
                type="primary"
                size="large"
                disabled={!isValid}
                onClick={handleCreate}
              >
                Create
              </BlueButton>
            </div>
          )}
        </Footer>
      </Drawer>
    </DrawerWrapper>
  );
};

export default CreatePropertyBar;
