import moment from 'moment';
import get from 'lodash/get';
import replace from 'lodash/replace';
import startCase from 'lodash/startCase';
import { useDispatch, useSelector } from 'react-redux';
import React,
{
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import {
  Modal,
} from 'antd';

import {
  Button,
  Select,
  Option,
  Radio,
  RadioGroup,
} from 'components/common';
import { SOURCE_TYPE } from 'data/types/source.types';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { exportData } from 'data/actions/export';
import {
  contactsFilterSelector,
  contactsFiltersSelector,
  selectedContactsSelector,
  sortContactsSelector,
  selectedAllContactsSelector,
  excludedContactsSelector,
} from 'data/selectors/contacts';
import { exportDataLoaderSelector } from 'data/selectors/loading';
import {
  companiesFiltersSelector,
  companiesFilterSelector,
  sortCompaniesSelector,
  selectedCompaniesSelector,
  selectedAllCompaniesSelector,
  excludedCompaniesSelector,
} from 'data/selectors/companies';
import { staticListsSelector } from 'data/selectors/lists';

import { toggleMessage } from 'data/actions/message';
import { exportColumnsConfigurationSelector } from 'data/selectors/user';
import {
  SelectLabel2,
  ModalBody,
  SelectLabel,
  RadioStyle,
  CustomConfig,
  CustomConfigContainer,
  ButtonEditConfig,
} from './styles';

import {
  ModalTitle,
  CloseIcon,
  Footer,
} from '../baseModal/styles';
import CallToSequences from './callToSequences';

const options = ['CSV', 'XLSX'];

const ExportModal = () => {
  const dispatch = useDispatch();

  const contactSort = useSelector(sortContactsSelector);
  const companySort = useSelector(sortCompaniesSelector);
  const modalOptions = useSelector(modalOptionsSelector);
  const allContactFilters = useSelector(contactsFilterSelector);
  const allCompanyFilters = useSelector(companiesFilterSelector);
  const allLists = useSelector(staticListsSelector);
  const { visible } = useSelector(modalsSelector);
  const contactFilters = useSelector(contactsFiltersSelector);
  const companyFilters = useSelector(companiesFiltersSelector);
  const selectedContacts = useSelector(selectedContactsSelector);
  const selectedCompanies = useSelector(selectedCompaniesSelector);
  const exportColumns = useSelector(exportColumnsConfigurationSelector);
  const loading = useSelector(exportDataLoaderSelector);
  const selectedAllCompanies = useSelector(selectedAllCompaniesSelector);
  const selectedAllContacts = useSelector(selectedAllContactsSelector);
  const excludedContacts = useSelector(excludedContactsSelector);
  const excludedCompanies = useSelector(excludedCompaniesSelector);

  const listId = get(modalOptions, 'listId');
  const exportType = get(modalOptions, 'exportType');
  const sequenceId = get(modalOptions, 'sequenceId');
  const sourceType = get(modalOptions, 'sourceType', null);
  const includeOption = get(modalOptions, 'includeProperties', 'columns_only');
  const customFilters = get(modalOptions, 'customFilters');
  const filters = get(modalOptions, 'customFilters', sourceType === 'company' ? companyFilters : contactFilters);
  const allFilters = sourceType === 'company' ? allCompanyFilters : allContactFilters;
  const sort = sourceType === 'company' ? companySort : contactSort;
  let itemIds = get(modalOptions, 'itemIds');
  if (!itemIds && !sequenceId) {
    itemIds = sourceType === 'company' ? selectedCompanies : selectedContacts;
  }
  const showCustomConfiguration = sourceType === SOURCE_TYPE.CONTACT && !sequenceId;
  const selectAll = sourceType === 'company' ? selectedAllCompanies : selectedAllContacts;

  const [exportName, setExportName] = useState('');
  const [includeProperties, setIncludeProperties] = useState(includeOption);
  const [format, setFormat] = useState(localStorage.getItem('lastExportFormat') ?? 'CSV');

  const exportCustomColumnsLength = useMemo(() => {
    const exportColumnsLength = sourceType === 'company' ? get(exportColumns, 'companies.length', 0) : get(exportColumns, 'contacts.length', 0);
    return exportColumnsLength;
  }, [exportColumns, sourceType]);

  const openExportConfigurationEditor = useCallback(() => {
    dispatch(toggleModal('export-configuration-editor', true, { sourceType, includeProperties }));
  }, [dispatch, includeProperties, sourceType]);

  const handleClose = useCallback(() => dispatch(toggleModal('export', false)), [dispatch]);

  const handleExport = useCallback(() => {
    if (!exportCustomColumnsLength && includeProperties === 'custom') {
      openExportConfigurationEditor();
    } else {
      const ids = selectAll ? [] : itemIds;
      localStorage.setItem('lastExportFormat', format);

      dispatch(exportData({
        sequenceId,
        name: exportName,
        filters,
        fileFormat: format.toLowerCase(),
        includeProperties,
        sourceType,
        sort,
        itemIds: Array.isArray(itemIds) && itemIds.length > 0 ? ids : undefined,
        excludedIds: sourceType === 'company' ? [...excludedCompanies] : [...excludedContacts],
      }, () => {
        handleClose();
        dispatch(toggleMessage('info', { header: 'Preparing the file, you’ll be notified' }));
      }));
    }
  }, [dispatch, handleClose, exportCustomColumnsLength, itemIds, selectAll, format, exportName, includeProperties, filters, sort, sourceType]); // eslint-disable-line

  useEffect(() => {
    if (exportCustomColumnsLength) {
      setIncludeProperties('custom');
    }
  }, [exportCustomColumnsLength]);

  useEffect(() => {
    const { pathname } = window.location;
    const date = moment().format('YYYY_MM_DD');
    const source = pathname.split('/')?.[2] || 'contacts';
    const type = pathname.split('/')?.[3] || exportType;
    const id = pathname.split('/')?.[4] || listId;

    let prefix = `Filtered_${source}`;

    if (type === 'filter') {
      if (id === 'all') prefix = `All ${source}`;
      else {
        const filter = allFilters.find((item) => item._id === id);
        if (filter && filter.name) prefix = filter.name;
      }
    } else if (type === 'import') {
      prefix = `Import_${source}`;
    } else if (type === 'list') {
      if (id) {
        const list = allLists.find((item) => item._id === id);
        if (list) prefix = list.name;
      }
    }

    let name = replace(`${startCase(prefix)}_${date}`, / /g, '_');
    if (name.length > 31 && format === 'XLSX') {
      name = replace(`${startCase(source)}_${date}`, / /g, '_');
    }
    setExportName(name);
  }, [allFilters, allLists, listId, exportType, format]);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Export
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={420}
      footer={false}
      onCancel={handleClose}
    >
      <ModalBody>
        {
          sourceType === SOURCE_TYPE.CONTACT && !sequenceId && process.env.REACT_APP_SEQUENCES_ON && <CallToSequences customFilters={customFilters} />
        }
        <SelectLabel>
          File format
        </SelectLabel>
        <Select size="default" onChange={setFormat} value={format} defaultValue="CSV">
          {options.map((item, index) => <Option key={`export-options-${index + 1}`} value={item}>{item}</Option>)}
        </Select>
        <br />
        <SelectLabel2>
          Properties
        </SelectLabel2>
        <RadioGroup
          onChange={(e) => setIncludeProperties(e.target.value)}
          value={includeProperties}
        >
          <Radio size="default" style={RadioStyle} value="columns_only">
            Include only properties in columns
          </Radio>
          <Radio size="default" style={RadioStyle} value="all">
            Include all properties
          </Radio>
          {showCustomConfiguration && (
            <Radio size="default" style={{ ...RadioStyle, marginBottom: 20 }} value="custom">
              <CustomConfigContainer>
                <CustomConfig>
                  Custom configuration
                  <ButtonEditConfig
                    onClick={openExportConfigurationEditor}
                  >
                    Edit
                  </ButtonEditConfig>
                </CustomConfig>
              </CustomConfigContainer>
            </Radio>
          )}
        </RadioGroup>
      </ModalBody>
      <Footer onlyFooter>
        <Button
          data-form="true"
          type="primary"
          loading={loading}
          onClick={handleExport}
        >
          Confirm
        </Button>
        <Button type="normal" onClick={handleClose}>Cancel</Button>
      </Footer>

    </Modal>
  );
};

export default ExportModal;
