import get from 'lodash/get';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { toggleMessage } from 'data/actions/message';

const useSubscriptionMessage = () => {
  const dispatch = useDispatch();

  const handleSubscription = useCallback(({ subscription, error }) => {
    if (error) {
      dispatch(toggleMessage('error', { text: error, duration: 15 }));
    } else {
      dispatch(toggleMessage('success', { header: 'Thank you', text: `Your ${get(subscription, 'plan.name', '')} plan has been activated successfully.`, duration: 15 }));
    }
  }, [dispatch]);

  return handleSubscription;
};

export default useSubscriptionMessage;
