import get from 'lodash/get';
import { createSelector } from 'reselect';
import { paymentGate } from 'data/types/payment.types';

const baseCardsSelector = (state) => get(state, 'cards.cards', []);
export const cardsSelector = createSelector(
  baseCardsSelector,
  (cards) => cards,
);

const baseCheckoutStatusSelector = (state) => get(state, 'cards.checkoutStatus', false);
export const checkoutStatusSelector = createSelector(
  baseCheckoutStatusSelector,
  (checkoutStatus) => checkoutStatus,
);

const baseMerchantSelector = (state) => get(state, 'cards.merchantId', null);
export const merchantSelector = createSelector(
  baseMerchantSelector,
  (merchantId) => merchantId,
);

const baseFondyPaymentFieldSelector = (state) => get(state, 'cards.fondyPfToken', null);
export const fondyPaymentFiledSelector = createSelector(
  baseFondyPaymentFieldSelector,
  (fondyPfToken) => fondyPfToken,
);

export const existFastSpringCardSelector = createSelector(
  baseCardsSelector,
  (cards) => cards.some((card) => card.paymentGate === paymentGate.FAST_SPRING),
);

export const getFastSpringDefaultCard = createSelector(
  baseCardsSelector,
  (cards) => cards.find((card) => card.paymentGate === paymentGate.FAST_SPRING && card.isDefault),
);

export const getDefaultCard = createSelector(
  baseCardsSelector,
  (cards) => cards.find((card) => card.isDefault),
);
