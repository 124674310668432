import React from 'react';
import { CustomRadio as Radio } from 'components/commonStyles';

const CustomRadio = ({
  size = 'large',
  value,
  children,
  ...props
}) => (
  <Radio size={size} value={value} {...props}> {/* eslint-disable-line */}
    {children}
  </Radio>
);

export default CustomRadio;
