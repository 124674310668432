import React from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Label } from '@newlab-io/getprospect-react-components/lib/ui';

import { toggleModal } from 'data/actions/modals';
import { Border, Button, Icon } from 'components/common';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import {
  ModalTitle,
  HeaderLabel,
  SubHeaderLabel,
  CloseIcon,
  Footer,
} from '../baseModal/styles';
import { OrderedList } from '../imapInfoModal/styles';
import { Hint } from './styles';

const AppPasswordInfoModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const { sequenceId } = useSelector(modalOptionsSelector);

  const handleClose = () => dispatch(toggleModal('app_password_info', false));
  const handleNext = () => dispatch(toggleModal('add_app_password', true, { sequenceId }));

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Connect Your Google Account
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={420}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        <HeaderLabel style={{ marginBottom: 10 }}>Enable 2-step verification</HeaderLabel>
        <SubHeaderLabel style={{ marginBottom: 10 }}>Enable 2-step verification and generate App password</SubHeaderLabel>
        <Label style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }} as="a" target="_blank" href="https://www.youtube.com/watch?v=J4CtP1MBtOE&t=10s">
          <Icon style={{ marginRight: 5 }} fill="primary" size="small" type="ic-ondemand-video" />
          See tutorial video
        </Label>
        <Border style={{ marginBottom: 20 }} />
        <OrderedList>
          <li>
            Go to your Google Account&lsquo;s&nbsp;
            <Label as="a" target="_blank" href="https://myaccount.google.com/security">Security Settings</Label>
          </li>
          <li>
            Enable&nbsp;
            <Label as="a" target="_blank" href="https://myaccount.google.com/signinoptions/two-step-verification">2-step verification</Label>
          </li>
          <li>
            Create an&nbsp;
            <Label as="a" target="_blank" href="https://myaccount.google.com/apppasswords">App password</Label>
          </li>
        </OrderedList>
        <Hint>
          Select “Other” for both App and Device
        </Hint>
        <Footer>
          <Button
            data-form="true"
            type="primary"
            onClick={handleNext}
          >
            Next
          </Button>
          <Button type="normal" onClick={handleClose}>Cancel</Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default AppPasswordInfoModal;
