import React from 'react';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toggleModal } from 'data/actions/modals';
import { SEARCH_CONTENT } from 'constants/searchContent';
import SearchFilters from '../../searchFilters';
import { MenuItem, FiltersContainer } from './styles';
import FullScreenMobileModal from '../fullScreenMobileModal';

const SearchFiltersMobileMenu = ({ type, location }) => {
  const dispatch = useDispatch();
  const current = get(location, 'pathname', '');

  const handleOpenRecentActivity = (show) => dispatch(toggleModal('searches_preview', true, { show }));

  const content = (
    <>
      <MenuItem onClick={() => handleOpenRecentActivity(SEARCH_CONTENT.SAVED_FILTERS)}>Saved searches</MenuItem>
      <MenuItem onClick={() => handleOpenRecentActivity(SEARCH_CONTENT.RECENT_ACTIVITY)}>Recent activities</MenuItem>
      {(current === '/search') && (
      <FiltersContainer>
        <SearchFilters />
      </FiltersContainer>
      )}
    </>
  );

  return (
    <FullScreenMobileModal type={type} content={content} />
  );
};

export default withRouter(SearchFiltersMobileMenu);
