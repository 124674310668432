import get from 'lodash/get';
import jwt from 'jsonwebtoken';
import isEqual from 'react-fast-compare';
import * as fileDownload from 'js-file-download';

import { SOURCE_TYPE } from 'data/types/source.types';
import { PageSizes } from 'data/types/tableMeta.types';
import { ACCOUNT_EVENTS } from 'data/types/event.types';
import { USER_STATUS } from 'data/types/user.types';
import { ASYNC_BOOLEAN } from 'data/types/progress.types';
import { startCase } from 'lodash';

if (parseInt(process?.env?.REACT_APP_DEMO_MODE, 10) === 1 && process?.env?.REACT_APP_DEMO_ACCOUNT_TOKEN) {
  localStorage.setItem('accessToken', process?.env?.REACT_APP_DEMO_ACCOUNT_TOKEN);
}

const initialState = {
  settings: {
    columnsConfiguration: localStorage.getItem('columnsConfiguration')
      ? JSON.parse(localStorage.getItem('columnsConfiguration'))
      : {
        contacts: [],
        companies: [],
      },
    contactsFavoriteFilters: [],
    companiesFilterConfiguration: [],
    companiesFavoriteFilters: [],
    subscriptionConfiguration: {
      sharePlan: true,
      shareLeads: true,
    },
    notificationConfiguration: {
      bell: true,
      popup: true,
      sound: true,
      redDot: true,
    },
    emailNotificationConfiguration: {
      export: true,
    },
    billingConfiguration: {
      notify: false,
    },
    previewConfiguration: {},
  },
  workspaceBilling: {
    companyName: '',
    invoiceEmail: '',
    companyAddress: '',
    billingInfo: '',
  },
  auth: {
    token: localStorage.getItem('accessToken'),
  },
  account: {},
  sessions: [],
  affiliateReferred: [],
  avatar: {
    src: '',
    bucketUrl: '',
    fields: {},
    file: null,
  },
  info: {},
  credits: [],
  collapseState: {
    group: '',
    step: '',
  },
  creditsHistory: {
    data: [],
    page: 1,
    pageSize: PageSizes.DEFAULT_USAGE_PAGE_SIZE,
    sort: {
      column: '',
      order: '',
    },
    totalItems: 0,
    totalPages: 0,
  },

  questions: {
    companySize: '',
    companyRole: '',
    targetAudience: '',
    usagePurpose: [],
  },
  creditsAnalytics: [],
  showCreditsBar: false,
  usersWorkspaces: [],
  workspaces: [],
  workSpaceAnalytics: [],
  currentWorkspace: get(jwt.decode(localStorage.getItem('accessToken')), 'workspaceId'),
  userHaveOAuthCode: false,
  activities: {
    viewed_lists: [],
    initialResponse: false,
  },
  plugin: {
    installed: ASYNC_BOOLEAN.LOADING,
    auth: {},
  },
  googleSheetsPlugin: {},
  apiKeys: [],
  isDemoUser: false,
  demoServiceError: null,
  demoUserIp: null,
  isAdmin: get(jwt.decode(localStorage.getItem('accessToken')), 'isAdmin', false),
  showLastStep: false,
  deleteAccount: {},
  recentActivity: {
    contacts: [],
    companies: [],
  },
  additionalData: {},
  nspScoreFeedback: null,
  closeBlackFridayBanner: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_DELETE_ACCOUNT': {
      return {
        ...state,
        deleteAccount: { ...initialState.deleteAccount },
      };
    }
    case 'SET_DELETE_ACCOUNT': {
      return {
        ...state,
        deleteAccount: {
          ...state.deleteAccount,
          ...action.data,
        },
      };
    }
    case 'GET_USER_SETTINGS': {
      const { settings } = initialState;
      const updateSettings = {
        ...settings,
        ...get(action, 'payload', {}),
      };
      const columnsConfigurationData = get(action, 'payload.columnsConfiguration', {});
      const storedColumnsConfigurationData = localStorage.getItem('columnsConfiguration');

      if (!storedColumnsConfigurationData) {
        localStorage.setItem('columnsConfiguration', JSON.stringify(columnsConfigurationData));
      }

      if (storedColumnsConfigurationData) {
        const columnsConfiguration = JSON.parse(storedColumnsConfigurationData);
        if (!isEqual(columnsConfiguration, columnsConfigurationData)) {
          localStorage.setItem('columnsConfiguration', JSON.stringify(columnsConfigurationData));
        }
      }
      return {
        ...state,
        settings: updateSettings,
      };
    }
    case 'CREATE_USER_SETTINGS': {
      return {
        ...state,
        settings: action.payload,
      };
    }
    case 'ADD_GROUPS_TO_PREVIEW_CONFIGURATION': {
      const { sourceType, groups } = action.data;
      const updatedProperty = sourceType === SOURCE_TYPE.CONTACT ? 'contacts' : 'companies';

      const updatedPropertyArray = get(state, `settings.previewConfiguration[${updatedProperty}]`);
      if (!updatedPropertyArray) {
        return state;
      }

      return {
        ...state,
        settings: {
          ...state.settings,
          previewConfiguration: {
            ...state.settings.previewConfiguration,
            [updatedProperty]: [...updatedPropertyArray, ...groups],
          },
        },
      };
    }
    case 'DELETE_GROUPS_FROM_PREVIEW_CONFIGURATION': {
      const { sourceType, groupNames } = action.data;
      const updatedProperty = sourceType === SOURCE_TYPE.CONTACT ? 'contacts' : 'companies';

      const updatedPropertyArray = get(state, `settings.previewConfiguration[${updatedProperty}]`);
      if (!updatedPropertyArray) {
        return state;
      }

      return {
        ...state,
        settings: {
          ...state.settings,
          previewConfiguration: {
            ...state.settings.previewConfiguration,
            [updatedProperty]: updatedPropertyArray.filter((group) => !groupNames.includes(group.groupName)),
          },
        },
      };
    }
    case 'UPDATE_USER_SETTINGS': {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...get(action, 'payload', {}),
        },
      };
    }
    case 'SIGN_IN': {
      const { auth } = initialState;
      const token = get(action, 'payload.accessToken', null);

      if (token) {
        const adminTempSession = get(jwt.decode(token), 'isTemp', false);

        if (adminTempSession) {
          localStorage.setItem('adminTempSession', adminTempSession);
        }

        localStorage.setItem('lastUserEmail', get(jwt.decode(token), 'email'));
      }
      return {
        ...state,
        isAdmin: get(jwt.decode(token), 'isAdmin', false),
        currentWorkspace: get(jwt.decode(token), 'workspaceId'),
        auth: {
          ...auth,
          token,
          isRememberMe: true,
        },
      };
    }
    case 'SIGN_UP': {
      const { auth } = initialState;
      const token = get(action, 'payload.accessToken', null);
      return {
        ...state,
        isAdmin: get(jwt.decode(token), 'isAdmin', false),
        currentWorkspace: get(jwt.decode(token), 'workspaceId'),
        auth: {
          ...auth,
          token,
        },
        showLastStep: state.plugin.installed === ASYNC_BOOLEAN.TRUE,
      };
    }

    case 'VERIFY_ACCOUNT_BY_CODE': {
      return {
        ...state,
        showLastStep: true,
      };
    }
    case 'SET_PLUGIN_INFORMATION': {
      return {
        ...state,
        plugin: {
          ...action.data,
          auth: state.plugin.auth,
        },
      };
    }
    case 'GOOGLE_AUTH': {
      const { auth } = initialState;
      const token = get(action, 'payload.accessToken', null);
      return {
        ...state,
        isAdmin: get(jwt.decode(token), 'isAdmin', false),
        currentWorkspace: get(jwt.decode(token), 'workspaceId'),
        showLastStep: get(action, 'payload.signUp'),
        auth: {
          ...auth,
          token,
        },
      };
    }

    case 'RESEND_VERIFY_EMAIL': {
      return {
        ...state,
      };
    }

    case 'SKIP_LAST_STEP': {
      if (action?.data?.showLeadTour) localStorage.setItem('showLeadTour', true);
      return {
        ...state,
        showLastStep: false,
      };
    }

    case 'SIGN_OUT': {
      if (window.Intercom) {
        window.Intercom('shutdown');
        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        });
      }
      return {
        ...initialState,
        plugin: state.plugin,
        auth: {
          token: null,
        },
      };
    }

    case 'GET_ACCOUNT': {
      const account = { ...action.payload };
      const newState = state;
      return {
        ...newState,
        account,
      };
    }
    case 'UPDATE_ACCOUNT': {
      if (action.updateState) {
        const account = { ...action.payload };
        return {
          ...state,
          account,
        };
      }
      return state;
    }
    case 'CHANGE_PASSWORD': {
      const account = { ...action.payload };
      return {
        ...state,
        account,
      };
    }
    case 'VERIFY_TOKEN':
    case ACCOUNT_EVENTS.VERIFICATION_EVENT: {
      try {
        const data = JSON.parse(action.payload);
        const { emailVerified, phoneVerified } = jwt.decode(data.token);
        localStorage.setItem('accessToken', data.token);
        return {
          ...state,
          auth: {
            ...state.auth,
            token: data.token,
          },
          account: {
            ...state.account,
            phone: data.phone || state.account.phone,
            emailVerified,
            phoneVerified,
          },
          isAdmin: get(jwt.decode(data.token), 'isAdmin', false),
        };
      } catch (err) { break; }
    }
    case ACCOUNT_EVENTS.SUBSCRIPTION_FAST_SPRING_EVENT:
    case ACCOUNT_EVENTS.SUBSCRIPTION_EVENT: {
      try {
        const data = JSON.parse(action.payload);
        if (data.card) {
          localStorage.setItem('accessToken', data.token);
          return {
            ...state,
            auth: {
              ...state.auth,
              token: data.token,
            },
            account: {
              ...state.account,
              card: data.card,
            },
          };
        } if (data.token) {
          localStorage.setItem('accessToken', data.token);
          return {
            ...state,
            auth: {
              ...state.auth,
              token: data.token,
            },
          };
        }
        return state;
      } catch (err) { break; }
    }
    case 'USER_ACCOUNT_SESSIONS': {
      const userSessions = [...action.payload];
      return {
        ...state,
        sessions: userSessions,
      };
    }
    case 'USER_SESSION_DISCONNECT': {
      const userSessions = [...state.sessions].filter((session) => session.id !== action.sessionId);
      return {
        ...state,
        sessions: userSessions,
      };
    }
    case ACCOUNT_EVENTS.DISCONNECTION_EVENT: {
      const sessionId = get(jwt.decode(localStorage.getItem('accessToken')), 'sessionId');
      let data;

      try {
        data = JSON.parse(action.payload);
      } catch { return state; }

      if (data?.excludeSessionIds && data?.excludeSessionIds?.includes(sessionId)) {
        return state;
      }
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      return {
        ...initialState,
        plugin: state.plugin,
        auth: {
          token: null,
        },
      };
    }
    case 'GET_USER_AFFILIATES': {
      return {
        ...state,
        affiliateReferred: action.payload,
      };
    }
    case 'GET_AVATAR_PRE_SIGNED': {
      return {
        ...state,
        avatar: {
          ...state.avatar,
          bucketUrl: action.payload.url,
          fields: action.payload.fields,
          file: action.file,
        },
      };
    }
    case 'LOAD_AVATAR_TO_S3': {
      const avatar = { src: `${state.avatar.bucketUrl}/${state.avatar.fields.Key}` };

      return {
        ...state,
        avatar,
      };
    }
    case 'REMOVE_USER_AVATAR': {
      return {
        ...state,
        avatar: initialState.avatar,
      };
    }
    case 'GET_USER_INFO': {
      return {
        ...state,
        info: { ...action.payload },
      };
    }
    case 'DELETE_USER': {
      return {
        ...state,
      };
    }
    case 'UPDATE_LOCAL_SETTINGS': {
      const { key, columnsConfiguration } = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          [key]: { ...columnsConfiguration },
        },
      };
    }

    case ACCOUNT_EVENTS.CREDITS_EVENT: {
      try {
        const data = JSON.parse(action.payload);
        const type = get(data, 'type');
        const current = parseInt(get(data, 'credits', 0), 10);
        const total = parseInt(get(data, 'maxAvailableCredits', 0), 10);
        if (window?.Intercom && type) {
          window.Intercom('update', {
            custom_attributes: {
              [`${startCase(type)}Emails`]: current,
              [`${startCase(type)}Limit`]: total,
            },
          });
        }
        return {
          ...state,
          credits: state.credits.map((item) => {
            if (item.type === type) {
              return {
                type,
                current,
                total: total || item.total,
              };
            }
            return item;
          }),
        };
      } catch {
        return state;
      }
    }
    case 'GET_USER_CREDITS': {
      return {
        ...state,
        credits: action.payload.map((credits) => ({
          type: credits.type,
          current: credits.credits,
          total: credits.maxAvailableCredits,
        })),
      };
    }

    case 'TOGGLE_COLLAPSE': {
      const { step, group } = action.data;
      const newCollapseState = { ...state.collapseState };
      if (typeof step === 'string') newCollapseState.step = step;
      if (typeof group === 'string') newCollapseState.group = group;
      return {
        ...state,
        collapseState: newCollapseState,
      };
    }

    case 'GET_USER_CREDITS_HISTORY': {
      const { data, meta } = action.payload;
      return {
        ...state,
        creditsHistory: {
          ...state.creditsHistory,
          data: [...data],
          ...meta,
        },
      };
    }

    case 'CHANGE_WORKSPACE_ADMIN':
    case 'GET_USER_WORKSPACES': {
      const currentWorkspace = get(jwt.decode(localStorage.getItem('accessToken')), 'workspaceId', state?.currentWorkspace);
      if (action.payload?.error) {
        return {
          ...state,
          currentWorkspace,
        };
      }
      return {
        ...state,
        currentWorkspace,
        workspaces: action.payload || state.workspaces,
      };
    }

    case 'ADD_NEW_WORKSPACE': {
      const newWorkspaces = [...state.workspaces];
      if (action.payload && !newWorkspaces.some((item) => item._id === get(action, 'payload._id'))) newWorkspaces.push(action.payload);
      if (action.data && !newWorkspaces.some((item) => item._id === get(action, 'data._id'))) newWorkspaces.push(action.data);
      return {
        ...state,
        workspaces: newWorkspaces,
      };
    }

    case 'RENAME_OWN_WORKSPACE': {
      return {
        ...state,
        workspaces: state.workspaces.map((workspace) => {
          if (workspace.workspaceId === action.data.workspaceId) {
            return {
              ...workspace,
              name: action.data.name,
            };
          }
          return workspace;
        }),
      };
    }

    case 'SET_SHOW_CREDITS_BAR': {
      const { value = initialState.showCreditsBar } = action.data;

      return {
        ...state,
        showCreditsBar: value,
      };
    }

    case 'SET_QUESTION_ANSWER': {
      const { answer, type } = action.data;
      return {
        ...state,
        questions: {
          ...state.questions,
          [type]: answer,
        },
      };
    }

    case 'CHANGE_USER_STATUS': {
      const { status } = action.data;
      return {
        ...state,
        account: {
          ...state.account,
          status,
        },
      };
    }

    case 'REQUIRE_USER_VERIFICATION': {
      return {
        ...state,
        account: {
          ...state.account,
          status: USER_STATUS.PENDING_REVIEW,
        },
      };
    }

    case ACCOUNT_EVENTS.ALLOW_PAYMENT_EVENT: {
      const { allow } = action.data || JSON.parse(action.payload);
      return {
        ...state,
        account: {
          ...state.account,
          status: allow ? USER_STATUS.ALLOWED_PAYMENT : USER_STATUS.NOT_ALLOWED_PAYMENT,
        },
      };
    }

    case 'GET_CREDITS_ANALYTICS': {
      return {
        ...state,
        creditsAnalytics: [...action.payload],
      };
    }

    case 'EXPORT_USER_CREDITS_HISTORY': {
      fileDownload(action.payload, 'GP_usage_history.csv');
      return state;
    }

    case 'GET_WORKSPACE_ANALYTICS': {
      const data = action.payload;

      if (data?.length) {
        data.forEach((item) => {
          const foundedWorkspace = state.workspaces.find(({ workspaceId }) => item.workspaceId === workspaceId);

          if (foundedWorkspace) {
            item.name = foundedWorkspace.name;
          }
        });

        return {
          ...state,
          workSpaceAnalytics: [...data],
        };
      }

      return {
        ...state,
      };
    }

    case 'RENAME_WORKSPACE_BY_ID': {
      const data = action.payload;

      if (data) {
        const { _id, name, workspaceId } = data;
        const updatedWorkspaces = [...state.workspaces];
        const updatedAnalytics = [...state.workSpaceAnalytics];

        updatedWorkspaces.forEach((workspace) => {
          if (workspace._id === _id) {
            workspace.name = name;
          }
        });

        updatedAnalytics.forEach((item) => {
          if (item.workspaceId === workspaceId) {
            item.name = name;
          }
        });

        return {
          ...state,
          workspaces: updatedWorkspaces,
          workSpaceAnalytics: updatedAnalytics,
        };
      }

      return {
        ...state,
      };
    }

    case 'DELETE_SINGLE_WORKSPACE': {
      const { workspaceId } = action;

      if (workspaceId) {
        const workspaceIndex = state.workspaces.findIndex((workspace) => workspace.workspaceId === workspaceId);
        const analyticIndex = state.workSpaceAnalytics.findIndex((workspace) => workspace.workspaceId === workspaceId);
        const updatedWorkspaces = [...state.workspaces];
        const updatedAnalytics = [...state.workSpaceAnalytics];

        if (workspaceIndex !== -1) {
          updatedWorkspaces.splice(workspaceIndex, 1);
        }

        if (analyticIndex !== -1) {
          updatedAnalytics.splice(analyticIndex, 1);
        }

        return {
          ...state,
          workspaces: updatedWorkspaces,
          workSpaceAnalytics: updatedAnalytics,
        };
      }

      return {
        ...state,
      };
    }

    case 'GET_WORKSPACE_BILLING_INFO': {
      if (action.payload) {
        const workspaceBilling = {};
        Object.keys(action.payload).forEach((key) => {
          workspaceBilling[key] = action.payload[key] || '';
        });
        return {
          ...state,
          workspaceBilling,
        };
      }
      return {
        ...state,
      };
    }

    case 'UPDATE_Workspace_BILLING_INFO': {
      if (action.payload) {
        const workspaceBilling = {};
        Object.keys(action.payload).forEach((key) => {
          workspaceBilling[key] = action.payload[key] || '';
        });
        return {
          ...state,
          workspaceBilling,
        };
      }
      return {
        ...state,
      };
    }

    case 'GET_USERS_WORKSPACES': {
      return {
        ...state,
        usersWorkspaces: [...action.payload],
      };
    }

    case 'AUTHORIZE_USER': {
      const { code } = action.payload;
      return {
        ...state,
        userHaveOAuthCode: !!code,
      };
    }

    case 'APPLY_AFFILIATE_CODE': {
      return state;
    }

    case 'UPDATE_WORKSPACE_LIMIT': {
      return {
        ...state,
        limits: {
          ...(state.limits || {}),
          [action.data.limit.key]: action.data.limit.value,
        },
      };
    }

    case 'GET_WORKSPACE_LIMITS': {
      return {
        ...state,
        limits: action.payload,
      };
    }

    case 'SAVE_QUALIFICATION': {
      return {
        ...state,
        account: {
          ...state.account,
          qualification: get(action, 'payload._id'),
        },
      };
    }

    case 'GET_WORKSPACE_ACTIVITY': {
      return {
        ...state,
        activities: {
          ...state.activities,
          viewed_lists: get(get(action, 'payload', []).find((activity) => activity.type === 'list_view'), 'value', []),
          initialResponse: true,
        },
      };
    }

    case 'SET_IS_DEMO_USER': {
      return {
        ...state,
        isDemoUser: action.data.isDemoUser,
      };
    }

    case 'TRANSFER_TRUEMAIL_USER': {
      return state;
    }

    case 'CREATE_API_KEY': {
      return {
        ...state,
        apiKeys: [action.payload, ...state.apiKeys],
      };
    }

    case 'GET_API_KEYS': {
      return {
        ...state,
        apiKeys: action.payload,
      };
    }

    case 'DELETE_API_KEY': {
      const { applicationId } = action;

      return {
        ...state,
        apiKeys: state.apiKeys.filter((apiKey) => apiKey._id !== applicationId),
      };
    }

    case 'MOVE_DEMO_TO_PROD': {
      const { code } = action?.data ?? {};

      if (!code) { return state; }
      return {
        ...state,
        demoServiceError: code,
      };
    }

    case 'CLEAR_DEMO_ERROR': {
      return {
        ...state,
        demoServiceError: initialState.demoServiceError,
      };
    }

    case 'GET_USER_IP': {
      const ip = action.payload;

      return {
        ...state,
        demoUserIp: ip,
      };
    }

    case 'SET_IS_PRICING_VISITED': {
      return {
        ...state,
        pricingVisited: true,
      };
    }

    case 'SWITCH_WORKSPACE': {
      return {
        ...state,
        currentWorkspace: jwt.decode(action.payload.token)?.workspaceId || state.currentWorkspace,
        isAdmin: get(jwt.decode(action.payload.token), 'isAdmin', false),
        activities: initialState.activities,
        creditsHistory: initialState.creditsHistory,
      };
    }

    case 'GET_USER_EXTENSION': {
      return {
        ...state,
        extension: action.payload,
      };
    }

    case 'UPDATE_SIGN_UP_SHOW_LAST_STEP': {
      return {
        ...state,
        showLastStep: action?.data?.isShowLastStep,
      };
    }

    case 'UPDATE_SIGN_UP_ADDITIONAL_DATA': {
      const { additionalData } = action?.data;

      const updatedState = {
        ...state,
      };

      if (additionalData?.demoSearchLeadsDone) {
        updatedState.additionalData.skipLeadsTour = true;
      }

      return updatedState;
    }

    case 'SET_RECENT_ACTIVITY': {
      const { data, source } = action.data;
      let newData;
      if (!data?._id) data._id = data.id;
      if (source === 'contacts') {
        newData = {
          _id: data._id || data.id,
          position: data?.companyRelations?.[0]?.position || data?.companies?.[0]?.position || data?.position,
          company: data?.companyRelations?.[0]?.company?.properties?.name?.value || data?.companies?.[0]?.company?.name || data?.company,
          location: data?.properties?.location?.value || data?.companyRelations?.[0]?.company?.properties?.headquarters?.value || data?.location,
          firstName: data?.properties?.firstName?.value || data?.firstName,
          lastName: data?.properties?.lastName?.value || data?.lastName,
          source: data.source,
        };
      } else {
        newData = {
          domain: data?.domain || data?.properties?.domain?.value,
          name: data?.name || data?.properties?.name?.value,
          industry: data?.industry || data?.properties?.industry?.value,
          size: data?.size || data?.properties?.size?.value,
          headquarters: data?.headquarters || data?.properties?.headquarters?.value,
          _id: data._id || data.id,
          source: data.source,
        };
      }
      if (state.recentActivity[source].find((item) => item?._id === data?._id)) return state;
      const newActivity = [...state.recentActivity[source] || []];
      newActivity.unshift(newData);
      return {
        ...state,
        recentActivity: {
          ...state.recentActivity,
          [source]: newActivity?.length > 3 ? newActivity.slice(0, 3) : newActivity,
        },
      };
    }

    case 'GET_RECENT_ACTIVITY': {
      if (!action.payload) return state;
      return {
        ...state,
        recentActivity: action.payload,
      };
    }
    case 'CREATE_OR_UPDATE_RECENT_ACTIVITY': {
      return state;
    }

    case 'CHECK_GOOGLE_SHEETS_PLUGIN': {
      return {
        ...state,
        googleSheetsPlugin: {
          installed: !action.data?.error,
        },
      };
    }

    case 'EXTENSION_AUTH': {
      return {
        ...state,
        plugin: {
          ...state.plugin,
          auth: {
            active: action.data.active,
            options: action.data.options,
          },
        },
      };
    }

    case 'UPDATE_FEEDBACK':
    case 'SAVE_FEEDBACK':
    case 'GET_LAST_NPS_SCORE_FEEDBACK': {
      return {
        ...state,
        nspScoreFeedback: action.payload,
      };
    }

    case 'CLOSE_BLACK_FRIDAY_BANNER': {
      return {
        ...state,
        closeBlackFridayBanner: true,
      };
    }

    default: return state;
  }
  return state;
};
