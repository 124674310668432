import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';

import { SEARCH_KIND } from 'constants/searchContent';
import { AWS_FILE_TYPES } from 'data/types/aws.types';
import { CLOSE_EVENT_SOURCE_CONNECTION } from 'data/types/event.types';

export const accountEventsSubscription = () => (dispatch) => {
  const closeEventSourceConnections = new Event(CLOSE_EVENT_SOURCE_CONNECTION);
  window.dispatchEvent(closeEventSourceConnections);

  dispatch({
    type: 'ACCOUNT_EVENTS_SUBSCRIPTION',
    payload: {
      event: 'api/v1/events',
    },
  });
};

export const searchCompanyByClearbit = (query, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'SEARCH_COMPANY_BY_CLEARBIT',
    payload: {
      endpoint: 'https://autocomplete.clearbit.com/v1/companies/suggest',
      method: 'GET',
      params: { query },
      custom: true,
      cancelPrevRequests: true,
    },
    debounce: {
      time: 200,
    },
    onSuccess,
    onFailed,
  });
};

export const subscribeActions = (data) => (dispatch) => {
  dispatch({
    type: 'SUBSCRIBE_ACTIONS',
    data,
  });
};

export const unsubscribeActions = (data) => (dispatch) => {
  dispatch({
    type: 'UNSUBSCRIBE_ACTIONS',
    data,
  });
};

export const createUserSearch = ({
  filters,
  sourceType,
  onSuccess = () => {},
  onFailed = () => {},
}) => (dispatch) => {
  dispatch({
    type: 'CREATE_USER_SEARCH',
    payload: {
      endpoint: 'api/v1/insights/search',
      method: 'POST',
      body: { filters, kind: SEARCH_KIND.USER_SEARCH, source: sourceType },
      showResponseError: true,
      delayLoad: 0,
    },
    onSuccess,
    onFailed,
  });
};

export const uploadImageToCDN = ({
  file,
  prefix,
  onSuccess = () => {},
  onFailed = () => {},
}) => (dispatch) => {
  const fileType = get(file, 'meta.type', '');

  dispatch({
    type: 'GET_IMAGE_PRE_SIGNED',
    payload: {
      endpoint: 'aws/pre-signed',
      method: 'POST',
      body: {
        prefix,
        contentType: fileType,
        preSignedType: AWS_FILE_TYPES.CDN,
      },
    },
    onFailed,
    onSuccess: (data) => {
      const { fields, url } = data;
      const form = new FormData();
      Object.keys(fields).forEach((key) => {
        form.append(key, fields[key]);
      });

      form.append('file', get(file, 'data', null), get(file, 'meta.name', ''));

      dispatch({
        type: 'UPLOAD_IMAGE_TO_S3',
        payload: {
          custom: true,
          endpoint: url,
          method: 'POST',
          body: form,
        },
        onSuccess: () => {
          onSuccess(data);
        },
        onFailed,
      });
    },
  });
};

export const createQuitReport = ({
  type,
  action,
  details,
  onSuccess = () => {},
}) => (dispatch) => {
  dispatch({
    type: `CREATE_QUIT_REPORT_${snakeCase(action).toUpperCase()}`,
    payload: {
      endpoint: 'api/v1/quit-reports',
      method: 'POST',
      body: {
        type,
        action,
        ...details,
      },
      showResponseError: true,
      delayLoad: 0,
    },
    onSuccess,
    components: ['createQuitReportLoader'],
  });
};

export const flagInaccurateFields = (inaccurateFields) => (dispatch) => {
  dispatch({
    type: 'FLAG_INACCURATE_FIELDS',
    payload: {
      endpoint: 'api/v1/inaccurate-field',
      method: 'POST',
      body: {
        inaccurateFields,
      },
      showResponseError: true,
    },
    inaccurateFields,
  });
};

export const getEntityHistory = ({
  source,
  entityId,
  onSuccess,
  onFailed,
  isSidebar,
}) => (dispatch) => {
  dispatch({
    type: `GET_${source.toUpperCase()}_HISTORY`,
    payload: {
      endpoint: `api/v1/history/${source}/${entityId}`,
      method: 'GET',
      showResponseError: true,
      delayLoad: 0,
    },
    isSidebar,
    onSuccess,
    onFailed,
    components: [isSidebar ? 'getHistorySidebarLoading' : 'getHistoryLoading'],
  });
};
