export const countSymbolsWithoutSpaces = (word) => {
  if (!word) return 0;
  return word.split(' ').filter(Boolean).reduce((a, b) => a + b.length, 0);
};

export const getTextContentOfReactElement = (elem) => {
  if (!elem) {
    return '';
  }
  if (typeof elem === 'string') {
    return elem;
  }
  const children = elem.props && elem.props.children;
  if (children instanceof Array) {
    return children.map(getTextContentOfReactElement).join('');
  }
  return getTextContentOfReactElement(children);
};
