import get from 'lodash/get';
import { removeTime } from 'helpers/dateFormat';
import { STATUSES } from 'constants/emailStatus';
import { SEARCH_KIND } from 'constants/searchContent';
import { SOURCE_TYPE } from 'data/types/source.types';
import { transformFilters } from 'helpers/transformFilters';
import * as SearchFiltersTypes from 'data/types/search.filters.types';
import { workspaceGlobalSettingsSelector } from 'data/selectors/user';
import { bulkSaveContactsFromInsights, searchInsightContacts, setInsightsContactsInitialRequest } from './contacts';
import {
  bulkSaveCompaniesFromInsights, getSavedInsightCompanies, searchInsightCompanies, setInsightsCompaniesInitialRequest,
} from './companies';
import { searchCompanyByClearbit } from './common';

export const setSearchError = (error) => (dispatch) => {
  dispatch({
    type: 'SET_SEARCH_ERROR',
    data: {
      error,
    },
  });
};

export const saveSearch = (search, onSuccess = () => {}, onFailed = () => {}) => (dispatch, getState) => {
  const state = getState();
  const filters = transformFilters(search.filters ?? JSON.parse(JSON.stringify(get(state, 'search.filters'))));

  dispatch({
    type: 'SAVE_SEARCH',
    payload: {
      endpoint: 'api/v1/searches',
      method: 'POST',
      body: { ...search, filters },
      showResponseError: true,
    },
    onSuccess,
    onFailed,
  });
};

export const updateSearch = (savedSearchId, {
  name,
  filters,
  onSuccess = () => {},
  onFailed = () => {},
} = {}) => (dispatch, getState) => {
  const state = getState();
  const transformedFilters = transformFilters(filters ?? JSON.parse(JSON.stringify(get(state, 'search.filters'))));

  dispatch({
    type: 'UPDATE_SEARCH',
    payload: {
      endpoint: `api/v1/searches/${savedSearchId}`,
      method: 'PUT',
      body: { name, filters: transformedFilters },
      showResponseError: true,
    },
    onSuccess,
    onFailed,
  });
};

export const getSavedSearchOptions = (pageNumber = 1, sourceType) => (dispatch) => {
  dispatch({
    type: 'GET_SAVED_SEARCH_OPTIONS',
    payload: {
      endpoint: 'api/v1/searches',
      method: 'GET',
      params: {
        sourceType,
        pageNumber,
        pageSize: 100,
      },
    },
    components: ['savedSearchOptionsLoader'],
  });
};

export const setInitialRequestSearch = (type, initialRequest) => (dispatch) => {
  dispatch({
    type: 'SET_INITIAL_REQUEST_SEARCH',
    data: { type, initialRequest },
  });
};

export const getDashboardSearches = () => (dispatch) => {
  dispatch({
    type: 'GET_DASHBOARD_SEARCHES',
    payload: {
      endpoint: 'api/v1/searches',
      method: 'GET',
      params: {
        pageNumber: 1,
        pageSize: 4,
      },
    },
    components: ['searchLoading'],
  });
};

export const getSearches = ({
  pageSize,
  page,
  sort,
  sourceType,
  query = '',
  onSuccess = () => {},
  onFailed = () => {},
  erasePreviousData = true,
}) => (dispatch, getState) => {
  const state = getState();
  const currentPageSize = get(state, 'contacts.insights.pageSize');
  const currentPage = get(state, 'contacts.insights.page');

  const params = {
    pageSize: pageSize || currentPageSize,
    pageNumber: page || currentPage,
    sourceType,
    query,
  };

  if (sort) {
    params.sort = sort.column;
    params.order = sort.order;
  }

  const onFailedProxy = (error) => {
    dispatch(setInitialRequestSearch('searches', false));
    onFailed(error);
  };

  dispatch({
    type: 'GET_SEARCHES',
    payload: {
      endpoint: 'api/v1/searches',
      method: 'GET',
      params,
    },
    erasePreviousData,
    components: ['searchLoading'],
    onSuccess,
    onFailed: onFailedProxy,
  });
};

export const findPositions = ({ name = '', limit = 5 }) => (dispatch) => {
  dispatch({
    type: 'GET_POSITIONS',
    payload: {
      endpoint: `api/v1/insights/search/autocomplete/position?q=${name}&limit=${limit}`,
      method: 'GET',
    },
    components: ['positionsLoader'],
  });
};

export const findIndustry = (name = '', limit = 5) => (dispatch) => {
  dispatch({
    type: 'GET_INDUSTRIES',
    payload: {
      endpoint: `api/v1/insights/search/autocomplete/industry?q=${name}&limit=${limit}`,
      method: 'GET',
    },
    components: ['industriesLoader'],
  });
};

export const addOptionToIncludedFilters = ({
  property, includedOp, element, splittable, updateOnly, checkboxes,
}) => (dispatch, getState) => {
  dispatch({
    type: 'ADD_OPTION_TO_INCLUDED_FILTERS',
    data: {
      property,
      includedOp,
      element,
      splittable,
      updateOnly,
      checkboxes,
    },
  });

  const state = getState();
  const positions = get(get(state, 'search.filters').find((filter) => filter?.property === property), 'included.value', []);

  if (property === 'company.position' && positions.length > 0) {
    dispatch({
      type: 'AUTOCOMPLETE_POSITION_OPTIONS',
      payload: {
        endpoint: 'api/v1/insights/search/position/autocomplete',
        method: 'POST',
        body: {
          positions,
        },
      },
    });
  }
};

export const changeIncludeUnknownFilters = ({
  includedUnknown,
  property,
}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_INCLUDE_UNKNOWN_FILTERS',
    data: {
      includedUnknown,
      property,
    },
  });
};

export const updateFiltersCheckboxes = ({ checkboxes, property }) => (dispatch) => {
  dispatch({
    type: 'UPDATE_FILTERS_CHECKBOXES',
    data: {
      checkboxes,
      property,
    },
  });
};

export const fetchSavedSearchById = ({ id, onSuccess = () => {} }) => (dispatch) => {
  dispatch({
    type: 'GET_SINGLE_SAVED_SEARCH',
    payload: {
      endpoint: `api/v1/searches/${id}`,
      method: 'GET',
    },
    onSuccess,
  });
};

export const addOptionToExcludedFilters = ({
  property, excludedOp, element,
}) => (dispatch) => {
  dispatch({
    type: 'ADD_OPTION_TO_EXCLUDED_FILTERS',
    data: {
      property,
      excludedOp,
      element,
    },
  });
};

export const removePropertyFromFilter = ({ property }) => (dispatch) => {
  dispatch({
    type: 'REMOVE_PROPERTY_FROM_FILTERS',
    data: {
      property,
    },
  });
};

export const removeOptionFromFilters = ({ element, property }) => (dispatch, getState) => {
  dispatch({
    type: 'REMOVE_OPTION_FROM_FILTERS',
    data: {
      property,
      element,
    },
  });

  const state = getState();
  const positions = get(get(state, 'search.filters').find((filter) => filter?.property === property), 'included.value', []);

  if (property === 'company.position' && positions.length > 0) {
    dispatch({
      type: 'AUTOCOMPLETE_POSITION_OPTIONS',
      payload: {
        endpoint: 'api/v1/insights/search/position/autocomplete',
        method: 'POST',
        body: {
          positions,
        },
      },
    });
  }
};

export const clearSearchFilter = (sourceType) => (dispatch) => {
  dispatch({
    type: 'CLEAR_SEARCH_FILTER',
    data: {
      sourceType,
    },
  });
};

export const getSeniorityLevelOptions = () => (dispatch) => {
  dispatch({
    type: 'GET_SENIORITY_OPTIONS',
    data: {},
  });
};

export const getCompanyTypeOptions = () => (dispatch) => {
  dispatch({
    type: 'GET_COMPANY_TYPE_OPTIONS',
    data: {},
  });
};

export const getContactEmailOptions = () => (dispatch, getState) => {
  const state = getState();
  const workspaceSettings = workspaceGlobalSettingsSelector(state);
  dispatch({
    type: 'GET_EMAIL_OPTIONS',
    data: {
      validOnly: !workspaceSettings?.showAcceptAllEmails,
    },
  });
};

export const getPositionOptions = ({ name = '', limit = 5 }) => (dispatch) => {
  dispatch({
    type: 'GET_POSITION_OPTIONS',
    payload: {
      endpoint: `api/v1/insights/search/autocomplete/position?q=${name}&limit=${limit}`,
      method: 'GET',
    },
  });
};

export const getDepartmentsOptions = ({ name = '', limit }) => (dispatch) => {
  dispatch({
    type: 'GET_DEPARTMENTS_OPTIONS',
    payload: {
      endpoint: `api/v1/insights/search/autocomplete/departments?q=${name}${limit ? `&limit=${limit}` : ''}`,
      method: 'GET',
    },
  });
};

export const departmentsOptionAutocomplete = ({ name = '' }) => (dispatch) => {
  dispatch({
    type: 'AUTOCOMPLETE_DEPARTMENT_OPTIONS',
    data: {
      name,
    },
  });
};

export const contactKeywordsAutocomplete = (name = '') => (dispatch) => {
  dispatch({
    type: 'AUTOCOMPLETE_CONTACT_KEYWORDS_OPTIONS',
    data: {
      name,
    },
  });
};

export const contactNameAutocomplete = (name = '') => (dispatch) => {
  dispatch({
    type: 'AUTOCOMPLETE_CONTACT_NAME_OPTIONS',
    data: {
      name,
    },
  });
};

export const getContactLocationOptions = (name = '', limit = 10, type = 'region') => (dispatch) => {
  dispatch({
    type: 'GET_CONTACT_LOCATION_OPTIONS',
    payload: {
      endpoint: `api/v1/insights/search/autocomplete/location?q=${name}&limit=${limit}&type=${type}`,
      method: 'GET',
    },
  });
};

export const getCompanyLocationOptions = (name = '', limit = 10, type = 'region') => (dispatch) => {
  dispatch({
    type: 'GET_COMPANY_LOCATION_OPTIONS',
    payload: {
      endpoint: `api/v1/insights/search/autocomplete/location?q=${name}&limit=${limit}&type=${type}`,
      method: 'GET',
    },
  });
};

export const getIndustryOptions = (name = '', limit) => (dispatch) => {
  dispatch({
    type: 'GET_INDUSTRY_OPTIONS',
    payload: {
      endpoint: `api/v1/insights/search/autocomplete/industry?q=${name}${limit ? `&limit=${limit}` : ''}`,
      method: 'GET',
    },
  });
};

export const industriesOptionAutocomplete = (name = '') => (dispatch) => {
  dispatch({
    type: 'AUTOCOMPLETE_INDUSTRIES_OPTIONS',
    data: {
      name,
    },
  });
};

export const getEmployeesOptions = () => (dispatch) => {
  dispatch({
    type: 'GET_EMPLOYEES_OPTIONS',
    data: {},
  });
};

export const getLastUpdatedOptions = (sourceType, customOptions = []) => (dispatch) => {
  dispatch({
    type: 'GET_LAST_UPDATED_OPTIONS',
    data: { sourceType, customOptions },
  });
};

export const getTechnologiesOptions = (query = '', limit = 5) => (dispatch) => {
  dispatch({
    type: 'GET_TECHNOLOGIES_OPTIONS',
    payload: {
      endpoint: `api/v1/insights/search/technology/autocomplete?q=${query}&limit=${limit}`,
      method: 'GET',
    },
  });
};

export const setSavedSearchFilter = ({
  filters = [], initRecentActivities = true, saveExisting = false, searchId,
}) => (dispatch) => {
  let sourceType = null;
  let value = null;
  if (filters.some((filter) => {
    if ([SearchFiltersTypes.CONTACT_UPDATED_AT, SearchFiltersTypes.COMPANY_UPDATED_AT].includes(filter.property)) {
      if (filter.property === SearchFiltersTypes.CONTACT_UPDATED_AT) {
        sourceType = SOURCE_TYPE.CONTACT;
      } else if (filter.property === SearchFiltersTypes.COMPANY_UPDATED_AT) {
        sourceType = SOURCE_TYPE.COMPANY;
      }
      [value] = filter.included.value;
      return true;
    }
    return false;
  })) {
    dispatch(getLastUpdatedOptions(sourceType, [{
      label: `${removeTime(value[0])} - ${removeTime(value[1])}`,
      value,
    }]));
  }
  dispatch({
    type: 'SET_SAVED_SEARCH_FILTER',
    data: {
      filters, initRecentActivities, searchId, saveExisting,
    },
  });
};

export const getCompanyNameOptions = (name = '') => (dispatch) => {
  const getCompanyNameOptionsAction = (companies) => {
    dispatch({
      type: 'GET_COMPANY_NAME_OPTIONS',
      data: companies,
    });
  };

  dispatch(searchCompanyByClearbit(name, getCompanyNameOptionsAction, () => getCompanyNameOptionsAction([])));
};

export const getCompanyKeywordOptions = (query = '', limit = 5) => (dispatch) => {
  if (!dispatch) return;

  dispatch({
    type: 'GET_COMPANY_KEYWORD_OPTIONS',
    payload: {
      endpoint: `api/v1/insights/search/company/keyword/autocomplete?q=${query}&limit=${limit}`,
      method: 'GET',
    },
  });
};

export const getCompanyDomainOptions = (domain = '') => (dispatch) => {
  const getCompanyDomainOptionsAction = (companies) => {
    dispatch({
      type: 'GET_COMPANY_DOMAIN_OPTIONS',
      data: companies,
    });
  };

  dispatch(searchCompanyByClearbit(domain, getCompanyDomainOptionsAction, () => getCompanyDomainOptionsAction([])));
};

export const findCountries = (
  pageSize = 10,
  pageNumber = 1,
  q = '',
  code = '',
) => (dispatch) => {
  const params = {
    pageSize,
    pageNumber,
    q,
    code,
  };
  dispatch({
    type: 'GET_COUNTRIES',
    payload: {
      endpoint: 'api/v1/insights/search/autocomplete/country',
      method: 'GET',
      params,
    },
  });
};

export const createInsightsSearch = ({
  selected,
  limits,
  listIds,
  source,
  saveAdded,
  savePhoneNumbers,
  selectedAll,
  customFilters,
  selectedItems,
  sequenceId,
  isDemo = false,
  onSuccess = () => {},
  onFailed = () => {},
}) => (dispatch, getState) => {
  const state = getState();
  const insightIds = selectedItems || get(state, source === SOURCE_TYPE.CONTACT ? 'contacts.selectedInsightContacts' : 'companies.selectedInsightCompanies');
  const existedInsightIds = insightIds && insightIds.length > 0;
  let relations;

  if (source === SOURCE_TYPE.CONTACT && existedInsightIds && !selectedAll) {
    relations = get(state, 'contacts.insights.data')
      .filter((contact) => insightIds.includes(get(contact, 'id')))
      .map((contact) => {
        if (get(contact, 'companies')) {
          return get(contact, 'companies')
            .map((relation) => {
              const email = get(relation, 'email');
              const item = { contact: get(contact, 'id'), company: get(relation, 'company.id', get(relation, 'company._id')) };
              if (email && ![STATUSES.PROCESSING].includes(get(email, 'status'))) item.email = email;
              return item;
            });
        }
        return null;
      })
      .filter((relation) => relation)
      .flat();
  }

  const onSuccessProxy = (result) => {
    if (existedInsightIds) {
      if (source === SOURCE_TYPE.CONTACT) {
        dispatch(bulkSaveContactsFromInsights({ insightIds, bulk: result.bulk }));
        dispatch({
          type: 'UPDATE_INSIGHTS_CONTACT_STATUS',
          data: {
            isSaving: true,
            insightIds,
          },
        });
      } else {
        dispatch(bulkSaveCompaniesFromInsights({ insightIds, bulk: result.bulk }));
      }
    }
    onSuccess(result);
  };

  let filters = customFilters || JSON.parse(JSON.stringify(get(state, 'search.filters', [])));
  const filtersTypes = source === SOURCE_TYPE.CONTACT ? SearchFiltersTypes.CONTACT_SEARCH_FILTERS : SearchFiltersTypes.COMPANY_SEARCH_FILTERS;
  const updatedFilters = filters.filter((filter) => filtersTypes.includes(filter.property));
  const components = ['insightsSearchLoading'];
  if (existedInsightIds) {
    components.push('bulkInsightsSearchLoading');
  }
  const companyDomainPropertyIndex = filters.findIndex((item) => item.property === 'company.domain');

  if (companyDomainPropertyIndex > -1 && 'map' in filters[companyDomainPropertyIndex].included && 'map' in filters[companyDomainPropertyIndex].excluded) {
    filters[companyDomainPropertyIndex].included.value = filters[companyDomainPropertyIndex].included.value.map((item) => filters[companyDomainPropertyIndex].included.map.find((innerItem) => innerItem?.printedData === item)?.domain ?? item);
    filters[companyDomainPropertyIndex].excluded.value = filters[companyDomainPropertyIndex].excluded.value.map((item) => filters[companyDomainPropertyIndex].excluded.map.find((innerItem) => innerItem?.printedData === item)?.domain ?? item);
    delete filters[companyDomainPropertyIndex].excluded.map;
    delete filters[companyDomainPropertyIndex].included.map;
  }

  filters = filters.map((updatedFilter) => {
    const transformData = (dataItem) => {
      let returningData = dataItem;

      if (typeof dataItem === 'string') returningData = dataItem;
      if (typeof dataItem === 'object') {
        if ('file' in dataItem) {
          returningData = {
            file: dataItem.file,
            matching: dataItem.matching,
            printedData: dataItem.printedData,
          };
        }
      }
      return returningData;
    };
    const updatedIncludes = updatedFilter?.included?.value.map(transformData);
    const updatedExcludes = updatedFilter?.excluded?.value.map(transformData);

    if (updatedIncludes) updatedFilter.included.value = updatedIncludes;
    if (updatedExcludes) updatedFilter.excluded.value = updatedExcludes;
    return updatedFilter;
  });

  dispatch({
    type: 'CREATE_INSIGHTS_SEARCH',
    payload: {
      endpoint: 'api/v1/insights/search',
      method: 'POST',
      body: {
        filters: updatedFilters,
        limits,
        kind: SEARCH_KIND.SEARCH_HISTORY,
        selected,
        source,
        listIds,
        saveAdded,
        savePhoneNumbers,
        relations,
        sequenceId,
        isDemo,
        insightIds: selectedAll ? [] : insightIds,
      },
      delayLoad: 0,
      showResponseError: true,
    },
    components,
    onSuccess: onSuccessProxy,
    onFailed,
  });
};

export const replaceSearchFilter = ({ operator, property, value }) => (dispatch) => {
  dispatch({
    type: 'REPLACE_SEARCH_FILTER',
    data: {
      operator,
      property,
      value,
    },
  });
};

export const setInsightsSourceType = (sourceType) => (dispatch) => {
  dispatch({
    type: 'SET_INSIGHTS_SOURCE_TYPE',
    data: { sourceType },
  });
};

export const setSelectedContactTab = (selectedTab) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_CONTACT_TAB',
    data: { selectedTab },
  });
};

export const setSelectedCompanyTab = (selectedTab) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_COMPANY_TAB',
    data: { selectedTab },
  });
};

export const getDashboardSearchesHistory = () => (dispatch) => {
  dispatch({
    type: 'GET_DASHBOARD_SEARCHES_HISTORY',
    payload: {
      endpoint: 'api/v1/insights/search/history',
      method: 'GET',
      params: {
        sourceType: SOURCE_TYPE.CONTACT,
        pageNumber: 1,
        pageSize: 4,
      },
      delayLoad: 0,
    },
    components: ['searchHistoryLoading'],
  });
};

export const getManagedSearchesHistory = ({
  pageSize,
  page,
  sort,
  query = '',
  onSuccess = () => {},
  onFailed = () => {},
  erasePreviousData = true,
}) => (dispatch, getState) => {
  const state = getState();
  const currentPageSize = get(state, 'contacts.insights.pageSize');
  const currentPage = get(state, 'contacts.insights.page');

  const params = {
    pageSize: pageSize || currentPageSize,
    pageNumber: page || currentPage,
    query,
  };

  if (sort) {
    params.sort = sort.column;
    params.order = sort.order;
  }

  const onFailedProxy = (error) => {
    dispatch(setInitialRequestSearch('searchesHistory', false));
    onFailed(error);
  };

  dispatch({
    type: 'GET_MANAGED_SEARCHES_HISTORY',
    payload: {
      endpoint: 'api/v1/insights/search/history',
      method: 'GET',
      params,
    },
    erasePreviousData,
    components: ['searchHistoryLoading'],
    onSuccess,
    onFailed: onFailedProxy,
  });
};

export const searchInsights = (sourceType, params) => (dispatch) => {
  if (sourceType === SOURCE_TYPE.CONTACT) {
    return dispatch(searchInsightContacts(params));
  } if (sourceType === SOURCE_TYPE.COMPANY) {
    return dispatch(searchInsightCompanies(params));
  }
  return params?.onFailed(new Error('Search insights: An unknown source type'));
};

export const setInsightInitialRequest = (sourceType, initialRequest) => (dispatch) => {
  if (sourceType === SOURCE_TYPE.CONTACT) {
    return dispatch(setInsightsContactsInitialRequest(initialRequest));
  } if (sourceType === SOURCE_TYPE.COMPANY) {
    return dispatch(setInsightsCompaniesInitialRequest(initialRequest));
  }
  throw new Error('Initial request: An unknown source type');
};

export const renameSavedSearch = (savedSearchId, newName) => (dispatch) => {
  dispatch({
    type: 'RENAME_SAVED_SEARCH',
    payload: {
      endpoint: `api/v1/searches/${savedSearchId}`,
      method: 'PATCH',
      body: { newName },
      showResponseError: true,
    },
    savedSearchId,
    newName,
  });
};

export const clearSearchImportPreprocess = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_SEARCH_IMPORT_PREPROCESS',
    data: {},
  });
};

export const addFilterMatchHeader = (data = {}) => (dispatch) => {
  dispatch({
    type: 'ADD_MATCHING_FILTER_HEADER',
    data,
  });
};

export const addImportIntoFilter = ({
  property, filterLabel, count, delimiter,
}) => (dispatch) => {
  dispatch({
    type: 'ADD_OPTION_TO_INCLUDED_FILTERS',
    data: {
      count,
      property,
      delimiter,
      includedOp: 'EQ',
      element: filterLabel,
      splittable: false,
      isImportFileOption: true,
    },
  });
  dispatch(clearSearchImportPreprocess());
};

export const reducedFilters = () => (dispatch) => {
  dispatch({
    type: 'REDUCED_TOTAL_FILTERS',
    data: {},
  });
};

export const getShortManagedSearchesHistory = ({
  page,
  sourceType,
  pageSize = 8,
  onSuccess = () => {},
  onFailed = () => {},
  erasePreviousData = true,
  savePrevMetaData = false,
  components,
}) => (dispatch) => {
  const onFailedProxy = (error) => {
    dispatch(setInitialRequestSearch('shortSearchesHistory', false));
    onFailed(error);
  };

  dispatch({
    type: 'GET_SHORT_MANAGED_SEARCHES_HISTORY',
    payload: {
      endpoint: 'api/v1/insights/search/history',
      method: 'GET',
      params: {
        sourceType,
        pageSize,
        pageNumber: page,
      },
      delayLoad: 0,
      showResponseError: true,
    },
    savePrevMetaData,
    erasePreviousData,
    components: components || ['shortSearchesHistoryLoader'],
    onSuccess,
    onFailed: onFailedProxy,
  });
};

export const getShortSavedSearches = ({
  page = 1,
  sourceType,
  pageSize = 5,
  onSuccess = () => {},
  onFailed = () => {},
  erasePreviousData = true,
  savePrevMetaData = false,
}) => (dispatch) => {
  const onFailedProxy = (error) => {
    dispatch(setInitialRequestSearch('shortSearches', false));
    onFailed(error);
  };

  dispatch({
    type: 'GET_SHORT_SEARCHES',
    payload: {
      endpoint: 'api/v1/searches',
      method: 'GET',
      params: {
        sourceType,
        pageNumber: page,
        pageSize,
      },
      delayLoad: 0,
      showResponseError: true,
    },
    savePrevMetaData,
    erasePreviousData,
    components: ['shortSearchesLoader'],
    onSuccess,
    onFailed: onFailedProxy,
  });
};

export const deleteSavedSearch = (savedSearchId, onSave) => (dispatch) => {
  dispatch({
    type: 'DELETE_SAVED_SEARCH',
    payload: {
      endpoint: `api/v1/searches/${savedSearchId}`,
      method: 'DELETE',
      showResponseError: true,
    },
    savedSearchId,
    onSuccess: onSave,
  });
};

export const clearSearchHistories = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_SEARCH_HISTORIES',
    data: {},
  });
};

export const stopSearch = (searchId, onSuccess = () => {}) => (dispatch) => {
  dispatch({
    type: 'STOP_SEARCH',
    payload: {
      endpoint: `api/v1/insights/search/stop/${searchId}`,
      method: 'POST',
      showResponseError: true,
    },
    onSuccess,
  });
};

export const clearInsights = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_INSIGHTS',
    data: {},
  });
};

export const getCompaniesLimit = () => (dispatch) => {
  dispatch({
    type: 'GET_COMPANIES_LIMIT',
    payload: {
      endpoint: 'api/v1/insights/search/companies/limit',
      method: 'GET',
      delayLoad: 0,
    },
    components: ['companiesLimitLoader'],
  });
};

export const updateDefaultProperties = (data) => (dispatch) => {
  dispatch({
    type: 'UPDATE_DEFAULT_SEARCH_PROPERTIES',
    data,
  });
};

export const fastSearchInsightContactsAndCompanies = (search, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'FAST_SEARCH_INSIGHT_CONTACTS_AND_COMPANIES',
    payload: {
      endpoint: `api/v1/insights/search/fast-search/${search}`,
      method: 'GET',
      delayLoad: 0,
    },
    components: ['fastSearchInput'],
    onSuccess,
    onFailed,
  });
};

export const clearFastSearchInsightContactsAndCompanies = (onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'CLEAR_FAST_SEARCH_INSIGHT__CONTACTS_AND_COMPANIES',
    data: {},
    components: ['fastSearchInput'],
    onSuccess,
    onFailed,
  });
};

export const getInsightById = ({
  insightId,
  sourceType,
  components = [],
  onSuccess,
  onFailed,
}) => (dispatch) => {
  const url = sourceType === SOURCE_TYPE.CONTACT
    ? `api/v1/insights/search/contacts/contact/${insightId}`
    : `api/v1/insights/search/companies/company/${insightId}`;

  dispatch({
    type: 'GET_INSIGHT_BY_ID',
    payload: {
      endpoint: url,
      method: 'GET',
      delayLoad: 0,
    },
    onSuccess: (payload) => {
      if (onSuccess) onSuccess(payload);
      if (sourceType === SOURCE_TYPE.CONTACT && payload?.companies?.length) {
        dispatch(getSavedInsightCompanies({
          type: 'GET_SAVED_INSIGHT_COMPANY_RELATIONS',
          data: payload.companies.map((relation) => ({
            id: relation.company._id,
            domain: relation.domain,
          })),
          isSidebar: true,
        }));
      }
    },
    onFailed,
    sourceType,
    components,
  });
};
