import styled from 'styled-components';

import { COLORS } from 'constants/styles';
import {
  Footer,
  CloseIcon,
} from '../baseModal/styles';

export const Container = styled.div`
  width: 305px;
  height: 305px;
`;

export const ButtonsContainer = styled(Footer)`
  display: flex;
  flex-direction: column;

  button {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

export const ImageSearch = styled.img`
  height: 150px;
  width: 150px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: -24px;
`;

export const ImageClose = styled(CloseIcon)`
  position: absolute;
  right: 11px;
  top: 10px;
  color: ${COLORS.GREY_G3} !important;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK_G1};
  margin-bottom: 10px;
  width: 275px;

  span {
    color: ${COLORS.RED}
  }
`;

export const Description = styled.div`
  font-weight: 500;
  width: 275px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;
