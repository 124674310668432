import styled from 'styled-components';
import { transparentize } from 'polished';

import { Button, RadioGroup, Select } from 'components/common';
import {
  COLORS,
  CREDIT_BAR_HEIGHT,
  HEADER_HEIGHT,
  MOBILE_MAX_WIDTH,
  TABS_HEIGHT,
  TOP_BAR_HEIGHT,
} from 'constants/styles';
import { MenuItem } from 'components/previewBars/styles';
import { cursorMixin } from 'components/commonStyles';
import { FILES_HEIGHT } from 'components/step/attachments/styles';

export const PanelContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => (props.marginSides ? '0 20px' : '0')};
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid ${COLORS.GREY_G4};
`;

export const TemplateContainer = styled.div`
  display: flex;

  & > * {
    flex-grow: 1;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    flex-direction: column;

    & > :not(:first-child) {
      border-left: 1px solid ${COLORS.GREY_G4};
    }
  }
`;

export const HeaderWrapper = styled.div`
  margin-right: 8px;
  min-width: 65px;
`;

export const VersionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const RadioContainer = styled(RadioGroup)`
  .ant-radio-button-wrapper {
    padding: 10px;
    padding-right: 5px;
    font-weight: 600;

    &-checked {
      background-color: ${transparentize(0.95, COLORS.PRIMARY)};
    }
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    width: ${(props) => props.mobileWidth}px;

    .ant-radio-group {
      display: flex;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid ${COLORS.GREY_G4};
  border-left: 1px solid ${COLORS.GREY_G4};
  border-right: 1px solid ${COLORS.GREY_G4};
  border-bottom-left-radius: ${(props) => (props.rounded ? '2px' : '')};
  border-bottom-right-radius: ${(props) => (props.rounded ? '2px' : '')};

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    padding: 10px;
  }
`;

export const ActionBallsContainer = styled(Button)`
  width: 32px;
  min-width: 32px;
  padding: 0;
  display: none;

  & > * {
    display: inline-block;
    margin-bottom: 2px;

    &:not(:last-child) {
      margin-right: 2px;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    display: flex;

    & .hide-mobile-action {
      display: none !important;
    }

    & > :first-child {
      padding: 0 10px;
    }

    ${ActionBallsContainer} {
      display: block;
    }
  }
`;

export const VersionActionsContainer = styled.div`
  display: flex;

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    flex-direction: row-reverse;

    & > :not(:first-child) {
      margin-right: 10px;
      white-space: nowrap;
    }
  }
`;

export const VersionsWrapper = styled.div`
  border-top: 1px solid ${COLORS.GREY_G4};
  border-left: ${(props) => (props.borderLeftNone ? '' : `1px solid ${COLORS.GREY_G4}`)};
  border-right: 1px solid ${COLORS.GREY_G4};
  border-top-left-radius: ${(props) => (props.rounded ? '2px' : '')};
  border-top-right-radius: ${(props) => (props.rounded ? '2px' : '')};
`;

export const VersionsContainer = styled(PanelContainer)`
  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    overflow-x: scroll;
  }
`;

export const FollowUpContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  margin-right: 29px;
  height: 32px;
`;

export const FollowUpSelect = styled(Select)`
  min-width: 60px;
  margin-right: -10px;

  .ant-select-selection .ant-select-arrow {
    top: 35%;
  }

  .ant-select-open .ant-select-selection {
    border-bottom: 1px solid ${COLORS.PRIMARY} !important;
  }

  .ant-select-selection {
    background-color: transparent;
    border: 1px solid transparent;
    box-shadow: none;
  }
`;

export const FollowUpText = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.GREY_G2};
`;

export const FollowUpDaysText = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.GREY_G1};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  border: 1px solid ${COLORS.GREY_G4};
  border-radius: 4px;
  width: ${(props) => (props.loading ? 60 : 32)}px;
  opacity: ${(props) => ((props.loading || props.disabled) ? 0.5 : 1)};
  height: 32px;
  background: ${COLORS.WHITE};

  &:not(:disabled) {
    &:hover, &:focus {
      background: ${COLORS.GREY_G5};
    }
  }

  &:not(:last-child) {
    margin-right: 12px;
  }

  ${(props) => (props.hasDot && `
    .material-icons:after {
      content: '•';
      position: absolute;
      margin-left: -12.3px;
      font-size: 14px;
      margin-top: 0.7px;
    }
  `)}

  ${cursorMixin}
`;

export const FindPropertyInput = styled.input`
  border: 0;
  width: 100%;
  height: 32px;
  padding-left: 13px;
  outline: none;

  &::placeholder {
    color: ${COLORS.GREY_G3};
  }
`;

export const FindPropertyContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-bottom: 1px solid ${COLORS.GREY_G4};
`;

export const FindPropertyGroupContainer = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
  font-weight: 600;
  font-size: 12px;
  padding: 6px 12px;
  padding-left: 15px;
  color: ${COLORS.BLACK_G1};
`;

export const PropertyMenuItem = styled(MenuItem)`
  display: flex;
  color: #4F5964;
  align-items: center;
  padding-left: 35px;
  min-height: 32px;
  height: 32px;
  ${(props) => (props.needBorder && `border-bottom: 1px solid ${COLORS.GREY_G4};`)}
`;

export const NotFoundMenuItem = styled(PropertyMenuItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 35px;
  padding-right: 25px;
  color: ${COLORS.PRIMARY};
`;

export const DeleteVersionContainer = styled.div`
  border-radius: 50%;
  background-color: ${COLORS.GREY_G3};
  display: flex;
  align-items: center;
  margin-left: 6px;
`;

export const VersionMenuItem = styled(MenuItem)`
  width: 112px;
  min-width: 112px;
  min-height: 32px;
  height: 32px;
  padding: 0 12px;
  color: ${COLORS.GREY_G1};
  font-weight: 500;
`;

export const TOP_MARGIN = 20;
export const FOLLOW_UP_HEIGHT = 52;
export const VERSIONS_HEIGHT = 70;
export const SUBJECT_HEIGHT = 55;
export const MAGICAL_HEIGHT = 93;

export const getStepHeight = (showBar, hasAttachments) => `calc(100vh - ${MAGICAL_HEIGHT}px ${hasAttachments ? `- ${FILES_HEIGHT}px` : ''} - ${SUBJECT_HEIGHT}px - ${VERSIONS_HEIGHT}px - ${TOP_MARGIN}px - ${FOLLOW_UP_HEIGHT}px - ${TABS_HEIGHT}px - ${HEADER_HEIGHT}px - ${TOP_BAR_HEIGHT}px ${showBar ? `- ${CREDIT_BAR_HEIGHT}px` : ''})`;

export const StepEditorContainer = styled.div`
  background-color: ${COLORS.WHITE};
  margin: 25px;
  margin-top: 10px;
  height: ${(props) => `calc(100vh - 40px - ${TOP_MARGIN}px - ${FOLLOW_UP_HEIGHT}px - ${TABS_HEIGHT}px - ${HEADER_HEIGHT}px - ${TOP_BAR_HEIGHT}px ${props.showBar ? `- ${CREDIT_BAR_HEIGHT}px` : ''})`};
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

export const SwitchLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.GREY_G1};
  margin-left: 10px;
  margin-right: 5px;
`;
