import startCase from 'lodash/startCase';

const transformCancelReasonFlowToText = (quitReport) => {
  const text = Object
    .keys(quitReport)
    .filter((key) => quitReport[key] && key !== 'workspaceId')
    .map((key, index) => `${index + 1}. ${startCase(key)} - ${startCase(quitReport[key])}`)
    .join('\n');
  return `\n${text}`;
};

export default transformCancelReasonFlowToText;
