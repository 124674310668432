import styled from 'styled-components';

export const Icon = styled.svg`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  color: #fff;
  margin-right: ${(props) => props.marginRight};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: center;
  background-size: cover;
  fill: currentcolor;
  text-anchor: middle; 
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#fff')};
  border-radius: ${(props) => props.borderRadius};
  background-position: center center;
  font-size: 0px;
  position: relative;
  
`;

export const Text = styled.text`
  color: transparent;
  font-size: ${(props) => props.textSize};
`;

export const Image = styled.image`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  display: none;
`;
