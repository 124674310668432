import moment from 'moment';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';

import { STATUSES } from 'constants/emailStatus';
import { replaceItem } from 'helpers/replaceItem';
import { SOURCE_TYPE } from 'data/types/source.types';
import { demoContacts } from 'data/mock/demoContacts';
import { demoInsights } from 'data/mock/demoInsights';
import { PageSizes } from 'data/types/tableMeta.types';
import { ACCOUNT_EVENTS } from 'data/types/event.types';
import { INSIGHTS_TYPE } from 'data/types/insights.types';
import { ContactsUsage } from 'data/types/contacts.types';
import { convertInsightToContact } from 'helpers/convert';
import { ActivityType, HistoryType } from 'data/types/history.types';
import { saveInsightsEmail, transformSimilarInsights } from 'helpers/insightHelpers';

const initialState = {
  contacts: {
    data: [],
    totalItems: 0,
    pageSize: PageSizes.DEFAULT_CONTACTS_PAGE_SIZE,
    page: 1,
    filters: [],
    sort: {},
    search: {},
    showDeleted: false,
    loaded: false,
    initialRequest: true,
    searchFieldData: false,
  },
  newContacts: [],
  insights: {
    data: [],
    totalItems: 0,
    defaultItems: 0,
    savedItems: 0,
    newItems: 0,
    pageSize: PageSizes.DEFAULT_CONTACTS_INSIGHTS_PAGE_SIZE,
    page: 1,
    filters: [],
    sort: {},
    initialRequest: true,
  },
  selectedFilter: {},
  additionalSelectedFilters: [],
  selectedContacts: [],
  excludedContacts: [],
  selectAllContacts: {
    selectAll: false,
    filterId: null,
    listId: null,
  },
  selectedInsightContacts: [],
  contactCompanyRelations: [],
  currentCompanyContactRelation: null,
  contactPreview: {},
  contactPreviewSidebar: {},
  contactId: null,
  savedFilters: [],
  showAllSavedFilters: false,
  blockedContacts: {
    data: [],
    totalItems: 0,
    pageSize: PageSizes.DEFAULT_BLOCKED_CONTACTS_PAGE_SIZE,
    page: 1,
  },
  deletedContacts: {
    data: [],
    totalItems: 0,
    pageSize: PageSizes.DEFAULT_TRASH_BIN_PAGE_SIZE,
    page: 1,
    search: {},
    filters: [],
    sort: {},
    selectAll: false,
    isChanged: false,
  },
  contactsAnalytics: {
    currentData: [],
    previousData: [],
    filterBy: ContactsUsage.SAVED_CONTACTS,
    startDate: moment().utc().subtract(30, 'days').startOf('days')
      .toISOString(),
    endDate: moment().utc().endOf('days').toISOString(),
  },
  inaccurateEmails: [],
  inaccurateDomains: [],
  onCheckAllChange: null,
  fastSearchDatabaseContacts: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_NOTE_FOR_CONTACT': {
      const field = `contactPreview${action.isSidebar ? 'Sidebar' : ''}`;
      if (isEmpty(state[field])) return state;
      return {
        ...state,
        [field]: {
          ...state[field],
          history: [{
            _id: action.payload._id,
            historyType: HistoryType.NOTE,
            type: ActivityType.WROTE_NOTE,
            header: 'Wrote note',
            createdAt: action.payload.createdAt,
            description: action.payload.text,
            author: {
              _id: action.payload.user,
            },
          }, ...state[field].history],
        },
      };
    }
    case 'ADD_CONTACTS_TO_SEQUENCE': {
      const field = `contactPreview${action.isSidebar ? 'Sidebar' : ''}`;
      if (isEmpty(state[field])) return state;
      return {
        ...state,
        [field]: {
          ...state[field],
          sequences: [...(state[field].sequences || []), action.sequence],
        },
      };
    }
    case 'REMOVE_SEQUENCE_LEADS': {
      const field = `contactPreview${action.isSidebar ? 'Sidebar' : ''}`;
      if (isEmpty(state[field])) return state;
      return {
        ...state,
        [field]: {
          ...state[field],
          sequences: state[field].sequences.filter((sequence) => sequence._id !== action.sequenceId),
        },
      };
    }
    case 'GET_CONTACT_HISTORY': {
      const field = `contactPreview${action.isSidebar ? 'Sidebar' : ''}`;
      return {
        ...state,
        [field]: {
          ...state[field],
          history: action.payload,
        },
      };
    }
    case 'GET_CONTACT_SEQUENCES': {
      const field = `contactPreview${action.isSidebar ? 'Sidebar' : ''}`;
      return {
        ...state,
        [field]: {
          ...state[field],
          sequences: action.payload,
        },
      };
    }
    case 'GET_CONTACT_LISTS': {
      const field = `contactPreview${action.isSidebar ? 'Sidebar' : ''}`;
      return {
        ...state,
        [field]: {
          ...state[field],
          populatedLists: action.payload,
        },
      };
    }
    case 'FLAG_INACCURATE_FIELDS': {
      const getFieldsWithoutDuplicates = (source) => {
        const fields = action.inaccurateFields.filter((item) => item.source === source);
        const property = source === SOURCE_TYPE.CONTACT ? 'inaccurateEmails' : 'inaccurateDomains';
        const fieldsWithoutDuplicates = [...state[property]].filter((field) => !fields.some((item) => item.value === field.value));
        if (state[property].length === fieldsWithoutDuplicates.length) {
          fieldsWithoutDuplicates.push(...fields);
        }
        return fieldsWithoutDuplicates;
      };
      return {
        ...state,
        inaccurateEmails: getFieldsWithoutDuplicates(SOURCE_TYPE.CONTACT),
        inaccurateDomains: getFieldsWithoutDuplicates(SOURCE_TYPE.COMPANY),
      };
    }
    case 'GET_INACCURATE_CONTACT_EMAILS': {
      return {
        ...state,
        inaccurateEmails: action.payload,
      };
    }
    case 'GET_INACCURATE_CONTACT_DOMAINS': {
      return {
        ...state,
        inaccurateDomains: action.payload,
      };
    }
    case 'CHANGE_CONTACTS_ANALYTICS_PARAMS': {
      return {
        ...state,
        contactsAnalytics: {
          ...state.contactsAnalytics,
          ...action.data,
        },
      };
    }

    case 'CLEAR_CONTACT_SEARCH': {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          search: initialState.contacts.search,
        },
      };
    }

    case 'GET_CONTACTS_ANALYTICS': {
      return {
        ...state,
        contactsAnalytics: {
          ...state.contactsAnalytics,
          currentData: action.payload.currentData,
          previousData: action.payload.previousData ?? [],
        },
      };
    }

    case 'SET_ON_CHECK_ALL_CHANGE': {
      return {
        ...state,
        onCheckAllChange: action.data.onCheckAllChange,
      };
    }
    case 'EXCLUDE_CONTACTS': {
      return {
        ...state,
        excludedContacts: [...action.data],
      };
    }
    case 'CLEAR_INSIGHTS': {
      return {
        ...state,
        insights: initialState.insights,
      };
    }
    case 'DELETE_CONTACTS': {
      const contacts = { ...state.contacts };
      const { contactsIds, operationType } = action;
      const { page } = state.deletedContacts;

      let newDeletedContactsPage = page;

      if (operationType !== 'delete') {
        if (page > 1) {
          newDeletedContactsPage = (state.deletedContacts.data.length - contactsIds.length) ? state.deletedContacts.page : (state.deletedContacts.page - 1);
        }
      }

      if (state.insights.data.some((item) => contactsIds.includes(item.savedWorkspaceId))) {
        const updatedInsights = state.insights.data.map((item) => {
          if (contactsIds.includes(item.savedWorkspaceId)) {
            item.saved = false;
          }

          return item;
        });

        return {
          ...state,
          contacts,
          insights: {
            ...state.insights,
            data: updatedInsights,
            initialRequest: true,
          },
          selectAllContacts: {
            selectAll: false,
            filterId: null,
            listId: null,
          },
          deletedContacts: {
            ...state.deletedContacts,
            page: newDeletedContactsPage,
            selectAll: false,
            isChanged: true,
          },
        };
      }

      return {
        ...state,
        contacts,
        selectAllContacts: {
          selectAll: false,
          filterId: null,
          listId: null,
        },
        insights: {
          ...state.insights,
          initialRequest: true,
        },
        deletedContacts: {
          ...state.deletedContacts,
          page: newDeletedContactsPage,
          selectAll: false,
          isChanged: true,
        },
      };
    }

    case 'GET_CONTACTS_FILTERS': {
      return {
        ...state,
        savedFilters: [
          {
            _id: 'all',
            name: 'All contacts',
            isSystem: true,
            filters: [],
          },
          ...action.payload,
        ],
      };
    }

    case 'SET_CONTACTS_PARAMS': {
      const { data = {} } = action;
      const tableMeta = localStorage.getItem('tableMeta');
      const parsedData = JSON.parse(tableMeta);
      localStorage.setItem('tableMeta', JSON.stringify({
        ...(parsedData ?? {}),
        contact: {
          page: data.page,
          pageSize: data.pageSize,
          sort: data.sort,
        },
      }));

      return {
        ...state,
        contacts: {
          ...state.contacts,
          ...action.data,
        },
      };
    }

    case 'SET_DELETED_CONTACTS_PARAMS': {
      return {
        ...state,
        deletedContacts: {
          ...state.deletedContacts,
          ...action.data,
        },
      };
    }

    case 'GET_CONTACTS_COUNT': {
      const { totalItems, totalPages } = action.payload;

      return {
        ...state,
        contacts: {
          ...state.contacts,
          totalItems,
          totalPages,
        },
      };
    }

    case 'GET_DELETED_CONTACTS_COUNT': {
      const { totalItems, totalPages } = action.payload;

      return {
        ...state,
        deletedContacts: {
          ...state.deletedContacts,
          totalItems,
          totalPages,
        },
      };
    }

    case 'SEARCH_CONTACTS': {
      const { data, meta } = action.payload;

      return {
        ...state,
        contacts: {
          ...state.contacts,
          data,
          ...meta,
          loaded: true,
          initialRequest: false,
        },
      };
    }

    case 'SEARCH_DELETED_CONTACTS': {
      const { data, meta } = action.payload;

      return {
        ...state,
        deletedContacts: {
          ...state.deletedContacts,
          data,
          ...meta,
          loaded: true,
          isChanged: false,
        },
      };
    }

    case 'SET_CONTACTS_INITIAL_REQUEST': {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          initialRequest: action.data.initialRequest,
        },
      };
    }

    case 'SET_INSIGHTS_CONTACTS_INITIAL_REQUEST': {
      const { initialRequest } = action.data;

      return {
        ...state,
        insights: {
          ...state.insights,
          initialRequest,
        },
      };
    }

    case 'SET_SELECTED_CONTACTS': {
      return {
        ...state,
        selectedContacts: [...action.data],
      };
    }

    case 'UPDATE_CONTACTS_FILTERS': {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          filters: action.data,
        },
      };
    }

    case 'ADD_ADDITIONAL_FILTER': {
      const { data } = action;
      const contactFilters = [...state.contacts.filters];
      const copyAdditionalSelectedFilters = [...state.additionalSelectedFilters];
      const selectedFilterExistsIndex = copyAdditionalSelectedFilters.findIndex((item) => item._id === data._id || item.additionalFilterType === data.additionalFilterType);

      if (selectedFilterExistsIndex > -1) {
        copyAdditionalSelectedFilters.splice(selectedFilterExistsIndex, 1);
      }
      copyAdditionalSelectedFilters.push(data);
      const additionalInParamsIndex = contactFilters.findIndex((item) => state.additionalSelectedFilters.find((filter) => get(item, 'included.value[0]') === filter?._id || get(item, '_id') === get(filter, 'filters[0]._id')));

      if (additionalInParamsIndex > -1) {
        contactFilters.splice(additionalInParamsIndex, 1);
      }

      return {
        ...state,
        additionalSelectedFilters: [...copyAdditionalSelectedFilters],
        contacts: {
          ...state.contacts,
          filters: [...contactFilters],
        },
      };
    }

    case 'CLEAR_ADDITIONAL_FILTER': {
      const { data: { saveContactParams = false } } = action;

      const contactFilters = [...state.contacts.filters];
      const additionalInParamsIndex = contactFilters.findIndex((item) => state.additionalSelectedFilters.find((filter) => get(item, 'included.value[0]') === filter?._id || get(item, '_id') === get(filter, 'filters[0]._id')));

      if (additionalInParamsIndex > -1 && !saveContactParams) {
        contactFilters.splice(additionalInParamsIndex, 1);
      }

      return {
        ...state,
        additionalSelectedFilters: initialState.additionalSelectedFilters,
        contacts: {
          ...state.contacts,
          filters: [...contactFilters],
        },
      };
    }

    case 'EDIT_CONTACTS_PROPERTY': {
      const { itemIds, properties } = action;
      const { pending } = action.payload;
      if (properties?.length > 0) {
        const [{ property, value }] = properties;
        const newProperty = { [property]: { value } };
        return {
          ...state,
          contacts: {
            ...state.contacts,
            data: pending ? state.contacts.data : state.contacts.data.map((contact) => {
              const newProperties = itemIds.includes(contact._id) ? newProperty : null;
              if (!newProperties) {
                return contact;
              }
              return {
                ...contact,
                properties: {
                  ...contact.properties,
                  ...newProperties,
                },
              };
            }),
          },
          selectAllContacts: {
            selectAll: false,
            filterId: null,
            listId: null,
          },
        };
      }
      return state;
    }

    case 'UPDATE_CONTACTS_FILTER': {
      const index = state.savedFilters.findIndex((item) => item._id === action.payload._id);
      const savedFilters = [...state.savedFilters];
      savedFilters[index] = action.payload;
      return {
        ...state,
        savedFilters,
      };
    }

    case 'CREATE_CONTACTS_FILTER': {
      const savedFilters = [...state.savedFilters];
      savedFilters.push(action.payload);
      return {
        ...state,
        savedFilters,
      };
    }

    case 'ADD_CONTACT': {
      const { data } = action.payload;

      const isExist = state.contacts.data.find((contact) => contact._id === data._id);

      return state.contacts.page === 1 && !isExist ? {
        ...state,
        contacts: {
          ...state.contacts,
          data: [data, ...state.contacts.data].slice(0, state.contacts.pageSize),
          totalItems: state.contacts.totalItems + 1,
        },
      } : { ...state };
    }

    case 'GET_CONTACT': {
      const field = `contactPreview${action.isSidebar ? 'Sidebar' : ''}`;
      return {
        ...state,
        [field]: action.payload,
      };
    }

    case 'UPDATE_COMPANY': {
      const newState = { ...state };
      const data = get(action, 'payload.data', {});
      if (newState.contactPreview?.companyRelations?.length) {
        newState.contactPreview = {
          ...newState.contactPreview,
          companyRelations: newState.contactPreview.companyRelations.map((relation) => {
            if (relation?.company?._id === data._id) {
              return {
                ...relation,
                company: data,
              };
            }
            return relation;
          }),
        };
      }
      if (newState.contactPreviewSidebar?.companyRelations?.length) {
        newState.contactPreviewSidebar = {
          ...newState.contactPreviewSidebar,
          companyRelations: newState.contactPreviewSidebar.companyRelations.map((relation) => {
            if (relation?.company?._id === data._id) {
              return {
                ...relation,
                company: data,
              };
            }
            return relation;
          }),
        };
      }
      return newState;
    }

    case 'UPDATE_CONTACT': {
      const { status } = action;
      const { data } = action.payload;
      const structure = ['_id', 'primaryPosition', 'properties', 'companyRelations', 'createdAt', 'updatedAt', 'source'];
      const field = `contactPreview${action.isSidebar ? 'Sidebar' : ''}`;

      let updatedData = {};

      switch (status) {
        case 'updated': {
          updatedData = {
            contacts: {
              ...state.contacts,
              data: [...replaceItem(state.contacts.data, data, structure)],
            },
          };
          break;
        }
        case 'added': {
          const isExist = state.contacts.data.find((contact) => contact._id === data._id);
          if (!isExist) {
            updatedData = {
              contacts: {
                ...state.contacts,
              },
              newContacts: [data, ...state.newContacts],
            };
          }
          break;
        }
        default: break;
      }

      return {
        ...state,
        ...updatedData,
        [field]: {
          ...data,
          history: state[field]?.history,
          sequences: state[field]?.sequences,
          populatedLists: state[field]?.populatedLists,
          savedColleagues: state[field]?.savedColleagues,
          colleagues: state[field]?.colleagues,
        },
      };
    }

    case 'RENAME_CONTACTS_FILTER': {
      const { _id, name } = action.payload;
      const savedFilters = [...state.savedFilters];
      const newFilters = savedFilters.find((item) => item._id === _id);
      newFilters.name = name;
      return {
        ...state,
        savedFilters,
      };
    }

    case 'DELETE_CONTACTS_FILTER': {
      const { filterId } = action;
      const newFilters = state.savedFilters.filter((item) => item._id !== filterId);
      return {
        ...state,
        savedFilters: newFilters,
      };
    }

    case 'CLONE_CONTACTS_FILTER': {
      const savedFilters = [...state.savedFilters];
      savedFilters.push(action.payload);
      return {
        ...state,
        savedFilters,
      };
    }

    case 'TOGGLE_SHOW_ALL_SAVED_FILTERS': {
      return {
        ...state,
        showAllSavedFilters: action.data.value,
      };
    }

    case 'SEARCH_INSIGHT_CONTACTS': {
      const data = get(action.payload, 'data', []);
      const insights = data.map((insight) => ({ ...insight, name: `${insight.firstName} ${insight.lastName}` }));
      const result = {
        ...state.insights,
        data: insights,
        pageSize: get(action.payload, 'meta.pageSize', PageSizes.DEFAULT_CONTACTS_INSIGHTS_PAGE_SIZE),
        page: get(action.payload, 'meta.page', 1),
        sort: get(action.payload, 'meta.sort', {}),
        initialRequest: false,
        totalItems: get(action.payload, 'meta.totalItems', 0),
      };

      const tableMeta = localStorage.getItem('tableMeta');
      const parsedData = JSON.parse(tableMeta);
      localStorage.setItem('tableMeta', JSON.stringify({
        ...(parsedData ?? {}),
        contactInsight: {
          pageSize: result.pageSize,
        },
      }));

      return {
        ...state,
        insights: result,
      };
    }

    case 'GET_SAVED_INSIGHT_CONTACTS': {
      const { onlyEnrich } = action;
      if (!onlyEnrich) {
        const savedInsights = get(action, 'payload', []);
        const contactPreviewSidebar = { ...get(state, 'contactPreviewSidebar', {}) };
        if (contactPreviewSidebar.colleagues?.data) {
          contactPreviewSidebar.colleagues.data = transformSimilarInsights(contactPreviewSidebar.colleagues, savedInsights);
        }
        return {
          ...state,
          insights: {
            ...state.insights,
            data: transformSimilarInsights(state.insights, savedInsights),
          },
          contactPreviewSidebar,
        };
      }
      return state;
    }

    case 'TOGGLE_SELECT_ALL_CONTACTS': {
      const { value, deleted } = action.data;

      if (deleted) {
        return {
          ...state,
          deletedContacts: { ...state.deletedContacts, selectAll: value },
        };
      }
      return {
        ...state,
        selectAllContacts: { ...state.selectAllContacts, ...value },
      };
    }

    case 'GET_BLOCKED_CONTACTS': {
      const { data, meta } = action.payload;
      return {
        ...state,
        blockedContacts: { ...state.blockedContacts, data, ...meta },
      };
    }

    case 'SET_BLOCKED_CONTACTS_PARAMS': {
      return {
        ...state,
        blockedContacts: { ...state.blockedContacts, ...action.data },
      };
    }

    case 'DELETE_BLOCKED_CONTACT': {
      const { contactId } = action;
      const newBlockedContacts = state.blockedContacts.data.filter((item) => item._id !== contactId);
      const currentPage = state.blockedContacts.page;

      return {
        ...state,
        blockedContacts: {
          ...state.blockedContacts,
          data: [...newBlockedContacts],
          totalItems: state.blockedContacts.totalItems - 1,
          page: newBlockedContacts.length ? currentPage : currentPage - 1,
        },
      };
    }

    case 'ADD_BLOCKED_CONTACTS': {
      return {
        ...state,
        blockedContacts: {
          ...state.blockedContacts,
          data: [...state.blockedContacts.data, ...action.payload],
          totalItems: state.blockedContacts.totalItems + action.payload.length,
        },
      };
    }

    case 'UPDATE_CONTACT_COMPANIES_RELATION': {
      const contact = action.payload;
      const contactsData = [...state.contacts.data];
      const field = `contactPreview${action.isSidebar ? 'Sidebar' : ''}`;
      const newContactPreviewData = { ...state[field] };

      if (contact) {
        const contactIndex = state.contacts.data.findIndex((item) => item._id === contact._id);
        if (contactIndex > -1) {
          contactsData[contactIndex].companyRelations = contact.companyRelations;
        }

        newContactPreviewData.companyRelations = contact.companyRelations;

        return {
          ...state,
          contacts: {
            ...state.contacts,
            data: contactsData,
          },
          [field]: newContactPreviewData,
        };
      }

      return {
        ...state,
      };
    }

    case 'SAVE_CONTACT_FROM_INSIGHTS': {
      const { insightId, payload } = action;
      const { _id, companyRelations } = payload;

      const insights = get(state, 'insights.data', []);

      const setSavedStatus = (contact) => {
        if (get(contact, 'id') === insightId) {
          contact.saved = true;
          contact.isSaving = false;
          contact.savedWorkspaceId = _id;
        }
        return contact;
      };

      const dataInsights = insights.map(setSavedStatus);

      const contactPreview = { ...state.contactPreview };
      if (state.contactPreview?.colleagues?.data) {
        const foundInsightContactIndex = state.contactPreview.colleagues.data.findIndex((contact) => insightId === contact.id);
        if (foundInsightContactIndex !== -1) {
          contactPreview.colleagues.data[foundInsightContactIndex].isSaving = false;
          contactPreview.colleagues.data[foundInsightContactIndex].saved = true;
          contactPreview.colleagues.data[foundInsightContactIndex].savedWorkspaceId = _id;
        }
      }

      return {
        ...state,
        contactPreview,
        contactPreviewSidebar: state.contactPreviewSidebar?._id === insightId ? {
          ...state.contactPreviewSidebar,
          savedWorkspaceId: _id,
          isSaving: false,
          companyRelations,
          history: [{
            _id,
            historyType: HistoryType.ENTITY,
            type: ActivityType.CREATED_ENTITY,
            header: 'Added from search',
            createdAt: new Date().toISOString(),
          }],
        } : state.contactPreviewSidebar,
        insights: {
          ...state.insights,
          data: dataInsights,
        },
      };
    }

    case ACCOUNT_EVENTS.ENRICHMENT_CONTACT_EVENT: {
      const payloadData = JSON.parse(action.payload);

      const contactsData = [...state.contacts.data];
      let { contactPreview, contactPreviewSidebar } = state;

      if (payloadData?.length) {
        payloadData.forEach((contact) => {
          const contactIndex = state.contacts.data.findIndex((item) => item._id === contact?._id);
          if (contactIndex > -1) {
            contactsData[contactIndex] = contact;

            if (contactPreview?._id === contact?._id) {
              contactPreview = {
                ...contact,
                history: contactPreview?.history,
                sequences: contactPreview?.sequences,
                populatedLists: contactPreview?.populatedLists,
                savedColleagues: contactPreview?.savedColleagues,
                colleagues: contactPreview?.colleagues,
              };
            } if (contactPreviewSidebar?._id === contact?._id) {
              contactPreviewSidebar = {
                ...contact,
                history: contactPreviewSidebar?.history,
                sequences: contactPreviewSidebar?.sequences,
                populatedLists: contactPreviewSidebar?.populatedLists,
                savedColleagues: contactPreviewSidebar?.savedColleagues,
                colleagues: contactPreviewSidebar?.colleagues,
              };
            }
          }
        });
      }
      return {
        ...state,
        contacts: {
          ...state.contacts,
          data: contactsData,
        },
        contactPreview,
        contactPreviewSidebar,
      };
    }

    case ACCOUNT_EVENTS.NEW_CONTACT_EVENT: {
      const eventContact = JSON.parse(action.payload);

      const isExist = state.contacts.data.find((contact) => contact._id === eventContact._id);

      return !isExist ? {
        ...state,
        newContacts: [eventContact, ...state.newContacts],
      } : { ...state };
    }

    case ACCOUNT_EVENTS.CONTACT_EMAIL_STATUS_EVENT: {
      try {
        const payloadData = action.payload ? JSON.parse(action.payload) : [];
        if (payloadData?.length && payloadData[0]?.isInsight) {
          if (!payloadData.some((item) => state.insights.data.some((insight) => item.contactId === insight.id))) {
            return state;
          }

          const setEmailStatus = (item) => {
            const insightDataInPayload = payloadData.find((arrayItem) => arrayItem?.contactId === item?.id);

            if (insightDataInPayload === -1) {
              return item;
            }
            const emailAddress = get(insightDataInPayload, 'email', null);
            const status = get(insightDataInPayload, 'status', null);
            const email = { value: emailAddress, status };
            item.companies.forEach((relation) => {
              const companyId = get(relation, 'company.id') || get(relation, 'company._id');
              if (insightDataInPayload && companyId === get(insightDataInPayload, 'contactCompanyRelation')) {
                relation.email = email;
              }
            });
            return item;
          };

          const updatedInsightsData = state.insights.data.map(setEmailStatus);

          return {
            ...state,
            insights: {
              ...state.insights,
              data: [...updatedInsightsData],
            },
          };
        }
        const contactsData = [...state.contacts.data];
        const contactPreview = { ...state.contactPreview };
        const contactPreviewSidebar = { ...state.contactPreviewSidebar };

        if (payloadData?.length) {
          payloadData.forEach((contact) => {
            const contactIndex = state.contacts.data.findIndex((item) => item._id === contact?._id);
            if (contactIndex > -1) {
              contactsData[contactIndex].companyRelations = contact.companyRelations;
            }
            if ([contactPreview._id, contactPreview.savedWorkspaceId].includes(contact._id)) {
              contactPreview.companyRelations = contact.companyRelations;
            }
            if ([contactPreviewSidebar._id, contactPreviewSidebar.savedWorkspaceId].includes(contact._id)) {
              contactPreviewSidebar.companyRelations = contact.companyRelations;
            }
            if (contactPreview.colleagues?.data) {
              contactPreview.colleagues.data = contactPreview.colleagues.data.map((contactInCompany) => {
                const contactId = contactInCompany.id || contactInCompany._id;
                const _contact = payloadData.find(({ insightId }) => contactId === insightId);
                if (_contact) {
                  return {
                    ...contactInCompany,
                    companyRelations: contactInCompany.companyRelations.map((relationInCompany) => {
                      const insightId = relationInCompany?.company?.id || relationInCompany?.company?._id;
                      const foundCompany = _contact.companyRelations.find((relation) => get(relation, 'company.insightId', '') === insightId);
                      if (foundCompany) {
                        return {
                          ...relationInCompany,
                          email: foundCompany.email,
                        };
                      }
                      return relationInCompany;
                    }),
                  };
                }
                return contactInCompany;
              });
            }
          });
        }

        const newContactsInsightsData = state.newContacts.map((item) => {
          const insightDataInPayload = payloadData.find((arrayItem) => arrayItem?._id === item?._id);

          if (!insightDataInPayload) {
            return item;
          }
          return insightDataInPayload;
        });

        return {
          ...state,
          insights: {
            ...state.insights,
            data: saveInsightsEmail(state.insights, payloadData),
          },
          contacts: {
            ...state.contacts,
            data: contactsData,
          },
          contactPreview,
          contactPreviewSidebar,
          newContacts: [...newContactsInsightsData],
        };
      } catch (err) {
        return state;
      }
    }

    case 'ADD_NEW_CONTACTS_TO_LIST': {
      const { contacts, newContacts } = state;
      const { currentListId } = action.data;

      const newContactsFiltered = newContacts
        .filter((newContact) => !contacts.data
          .find((contact) => contact._id === newContact._id) && (get(newContact, 'listRelations', []).includes(currentListId) || currentListId === 'all'));

      return newContactsFiltered.length ? {
        ...state,
        contacts: {
          ...contacts,
          data: [...newContactsFiltered, ...contacts.data].slice(0, state.contacts.pageSize),
          totalItems: state.contacts.totalItems + newContacts.length,
        },
        newContacts: initialState.newContacts,
      } : {
        ...state,
        contacts: {
          ...contacts,
        },
        newContacts: initialState.newContacts,
      };
    }

    case 'BULK_SAVE_CONTACTS_FROM_INSIGHTS': {
      const { insightIds, bulk } = action.data;
      const insights = get(state, 'insights.data', []);

      const setSavedStatus = (contactInsight) => {
        if (insightIds.includes(get(contactInsight, 'id'))) {
          contactInsight.saved = true;
          contactInsight.isSaving = false;
          contactInsight.savedWorkspaceId = get(bulk, 'data', []).find((contact) => contact.insightId === contactInsight.id)?._id;
        }
        return contactInsight;
      };

      const dataInsights = insights.map(setSavedStatus);

      return {
        ...state,
        insights: {
          ...state.insights,
          data: dataInsights,
        },
      };
    }

    case 'SEARCH_LIST_CONTACTS': {
      const newContacts = action.payload
        ? {
          data: get(action, 'payload.data', []),
          ...get(action, 'payload.meta', {}),
        }
        : action.data;
      newContacts.loaded = true;
      newContacts.initialRequest = false;
      return {
        ...state,
        contacts: newContacts,
      };
    }

    case 'UPDATE_INSIGHTS_CONTACT_STATUS': {
      const { insightIds, isSaving, status = STATUSES.PROCESSING } = action.data;
      const insights = get(state, 'insights.data', []);

      const setSavedStatus = (contact) => {
        if (insightIds.includes(get(contact, 'id'))) {
          contact.companies.forEach((company) => {
            if (status === null && company) {
              delete company.email;
            } else {
              set(company, 'email.status', status);
            }
          });
          contact.isSaving = isSaving;
        }
        return contact;
      };

      const dataInsights = insights.map(setSavedStatus);

      const contactPreviewSidebar = { ...state.contactPreviewSidebar };
      if (insightIds.includes(state.contactPreviewSidebar?._id)) {
        contactPreviewSidebar.isSaving = isSaving;
      }

      const contactPreview = { ...state.contactPreview };

      if (state.contactPreview?._id && state.contactPreview.colleagues?.data) {
        contactPreview.colleagues.data = contactPreview.colleagues.data.map((contact) => {
          if (insightIds.includes(contact.id) || insightIds.includes(contact._id)) {
            return {
              ...contact,
              isSaving,
            };
          }
          return contact;
        });
      }

      return {
        ...state,
        contactPreviewSidebar,
        contactPreview,
        insights: {
          ...state.insights,
          data: dataInsights,
        },
      };
    }

    case 'SET_SELECTED_INSIGHT_CONTACTS': {
      return {
        ...state,
        selectedInsightContacts: [...action.data],
      };
    }

    case 'DELETE_COMPANY_RELATION_EMAIL': {
      const { contactId, relationId } = action;
      let updateState = false;
      const field = `contactPreview${action.isSidebar ? 'Sidebar' : ''}`;
      try {
        if (get(action, 'payload.success')) {
          const contactsData = [...state.contacts.data];
          const newContactPreviewData = { ...state[field] };
          const contactIndex = state.contacts.data.findIndex((item) => item._id === contactId);

          if (contactIndex > -1) {
            updateState = true;
            const relation = contactsData[contactIndex].companyRelations.find((item) => item._id === relationId);
            if (relation) {
              relation.email.value = '';
              relation.email.status = '';
            }
          }
          if (newContactPreviewData._id === contactId) {
            updateState = true;
            const relation = newContactPreviewData.companyRelations.find((item) => item._id === relationId);
            if (relation) {
              relation.email.value = '';
              relation.email.status = '';
            }
          }
          return updateState
            ? {
              ...state,
              contacts: {
                ...state.contacts,
                data: contactsData,
              },
              [field]: newContactPreviewData,
            }
            : state;
        }
        return state;
      } catch (err) {
        return state;
      }
    }

    case 'SELECT_FILTER': {
      return {
        ...state,
        selectedFilter: action.data,
      };
    }

    case 'BULK_EMAILS_VALIDATION': {
      const { tasks } = action;
      const contactsData = [...state.contacts.data];
      const contactPreview = { ...state.contactPreview };
      const contactPreviewSidebar = { ...state.contactPreviewSidebar };
      let updateState = false;
      tasks.forEach((item) => {
        const { contactId, relationId, email } = item;
        const contactIndex = state.contacts.data.findIndex((contact) => contact._id === contactId);
        if (contactIndex > -1) {
          const companyRelationIndex = contactsData[contactIndex].companyRelations.findIndex((relation) => relation._id === relationId);

          if (companyRelationIndex > -1) {
            updateState = true;
            contactsData[contactIndex].companyRelations[companyRelationIndex].email.status = STATUSES.PROCESSING;
            contactsData[contactIndex].companyRelations[companyRelationIndex].email.value = email;
          }
        }
        if ([contactPreview._id, contactPreview.savedWorkspaceId].includes(contactId)) {
          const relationIndex = contactPreview.companyRelations.findIndex((relation) => relation._id === relationId);
          if (relationIndex > -1) {
            updateState = true;
            contactPreview.companyRelations[relationIndex].email.status = STATUSES.PROCESSING;
          }
        } if ([contactPreviewSidebar._id, contactPreviewSidebar.savedWorkspaceId].includes(contactId)) {
          const relationIndex = contactPreviewSidebar.companyRelations.findIndex((relation) => relation._id === relationId);
          if (relationIndex > -1) {
            updateState = true;
            contactPreviewSidebar.companyRelations[relationIndex].email.status = STATUSES.PROCESSING;
          }
        }
      });
      return updateState
        ? {
          ...state,
          contacts: {
            ...state.contacts,
            data: contactsData,
          },
          contactPreview,
          contactPreviewSidebar,
        }
        : state;
    }

    case 'SET_EMAIL_STATUS': {
      const { contactId, companyIndex, status } = action.data;
      const contactsData = [...state.contacts.data];
      const contactPreview = { ...state.contactPreview };
      const contactPreviewSidebar = { ...state.contactPreviewSidebar };
      let stateUpdated = false;

      try {
        const contactIndex = state.contacts.data.findIndex((contact) => contact._id === contactId);
        if (contactIndex > -1) {
          contactsData[contactIndex].companyRelations[companyIndex].email.status = status;
          stateUpdated = true;
        }
        if (contactPreview._id === contactId) {
          contactPreview.companyRelations[companyIndex].email.status = status;
          stateUpdated = true;
        }
        if (contactPreviewSidebar._id === contactId) {
          contactPreviewSidebar.companyRelations[companyIndex].email.status = status;
          stateUpdated = true;
        }
      } catch (err) {
        stateUpdated = false;
      }
      const insights = get(state, 'insights.data', []);
      const data = insights.map((contact) => {
        if (get(contact, 'id') === contactId) {
          if (!contact.companies[companyIndex].email) {
            stateUpdated = true;
            contact.companies[companyIndex].email = {
              status,
              value: null,
            };
          }
        }
        return contact;
      });
      if (!stateUpdated) return state;
      return {
        ...state,
        contacts: {
          ...state.contacts,
          data: contactsData,
        },
        contactPreview,
        contactPreviewSidebar,
        insights: {
          ...state.insights,
          data,
        },
      };
    }
    case 'GET_INSIGHT_BY_ID': {
      if (action.sourceType !== SOURCE_TYPE.CONTACT) return state;
      return {
        ...state,
        contactPreviewSidebar: convertInsightToContact(action.payload),
      };
    }
    case 'GET_SAVED_CONTACT_BY_INSIGHT_ID': {
      return {
        ...state,
        contactPreviewSidebar: action.payload,
      };
    }
    case 'GET_INSIGHT_CONTACTS_COUNT': {
      const { selectedTab, totalItems: currentCount } = action;
      const countInsights = {};
      const count = action.payload;
      const maxCount = currentCount > count ? currentCount : count;
      const minCount = currentCount > count ? count : currentCount;
      switch (selectedTab) {
        case INSIGHTS_TYPE.INSIGHTS:
          countInsights.defaultItems = currentCount;
          countInsights.savedItems = count;
          countInsights.newItems = Math.abs(currentCount - count);
          break;
        case INSIGHTS_TYPE.NEW_INSIGHTS:
          countInsights.defaultItems = maxCount;
          countInsights.newItems = minCount;
          countInsights.savedItems = Math.abs(count - currentCount);
          break;
        case INSIGHTS_TYPE.SAVED_INSIGHTS:
          countInsights.defaultItems = count;
          countInsights.newItems = Math.abs(count - currentCount);
          countInsights.savedItems = currentCount;
          break;
        default: break;
      }

      return {
        ...state,
        insights: {
          ...state.insights,
          ...countInsights,
        },
      };
    }
    case 'SET_PREVIEW_CONTACT_RELATIONS': {
      const field = `contactPreview${action.data.isSidebar ? 'Sidebar' : ''}`;
      if (action.data.toDelete) {
        return {
          ...state,
          [field]: {
            ...state[field],
            populatedLists: state[field].populatedLists ? state[field].populatedLists.filter((list) => action.data.listRelations.includes(list._id)) : state[field].populatedLists,
            listRelations: action.data.listRelations,
          },
        };
      }
      return {
        ...state,
        [field]: {
          ...state[field],
          populatedLists: state[field].populatedLists ? [...state[field].populatedLists, ...action.data.populatedLists] : action.data.populatedLists,
          listRelations: action.data.listRelations,
        },
      };
    }

    case 'FAST_SEARCH_DATABASE_CONTACTS': {
      return {
        ...state,
        fastSearchDatabaseContacts: action?.payload?.data || [],
      };
    }

    case 'ADD_DEMO_CONTACTS': {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          data: demoContacts,
          totalItems: 5,
          initialRequest: false,
        },
        insights: {
          ...state.insights,
          data: demoInsights,
        },
      };
    }
    case 'ADD_PRIMARY_POSITION': {
      return {
        ...state,
        contactPreview: {
          ...state.contactPreview,
          primaryPosition: {
            ...(state.contactPreview.primaryPosition || {}),
            options: [action.data.position, ...(state.contactPreview?.primaryPosition?.options || [])],
          },
        },
      };
    }

    case 'SEARCH_PHONE_NUMBER': {
      const { contactId, insightId, phone } = action.payload;

      const contactsData = [...state.contacts.data];
      const insightsData = [...state.insights.data];
      const contactPreviewData = { ...state.contactPreview };
      const contactPreviewSidebarData = { ...state.contactPreviewSidebar };
      const insightIndex = state.insights.data.findIndex((item) => item.id === insightId);
      const contactIndex = state.contacts.data.findIndex((item) => item._id === contactId);
      if (insightIndex >= 0) insightsData[insightIndex].phone = phone;
      if (contactIndex >= 0) contactsData[contactIndex].properties.phone = { value: phone };
      if ([contactId, insightId].includes(contactPreviewData?._id) && contactPreviewData?.properties) contactPreviewData.properties.phone = { value: phone };
      if ([contactId, insightId].includes(contactPreviewSidebarData?._id) && contactPreviewSidebarData?.properties) {
        contactPreviewSidebarData.properties.phone = { value: phone };
      }
      if (contactPreviewData?.colleagues?.data) {
        contactPreviewData.colleagues.data = contactPreviewData.colleagues.data.map((colleague) => {
          const colleagueId = colleague._id || colleague.id;
          if (colleagueId === insightId || colleagueId === contactId) {
            return {
              ...colleague,
              properties: {
                ...colleague.properties,
                phone: {
                  value: phone,
                },
              },
            };
          }
          return colleague;
        });
      }

      return {
        ...state,
        insights: {
          ...state.insights,
          data: insightsData,
        },
        contacts: {
          ...state.contacts,
          data: contactsData,
        },
        contactPreview: contactPreviewData,
        contactPreviewSidebar: contactPreviewSidebarData,
      };
    }

    case 'SEARCH_CONTACT_COLLEAGUES': {
      const field = `contactPreview${action.isSidebar ? 'Sidebar' : ''}`;
      const subField = action.saved ? 'savedColleagues' : 'colleagues';
      return {
        ...state,
        [field]: {
          ...state[field],
          [subField]: {
            ...action.payload.meta,
            page: get(action, 'payload.meta.page', 1),
            data: action.saved ? action.payload.data.map((item) => ({ ...item, saved: true })) : action.payload.data.map(convertInsightToContact),
            initialRequest: true,
          },
        },
      };
    }

    case 'GET_SAVED_INSIGHT_COMPANY_RELATIONS': {
      const field = `contactPreview${action.isSidebar ? 'Sidebar' : ''}`;
      const savedInsights = get(action, 'payload', []);
      return {
        ...state,
        [field]: {
          ...state[field],
          companyRelations: state[field].companyRelations.map((relation) => ({
            ...relation,
            company: transformSimilarInsights({ data: [relation.company] }, savedInsights)[0],
          })),
        },
      };
    }

    case 'GET_CONTACT_COLLEAGUES_POSITIONS': {
      const field = `contactPreview${action.isSidebar ? 'Sidebar' : ''}`;
      return {
        ...state,
        [field]: {
          ...state[field],
          contactPositions: action.payload,
        },
      };
    }

    case 'GET_CONTACT_COLLEAGUES_COUNTRIES': {
      const field = `contactPreview${action.isSidebar ? 'Sidebar' : ''}`;
      return {
        ...state,
        [field]: {
          ...state[field],
          contactCountries: action.payload,
        },
      };
    }

    case 'SIGN_OUT':
    case 'SWITCH_WORKSPACE': {
      return initialState;
    }
    default:
      return state;
  }
};
