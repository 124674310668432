import React from 'react';

import { Icon } from 'components/common';
import { ShortContainer } from './styles';

const ShortMessage = ({ data }) => {
  const {
    text,
  } = data;

  return (
    <ShortContainer>
      <Icon type="ic-check-circle" fill="white" style={{ marginRight: 8 }} />
      <span>
        {text}
      </span>
    </ShortContainer>
  );
};

export default ShortMessage;
