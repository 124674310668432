import styled, { css } from 'styled-components';
import * as CONST from 'constants/styles';
import { ReactComponent as IcMenu } from 'assets/images/ic-menu.svg';
import { ReactComponent as IcClose } from 'assets/images/ic-close.svg';
import { ReactComponent as IcStar } from 'assets/images/ic-star-border.svg';
import { ReactComponent as IcDelete } from 'assets/images/ic-delete-trash.svg';
import { ReactComponent as IcImportGetProspect } from 'assets/images/ic-import-prospect.svg';
import { ReactComponent as IcOpenInNew } from 'assets/images/ic-open-in-new.svg';
import { ReactComponent as IcEnrichment } from 'assets/images/ic-enrichment.svg';
import { ReactComponent as IcIgnoreList } from 'assets/images/ic-ignore-list.svg';
import { ReactComponent as IcImportCompanies } from 'assets/images/ic-import-companies.svg';
import { ReactComponent as IcImportContacts } from 'assets/images/ic-import-contacts.svg';
import { ReactComponent as IcImportUpload } from 'assets/images/ic-import-upload.svg';
import { ReactComponent as IcPaperClip } from 'assets/images/ic-paper-clip.svg';
import { ReactComponent as IcCheckCircle } from 'assets/images/ic-check-circle.svg';
import { ReactComponent as IcCancel } from 'assets/images/ic-cancel.svg';
import { ReactComponent as IcImportData } from 'assets/images/ic-import-data.svg';
import { ReactComponent as IcTwoWaySync } from 'assets/images/ic-two-way-sync.svg';
import { ReactComponent as IcDrag } from 'assets/images/ic-drag-indicator.svg';
import { ReactComponent as IcChevronLeft } from 'assets/images/ic-chevron-left.svg';
import { ReactComponent as IcEdit } from 'assets/images/ic-edit.svg';
import { ReactComponent as IcExpandMore } from 'assets/images/ic-expand-m.svg';
import { ReactComponent as IcExpandLess } from 'assets/images/ic-expand-less.svg';
import { ReactComponent as IcDel } from 'assets/images/ic-del.svg';
import { ReactComponent as IcAdd } from 'assets/images/ic-plus.svg';
import { ReactComponent as IcEmail } from 'assets/images/ic-email.svg';
import { ReactComponent as IcScratch } from 'assets/images/scratch.svg';
import { ReactComponent as IcPersonEdit } from 'assets/images/ic-person-edit.svg';
import { ReactComponent as IcFontDownloadOutline } from 'assets/images/ic-font-download-outline.svg';
import { ReactComponent as IcImageOutline } from 'assets/images/ic-image-outline.svg';
import { ReactComponent as IcLink } from 'assets/images/ic-link.svg';
import { ReactComponent as IcReceipt } from 'assets/images/ic-receipt.svg';
import { ReactComponent as IcLibraryBooks } from 'assets/images/ic-library-books.svg';
import { ReactComponent as IcEventNoteEdit } from 'assets/images/ic-event-note-edit.svg';
import { ReactComponent as IcOnboardingFindLead } from 'assets/images/ic-onboarding-find-lead.svg';
import { ReactComponent as IcOnboardingInfo } from 'assets/images/ic-onboarding-info.svg';
import { ReactComponent as IcOnboardingEnrich } from 'assets/images/ic-onboarding-enrich.svg';
import { ReactComponent as IcOnboardingOrganize } from 'assets/images/ic-onboarding-organize.svg';
import { ReactComponent as IcExistingList } from 'assets/images/ic-existing-list.svg';
import { ReactComponent as IcGraphic } from 'assets/images/ic-graphic.svg';
import { ReactComponent as IcHelp } from 'assets/images/ic-help.svg';
import { ReactComponent as IcExitToApp } from 'assets/images/ic-exit-to-app.svg';
import { ReactComponent as IcIconBack } from 'assets/images/ic-arrow-back.svg';
import { ReactComponent as IcFolder } from 'assets/images/ic-folder.svg';
import { ReactComponent as IcCheck } from 'assets/images/ic-check.svg';
import { ReactComponent as IcCloseBlue } from 'assets/images/ic-close-blue.svg';
import { ReactComponent as IcHome } from 'assets/images/ic-home.svg';
import { ReactComponent as IcEmptyState } from 'assets/images/emptyState.svg';
import { ReactComponent as IcArrowDropDown } from 'assets/images/ic-arrow-drop-down.svg';
import { ReactComponent as IcChrome } from 'assets/images/chrome.svg';
import { ReactComponent as IcDomain } from 'assets/images/ic-domain.svg';
import { ReactComponent as IcDocuments } from 'assets/images/ic-documents.svg';
import { ReactComponent as IcPeople } from 'assets/images/ic-people.svg';
import { ReactComponent as IcGoogle } from 'assets/images/ic-google.svg';
import { ReactComponent as IcTabs } from 'assets/images/ic_tabs.svg';

import { ReactComponent as IcInfoOutline } from 'assets/images/ic-info-outline.svg';
import { ReactComponent as IcSend } from 'assets/images/ic-send.svg';
import { ReactComponent as IcVisibility } from 'assets/images/ic-visibility.svg';
import { ReactComponent as IcMouse } from 'assets/images/ic-mouse.svg';
import { ReactComponent as IcReply } from 'assets/images/ic-reply.svg';
import { ReactComponent as IcMoreVert } from 'assets/images/ic-more-vert.svg';
import { ReactComponent as IcBarChart } from 'assets/images/ic-bar-chart.svg';
import { ReactComponent as IcSettings } from 'assets/images/ic-settings.svg';
import { ReactComponent as IcDeleteTrash } from 'assets/images/ic_delete_grey.svg';
import { ReactComponent as IcPublish } from 'assets/images/ic-publish.svg';
import { ReactComponent as IcPause } from 'assets/images/ic-pause.svg';
import { ReactComponent as IcResume } from 'assets/images/ic-resume.svg';
import { ReactComponent as IcSearch } from 'assets/images/ic-search.svg';
import { ReactComponent as IcContentCopy } from 'assets/images/ic-content-copy.svg';
import { ReactComponent as IcFormatClear } from 'assets/images/ic-format-clear.svg';
import { ReactComponent as IcFormatListBulleted } from 'assets/images/ic-format-list-bulleted.svg';
import { ReactComponent as IcFormatBold } from 'assets/images/ic-format-bold.svg';
import { ReactComponent as IcFormatItalic } from 'assets/images/ic-format-italic.svg';
import { ReactComponent as IcFormatUnderlined } from 'assets/images/ic-format-underlined.svg';
import { ReactComponent as IcFormatColorText } from 'assets/images/ic-format-color-text.svg';
import { ReactComponent as IcFormatColorFill } from 'assets/images/ic-format-color-fill.svg';
import { ReactComponent as IcFormatAlignRight } from 'assets/images/ic-format-align-right.svg';
import { ReactComponent as IcFormatAlignJustify } from 'assets/images/ic-format-align-justify.svg';
import { ReactComponent as IcFormatAlignCenter } from 'assets/images/ic-format-align-center.svg';
import { ReactComponent as IcFormatAlignLeft } from 'assets/images/ic-format-align-left.svg';
import { ReactComponent as IcCode } from 'assets/images/ic-code.svg';
import { ReactComponent as IcAccessTime } from 'assets/images/ic-access-time.svg';
import { ReactComponent as IcCallMissedOutgoing } from 'assets/images/ic-call-missed-outgoing.svg';
import { ReactComponent as IcDoNotDisturbAlt } from 'assets/images/ic-do-not-disturb-alt.svg';
import { ReactComponent as IcDownload } from 'assets/images/ic-download.svg';
import { ReactComponent as IcDataDotObject } from 'assets/images/ic-data-dot-object.svg';
import { ReactComponent as IcArrowVectorRight } from 'assets/images/ic-arrow-vector-right.svg';
import { ReactComponent as IcLinkedIn } from 'assets/images/social-icons/ic-linkedin.svg';
import { ReactComponent as IcYoutube } from 'assets/images/social-icons/ic-youtube.svg';
import { ReactComponent as IcFacebook } from 'assets/images/social-icons/ic-facebook.svg';
import { ReactComponent as IcTwitter } from 'assets/images/social-icons/ic-twitter.svg';
import { ReactComponent as IcInstagram } from 'assets/images/social-icons/ic-instagram.svg';

export const IconYoutube = styled(IcYoutube)`
  path {
    fill: ${(props) => props.fill || CONST.COLORS.GREY_G3} !important;
  }
`;
export const IconFacebook = styled(IcFacebook)`
  path {
    fill: ${(props) => props.fill || CONST.COLORS.GREY_G3} !important;
  }
`;
export const IconTwitter = styled(IcTwitter)`
  path {
    fill: ${(props) => props.fill || CONST.COLORS.GREY_G3} !important;
  }
`;
export const IconInstagram = styled(IcInstagram)`
  path {
    fill: ${(props) => props.fill || CONST.COLORS.GREY_G3} !important;
  }
`;
export const IconLinkedIn = styled(IcLinkedIn)`
  path {
    fill: ${(props) => props.fill || CONST.COLORS.GREY_G3} !important;
  }
`;
export const IconDownload = styled(IcDownload)``;
export const IconDoNotDisturbAlt = styled(IcDoNotDisturbAlt)``;
export const IconCallMissedOutgoing = styled(IcCallMissedOutgoing)``;
export const IconAccessTime = styled(IcAccessTime)``;
export const IconCode = styled(IcCode)``;
export const IconDataDotObject = styled(IcDataDotObject)`
  path {
    fill: ${(props) => props.fill || CONST.COLORS.GREY_G3} !important;
  }
`;
export const IconFormatAlignLeft = styled(IcFormatAlignLeft)``;
export const IconFormatAlignCenter = styled(IcFormatAlignCenter)``;
export const IconFormatAlignJustify = styled(IcFormatAlignJustify)``;
export const IconFormatAlignRight = styled(IcFormatAlignRight)``;
export const IconFormatColorFill = styled(IcFormatColorFill)``;
export const IconFormatColorText = styled(IcFormatColorText)``;
export const IconFormatUnderlined = styled(IcFormatUnderlined)``;
export const IconFormatItalic = styled(IcFormatItalic)``;
export const IconFormatBold = styled(IcFormatBold)``;
export const IconFormatListBulleted = styled(IcFormatListBulleted)``;
export const IconFormatClear = styled(IcFormatClear)``;
export const IconContentCopy = styled(IcContentCopy)``;
export const IconSearch = styled(IcSearch)`
  g {
    fill: ${(props) => props.fill || CONST.COLORS.GREY_G3} !important;
  }
`;
export const IconPause = styled(IcPause)``;
export const IconResume = styled(IcResume)``;
export const IconPublish = styled(IcPublish)``;
export const IconDeleteTrash = styled(IcDeleteTrash)``;
export const IconSettings = styled(IcSettings)`
  #kavabaga13 {
    fill: ${(props) => props.fill || CONST.COLORS.GREY_G3} !important;
  }
`;
export const IconBarChart = styled(IcBarChart)`
  #kavabagaa11 {
    fill: ${(props) => props.fill || CONST.COLORS.GREY_G3} !important;
  }
`;
export const IconMoreVert = styled(IcMoreVert)`
  mask {
    fill: ${(props) => props.fill || CONST.COLORS.PRIMARY} !important;
  }
`;
export const IconReply = styled(IcReply)``;
export const IconMouse = styled(IcMouse)``;
export const IconVisibility = styled(IcVisibility)`
  #vladika {
    fill: ${(props) => props.fill}
  }
`;

export const IconSend = styled(IcSend)``;

export const IconTabs = styled(IcTabs)`
  #Black2 {
    fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
  }
`;

export const IconInfoOutline = styled(IcInfoOutline)`
`;

export const IconPeople = styled(IcPeople)`
  #Black {
    fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
  }
`;

export const IconDomain = styled(IcDomain)`
  g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;

export const IconDocuments = styled(IcDocuments)`
  g {
    path {
      fill: ${(props) => props.fill}
    }
  }
`;

export const IconChrome = styled(IcChrome)``;

export const IconArrowDropDown = styled(IcArrowDropDown)`
  g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;

export const IconPersonEdit = styled(IcPersonEdit)`
  g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;

export const IconFontDownloadOutline = styled(IcFontDownloadOutline)`
  g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;

export const IconImageOutline = styled(IcImageOutline)`
  g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;

export const IconLink = styled(IcLink)`
  g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;

export const IconReceipt = styled(IcReceipt)`
  g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;

export const IconLibraryBooks = styled(IcLibraryBooks)`
  g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;

export const IconEventNoteEdit = styled(IcEventNoteEdit)`
  g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;

export const IconEmail = styled(IcEmail)`
  g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;

export const IconAdd = styled(IcAdd)`
  g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;

export const IconDel = styled(IcDel)`
  g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;

export const IconExpandLess = styled(IcExpandLess)`
  g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;

export const IconExpandMore = styled(IcExpandMore)`
  g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;

export const IconChevronLeft = styled(IcChevronLeft)`
 g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;

export const IconMenu = styled(IcMenu)`
 g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;

export const IconClose = styled(IcClose)`
 g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;

export const IconStar = styled(IcStar)`
 g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3};
      &:hover {
        fill: ${CONST.COLORS.GREY_G2};
        cursor: pointer;
      }
    }
  }
`;

export const IconDelete = styled(IcDelete)`
 g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3};
      &:hover {
        fill: ${CONST.COLORS.GREY_G2};
        cursor: pointer;
      }
    }
  }
`;

export const IconImportGetProspect = styled(IcImportGetProspect)`
 g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3};
      &:hover {
        fill: ${CONST.COLORS.GREY_G2};
        cursor: pointer;
      }
    }
  }
`;

export const IconOpenInNew = styled(IcOpenInNew)`
  #BLACKKKKKKK {
    fill: ${(props) => props.fill || CONST.COLORS.GREY_G3};
  }
`;
export const IconEnrichment = styled(IcEnrichment)``;
export const IconIgnoreList = styled(IcIgnoreList)``;
export const IconImportCompanies = styled(IcImportCompanies)``;
export const IconImportContacts = styled(IcImportContacts)``;
export const IconImportUpload = styled(IcImportUpload)``;
export const IconPaperClip = styled(IcPaperClip)``;
export const IconCheckCircle = styled(IcCheckCircle)`
 g {
    path {
      fill: ${(props) => props.fill || CONST.COLORS.GREY_G3}
    }
  }
`;
export const IconCancel = styled(IcCancel)``;
export const IconScratch = styled(IcScratch)`
  #Mask2 {
    fill: ${(props) => props.fill};
  }
`;

export const IconImportData = styled(IcImportData)``;
export const IconTwoWaySync = styled(IcTwoWaySync)``;
export const IconDrag = styled(IcDrag)``;

export const IconEdit = styled(IcEdit)`
  g {
    path {
      ${(props) => props.fill && `
        fill: ${props.fill};
      `}
    }
  }
`;

export const IconOnboardingFindLead = styled(IcOnboardingFindLead)``;
export const IconOnboardingInfo = styled(IcOnboardingInfo)``;
export const IconOnboardingEnrich = styled(IcOnboardingEnrich)``;
export const IconOnboardingOrganize = styled(IcOnboardingOrganize)``;
export const IconExistingList = styled(IcExistingList)``;
export const IconHelp = styled(IcHelp)``;
export const IconExitToApp = styled(IcExitToApp)``;
export const IconGraphic = styled(IcGraphic)`
  width: 16px;
  g {
    path {
      ${(props) => props.fill && `
        fill: ${props.fill};
      `}
    }
  }
`;

export const IconBack = styled(IcIconBack)`
 g {
    path {
      ${(props) => props.fill && `
        fill: ${props.fill};
      `}
    }
}`;
export const IconFolder = styled(IcFolder)``;
export const IconCheck = styled(IcCheck)``;
export const IconCloseBlue = styled(IcCloseBlue)`
   g {
    path {
      ${(props) => props.fill && `
        fill: ${props.fill};
      `}
    }
  }
`;

export const IconHome = styled(IcHome)`
   g {
    path {
      ${(props) => props.fill && `
        fill: ${props.fill};
      `}
    }
  }
`;

export const IconEmptyState = styled(IcEmptyState)``;
export const IconGoogle = styled(IcGoogle)``;

export const IconArrowVectorRight = styled(IcArrowVectorRight)``;

const iconMixin = css`
  color: ${(props) => {
    switch (props.fill) {
      case 'danger':
        return CONST.COLORS.RED;
      case 'primary':
        return CONST.COLORS.PRIMARY;
      case 'default':
        return CONST.COLORS.GREY_G3;
      case 'grey':
        return CONST.COLORS.GREY_G4;
      case 'yellow':
        return CONST.COLORS.YELLOW_Y1;
      case 'success':
        return CONST.COLORS.SUCCESS_S1;
      case 'green':
        return CONST.COLORS.GREEN_G4;
      case 'purple':
        return CONST.COLORS.PURPLE;
      case 'pink':
        return CONST.COLORS.PINK;
      case 'white':
        return CONST.COLORS.WHITE;
      case 'red':
        return CONST.COLORS.RED;
      default:
        return props.fill || CONST.COLORS.GREY_G3;
    }
  }} !important;

  ${(props) => props.help && `
    cursor: help;
  `}

  ${(props) => props.clickable && `
    cursor: pointer;
  `}

  ${(props) => props.disabled && `
    opacity: 0.75;
    pointer-events: none;
  `}
`;

const iconMixinOnHover = css`
  &:hover {
    color: ${(props) => {
    switch (props.fillOnHover) {
      case 'danger':
        return CONST.COLORS.RED;
      case 'primary':
        return CONST.COLORS.PRIMARY;
      case 'default':
        return CONST.COLORS.GREY_G3;
      case 'grey':
        return CONST.COLORS.GREY_G4;
      case 'yellow':
        return CONST.COLORS.YELLOW_Y1;
      case 'success':
        return CONST.COLORS.SUCCESS_S1;
      case 'green':
        return CONST.COLORS.GREEN_G4;
      case 'purple':
        return CONST.COLORS.PURPLE;
      case 'pink':
        return CONST.COLORS.PINK;
      case 'white':
        return CONST.COLORS.WHITE;
      case 'red':
        return CONST.COLORS.RED;
      default:
        return props.fill || CONST.COLORS.GREY_G3;
    }
  }} !important;
  }
`;

export const MaterialIcon = styled.span.attrs((props) => ({
  className: `material-icons${props.outlined ? '-outlined' : ''}`,
}))`
  font-size: ${(props) => (typeof props.size === 'number' ? props.size : CONST.ComponentsConfig.icon[props.size].fontSize)}px !important;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'inherit')};
  ${(props) => props.fillOnHover && iconMixinOnHover}
  ${iconMixin}
`;
