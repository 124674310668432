export const DEMO_USER_ACCOUNT = 'demo@getprospect.com';
export const DEMO_USER_DISABLE_TEXT = 'Please Sign-up to see more';

export const DEMO_ERROR_TYPES = Object.freeze({
  LIMIT: 'limit',
  OFFLINE: 'offline',
});
export const DEMO_ERROR_MESSAGES = Object.freeze({
  [DEMO_ERROR_TYPES.LIMIT]: 'Sorry, but you reached the limits for demo account. Please Sign up to get 50 emails for free.',
  [DEMO_ERROR_TYPES.OFFLINE]: 'The demo is unavailable for technical issues. We are going to fix it in.',
});
