import get from 'lodash/get';

import { currentCompanyTechnologiesSelector } from 'data/selectors/companies';

export const getAllProperties = () => (dispatch, getState) => {
  const state = getState();
  const technologies = currentCompanyTechnologiesSelector(state);

  dispatch({
    type: 'GET_ALL_PROPERTIES',
    payload: {
      endpoint: 'api/v1/properties',
      method: 'GET',
      delayLoad: 0,
    },
    technologies,
    components: ['mapContactProperties', 'propertiesLoading'],
  });
};

export const addProperty = (property, onSuccess = () => {}, onFailed = () => {}) => (dispatch, getStore) => {
  const store = getStore();
  const { firstName, lastName, _id } = get(store, 'user.account', {});

  dispatch({
    type: 'ADD_PROPERTY',
    payload: {
      endpoint: 'api/v1/properties',
      method: 'POST',
      body: property,
      showResponseError: true,
    },
    owner: { firstName, lastName, _id },
    onSuccess,
    onFailed,
  });
};

export const getPropertyGroups = () => (dispatch) => {
  dispatch({
    type: 'GET_PROPERTY_GROUPS',
    payload: {
      endpoint: 'api/v1/properties/groups',
      method: 'GET',
    },
  });
};

export const setSelectedProperties = (selectedProperties) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_PROPERTIES',
    data: selectedProperties,
  });
};

export const setSelectedPropertyGroups = (selectedPropertyGroups) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_PROPERTY_GROUPS',
    data: selectedPropertyGroups,
  });
};

export const deleteProperties = (propertyIds, onSuccess = () => {}) => (dispatch) => {
  dispatch({
    type: 'DELETE_PROPERTIES',
    payload: {
      endpoint: 'api/v1/properties',
      method: 'DELETE',
      body: {
        propertyIds,
      },
      showResponseError: true,
    },
    propertyIds,
    onSuccess,
  });
};

export const deletePropertyGroups = ({ names, sourceType }) => (dispatch) => {
  dispatch({
    type: 'DELETE_PROPERTY_GROUPS',
    payload: {
      endpoint: 'api/v1/properties/groups/delete',
      method: 'DELETE',
      body: {
        names,
        sourceType,
      },
    },
    names,
  });
};

export const restoreProperties = (propertyIds) => (dispatch) => {
  dispatch({
    type: 'RESTORE_PROPERTIES',
    payload: {
      endpoint: 'api/v1/properties',
      method: 'PATCH',
      body: {
        propertyIds,
      },
    },
    propertyIds,
  });
};

export const updateProperties = (propertyIds, data, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_PROPERTIES',
    payload: {
      endpoint: 'api/v1/properties',
      method: 'PUT',
      body: {
        propertyIds,
        ...data,
      },
    },
    onSuccess,
    onFailed,
    propertyIds,
    newData: data,
  });
};

export const updateProperty = ({
  propertyId,
  property,
  onSuccess,
  onFailed,
}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_PROPERTY',
    payload: {
      endpoint: `api/v1/properties/${propertyId}`,
      method: 'PUT',
      body: property,
    },
    onSuccess,
    onFailed,
    propertyId,
    newData: property,
  });
};

export const createGroup = ({ name, sourceType }, { onSuccess, onFailed }) => (dispatch) => {
  dispatch({
    type: 'CREATE_GROUP',
    payload: {
      endpoint: 'api/v1/properties/groups',
      method: 'POST',
      body: {
        name,
        sourceType,
      },
    },
    onSuccess,
    onFailed,
  });
};

export const renameGroup = (groupName, { name }) => (dispatch) => {
  dispatch({
    type: 'RENAME_PROPERTY_GROUP',
    payload: {
      endpoint: `api/v1/properties/groups/${groupName}`,
      method: 'PATCH',
      body: { name },
    },
    oldName: groupName,
    newName: name,
  });
};
