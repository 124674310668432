import * as fileDownload from 'js-file-download';

const initialState = {
  invoices: {
    data: [],
    totalItems: 0,
    pageSize: 25,
    page: 1,
    sort: {},
  },
  fsInvoiceExists: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_INVOICES': {
      const { data, meta } = action.payload;
      return {
        ...state,
        invoices: {
          ...state.invoices,
          data,
          ...meta,
        },
      };
    }
    case 'DOWNLOAD_INVOICE': {
      const { invoiceId } = action;
      fileDownload(action.payload, `Invoice-GP-${invoiceId}.pdf`);
      return state;
    }
    case 'SIGN_OUT':
    case 'SWITCH_WORKSPACE': {
      return initialState;
    }
    case 'CHECK_FS_INVOICE': {
      return {
        ...state,
        fsInvoiceExists: action.payload,
      };
    }
    default:
      return state;
  }
};
