import React, { useEffect, useState } from 'react';
import {
  Icon,
} from 'antd';
import get from 'lodash/get';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';

import { toggleModal } from 'data/actions/modals';

import { getSelectedList } from 'data/selectors/contacts';
import { updateList, getContactsList } from 'data/actions/lists';
import {
  HeaderStyle,
} from '../../previewBars/styles';
import {
  ModalTitle,
  CloseIcon,
} from '../baseModal/styles';
import {
  Header,
  Body,
  Divider,
  ListNameInput,
  Container,
} from './styles';

const ListDetailsBar = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const { listsId } = useSelector(modalOptionsSelector);
  const [name, setName] = useState(false);
  const list = useSelector(getSelectedList);

  useEffect(() => {
    dispatch(getContactsList(listsId));
  }, [listsId, dispatch]);

  useEffect(() => {
    setName(list.name);
  }, [list]);

  return (
    <Container
      title={(
        <ModalTitle>
          List Details
          <CloseIcon
            onClick={() => dispatch(toggleModal('list_details', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      headerStyle={HeaderStyle}
      placement="right"
      onClose={() => dispatch(toggleModal('list_details', false))}
      closable={false}
      visible={visible}
      bodyStyle={{
        padding: 0,
      }}
      destroyOnClose
    >
      <Header>
        <div>
          <ListNameInput
            placeholder="Name your list"
            suffix={<Icon type="edit" theme="twoTone" />}
            size="large"
            onBlur={() => { dispatch(updateList(list.listId, { name })); }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <b>Updated at:</b>
          {' '}
          { moment(get(list, 'updatedAt')).format('LLL') }
        </div>
        <div>
          <b>Created at:</b>
          {' '}
          { moment(get(list, 'createdAt')).format('LLL') }
        </div>
        <Divider />
      </Header>
      <Body>
        <div>
          <b>Other tools that use this list</b>
        </div>
        <div>
          None
        </div>
      </Body>
    </Container>
  );
};

export default ListDetailsBar;
