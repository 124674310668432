import React from 'react';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import {
  getSeniorityLevelOptions,
  getPositionOptions,
  getContactLocationOptions,
  getCompanyLocationOptions,
  getIndustryOptions,
  getEmployeesOptions,
  getContactEmailOptions,
  // getLastUpdatedOptions,
  getCompanyDomainOptions,
  getCompanyNameOptions,
  getTechnologiesOptions,
  getCompanyTypeOptions,
  getCompanyKeywordOptions,
  // getDepartmentsOptions,
  departmentsOptionAutocomplete,
  industriesOptionAutocomplete,
  contactKeywordsAutocomplete,
  contactNameAutocomplete,
} from 'data/actions/search';

import CompanyIcon from 'components/companyIcon';
import { SOURCE_TYPE } from 'data/types/source.types';
import * as Types from 'data/types/search.filters.types';
import { CustomRenderLine, LabelItem } from './styles';

const FiltersStructure = [
  {
    groupName: 'Filters',
    source: SOURCE_TYPE.CONTACT,
    open: true,
    visibleTabs: ['contact'],
    filters: [
      {
        label: 'Job title',
        source: SOURCE_TYPE.CONTACT,
        property: Types.CONTACT_POSITION,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        showDefaultBeforeFirstType: true,
        hideDropdownWhenDefaultListEmpty: true,
        showLastSearchWhenEmptyInput: true,
        disableNotFoundDropdown: true,
        // loadDefault: () => getPositionOptions({}),
        excluded: true,
        isDynamic: true,
        includedOp: 'CONTAINS_POSITION',
        excludedOp: 'NOT_CONTAINS_POSITION',
        input: {
          placeholder: 'Add positon',
          autoComplete: (value) => getPositionOptions({ name: value, limit: value ? 10 : 5 }),
        },
        dataTour: ['save-leads-tour-step-1', 'demo-search-leads-step-1'],
        activeInDemoTour: true,
        collapseIfEmpty: false,
        splittable: true,
      },
      {
        label: 'Departments',
        source: SOURCE_TYPE.CONTACT,
        property: Types.CONTACT_DEPARTMENTS,
        type: Types.SEARCH_FILTER_TYPES.TREE,
        // loadDefault: () => getDepartmentsOptions({}),
        excluded: true,
        isDynamic: true,
        includedOp: 'CONTAINS_POSITION',
        excludedOp: 'NOT_CONTAINS_POSITION',
        input: {
          placeholder: 'Add department',
          autoComplete: (value) => departmentsOptionAutocomplete({ name: value }),
        },
        collapseIfEmpty: false,
        splittable: true,
      },
      {
        label: 'Seniority',
        source: SOURCE_TYPE.CONTACT,
        property: Types.CONTACT_SENIORITY,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        loadDefault: () => getSeniorityLevelOptions(),
        excluded: true,
        isDynamic: true,
        includedOp: 'CONTAINS_SENIORITY',
        excludedOp: 'NOT_CONTAINS_SENIORITY',
        collapseIfEmpty: false,
        activeInDemoTour: true,
      },
      {
        label: 'Location',
        source: SOURCE_TYPE.CONTACT,
        property: Types.CONTACT_LOCATION,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        loadDefault: () => getContactLocationOptions(),
        excluded: true,
        isDynamic: true,
        includedOp: 'LOCATION',
        excludedOp: 'NOT_LOCATION',
        input: {
          placeholder: 'Add location',
          autoComplete: (value, autocompleteType) => getContactLocationOptions(value, 10, autocompleteType),
        },
        collapseIfEmpty: false,
        splittable: false,
        activeInDemoTour: true,
        dataTour: ['demo-search-leads-step-3'],
        autocompleteOptions: [
          {
            label: 'Region',
            value: 'region',
            default: true,
          },
          {
            label: 'Postal code',
            value: 'postalCode',
            default: false,
          },
        ],
      },
      {
        label: 'Industry',
        property: Types.COMPANY_INDUSTRY,
        source: SOURCE_TYPE.COMPANY,
        type: Types.SEARCH_FILTER_TYPES.TREE,
        excluded: true,
        isDynamic: true,
        includedOp: 'EQ',
        excludedOp: 'NEQ',
        activeInDemoTour: true,
        loadDefault: () => getIndustryOptions(),
        input: {
          placeholder: 'Add industry',
          autoComplete: (value) => industriesOptionAutocomplete(value),
        },
        collapseIfEmpty: false,
        splittable: false,
        dataTour: ['demo-search-leads-step-2'],
      },
      {
        label: 'Employees',
        property: Types.COMPANY_SIZE,
        source: SOURCE_TYPE.COMPANY,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        excluded: false,
        isDynamic: true,
        includedOp: 'RANGE',
        loadDefault: () => getEmployeesOptions(),
        collapseIfEmpty: false,
        activeInDemoTour: true,
      },
      {
        label: 'Headquarters',
        property: Types.COMPANY_LOCATION,
        source: SOURCE_TYPE.COMPANY,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        loadDefault: () => getCompanyLocationOptions(),
        excluded: true,
        isDynamic: true,
        includedOp: 'LOCATION',
        excludedOp: 'NOT_LOCATION',
        input: {
          placeholder: 'Add location',
          autoComplete: (value, autocompleteType) => getCompanyLocationOptions(value, 10, autocompleteType),
        },
        collapseIfEmpty: false,
        splittable: false,
        autocompleteOptions: [
          {
            label: 'Region',
            value: 'region',
            default: true,
          },
          {
            label: 'Postal code',
            value: 'postalCode',
            default: false,
          },
        ],
      },
      {
        label: 'Domain',
        property: Types.COMPANY_DOMAIN,
        source: SOURCE_TYPE.COMPANY,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        excluded: true,
        isDynamic: true,
        includedOp: 'EQ',
        excludedOp: 'NEQ',
        input: {
          placeholder: 'Add company domain',
          autoComplete: (value) => getCompanyDomainOptions(value),
        },
        collapseIfEmpty: false,
        splittable: true,
        applyImportFile: true,
        hideDropdownWhenDefaultListEmpty: true,
        showDefaultBeforeFirstType: true,
        showLastSearchWhenEmptyInput: true,
        disableNotFoundDropdown: true,
        renderOption: (label) => (
          <CustomRenderLine>
            <CompanyIcon
              animated={false}
              size="20px"
              style={{ minWidth: 20, borderRadius: 4 }}
              name={get(label, '[0]', '').toUpperCase()}
              domain={label || ''}
              code={label || ''}
            />
            <LabelItem>{label}</LabelItem>
          </CustomRenderLine>
        ),
      },
      {
        label: 'Company name',
        property: Types.COMPANY_NAME,
        source: SOURCE_TYPE.COMPANY,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        excluded: true,
        isDynamic: true,
        includedOp: 'CONTAINS',
        excludedOp: 'NOT_CONTAINS',
        hideDropdownWhenDefaultListEmpty: true,
        showDefaultBeforeFirstType: true,
        showLastSearchWhenEmptyInput: true,
        disableNotFoundDropdown: true,
        input: {
          placeholder: 'Add company name',
          autoComplete: (value) => getCompanyNameOptions(value),
        },
        processUniqueStrategy: (names) => uniqBy(names, 'name'),
        renderOption: ({ domain, name }) => (
          <CustomRenderLine>
            <CompanyIcon
              animated={false}
              size="24px"
              style={{ minWidth: 24, borderRadius: 4 }}
              name={get(name, '[0]', '').toUpperCase()}
              domain={domain || ''}
              code={name || ''}
            />
            <LabelItem>{name}</LabelItem>
          </CustomRenderLine>
        ),
        collapseIfEmpty: false,
        splittable: true,
        applyImportFile: true,
      },
      {
        label: 'Founded year',
        property: Types.COMPANY_FOUNDED_AT,
        source: SOURCE_TYPE.COMPANY,
        type: Types.SEARCH_FILTER_TYPES.DATE_RANGE,
        excluded: false,
        includedOp: 'UNIVERSAL_RANGE',
        collapseIfEmpty: false,
        disableTags: false,
        showActiveControls: false,
        splittable: false,
        updateOnly: true,
        isDynamic: true,
      },
      {
        label: 'Company type',
        source: SOURCE_TYPE.CONTACT,
        property: Types.COMPANY_TYPE,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        loadDefault: () => getCompanyTypeOptions(),
        excluded: true,
        isDynamic: true,
        includedOp: 'CONTAINS',
        excludedOp: 'NOT_CONTAINS',
        collapseIfEmpty: false,
      },
      {
        label: 'Company keywords',
        property: Types.COMPANY_KEYWORDS,
        source: SOURCE_TYPE.COMPANY,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        excluded: true,
        isDynamic: true,
        showDefaultBeforeFirstType: true,
        hideDropdownWhenDefaultListEmpty: true,
        showLastSearchWhenEmptyInput: true,
        disableNotFoundDropdown: true,
        includedOp: 'KEYWORDS_SEARCH',
        excludedOp: 'NOT_KEYWORDS_SEARCH',
        // loadDefault: getCompanyKeywordOptions(),
        input: {
          placeholder: 'Add company keyword',
          autoComplete: (value) => getCompanyKeywordOptions(value),
        },
        collapseIfEmpty: false,
        splittable: true,
        checkboxOptions: [
          {
            label: 'Search in the description',
            value: 'search_desc',
            default: true,
          },
        ],
      },
      {
        label: 'Technologies',
        property: Types.COMPANY_TECHNOLOGIES,
        source: SOURCE_TYPE.COMPANY,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        excluded: true,
        isDynamic: true,
        splittable: false,
        includedOp: 'EQ',
        excludedOp: 'NEQ',
        input: {
          placeholder: 'Add technology',
          autoComplete: (value) => getTechnologiesOptions(value, value ? 10 : 5),
        },
        hideDropdownWhenDefaultListEmpty: true,
        showDefaultBeforeFirstType: true,
        showLastSearchWhenEmptyInput: true,
        disableNotFoundDropdown: true,
        collapseIfEmpty: true,
        renderOption: (item) => (
          <CustomRenderLine>
            <CompanyIcon
              animated={false}
              size="24px"
              style={{ minWidth: 24, borderRadius: 4 }}
              name={get(item, 'label[0]', '').toUpperCase()}
              domain={item.domain || ''}
              code={item.label || ''}
            />
            <LabelItem>{item.label}</LabelItem>
          </CustomRenderLine>
        ),
      },
      {
        label: 'Contact name',
        source: SOURCE_TYPE.CONTACT,
        property: Types.CONTACT_NAME,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        excluded: true,
        isDynamic: true,
        includedOp: 'CONTAINS_NAME',
        excludedOp: 'NOT_CONTAINS_NAME',
        input: {
          placeholder: 'Add name',
          autoComplete: (value) => contactNameAutocomplete(value),
        },
        collapseIfEmpty: false,
        splittable: true,
        hideDropdownWhenDefaultListEmpty: true,
        disableNotFoundDropdown: true,
      },
      {
        label: 'Contact keywords',
        property: Types.KEYWORDS,
        source: SOURCE_TYPE.CONTACT,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        includedOp: 'KEYWORDS_SEARCH',
        isDynamic: true,
        input: {
          placeholder: 'Add keywords',
          autoComplete: (value) => contactKeywordsAutocomplete(value),
        },
        checkboxOptions: [
          {
            label: 'Search in the summary',
            value: 'search_desc',
            default: true,
          },
        ],
        hideDropdownWhenDefaultListEmpty: true,
        disableNotFoundDropdown: true,
      },
      {
        label: 'Email',
        source: SOURCE_TYPE.CONTACT,
        property: Types.CONTACT_EMAIL,
        type: Types.SEARCH_FILTER_TYPES.CHECKBOX,
        loadDefault: () => getContactEmailOptions(),
        excluded: true,
        includedOp: 'EMAIL_STATUS',
        collapseIfEmpty: false,
        components: ['subscriptionsLoader'],
        count: Types.COUNT_OPTIONS_TYPES.SINGLE,
      },
      // {
      //   label: 'Last updated',
      //   source: SOURCE_TYPE.CONTACT,
      //   property: Types.CONTACT_UPDATED_AT,
      //   type: Types.SEARCH_FILTER_TYPES.LIST,
      //   loadDefault: () => getLastUpdatedOptions(SOURCE_TYPE.CONTACT),
      //   excluded: false,
      //   includedOp: 'UNIVERSAL_RANGE',
      //   collapseIfEmpty: false,
      //   count: Types.COUNT_OPTIONS_TYPES.SINGLE,
      //   updateOnly: true,
      // },
    ],
  },
  {
    groupName: 'Filters',
    open: window.innerHeight > 900, // close filters if screen size < 900px
    source: SOURCE_TYPE.COMPANY,
    visibleTabs: ['company'],
    filters: [
      {
        label: 'Headquarters',
        property: Types.COMPANY_LOCATION,
        source: SOURCE_TYPE.COMPANY,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        loadDefault: () => getCompanyLocationOptions(),
        excluded: true,
        isDynamic: true,
        includedOp: 'LOCATION',
        excludedOp: 'NOT_LOCATION',
        input: {
          placeholder: 'Add location',
          autoComplete: (value, autocompleteType) => getCompanyLocationOptions(value, 10, autocompleteType),
        },
        collapseIfEmpty: false,
        splittable: false,
        autocompleteOptions: [
          {
            label: 'Region',
            value: 'region',
            default: true,
          },
          {
            label: 'Postal code',
            value: 'postalCode',
            default: false,
          },
        ],
      },
      {
        label: 'Industry',
        property: Types.COMPANY_INDUSTRY,
        source: SOURCE_TYPE.COMPANY,
        type: Types.SEARCH_FILTER_TYPES.TREE,
        excluded: true,
        isDynamic: true,
        includedOp: 'EQ',
        excludedOp: 'NEQ',
        loadDefault: () => getIndustryOptions(),
        input: {
          placeholder: 'Add industry',
          autoComplete: (value) => industriesOptionAutocomplete(value),
        },
        collapseIfEmpty: false,
        splittable: false,
      },
      {
        label: 'Employees',
        property: Types.COMPANY_SIZE,
        source: SOURCE_TYPE.COMPANY,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        excluded: false,
        isDynamic: true,
        includedOp: 'RANGE',
        loadDefault: () => getEmployeesOptions(),
        collapseIfEmpty: false,
      },
      {
        label: 'Technologies',
        property: Types.COMPANY_TECHNOLOGIES,
        source: SOURCE_TYPE.COMPANY,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        excluded: true,
        isDynamic: true,
        splittable: false,
        includedOp: 'EQ',
        excludedOp: 'NEQ',
        input: {
          placeholder: 'Add technology',
          autoComplete: (value) => getTechnologiesOptions(value, value ? 10 : 5),
        },
        collapseIfEmpty: true,
        hideDropdownWhenDefaultListEmpty: true,
        showDefaultBeforeFirstType: true,
        showLastSearchWhenEmptyInput: true,
        disableNotFoundDropdown: true,
        renderOption: (item) => (
          <CustomRenderLine>
            <CompanyIcon
              animated={false}
              size="24px"
              style={{ minWidth: 24, borderRadius: 4 }}
              name={get(item, 'label[0]', '').toUpperCase()}
              domain={item.domain || ''}
              code={item.label || ''}
            />
            <LabelItem>{item.label}</LabelItem>
          </CustomRenderLine>
        ),
      },
      {
        label: 'Domain',
        property: Types.COMPANY_DOMAIN,
        source: SOURCE_TYPE.COMPANY,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        excluded: true,
        isDynamic: true,
        includedOp: 'EQ',
        excludedOp: 'NEQ',
        input: {
          placeholder: 'Add company domain',
          autoComplete: (value) => getCompanyDomainOptions(value),
        },
        collapseIfEmpty: false,
        splittable: true,
        applyImportFile: true,
        hideDropdownWhenDefaultListEmpty: true,
        showDefaultBeforeFirstType: true,
        showLastSearchWhenEmptyInput: true,
        disableNotFoundDropdown: true,
        renderOption: (label) => (
          <CustomRenderLine>
            <CompanyIcon
              animated={false}
              size="24px"
              style={{ minWidth: 24, borderRadius: 4 }}
              name={get(label, '[0]', '').toUpperCase()}
              domain={label || ''}
              code={label || ''}
            />
            <LabelItem>{label}</LabelItem>
          </CustomRenderLine>
        ),
      },
      {
        label: 'Name',
        property: Types.COMPANY_NAME,
        source: SOURCE_TYPE.COMPANY,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        excluded: true,
        isDynamic: true,
        includedOp: 'CONTAINS',
        excludedOp: 'NOT_CONTAINS',
        input: {
          placeholder: 'Add company name',
          autoComplete: (value) => getCompanyNameOptions(value),
        },
        hideDropdownWhenDefaultListEmpty: true,
        showDefaultBeforeFirstType: true,
        showLastSearchWhenEmptyInput: true,
        disableNotFoundDropdown: true,
        processUniqueStrategy: (names) => uniqBy(names, 'name'),
        renderOption: ({ domain, name }) => (
          <CustomRenderLine>
            <CompanyIcon
              animated={false}
              size="24px"
              style={{ minWidth: 24, borderRadius: 4 }}
              name={get(name, '[0]', '').toUpperCase()}
              domain={domain || ''}
              code={name || ''}
            />
            <LabelItem>{name}</LabelItem>
          </CustomRenderLine>
        ),
        collapseIfEmpty: false,
        splittable: true,
        applyImportFile: true,
      },
      {
        label: 'Founded year',
        property: Types.COMPANY_FOUNDED_AT,
        source: SOURCE_TYPE.COMPANY,
        type: Types.SEARCH_FILTER_TYPES.DATE_RANGE,
        excluded: false,
        includedOp: 'UNIVERSAL_RANGE',
        collapseIfEmpty: false,
        disableTags: false,
        showActiveControls: false,
        splittable: false,
        updateOnly: true,
        isDynamic: true,
      },
      {
        label: 'Type',
        source: SOURCE_TYPE.COMPANY,
        property: Types.COMPANY_TYPE,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        loadDefault: () => getCompanyTypeOptions(),
        excluded: true,
        isDynamic: true,
        includedOp: 'CONTAINS',
        excludedOp: 'NOT_CONTAINS',
        collapseIfEmpty: false,
      },
      {
        label: 'Keywords',
        property: Types.COMPANY_KEYWORDS,
        source: SOURCE_TYPE.COMPANY,
        type: Types.SEARCH_FILTER_TYPES.LIST,
        showDefaultBeforeFirstType: true,
        hideDropdownWhenDefaultListEmpty: true,
        disableNotFoundDropdown: true,
        showLastSearchWhenEmptyInput: true,
        excluded: true,
        isDynamic: true,
        includedOp: 'KEYWORDS_SEARCH',
        excludedOp: 'NOT_KEYWORDS_SEARCH',
        // loadDefault: getCompanyKeywordOptions(),
        input: {
          placeholder: 'Add company keyword',
          autoComplete: (value) => getCompanyKeywordOptions(value),
        },
        collapseIfEmpty: false,
        splittable: true,
        checkboxOptions: [
          {
            label: 'Search in the description',
            value: 'search_desc',
            default: true,
          },
        ],
      },
      // {
      //   label: 'Last updated',
      //   source: SOURCE_TYPE.COMPANY,
      //   property: Types.COMPANY_UPDATED_AT,
      //   type: Types.SEARCH_FILTER_TYPES.LIST,
      //   loadDefault: () => getLastUpdatedOptions(SOURCE_TYPE.COMPANY),
      //   excluded: false,
      //   includedOp: 'UNIVERSAL_RANGE',
      //   collapseIfEmpty: false,
      //   count: Types.COUNT_OPTIONS_TYPES.SINGLE,
      //   updateOnly: true,
      // },
    ],
  },
];

export default FiltersStructure;
