import { removeConsecutiveDuplicates, removeLastOccurrenceFromArray } from 'helpers/arrays';

const initialState = {
  history: [],
  previewHistory: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_HISTORY': {
      return {
        ...state,
        history: removeConsecutiveDuplicates([...state.history, action.data.url]),
      };
    }
    case 'REMOVE_HISTORY': {
      return {
        ...state,
        history: removeLastOccurrenceFromArray(state.history, action.data.url),
      };
    }
    case 'ADD_PREVIEW_HISTORY': {
      return {
        ...state,
        previewHistory: [...state.previewHistory, action.data],
      };
    }
    case 'CLEAR_PREVIEW_HISTORY': {
      return {
        ...state,
        previewHistory: initialState.previewHistory,
      };
    }
    case 'SIGN_OUT':
    case 'SWITCH_WORKSPACE': {
      return initialState;
    }
    default:
      return state;
  }
};
