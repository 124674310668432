import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleMessage } from 'data/actions/message';

import { billingSelector, accountSelector, workspaceIdSelector } from 'data/selectors/user';
import { subscriptionsSelector } from 'data/selectors/subscriptions';
import useFastSpring from 'hooks/useFastSpring';
import { encryptSensitiveData } from 'data/actions/subscriptions';
import { PAYERS } from 'constants/payers';

import { FASTSPRING_ERROR_EVENT, FASTSPRING_POPUP_CLOSE_EVENT } from 'data/types/event.types';
import { toggleModal } from 'data/actions/modals';
import { updateLoading } from 'data/actions/loading';

const useFastSpringCheckout = ({ setLoading = () => {} } = {}) => {
  const userBilling = useSelector(billingSelector);
  const account = useSelector(accountSelector);
  const workspaceId = useSelector(workspaceIdSelector);
  const subscription = useSelector(subscriptionsSelector);
  const dispatch = useDispatch();

  const [successfullPaymentCallback, setSuccessfullPaymentCallback] = useState();

  const { fastspring } = useFastSpring({});

  useEffect(() => {
    const flag = !fastspring?.builder;
    setLoading(flag);
    dispatch(updateLoading('fastSpringUpdateLoading', flag));
  }, [dispatch, fastspring, setLoading]);

  useEffect(() => {
    function fastSpringErrorListener(e) {
      const { detail = {} } = e;
      const { code, error } = detail;

      if (code >= 400 && error) {
        dispatch(toggleMessage('error', { header: 'Something went wrong' }));
      }
    }
    window.addEventListener(FASTSPRING_ERROR_EVENT, fastSpringErrorListener);

    return () => {
      window.removeEventListener(FASTSPRING_ERROR_EVENT, fastSpringErrorListener);
    };
  }, [dispatch]);

  useEffect(() => {
    let eventFunc = () => {};

    if (successfullPaymentCallback) eventFunc = successfullPaymentCallback;
    window.addEventListener(FASTSPRING_POPUP_CLOSE_EVENT, eventFunc);

    return () => {
      window.removeEventListener(FASTSPRING_POPUP_CLOSE_EVENT, eventFunc);
    };
  }, [successfullPaymentCallback]);

  const addItemToCheckout = useCallback((fastSpringPurchaseItem, callback = () => {}) => {
    setLoading(true);
    dispatch(updateLoading('fastSpringUpdateLoading', true));

    if (!fastspring?.builder) return;
    fastspring.builder.reset(() => {
      fastspring.builder.add(fastSpringPurchaseItem, () => {
        setLoading(false);
        callback();
      });
    });
  }, [dispatch, fastspring, setLoading]);

  const updateItemQuantity = useCallback((fastSpringPurchaseItem, quantity, callback = () => {}, disableLoading = false) => {
    if (!disableLoading) {
      setLoading(true);
      dispatch(updateLoading('fastSpringUpdateLoading', true));
    }

    if (!fastspring?.builder) return;
    fastspring.builder.update(fastSpringPurchaseItem, quantity, () => {
      if (!disableLoading) {
        setLoading(false);
      }
      callback();
    });
  }, [dispatch, fastspring, setLoading]);

  const fastSpringCheckout = useCallback(({
    firstPayment = false,
    detailes = {},
    successCallback,
    billingCycle,
    planPrice,
    bulkId,
    isBulkVerification,
    planId,
    phoneSearchPrice,
    phoneSearchQuantity,
  }) => {
    try {
      setLoading(true);
      if (successCallback) {
        setSuccessfullPaymentCallback(() => successCallback);
      }
      const {
        firstName = account?.firstName || '',
        lastName = account?.lastName || '',
        phone = account?.phone || '',
      } = detailes;
      let { email = userBilling?.invoiceEmail || account?.email || '' } = detailes;
      const tagsData = {
        workspaceId,
        billingCycle,
        planPrice,
        bulkId,
        isBulkVerification,
        email,
        userId: account?._id,
        planId,
        phoneSearchPrice,
        phoneSearchQuantity,
      };

      const addItemAndCheckout = () => {
        fastspring.builder.tag(tagsData);

        fastspring.builder.checkout();
        setLoading(false);
        dispatch(toggleModal('bulk_verification', false));
        dispatch(toggleModal('billing', false));
      };

      if (firstPayment) {
        if (subscription?.payer === PAYERS.COMPANY) email = '';
        fastspring.builder.recognize(
          {
            email,
            firstName,
            lastName,
            phoneNumber: phone,
          },
        );
        addItemAndCheckout();
      } else {
        dispatch(encryptSensitiveData({
          data: {
            contact: {
              email,
              firstName,
              lastName,
              phoneNumber: phone,
            },
          },
          onSuccess: (response) => {
            const { securePayload, secureKey } = response;
            fastspring.builder.secure(securePayload, secureKey, addItemAndCheckout);
          },
        }));
      }
    } catch {
      setLoading(false);
    }
  }, [fastspring, setLoading, userBilling, account, workspaceId, dispatch, subscription]);

  return {
    fastspring, fastSpringCheckout, addItemToCheckout, updateItemQuantity,
  };
};

export default useFastSpringCheckout;
