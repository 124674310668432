import get from 'lodash/get';
import React, { useState } from 'react';

import { Tag } from 'components/common';
import { removeTime } from 'helpers/dateFormat';
import * as Types from 'data/types/search.filters.types';
import { INSIGHTS_REQUEST_TYPE } from 'data/types/insights.request.type';
import {
  Field,
  FieldDataTitle,
  FieldDataWrapper,
  FieldListContainer,
  TagDangerStyles,
  TagMoreStyles,
  TagPrimaryStyles,
  TagsActivityWrapper,
  FieldDataDescription,
} from '../../../styles';

const SearchFilter = ({ filter, hasActions }) => {
  const [hasLimitFilters, setHasLimitFilters] = useState(true);

  const LIMIT_FILTERS = 3;

  const getValue = (operator, value) => {
    if (operator === 'EMAIL_STATUS') {
      if (value === 'all') {
        return 'with emails';
      } if (value === 'all_contacts') {
        return 'all contacts';
      }
    }
    if (operator === 'UNIVERSAL_RANGE') {
      if (['contact.updatedAt', 'company.updatedAt'].includes(filter.property)) {
        return `${removeTime(String(value[0]))} - ${removeTime(String(value[1]))}`;
      }
      const minDate = get(value, '[0]', null);
      const maxDate = get(value, '[1]', null);
      const unknown = get(value, '[2]', null);
      let resultValue = '';

      if (minDate && !maxDate) {
        resultValue = `> ${minDate}`;
      }
      if (!minDate && maxDate) {
        resultValue = `< ${maxDate}`;
      }
      if (minDate && maxDate) {
        resultValue = `${minDate} - ${maxDate}`;
      }
      resultValue = `${resultValue} ${unknown ? ' or unknown' : ''}`;
      return resultValue;
    }
    if (['NOT_LOCATION', 'LOCATION'].some((i) => i === operator) && Array.isArray(value)) {
      return get(value, '[0]');
    }
    if (typeof value === 'object') {
      if (value?.printedData) {
        return value.printedData;
      }
      if (Array.isArray(value) && filter.property === 'company.technologies') {
        return value[0];
      }
      return '';
    }
    return value;
  };

  const showAllFilters = () => setHasLimitFilters(false);

  const includedFilters = filter.included?.value?.length ? filter.included.value : [];
  const excludedFilters = filter.excluded?.value?.length ? filter.excluded.value : [];

  const allFilters = [...includedFilters, ...excludedFilters];

  const slicedFilters = hasLimitFilters ? allFilters.slice(0, LIMIT_FILTERS) : allFilters;

  return (
    <Field hasActions={hasActions}>
      <FieldListContainer>
        <FieldDataWrapper>
          <FieldDataTitle>{`${filter.label}: `}</FieldDataTitle>
          <TagsActivityWrapper>
            {
              slicedFilters.map((value) => (filter?.count === Types.COUNT_OPTIONS_TYPES.SINGLE ? (<FieldDataDescription key={`search_${value.entry}_${value._id}_${getValue(filter[value.entry].operator, value.value)}`}>{getValue(filter[value.entry].operator, value.value)}</FieldDataDescription>) : (
                <Tag key={`search_${value.entry}_${value._id}_${getValue(filter[value.entry].operator, value.value)}`} style={value.entry === INSIGHTS_REQUEST_TYPE.INCLUDED ? TagPrimaryStyles : TagDangerStyles}>
                  {getValue(filter[value.entry].operator, value.value)}
                </Tag>
              )))
            }
            {
              hasLimitFilters && allFilters.length > LIMIT_FILTERS ? (
                <Tag style={TagMoreStyles} onClick={showAllFilters}>
                  {`+${allFilters.length - LIMIT_FILTERS} more`}
                </Tag>
              ) : null
            }
          </TagsActivityWrapper>
        </FieldDataWrapper>
      </FieldListContainer>
    </Field>
  );
};

export default SearchFilter;
