import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Wide = {
  width: '100%',
};
export const InputContainer = styled.div`
  padding: 10px 0px;
  width: 100%;
`;

export const Half = styled.div`
  width: 50%;
  padding: 0 20px;

  &:first-child {
    border-right: 1px solid #dfe3eb;
  }
`;

export const PropertyItem = styled.div`
  padding: 8px 0;
  font-size: 5px;
  margin-left: 15px;

  span {
    font-size: 15px;
  }
`;

export const PropertiesContainer = styled.div`
  height: 250px;
  overflow-y: scroll;
  margin: 15px 0;
`;

export const PropertyGroup = styled.span`
  font-weight: 600;
`;

export const Label = styled.span`
  font-weight: 500;
  text-transform: uppercase;
`;

export const SelectedItem = styled.div`
  position: relative;
  display: flex;
  height: 40px;
  width: 95%;
  color: rgb(51,71,91);
  padding: 10px 10px 10px 20px;
  margin: 10px 0;
  font-size: 14px;
  background-color: rgb(234,240,246);
  border: 1px solid rgb(203,214,226);
  border-radius: 3px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  &:before {
    content: '';
    width: 5px;
    height: 18px;
    position: absolute;
    left: 7px;
    top: calc((100% - 18px) / 2);
    border-left: 2px dotted rgba(0,0,0,0.65);
    border-right: 2px dotted rgba(0,0,0,0.65);
    box-sizing: border-box;
  }

  &:first-child {
    &:before { content: none }
  }
`;
