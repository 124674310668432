import React from 'react';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
} from 'antd';

import { Button } from 'components/common';

import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { userWorkspacesSelector, workspaceIdSelector } from 'data/selectors/user';
import { deleteWorkspace, switchWorkspace } from 'data/actions/user';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from 'components/modals/baseModal/styles';

const DeleteWorkspaceModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const modalOptions = useSelector(modalOptionsSelector);
  const userWorkspaces = useSelector(userWorkspacesSelector);
  const currentUserworkspaceId = useSelector(workspaceIdSelector);

  const workspaceId = get(modalOptions, 'workspaceId', '');
  const currentWorkspace = userWorkspaces.find((userWorkspace) => userWorkspace.workspaceId === workspaceId);

  const onSuccess = () => {
    const userWorkspace = userWorkspaces.find((item) => item.isDefault);

    if (userWorkspace && currentUserworkspaceId !== userWorkspace.workspaceId) {
      dispatch(switchWorkspace({ workspaceId: userWorkspace.workspaceId }));
    }
  };

  const handleDeleteWorkspace = () => {
    dispatch(toggleModal('delete_workspace', false));
    dispatch(deleteWorkspace(workspaceId, onSuccess));
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Delete workspace
          {' '}
          {get(currentWorkspace, 'name', '')}
          <CloseIcon
            onClick={() => dispatch(toggleModal('delete_workspace', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={() => dispatch(toggleModal('delete_workspace', false))}
    >
      <div>
        Are you sure you want to delete
        {' '}
        {get(currentWorkspace, 'name', '')}
        ?
        &nbsp;
      </div>
      <Footer>
        <Button
          type="danger"
          onClick={handleDeleteWorkspace}
        >
          Delete
        </Button>
        <Button type="default" onClick={() => dispatch(toggleModal('delete_workspace', false))}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default DeleteWorkspaceModal;
