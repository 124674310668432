import { Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Button } from 'components/common';
import { deleteUser } from 'data/actions/user';
import { toggleModal } from 'data/actions/modals';
import { REASON_TYPE } from 'data/types/user.types';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { createQuitReport } from 'data/actions/common';
import { analyticsAndDeleteUserLoaderSelector } from 'data/selectors/loading';
import useDeleteAccountMessage from 'hooks/messageTemplates/useDeleteAccountMessage';

import {
  AskText,
  SorryText,
  BlueLabel,
  ReasonRadio,
  ReasonsRadioWrapper,
} from '../cancelSubscriptionModal/styles';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../baseModal/styles';

const NewDeleteAccountModal = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { visible } = useSelector(modalsSelector);
  const { deleteAccount } = useSelector(modalOptionsSelector);
  const loading = useSelector(analyticsAndDeleteUserLoaderSelector);
  const [isHiddenContent, setIsHiddenContent] = useState(false);

  const deleteAccountMessage = useDeleteAccountMessage();

  const [reason, setReason] = useState();

  // Should be changed in future
  useEffect(() => {
    if (location?.pathname === '/login') {
      setIsHiddenContent(true);
    }
  }, [location]);

  const reasonOptions = [
    { label: "I don't understand how the app works", value: REASON_TYPE.HARD_APP },
    { label: "I don't want you to keep my data", value: REASON_TYPE.BAD_KEEPING_DATA },
    { label: 'I didn’t find the right contacts', value: REASON_TYPE.NOT_FOUND_CONTACTS },
    { label: 'I switched to another tool', value: REASON_TYPE.ANOTHER_TOOL },
    { label: 'Other', value: REASON_TYPE.OTHER },
  ];

  const handleClose = () => {
    dispatch(toggleModal('new_delete_account', false));
  };

  const handleDeleteAccount = () => {
    dispatch(deleteUser(() => {
      handleClose();
      deleteAccountMessage();
    }));
  };

  const openIntercom = () => {
    if (window.Intercom) {
      window.Intercom('showNewMessage');
    }
  };

  const openManagerDeleteAccountModal = () => {
    if ([REASON_TYPE.BAD_KEEPING_DATA].includes(reason)) {
      dispatch(createQuitReport({
        type: reason,
        action: 'deleteAccount',
        details: {
          deleteAccount,
        },
        onSuccess: handleDeleteAccount,
      }));
    } else {
      dispatch(toggleModal('manager_delete_account', true, { reason, deleteAccount }));
    }
  };

  if (isHiddenContent) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Is this goodbye?
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        <SorryText>We&lsquo;re so sorry to see you go :(</SorryText>
        <AskText>Please tell us why you decided to delete your account. Learning about your experience is essential for us to improve the product.</AskText>
        <ReasonsRadioWrapper value={reason} onChange={(e) => setReason(e.target?.value)}>
          {
            reasonOptions.map((reasonItem) => (
              <ReasonRadio key={reasonItem.value} value={reasonItem.value}>
                {reasonItem.label}
              </ReasonRadio>
            ))
          }
        </ReasonsRadioWrapper>
        <AskText>
          If you have any inquiries,
          {' '}
          <BlueLabel onClick={openIntercom}>
            please contact us via chat.
          </BlueLabel>
        </AskText>
        <Footer>
          <Button
            data-form="true"
            type="danger"
            loading={loading}
            disabled={!reason}
            onClick={openManagerDeleteAccountModal}
          >
            Delete my account
          </Button>
          <Button type="normal" onClick={handleClose}>Back</Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default NewDeleteAccountModal;
