import { COLORS } from 'constants/styles';
import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  padding: 15px;
  border: 1px solid ${(props) => (props.selected ? COLORS.PRIMARY : COLORS.GREY_G4)};
  border-radius: 4px;
  background-color: ${(props) => (props.selected ? COLORS.PRIMARY_P4 : COLORS.WHITE)};
`;

export const Hint = styled.span`
  color: ${COLORS.GREY_G1};
  font-weight: 500;
`;
