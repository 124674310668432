import debounce from 'lodash/debounce';

export default () => {
  const debounceMap = new Map();
  const debounceCreator = (time) => debounce((key, next, action) => {
    debounceMap.delete(key);
    next(action);
  }, time);

  return () => (next) => (action) => {
    if (!action) return;
    const { debounce: meta } = action;
    if (meta) {
      const key = meta.key ?? action.type;
      if (debounceMap.has(key)) {
        debounceMap.get(key)(key, next, action);
      } else {
        const debounceCallback = debounceCreator(meta.time);
        debounceMap.set(key, debounceCallback);
        debounceCallback(key, next, action);
      }
    } else {
      next(action);
    }
  };
};
