import React from 'react';

import useTimer from 'hooks/useTimer';
import { Button } from 'components/common';

const TimerButton = ({
  type,
  text,
  onClick,
  onClickReset,
  size = 'large',
  timeReload,
  style,
  disabled,
  startZero,
}) => {
  const [time, resetTime] = useTimer({ timeReload, startZero });

  const onClickProxy = () => {
    if (onClickReset) {
      onClickReset(resetTime);
    } else {
      resetTime();
      if (onClick) {
        onClick();
      }
    }
  };

  return (
    <Button size={size} type={type} onClick={onClickProxy} style={style} disabled={time !== 0 || disabled}>
      {text}
      &nbsp;
      {
        time !== 0 ? (
          <>
            (
            {time}
            )
          </>
        ) : null
      }
    </Button>
  );
};

export default TimerButton;
