import { Liquid } from 'liquidjs';
import isEmpty from 'lodash/isEmpty';
import { getName } from 'country-list';

import { getFullName } from './getFullName';

class LiquidProxy extends Liquid {
  // eslint-disable-next-line
  extendVariables(variables) {
    const extendedVariables = variables;
    if (!isEmpty(extendedVariables)) {
      if (!extendedVariables.fullName) {
        extendedVariables.fullName = getFullName(variables);
      }
      if (!extendedVariables.country && variables.countryCode) {
        extendedVariables.country = getName(variables.countryCode);
      }
      if (Array.isArray(extendedVariables.companyLinkedin) && extendedVariables.companyLinkedin.length) {
        const commonLinkedin = extendedVariables.companyLinkedin.find((linkedin) => linkedin.type === 'common');
        let linkedin = commonLinkedin;
        if (!commonLinkedin) {
          [linkedin] = extendedVariables.companyLinkedin;
        }
        extendedVariables.companyLinkedin = `https://www.linkedin.com/company/${linkedin.id}`;
      }
    }
    return extendedVariables;
  }

  // eslint-disable-next-line
  getVariables(content) {
    const contentVariables = [...content.matchAll(/{{.*?}}/g)].map(([variable]) => variable.replace(/[{}]/g, '').trim());
    return [...new Set(contentVariables)];
  }

  // eslint-disable-next-line
  getExtendedVariablesList() {
    return ['fullName', 'country'];
  }

  parseAndRender(content, variables) {
    const extendedVariables = this.extendVariables(variables);
    return super.parseAndRender(content, extendedVariables);
  }

  parseAndRenderSync(content, variables) {
    const extendedVariables = this.extendVariables(variables);
    return super.parseAndRenderSync(content, extendedVariables);
  }
}

const engineProxy = new LiquidProxy();

export default engineProxy;
