import { useDispatch, useSelector } from 'react-redux';
import React, {
  useCallback, useRef, useState, useEffect,
} from 'react';
import { Drawer } from 'antd';
import { nanoid } from 'nanoid';
import cloneDeep from 'lodash/cloneDeep';
import { getValueOfInput } from 'helpers/dom';

import {
  Button,
  Icon,
  Input,
  PhoneInput,
} from 'components/common';
import AddFilter from 'components/addFilter';
import * as CONSTANTS from 'constants/styles';
import { toggleModal } from 'data/actions/modals';
import { modalsSelector } from 'data/selectors/modals';
import { contactSelector } from 'data/selectors/contacts';
import { contactPreviewBarButton } from 'data/selectors/loading';
import {
  deleteCompanyRelationEmail,
  updateContactCompaniesRelation,
  updateContact,
} from 'data/actions/contacts';
import { Label, EditWrapper, ValuesContainer } from 'components/entityInfo/common/styles';
import {
  Footer,
  ButtonStyle,
  BodyStyle,
} from '../../createContactBar/styles';
import { ModalTitle, CloseIcon } from '../../modals/baseModal/styles';
import { HeaderStyle } from '../styles';

const EditContactDetailsPreviewBar = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const contact = useSelector(contactSelector);
  const loading = useSelector(contactPreviewBarButton);

  const valuesRef = useRef();
  const phoneNumbersValuesRef = useRef();

  const initRelations = useCallback(() => cloneDeep(contact.companyRelations.filter((relation) => relation?.email?.value)), [contact.companyRelations]);
  const resetPhoneNumbers = useCallback(() => cloneDeep((contact?.properties?.phone?.value || []).map((item) => ({ phone: item, _id: nanoid() }))), [contact.properties]);

  const [companyRelations, setCompanyRelations] = useState(() => initRelations());
  const [phoneNumbers, setPhoneNumbers] = useState(() => resetPhoneNumbers());

  const closePreviewBar = () => dispatch(toggleModal('edit_contact_details_preview_bar', false));

  const updatePhones = () => {
    dispatch(updateContact({
      contactId: contact._id,
      status: 'updated',
      properties: [
        {
          property: 'phone',
          value: phoneNumbers.filter((item) => item.phone).map((item) => `+${+item.phone}`),
        },
      ],
    }));
  };

  const submit = () => {
    dispatch(updateContactCompaniesRelation({
      contactId: contact._id,
      data: companyRelations.map((relation) => {
        if (relation.new) {
          delete relation._id;
          delete relation.new;
        }
        return relation;
      }).filter((item) => item.email?.value || item.company),
      onSuccess: () => {
        closePreviewBar();
        const deletedEmails = contact.companyRelations.filter((relation) => !relation.new && relation?.email?.value && companyRelations.every((item) => item._id !== relation._id));
        if (deletedEmails.length) {
          deletedEmails.forEach(({ _id: relationId }) => {
            dispatch(deleteCompanyRelationEmail({
              contactId: contact._id,
              relationId,
              onSuccess: closePreviewBar,
            }));
          });
        }
      },
    }));
    updatePhones();
  };

  useEffect(() => {
    if (!visible) {
      setPhoneNumbers(resetPhoneNumbers());
    }
  }, [visible, resetPhoneNumbers]);

  return (
    <Drawer
      title={(
        <ModalTitle>
          Edit details
          <CloseIcon
            data-close="true"
            onClick={closePreviewBar}
            className="ic_close"
          />
        </ModalTitle>
      )}
      headerStyle={HeaderStyle}
      bodyStyle={BodyStyle}
      placement="right"
      onClose={closePreviewBar}
      visible={visible}
      width={CONSTANTS.DRAWER_WIDTH}
      closable={false}
      zIndex={CONSTANTS.Z_INDEXES.MOBILE_MODAL}
    >
      <EditWrapper style={{ padding: 0, width: '100%' }}>
        <ValuesContainer ref={valuesRef}>
          {
            companyRelations.map((relation, index) => (
              <div key={`edit_details_${relation._id}`}>
                <Label>
                  {`Email ${index > 0 ? index + 1 : ''}`}
                </Label>
                <Input
                  onChange={getValueOfInput((value) => {
                    setCompanyRelations((prev) => prev.map((item) => {
                      if (item._id === relation._id) {
                        return {
                          ...item,
                          email: {
                            ...(item.email || {}),
                            value,
                          },
                        };
                      }
                      return item;
                    }));
                  })}
                  value={relation.email.value}
                  size="default"
                  suffix={(
                    <Icon
                      onClick={() => {
                        setCompanyRelations((prev) => prev.filter((item) => (item._id !== relation._id)));
                      }}
                      size="small"
                      type="ic-delete"
                    />
                  )}
                />
              </div>
            ))
          }
        </ValuesContainer>
        <AddFilter
          onClick={() => {
            setCompanyRelations((prev) => [
              ...prev,
              {
                _id: nanoid(),
                new: true,
                email: {
                  status: '',
                  value: '',
                },
              },
            ]);
            setImmediate(() => {
              valuesRef.current.scrollTo(0, valuesRef.current.scrollHeight);
            });
          }}
          label="Add email"
        />
        <ValuesContainer ref={phoneNumbersValuesRef}>
          {
            phoneNumbers.map((data, index) => (
              <div style={{ padding: '0 2px', paddingBottom: index === phoneNumbers.length - 1 ? 2 : 0 }} key={`edit_details_${data._id}`}>
                <Label>
                  {`Phone ${index > 0 ? index + 1 : ''}`}
                </Label>
                <PhoneInput
                  onChange={(value, _data) => {
                    setPhoneNumbers((prev) => prev.map((item) => {
                      if (item._id === data._id) {
                        return {
                          ...item,
                          phone: value,
                          country: _data.countryCode,
                        };
                      }
                      return item;
                    }));
                  }}
                  isValid={false}
                  country={data.country}
                  value={data.phone}
                  renderSuffixIfHovered
                  renderSuffixIfFocused
                  suffix={() => (
                    <Icon
                      className="phone-suffix"
                      onClick={() => {
                        setPhoneNumbers((prev) => prev.filter((item, itemIndex) => (itemIndex !== index)));
                      }}
                      size="small"
                      type="ic-delete"
                    />
                  )}
                />
              </div>
            ))
          }
        </ValuesContainer>
        <AddFilter
          onClick={() => {
            setPhoneNumbers((prev) => [
              ...prev,
              {
                _id: nanoid(),
                new: true,
                phone: '',
                country: 'us',
              },
            ]);
          }}
          label="Add phone"
        />
      </EditWrapper>
      <Footer>
        <Button
          data-form="true"
          type="primary"
          size="large"
          style={ButtonStyle}
          loading={loading}
          onClick={submit}
        >
          Save
        </Button>
        <Button
          type="normal"
          size="large"
          onClick={closePreviewBar}
        >
          Cancel
        </Button>
      </Footer>
    </Drawer>
  );
};

export default EditContactDetailsPreviewBar;
