import get from 'lodash/get';
import jwt from 'jsonwebtoken';
import { createSelector } from 'reselect';
import moment from 'moment';

import { CREDITS_TYPE } from 'data/types/user.types';
import { ASYNC_BOOLEAN } from 'data/types/progress.types';
import { SUBSCRIPTION_STATUS } from 'data/types/subscription.types';
import { dashboardSequencesSelector } from './sequences';
import { hadSuccessfullInvoiceSelector } from './invoices';
import { isPaidSubscriptionSelector, subscriptionsSelector } from './subscriptions';

export const baseUserSelector = (state) => get(state, 'user', {});

export const baseFilterConfigurationSelector = (state) => get(state, 'user.settings.columnsConfiguration', {});
export const columnsConfigurationSelector = createSelector(
  baseFilterConfigurationSelector,
  (selectedColumns) => selectedColumns,
);

export const baseExportConfigurationSelector = (state) => get(state, 'user.settings.exportColumnsConfiguration', {});
export const exportColumnsConfigurationSelector = createSelector(
  baseExportConfigurationSelector,
  (selectedColumns) => selectedColumns,
);

export const baseCompaniesFilterConfigurationSelector = (state) => get(state, 'user.settings.companiesFilterConfiguration', []);
export const companiesFilterConfigurationSelector = createSelector(
  baseCompaniesFilterConfigurationSelector,
  (selectedColumns) => selectedColumns,
);
export const baseContactsFavoriteFilters = (state) => get(state, 'user.settings.contactsFavoriteFilters', []);
export const contactsFavoriteFiltersSelector = createSelector(
  baseContactsFavoriteFilters,
  (favoriteFilters) => favoriteFilters,
);

export const baseCompaniesFavoriteFilters = (state) => get(state, 'user.settings.companiesFavoriteFilters', []);
export const companiesFavoriteFiltersSelector = createSelector(
  baseCompaniesFavoriteFilters,
  (favoriteFilters) => favoriteFilters,
);

export const baseAuthSelector = (state) => get(state, 'user.auth', {});
export const authTokenSelector = createSelector(
  baseAuthSelector,
  (auth) => auth.token,
);

export const isUserAdminSelector = createSelector(
  baseUserSelector,
  (user) => user.isAdmin,
);

export const isAuthSelector = createSelector(
  baseAuthSelector,
  (auth) => !!auth.token,
);

export const baseAccountSelector = (state) => get(state, 'user.account', {});
export const accountSelector = createSelector(
  baseAccountSelector,
  (account) => account,
);

export const gpGroupSelector = createSelector(
  baseAuthSelector,
  (auth) => get(jwt.decode(auth.token), 'gp_group'),
);

export const userIdSelector = createSelector(
  baseAuthSelector,
  (auth) => get(jwt.decode(auth.token), '_id'),
);

export const baseNotificationConfiguration = (state) => get(state, 'user.settings.notificationConfiguration', {});
export const notificationConfigurationSelector = createSelector(
  baseNotificationConfiguration,
  (notificationConfiguration) => notificationConfiguration,
);

export const baseEmailNotificationConfiguration = (state) => get(state, 'user.settings.emailNotificationConfiguration', {});
export const emailNotificationConfigurationSelector = createSelector(
  baseEmailNotificationConfiguration,
  (emailNotificationConfiguration) => emailNotificationConfiguration,
);

export const baseBillingConfiguration = (state) => get(state, 'user.settings.billingConfiguration', {});
export const billingConfigurationSelector = createSelector(
  baseBillingConfiguration,
  (billingConfiguration) => billingConfiguration,
);

export const userSessionsSelector = (state) => get(state, 'user.sessions', []);

export const userAffiliateReferred = (state) => get(state, 'user.affiliateReferred', []);

export const baseAvatarSelector = (state) => get(state, 'user.avatar', {});
export const userAvatarSelector = createSelector(
  baseAvatarSelector,
  (avatar) => avatar,
);

export const userInfoSelect = (state) => get(state, 'user.info', {});
export const userInfoSelector = createSelector(
  userInfoSelect,
  (info) => info,
);

const baseUserSettingsSelector = (state) => get(state, 'user.settings', {});
export const userSettingsSelector = createSelector(
  baseUserSettingsSelector,
  (settings) => settings,
);

export const userContactPreviewSelector = createSelector(
  baseUserSettingsSelector,
  (settings) => get(settings, 'previewConfiguration.contacts', []),
);

export const userCompanyPreviewSelector = createSelector(
  baseUserSettingsSelector,
  (settings) => get(settings, 'previewConfiguration.companies', []),
);

export const userCreditsSelector = createSelector(
  baseUserSelector,
  (user) => (user.credits || []).find((item) => item.type === CREDITS_TYPE.EMAIL_SEARCH) || { current: 0, total: 0 },
);

export const userCreditsVerificationSelector = createSelector(
  baseUserSelector,
  (user) => (user.credits || []).find((item) => item.type === CREDITS_TYPE.EMAIL_VERIFICATION) || { current: 0, total: 0 },
);

export const userCreditsPhoneSearchSelector = createSelector(
  baseUserSelector,
  (user) => (user.credits || []).find((item) => item.type === CREDITS_TYPE.PHONE_SEARCH) || { current: 0, total: 0 },
);

export const userHistorySelector = createSelector(
  baseUserSelector,
  (user) => user.creditsHistory,
);

export const showCreditsBarSelector = createSelector(
  baseUserSelector,
  (user) => {
    const isClosedCreditsBar = !!sessionStorage.getItem('closedCreditsBar');

    return !isClosedCreditsBar ? user.showCreditsBar : false;
  },
);

export const showImportLimitSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'limits.isImportLimit', false),
);

export const showCancelSubscriptionLimitSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'limits.isCancelSubscriptionLimit', false),
);

export const showDeleteAccountLimitSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'limits.isDeleteAccountLimit', false),
);

export const isDeleteAccountLimitSelector = createSelector(
  showCancelSubscriptionLimitSelector,
  showDeleteAccountLimitSelector,
  (cancelSubscriptionLimit, deleteAccountLimit) => cancelSubscriptionLimit || deleteAccountLimit,
);

export const hasCancelSubscriptionReportSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'limits.hasCancelSubscriptionReport', false),
);

export const showInvalidEmailBarSelector = createSelector(
  baseAccountSelector,
  (account) => account.emailStatus === 'invalid',
);

export const showLimitedPlanBarSelector = createSelector(
  baseAuthSelector,
  (auth) => get(jwt.decode(auth.token), 'hasLimitedPlan'),
);

export const userEmailSelector = createSelector(
  baseAuthSelector,
  (auth) => get(jwt.decode(auth.token), 'email'),
);

export const showBarSelector = createSelector(
  showCreditsBarSelector,
  showInvalidEmailBarSelector,
  showImportLimitSelector,
  showLimitedPlanBarSelector,
  (showCreditsBar, showInvalidEmailBar, showImportLimitBar, showLimitedPlanBar) => showCreditsBar || showInvalidEmailBar || showImportLimitBar || showLimitedPlanBar,
);

export const questionsSelector = createSelector(
  baseUserSelector,
  (user) => user.questions,
);

export const creditsAnalyticsSelector = createSelector(
  baseUserSelector,
  (user) => user.creditsAnalytics,
);

export const collapseGroupSelector = (key) => createSelector(
  baseUserSelector,
  (user) => user?.collapseState?.group === key,
);

export const collapseStepSelector = (key) => createSelector(
  baseUserSelector,
  (user) => user?.collapseState?.step === key,
);
export const userWorkspacesSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'workspaces', []),
);
export const getWorkspaceAnalytics = createSelector(
  baseUserSelector,
  (user) => get(user, 'workSpaceAnalytics', []),
);

export const billingSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'workspaceBilling', {}),
);

export const workspaceIdSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'currentWorkspace', false),
);

export const workspaceGlobalSettingsSelector = createSelector(
  userWorkspacesSelector,
  workspaceIdSelector,
  (workspaces, workspaceId) => workspaces.find((item) => item.workspaceId === workspaceId),
);

export const usersWorkspacesSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'usersWorkspaces', []),
);

export const userHaveOAuthCodeSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'userHaveOAuthCode', false),
);

export const activitiesSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'activities', {}),
);

export const pluginInstalledSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'plugin.installed') === true,
);

export const googleSheetsPluginInstalledSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'googleSheetsPlugin.installed'),
);

export const apiKeysSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'apiKeys', []),
);

export const demoUserSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'isDemoUser', false),
);

export const demoServiceErrorSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'demoServiceError'),
);

export const demoUserIpSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'demoUserIp'),
);

export const showLastStepSelector = createSelector(
  baseUserSelector,
  (user) => user.showLastStep,
);

export const skipLeadsTourSelector = createSelector(
  baseUserSelector,
  (user) => user?.additionalData?.skipLeadsTour,
);

export const deleteAccountSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'deleteAccount', {}),
);

export const canDeleteAccountAutomaticallySelector = createSelector(
  hadSuccessfullInvoiceSelector,
  isPaidSubscriptionSelector,
  subscriptionsSelector,
  hasCancelSubscriptionReportSelector,
  (hadSuccessfullInvoice, isPaidSubscription, subscription, hasCancelSubscriptionReport) => {
    if (isPaidSubscription) {
      return ([SUBSCRIPTION_STATUS.DISABLED, SUBSCRIPTION_STATUS.PAUSE].includes(subscription.status) && hasCancelSubscriptionReport) || hasCancelSubscriptionReport;
    }
    return hadSuccessfullInvoice && hasCancelSubscriptionReport;
  },
);

const baseUserExtensionSelector = (state) => get(state, 'user.extension', {});
export const userExtensionSelector = createSelector(
  baseUserExtensionSelector,
  (extension) => extension,
);

export const hasSuggestionsSelector = createSelector(
  pluginInstalledSelector,
  googleSheetsPluginInstalledSelector,
  dashboardSequencesSelector,
  (
    pluginInstalled,
    googleSheetsPluginInstalled,
    // sequences,
  ) => [ASYNC_BOOLEAN.ERROR, ASYNC_BOOLEAN.FALSE].includes(pluginInstalled)
    || !googleSheetsPluginInstalled,
  // || (sequences.totalItems === 0),
);

export const recentActivitySelector = createSelector(
  baseUserSelector,
  (user) => user.recentActivity,
);

export const nspScoreFeedbackSelector = createSelector(
  baseUserSelector,
  (user) => user.nspScoreFeedback,
);

export const extensionAuthActiveSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'plugin.auth.active', false),
);

export const extensionAuthOptionsSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'plugin.auth.options', {}),
);

export const isBlackFridayPricingSelector = createSelector(
  () => {
    const startDate = moment('2023-11-22:00:00:00');
    const endDate = moment('2023-11-28:23:59:59');

    if (moment().isBetween(startDate, endDate)) return true;
    return false;
  },
);
