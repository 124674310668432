import get from 'lodash/get';

import { ACCOUNT_EVENTS } from 'data/types/event.types';
import { ACCOUNT_STATUS } from 'data/types/user.types';
import { PageSizes } from '../types/tableMeta.types';

const initialState = {
  integrations: {
    data: [],
    totalItems: 0,
    totalPages: 0,
    page: 1,
    pageSize: PageSizes.DEFAULT_INTEGRATIONS_PAGE_SIZE,
  },
  integrationsCategories: [],
  connectedAccounts: [],
  hubspot: {
    properties: {
      contact: [],
      company: [],
    },
    connection: {},
    currentTask: {},
  },
  salesforce: {
    properties: {
      contact: [],
      company: [],
    },
    connection: {},
    currentTask: {},
  },
  zoho: {
    properties: {
      contact: [],
      company: [],
    },
    connection: {},
    currentTask: {},
  },
  pipedrive: {
    properties: {
      contact: [],
      company: [],
    },
    connection: {},
    currentTask: {},
  },
  sheets: {
    properties: {
      contact: [],
      company: [],
    },
    connection: {},
    currentTask: {},
    files: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_INTEGRATIONS': {
      const page = get(action, 'payload.meta.page', 1);
      const data = get(action, 'payload.data', []);
      return {
        ...state,
        integrations: {
          ...state.integrations,
          page,
          data: page > 1 ? [...state.integrations.data, ...data] : data,
          totalItems: get(action, 'payload.meta.totalItems', 0),
          pageSize: get(action, 'payload.meta.pageSize', PageSizes.DEFAULT_INTEGRATIONS_PAGE_SIZE),
          totalPages: get(action, 'payload.meta.totalPages', 0),
        },
      };
    }

    case 'GET_ALL_INTEGRATIONS_CATEGORIES': {
      return {
        ...state,
        integrationsCategories: get(action, 'payload', []),
      };
    }

    case 'GET_CONNECTED_ACCOUNTS': {
      return {
        ...state,
        connectedAccounts: get(action, 'payload', []),
      };
    }

    case 'GET_COUNT_ACTIVE_CAMPAIGNS': {
      if (!Array.isArray(action.payload)) return state;
      return {
        ...state,
        connectedAccounts: state.connectedAccounts.map((account) => {
          const countCampaigns = action.payload.find((item) => item.accountId === account._id)?.countCampaigns || 0;
          return {
            ...account,
            countCampaigns,
          };
        }),
      };
    }

    case 'GET_SUM_SENDING_LIMITS': {
      if (!Array.isArray(action.payload)) return state;
      return {
        ...state,
        connectedAccounts: state.connectedAccounts.map((account) => {
          const sumSendingLimit = action.payload.find((item) => item.accountId === account._id)?.sumSendingLimit || 0;
          return {
            ...account,
            sumSendingLimit,
          };
        }),
      };
    }

    case 'PUSH_CONNECTED_ACCOUNT': {
      const account = get(action, 'data');
      if (!account) return state;
      const hasConnectedAccount = state.connectedAccounts.some((item) => item?._id === account?._id);
      const connectedAccounts = hasConnectedAccount ? state.connectedAccounts.map((item) => {
        if (item?._id === account?._id) {
          return {
            ...account,
            sumSendingLimit: item.sumSendingLimit,
            countCampaigns: item.countCampaigns,
          };
        }
        return item;
      }) : [...state.connectedAccounts, account];
      return {
        ...state,
        connectedAccounts,
      };
    }

    case 'CONNECT_CUSTOM_ACCOUNT': {
      const account = get(action, 'payload');
      const hasConnectedAccount = state.connectedAccounts.some((item) => item?._id === account?._id);
      const connectedAccounts = hasConnectedAccount ? state.connectedAccounts.map((item) => (item?._id === account?._id ? account : item)) : [...state.connectedAccounts, account];
      return {
        ...state,
        connectedAccounts,
      };
    }

    case 'DELETE_CONNECTED_ACCOUNT': {
      const accountId = get(action, 'payload.deleted');
      if (!accountId) return state;
      const connectedAccounts = state.connectedAccounts.filter((item) => item?._id !== accountId);
      return {
        ...state,
        connectedAccounts,
      };
    }

    case 'UPDATE_CONNECTED_ACCOUNT': {
      const { accountId, body } = action;
      return {
        ...state,
        connectedAccounts: state.connectedAccounts.map((account) => {
          if (account._id === accountId) {
            return {
              ...account,
              ...body,
              ...action.payload,
              countCampaigns: account.countCampaigns,
            };
          }
          return account;
        }),
      };
    }

    case 'REFRESH_CONNECTED_ACCOUNT': {
      const { accountId } = action;
      return {
        ...state,
        connectedAccounts: state.connectedAccounts.map((account) => {
          if (account._id === accountId) {
            return {
              ...account,
              ...action.payload,
            };
          }
          return account;
        }),
      };
    }

    case 'DISABLE_CONNECTED_ACCOUNT': {
      const { accountId, disabled } = action;
      return {
        ...state,
        connectedAccounts: state.connectedAccounts.map((account) => {
          if (account._id === accountId) {
            return {
              ...account,
              status: disabled ? ACCOUNT_STATUS.INACTIVE : ACCOUNT_STATUS.ACTIVE,
              ...action.payload,
              sumSendingLimit: 0,
              countCampaigns: disabled === true ? 0 : account.countCampaigns,
            };
          }
          return account;
        }),
      };
    }
    case 'SYNC_HUBSPOT_USER':
    case 'UPDATE_HUBSPOT_CONNECTION':
    case 'GET_HUBSPOT_CONNECTION': {
      if (!action.payload) return state;
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          connection: action.payload,
        },
      };
    }
    case 'SYNC_SALESFORCE_USER':
    case 'UPDATE_SALESFORCE_CONNECTION':
    case 'GET_SALESFORCE_CONNECTION': {
      if (!action.payload) return state;
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          connection: action.payload,
        },
      };
    }
    case 'SYNC_ZOHO_USER':
    case 'UPDATE_ZOHO_CONNECTION':
    case 'GET_ZOHO_CONNECTION': {
      if (!action.payload) return state;
      return {
        ...state,
        zoho: {
          ...state.zoho,
          connection: action.payload,
        },
      };
    }
    case 'UNLINK_INTEGRATION': {
      return {
        ...state,
        [action.integration.name]: initialState[action.integration.name],
      };
    }
    case ACCOUNT_EVENTS.UPDATE_INTEGRATION_CONNECTION_EVENT: {
      if (!action.payload) return state;
      const { connection, name } = JSON.parse(action.payload);
      return {
        ...state,
        [name]: {
          ...state[name],
          connection,
        },
      };
    }

    case 'UPDATE_INTEGRATION_STATUS': {
      if (!action.payload) return state;

      const integration = {
        ...state[action.integration.name],
        connection: {
          ...state[action.integration.name].connection,
          status: action.payload,
        },
      };
      return {
        ...state,
        [action.integration.name]: integration,
      };
    }
    case 'SYNC_INTEGRATION':
    case 'GET_INTEGRATION_TASK': {
      if (!action.payload) return state;
      const task = action.payload;

      const integration = {
        ...state[action.integration.name],
        currentTask: task,
      };

      return {
        ...state,
        [action.integration.name]: integration,
      };
    }
    case 'SYNC_PIPEDRIVE_USER':
    case 'UPDATE_PIPEDRIVE_CONNECTION':
    case 'GET_PIPEDRIVE_CONNECTION': {
      if (!action.payload) return state;
      return {
        ...state,
        pipedrive: {
          ...state.pipedrive,
          connection: action.payload,
        },
      };
    }
    case 'SYNC_SHEETS_DATA':
    case 'UPDATE_SHEETS_CONNECTION':
    case 'GET_SHEETS_CONNECTION': {
      if (!action.payload) return state;
      return {
        ...state,
        sheets: {
          ...state.sheets,
          connection: action.payload,
        },
      };
    }
    case 'SEARCH_SHEETS': {
      if (!action.payload) return state;
      return {
        ...state,
        sheets: {
          ...state.sheets,
          files: action.payload,
        },
      };
    }
    case 'SWITCH_WORKSPACE': {
      return initialState;
    }
    default:
      return state;
  }
};
