export const stopPropagation = (callback) => (e, ...args) => {
  if (e?.stopPropagation) e.stopPropagation();
  if (callback) callback(...args);
};

export const preventDefault = (callback) => (e, ...args) => {
  if (e?.preventDefault) e.preventDefault();
  if (callback) callback(...args);
};

export const onPressEnter = (callback) => (e) => {
  if (e.key === 'Enter') callback();
};

export const getValueOfInput = (callback, property = 'value') => (e) => {
  if (e.target) callback(e.target[property]);
};
