import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useSubscribeActions from './useSubscribeActions';

let cache = [];

const useFetch = ({
  selector = () => undefined,
  action,
  cached = false,
  loadingSelector = () => false,
}) => {
  const dispatch = useDispatch();
  const data = useSelector(selector);
  const loading = useSelector(loadingSelector);

  const refresh = useCallback((...dynamicParams) => {
    if (dynamicParams.length > 0) {
      dispatch(action(...dynamicParams));
      return;
    }
    dispatch(action());
  }, [dispatch, action]);

  useEffect(() => {
    const hashedKey = action.name;
    if (!cached || !cache.includes(hashedKey)) {
      dispatch(action());
      if (cached) {
        cache = cache.filter((item) => !item.startsWith(action.name));
        cache.push(hashedKey);
      }
    }
  }, [dispatch, cached, action]);

  useSubscribeActions({
    actions: ['SIGN_OUT', 'SWITCH_WORKSPACE'],
    callback: () => {
      cache = [];
    },
  });

  return { data, loading, refresh };
};

export default useFetch;
