export function clearStep(step, state, initialState) {
  const updatedState = { ...state };

  switch (step) {
    case 0: {
      updatedState.settings = {
        ...updatedState.settings,
        type: initialState.settings.type,
      };
      break;
    }
    case 1: {
      updatedState.settings = {
        ...updatedState.settings,
        source: initialState.settings.source,
      };
      break;
    }
    case 2: {
      updatedState.settings = {
        ...updatedState.settings,
        name: initialState.settings.name,
      };
      updatedState.preprocess = initialState.preprocess;
      break;
    }
    case 3: {
      updatedState.settings = {
        ...updatedState.settings,
        unmatchedImport: initialState.settings.unmatchedImport,
      };
      updatedState.matchedProperties = initialState.matchedProperties;
      break;
    }
    case 4: {
      updatedState.settings = {
        ...updatedState.settings,
        fileName: initialState.settings.name,
      };
      break;
    }

    default: {
      return updatedState;
    }
  }

  return updatedState;
}
