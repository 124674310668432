import moment from 'moment';

export const FORMAT_FOR_TODAY = '[Today at] h:mm A';
export const FORMAT_FOR_TODAY_ONLY_TIME = 'h:mm A';

export const formats = Object.freeze({
  0: FORMAT_FOR_TODAY,
  1: '[Yesterday at] h:mm A',
  other: 'MMM DD, YYYY',
  withoutYear: 'MMM DD',
  tooltip: 'LLL',
});

export const dateFormat = (date = moment(), withTime = true, formatForToday = FORMAT_FOR_TODAY) => {
  const daysDuration = moment.duration(moment().startOf('day').diff(moment(date).startOf('day'))).asDays();
  let currentDateFormat = formats.other;

  if (daysDuration === 0) {
    if (formatForToday === FORMAT_FOR_TODAY) {
      currentDateFormat = formats[daysDuration];
    } else if (formatForToday === FORMAT_FOR_TODAY_ONLY_TIME) {
      currentDateFormat = FORMAT_FOR_TODAY_ONLY_TIME;
    }
  } else if (daysDuration === 1) currentDateFormat = formats[daysDuration];
  else if (moment(date).year() === moment().year()) currentDateFormat = formats.withoutYear;

  return moment(date).format(withTime ? currentDateFormat : currentDateFormat.replace('at] h:mm A', ']'));
};

export const addPrefixZero = (number) => {
  if (String(number).length === 1) {
    return `0${number}`;
  }
  return number;
};

export const fixDate = (date) => {
  if (!date) return date;
  const dateSplit = date instanceof Date ? date.toISOString() : date.split('-');
  if (dateSplit.length === 3) {
    return dateSplit.reduce((first, second) => (first ? `${first}-${addPrefixZero(second)}` : second), '');
  }
  return date;
};

export const removeTime = (date) => {
  if (!date) return date;
  if (Number.isInteger(date)) {
    return date;
  }
  if (date === 'now') {
    return 'now';
  }
  if (date.includes('T')) {
    return date.split('T')[0];
  }
  return date;
};
