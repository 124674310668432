import React from 'react';
import moment from 'moment-timezone';
import {
  Select as SelectA,
} from 'antd';

import Select from '../customSelect';

const { Option } = SelectA;

const TimezoneSelect = (props) => (
  // eslint-disable-next-line
  <Select {...props}>
    {
      moment.tz.names().map((timezone) => (
        <Option value={timezone} key={timezone}>
          {`(UTC ${moment.tz(timezone).format('Z')}) ${timezone}`}
        </Option>
      ))
    }
  </Select>
);

export default TimezoneSelect;
