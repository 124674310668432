import styled from 'styled-components';

import { Icon } from 'components/common';
import { COLORS } from 'constants/styles';

export const MenuContainer = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  background-color: ${COLORS.BLUE};
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${COLORS.WHITE};
  margin: -16px -24px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
`;

export const HeaderIcon = styled(Icon)``;
