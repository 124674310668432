import get from 'lodash/get';
import { nanoid } from 'nanoid';

// eslint-disable-next-line import/prefer-default-export
export const getAllNotifications = ({ page, countNotifications }) => (dispatch, getStore) => {
  const store = getStore();
  const pageSize = get(store, 'notifications.notifications.pageSize', 50);
  const pageNumber = get(store, 'notifications.notifications.page', 1);
  const params = {
    pageSize: countNotifications || pageSize,
    pageNumber: page || pageNumber,
  };

  dispatch({
    type: 'GET_ALL_NOTIFICATIONS',
    payload: {
      endpoint: 'api/v1/notifications',
      method: 'GET',
      params,
      delayLoad: 0,
    },
    components: ['notificationList'],
  });
};

export const sendNotification = ({
  type,
  content,
}) => (dispatch) => {
  const date = new Date().toISOString();

  dispatch({
    type: 'SEND_NOTIFICATION',
    data: {
      _id: nanoid(),
      type,
      read: false,
      content,
      fake: true,
      sendDate: date,
      updatedAt: date,
    },
  });
};

export const removePopUpDataAction = () => (dispatch) => {
  dispatch({
    type: 'REMOVE_POP_UP_DATA',
    data: 'remove data',
  });
};

export const updateNotifications = (fields, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  const exceptFakeFields = fields.filter((field) => !field.fake);

  dispatch({
    type: 'UPDATE_NOTIFICATIONS',
    payload: {
      endpoint: 'api/v1/notifications',
      method: 'PATCH',
      body: { fields: exceptFakeFields },
    },
    onSuccess: (data) => onSuccess(data),
    onFailed: (data) => onFailed(data),
  });
};
