import { Modal } from 'antd';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useMemo } from 'react';

import { Button, Checkbox, Search } from 'components/common';
import { exportColumnsConfigurationSelector } from 'data/selectors/user';
import { updateWorkspaceSettings } from 'data/actions/user';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { propertiesSelector } from 'data/selectors/properties';
import { SOURCE_TYPE } from 'data/types/source.types';
import { renameLabel } from 'helpers/renameLabel';

import SelectedColumns from './selectedColumns';

import {
  Container,
  Half,
  PropertyItem,
  PropertiesContainer,
  PropertyGroup,
  Footer,
  PropertiesWrapper,
  ModalWrapper,
  ParagraphText,
  CheckboxText,
  CreatePropertyLink,
} from '../../propertyListEditor/styles';

import {
  ModalTitle,
  CloseIcon,
} from '../baseModal/styles';

import {
  SearchPropertiesForm,
} from '../../addCompaniesToContactBar/styles';

const excludedFields = ['name', 'associated_company'];
const excludedContactCompanyField = [];
const systemProperties = [
  {
    name: 'getProspectId',
    label: 'GetProspect id',
    sourceType: 'contact',
  },
  {
    label: 'Owner',
    name: 'owner',
    sourceType: 'contact',
  },
  {
    label: 'Workspace',
    name: 'workspace',
    sourceType: 'contact',
  },
  {
    label: 'List',
    name: 'list',
    sourceType: 'contact',
  },
  {
    label: 'Country',
    name: 'country',
    sourceType: 'contact',
  },
];

const ExportConfigurationEditor = () => {
  const dispatch = useDispatch();

  const properties = useSelector(propertiesSelector);
  const currentColumns = useSelector(exportColumnsConfigurationSelector);

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const { visible } = useSelector(modalsSelector);
  const modalOptions = useSelector(modalOptionsSelector);
  const sourceType = get(modalOptions, 'sourceType', SOURCE_TYPE.CONTACT);
  const includeProperties = get(modalOptions, 'includeProperties', 'custom');
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [searchName, handleChange] = useState('');
  const configurationSourceType = sourceType === SOURCE_TYPE.CONTACT ? 'contacts' : 'companies';

  useEffect(() => {
    if (currentColumns && visible && currentColumns[configurationSourceType]) {
      setSelectedColumns(currentColumns[configurationSourceType]);
    }
  }, [currentColumns, visible, sourceType, configurationSourceType]);

  const getPropertyLabel = (propertyName, source) => {
    const property = [...systemProperties, ...properties].find((prop) => prop.name === propertyName && prop.sourceType === source);
    if (sourceType === SOURCE_TYPE.CONTACT && get(property, 'sourceType') === SOURCE_TYPE.COMPANY) {
      return `Company ${property.label ?? propertyName}`;
    }
    const label = get(property, 'label', startCase(propertyName));
    return label;
  };
  const getPropertyIsDefault = (propertyName) => get([...systemProperties, ...properties].find((prop) => prop.name === propertyName), 'default');

  useEffect(() => {
    const data = [...systemProperties, ...properties].filter((item) => getPropertyLabel(item?.name, item?.sourceType)
      .toLowerCase()
      .startsWith(searchName));
    if (sourceType === SOURCE_TYPE.CONTACT) {
      setDataSource(data.map((property) => {
        if (property.sourceType === SOURCE_TYPE.COMPANY) {
          return {
            ...property,
            label: property.label.startsWith('Company') ? property.label : `Company ${property.label}`,
          };
        }
        return property;
      }));
    } else {
      setDataSource(data.filter((item) => {
        let isNotExcluded = sourceType === item.sourceType && !excludedFields.includes(item.name);

        if (sourceType === SOURCE_TYPE.CONTACT && isNotExcluded && item.sourceType === SOURCE_TYPE.COMPANY) {
          isNotExcluded = !excludedContactCompanyField.includes(item.name);
        }
        return isNotExcluded;
      }));
    }
  }, [searchName, sourceType, properties]); // eslint-disable-line

  const getUpdatedRequest = () => {
    const exportColumnsConfiguration = {
      ...currentColumns,
      [configurationSourceType]: selectedColumns.map((item) => ({ propertyName: item.propertyName, sourceType: item.sourceType })),
    };
    return { exportColumnsConfiguration };
  };

  const updateColumns = (column, value) => {
    const name = column.propertyName ?? column.name;
    if (!value) return setSelectedColumns(selectedColumns.filter((item) => !(item.propertyName === name && item.sourceType === column.sourceType)));
    if (!selectedColumns.some((item) => item.propertyName === name && column.sourceType === item.sourceType)) {
      return setSelectedColumns([...selectedColumns, { propertyName: column.name, sourceType: column.sourceType }]);
    }
    return true;
  };

  const groupedProperties = useMemo(() => {
    const reducedData = dataSource.reduce((result, item) => {
      const itemSourceType = get(item, 'sourceType');
      const groupName = itemSourceType === SOURCE_TYPE.CONTACT ? 'Contact properties' : 'Company properties';
      const prev = result[groupName] || [];
      result[groupName] = [...prev, item];
      return result;
    }, { 'Contact properties': [], 'Company properties': [] });
    let indexCompanySize = reducedData['Company properties'].findIndex((prop) => prop.description === 'Company size');
    const indexCompanyLinkedIn = reducedData['Company properties'].findIndex((prop) => prop.description === 'Company linkedIn');

    if (indexCompanySize !== -1 && indexCompanyLinkedIn !== -1) {
      const companyLinkedInProp = reducedData['Company properties'].splice(indexCompanyLinkedIn, 1);
      indexCompanySize = reducedData['Company properties'].findIndex((prop) => prop.description === 'Company size');
      reducedData['Company properties'].splice(indexCompanySize, 1, reducedData['Company properties'][indexCompanySize], companyLinkedInProp[0]);
    }
    return reducedData;
  }, [dataSource]);

  const groups = Object.keys(groupedProperties);
  const groupsProperties = Object.values(groupedProperties);
  const groupsPropertiesFilterCondition = (item) => {
    if (sourceType === 'contact') {
      let isExcluded = excludedFields.includes(item.name);

      if (item.sourceType === SOURCE_TYPE.COMPANY && isExcluded) {
        isExcluded = excludedContactCompanyField.includes(item.name);
      }
      return !isExcluded;
    }
    return !excludedFields.includes(item.name) && sourceType === item.sourceType;
  };

  const handleSave = () => {
    setLoading(true);
    dispatch(updateWorkspaceSettings(getUpdatedRequest(), () => {
      setLoading(false);
      dispatch(toggleModal('export', true, { sourceType, includeProperties }));
    }, () => {
      setLoading(false);
    }));
  };

  return (
    <ModalWrapper>
      <Modal
        visible={visible}
        title={(
          <ModalTitle>
            Choose which columns you export
            <CloseIcon
              onClick={() => dispatch(toggleModal('export', true, { sourceType, includeProperties }))}
              className="ic_close"
            />
          </ModalTitle>
        )}
        width={760}
        footer={false}
        bodyStyle={{ padding: '24px 0px' }}
        closable={false}
        onCancel={() => dispatch(toggleModal('export', true, { sourceType, includeProperties }))}
      >
        <Container>
          <Half>
            <Search
              onChange={(e) => handleChange(e.target.value.toLowerCase())}
              style={SearchPropertiesForm}
              placeholder="Search properties"
              autoFocus
            />
            <PropertiesWrapper>
              {groups.map((group, index) => {
                const propertiesArr = groupsProperties[index].filter((item) => groupsPropertiesFilterCondition(item));
                return propertiesArr?.length ? (
                  <PropertiesContainer key={`export_property_group_label_${group})}`}>
                    <PropertyGroup>{group}</PropertyGroup>
                    {
                      propertiesArr.map((property, propertyIndex) => (
                        <PropertyItem key={`export_property_item_${propertyIndex + 1}`}>
                          <Checkbox
                            style={CheckboxText}
                            checked={selectedColumns.some((item) => item.sourceType === property.sourceType && item.propertyName === get(property, 'name'))}
                            onChange={(e) => {
                              updateColumns(property, e.target.checked);
                            }}
                          >
                            {renameLabel(get(property, 'label'), get(property, 'default'))}
                          </Checkbox>
                        </PropertyItem>
                      ))
                    }
                  </PropertiesContainer>
                ) : null;
              })}
            </PropertiesWrapper>
            <ParagraphText>
              {'Don\'t see the property you\'re looking for? '}
              <CreatePropertyLink onClick={() => {
                dispatch(toggleModal('create_property_preview', true));
              }}
              >
                {' '}
                Create a property
              </CreatePropertyLink>
            </ParagraphText>
          </Half>

          <SelectedColumns
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            getPropertyLabel={getPropertyLabel}
            updateColumns={updateColumns}
            getPropertyIsDefault={getPropertyIsDefault}
          />
        </Container>
        <Footer>
          <Button
            data-form={loading ? 'false' : 'true'}
            loading={loading}
            type="primary"
            size="large"
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            onClick={() => dispatch(toggleModal('export', true, { sourceType, includeProperties }))}
            type="normal"
            size="large"
          >
            Back
          </Button>
          <CreatePropertyLink onClick={() => setSelectedColumns([])}>Remove all columns</CreatePropertyLink>
        </Footer>

      </Modal>
    </ModalWrapper>
  );
};

export default ExportConfigurationEditor;
