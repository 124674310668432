import React from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
} from 'antd';

import { Button } from 'components/common';

import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { deleteFolder } from 'data/actions/lists';
import { toggleModal } from 'data/actions/modals';
import { getAllChildrenIds, getFolderById } from 'helpers/folderTree';

import {
  ModalTitle,
  CloseIcon,
  Label,
  Footer,
} from '../baseModal/styles';

const DeleteFolderModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const modalOptions = useSelector(modalOptionsSelector);

  const folderId = get(modalOptions, 'folderId');
  const folders = get(modalOptions, 'folders');
  const kind = get(modalOptions, 'kind');
  const tree = getFolderById(folderId, folders);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Delete these folders?
          <CloseIcon
            onClick={() => dispatch(toggleModal('delete_folder', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={450}
      footer={false}
      headerStyle={{ background: 'red' }}
      onCancel={() => dispatch(toggleModal('delete_folder', false))}
      closeIcon={<div />}
    >
      <>
        <Label>
          Deleting folders can&apos;t be undone.
          {` Any ${kind} inside these folders won't be deleted. `}
          The folders selected to be deleted are:
          {' '}
          {get(tree, 'name', '')}
        </Label>
      </>
      <Footer>
        <Button
          data-form={!folderId ? 'false' : 'true'}
          type="danger"
          size="large"
          onClick={() => {
            const folderIds = getAllChildrenIds(tree, [folderId]);
            dispatch(toggleModal('delete_folder', false));
            dispatch(deleteFolder(folderIds));
          }}
          disabled={!folderId}
        >
          Delete folder
        </Button>
        <Button
          size="large"
          type="default"
          onClick={() => dispatch(toggleModal('delete_folder', false))}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default DeleteFolderModal;
