import { useEffect } from 'react';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { toggleMessage } from 'data/actions/message';
import queryString from 'query-string';

const useTransferTruemailUser = () => {
  const dispatch = useDispatch();
  const queryData = queryString.parse(window.location.search);
  const truemailUser = get(queryData, 'truemailUser');

  useEffect(() => {
    if (truemailUser) {
      dispatch(toggleMessage('info', { text: 'The registration on TrueMail is closed now. Please, use the partner tool GetProspect for email verification purposes.', duration: 1000 * 60 }));
    }
  }, []); // eslint-disable-line
};

export default useTransferTruemailUser;
