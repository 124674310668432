import styled from 'styled-components';
import { Icon as IconA } from 'antd';

import Icon from 'components/common/icon';
import { Select as SelectC } from 'components/common';
import * as CONSTANTS from 'constants/styles';
import plusIcon from 'assets/images/ic-plus-blue.svg';

export const ListName = styled.span`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  font-weight: 500;
  color: ${CONSTANTS.COLORS.BLACK_G1};
  overflow: hidden;

  ${(props) => props.hasNoList && `
    opacity: 0.5;
  `}
`;

export const FolderName = styled.span`
  font-size: 12px;
  color: ${CONSTANTS.COLORS.GREY_G2};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DropdownWrapper = styled.div`
  .ant-select-dropdown-menu {
    overscroll-behavior: none;
    .list-container {
      width: 90%;
      margin-top: 10px;
      overflow: hidden;
      white-space: nowrap;

      ${ListName} {
        display: inline-block;
        max-width: 80%;
      }

      ${FolderName} {
        display: inline-block;
      }
    }

    li:not(.ant-select-dropdown-menu-item-selected) {
      svg {
        display: none;
      }
    }

    ${(props) => (props.showCreate ? `
      .ant-select-dropdown-menu-item:not(.saved-list):not(.ant-select-dropdown-menu-item-disabled) {
        color: ${CONSTANTS.COLORS.PRIMARY};
        border-bottom: 1px solid ${CONSTANTS.COLORS.GREY_G7} !important;
        position: relative;
        background-image: url(${plusIcon});
        background-position: 10px;
        background-repeat: no-repeat;

        
          &:before {
            content: ' Create list "';
            position: inherit;
            margin-left: 20px;
          }

        &:after {
          content: '"';
        }
      }
    ` : `
      .ant-select-dropdown-menu-item:not(.saved-list):not(.ant-select-dropdown-menu-item-disabled) {
          color: ${CONSTANTS.COLORS.PRIMARY};
          border-bottom: 1px solid ${CONSTANTS.COLORS.GREY_G7} !important;
          position: relative;
          background-position: 10px;
          background-repeat: no-repeat;

          
            &:before {
              ${(props.unselect ? 'content: \' Unselect list "\';' : 'content:\' Select list "\';')}
              position: inherit;
            }

          &:after {
            content: '"';
          }
        }
    `)}

    .ant-select-dropdown-menu-item.saved-list {
      display: flex;
      justify-content: space-between;
      align-content: center;
      overflow: hidden;
      padding: 0 12px;
      height: 40px;
    }
  }

  .ant-tooltip-inner {
    text-align: left;
    background-color: rgba(0, 0, 0, 0.85);
    width: 270px;
  }

  .ant-tooltip-arrow {
    display: none;
  }
`;

export const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${CONSTANTS.COLORS.GREY_G1};
  margin-bottom: 5px;
  display: flex;
`;

export const IconCheckWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Select = styled(SelectC)`
  width: 100%;
  height: 100%;
  align-self: center;
  cursor: pointer;

  .ant-select-arrow {
    top: ${(props) => (props.size === 'default' ? 11 : 15)}px !important;
  }
  
  .ant-select-selection__placeholder {
    margin-left: 0;
  }

  .ant-select-selection {
    .ant-select-selection__rendered {
      overflow: hidden;
      margin-left: 10px;
      margin-right: 30px;
      max-width: 90%;
      max-height: ${(props) => {
    switch (props.size) {
      case 'large':
        return '36px';
      case 'default':
        return '30px';
      default: return '32px;';
    }
  }};
    }

    ul {
      display: flex;
      overflow-x: hidden;
    }

    .ant-select-selection__clear {
      top: ${(props) => (props.size === 'default' ? 12 : 15)}px !important;
      margin-right: 10px;
      background: white;
    }

    .ant-select-selection__choice {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      width: fit-content;
      height: 20px;
      position: relative;
      border-radius: 4px;
      border: 0;
      padding: 0 6px 0 10px !important;
      transition: unset !important;

      ${IconCheckWrapper} {
        display: none;
      }

      .list-container {
        display: flex;
        ${ListName} {
          font-weight: normal;
          color: ${CONSTANTS.COLORS.GREY_G1};
        }

        ${FolderName} {
          display: none;
        }
      }

      .ant-select-selection__choice__remove {
        display: none;
      }

      .ant-select-selection__choice__content {
        font-family: AvenirNext;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: ${CONSTANTS.COLORS.BLACK_G1};
        text-overflow: ellipsis;
        overflow: hidden; 
        white-space: nowrap;
        margin: 0;
      }
    }

    .ant-select-selection__choice__disabled {
      overflow: initial;
      .ant-select-selection__choice__content {
        text-overflow: initial;
      }

      .ant-select-selection__choice__content {
        color: ${CONSTANTS.COLORS.GREY_G1};
      }
    }
  }
`;

export const ListEmpty = styled.div`
  display: flex;
  justify-content: center;
`;

export const IconCheck = styled(IconA).attrs(() => ({ type: 'check' }))`
  color: ${CONSTANTS.COLORS.PRIMARY};
`;

export const IconArrow = styled(Icon).attrs((props) => ({
  type: 'ic-chevron-left',
  fill: props.open ? CONSTANTS.COLORS.PRIMARY : '',
}))`
  transform: ${(props) => (props.open ? 'rotate(90deg)' : 'rotate(-90deg)')};
  transition: transform 0.2s ease;
  cursor: pointer;
`;

export const IconClear = styled(Icon).attrs(() => ({ type: 'ic-close', fill: 'primary' }))`
  cursor: pointer;
`;
