import get from 'lodash/get';

import { getItemsPerPage } from 'helpers/pageCalculation';
import { ACCOUNT_EVENTS } from '../types/event.types';
import { PageSizes } from '../types/tableMeta.types';

const initialState = {
  notifications: {
    data: [],
    pageSize: PageSizes.DEFAULT_NOTIFICATIONS_PAGE_SIZE,
    page: 1,
    unreadCount: 0,
  },
  popUpNotification: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_NOTIFICATIONS': {
      return {
        ...state,
        notifications: {
          ...action.payload.meta,
          data: [...state.notifications.data, ...action.payload?.data],
        },
      };
    }

    case 'SEND_NOTIFICATION': {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data: action.data.type ? [action.data, ...state.notifications.data] : state.notifications.data,
        },
        popUpNotification: action.data,
      };
    }

    case ACCOUNT_EVENTS.NOTIFICATION_EVENT: {
      const newNotification = JSON.parse(action.payload);
      const { data, ...meta } = state.notifications;
      const totalItems = get(meta, 'totalItems', 0) + 1;
      let unreadCount = get(meta, 'unreadCount', 0);
      let totalPages = get(meta, 'totalPages', 0);
      totalPages = getItemsPerPage(totalItems, meta.pageSize);
      newNotification.sendDate = new Date().toISOString();

      if (newNotification.read === false) {
        unreadCount += 1;
      }

      return {
        ...state,
        notifications: {
          ...meta,
          totalItems,
          totalPages,
          unreadCount,
          data: [...data, newNotification],
        },
        popUpNotification: newNotification,
      };
    }

    case 'CLOSE_CONNECT_MAILBOX':
    case 'REMOVE_POP_UP_DATA': {
      return {
        ...state,
        popUpNotification: initialState.popUpNotification,
      };
    }

    case 'UPDATE_NOTIFICATIONS': {
      const updatedNotifications = [...action.payload.data];

      const newData = get(state, 'notifications.data', []).map((notification) => {
        const updated = updatedNotifications.find((el) => el._id === notification._id);
        if (notification.fake) {
          notification.read = true;
          notification.seen = new Date().toISOString();
        }
        if (updated) {
          notification.read = updated.read;
          notification.seen = updated.seen;
        }
        return notification;
      });
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...action.payload.meta,
          data: newData,
        },
      };
    }
    case 'SIGN_OUT':
    case 'SWITCH_WORKSPACE': {
      return initialState;
    }

    default:
      return state;
  }
};
