import { useEffect, useState, useCallback } from 'react';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

const useCarousel = ({
  prefix = '',
  items,
  autoScrollInterval,
}) => {
  const [activeItem, setActiveItem] = useState(0);

  const itemsLength = items.length;

  const swipeItem = useCallback((direction) => () => {
    setActiveItem((prevActiveItem) => {
      let nextItem = prevActiveItem;
      if (direction === 'left') {
        if (prevActiveItem === 0) nextItem = itemsLength - 1;
        else nextItem -= 1;
      } else if (prevActiveItem === itemsLength - 1) {
        nextItem = 0;
      } else {
        nextItem = prevActiveItem + 1;
      }
      return nextItem;
    });
  }, [itemsLength]);

  useEffect(() => {
    scrollIntoView(document.querySelector(`#${prefix}${activeItem}`), {
      behavior: 'smooth',
    });
  }, [prefix, activeItem]);

  useEffect(() => {
    let interval;
    if (autoScrollInterval) {
      interval = setInterval(() => {
        setActiveItem((prevActiveItem) => {
          if (prevActiveItem === itemsLength - 1) return 0;
          return prevActiveItem + 1;
        });
      }, autoScrollInterval);
    }
    return () => clearInterval(interval);
  }, [autoScrollInterval, itemsLength]);

  return { swipeItem, activeItem };
};

export default useCarousel;
