import { headShake } from 'react-animations';
import styled, { css, keyframes } from 'styled-components';

import EllipsisText from 'components/ellipsisText';
import { COLORS, MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from 'constants/styles';

const headShakeKeyFrame = keyframes`${headShake}`;

export const IconContainer = styled.div`
  background: ${COLORS.WHITE};
  width: 25px;
  height: 25px;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid ${COLORS.GREY_G14};
  border-radius: 2px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.05)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.05));

  &:hover {
    background: ${COLORS.PRIMARY_P4};
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    display: ${(props) => (props.hideOnMobile ? 'none' : 'flex')};
  }
`;

export const Container = styled.div`
  padding: 0 5px;
  ${(props) => (!props.collapsed && props.hasBorderBottom && `
    border-bottom: 1px solid ${COLORS.GREY_G4};
  `)}

  &:hover {
    .icon-edit {
      position: static;
      display: flex;
    }
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    .icon-edit {
      position: static;
    }
  }
`;

export const DraggableIconContainer = styled.div`
  align-items: center;
  width: 20px;
  margin-left: -15px;
`;

const hasHover = css`
  &:hover {
    cursor: pointer;
    background-color: ${COLORS.GREY_G5};
    border-radius: 2px;

    ${IconContainer} {
      display: flex;
    }
  }
`;

const tabletDirection = css`
  @media (max-width: ${TABLET_MAX_WIDTH}px) {
    align-items: flex-start;
    flex-direction: column;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    align-items: center;
    flex-direction: row;
  }
`;

export const textAreaMixin = css`
  overflow: hidden;
  
  ${(props) => (!props.showMore && `
    max-height: 68px;
  `)}
`;

export const oneLineTextMixin = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Value = styled.span`
  position: relative;
  font-weight: ${(props) => (props.fontWeight || 500)};
  width: 100%;
  font-size: 14px;
  padding-top: 5px;
  hyphens: auto;
  color: ${(props) => {
    if (props.error) return COLORS.RED;
    return props.gray ? COLORS.GREY_G2 : COLORS.BLACK_G1;
  }};
  cursor: ${(props) => (props.readOnly ? 'default' : 'pointer')};
  ${(props) => (props.fieldType === 'textarea' ? textAreaMixin : oneLineTextMixin)}
  ${(props) => (props.primary && `
    color: ${COLORS.PRIMARY};
  `)}

  & .property--text.animated {
    animation: 1s ${headShakeKeyFrame};
  }
`;

export const PropertyContainer = styled.div`
  display: flex;
  padding: 0 10px;
  min-height: 34px;

  input, .ant-select-selection-selected-value {
    font-size: 14px !important;
  }

  ${(props) => (props.hasHover && hasHover)}
  /* ${(props) => (props.hasTabletDirection && tabletDirection)} */
  ${(props) => (props.hasValueHoverColor && `
    &:hover {
      ${Value} {
        color: ${COLORS.PRIMARY};
      }
    }
  `)}
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 40px;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  justify-content: space-between;

  ${DraggableIconContainer} {
    display: ${(props) => (props.hasSpaceLeft || props.hasDraggableIcon ? 'flex' : 'none')};
    visibility: hidden;
  }

  &:hover {
    @media (min-width: ${MOBILE_MAX_WIDTH}px) {
      ${DraggableIconContainer} {
        visibility: ${(props) => (props.hasDraggableIcon ? 'visible' : 'hidden')};;
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: ${COLORS.BLACK_G1};
`;

export const Label = styled(EllipsisText)`
  min-width: 110px;
  max-width: 110px;
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => (props.error ? COLORS.RED : COLORS.GREY_G2)};
  padding-top: 5px;
  display: block;
  align-self: start;
`;

export const ShowMore = styled.span`
  color: ${COLORS.PRIMARY};
  cursor: pointer;
  white-space: nowrap;
  font-weight: 500;
`;

export const PropertiesContainer = styled.div`
  overflow-y: hidden;
  transition: height 0.3s ease-in-out;
`;

export const AlternativeHeaderContainer = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  margin: 0 -5px;

  color: ${(props) => (props.collapsed ? COLORS.BLACK_G1 : COLORS.PRIMARY)};

  .material-icons {
    color: ${(props) => (props.collapsed ? COLORS.GREY_G2 : COLORS.PRIMARY)} !important;
  }
`;

export const TreeDots = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.BLACK_G1};
`;

export const BorderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 10px;
  margin-top: 5px;
  padding-right: 5px;
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLORS.GREY_G4};
`;

export const ArrowContainer = styled.div`
  position: absolute;
  right: 12px;
  top: 5px;
`;
