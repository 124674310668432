export const withKeys = (array = []) => {
  if (!Array.isArray(array)) return null;
  return array.map((el, index) => ({ ...el, key: index }));
};

export const arrayToQuery = (array) => array.join(',');

export const setDisableSpecificItem = (item, field, equalValue) => {
  if (item[field] === equalValue) {
    return { ...item, disabled: true };
  }
  return item;
};

export const sortArrayBySpecificNames = (array, key, names) => {
  const sortedArray = [];
  const copyArray = [...array];
  names.forEach((name) => {
    const foundItemIndex = copyArray.findIndex((item) => item && item[key] === name);
    if (foundItemIndex !== -1) {
      sortedArray.push(copyArray[foundItemIndex]);
      delete copyArray[foundItemIndex];
    }
  });
  return [...sortedArray, ...copyArray.filter((item) => item)];
};

export const sortObjectBySpecificNames = (obj, names, createEmptyNames = false) => {
  const sortedObject = {};
  const copyObject = { ...obj };
  names.forEach((name) => {
    const nameProperty = Object.keys(copyObject).find((property) => property === name);
    if (nameProperty) {
      sortedObject[nameProperty] = copyObject[nameProperty];
      delete copyObject[nameProperty];
    } else if (createEmptyNames) {
      sortedObject[name] = undefined;
    }
  });
  return { ...sortedObject, ...copyObject };
};

export const sortDatasetByMonths = (dataset, fieldName = 'createdAt') => {
  const sortedDataset = {};
  const currentYear = new Date()?.toLocaleString('en-US', { year: 'numeric' });
  dataset.sort((a, b) => b[fieldName]?.localeCompare(a[fieldName])).forEach((item) => {
    let month = new Date(item[fieldName])?.toLocaleString('en-US', { month: 'long' });
    const year = new Date(item[fieldName])?.toLocaleString('en-US', { year: 'numeric' });
    if (year !== currentYear) {
      month = `${month} ${year}`;
    }
    if (sortedDataset[month]) { sortedDataset[month].push(item); } else { sortedDataset[month] = [item]; }
  });
  return sortedDataset;
};

export const removeConsecutiveDuplicates = (arr) => arr.filter((value, index, array) => value !== array[index + 1]);

export const removeLastOccurrenceFromArray = (array, string) => {
  for (let i = array.length - 1; i >= 0; i = -1) {
    if (array[i] === string) {
      array.splice(i, 1);
      break;
    }
  }

  return array;
};
