import styled from 'styled-components';

import { COLORS, TABLET_PORTRAIT_MAX_WIDTH } from 'constants/styles';

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 20px;
  padding-left: 10px;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.BLACK_G1};
`;

export const EmailContainer = styled.div`
  color: ${(props) => (props.error ? COLORS.RED : COLORS.BLACK_WITH_OPACITY_B1)};
`;

export const EditorBottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${COLORS.GREY_G4};
  border-top: none;
  border-radius: 4px;
  padding: 12px;
  margin-top: -3px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

export const LabelContainer = styled.div`
  margin: 10px 15px;
`;

export const PropertyContainer = styled.div`
  margin: 0 5px;
`;

export const TabsContainer = styled.div`
  width: ${(props) => (props.countTabs * 125)}px;

  @media (max-width: ${TABLET_PORTRAIT_MAX_WIDTH}px) {
    width: 100vw;
  }
`;

export const TabContainer = styled.div`
  margin-top: 16px;
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.GREY_G4};
  margin-top: 15px;
`;

export const StatusContainer = styled.div`
  height: 34px;
  display: flex;
  align-items: center;
  margin: 0 10px;
`;
