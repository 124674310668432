const daysOption = {
  name: 'Days of week',
  data: [
    {
      label: 'Monday',
      value: 'Monday',
    },
    {
      label: 'Tuesday',
      value: 'Tuesday',
    },
    {
      label: 'Wednesday',
      value: 'Wednesday',
    },
    {
      label: 'Thursday',
      value: 'Thursday',
    },
    {
      label: 'Friday',
      value: 'Friday',
    },
    {
      label: 'Saturday',
      value: 'Saturday',
    },
    {
      label: 'Sunday',
      value: 'Sunday',
    },
  ],
};

export default daysOption;
