import { get, sum } from 'lodash';

import React, {
  useRef,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchFiltersSelector } from 'data/selectors/search';
import { demoUserSelector } from 'data/selectors/user';
import { clearSearchFilter } from 'data/actions/search';
import { toggleModal } from 'data/actions/modals';

import {
  CollapseContainer,
  FiltersHeader,
  FiltersParagraph,
  FilterContainer,
  HeaderText,
} from './styles';
import FiltersStructure from './filtersStructure';
import FilterItem from './filtersGenerator/index';

const SearchFilters = ({ sourceType }) => {
  const dispatch = useDispatch();
  const saveRef = useRef();
  const resetRef = useRef();
  const closeFilters = useRef([]);
  const [filtersCount, setFiltersCount] = useState(0);
  const [saveButtonRef, setSaveButtonRef] = useState();
  const [resetButtonRef, setResetButtonRef] = useState();
  const filters = useSelector(searchFiltersSelector());
  const [collapseKey, setCollapseKey] = useState('');
  const isDemoUser = useSelector(demoUserSelector);

  useEffect(() => {
    setSaveButtonRef({ current: get(saveRef, 'current.buttonNode') });
    setResetButtonRef({ current: get(resetRef, 'current.buttonNode') });
  }, []);

  useEffect(() => {
    if (filters.length >= 1 && filters.some((i) => i.property === 'email')) {
      const count = [];
      filters.forEach((element) => {
        count.push(element.included.value?.length);
      });
      setFiltersCount(sum(count) - 1);
    } else if (filters.length >= 1) {
      const count = [];
      filters.forEach((element) => {
        count.push(element.included.value?.length);
      });
      setFiltersCount(sum(count));
    } else {
      setFiltersCount(0);
    }
  }, [filters]);

  const clearFilters = () => {
    dispatch(clearSearchFilter());
  };
  const filtersCountStr = `Clear(${filtersCount})`;

  return (
    <>
      <FilterContainer className="filter-date-target">
        <FiltersHeader style={isDemoUser ? { justifyContent: 'space-between' } : {}}>
          <HeaderText>
            Filters
          </HeaderText>
          {filtersCount
            ? (
              <>
                <FiltersParagraph onClick={clearFilters}>{filtersCountStr}</FiltersParagraph>
                {!isDemoUser ? (<FiltersParagraph onClick={() => { dispatch(toggleModal('save_search', true, { sourceType })); }}>Save</FiltersParagraph>) : null}
              </>
            )
            : ''}
        </FiltersHeader>
        <CollapseContainer>
          {
          FiltersStructure.filter(({ visibleTabs }) => visibleTabs.includes(sourceType)).map((filtersGroup) => (
            <div
              key={`collapse_group_contacts_${filtersGroup.groupName}`}
              data-tour={get(filtersGroup, 'dataTour', [])?.join(',')}
            >
              {
                filtersGroup.filters.map((filter) => (
                  <FilterItem
                    closeFilter={(callback) => {
                      const isExist = closeFilters?.current?.find((existsCallback) => existsCallback === callback);

                      if (!isExist) {
                        closeFilters.current.push(callback);
                      }
                    }}
                    collapseKey={collapseKey}
                    setCollapseKey={setCollapseKey}
                    controlRefs={[saveButtonRef, resetButtonRef]}
                    filter={filter}
                    key={`filter_item_${filtersGroup.groupName}_${filter.property}`}
                  />
                ))
              }
            </div>
          ))
        }
        </CollapseContainer>
      </FilterContainer>
    </>
  );
};
export default SearchFilters;
