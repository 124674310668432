import React, { useCallback } from 'react';

import { BreadcrumbsButtonItem, BreadcrumbsContainer } from '../../tables/foldersTable/styles';
import {
  BreadCrumbsWrapper,
  RootPath,
} from './styles';
import Icon from '../../common/icon';

const Breadcrumbs = ({
  breadCrumbs = [], folderId, setCreatingNewFolder, setFolderId,
}) => {
  const movePrevPath = useCallback(() => {
    const currentSelectedIndex = breadCrumbs.findIndex((item) => item._id === folderId);

    if (currentSelectedIndex !== -1) {
      setFolderId(breadCrumbs[currentSelectedIndex - 1]._id);
    }
  }, [folderId, breadCrumbs, setFolderId]);

  return (
    <BreadCrumbsWrapper>
      <RootPath>
        <Icon
          type="ic-home"
          fill="primary"
          size="small"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setFolderId('all');
          }}
        />
      </RootPath>
      {
        breadCrumbs.length > 1 && (
          <>
            <Icon style={{ cursor: 'pointer', marginLeft: '5px' }} onClick={movePrevPath} type="ic-arrow-back" size="smallest" />
            <BreadcrumbsContainer style={{ display: 'inline-block' }}>
              {breadCrumbs && breadCrumbs.filter((item) => item._id !== 'all').map((item) => (
                <BreadcrumbsButtonItem
                  currentItem={item._id === folderId}
                  onClick={() => {
                    if (item._id !== folderId) {
                      setCreatingNewFolder(false);
                      setFolderId(item._id);
                    }
                  }}
                  key={`folder-breadcrumbs-${item._id}`}
                >
                  {item.name}
                </BreadcrumbsButtonItem>
              ))}
            </BreadcrumbsContainer>
          </>
        )
      }
    </BreadCrumbsWrapper>
  );
};

export default Breadcrumbs;
