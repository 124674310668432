import styled from 'styled-components';

export const ContactPropertyContainer = styled.div`
  margin-top: 15px;
`;

export const PropertyLabel = styled.span`
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
  color: #000;
`;

export const Footer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #f5f8fa;
  border-top: 1px solid #cbd6e2;
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;

export const ButtonStyle = {
  marginRight: 10,
};

export const StepsContainer = styled.div`
  margin-top: 30px;
`;

export const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid #cbd6e2;
 `;

export const SettingsDescription = styled.div`
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 `;

export const PopoverStyle = styled.div`
  margin-left: auto;
  width: 60px;
`;
