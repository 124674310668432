import get from 'lodash/get';
import { createSelector } from 'reselect';

const baseIntegrationsSelector = (state) => get(state, 'integrations', {});

export const integrationsSelector = createSelector(
  baseIntegrationsSelector,
  (integrations) => integrations?.integrations,
);

const baseIntegrationsCategoriesSelector = (state) => get(state, 'integrations.integrationsCategories', []);
export const integrationsCategoriesSelector = createSelector(
  baseIntegrationsCategoriesSelector,
  (integrationsCategories) => integrationsCategories,
);

export const connectedAccountsSelector = createSelector(
  baseIntegrationsSelector,
  (integrations) => get(integrations, 'connectedAccounts', []),
);

const baseIntegrationSelector = (integrationName) => (state) => get(state, `integrations.${integrationName}`, {});

export const hubspotPropertiesByTypeSelector = (type) => createSelector(
  baseIntegrationSelector('hubspot'),
  (integration) => get(integration, `connection.hsProperties.${type}`, []),
);

export const salesforcePropertiesByTypeSelector = (type) => createSelector(
  baseIntegrationSelector('salesforce'),
  (integration) => get(integration, `connection.sfProperties.${type}`, []),
);

export const zohoPropertiesByTypeSelector = (type) => createSelector(
  baseIntegrationSelector('zoho'),
  (integration) => get(integration, `connection.zhProperties.${type}`, []),
);

export const connectionSelector = (integrationName) => createSelector(
  baseIntegrationSelector(integrationName),
  (integration) => get(integration, 'connection', {}),
);

export const integrationTaskSelector = (integrationName) => createSelector(
  baseIntegrationSelector(integrationName),
  (integration) => get(integration, 'currentTask', {}),
);

export const pipedrivePropertiesByTypeSelector = (type) => createSelector(
  baseIntegrationSelector('pipedrive'),
  (integration) => get(integration, `connection.pdProperties.${type}`, []),
);

export const googleSheetsPropertiesSelector = createSelector(
  baseIntegrationSelector('sheets'),
  (integration) => get(integration, 'connection.properties.contact', []).filter((el) => el.display),
);

export const googleSheetsListSelector = createSelector(
  baseIntegrationSelector('sheets'),
  (integration) => get(integration, 'files', []),
);

export const googleSheetsSpreadsheetSelector = createSelector(
  baseIntegrationSelector('sheets'),
  (integration) => get(integration, 'connection.spreadsheet'),
);

export const worksheetsListSelector = createSelector(
  baseIntegrationSelector('sheets'),
  (integration) => get(integration, 'connection.spreadsheet.ranges', []),
);

export const selectedWorksheetSelector = createSelector(
  baseIntegrationSelector('sheets'),
  (integration) => get(integration, 'connection.worksheet'),
);

export const googleSheetsFileNameSelector = createSelector(
  baseIntegrationSelector('sheets'),
  (integration) => get(integration, 'connection.spreadsheet.name'),
);
