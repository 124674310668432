import styled from 'styled-components';

import { Icon } from 'components/common';
import * as CONSTANTS from 'constants/styles';
import {
  MessageHeaderWrapper,
  MessageContainer,
  MessageButtonWrapper,
  MessageContentWrapper,
} from '../default/styles';

export const Container = styled(MessageContainer)`
  width: 315px;
`;

export const HeaderWrapper = styled(MessageHeaderWrapper)`
  font-size: 14px;

  span {
    color: #071427;
  }
`;

export const ButtonWrapper = styled(MessageButtonWrapper)`
  margin-top: 15px;

  button {
    span {
      font-size: 13px !important;
    }
  }
`;

export const ContentWrapper = styled(MessageContentWrapper)`
  font-size: 12px;
  color: #979FA8;
  margin-top: 0;
  display: flex;
  align-items: end;
  justify-content: space-between;

  strong {
    color: #4F5964;
  }
`;

export const TextWrapper = styled.div`
  max-width: 220px;
`;

export const MessageAction = styled.span`
  cursor: pointer;
  text-decoration: underline;
  margin-top: 5px;
`;

export const CloseMessageImg = styled(Icon).attrs(() => ({
  type: 'ic-close',
  size: 'small',
  fill: CONSTANTS.COLORS.BLACK_WITH_OPACITY_B2,
}))`
  cursor: pointer;
  right: 16px;
  margin-left: auto;
  margin-top: -6px;
  font-size: 16px !important;
`;

export const MessageTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 15px 10px;
  font-size: 13px;
  border: 4px;
`;
