import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { messageOptionsSelector } from 'data/selectors/message';
import { useFavicon, drawTextBubble } from 'react-usefavicon/dist/react-usefavicon';
import { MessagesContainer } from './styles';
import MessageItem from './messageItem';

const MessageController = () => {
  const messages = useSelector(messageOptionsSelector);
  const [tabIsActive, toggleTabIsActive] = useState(true);

  const {
    restoreFavicon,
    drawOnFavicon,
  } = useFavicon()[1];

  useEffect(() => {
    if (messages.length) {
      drawOnFavicon(drawTextBubble, {
        faviconSize: 32,
        clear: false,
        color: 'crimson',
        label: `${messages.length}`,
      });
    } else restoreFavicon();
  }, [messages.length, drawOnFavicon, restoreFavicon]);

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      toggleTabIsActive(!document.hidden);
    });

    return () => {
      document.removeEventListener('visibilitychange', () => {
        toggleTabIsActive(!document.hidden);
      });
    };
  }, []);
  return (
    <MessagesContainer>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {messages.map((message) => <MessageItem tabIsActive={tabIsActive} key={message.id} {...message} />)}
    </MessagesContainer>
  );
};

export default MessageController;
