import get from 'lodash/get';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import React, {
  useState,
} from 'react';

import Step from 'components/step';
import Loader from 'components/loading';
import { Button } from 'components/common';
import AddFilter from 'components/addFilter';
import { validateEmail } from 'helpers/validate';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { sendTestMessage } from 'data/actions/sequences';
import { removeSignature } from 'helpers/sequences/signature';
import { sequenceAccountSelector } from 'data/selectors/sequences';
import { sendTestMessageLoadingSelector } from 'data/selectors/loading';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';

import {
  Container,
} from '../createEmailTemplate/styles';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../baseModal/styles';
import {
  PanelHeader, PanelInput,
} from '../../step/styles';
import {
  HeaderWrapper,
  PanelContainer,
} from '../../sequenceInfo/tabs/steps/stepEditor/styles';
import {
  EditorContainer,
} from './styles';

const TestPreviewSequenceStepModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { visible } = useSelector(modalsSelector);
  const {
    content,
    subject,
    previousModal,
  } = useSelector(modalOptionsSelector);
  const account = useSelector(sequenceAccountSelector);
  const loadingTestMessage = useSelector(sendTestMessageLoadingSelector);

  const [dynamicContent, setDynamicContent] = useState(content);
  const [dynamicSubject, setDynamicSubject] = useState(subject);
  const [sendTo, setSendTo] = useState(account?.email || '');

  const errorSendTo = !validateEmail(sendTo);

  const handleClose = () => {
    if (previousModal) {
      dispatch(toggleModal(previousModal, true, { subject, content }));
    } else {
      dispatch(toggleModal('test_preview_sequence_step', false));
    }
  };

  const sendTestEmail = () => {
    const contentWithoutSignature = removeSignature(dynamicContent);
    dispatch(sendTestMessage({
      subject: dynamicSubject,
      content: contentWithoutSignature,
      sendTo,
    }, () => {
      handleClose();
      dispatch(toggleMessage('success', { header: `The preview of your email has been sent to ${sendTo}` }));
    }));
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle white>
          Send test email
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={1050}
      footer={false}
      wrapClassName="ant-tablet-width ant-white-modal"
      onCancel={handleClose}
    >
      <Container noShadow>
        <EditorContainer>
          <div>
            <Loader loading={false}>
              <PanelContainer>
                <HeaderWrapper>
                  <PanelHeader>Send to</PanelHeader>
                </HeaderWrapper>
                <PanelInput
                  name="email"
                  size="default"
                  error={errorSendTo ? 'The email is invalid' : false}
                  value={sendTo}
                  onChange={(e) => setSendTo(e.target.value)}
                />
              </PanelContainer>
              <PanelContainer>
                <HeaderWrapper>
                  <PanelHeader>From</PanelHeader>
                </HeaderWrapper>
                {
                  account ? (
                    <span>{`${get(account, 'name', '')} ${account ? '-' : ''} ${get(account, 'email', '')}`}</span>
                  ) : (
                    <AddFilter
                      onClick={() => {
                        handleClose();
                        history.push('/sequences/email-accounts');
                      }}
                      label="Connect email"
                    />
                  )
                }
              </PanelContainer>
              <Step
                id="test_preview_sequence"
                bordered={false}
                height={260}
                content={dynamicContent}
                subject={dynamicSubject}
                onChange={({ content: contentLocal, subject: subjectLocal }) => {
                  setDynamicContent(contentLocal);
                  setDynamicSubject(subjectLocal);
                }}
                style={{ padding: '0', borderBottom: 0 }}
              />
            </Loader>
          </div>
        </EditorContainer>
      </Container>
      <Footer>
        <Button loading={loadingTestMessage} disabled={errorSendTo} type="primary" onClick={sendTestEmail}>Send test</Button>
        <Button type="normal" onClick={handleClose}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default TestPreviewSequenceStepModal;
