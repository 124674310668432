import React from 'react';

export const Star = ({ color, type }) => {
  if (type === 'half') {
    return (
      <>
        <path opacity="0.1" d="M8 0L10.5863 4.44033L15.6085 5.52786L12.1846 9.35967L12.7023 14.4721L8 12.4L3.29772 14.4721L3.81535 9.35967L0.391548 5.52786L5.41374 4.44033L8 0Z" fill="#102A51" />
        <path d="M7.99908 0L8 8L7.99908 12.4L3.2968 14.4721L3.81443 9.35967L0.390625 5.52786L5.41282 4.44033L7.99908 0Z" fill={color} />
      </>
    );
  }

  return (
    <path d="M8 0L10.5863 4.44033L15.6085 5.52786L12.1846 9.35967L12.7023 14.4721L8 12.4L3.29772 14.4721L3.81535 9.35967L0.391548 5.52786L5.41374 4.44033L8 0Z" fill={type === 'empty' ? 'rgb(224 227 233)' : color} />
  );
};

const Stars = ({ color, maxRating = 5, rating }) => (
  Array(maxRating).fill(maxRating).map((_, index) => {
    let type = 'whole';
    const countEmptyStars = maxRating - rating;
    if (countEmptyStars > maxRating - (index + 1)) {
      if (countEmptyStars !== Math.floor(countEmptyStars)) {
        type = 'half';
      } else {
        type = 'empty';
      }
    }
    return (
      // eslint-disable-next-line
      <svg key={`star-${index}`} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Star color={color} type={type} />
      </svg>
    );
  })
);

export default Stars;
