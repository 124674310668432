import get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleModal } from 'data/actions/modals';
import { changePassword } from 'data/actions/user';
import { Button, Password } from 'components/common';
import { modalsSelector } from 'data/selectors/modals';
import { passwordWithRepeatValidate } from 'helpers/validate';
import {
  CloseIcon,
  ModalTitle,
  Label,
} from '../baseModal/styles';
import {
  PropertyItem,
  ModalButtonGroup,
} from './styles';
import { SystemModal } from '../../commonStyles';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { visible, type } = useSelector(modalsSelector);
  const [passwords, updatePasswords] = useState({});

  const isVisible = visible && type === 'change_password';

  const changePasswordsHandler = (e) => {
    const newPasswords = { ...passwords };
    newPasswords[e.target.name] = e.target.value;
    updatePasswords(newPasswords);
  };

  const closeModalHandler = () => {
    dispatch(toggleModal('change_password', false));
    updatePasswords({});
  };

  const updateHandler = () => {
    dispatch(changePassword({
      password: get(passwords, 'current', ''),
      newPassword: get(passwords, 'new', ''),
    }));
    closeModalHandler();
  };

  const disableUpdate = !passwordWithRepeatValidate(get(passwords, 'new', ''), get(passwords, 'repeat', '')) || !get(passwords, 'current', '').length;

  return (
    <SystemModal
      visible={isVisible}
      title={(
        <ModalTitle>
          Change password
          <CloseIcon
            onClick={closeModalHandler}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={520}
      footer={false}
      onCancel={closeModalHandler}
    >
      <div>
        <div>
          <PropertyItem>
            <Label>
              Current password
            </Label>
            <Password
              size="large"
              value={get(passwords, 'current')}
              name="current"
              onChange={changePasswordsHandler}
              visibilityToggle={false}
              autoFocus
            />
          </PropertyItem>
        </div>
        <div>
          <PropertyItem>
            <Label>
              New password
            </Label>
            <Password
              size="large"
              value={get(passwords, 'new')}
              name="new"
              onChange={changePasswordsHandler}
              visibilityToggle={false}
            />
          </PropertyItem>
        </div>
        <div>
          <PropertyItem>
            <Label>
              Repeat
            </Label>
            <Password
              size="large"
              value={get(passwords, 'repeat')}
              name="repeat"
              onChange={changePasswordsHandler}
              visibilityToggle={false}
            />
          </PropertyItem>
        </div>
        <ModalButtonGroup>
          <Button
            data-form={disableUpdate ? 'false' : 'true'}
            type="primary"
            size="large"
            style={{
              padding: '0 30px',
              fontSize: 14,
              marginRight: 10,
            }}
            disabled={disableUpdate}
            onClick={updateHandler}
          >
            <span>Update</span>
          </Button>
          <Button
            type="normal"
            size="large"
            style={{
              padding: '0 20px',
              fontSize: 14,
            }}
            onClick={closeModalHandler}
          >
            <span>Cancel</span>
          </Button>
        </ModalButtonGroup>
      </div>
    </SystemModal>
  );
};

export default ChangePassword;
