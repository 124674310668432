import { Empty } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EllipsisSpan } from 'components/commonStyles';
import { Icon, Option, Select } from 'components/common';
import { addPrimaryPosition } from 'data/actions/contacts';
import { contactPrimaryPosition } from 'data/selectors/contacts';
import { NewSequenceContainer } from 'components/modals/addToSequenceModal/styles';

const PrimaryPosition = ({
  value,
  handleChange,
}) => {
  const dispatch = useDispatch();

  const primaryPosition = useSelector(contactPrimaryPosition);

  const [visible, toggleVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const renderNotFoundContent = () => {
    if (!searchValue) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    return (
      <NewSequenceContainer
        style={{ margin: '-5px -12px' }}
        onClick={() => {
          toggleVisible(false);
          setSearchValue('');
          dispatch(addPrimaryPosition(searchValue));
          handleChange({ target: { name: 'primaryPosition', value: searchValue } });
        }}
      >
        <Icon style={{ marginLeft: 10 }} type="ic-add" />
        <EllipsisSpan style={{ display: 'block', marginLeft: 15, marginRight: 15 }}>
          {`Create position "${searchValue}"`}
        </EllipsisSpan>
      </NewSequenceContainer>
    );
  };

  return (
    <Select
      open={visible}
      onDropdownVisibleChange={toggleVisible}
      notFoundContent={renderNotFoundContent()}
      placeholder="Select position"
      showSearch
      onSearch={(e) => setSearchValue(e)}
      size="default"
      value={value}
      filterOption={(_, o) => o?.props?.children && o.props.children.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0}
      onChange={(_value) => {
        handleChange({
          target: {
            name: 'primaryPosition',
            value: _value,
          },
        });
      }}
    >
      {
        (primaryPosition?.options || []).map((position) => (
          <Option value={position} key={position}>{position}</Option>
        ))
      }
    </Select>
  );
};

export default PrimaryPosition;
