import React from 'react';
import { Label } from '@newlab-io/getprospect-react-components/lib/ui';

import { Icon } from 'components/common';
import StatusLabel from 'components/statusLabel';
import { StatusContainer, TextContainer } from './styles';

const StatusValidation = ({
  nameProtocol,
  valid,
  linkManual,
  onRefresh,
  hasIconRefresh,
}) => (
  <StatusContainer>
    <Label size="small" kind="quaternary" style={{ width: 110 }} fontWeight={500}>{nameProtocol}</Label>
    <StatusLabel status={valid ? 'success' : 'error'}>
      <TextContainer>
        <span>
          &nbsp;
          {valid ? 'Connected' : 'Failed'}
          &nbsp;
        </span>
        {
          !valid ? (
            <>
              {
                hasIconRefresh && (
                  <Icon style={{ marginLeft: 2 }} size="small" onClick={onRefresh} type="ic-refresh" />
                )
              }
              <Icon
                fillOnHover="primary"
                outlined
                onClick={() => window.open(linkManual, '_blank')}
                tooltipOptions={{
                  placement: 'bottomLeft',
                  title: (
                    <>
                      <span>How to set up the</span>
                      &nbsp;
                      {nameProtocol}
                      &nbsp;
                      <span>record</span>
                    </>
                  ),
                }}
                style={{ marginLeft: 2 }}
                size="small"
                type="ic-info"
              />
            </>
          ) : null
        }
      </TextContainer>
    </StatusLabel>
  </StatusContainer>
);

export default StatusValidation;
