export const getItemsPerPage = (totalItems, pageSize) => {
  if (pageSize === 0) {
    return 0;
  }
  const roundResult = Math.round(totalItems / pageSize);
  const result = totalItems / pageSize;
  return result > roundResult ? roundResult + 1 : roundResult;
};

export const getTotalPages = (totalItems, itemsPerPage) => Math.ceil(totalItems / itemsPerPage);
