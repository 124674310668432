import { useEffect } from 'react';
import { userExtensionSelector, userSettingsSelector } from 'data/selectors/user';

import { useSelector, useDispatch } from 'react-redux';

import useLoginPlugin from 'hooks/useLoginPlugin';
import { useHistory } from 'react-router-dom';
import { userExtensionLoaderSelector, userSettingsLoaderSelector } from 'data/selectors/loading';

const ExtensionInstallHandler = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { completedSteps } = useSelector(userSettingsSelector);
  const { demoMaxStep } = useSelector(userExtensionSelector);

  const userSettingsLoading = useSelector(userSettingsLoaderSelector);
  const userExtensionLoading = useSelector(userExtensionLoaderSelector);
  const loginPlugin = useLoginPlugin();

  useEffect(() => {
    if (history && !userExtensionLoading && !userSettingsLoading) {
      window.location.href = 'https://www.linkedin.com/search/results/people/?keywords=ceo&origin=CLUSTER_EXPANSION&sid=9UW';
      loginPlugin();
    }
  }, [dispatch, loginPlugin, history, completedSteps, demoMaxStep, userExtensionLoading, userSettingsLoading]);

  return null;
};

export default ExtensionInstallHandler;
