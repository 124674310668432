const GenderOption = {
  name: 'Gender',
  data: [
    {
      label: 'Male',
      value: 'Male',
    },
    {
      label: 'Female',
      value: 'Female',
    },
    {
      label: 'Neither',
      value: 'Neither',
    },
    {
      label: 'I prefer not to say',
      value: 'I prefer not to say',
    },
  ],
};

export default GenderOption;
