import React from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { toggleModal } from 'data/actions/modals';
import logoIcon from 'assets/images/logo-new.svg';
import gmailIcon from 'assets/images/ic_gmail.svg';
import ConnectGoogleButton from 'components/connectGoogleButton';
import { modalsSelector } from 'data/selectors/modals';
import useGoogleOauth from 'hooks/useGoogleOauth';

import {
  ModalTitle,
  ProperCloseIcon,
  ColumnFooter,
  HeaderLabel,
  SubHeaderLabel,
} from '../baseModal/styles';
import { ImagesContainer, PointsContainer, Point } from './styles';

const AddGoogleAccountModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);

  const handleClose = () => dispatch(toggleModal('add_google_account', false));
  const { connectGoogleSheets } = useGoogleOauth();

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle white>
          <ProperCloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      wrapClassName="ant-white-modal"
      closable={false}
      width={345}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        <ImagesContainer>
          <img width="32" height="32" src={gmailIcon} alt="Logo" />
          <PointsContainer>
            <Point />
            <Point />
            <Point />
            <Point />
          </PointsContainer>
          <img width="42" height="28" src={logoIcon} alt="Logo" />
        </ImagesContainer>
        <HeaderLabel>Add Google account</HeaderLabel>
        <SubHeaderLabel>
          To activate the integration please connect
          <br />
          your Google Drive account to GetProspect
        </SubHeaderLabel>
        <ColumnFooter>
          <ConnectGoogleButton signIn onClick={() => window.open(connectGoogleSheets(), '_self')} />
        </ColumnFooter>
      </div>
    </Modal>
  );
};

export default AddGoogleAccountModal;
