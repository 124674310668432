const initialState = {
  settings: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_FILTER_SETTINGS': {
      const { filterId, name, value } = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          [filterId]: {
            ...state.settings[filterId],
            [name]: value,
          },
        },
      };
    }
    default:
      return state;
  }
};
