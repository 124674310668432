import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

const useResetParams = (action, path) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const resetParams = useCallback((obj) => {
    if (action) dispatch(action(obj));
    let finalDestination = location.pathname;
    const isIncludeTourSearch = location.search.includes('tour');

    if (isIncludeTourSearch) {
      finalDestination += location.search;
    }
    if (path) {
      finalDestination = path;
    }
    if (/\/search\/(contact|company)\/.*/.test(finalDestination) && !isIncludeTourSearch) {
      const [, , sourcePath] = finalDestination.split('/');
      finalDestination = `/search/${sourcePath}`;
    }

    if (location.pathname !== finalDestination) {
      history.push(finalDestination);
    }
  }, [dispatch, action, location, path, history]);

  return resetParams;
};

export default useResetParams;
