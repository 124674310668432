import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const CodeWrapper = styled.div`
  margin: 20px 0;
`;

export const NumberText = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.06em;
  color: ${COLORS.BLACK_G1};
  margin-bottom: 10px;
`;

export const SubHeader = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${COLORS.GREY_G1};
  margin-bottom: 10px;
`;

export const SubHeaderAction = styled.p`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${COLORS.PRIMARY};
  margin-bottom: 0;

  ${(props) => (props.disabled && `
    opacity: 0.3;
    cursor: not-allowed;
  `)}
`;

export const Header = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: ${COLORS.BLACK_G1};
  margin-bottom: 15px;
`;

export const VerifyPhoneImage = styled.img`
  width: 100%;
`;

export const VerifyPhoneImageContainer = styled.div`
  width: 295px;
  height: 165px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 30px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .code-input-container {
    & > div:not(:last-child) {
      margin-right: 25px;
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
