import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import { modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import {
  MenuItem,
} from './styles';
import FullScreenMobileModal from '../fullScreenMobileModal';

const ListPageMobileMenu = ({
  type,
}) => {
  const dispatch = useDispatch();
  const options = useSelector(modalOptionsSelector);
  const tab = get(options, 'tab', 'lists');
  const changeTab = get(options, 'changeTab', () => {});

  const clickMenuItem = (value) => {
    dispatch(toggleModal(type, false));
    changeTab(value);
  };

  return (
    <FullScreenMobileModal
      type={type}
      content={(
        <>
          <MenuItem onClick={(e) => clickMenuItem(e.target.value)} value="lists" active={tab === 'lists'}>All lists</MenuItem>
          <MenuItem onClick={(e) => clickMenuItem(e.target.value)} value="folders" active={tab === 'folders'}>Folders</MenuItem>
        </>
      )}
    />
  );
};

export default ListPageMobileMenu;
