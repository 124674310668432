import { Modal } from 'antd';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { toggleModal } from 'data/actions/modals';
import { Input, Button } from 'components/common';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { createCompaniesFilter } from 'data/actions/companies';
import { companiesFiltersSelector } from 'data/selectors/companies';
import {
  CloseIcon,
  ModalTitle,
  Label,
  InputStyle,
  Footer,
} from '../baseModal/styles';

const CreateCompanyFilterModal = () => {
  const dispatch = useDispatch();
  const filters = useSelector(companiesFiltersSelector);
  const { visible } = useSelector(modalsSelector);
  const { history } = useSelector(modalOptionsSelector);

  const [name, updateName] = useState('');
  useEffect(() => updateName(''), [visible]);
  const navigate = (id) => {
    history.push(`/companies/filter/${id}?existed=true`);
  };

  const onSuccessCreateList = (data) => {
    const id = get(data, '_id');
    if (id) navigate(id);
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Create a new view
          <CloseIcon
            onClick={() => dispatch(toggleModal('create_company_filter', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={() => dispatch(toggleModal('create_company_filter', false))}
    >
      <>
        <Label>Name*</Label>
        <Input
          autoFocus
          onChange={(e) => {
            updateName(e.target.value);
          }}
          value={name}
          placeholder="Type the view name..."
          style={InputStyle}
        />
      </>
      <Footer>
        <Button
          data-form={!name || !filters.length ? 'false' : 'true'}
          type="primary"
          onClick={() => {
            dispatch(createCompaniesFilter(name, filters, onSuccessCreateList));
            dispatch(toggleModal('create_company_filter', false));
          }}
          disabled={!name || !filters.length}
        >
          Save
        </Button>
        <Button type="normal" onClick={() => dispatch(toggleModal('create_company_filter', false))}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default withRouter(CreateCompanyFilterModal);
