import { useSelector } from 'react-redux';

import { FASTSPRING_ERROR_EVENT } from 'data/types/event.types';
import { subscriptionsSelector } from 'data/selectors/subscriptions';
import { PAYERS } from 'constants/payers';
import useFastSpringScript from './useFastSpringScript';

const DEFAULT_SDK_URL = 'https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.5/fastspring-builder.min.js';
const {
  REACT_APP_STOREFRONT_API_TEST, REACT_APP_STOREFRONT_API_B2B_TEST, REACT_APP_STOREFRONT_API, REACT_APP_STOREFRONT_API_B2B,
} = process.env;

function catchFastSpringError(code, stringError) {
  const fastSpringEvent = new CustomEvent(FASTSPRING_ERROR_EVENT, {
    detail: {
      code,
      error: stringError,
    },
  });
  window.dispatchEvent(fastSpringEvent);
}

window.catchFastSpringError = catchFastSpringError;

const getPayerStorefront = (payer) => payer === PAYERS.COMPANY //eslint-disable-line
  ? process.env.NODE_ENV === 'development' ? REACT_APP_STOREFRONT_API_B2B_TEST : REACT_APP_STOREFRONT_API_B2B
  : process.env.NODE_ENV === 'development' ? REACT_APP_STOREFRONT_API_TEST : REACT_APP_STOREFRONT_API;

const useFastSpring = ({
  sdkUrl = DEFAULT_SDK_URL,
}) => {
  const subscription = useSelector(subscriptionsSelector);
  const storefront = getPayerStorefront(subscription.payer);

  // put data-debug: true for enable debug mode
  const [loaded] = useFastSpringScript(sdkUrl, {
    'data-access-key': 'XVM64EEERCQL0YLLRRYXPQ',
    'data-popup-closed': 'onFSPopupClosed',
    'data-storefront': storefront,
    id: 'fsc-api',
    type: 'text/javascript',
    'data-error-callback': 'catchFastSpringError',
    'data-data-callback': 'fastSpringDataCallback',
  });

  const { fastspring } = window;

  // if (onPopupClose) {
  //   window.onFSPopupClosed = onPopupClose;
  // }

  return { loaded, fastspring };
};

export default useFastSpring;
