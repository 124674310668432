import { nanoid } from 'nanoid';

const initialState = {
  messages: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_MESSAGE': {
      const { options, type } = action.data;
      const defaultMessageOptions = {
        id: nanoid(),
        text: '',
        icon: '',
        header: '',
        duration: 5,
        link: {
          url: '',
          text: '',
        },
        type: '',
      };

      return {
        ...state,
        messages: [
          {
            ...defaultMessageOptions,
            ...options,
            type,
          },
          ...state.messages,
        ],
      };
    }

    case 'CLEAR_MESSAGE': {
      return {
        ...state,
        messages: [...state.messages].filter((message) => (message.id !== action.data)),
      };
    }

    default:
      return state;
  }
};
