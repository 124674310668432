import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/loading';
import { toggleModal } from 'data/actions/modals';
import { selectedContactsSelector } from 'data/selectors/contacts';
import { createSequenceLoaderSelector } from 'data/selectors/loading';
import { addContactsToSequence, createSequence } from 'data/actions/sequences';
import useContactsSavedMessage from 'hooks/messageTemplates/useContactsSavedMessage';

import {
  ButtonEditConfig,
  ButtonTryNow,
  CallToSequencesContainer,
  CallToSequencesDescription,
  CallToSequencesHeader,
} from './styles';

const CallToSequences = ({ customFilters }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector(createSequenceLoaderSelector);
  const selectedContacts = useSelector(selectedContactsSelector);

  const [isClosed, setIsClosed] = useState(!!localStorage.getItem('closedCallToSequences'));

  const showContactsSavedMessage = useContactsSavedMessage();

  const close = () => {
    localStorage.setItem('closedCallToSequences', 'true');
    setIsClosed(true);
    dispatch(toggleModal('export', false));
  };

  const create = () => {
    dispatch(createSequence(null, (sequence) => {
      close();
      setImmediate(() => {
        history.push(`/sequences/${sequence._id}/steps`);
        dispatch(addContactsToSequence({
          sequenceId: sequence._id,
          contactsIds: selectedContacts,
          onSuccess: (data) => {
            showContactsSavedMessage({
              ...data,
              sequenceId: sequence._id,
            });
          },
          customFilters,
        }));
      });
    }));
  };

  if (isClosed) return null;

  return (
    <CallToSequencesContainer>
      <CallToSequencesHeader>
        Try GetProspect Sequence tool
      </CallToSequencesHeader>
      <CallToSequencesDescription>
        Instead of exporting your leads, add them to the sequence!
      </CallToSequencesDescription>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ButtonTryNow
          onClick={create}
        >
          <span style={{ marginRight: loading ? 5 : 0 }}>
            Try now
          </span>
          <Loader loading={loading} />
        </ButtonTryNow>
        <ButtonEditConfig
          style={{ marginLeft: 5 }}
          onClick={close}
        >
          Skip
        </ButtonEditConfig>
      </div>
    </CallToSequencesContainer>
  );
};

export default CallToSequences;
