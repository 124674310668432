import styled from 'styled-components';

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    height: 42px;
    width: 100%;
    font-size: 13px !important;
  }
`;

export const FooterOr = styled.div`
  margin: 0 10px;
`;

export const Description = styled.div`
  margin-bottom: 10px;
`;
