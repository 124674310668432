import { Progress } from 'data/types/progress.types';

export const findAllBulkOperations = (onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: 'FIND_ALL_BULK_OPERATIONS',
    payload: {
      endpoint: 'api/v1/bulk-operations',
      method: 'GET',
      params: {
        status: Progress.IN_PROGRESS,
      },
      delayLoad: 0,
    },
    components: ['bulkOperationsLoader'],
    onSuccess,
    onFailed,
  });
};

export const deleteBulkOperation = (bulkOperationId) => (dispatch) => {
  dispatch({
    type: 'DELETE_BULK_OPERATION',
    data: { bulkOperationId },
  });
};

export const createBulkOperation = (bulkOperation) => (dispatch) => {
  dispatch({
    type: 'CREATE_BULK_OPERATION',
    data: { bulkOperation },
  });
};
