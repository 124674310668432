import pick from 'lodash/pick';

export const removeEmpty = (obj) => {
  const newObj = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      if (Array.isArray(obj[key])) {
        const clearedData = [...Object.values(removeEmpty(obj[key]))];

        if (clearedData.length) {
          newObj[key] = clearedData;
        }
      } else {
        const clearedData = removeEmpty(obj[key]);

        if (Object.keys(clearedData).length) {
          newObj[key] = clearedData;
        }
      }
    } else if (obj[key] !== '' && obj[key]) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};

export const omitDeep = (obj, properties = []) => {
  if (!obj) return obj;
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (!properties.includes(key)) {
      if (Array.isArray(obj[key])) {
        newObj[key] = [];
        obj[key].forEach((item, index) => {
          if (typeof item === 'object') {
            newObj[key][index] = omitDeep(item, properties);
          } else {
            newObj[key][index] = item[index];
          }
        });
      } else if (typeof obj[key] === 'object') {
        newObj[key] = omitDeep(obj[key], properties);
      } else {
        newObj[key] = obj[key];
      }
    }
  });
  return newObj;
};

export const containsProperties = (obj, properties = []) => Object.keys(pick(obj, properties)).length === properties.length;
