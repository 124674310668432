import get from 'lodash/get';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
} from 'antd';

import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { Button, Checkbox } from 'components/common';
import {
  contactsFoldersSelector,
  deletedListsSelector,
  listsSelector,
  selectAllListsSelector,
  selectedListsSelector,
} from 'data/selectors/lists';
import { searchContacts } from 'data/actions/contacts';
import { getAllChildrenIds, getFolderById } from 'helpers/folderTree';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import {
  deleteFolder,
  searchLists,
  setListsParams,
  updateListsConditions,
} from 'data/actions/lists';
import { upperCaseFirst } from 'helpers/upperCaseFirst';
import {
  ModalTitle,
  CloseIcon,
  Footer,
  Label,
} from '../baseModal/styles';
import { CheckboxWrapper } from './styles';

const DeleteListModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const modalOptions = useSelector(modalOptionsSelector);
  const folders = useSelector(contactsFoldersSelector);
  const selectAllLists = useSelector(selectAllListsSelector);
  const listsItems = useSelector(listsSelector);
  const { totalItems: totalDeletedLists, selectAll: selectAllDeletedLists } = useSelector(deletedListsSelector);
  const { data: lists, totalItems: totalLists, page } = useSelector(listsSelector);

  const operationType = get(modalOptions, 'operationType', 'delete');

  const [updateContactsStatus, setUpdateContactsStatus] = useState(operationType === 'restore');
  const [type, setType] = useState('');

  const setSelectedRowKeys = get(modalOptions, 'setSelectedRowKeys');
  const listId = get(modalOptions, 'listId');
  const folderId = get(modalOptions, 'folderId');
  const listIds = get(modalOptions, 'listIds', []);
  const folderIds = get(modalOptions, 'folderIds', []);
  const excludedRowKeys = get(modalOptions, 'excludedRowKeys', []);
  const kind = get(modalOptions, 'kind', 'lists');
  const selectedLists = useSelector(selectedListsSelector);
  const { listsIds, idsFolders } = (() => {
    let listIdsItems = operationType === 'delete' ? listIds : selectedLists;
    const folderIdsItems = folderIds;

    if (listId) {
      listIdsItems = [listId];
    }
    return { listsIds: listIdsItems, idsFolders: folderIdsItems };
  })();

  const allIdsFolders = useMemo(() => {
    const currentFolder = getFolderById(folderId, folders);
    return getAllChildrenIds(currentFolder, idsFolders) || [];
  }, [idsFolders, folderId, folders]);

  const operationName = operationType === 'delete' ? 'Delete' : upperCaseFirst(operationType).replace('_', ' ');

  const [listsCount, setListsCount] = useState(0);

  useEffect(() => {
    if (operationType === 'delete') {
      if (selectAllLists) setListsCount(totalLists - excludedRowKeys.length);
      else setListsCount(listIds.length);
    } else if (selectAllDeletedLists) setListsCount(totalDeletedLists - excludedRowKeys.length);
    else setListsCount(selectedLists.length);
  }, [listsItems, totalLists, totalDeletedLists, selectAllLists, selectAllDeletedLists, operationType, selectedLists, listIds, excludedRowKeys]);

  useEffect(() => {
    let typeData = '';
    if (listsIds.length && idsFolders.length) {
      typeData = 'items';
    } else if (listsIds.length) {
      typeData = (listsIds.length > 1 || selectAllLists || selectAllDeletedLists) ? `${operationName} these ${listsCount} lists?` : `${operationName} this list?`;
    } else {
      typeData = (allIdsFolders.length > 1) ? `${operationName} these ${allIdsFolders.length} folders?` : `${operationName} this folder?`;
    }
    setType(typeData);
  }, [operationName, listsCount, allIdsFolders.length, listsIds.length, folderId, folders, idsFolders, selectAllLists, selectAllDeletedLists]);

  const handleDelete = () => {
    switch (operationType) {
      case 'delete': {
        if (listsIds.length) {
          dispatch(updateListsConditions({
            listsIds: selectAllLists ? [] : listsIds,
            excludedRowKeys,
            updateContactsStatus,
            onSuccess: ({ pending }) => {
              const newListsPage = (page > 1 && ((lists.length - listsIds.length)) <= 0) ? page - 1 : page;
              dispatch(setListsParams({ page: selectAllLists ? 1 : newListsPage }));
              if (pending && updateContactsStatus) {
                dispatch(toggleMessage('info', { header: 'Deletion contacts in progress' }));
              } else if (updateContactsStatus) {
                dispatch(searchContacts());
              }
            },
          }));
        }
        if (allIdsFolders.length) {
          dispatch(deleteFolder(allIdsFolders, folderId === 'all'));
        }
        break;
      }
      case 'permanent_delete':
      case 'restore': {
        if (listsIds.length || selectAllDeletedLists) {
          dispatch(updateListsConditions({
            listsIds: selectAllDeletedLists ? [] : listsIds,
            excludedRowKeys,
            updateContactsStatus,
            onSuccess: ({ pending = false }) => {
              if (operationType === 'restore') dispatch(searchLists());
              if (pending && updateContactsStatus) {
                dispatch(toggleMessage('info', { header: 'Restoring contacts in progress' }));
              } else if (updateContactsStatus) {
                dispatch(searchContacts());
              }
            },
            operationType,
          }));
        }
        break;
      }
      default: break;
    }

    if (setSelectedRowKeys) setSelectedRowKeys([]);
    dispatch(toggleModal('delete_lists', false));
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger={operationType !== 'restore'}>
          {type}
          <CloseIcon
            onClick={() => dispatch(toggleModal('delete_lists', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      headerStyle={{ background: 'red' }}
      onCancel={() => dispatch(toggleModal('delete_lists', false))}
      closeIcon={<div />}
    >
      <>
        <Label>
          You&#39;re about to
          {' '}
          {(operationType === 'permanent_delete ') && 'permanently '}
          {(operationType === 'restore') ? 'restore' : 'delete'}
          {
            folderId ? ` ${allIdsFolders.length > 1 ? 'folders' : 'this folder'}` : ` ${listsIds.length > 1 || selectAllDeletedLists || selectAllLists ? `${listsCount} lists` : 'this list'}`
          }
          {'. '}
          {(operationType !== 'restore') && ((operationType === 'permanent_delete') ? "This action can't be undone." : `${kind === 'list' ? "Deleted lists can't be restored after 60 days." : ''}`)}
        </Label>
        <br />
        {(operationType !== 'permanent_delete' && kind === 'list') && (
          <CheckboxWrapper>
            <Label>
              <Checkbox
                disabled={!!excludedRowKeys.length}
                checked={updateContactsStatus}
                onChange={(e) => setUpdateContactsStatus(e.target.checked)}
              >
                {(operationType === 'restore') ? `Restore all contacts associated with this list${((listsIds.length > 1) || selectAllLists) ? 's' : ''}.` : 'Delete all contacts associated with this list from the workspace.'}
              </Checkbox>
            </Label>
          </CheckboxWrapper>
        )}
      </>
      <Footer>
        <Button
          data-form="true"
          type={(operationType === 'restore') ? 'primary' : 'danger'}
          size="large"
          onClick={handleDelete}
        >
          {(operationType === 'restore') ? 'Restore' : 'Delete'}
        </Button>
        <Button
          size="large"
          type="default"
          onClick={() => dispatch(toggleModal('delete_lists', false))}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default DeleteListModal;
