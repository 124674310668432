import styled from 'styled-components';
import * as CONSTANTS from 'constants/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

export const Title = styled.h2`
  color: inherit;
  font-weight: 400;
  font-size: 24px;
  margin-top: 0;
  display: block;
  line-height: normal;
  text-transform: none;
  margin-bottom: 40px;
`;

export const DescriptionWrapper = styled.div`
  text-align: center;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #33475b;
  line-height: 20px;
  margin: 0;
`;

export const NoteCheckbox = styled.p`
  line-height: 20px;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
`;

export const UploadIcon = {
  fontSize: 40,
  color: '#7c98b5',
  marginRight: 10,
};

export const FileName = styled.div`
  max-width: 425px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 29px 0 28px 0;
  align-items: center;
  line-height: 25px;
  color: rgb(124, 152, 182);
  border-color: rgb(81, 111, 144);
  min-height: 65px;

  div.file-loading {
    flex-direction: row;
    display: flex;
    justify-content: center;
    width: 100%;
    div:first-child {
      margin-right: 10px;
    }
  }
`;

export const UploadText = styled.p`
  margin: 0;
  line-height: 24px;
  font-size: 16px;
  color: ${CONSTANTS.COLORS.BLACK_WITH_OPACITY_B1};
  margin-bottom: 1px;
`;

export const UploadTypes = styled(UploadText)`
  line-height: 22px;
  font-size: 14px;
  margin-bottom: 0;
  color: ${CONSTANTS.COLORS.GREY_G13};
`;

export const UploadIconWrapper = styled.div`
  margin-bottom: 24px;
`;

export const ImportContentWrapper = styled.div`
  max-width: 510px;
  margin: 0 auto
`;

export const DraggerContainer = styled.div`
  margin: ${(props) => (props.loaded ? 40 : 20)}px auto;
  border-color: rgb(81, 111, 144);
  
  .ant-upload.ant-upload-drag {
    background-color: ${CONSTANTS.COLORS.GREY_WITH_OPACITY_G2};
  }
  
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
  
  .ant-upload.ant-upload-drag {
    border-width: 2px;
  }
  
  ${(props) => props.loaded && `
    ${UploadContainer} {
      padding: 0;
      min-height: auto;
      flex-direction: row;
      
      > div:first-child {
        display: inherit;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
      } 
    }
    
    .ant-upload.ant-upload-drag {
      background-color: transparent;
      border: none;
    }
    
    i.anticon.anticon-close {
      top: calc(50% - 5.5px) !important;
      right: 5px !important;
    }
    
    .ant-upload.ant-upload-disabled {
      cursor: initial;
    }
  `}
`;

export const CloseIcon = {
  fontSize: 11,
  color: 'rgb(124, 152, 182)',
  position: 'absolute',
  right: 10,
  top: 10,
  cursor: 'pointer',
};
