import { useState, useEffect, useCallback } from 'react';

const useTimer = ({ timeReload, startZero }) => {
  const [time, setTime] = useState(startZero ? 0 : timeReload);

  useEffect(() => {
    let timer;
    if (time > 0) {
      timer = setTimeout(() => setTime(time - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [time]);

  const resetTime = useCallback(() => setTime(timeReload), [timeReload]);

  return [time, resetTime];
};

export default useTimer;
