import React from 'react';
import { useSelector } from 'react-redux';

import { cancelSubscriptionSelector } from 'data/selectors/subscriptions';
import Connect from '../../common/components/connect';

const PoorEmails = ({
  onFinish,
  setNextStep,
  stepNumber,
  onClose,
}) => {
  const { sharedInformation } = useSelector(cancelSubscriptionSelector);

  const steps = {
    1: {
      component: Connect,
      params: {
        hiddenDemo: true,
        description: "We are very sorry such a situation happened. We'd like to investigate your case closer to improve our product. Please share more details and tell us what problems you faced while using the GetProspect tools.",
        onNext: setNextStep,
        onClose,
      },
    },
  };

  const step = steps[stepNumber];

  if (!step) {
    const needToAct = !sharedInformation;
    onFinish({ needToAct });
    return null;
  }

  const { component: Component, params = {} } = step;

  // eslint-disable-next-line
  return <Component {...params} />;
};

export default PoorEmails;
