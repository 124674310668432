import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const NewSequenceContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.loading ? 'not-allowed' : 'pointer')};
  height: 32px;

  span {
    font-weight: 500;
    color: ${COLORS.PRIMARY} !important;
  }

  span:not(:last-child) {
    opacity: ${(props) => (props.loading ? 0.75 : 1)};
  }

  &:hover {
    background-color: ${COLORS.PRIMARY_P4};
  }
`;
