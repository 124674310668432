import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';

import { updateLoading } from 'data/actions/loading';
import { FASTSPRING_POPUP_CLOSE_EVENT } from 'data/types/event.types';
import { setFastSpringData, setRedirectAfterFirstPay } from 'data/actions/subscriptions';

const useFastSpringCallbacks = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    function fastSpringDataCallback(data) {
      dispatch(setFastSpringData(data));
      dispatch(updateLoading('fastSpringUpdateLoading', false));
    }

    window.fastSpringDataCallback = fastSpringDataCallback;

    return () => {
      window.fastSpringDataCallback = () => {};
    };
  }, [dispatch]);

  useEffect(() => {
    function onPopupClose(data) {
      const fastSpringEvent = new CustomEvent(FASTSPRING_POPUP_CLOSE_EVENT, {
        detail: data,
      });
      window.dispatchEvent(fastSpringEvent);

      if (data?.reference) {
        const isPricingPage = get(window, 'location.pathname', '')?.includes('/pricing');

        if (isPricingPage) {
          dispatch(setRedirectAfterFirstPay(true));
        }
      }
    }

    window.onFSPopupClosed = onPopupClose;

    return () => {
      window.onFSPopupClosed = () => {};
    };
  }, [dispatch]);
};

export default useFastSpringCallbacks;
