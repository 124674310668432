import React from 'react';
import { useSelector } from 'react-redux';

import { ConfirmationModal } from 'components/common';
import { SUBSCRIPTION_STATUS } from 'data/types/subscription.types';
import { BasePauseSubscriptionModal } from 'components/modals/pauseSubscriptionModal';
import { cancelSubscriptionSelector, isDisabledPauseSelector } from 'data/selectors/subscriptions';
import Rating from '../../common/components/rating';
import Connect from '../../common/components/connect';
import PauseQuestion from '../../common/components/pauseQuestion';

const FinishedWork = ({
  onFinish,
  setNextStep,
  setPreviousStep,
  stepNumber,
  onClose,
  deleteAccount,
}) => {
  const finishedWork = useSelector(cancelSubscriptionSelector);
  const isDisabledPause = useSelector(isDisabledPauseSelector);

  const PERIOD_MONTHS = '1-3 months';
  const isFinishedWorkWithLowRating = finishedWork.rating < 7;
  const isPausedMore3Months = finishedWork.pausingPeriod === null;
  const isPausingPeriodNumber = typeof finishedWork.pausingPeriod === 'number';
  const isNonAllowed3Step = isPausedMore3Months || isFinishedWorkWithLowRating || isDisabledPause;

  const steps = {
    1: {
      component: Rating,
      params: {
        onClose,
        onNext: setNextStep,
      },
    },
    2: {
      component: isFinishedWorkWithLowRating ? Connect : PauseQuestion,
      params: isFinishedWorkWithLowRating ? {
        description: 'We are sorry to hear that. Would you mind sharing your opinion on using our product in more details?',
        onClose,
        onNext: setNextStep,
      } : {
        onClose,
        onNext: ({ pausingPeriod }) => {
          const isCurrentNonAllowed3Step = pausingPeriod === null || isDisabledPause;
          setNextStep({ pausingPeriod }, isCurrentNonAllowed3Step ? 4 : undefined);
        },
      },
    },
    3: isNonAllowed3Step ? null : {
      component: BasePauseSubscriptionModal,
      params: {
        onClose,
        onSuccess: ({ period }) => {
          setNextStep({ pausingPeriod: period });
        },
      },
    },
    4: isPausingPeriodNumber ? null : {
      component: ConfirmationModal,
      params: {
        title: deleteAccount ? 'Delete account' : 'Are you sure?',
        type: 'danger',
        // eslint-disable-next-line no-nested-ternary
        label: deleteAccount && isDisabledPause ? 'Are you sure you want to finish using the GetProspect tools?' : isDisabledPause ? null : 'You cannot continue with 30% discount',
        submitButtonText: deleteAccount ? 'Delete' : 'Cancel subscription',
        closeButtonText: isDisabledPause ? 'No' : 'Pause',
        onClose: (reason) => {
          if (reason !== 'success') {
            if (isDisabledPause || reason === 'close') {
              onClose();
            } else if (reason === 'selectedAnotherOption') {
              setPreviousStep({ pausingPeriod: PERIOD_MONTHS });
            }
          }
        },
        onSubmit: setNextStep,
      },
    },
  };

  const step = steps[stepNumber];

  if (!step) {
    const needToAct = !finishedWork.sharedInformation;
    onFinish({
      needToAct,
      status: finishedWork.pausingPeriod && !isPausedMore3Months && !isDisabledPause ? SUBSCRIPTION_STATUS.PAUSE : SUBSCRIPTION_STATUS.DISABLED,
      period: !isPausedMore3Months && !isDisabledPause ? undefined : finishedWork.pausingPeriod,
    });
    return null;
  }

  const { component: Component, params = {} } = step;

  // eslint-disable-next-line
  return <Component {...params} />;
};

export default FinishedWork;
