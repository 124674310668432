import React, {
  useEffect,
  useState,
  lazy,
  Suspense,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import get from 'lodash/get';
import jwt from 'jsonwebtoken';
import { ResizeProvider, ThemeProvider, EnvironmentProvider } from '@newlab-io/getprospect-react-components/lib/providers';

import useScript from 'hooks/useScript';
import { getAllNotifications } from 'data/actions/notifications';
import {
  accountSelector,
  isAuthSelector,
  showLastStepSelector,
  workspaceIdSelector,
  extensionAuthActiveSelector,
} from 'data/selectors/user';
import { getAllProperties } from 'data/actions/properties';

import {
  signOut,
  getAccount,
  getUserSettings,
  getUserCredits,
  getUserWorkspaces,
  updateAccount,
  setSignUpFingerPrint,
  getWorkspaceLimits,
  setPluginInformation,
  getUserExtension,
  setExtensionAuth,
} from 'data/actions/user';
import { DEMO_USER_ACCOUNT } from 'data/types/demoUser.types';
import { accountEventsSubscription } from 'data/actions/common';
import MessageController from 'components/message';
import { useAffiliate } from 'hooks/useAffiliate';
import useKeyHandler from 'hooks/useKeyHandler';
import { getFoldersRelation, getRecentLists } from 'data/actions/lists';
import useSwipeHandler from 'hooks/useSwipeHandler';
import PhoneVerificationLayout from 'routes/phoneVerificationLayout';
import EmailConfirmationLayout from 'routes/emailConfirmationLayout';
import { getSubscription } from 'data/actions/subscriptions';
import LoginLayout from 'routes/loginLayout';
import useStoreTriggers from 'hooks/useStoreTriggers';
import useIntercom from 'hooks/useIntercom';
import useEventTriggers from 'hooks/useEventTriggers';
import useOAuthLink from 'hooks/useOAuthLink';
import { findAllBulkOperations } from 'data/actions/bulkOperations';
import getGoogleClientId from 'helpers/getGoogleClientId';
import Verification from 'pages/authorization/verification';
import ExtensionUninstallFeedback from 'pages/extension/uninstall';
import useTransferTruemailUser from 'hooks/useTransferTruemailUser';
import WhiteLoading from 'components/whiteLoading';
import useRecentActivity from 'hooks/useRecentActivity';
import OAuthPage from 'pages/oauth';
import { getCards } from 'data/actions/cards';
import useFastSpring from 'hooks/useFastSpring';
import useFastSpringCallbacks from 'hooks/useFastSpring/useFastSpringCallbacks';
import SignUpLastStepLayout from 'routes/signUpLastStepLayout';
import ExtensionInstallHandler from 'pages/extension/install';
import AuthHandler from 'pages/authorization/authHandler';
import { sendLoginAnalytic } from 'helpers/googleAnalytics';
import { getDepartmentsOptions } from 'data/actions/search';
import checkChromeExtension from 'helpers/checkChromeExtension';
import { Z_INDEXES } from 'constants/styles';

const MainAppLayout = lazy(() => import('routes/mainAppLayout'));

const App = () => {
  const dispatch = useDispatch();

  const isAuth = useSelector(isAuthSelector);
  const isExtensionAuthActive = useSelector(extensionAuthActiveSelector);

  const {
    emailVerified,
    _id: loggedUserId,
    gaClientId,
    signUpFingerPrint,
    utmSource,
    createdAt,
    phoneVerified,
  } = useSelector(accountSelector);

  const workspaceId = useSelector(workspaceIdSelector);
  const showLastStep = useSelector(showLastStepSelector);
  const decodedToken = jwt.decode(localStorage.getItem('accessToken'));
  const phoneNotVerified = phoneVerified === false && decodedToken?.hasRiskScore === true && !decodedToken?.hasLimitedPlan;

  const [isDemoUser, setIsDemoUser] = useState(decodedToken?.email === DEMO_USER_ACCOUNT);

  const emailNotVerified = !emailVerified && decodedToken?.emailVerified === false;
  const showMainRoutes = !phoneNotVerified && !emailNotVerified;
  const emailVerifiedToken = get(decodedToken, 'emailVerified');

  useSwipeHandler({ swipes: ['Right'], dataAttr: 'close' });
  useKeyHandler({ keys: ['Enter'], dataAttr: 'form', blackList: ['TEXTAREA'] });
  useAffiliate();
  useOAuthLink();
  useTransferTruemailUser();

  useScript(process.env.REACT_APP_BLUESNAP_JS_URL);
  useStoreTriggers();
  useEventTriggers();
  useRecentActivity();
  useIntercom(isDemoUser);
  useFastSpringCallbacks();
  useFastSpring({});
  if (navigator.platform !== 'MacIntel') {
    const element = document.querySelector('html');
    element.className = 'runWin32';
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const path = window.location.pathname;
    if (queryParams.get('existed') || path.includes('creating')) {
      queryParams.delete('existed');
      const queryParamsStr = queryParams.toString();
      window.location.href = `${path.replace(/creating$/, 'all')}${queryParamsStr.length > 0 ? `?${queryParamsStr}` : ''}`;
    }

    if (queryParams.get('extensionAuth')) {
      dispatch(setExtensionAuth(true));
    }
  }, [dispatch]);

  useEffect(() => {
    setIsDemoUser(decodedToken?.email === DEMO_USER_ACCOUNT);
  }, [decodedToken]);

  useEffect(() => {
    if (emailVerifiedToken) {
      dispatch(getAccount());
      dispatch(getUserExtension());
      dispatch(getUserSettings(['userSettingsLoader']));
    }
  }, [dispatch, emailVerifiedToken]);

  useEffect(() => {
    const listener = window.addEventListener('storage', (e) => {
      if (get(e, 'key') === 'accessToken' && !get(e, 'newValue', false)) {
        dispatch(signOut());
      }
    });

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [dispatch]);

  useEffect(() => {
    if (isAuth) {
      dispatch(accountEventsSubscription());
    }

    if (isAuth && showMainRoutes) {
      dispatch(getAllNotifications({}));
      dispatch(getUserWorkspaces());
      dispatch(getRecentLists());
      dispatch(getFoldersRelation());
      dispatch(getUserCredits({}));
      dispatch(getAllProperties());
      dispatch(getSubscription());
      dispatch(findAllBulkOperations());
      dispatch(getWorkspaceLimits());
      dispatch(getCards());
      dispatch(getDepartmentsOptions({}));
    }
  }, [dispatch, isAuth, showMainRoutes, workspaceId]);

  useEffect(() => {
    sendLoginAnalytic({
      loggedUserId, utmSource, createdAt,
    });
  }, [loggedUserId, createdAt, utmSource]);

  useEffect(() => {
    const isTempAdminSession = localStorage.getItem('adminTempSession');

    if (loggedUserId && !signUpFingerPrint && !isTempAdminSession) {
      dispatch(setSignUpFingerPrint());
    }
  }, [dispatch, signUpFingerPrint, loggedUserId]);

  useEffect(() => {
    if (loggedUserId && !gaClientId) {
      try {
        const clientId = getGoogleClientId();
        if (clientId) {
          dispatch(updateAccount([{ gaClientId: clientId }]));
        }
      // eslint-disable-next-line no-empty
      } catch (err) {}
    }
  }, [loggedUserId, gaClientId, dispatch, utmSource]);

  useEffect(() => {
    const checkPlugin = async () => {
      const isPluginInstalled = await checkChromeExtension();
      dispatch(setPluginInformation({ installed: isPluginInstalled }));
    };
    checkPlugin();
  }, [dispatch]);

  const renderRoutes = () => {
    if (emailNotVerified) {
      return <EmailConfirmationLayout decodedToken={decodedToken} />;
    } if (phoneNotVerified) {
      return <PhoneVerificationLayout />;
    } if (showLastStep) {
      return <SignUpLastStepLayout />;
    } if (isExtensionAuthActive && isAuth) {
      return (
        <Switch>
          <Route path="/extension/auth" component={AuthHandler} />
          <Redirect to="/extension/auth" />
        </Switch>
      );
    } if (isAuth && isDemoUser) {
      return <Suspense fallback={<WhiteLoading height="100vh" width="100%" />}><MainAppLayout isDemoUser /></Suspense>;
    } if (isAuth && showMainRoutes) {
      return <Suspense fallback={<WhiteLoading height="100vh" width="100%" />}><MainAppLayout /></Suspense>;
    }
    return <LoginLayout />;
  };

  return (
    <EnvironmentProvider name="crm" experimentalFeatures={['phone']}>
      <ResizeProvider>
        <ThemeProvider zIndexes={{ popover: Z_INDEXES.POPOVER }}>
          <Router>
            {
              isAuth ? (
                <Switch>
                  <Route path="/uninstall">
                    <ExtensionUninstallFeedback />
                  </Route>
                  <Route path="/install">
                    <ExtensionInstallHandler />
                  </Route>
                </Switch>
              ) : (
                <Switch>
                  <Route path="/uninstall" component={() => { window.location.href = localStorage.getItem('lastUserEmail') ? `${process.env.REACT_APP_WEBSITE_URL}/uninstall?data=${Buffer.from(localStorage.getItem('lastUserEmail'), 'utf8').toString('base64')}` : `${process.env.REACT_APP_WEBSITE_URL}/uninstall`; return null; }} />
                  <Route path="/install" component={() => { window.location.href = `${window.location.host}/sign-up?extension=true`; return null; }} />
                </Switch>
              )
            }
            {
              !isAuth ? (
                <Route path="/verification" component={Verification} />
              ) : null
            }
            <Route path="/oauth/:source(google)/verify" component={OAuthPage} />
            {renderRoutes()}
            <MessageController />
          </Router>
        </ThemeProvider>
      </ResizeProvider>
    </EnvironmentProvider>
  );
};

export default App;
