import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
import { transparentize } from 'polished';
import * as CONSTANTS from '../../../constants/styles';

const fadeInAnimation = keyframes`${fadeIn}`;

export const UninstallContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);

  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    display: flex;
    flex-direction: column;
    margin-top: 101px;
    height: auto;
  }
  @media(max-width: ${CONSTANTS.TABLET_MAX_WIDTH}px) {
    display: flex;
    flex-direction: column;
    margin-top: 100px;

  }
`;

export const SubmitForm = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin-left: 50px;

  .ant-input{
    width: 505px;
    height: 120px;
  }

  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    .ant-input {
      width: 320px;
      height: 80px;
    }
    margin-left: auto;
    margin-right: auto;
    width: 320px;
  }

`;

export const Header = styled.div`
  display: flex;
  text-align: left;
  font-weight: 520;
  font-size: 24px;

  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    /* margin: 0 auto; */
    /* text-align: center; */
  }
`;

export const Text = styled.p`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${transparentize(0.15, CONSTANTS.COLORS.BLACK)};
  margin: 0;

  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    display: flex;
    justify-content: left;
    text-align: left;
  }

`;

export const ContentImg = styled.img`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  animation: 400ms ${fadeInAnimation};
  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    width: 400px;
  }
`;

export const RadioStyle = {
  display: 'flex',
  margin: '5px 5px 5px 0',
};

export const LogoForm = styled.div` 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 50px;

  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;
