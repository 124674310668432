import { get } from 'lodash';

export const updateTour = (data) => (dispatch) => {
  dispatch({
    type: 'UPDATE_TOUR',
    data,
  });
};

export const incrementUpdateTour = () => (dispatch, getState) => {
  const state = getState();
  const retryStep = get(state, 'tour.retryStep', 0);
  dispatch(updateTour({ retryStep: retryStep + 1 }));
};
