import styled from 'styled-components';

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #071427;
`;

export const Body = styled.div`
  padding: 1px;
  display: flex;
  flex-direction: column;

  .ic_close {
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 20px !important;
  }
`;

export const MarksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const MarkCommentsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 2px;
`;

export const MarkComment = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #979FA8;
`;

export const MarkItem = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) => (props.active ? '1px solid #1890FF' : '1px solid #DFE2E5')};
  border-radius: 100px;
  color: ${(props) => (props.active ? '#FFFFFF' : '#4F5964')};
  background: ${(props) => (props.active ? '#1890FF' : '#FFFFFF')};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  :hover {
    border: 1px solid #1890FF;
    color: ${(props) => (props.active ? '#FFFFFF' : '#1890FF')};
  }

  :active {
    transform: scale(0.9);
  }
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    height: 32px;
    width: 250px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
`;

export const RemindMeLater = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: underline;
  color: #979FA8;
  cursor: pointer;

  :active {
    transform: scale(0.9);
  }
`;
