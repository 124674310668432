import { isInteger } from 'lodash';

export function setLastWorkspaceId(email, workspaceId) {
  let lastWorkspaces = JSON.parse(localStorage.getItem('lastWorkspaceId'));
  if (!lastWorkspaces?.length) lastWorkspaces = [{ email, workspaceId }];
  if (!lastWorkspaces?.find((historyItem) => historyItem.email === email)) lastWorkspaces.push({ email, workspaceId });
  else {
    lastWorkspaces = lastWorkspaces.map((historyItem) => {
      if (historyItem?.email === email) historyItem.workspaceId = workspaceId;
      return historyItem;
    });
  }
  localStorage.setItem('lastWorkspaceId', JSON.stringify(lastWorkspaces));
}

export function getLastWorkspaceId(email) {
  if (isInteger(JSON.parse(localStorage.getItem('lastWorkspaceId')))) localStorage.removeItem('lastWorkspaceId');
  const lastWorkspaces = JSON.parse(localStorage.getItem('lastWorkspaceId'));
  let workspaceId = null;
  if (lastWorkspaces?.length) {
    workspaceId = lastWorkspaces?.find((historyItem) => historyItem?.email === email)?.workspaceId;
  }
  return workspaceId ? parseInt(workspaceId, 10) : null;
}
