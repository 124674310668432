import moment from 'moment';
import replace from 'lodash/replace';
import startCase from 'lodash/startCase';
import { useDispatch, useSelector } from 'react-redux';
import React,
{
  useState,
  useCallback,
} from 'react';
import {
  Modal,
} from 'antd';

import {
  Button,
  Select,
  Option,
} from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { exportSimpleData } from 'data/actions/export';
import { workspaceIdSelector } from 'data/selectors/user';
import { exportDataLoaderSelector } from 'data/selectors/loading';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import {
  ModalBody,
  SelectLabel,
} from '../exportModal/styles';

import {
  ModalTitle,
  CloseIcon,
  Footer,
} from '../baseModal/styles';

const options = ['CSV', 'XLSX'];

const SimpleExportModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const workspaceId = useSelector(workspaceIdSelector);
  const loading = useSelector(exportDataLoaderSelector);
  const modalOptions = useSelector(modalOptionsSelector);

  const [format, setFormat] = useState(localStorage.getItem('lastExportFormat') ?? 'CSV');

  const handleClose = useCallback(() => dispatch(toggleModal('simple_export', false)), [dispatch]);

  const handleExport = useCallback(() => {
    localStorage.setItem('lastExportFormat', format);
    const date = moment().format('YYYY_MM_DD');
    const name = replace(`${startCase(modalOptions.kind)}_${workspaceId}_${date}`, / /g, '_');
    dispatch(exportSimpleData({
      ...modalOptions,
      name,
      fileFormat: format.toLowerCase(),
    }, () => {
      handleClose();
      dispatch(toggleMessage('info', { header: 'Preparing the file, you’ll be notified' }));
    }));
  }, [dispatch, handleClose, format, modalOptions, workspaceId]);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Export
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={420}
      footer={false}
      onCancel={handleClose}
    >
      <ModalBody>
        <SelectLabel>
          File format
        </SelectLabel>
        <Select size="default" onChange={setFormat} value={format} defaultValue="CSV">
          {options.map((item, index) => <Option key={`export-options-${index + 1}`} value={item}>{item}</Option>)}
        </Select>
      </ModalBody>
      <Footer>
        <Button
          data-form="true"
          type="primary"
          loading={loading}
          onClick={handleExport}
        >
          Confirm
        </Button>
        <Button type="normal" onClick={handleClose}>Cancel</Button>
      </Footer>

    </Modal>
  );
};

export default SimpleExportModal;
