import React, { useState, useEffect, useMemo } from 'react';
import {
  Modal,
} from 'antd';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { useDispatch, useSelector } from 'react-redux';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';

import {
  Button,
  Checkbox,
  Search,
  Icon,
} from 'components/common';
import * as CONSTANTS from 'constants/styles';
import { toggleModal } from 'data/actions/modals';
import { renameLabel } from 'helpers/renameLabel';
import { toggleMessage } from 'data/actions/message';
import { SOURCE_TYPE } from 'data/types/source.types';
import { searchContacts } from 'data/actions/contacts';
import { updateWorkspaceSettings } from 'data/actions/user';
import { propertiesSelector } from 'data/selectors/properties';
import { updateSequenceSettings } from 'data/actions/sequences';
import { columnsConfigurationSelector } from 'data/selectors/user';
import { engagedAudienceColumnsSelector } from 'data/selectors/sequences';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';

import {
  Container,
  Half,
  PropertyItem,
  PropertiesContainer,
  PropertyGroup,
  SelectedItem,
  Footer,
  PropertiesWrapper,
  ModalWrapper,
  SelectedPropertiesContainer,
  ParagraphText,
  CheckboxText,
  CloseIconStyle,
  Label,
  CreatePropertyLink,
  ScrollContainer,
} from './styles';

import {
  ModalTitle,
  CloseIcon,
} from '../modals/baseModal/styles';

import {
  SearchPropertiesForm,
} from '../addCompaniesToContactBar/styles';

const excludeCompanyFields = ['technologies', 'keywords'];
const excludedFields = ['Company technologies', 'Company keywords', 'firstName', 'lastName', 'name', 'Associated Company Linkedin', 'Keywords', 'Technologies', 'Associated Company Keywords', 'Associated Company Technologies'];
const excludedFieldsForSequence = [...excludedFields, 'Description', 'Postal Code', 'Technologies', 'Name', 'Linkedin'];

const PropertyListEditor = () => {
  const dispatch = useDispatch();

  const properties = useSelector(propertiesSelector);
  const currentColumns = useSelector(columnsConfigurationSelector);
  const sequenceColumns = useSelector(engagedAudienceColumnsSelector);

  const [dataSource, setDataSource] = useState([]);
  const { visible } = useSelector(modalsSelector);
  const { isSequence, maxColumns, sourceType = SOURCE_TYPE.CONTACT } = useSelector(modalOptionsSelector);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [searchName, handleChange] = useState('');
  const configurationSourceType = sourceType === SOURCE_TYPE.CONTACT ? 'contacts' : 'companies';

  const fullProperties = useMemo(() => {
    const companyPropertiesForContacts = sourceType === SOURCE_TYPE.CONTACT ? properties.filter((property) => property.sourceType === SOURCE_TYPE.COMPANY).map((property) => ({
      _id: property._id,
      label: property.label.startsWith('Company') ? property.label : `Company ${property.label}`,
      name: `associated_company_${property.name}`,
      sourceType: SOURCE_TYPE.COMPANY,
      group: {
        name: 'Company properties',
      },
      virtualRequired: !excludeCompanyFields.includes(property.name),
      default: property?.default,
    })) : [];
    const virtualProperties = isSequence ? [
      {
        label: 'Position',
        name: 'position',
        sourceType: SOURCE_TYPE.COMPANY,
      },
    ] : [
      {
        label: 'Owner',
        name: 'owner',
        sourceType: SOURCE_TYPE.CONTACT,
      },
      {
        label: 'Owner',
        name: 'owner',
        sourceType: SOURCE_TYPE.COMPANY,
      },
    ];
    return [...properties, ...companyPropertiesForContacts, ...virtualProperties];
  }, [sourceType, properties, isSequence]);

  const handleClose = () => dispatch(toggleModal('edit_columns', false));

  const hideColumns = () => {
    if (isSequence) {
      setSelectedColumns(['position']);
    } else {
      setSelectedColumns(['name']);
    }
  };

  useEffect(() => {
    if (visible) {
      if (isSequence) {
        setSelectedColumns(sequenceColumns);
      } else if (currentColumns && visible && currentColumns[configurationSourceType]) {
        setSelectedColumns(currentColumns[configurationSourceType].map((item) => get(item, 'propertyName')));
      }
    }
  }, [isSequence, sequenceColumns, currentColumns, visible, sourceType, configurationSourceType]);

  useEffect(() => {
    const data = fullProperties.filter((item) => renameLabel(get(item, 'label', ''))
      .toLowerCase()
      .startsWith(searchName));
    setDataSource(data.filter((item) => ((sourceType === item.sourceType && !item.hidden) || item.virtualRequired) && !(isSequence ? excludedFieldsForSequence : excludedFields).includes(renameLabel(item.name))));
  }, [isSequence, searchName, sourceType, fullProperties]);

  const getPropertyLabel = (propertyName) => get(fullProperties.find((prop) => prop.name === propertyName), 'label', startCase(propertyName));
  const getPropertyIsDefault = (propertyName) => get(fullProperties.find((prop) => prop.name === propertyName), 'default');

  const getUpdatedRequest = () => {
    const columnsConfiguration = {
      ...currentColumns,
      [configurationSourceType]: selectedColumns.map((item) => ({ propertyName: item })),
    };
    return { columnsConfiguration };
  };

  const updateColumns = (column, value) => {
    let updated = [...selectedColumns];
    if (value) {
      if (!updated.includes(column)) updated.push(column);
    } else {
      updated = updated.filter((item) => item !== column);
    }
    setSelectedColumns(updated);
  };

  const groupedProperties = dataSource.reduce((result, item) => {
    const itemGroupName = get(item, 'group.name');
    let groupName = itemGroupName;
    if (!groupName) {
      groupName = SOURCE_TYPE.CONTACT === item.sourceType ? 'Contact properties' : 'Company properties';
    }
    const prev = result[groupName] || [];
    result[groupName] = [...prev, item];
    return result;
  }, {});

  const groups = Object.keys(groupedProperties);
  const groupsProperties = Object.values(groupedProperties);

  const onDragEnd = (result) => {
    const { destination, source } = result;
    const newSelectedColumns = [...selectedColumns];

    if (!destination) return;
    if (
      destination.droppableId === source.droppableId
      && destination.index === source.index
    ) return;

    newSelectedColumns.splice(source.index, 1);
    newSelectedColumns.splice(destination.index, 0, selectedColumns[source.index]);
    setSelectedColumns(newSelectedColumns);
  };

  return (
    <ModalWrapper>
      <Modal
        visible={visible}
        title={(
          <ModalTitle>
            Choose which columns you see
            <CloseIcon
              onClick={handleClose}
              className="ic_close"
            />
          </ModalTitle>
        )}
        width={760}
        footer={false}
        bodyStyle={{ padding: '24px 0px' }}
        closable={false}
        onCancel={handleClose}
      >
        <Container>
          <Half>
            <Search
              onChange={(e) => handleChange(e.target.value.toLowerCase())}
              style={SearchPropertiesForm}
              placeholder="Search properties"
              autoFocus
            />
            <PropertiesWrapper>
              {groups.map((group, index) => (
                <PropertiesContainer key={`property_group_item_${group})}`}>
                  <PropertyGroup>{group}</PropertyGroup>
                  {
                    groupsProperties[index].map((property) => (
                      <PropertyItem key={`property_list_item_${get(property, '_id')}`}>
                        <Checkbox
                          disabled={['name'].includes(get(property, 'name'))}
                          style={CheckboxText}
                          checked={selectedColumns.includes(get(property, 'name'))}
                          onChange={(e) => {
                            if (maxColumns && selectedColumns.length === maxColumns) {
                              dispatch(toggleMessage('error', { header: `You can't choose more than ${maxColumns}` }));
                            } else {
                              updateColumns(get(property, 'name'), e.target.checked);
                            }
                          }}
                        >
                          {renameLabel(get(property, 'label'), get(property, 'default'))}
                        </Checkbox>
                      </PropertyItem>
                    ))
                  }
                </PropertiesContainer>
              ))}
            </PropertiesWrapper>
            {
              !isSequence && (
                <ParagraphText>
                  {'Don\'t see the property you\'re looking for? '}
                  <CreatePropertyLink onClick={() => {
                    dispatch(toggleModal('create_property_preview', true));
                  }}
                  >
                    {' '}
                    Create a property
                  </CreatePropertyLink>
                </ParagraphText>
              )
            }
          </Half>
          <Half>
            <Label>
              Selected columns (
              {selectedColumns.length}
              )
            </Label>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="properties-column-1">
                {(droppableProvided) => (
                  <SelectedPropertiesContainer
                    ref={droppableProvided.innerRef}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...droppableProvided.droppableProps}
                  >
                    <ScrollContainer>
                      {selectedColumns.map((item, index) => (
                        (item === 'name' || (isSequence && item === 'position')) ? (
                          <SelectedItem key={`selected_item_${item}`}>
                            <div style={{ marginLeft: '22px' }}>
                              {renameLabel(getPropertyLabel(item), getPropertyIsDefault(item))}
                              {' '}
                            </div>
                          </SelectedItem>
                        ) : (
                          <Draggable
                            prefix={(<Icon size="small" type="ic-drag-indicator" className="ic_drag_indicator" />)}
                            key={`draggble_item_${item}`}
                            draggableId={`draggble_item_${item}`}
                            index={index}
                          >
                            {(draggableProvided, snapshot) => {
                              const droppableStyle = {
                                ...draggableProvided.draggableProps.style,
                                borderColor: snapshot.isDragging && CONSTANTS.COLORS.PRIMARY,
                              };

                              return (
                                <SelectedItem
                                  key={`selected_item_${item}`}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...draggableProvided.draggableProps}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...draggableProvided.dragHandleProps}
                                  ref={draggableProvided.innerRef}
                                  style={droppableStyle}
                                  isDraggable
                                >
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon
                                      size="small"
                                      style={{ marginRight: '7px' }}
                                      alt=""
                                      type="ic-drag-indicator"
                                      className="ic_drag_indicator"
                                    />
                                    {renameLabel(getPropertyLabel(item), getPropertyIsDefault(item))}
                                  </div>
                                  <Icon
                                    size="small"
                                    type="ic-close"
                                    className="selected-item-close"
                                    style={CloseIconStyle}
                                    onClick={() => { updateColumns(item, false); }}
                                  />
                                </SelectedItem>
                              );
                            }}
                          </Draggable>
                        )
                      ))}
                      {droppableProvided.placeholder}
                    </ScrollContainer>
                  </SelectedPropertiesContainer>
                )}
              </Droppable>
            </DragDropContext>
          </Half>
        </Container>
        <Footer>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              if (isSequence) {
                dispatch(updateSequenceSettings({ engagedAudienceColumns: selectedColumns }));
              } else {
                dispatch(updateWorkspaceSettings(
                  getUpdatedRequest(),
                  (data) => {
                    dispatch(searchContacts(data));
                  },
                ));
              }
              handleClose();
            }}
          >
            Save
          </Button>
          <Button
            onClick={handleClose}
            type="normal"
            size="large"
          >
            Cancel
          </Button>
          <CreatePropertyLink onClick={hideColumns}>Hide all columns</CreatePropertyLink>
        </Footer>

      </Modal>
    </ModalWrapper>
  );
};

export default PropertyListEditor;
