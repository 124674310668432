import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

import { deletedPropertiesSourceTypeSelector, propertiesSourceTypeSelector } from 'data/selectors/properties';
import { renameLabel } from 'helpers/renameLabel';

const useCheckProperty = ({ sourceType, label }) => {
  const properties = useSelector(propertiesSourceTypeSelector(sourceType, true));
  const deleteProperties = useSelector(deletedPropertiesSourceTypeSelector(sourceType));

  const [existedProperty, setExistedProperty] = useState(false);

  useEffect(() => {
    if (label) {
      const isPropertyExisted = properties.some((item) => renameLabel(item.label) === label || item.label === label) || deleteProperties.some((item) => item.label === label);
      setExistedProperty(isPropertyExisted);
    } else {
      setExistedProperty(false);
    }
  }, [properties, deleteProperties, label]);

  return existedProperty;
};

export default useCheckProperty;
