import React from 'react';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import * as CONSTANTS from 'constants/styles';

import { Icon } from 'components/common';
import { renameLabel } from 'helpers/renameLabel';
import {
  SelectedItem,
  SelectedPropertiesContainer,
  CloseIconStyle,
  Label,
  ScrollContainer,
  Half,
} from '../../propertyListEditor/styles';

const SelectedColumns = ({
  selectedColumns,
  updateColumns,
  setSelectedColumns,
  getPropertyLabel,
  getPropertyIsDefault,
}) => {
  const onDragEnd = (result) => {
    const { destination, source } = result;
    const newSelectedColumns = [...selectedColumns];

    if (!destination) return;
    if (
      destination.droppableId === source.droppableId
      && destination.index === source.index
    ) return;

    newSelectedColumns.splice(source.index, 1);
    newSelectedColumns.splice(destination.index, 0, selectedColumns[source.index]);
    setSelectedColumns(newSelectedColumns);
  };

  return (
    <Half>
      <Label>
        Selected columns (
        {selectedColumns.length}
        )
      </Label>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="properties-column-1">
          {(droppableProvided) => (
            <SelectedPropertiesContainer
              ref={droppableProvided.innerRef}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...droppableProvided.droppableProps}
            >
              <ScrollContainer>
                {selectedColumns.map((item, index) => (
                  <Draggable
                    prefix={(<Icon size="small" type="ic-drag-indicator" className="ic_drag_indicator" />)}
                    key={`draggble_item_${item.propertyName}_${item.sourceType}`}
                    draggableId={`draggble_item_${item.propertyName}`}
                    index={index}
                  >
                    {(draggableProvided, snapshot) => {
                      const droppableStyle = {
                        ...draggableProvided.draggableProps.style,
                        borderColor: snapshot.isDragging && CONSTANTS.COLORS.PRIMARY,
                      };

                      return (
                        <SelectedItem
                          key={`selected_item_${item.propertyName}_${item.sourceType}`}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...draggableProvided.draggableProps}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...draggableProvided.dragHandleProps}
                          ref={draggableProvided.innerRef}
                          style={droppableStyle}
                          isDraggable
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon
                              size="small"
                              style={{ marginRight: '7px' }}
                              type="ic-drag-indicator"
                              className="ic_drag_indicator"
                            />
                            {renameLabel(getPropertyLabel(item.propertyName, item.sourceType), getPropertyIsDefault(item.propertyName))}
                          </div>

                          <Icon
                            size="small"
                            type="ic-close"
                            className="selected-item-close"
                            style={CloseIconStyle}
                            onClick={() => { updateColumns(item, false); }}
                          />
                        </SelectedItem>
                      );
                    }}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </ScrollContainer>
            </SelectedPropertiesContainer>
          )}
        </Droppable>
      </DragDropContext>
    </Half>
  );
};

export default SelectedColumns;
