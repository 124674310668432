import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Button,
} from 'antd';

import ListSelect from 'components/listSelect';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import {
  listAddContacts,
} from 'data/actions/lists';

import {
  CloseIcon,
  ModalTitle,
  Label,
  Footer,
} from '../baseModal/styles';

const AddToStaticListModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const {
    tourListId,
    setSelectedRowKeys,
    selectedItems,
    filters,
    search,
    selectAll,
  } = useSelector(modalOptionsSelector);

  const [selectedListId, selectListId] = useState([]);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    selectListId([]);
  }, [visible]);

  useEffect(() => {
    if (tourListId) selectListId(tourListId);
  }, [tourListId]);

  const onSuccess = (data) => {
    if (!data.pending) {
      const countUpdatedItems = get(data, 'countUpdatedItems');
      if (countUpdatedItems) {
        dispatch(toggleMessage('success', { header: 'Contacts were added to list(s)!' }));
      }
    }
    if (setSelectedRowKeys) setSelectedRowKeys([]);
  };

  const onChange = (value) => {
    selectListId(value);
  };

  const handleClose = () => dispatch(toggleModal('add_to_static_list', false));

  const selectedContactsLength = get(selectedItems, 'length', 0);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          {
            selectedContactsLength > 1
              ? `Add ${selectedContactsLength} contacts to a list`
              : 'Add to a list'
          }
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      width={450}
      closable={false}
      footer={false}
      onCancel={handleClose}
    >
      <>
        <Label style={{ marginBottom: '20px', display: 'block' }}>
          {
            selectedContactsLength > 1
              ? `Add ${selectedContactsLength} contacts to this list:`
              : 'Add selected contact to this list:'
          }
        </Label>
        <ListSelect
          size="large"
          mode={false}
          maxTagCount={2}
          value={selectedListId}
          onSelectedListsChange={onChange}
          isOpened={isOpened}
          setIsOpened={setIsOpened}
        />
      </>
      <Footer>
        <Button
          data-form={!selectedListId ? 'false' : 'true'}
          type="primary"
          data-tour="create-list-tour-step-6"
          size="large"
          onClick={() => {
            if (selectedListId) {
              dispatch(listAddContacts({
                listIds: selectedListId,
                contactsIds: selectedItems,
                onSuccess,
                filters,
                search,
                selectAll,
              }));
            }
            handleClose();
          }}
          disabled={!selectedListId}
        >
          Add
        </Button>
        <Button size="large" type="normal" onClick={handleClose}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default AddToStaticListModal;
