import React from 'react';
import { useDispatch } from 'react-redux';

import { deleteUser } from 'data/actions/user';
import { toggleModal } from 'data/actions/modals';
import { ConfirmationModal } from 'components/common';
import useDeleteAccountMessage from 'hooks/messageTemplates/useDeleteAccountMessage';

const ConfirmationDeleteAccountModal = () => {
  const dispatch = useDispatch();

  const deleteAccountMessage = useDeleteAccountMessage();

  const onSubmit = () => dispatch(deleteUser(deleteAccountMessage));

  const handleClose = () => dispatch(toggleModal('confirmation_delete_account', false));

  return (
    <ConfirmationModal
      title="Delete account"
      type="danger"
      label="Are you sure you want to finish using the GetProspect tools?"
      onClose={handleClose}
      onSubmit={onSubmit}
    />
  );
};

export default ConfirmationDeleteAccountModal;
