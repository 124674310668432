export const emailProviders = Object.freeze({
  '@gmail.com': 'https://mail.google.com',
  '@outlook.com': 'https://login.live.com/',
  '@hotmail.com': 'https://login.live.com/',
  '@zohomail.eu': 'https://accounts.zoho.eu',
  '@aol.com': 'https://mail.aol.com',
  '@mail.com': 'https://www.mail.com/int',
  '@yahoo.com': 'https://login.yahoo.com/',
  '@icloud.com': 'https://www.icloud.com/mail',
  '@gmx.com': 'https://www.gmx.com/',
  '@gmx.us': 'https://www.gmx.com/',
});
