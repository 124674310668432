import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
`;

export const Form = styled.div`
    width: 320px;
    height: 550px;
    background-color: #ffffff;
    border: 1px solid transparent;
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    text-align: center;
`;

export const Label = styled.div`
    margin: 10px 0 5px 0;
    text-align: left;
`;

export const Text = styled.div`
  margin-left: 5px;
`;
export const PasswordChecked = styled.div`
display: flex;
align-items: center;
padding: 5px;
`;
