import get from 'lodash/get';
import { createSelector } from 'reselect';

import { SOURCE_TYPE } from 'data/types/source.types';
import {
  contactsInsightsSelector,
  initialRequestInsightContactsSelector,
} from './contacts';
import {
  companiesInsightsSelector,
  initialRequestInsightCompaniesSelector,
} from './companies';

const baseSearchSelector = (state) => get(state, 'search', {});

export const companiesLimitSelector = createSelector(
  baseSearchSelector,
  (search) => get(search, 'limits.companies', { availableCompanies: 0, limitCompanies: 0 }),
);

const baseSearchesSelector = (state) => get(state, 'search.searches', []);
export const searchesSelector = createSelector(
  baseSearchesSelector,
  (searches) => searches,
);

const baseLocationsSelector = (state) => get(state, 'search.locations', []);
export const locationsSelector = createSelector(
  baseLocationsSelector,
  (locations) => locations,
);

const basePositionsSelector = (state) => get(state, 'search.positions', []);
export const positionsSelector = createSelector(
  basePositionsSelector,
  (positions) => ({ data: positions }),
);

const baseIndustriesSelector = (state) => get(state, 'search.industries', []);
export const industriesSelector = createSelector(
  baseIndustriesSelector,
  (industries) => industries,
);

const baseFiltersSelector = (state) => get(state, 'search.filters', []);
export const getFiltersSelector = (property) => createSelector(
  baseFiltersSelector,
  (filters) => filters.find((filter) => filter.property === property),
);

const baseFilterOptionsSelector = (state) => get(state, 'search.filterOptions', {});
export const getFilterOptionsSelector = (property) => createSelector(
  baseFilterOptionsSelector,
  (options) => get(options, property, []),
);

const baseDefaultFilterOptionsSelector = (state) => get(state, 'search.filterWithDefaultOptions', {});
export const getExistedDefaultFilterOptions = (property) => createSelector(
  baseDefaultFilterOptionsSelector,
  (isExist) => get(isExist, property, false),
);

const baseFilterAutocompleteOptionsSelector = (state) => get(state, 'search.autocompleteFilterOptions', {});
export const getFilterAutocompleteOptionsSelector = (property) => createSelector(
  baseFilterAutocompleteOptionsSelector,
  (options) => get(options, property),
);

export const getFirstMatchOptonSelector = (property) => createSelector(
  baseFilterOptionsSelector,
  (options) => get(options, property)?.[0],
);

export const searchFiltersSelector = () => createSelector(
  baseFiltersSelector,
  (filters) => filters,
);

const baseCountriesSelector = (state) => get(state, 'search.countries', {});
export const countriesSelector = createSelector(
  baseCountriesSelector,
  (countries) => countries,
);

export const sourceTypeInsightsSelector = createSelector(
  baseSearchSelector,
  (insights) => insights.sourceType,
);

export const selectedContactTabSelector = createSelector(
  baseSearchSelector,
  (insights) => insights.selectedContactTab,
);

export const selectedCompanyTabSelector = createSelector(
  baseSearchSelector,
  (insights) => insights.selectedCompanyTab,
);

export const searchesHistorySelector = createSelector(
  baseSearchSelector,
  (insights) => insights.searchesHistory,
);

export const dashboardSearchesHistorySelector = createSelector(
  baseSearchSelector,
  (insights) => insights.dashboardSearchesHistory,
);

export const dashboardSearchesSelector = createSelector(
  baseSearchSelector,
  (insights) => insights.dashboardSearches,
);

export const initialRequestDashboardSearchesSelector = createSelector(
  dashboardSearchesSelector,
  (dashboardSearches) => get(dashboardSearches, 'initialRequest', false),
);

export const initialRequestDashboardSearchesHistorySelector = createSelector(
  dashboardSearchesHistorySelector,
  (dashboardSearchesHistory) => get(dashboardSearchesHistory, 'initialRequest', false),
);

export const insightsSelector = (sourceType) => {
  if (sourceType === SOURCE_TYPE.CONTACT) {
    return contactsInsightsSelector;
  } if (sourceType === SOURCE_TYPE.COMPANY) {
    return companiesInsightsSelector;
  }
  throw new Error('Insights: An unknown source type');
};

export const insightInitialRequestSelector = (sourceType) => {
  if (sourceType === SOURCE_TYPE.CONTACT) {
    return initialRequestInsightContactsSelector;
  } if (sourceType === SOURCE_TYPE.COMPANY) {
    return initialRequestInsightCompaniesSelector;
  }
  throw new Error('Insights initial request: An unknown source type');
};

const baseInitialRequestSearchesSelector = (state) => get(state, 'search.searches.initialRequest', false);
export const initialRequestSearchesSelector = createSelector(
  baseInitialRequestSearchesSelector,
  (initialRequest) => initialRequest,
);

const baseInitialRequestSearchesHistorySelector = (state) => get(state, 'search.searchesHistory.initialRequest', false);
export const initialRequestSearchesHistorySelector = createSelector(
  baseInitialRequestSearchesHistorySelector,
  (initialRequest) => initialRequest,
);

export const initialRequestAllSeachesSelector = (type) => {
  switch (type) {
    case 'contact':
    case 'company':
      return initialRequestSearchesSelector;
    default:
      return initialRequestSearchesHistorySelector;
  }
};

export const searchImportFilterPreprocess = createSelector(
  baseSearchSelector,
  (search) => get(search, 'searchImport'),
);

export const searchFiltersTotalCount = createSelector(
  baseSearchSelector,
  (search) => get(search, 'totalFilters', 0),
);

export const searchOptionsSelector = createSelector(
  baseSearchSelector,
  (search) => search.searchOptions,
);

export const initialRequestSearchesPreviewBarSelector = createSelector(
  initialRequestSearchesSelector,
  initialRequestSearchesHistorySelector,
  (initialRequest1, initialRequest2) => initialRequest1 || initialRequest2,
);

export const initRecentActivitiesSelector = createSelector(
  baseSearchSelector,
  (search) => search.initRecentActivities,
);

export const shortSearchesSelector = createSelector(
  baseSearchSelector,
  (search) => search.shortSearches,
);

export const shortSearchesHistorySelector = createSelector(
  baseSearchSelector,
  (search) => search.shortSearchesHistory,
);

export const initialRequestShortSearchesHistorySelector = createSelector(
  baseSearchSelector,
  (search) => get(search, 'shortSearchesHistory.initialRequest', false),
);

export const initialRequestShortSavedSearchesSelector = createSelector(
  baseSearchSelector,
  (search) => get(search, 'shortSearches.initialRequest', false),
);

export const lastUserSearchIdSelector = createSelector(
  baseSearchSelector,
  (search) => get(search, 'lastUserSearchId'),
);

export const initialRequestShortSearchesSelector = createSelector(
  initialRequestShortSearchesHistorySelector,
  initialRequestShortSavedSearchesSelector,
  (initialRequest1, initialRequest2) => initialRequest1 || initialRequest2,
);

export const isEmptySearchesPanelSelector = createSelector(
  initialRequestShortSearchesSelector,
  shortSearchesSelector,
  shortSearchesHistorySelector,
  (initialRequest, searches, searchHistories) => !initialRequest && searches.totalItems === 0 && searchHistories.totalItems === 0,
);

export const fastSearchDataSelector = createSelector(
  baseSearchSelector,
  (search) => search?.fastSearchData,
);

export const searchErrorSelector = createSelector(
  baseSearchSelector,
  (search) => search.searchError,
);
