import styled from 'styled-components';

export const Footer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  button {
    margin-right: 10px;
  }
`;

export const ButtonStyle = {
  fontWeight: 500,
  fontSize: 13,
};
