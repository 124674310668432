import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { toggleMessage } from 'data/actions/message';

const useDeleteAccountMessage = () => {
  const dispatch = useDispatch();

  const showMessage = useCallback(() => {
    dispatch(toggleMessage('success', { header: 'Your account has been deleted', text: 'We respect your decision and thank you for your time with us.' }));
  }, [dispatch]);

  return showMessage;
};

export default useDeleteAccountMessage;
