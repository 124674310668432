import { useEffect } from 'react';

import { ACCOUNT_EVENTS, EVENTS_DATA } from 'data/types/event.types';
import useSubscriptionMessage from './messageTemplates/useSubscriptionMessage';

const useEventTriggers = () => {
  const showSubscriptionMessage = useSubscriptionMessage();

  useEffect(() => {
    function handleEvent({ detail: event }) {
      const { type, data: eventData } = event;
      const data = JSON.parse(eventData);
      switch (type) {
        case ACCOUNT_EVENTS.SUBSCRIPTION_EVENT: {
          showSubscriptionMessage(data);
          break;
        }
        default: break;
      }
    }
    window.addEventListener(EVENTS_DATA, handleEvent);
    return () => window.removeEventListener(EVENTS_DATA, handleEvent);
  }, [showSubscriptionMessage]);
};

export default useEventTriggers;
