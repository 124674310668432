import get from 'lodash/get';
import { Switch } from 'antd';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { Flex, Label } from '@newlab-io/getprospect-react-components/lib/ui';
import { Tabs } from '@newlab-io/getprospect-react-components/lib/components';

import { Button } from 'components/common';
import useTinyForm from 'hooks/useTinyForm';
import engineProxy from 'helpers/liquid.engine';
import { toggleModal } from 'data/actions/modals';
import useGoogleOauth from 'hooks/useGoogleOauth';
import { removeEmpty } from 'helpers/objectHelpers';
import { toggleMessage } from 'data/actions/message';
import { ACCOUNT_STATUS } from 'data/types/user.types';
import Property from 'components/entityInfo/common/properties/property';
import { ButtonStyle, Footer } from 'components/createContactBar/styles';
import useUpdateAccountStatus from 'hooks/sequences/useUpdateAccountStatus';
import { updateConnectedAccountLoadingSelector } from 'data/selectors/loading';
import { refreshConnectedAccount, updateConnectedAccount } from 'data/actions/integrations';
import { updateSequenceAccount, updateSequenceSettingsRemotely } from 'data/actions/sequences';
import {
  Border,
  LabelContainer, PropertyContainer, TabContainer, TabsContainer,
} from './styles';
import StatusValidation from './statusValidation';
import Signature from './signature';

const Form = ({
  baseTab,
  emailAccount,
  sequenceId,
  connectedAccounts,
  setAccountId,
}) => {
  const dispatch = useDispatch();

  const loading = useSelector(updateConnectedAccountLoadingSelector);

  const { connectGmailAccount } = useGoogleOauth();

  const filteredConnectedAccounts = connectedAccounts.filter((account) => !get(account, 'smtp.error') && !get(account, 'imap.error'));

  const tabs = [{ label: 'Settings', value: 'settings' }];
  if (!emailAccount?.accountId) {
    tabs.push({ label: 'SMTP and IMAP', value: 'smtp_imap' });
  }

  const isValidSpf = get(emailAccount, 'validSpf');
  const isValidDkim = get(emailAccount, 'validDkim');

  const smtpError = get(emailAccount, 'smtp.error');
  const imapError = get(emailAccount, 'imap.error');

  const previousSmtpPasswordRef = useRef(get(emailAccount, 'smtp.password'));
  const previousImapPasswordRef = useRef(get(emailAccount, 'imap.password'));

  const [tab, setTab] = useState(() => {
    if (baseTab) return baseTab;
    return tabs.length === 2 && (smtpError || imapError) ? 'smtp_imap' : 'settings';
  });

  const closePreviewBar = () => dispatch(toggleModal('email_account_preview_bar', false));

  const validTextWithVariables = useCallback((customMessage) => (value) => {
    try {
      engineProxy.parseAndRenderSync(value || '');
      return true;
    } catch (err) {
      return customMessage ?? err.message;
    }
  }, []);

  const dynamicSchema = useMemo(() => {
    const schema = {
      name: {
        value: get(emailAccount, 'name'),
        conditions: [
          {
            label: 'Must contain value',
            condition: Boolean,
          },
        ],
      },
      dailyLimit: {
        value: get(emailAccount, 'dailyLimit'),
        conditions: [
          {
            label: 'Must contain value',
            condition: Boolean,
          },
        ],
      },
      signature: {
        value: get(emailAccount, 'signature'),
        conditions: [
          {
            condition: validTextWithVariables(),
          },
        ],
      },
      domainTracker: {
        value: get(emailAccount, 'domainTracker'),
      },
      email: {
        value: get(emailAccount, 'email'),
      },
    };
    if (!emailAccount?.accountId) {
      schema.smtpLogin = {
        value: get(emailAccount, 'smtp.login', ''),
      };
      schema.smtpPassword = {
        value: get(emailAccount, 'smtp.password', ''),
      };
      schema.smtpHost = {
        value: get(emailAccount, 'smtp.host', ''),
      };
      schema.smtpPort = {
        value: get(emailAccount, 'smtp.port', ''),
      };
      schema.imapLogin = {
        value: get(emailAccount, 'imap.login', ''),
      };
      schema.imapPassword = {
        value: get(emailAccount, 'imap.password', ''),
      };
      schema.imapHost = {
        value: get(emailAccount, 'imap.host', ''),
      };
      schema.imapPort = {
        value: get(emailAccount, 'imap.port', ''),
      };
    }
    return schema;
  }, [emailAccount, validTextWithVariables]);

  const {
    data,
    errors,
    handleChange,
    handleSubmit,
    isDisabledForm,
  } = useTinyForm({
    schema: dynamicSchema,
    onSubmit: (result) => {
      if (!emailAccount?.accountId) {
        let { imapPassword } = result.data;
        if (previousSmtpPasswordRef.current !== result.data.smtpPassword && previousImapPasswordRef.current === result.data.imapPassword) {
          imapPassword = result.data.smtpPassword;
        }
        result.data.smtp = {
          login: result.data.smtpLogin,
          password: result.data.smtpPassword,
          host: result.data.smtpHost,
          port: result.data.smtpPort,
        };
        result.data.imap = {
          login: result.data.imapLogin,
          password: imapPassword,
          host: result.data.imapHost,
          port: result.data.imapPort,
        };
        Object.keys(result.data).forEach((key) => {
          if ((key !== 'smtp' && key.startsWith('smtp')) || (key !== 'imap' && key.startsWith('imap'))) {
            delete result.data[key];
          }
        });
      }
      dispatch(updateConnectedAccount({
        accountId: emailAccount._id,
        body: result.data,
        onSuccess: ({ status }) => {
          if (result.data.dailyLimit > 250) {
            result.setData({ target: { name: 'dailyLimit', value: 250 } });
            dispatch(toggleMessage('error', { header: "Daily limit can't be greater than 250" }));
          }
          if (status === ACCOUNT_STATUS.ACTIVE) {
            setTab('settings');
            if (sequenceId) {
              dispatch(updateSequenceSettingsRemotely({
                sequenceId,
                data: { account: emailAccount._id },
                onSuccess: () => {
                  dispatch(updateSequenceAccount({ sequenceId, account: emailAccount }));
                },
              }));
            }
          }
        },
        onFailed: (err) => {
          const message = get(err, 'response.data.message');
          if (Array.isArray(message)) {
            message.forEach(({ field, reason }) => {
              result.setErrors(field, reason);
            });
          } else {
            const _errors = get(err, 'response.data.errors');
            if (Array.isArray(_errors)) {
              _errors.forEach(({ title, details }) => {
                result.setErrors(title, details);
              });
            }
          }
        },
      }));
    },
  });

  const equalsAccount = useMemo(() => {
    const pickedProperties = ['email', 'name', 'signature', 'domainTracker', 'dailyLimit'];
    if (!emailAccount?.accountId) {
      pickedProperties.push('smtpLogin', 'smtpPassword', 'smtpHost', 'smtpPort', 'imapLogin', 'imapPassword', 'imapHost', 'imapPort');
    }
    const clonedEmailAccount = { ...emailAccount };
    if (!emailAccount?.accountId) {
      clonedEmailAccount.smtpLogin = get(emailAccount, 'smtp.login', '');
      clonedEmailAccount.smtpPassword = get(emailAccount, 'smtp.password', '');
      clonedEmailAccount.smtpHost = get(emailAccount, 'smtp.host', '');
      clonedEmailAccount.smtpPort = get(emailAccount, 'smtp.port', '');
      clonedEmailAccount.imapLogin = get(emailAccount, 'imap.login', '');
      clonedEmailAccount.imapPassword = get(emailAccount, 'imap.password', '');
      clonedEmailAccount.imapHost = get(emailAccount, 'imap.host', '');
      clonedEmailAccount.imapPort = get(emailAccount, 'imap.port', '');
    }
    return isEqual(removeEmpty(pick(data, pickedProperties)), removeEmpty(pick(clonedEmailAccount, pickedProperties)));
  }, [data, emailAccount]);

  const updateAccountStatus = useUpdateAccountStatus({
    _id: get(emailAccount, '_id'),
    accountId: get(emailAccount, 'accountId'),
    countCampaigns: get(emailAccount, 'countCampaigns'),
    status: get(emailAccount, 'status'),
    connectGmailAccount,
  });

  const handleRefresh = () => {
    dispatch(refreshConnectedAccount(emailAccount._id));
  };

  return (
    <>
      <div>
        <Flex align="center" justify="space-between">
          <LabelContainer>
            <Label
              as="div"
              size="small"
              kind="secondary"
              fontWeight={600}
            >
              {emailAccount?.email || 'Email account'}
            </Label>
            <Label
              size="small"
              kind="quaternary"
              fontWeight={500}
            >
              {emailAccount?.provider}
            </Label>
          </LabelContainer>
          {
            emailAccount && (
              <div style={{ marginRight: 15 }}>
                <Switch checked={emailAccount.status === ACCOUNT_STATUS.ACTIVE} onChange={updateAccountStatus} />
              </div>
            )
          }
        </Flex>
        {
          sequenceId && (filteredConnectedAccounts?.length > 1 || !emailAccount) && (
            <PropertyContainer>
              <Property
                ignoreAdaptive
                fieldType="select"
                label="Select"
                placeholder="Select email account"
                options={filteredConnectedAccounts.map((account) => ({ value: account._id, label: account.email }))}
                defaultValue={emailAccount?._id}
                updateProperty={({ value }) => {
                  const account = filteredConnectedAccounts.find((item) => item._id === value);
                  dispatch(updateSequenceSettingsRemotely({
                    sequenceId,
                    data: { account: value },
                    onSuccess: () => {
                      setAccountId(value);
                      dispatch(updateSequenceAccount({ sequenceId, account }));
                    },
                  }));
                }}
              />
            </PropertyContainer>
          )
        }
        <LabelContainer style={{ cursor: 'pointer' }} onClick={() => dispatch(toggleModal('imap_info', true, { sequenceId }))}>
          <Label
            size="small"
            kind="primary"
            fontWeight={600}
          >
            Add account
          </Label>
        </LabelContainer>
        {
          emailAccount?._id && (
            <>
              <TabsContainer countTabs={tabs.length}>
                <Tabs
                  onChange={setTab}
                  value={tab}
                  tabs={tabs}
                />
              </TabsContainer>
              <TabContainer>
                {
                  tab === 'settings' && (
                    <>
                      <PropertyContainer>
                        <Property
                          ignoreAdaptive
                          label="Sender"
                          name="name"
                          defaultValue={data.name}
                          updateProperty={({ property, value }) => handleChange({ target: { name: property, value } })}
                        />
                      </PropertyContainer>
                      <PropertyContainer>
                        <Signature
                          signature={data.signature}
                          error={errors.signature}
                          handleChange={handleChange}
                        />
                      </PropertyContainer>
                      <PropertyContainer>
                        <Property
                          ignoreAdaptive
                          label="24h emails limit"
                          name="dailyLimit"
                          bounded
                          fieldType="number"
                          error={errors.dailyLimit}
                          defaultValue={data.dailyLimit}
                          updateProperty={({ property, value }) => handleChange({ target: { name: property, value } })}
                        />
                      </PropertyContainer>
                      <PropertyContainer>
                        <Property
                          ignoreAdaptive
                          label="Domain"
                          placeholder="o.domain.com"
                          name="domainTracker"
                          defaultValue={data.domainTracker}
                          updateProperty={({ property, value }) => handleChange({ target: { name: property, value } })}
                        />
                      </PropertyContainer>
                      <PropertyContainer>
                        <Property
                          ignoreAdaptive
                          label=" "
                          placeholder="For tracking clicks, opens & unsubscribes"
                          readOnly
                        />
                      </PropertyContainer>
                      <PropertyContainer>
                        <Property
                          ignoreAdaptive
                          label=" "
                          fieldType="textarea"
                          placeholder="For better deliverability, a daily limit of 50 emails or less is recommended."
                          readOnly
                        />
                      </PropertyContainer>
                      <PropertyContainer>
                        <StatusValidation
                          onRefresh={handleRefresh}
                          nameProtocol="SPF"
                          linkManual="https://support.google.com/a/answer/33786"
                          valid={isValidSpf}
                          hasIconRefresh={!isEmpty(emailAccount?.selectors)}
                        />
                      </PropertyContainer>
                      <PropertyContainer>
                        <StatusValidation
                          onRefresh={handleRefresh}
                          nameProtocol="DKIM"
                          linkManual="https://support.google.com/a/answer/174124"
                          valid={isValidDkim}
                          hasIconRefresh={!isEmpty(emailAccount?.selectors)}
                        />
                      </PropertyContainer>
                      <LabelContainer style={{ cursor: 'pointer' }} onClick={() => dispatch(toggleModal('delete_email_account', true, { accountId: emailAccount._id }))}>
                        <Label
                          size="small"
                          kind="primary"
                          fontWeight={600}
                        >
                          Delete account
                        </Label>
                      </LabelContainer>
                    </>
                  )
                }
                {
                  tab === 'smtp_imap' && (
                    <>
                      <LabelContainer>
                        <Label
                          size="small"
                          kind="secondary"
                          fontWeight={600}
                        >
                          SMTP
                        </Label>
                      </LabelContainer>
                      <PropertyContainer>
                        <Property
                          ignoreAdaptive
                          label="Email"
                          defaultValue={data.email}
                          name="email"
                          updateProperty={({ property, value }) => handleChange({ target: { name: property, value } })}
                        />
                      </PropertyContainer>
                      <PropertyContainer>
                        <Property
                          ignoreAdaptive
                          label="Login"
                          defaultValue={data.smtpLogin}
                          name="smtpLogin"
                          updateProperty={({ property, value }) => handleChange({ target: { name: property, value } })}
                        />
                      </PropertyContainer>
                      <PropertyContainer>
                        <Property
                          ignoreAdaptive
                          label="Password"
                          defaultValue={data.smtpPassword}
                          error={smtpError}
                          fieldType="password"
                          name="smtpPassword"
                          updateProperty={({ property, value }) => handleChange({ target: { name: property, value } })}
                        />
                      </PropertyContainer>
                      <PropertyContainer>
                        <Property
                          ignoreAdaptive
                          label="Server"
                          defaultValue={data.smtpHost}
                          error={smtpError}
                          name="smtpHost"
                          updateProperty={({ property, value }) => handleChange({ target: { name: property, value } })}
                        />
                      </PropertyContainer>
                      <PropertyContainer>
                        <Property
                          ignoreAdaptive
                          label="Port"
                          defaultValue={data.smtpPort}
                          error={smtpError}
                          name="smtpPort"
                          updateProperty={({ property, value }) => handleChange({ target: { name: property, value } })}
                        />
                      </PropertyContainer>
                      <Border />
                      <LabelContainer>
                        <Label
                          size="small"
                          kind="secondary"
                          fontWeight={600}
                        >
                          IMAP
                        </Label>
                      </LabelContainer>
                      <PropertyContainer>
                        <Property
                          ignoreAdaptive
                          label="Login"
                          defaultValue={data.imapLogin}
                          name="imapLogin"
                          updateProperty={({ property, value }) => handleChange({ target: { name: property, value } })}
                        />
                      </PropertyContainer>
                      <PropertyContainer>
                        <Property
                          ignoreAdaptive
                          label="Password"
                          defaultValue={data.imapPassword}
                          error={imapError}
                          fieldType="password"
                          name="imapPassword"
                          updateProperty={({ property, value }) => handleChange({ target: { name: property, value } })}
                        />
                      </PropertyContainer>
                      <PropertyContainer>
                        <Property
                          ignoreAdaptive
                          label="Server"
                          defaultValue={data.imapHost}
                          error={imapError}
                          name="imapHost"
                          updateProperty={({ property, value }) => handleChange({ target: { name: property, value } })}
                        />
                      </PropertyContainer>
                      <PropertyContainer>
                        <Property
                          ignoreAdaptive
                          label="Port"
                          defaultValue={data.imapPort}
                          error={imapError}
                          name="imapPort"
                          updateProperty={({ property, value }) => handleChange({ target: { name: property, value } })}
                        />
                      </PropertyContainer>
                    </>
                  )
                }
              </TabContainer>
            </>
          )
        }
      </div>
      <Footer>
        <Button
          data-form="true"
          type="primary"
          size="large"
          loading={loading}
          style={ButtonStyle}
          disabled={emailAccount?.status === ACCOUNT_STATUS.DISCONNECT ? false : (isDisabledForm || equalsAccount)}
          onClick={handleSubmit}
        >
          {emailAccount?.status === ACCOUNT_STATUS.DISCONNECT ? 'Activate account' : 'Save changes'}
        </Button>
        <Button
          type="normal"
          size="large"
          style={ButtonStyle}
          onClick={closePreviewBar}
        >
          Cancel
        </Button>
      </Footer>
    </>
  );
};

export default Form;
