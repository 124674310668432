import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

const initialState = {
  properties: [],
  initRequest: true,
  deletedProperties: [],
  groups: [],
  selectedProperties: [],
  selectedPropertyGroups: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SEARCH_TECHNOLOGIES': {
      return {
        ...state,
        properties: state.properties.map((property) => {
          if (property.name === 'technologies') {
            return {
              ...property,
              options: uniqBy([...action.technologies, ...action.payload.data].map((technology) => ({
                label: technology.name,
                value: technology._id,
              })), 'value'),
            };
          }
          return property;
        }),
      };
    }
    case 'GET_CURRENT_COMPANY_TECHNOLOGIES': {
      return {
        ...state,
        properties: state.properties.map((property) => {
          if (property.name === 'technologies') {
            return {
              ...property,
              options: action.payload.map((technology) => ({
                label: technology.name,
                value: technology._id,
              })),
            };
          }
          return property;
        }),
      };
    }
    case 'GET_ALL_PROPERTIES': {
      const { technologies } = action;
      const modifiedProperties = (action.payload || []).map((item) => {
        const result = { ...item };
        if (get(item, 'options.length')) {
          const newOptionsObject = {};

          if (item.name === 'technologies') {
            item.options = technologies.map((technology) => ({
              label: technology.name,
              value: technology._id,
            }));
          }

          item.options.forEach((option) => {
            newOptionsObject[option.value] = option.label;
          });
          result.optionsObject = newOptionsObject;
        }
        return result;
      });

      const properties = modifiedProperties.filter((property) => !property.deleted) || [];
      const deletedProperties = modifiedProperties.filter((property) => property.deleted) || [];

      return {
        ...state,
        initRequest: false,
        properties,
        deletedProperties,
      };
    }

    case 'RESTORE_PROPERTIES': {
      const { propertyIds } = action;
      const modifiedItems = get(action, 'payload.nModified', null);

      if (modifiedItems) {
        const properties = [...state.deletedProperties.filter((item) => propertyIds.includes(item._id)), ...state.properties];
        const deletedProperties = state.deletedProperties.filter((item) => item.default || !propertyIds.includes(item._id));
        return {
          ...state,
          deletedProperties,
          properties,
        };
      }
      return {
        ...state,
      };
    }

    case 'ADD_PROPERTY': {
      const newProperty = get(action, 'payload');

      let hasNewGroup = false;
      const newGroup = get(newProperty, 'group');
      if (newGroup) {
        const group = state.groups.find((gr) => gr.name === newGroup.name);
        if (!group) {
          hasNewGroup = true;
        }
      }

      const owner = get(action, 'owner');
      newProperty.owner = owner;
      if (get(newProperty, 'options.length')) {
        const newOptionsObject = {};
        newProperty.options.forEach((option) => {
          newOptionsObject[option.value] = option.label;
        });
        newProperty.optionsObject = newOptionsObject;
      }
      return {
        ...state,
        groups: hasNewGroup ? [newGroup, ...state.groups] : state.groups,
        properties: [newProperty, ...state.properties],
      };
    }

    case 'GET_PROPERTY_GROUPS': {
      return {
        ...state,
        groups: action.payload,
      };
    }

    case 'SET_SELECTED_PROPERTIES': {
      return {
        ...state,
        selectedProperties: action.data,
      };
    }

    case 'SET_SELECTED_PROPERTY_GROUPS': {
      return {
        ...state,
        selectedPropertyGroups: action.data,
      };
    }

    case 'DELETE_PROPERTIES': {
      const { propertyIds } = action;
      const deletedItems = get(action, 'payload.deletedCount', null);
      const modifiedItems = get(action, 'payload.nModified', null);
      const newState = {
        ...state,
      };

      if (deletedItems) {
        newState.deletedProperties = state.deletedProperties.filter((item) => item.default || !propertyIds.includes(item._id));
      }

      if (modifiedItems) {
        newState.deletedProperties = [...state.properties.filter((item) => !item.default && propertyIds.includes(item._id)), ...state.deletedProperties];
        newState.properties = state.properties.filter((item) => item.default || !propertyIds.includes(item._id));
      }

      return newState;
    }

    case 'DELETE_PROPERTY_GROUPS': {
      const { names } = action;
      return {
        ...state,
        groups: state.groups.filter((group) => !names.includes(group.name)),
        properties: state.properties.map((property) => {
          if (names.includes(get(property, 'group.name'))) {
            return {
              ...property,
              group: null,
            };
          }
          return property;
        }),
        deletedProperties: state.deletedProperties.map((property) => {
          if (names.includes(get(property, 'group.name'))) {
            return {
              ...property,
              group: null,
            };
          }
          return property;
        }),
      };
    }

    case 'UPDATE_PROPERTIES': {
      const { group } = action.payload;
      const { propertyIds, newData } = action;
      const newProperties = state.properties.map((item) => {
        if (propertyIds.includes(item._id)) {
          return {
            ...item,
            ...newData,
            group,
          };
        }
        return item;
      });

      let existed = true;
      if (group) {
        existed = state.groups.some((gr) => gr._id === group._id);
      }

      return {
        ...state,
        groups: group && !existed ? [group, ...state.groups] : state.groups,
        properties: newProperties,
      };
    }

    case 'UPDATE_PROPERTY': {
      const { group } = action.payload;
      const { propertyId, newData } = action;

      let existed = true;
      if (group) {
        existed = state.groups.some((gr) => gr._id === group._id);
      }

      return {
        ...state,
        groups: group && !existed ? [group, ...state.groups] : state.groups,
        properties: state.properties.map((property) => {
          if (property._id === propertyId) {
            return {
              ...property,
              ...newData,
              group,
            };
          }
          return { ...property };
        }),
      };
    }

    case 'RENAME_PROPERTY_GROUP': {
      return {
        ...state,
        properties: state.properties.map((property) => {
          if (get(property, 'group.name') === action.oldName) {
            return {
              ...property,
              group: {
                ...property.group,
                name: action.newName,
              },
            };
          }
          return property;
        }),
        deletedProperties: state.deletedProperties.map((property) => {
          if (get(property, 'group.name') === action.oldName) {
            return {
              ...property,
              group: {
                ...property.group,
                name: action.newName,
              },
            };
          }
          return property;
        }),
        groups: state.groups.map((group) => {
          if (group.name === action.oldName) {
            return {
              ...group,
              name: action.newName,
            };
          }
          return group;
        }),
      };
    }

    case 'CREATE_GROUP': {
      const newGroup = action.payload;
      delete newGroup.settings;
      return {
        ...state,
        groups: [newGroup, ...state.groups],
      };
    }

    case 'SIGN_OUT':
    case 'SWITCH_WORKSPACE': {
      return initialState;
    }

    default:
      return state;
  }
};
