import { useCallback } from 'react';
import { loginPlugin } from 'helpers/pluginAuthorization';
import checkChromeExtension from 'helpers/checkChromeExtension';
import { ASYNC_BOOLEAN } from 'data/types/progress.types';

const useLoginPlugin = () => useCallback((closeWindow = false) => {
  const registerPlugin = async () => {
    const isInstalled = await checkChromeExtension();
    if (isInstalled === ASYNC_BOOLEAN.TRUE) {
      loginPlugin(
        closeWindow,
        localStorage.getItem('accessToken'),
        localStorage.getItem('refreshToken'),
      );
    }
  };

  registerPlugin();
}, []);

export default useLoginPlugin;
