import { createSelector } from 'reselect';
import get from 'lodash/get';

export const baseTourSelector = (state) => get(state, 'tour', {});

export const tourCurrentStepSelector = createSelector(
  baseTourSelector,
  (data) => data.step,
);

export const tourCurrentNameSelector = createSelector(
  baseTourSelector,
  (data) => data.name,
);

export const tourOptionsSelector = createSelector(
  baseTourSelector,
  (data) => data.options,
);

export const tourRetryStepSelector = createSelector(
  baseTourSelector,
  (data) => data.retryStep,
);
