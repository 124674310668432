import React, { useEffect, useState } from 'react';
import {
  Modal,
} from 'antd';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Loader from 'components/loading';
import {
  Input,
  Select,
  Option,
  Radio,
} from 'components/common';
import { getSavedSearchOptions, saveSearch, updateSearch } from 'data/actions/search';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { searchOptionsSelector } from 'data/selectors/search';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { savedSearchOptionsLoaderSelector } from 'data/selectors/loading';

import {
  Footer,
  InputNameWrapper,
  Description,
  Container,
  ModalButton,
  inputStyles,
} from './styles';

import {
  CloseIcon,
  ModalTitle,
  Label,
} from '../baseModal/styles';

const SaveSearch = () => {
  const dispatch = useDispatch();
  const {
    searchId,
    sourceType,
    filters,
    backModal,
    backOptions,
    onChange,
  } = useSelector(modalOptionsSelector);
  const { visible, type } = useSelector(modalsSelector);
  const searchOptions = useSelector(searchOptionsSelector);
  const loading = useSelector(savedSearchOptionsLoaderSelector);

  const OPTIONS = Object.freeze({
    CREATE_NEW: 'create_new',
    UPDATE_EXISTING: 'update_existing',
  });

  const [name, setName] = useState();
  const [existedId, setExistedId] = useState();
  const [option, setOption] = useState(OPTIONS.CREATE_NEW);

  const searchName = option === OPTIONS.CREATE_NEW ? name : searchOptions.data.find((searchOption) => searchOption._id === existedId)?.name;
  const saveSuccessfulMsg = `Search "${searchName}" ${option === OPTIONS.CREATE_NEW ? 'created' : 'updated'}`;

  const handleClose = () => {
    if (backModal) {
      dispatch(toggleModal(backModal, true, backOptions));
    } else {
      dispatch(toggleModal('save_search', false));
    }
  };

  const onSuccessSave = () => {
    if (onChange) onChange();
    dispatch(toggleMessage('success', { header: saveSuccessfulMsg }));
    handleClose();
  };

  const handleAction = () => {
    if (option === OPTIONS.CREATE_NEW) {
      dispatch(saveSearch(
        {
          searchId,
          name,
          filters,
          sourceType,
        },
        onSuccessSave,
      ));
    } else if (option === OPTIONS.UPDATE_EXISTING) {
      dispatch(updateSearch(existedId, {
        filters,
        onSuccess: onSuccessSave,
      }));
    }
  };
  const isVisible = visible && type === 'save_search';
  const validForm = (option === OPTIONS.CREATE_NEW && name) || (option === OPTIONS.UPDATE_EXISTING && existedId);

  useEffect(() => {
    dispatch(getSavedSearchOptions(1, sourceType));
  }, [dispatch, sourceType]);

  return (
    <Modal
      visible={isVisible}
      title={(
        <ModalTitle>
          {`Save your ${sourceType === 'contact' ? 'lead' : sourceType} filters`}
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      width={520}
      footer={false}
      onCancel={handleClose}
      closable={false}
    >
      <Loader loading={loading}>
        <Description>Give a name to your set of filters. It will help to find the search quickly and continue working with it. </Description>
        <Container>
          <InputNameWrapper>
            <Label>
              <Radio checked={option === OPTIONS.CREATE_NEW} onChange={() => setOption(OPTIONS.CREATE_NEW)}>
                Create new
              </Radio>
            </Label>
            {
              option === OPTIONS.CREATE_NEW ? (
                <Input
                  inputStyle={inputStyles}
                  autoFocus
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                  placeholder="Enter name"
                />
              ) : null
            }
          </InputNameWrapper>
          <InputNameWrapper>
            <Label>
              <Radio checked={option === OPTIONS.UPDATE_EXISTING} onChange={() => setOption(OPTIONS.UPDATE_EXISTING)}>
                Update existing
              </Radio>
            </Label>
            {
              option === OPTIONS.UPDATE_EXISTING ? (
                <Select
                  showSearch
                  filterOption={(input, o) => (o.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
                  disabled={option !== OPTIONS.UPDATE_EXISTING}
                  style={inputStyles}
                  size="large"
                  onChange={setExistedId}
                  placeholder="Select..."
                >
                  {
                    searchOptions.data.map((searchOption) => (
                      <Option value={searchOption._id} key={`search-option-${searchOptions._id}`}>{searchOption.name}</Option>
                    ))
                  }
                </Select>
              ) : null
            }
          </InputNameWrapper>
        </Container>
      </Loader>
      <Footer>
        <ModalButton
          data-form={validForm ? 'true' : 'false'}
          type="primary"
          disabled={!validForm}
          onClick={handleAction}
        >
          {`${option === OPTIONS.CREATE_NEW ? 'Save' : 'Update'} filters`}
        </ModalButton>
        <ModalButton type="normal" onClick={handleClose}>Cancel</ModalButton>
      </Footer>
    </Modal>
  );
};

export default withRouter(SaveSearch);
