import { PageSizes } from 'data/types/tableMeta.types';

const initialState = {
  teammates: {
    data: [],
    totalItems: 0,
    page: 1,
    pageSize: PageSizes.DEFAULT_TEAMMATES_PAGE_SIZE,
  },
  allTeammates: [],
  relation: { },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_TEAMMATES': {
      return {
        ...state,
        allTeammates: action.payload,
      };
    }
    case 'GET_TEAMMATES': {
      const { data, meta } = action.payload;
      return {
        ...state,
        teammates: {
          ...state.teammates,
          ...meta,
          data,
        },
      };
    }
    case 'GET_RELATION': {
      return {
        ...state,
        relation: action.payload || state.relation,
      };
    }
    case 'UPDATE_TEAMMATE': {
      const { relationId, options } = action;
      const newTeammatesData = state.teammates.data.map((el) => ((el._id === relationId) ? { ...el, ...options } : el));
      return {
        ...state,
        teammates: { ...state.teammates, data: newTeammatesData },
      };
    }
    case 'UPDATE_USER_RELATION': {
      return {
        ...state,
        relation: {
          ...state.relation,
          ...action.options,
        },
      };
    }
    case 'CONFIRM_RELATION': {
      return {
        ...state,
        relation: action.payload,
      };
    }
    case 'ADD_TEAMMATE': {
      return {
        ...state,
        teammates: {
          ...state.teammates,
          data: [...state.teammates.data, action.payload],
        },
      };
    }
    case 'DELETE_TEAMMATE': {
      const { relationId } = action;
      const newTeammates = state.teammates.data.filter((el) => el._id !== relationId);

      return {
        ...state,
        teammates: {
          ...state.teammates,
          data: newTeammates,
          totalItems: state.teammates.totalItems - 1,
        },
      };
    }
    case 'DELETE_ALL_TEAMMATES': {
      return {
        ...state,
        teammates: { ...initialState.teammates },
      };
    }
    case 'SET_TEAMMATES_PARAMS': {
      return {
        ...state,
        teammates: {
          ...state.teammates,
          ...action.data,
        },
      };
    }
    case 'SIGN_OUT':
    case 'SWITCH_WORKSPACE': {
      return initialState;
    }
    default:
      return state;
  }
};
