import parsePhoneNumber from 'libphonenumber-js/mobile';

export function passwordLengthValidate(password) {
  return password.length > 11 && password.length < 129;
}

export function passwordHasSpecialSymbol(password) {
  const specialSymbols = ['!', '"', '#', '$', '%', '&', "'", '(', ')', '*', '+', ',', '-', '.', '/', ':', ';', '<', '=', '>', '?', '@', '[', '\\', ']', '^', '_', "'", '{', '|', '}', '~'];
  return specialSymbols.some((symbol) => password.includes(symbol));
}

export function passwordWithRepeatValidate(newPassword = '', repeatPassword = '') {
  return newPassword === repeatPassword && passwordLengthValidate(newPassword) && passwordLengthValidate(repeatPassword);
}

export const validateEmail = (email) => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export function validateAffiliateLink(link) {
  const { search = '' } = new URL(link);

  if (!search) {
    return false;
  }
  const [affiliate = '', affiliateCode = ''] = search.split('=');

  return affiliate.includes('affiliate') && !!affiliateCode;
}

export function validatePhone(_, data) {
  try {
    const number = parsePhoneNumber(`+${data?.phone?.replace(/\D/g, '')}` || '', data?.countryCode?.toUpperCase());
    return number.isValid();
  } catch (err) {
    return false;
  }
}

export function validateName(name) {
  try {
    if (name?.trim()?.length > 1) return true;
    return false;
  } catch (err) {
    return false;
  }
}

export function validateOAuth2Link(link = '') {
  const regExp = /^http(s{0,1}):\/\/.*\/oauth2\/connect\?client_id=.*(&redirect_uri=.*&response_type=.*$|&response_type=.*&redirect_uri=.*$)/;
  return regExp.test(String(link));
}

export const validateUrl = (link, optional = false) => {
  if (optional && !link) return true;
  // eslint-disable-next-line
  const re = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  return re.test(String(link).toLowerCase());
};

export const validateMaxRange = (maxRange) => (number) => Number.parseInt(number, 10) <= maxRange;
export const validateMinRange = (minRange) => (number) => Number.parseInt(number, 10) >= minRange;
