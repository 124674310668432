import { useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

// swipes - Right, Left, Up, Down, All

const useSwipeHandler = ({ swipes = [], dataAttr }) => {
  const { ref } = useSwipeable(swipes.reduce((prevSwipe, swipe) => ({
    ...prevSwipe,
    [`onSwiped${swipe === 'All' ? '' : swipe}`]: () => {
      const element = document.querySelector(`[data-${dataAttr}="true"]`);
      if (element) {
        element.click();
      }
    },
  }), {}));

  useEffect(() => {
    ref(document);
  });
};

export default useSwipeHandler;
