import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import {
  Modal,
} from 'antd';
import {
  Button,
} from 'components/common';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { startBulkVerification } from 'data/actions/verifications';
import { verificationsBulkSelector } from 'data/selectors/verifications';
import { userCreditsVerificationSelector } from 'data/selectors/user';
import { round } from 'lodash';
import calculatePrice from 'helpers/calculatePrice';
import usePreSubscription from 'hooks/usePreSubscription';
import Loader from 'components/loading';
import {
  Container,
  Half,
} from './styles';
import {
  ModalTitle,
  CloseIcon,
} from '../baseModal/styles';

const BulkVerificationModal = () => {
  const dispatch = useDispatch();

  const { bulkId, plan } = useSelector(modalOptionsSelector);
  const { visible } = useSelector(modalsSelector);
  const { fileInfo } = useSelector(verificationsBulkSelector(bulkId));
  const { current: credits } = useSelector(userCreditsVerificationSelector);

  const [price, setPrice] = useState(0);
  const [creditsPrice, setCreditsPrice] = useState(0);

  const handlePreSubscription = usePreSubscription();
  const [isLoadingFastSpring] = useState(false);
  const [isDisabledUseCredits, setDisableUseCredits] = useState(credits <= get(fileInfo, 'billable'));

  useEffect(() => {
    setDisableUseCredits(credits < get(fileInfo, 'billable'));
  }, [fileInfo, credits]);

  const handleBuy = () => {
    dispatch(toggleModal('bulk_verification', false));
    handlePreSubscription({
      bulkId,
      plan: {
        ...plan,
        price,
      },
    });
  };

  const handleCredits = () => {
    dispatch(toggleModal('bulk_verification', false));
    dispatch(startBulkVerification(bulkId, 'credits'));
  };

  useEffect(() => {
    const calculetedPrice = calculatePrice({ planPrice: plan.price, quantity: fileInfo.billable % 1000 ? fileInfo.total : fileInfo.billable });
    setPrice(calculetedPrice);
    setCreditsPrice(round((fileInfo.billable * 1), 2)); // TODO: define credits cost global
  }, [fileInfo.billable, plan, fileInfo.total]);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Bulk Verification
          <CloseIcon
            onClick={() => dispatch(toggleModal('bulkVerification', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      centered
      width={450}
      padding={10}
      footer={false}
      onCancel={() => dispatch(toggleModal('bulkVerification', false))}
    >
      <Loader loading={isLoadingFastSpring}>
        <Container>
          <Half>
            <div>
              This verification will cost you
              {' '}
              <b>
                {Math.round(creditsPrice)}
                &nbsp;verifications
              </b>
            </div>
            <Button
              className={isLoadingFastSpring || isDisabledUseCredits ? '' : 'btn-credits'}
              onClick={handleCredits}
              disabled={isLoadingFastSpring || isDisabledUseCredits}
            >
              Use verifications
            </Button>
          </Half>
          <Half>
            <div>
              Or you can buy it for
              {' '}
              <p>
                {`$${price} USD`}
              </p>
            </div>
            <Button
              type="primary"
              onClick={handleBuy}
              disabled={isLoadingFastSpring}
            >
              Buy
            </Button>
          </Half>
        </Container>
      </Loader>
    </Modal>
  );
};

export default BulkVerificationModal;
