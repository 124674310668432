import React, {
  useMemo,
} from 'react';

import Stars from 'components/stars';
import { COLORS } from 'constants/styles';
import g2 from 'assets/images/cites/companies/g2.png';
import capterra from 'assets/images/cites/companies/capterra.png';
import chromeStore from 'assets/images/cites/companies/chrome-store.svg';
import {
  RatingsContainer,
  ImageCompanyRating,
  StarsContainer,
  RatingsText,
  ReviewsText,
} from './styles';

const CompanyReviews = () => {
  const companies = useMemo(() => {
    const items = [
      {
        id: 0,
        company: chromeStore,
        mobileWidthRatingCompany: '122px',
        rating: 4.8,
        reviews: 280,
        starsColor: COLORS.YELLOW_Y1,
      },
      {
        id: 1,
        company: g2,
        widthRatingCompany: '100px',
        rating: 4,
        reviews: 21,
        mobileWidthRatingCompany: '86px',
        starsColor: '#FF4A2C',
      },
      {
        id: 2,
        company: capterra,
        mobileWidthRatingCompany: '93px',
        widthRatingCompany: '110px',
        heightRatingCompany: '25px',
        rating: 4.6,
        reviews: 14,
        starsColor: COLORS.YELLOW_Y1,
      },
    ];
    return items;
  }, []);

  return (
    <RatingsContainer>
      {
        companies.map((cite) => (
          <div key={cite.id} style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ height: 30 }}>
              <ImageCompanyRating widthRatingCompany={cite.widthRatingCompany} mobileWidthRatingCompany={cite.mobileWidthRatingCompany} style={{ height: cite.heightRatingCompany }} src={cite.company} alt="Company" />
            </div>
            <StarsContainer>
              <div>
                <Stars color={cite.starsColor} rating={cite.rating} />
              </div>
              <div>
                <RatingsText>
                  {cite.rating.toFixed(1)}
                  /5
                  {' '}
                </RatingsText>
                <ReviewsText>
                  {cite.reviews}
                  {' '}
                  reviews
                </ReviewsText>
              </div>
            </StarsContainer>
          </div>
        ))
      }
    </RatingsContainer>
  );
};

export default CompanyReviews;
