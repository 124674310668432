import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { disableStepVersion } from 'data/actions/sequences';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { Container } from '../deleteStep/styles';
import {
  ModalTitle, CloseIcon, Footer,
} from '../baseModal/styles';

const DisableStepVersionModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { stepId, letter, disabled } = useSelector(modalOptionsSelector);

  const handleClose = () => dispatch(toggleModal('disable_step_version', false));
  const handleDisable = () => {
    dispatch(disableStepVersion(stepId, letter, disabled));
    handleClose();
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger={disabled}>
          {disabled ? 'Disable' : 'Activate'}
          {' '}
          the version
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <Container>
        <div>
          You&#39;re about to
          {' '}
          {disabled ? 'disable' : 'activate'}
          {' '}
          the version.
        </div>

      </Container>
      <Footer>
        <Button
          data-form="true"
          type={disabled ? 'danger' : 'primary'}
          size="large"
          onClick={handleDisable}
        >
          {disabled ? 'Disable' : 'Activate'}
        </Button>
        <Button
          size="large"
          type={disabled ? 'default' : 'normal'}
          onClick={handleClose}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default DisableStepVersionModal;
