import React from 'react';

import ProductCites from 'components/productCites';
import CompanyReviews from 'components/companyReviews';
import {
  BaseContentContainer,
} from '../styles';

import {
  LoginContentContainer,
  Container,
  HeaderWrapper,
  GetProspectCite,
} from './styles';

const BasePart = () => (
  <Container>
    <LoginContentContainer>
      <HeaderWrapper style={{ marginLeft: '28px' }}>
        <GetProspectCite>
          GetProspect is trusted by 200,000+
          <br />
          professionals worldwide.
        </GetProspectCite>
      </HeaderWrapper>
      <BaseContentContainer>
        <ProductCites />
        <CompanyReviews />
      </BaseContentContainer>
    </LoginContentContainer>
  </Container>
);

export default BasePart;
