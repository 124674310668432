import { Modal } from 'antd';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Flex } from '@newlab-io/getprospect-react-components/lib/ui';

import { Button } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import useGoogleOauth from 'hooks/useGoogleOauth';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import {
  ModalTitle,
  HeaderLabel,
  CloseIcon,
  Footer,
} from '../baseModal/styles';
import Connection from './connection';

const AddEmailAccountModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const { sequenceId } = useSelector(modalOptionsSelector);

  const { connectGmailAccount } = useGoogleOauth({ sequenceId });

  const [typeConnection, setTypeConnection] = useState('oauth');

  const handleClose = () => dispatch(toggleModal('add_email_account', false));
  const handleNext = () => {
    if (typeConnection === 'oauth') {
      connectGmailAccount();
    } else {
      dispatch(toggleModal('app_password_info', true, { sequenceId }));
    }
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Add account
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={420}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        <HeaderLabel style={{ marginBottom: 20 }}>Select a connection type</HeaderLabel>
        <Flex style={{ paddingBottom: 10 }} direction="column" gap={10}>
          <Connection
            name="oAuth connect"
            hints={[
              { name: 'Easier to setup' },
              { name: 'More stable and less disconnects' },
            ]}
            recommended
            selected={typeConnection === 'oauth'}
            onSelect={() => setTypeConnection('oauth')}
          />
          <Connection
            name="App Password"
            hints={[
              { name: 'Requires 2-factor authentication' },
              { name: 'More prone to disconnects' },
            ]}
            selected={typeConnection === 'appPassword'}
            onSelect={() => setTypeConnection('appPassword')}
          />
        </Flex>
        <Footer>
          <Button
            data-form="true"
            type="primary"
            onClick={handleNext}
          >
            Next
          </Button>
          <Button type="normal" onClick={handleClose}>Cancel</Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default AddEmailAccountModal;
