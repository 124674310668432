import * as CONSTANTS from 'constants/styles';

export const searchIconData = Object.freeze({
  SAVED_FILTERS: {
    icon: {
      type: 'ic-filter',
      fill: 'yellow',
    },
    background: CONSTANTS.COLORS.YELLOW_WITH_OPACITY,
  },
  LEAD_SEARCH: {
    icon: {
      type: 'ic-person-search',
      fill: 'green',
    },
    background: CONSTANTS.COLORS.GREEN_WITH_OPACITY,
  },
  LEAD_SAVED: {
    icon: {
      type: 'ic-person-add-alt-1',
      fill: 'primary',
    },
    background: CONSTANTS.COLORS.PRIMARY_P2,
  },
  COMPANY_SAVED: {
    icon: {
      type: 'ic-add-business',
      fill: 'purple',
    },
    background: CONSTANTS.COLORS.PURPLE_WITH_OPACITY,
  },
  COMPANY_SEARCH: {
    icon: {
      type: 'ic-store',
      fill: 'pink',
    },
    background: CONSTANTS.COLORS.PINK_WITH_OPACITY,
  },
  ERROR: {
    icon: {
      type: 'ic-error',
      fill: 'red',
    },
    background: CONSTANTS.COLORS.RED_WITH_OPACITY,
  },
});
