import styled from 'styled-components';
import { Input, List } from 'antd';
import * as CONSTANTS from 'constants/styles';
import { BreadcrumbsChevron } from '../../tables/foldersTable/styles';

const { Item } = List;

export const Container = styled.div`
   .ant-tree-switcher-noop {
    display: none;
  }

  .ant-tree-switcher {
    display: none !important;
  }

  .ant-tree-node-selected {
    span {
      color: #071427;
    }
  }

  .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before, .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before {
    background: #d1e9ff;
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const BlankListItem = styled(ListItem)`
  justify-content: space-between;
`;
export const BlankDataContainer = styled.div`
  display: inherit;
  flex-direction: inherit;
  align-items: inherit;
  width: 100%;
`;
export const BlankButtonControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;
export const BlankFolderInput = styled(Input)`
  margin-left: 10px;
  max-width: 80%;
  border: none;
  background: transparent;
  padding-left: 0;
  
  :focus {
    outline: none;
    box-shadow: none;
  }
`;

export const ListItemText = styled.span`
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 85%;
`;

export const ListWrapper = styled.div`
  .ant-list-item {
    position: relative;
    max-height: 47px;
    padding-left: 5px;
    cursor: pointer;
    
    :hover {
      background-color: ${CONSTANTS.COLORS.BLUE_B5};
    }
    
    .list-chevron {
      position: absolute;
      right: 10px;
    }
    
    :last-child {
      border-bottom: 1px solid #e8e8e8;
    }
  }
  
  .ant-spin-nested-loading {
    height: 330px;
    overflow-x: scroll;

    @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
      margin-top: 64px;
  }
  }
  
  .ant-list-header {
    padding-top: 0;
  }
  
  position: relative;
  ${(props) => props.showShadow && `
    :after {
     content: '';
     height: 10px;
     width: 100%;
     position: absolute;
     bottom: 0; 
     background-image: -webkit-linear-gradient(top,rgba(255,255,255,.2) 0,#efefef 100%);
  }
  `}
  
  .ant-list-empty-text {
    height: 330px;
    padding: 0;
  }
`;

export const ListFolderItem = styled(Item)``;

export const FooterMoveListWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FooterMoveListButtonsGroup = styled.div`

`;

export const FooterActionTest = styled.span`
  color: ${(props) => (props.disableAction ? CONSTANTS.COLORS.GREY_G2 : CONSTANTS.COLORS.PRIMARY)};
  font-weight: 500;
  cursor: ${(props) => (props.disableAction ? 'not-allowed' : 'pointer')};
`;

export const HeaderListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 32px;
`;

export const MoveListSearch = {
  width: 180,
};

export const TableBreadCrumbsWrapper = {
  paddingLeft: 18,
  paddingRight: 10,
  maxWidth: `calc(100vw - ${CONSTANTS.CONTENT_MARGIN_LEFT}px`,
  borderBottom: `1px solid ${CONSTANTS.COLORS.GREY_G7}`,
  borderLeft: `1px solid ${CONSTANTS.COLORS.GREY_G7}`,
};

export const BreadCrumbsWrapper = styled.div`
  max-width: 290px;
  height: 42px;
  display: flex;
  align-items: center;
`;

export const RootPath = styled.div`
  display: flex;
  align-items: center;
`;
export const BreadcrumbWrapperItem = styled.div`
  max-width: 50px;
`;
export const BreadcrumbItem = styled.button`
  display: contents;
  font-size: 14px;
  background-color: transparent;
  border: none;
  outline: none;
  position: relative;
  padding: 0;
  color: ${(props) => (props.currentItem ? CONSTANTS.COLORS.BLACK_G1 : CONSTANTS.COLORS.GREY_G2)};
  cursor: ${(props) => (props.currentItem ? 'not-allowed' : 'pointer')};

  :last-child {
    ${BreadcrumbsChevron} {
      display: none;
    }
  }
  
  ${(props) => !props.currentItem && `
    :hover {
      color: ${CONSTANTS.COLORS.PRIMARY};
    }
  `}
`;

export const MovePrev = styled.div`
  cursor: pointer;
  margin-left: 5px;
  
  i {
    margin-top: 9px;
  }
  
  svg {
    fill: ${CONSTANTS.COLORS.GREY_G3};
  }
`;

export const ModalTitleWrapper = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 20px;
`;

export const EmptyContainer = styled.div`
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyContentContainer = styled.div`
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
`;

export const EmptyContentDescription = styled.span`
  width: inherit;
  color: ${CONSTANTS.COLORS.BLACK_G1};
  max-width: 254px;
  margin-top: 25px;
`;
