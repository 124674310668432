import React from 'react';
import icWorldWideWebFlatlinePath from 'assets/images/world_wide_web_flatline.svg';

import {
  HeaderWrapper,
  ContentContainer,
  Header,
  SubHeader,
  BenefitsContainer,
  BenefitsTitle,
  BenefitItem,
  BenefitsContent,
  BenefitsImage,
  BenefitItemCircle,
  BenefitsColumnsWrapper,
  BenefitItemTitle,
  UnconfirmedEmailContainer,
  BenefitsColumn,
} from './styles';

const IconCheck = () => (
  <svg width="24" height="18" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3873 23.1541L12.3476 23.1938L12.3176 23.2412C12.2687 23.3182 12.2012 23.3816 12.1213 23.4255C12.0414 23.4695 11.9518 23.4925 11.8606 23.4925C11.7694 23.4925 11.6798 23.4695 11.5999 23.4255C11.52 23.3816 11.4525 23.3182 11.4036 23.2412L11.3759 23.1975L11.3399 23.1603L0.81259 12.2742L0.773136 12.2334L0.725645 12.2023C0.65627 12.1569 0.599759 12.0943 0.56159 12.0207C0.523416 11.947 0.504888 11.8648 0.507797 11.7819C0.510705 11.699 0.534953 11.6182 0.578194 11.5474C0.621426 11.4766 0.682172 11.4182 0.754555 11.3778L0.804179 11.3501L0.846444 11.3121L3.48816 8.9369L3.52669 8.90226L3.55742 8.86055C3.60222 8.79976 3.66067 8.75034 3.72806 8.71628C3.79543 8.68224 3.86985 8.6645 3.94533 8.6645C4.02081 8.6645 4.09524 8.68224 4.16261 8.71628C4.22998 8.75033 4.28842 8.79974 4.33323 8.86055L4.35398 8.88872L4.3784 8.91377C6.43266 11.021 8.49671 13.125 10.5705 15.2258C10.709 15.4246 10.892 15.5889 11.1052 15.7053C11.3354 15.831 11.5934 15.8968 11.8556 15.8968C12.1179 15.8968 12.3759 15.831 12.6061 15.7053C12.8184 15.5894 13.0008 15.426 13.1391 15.2282L27.6577 0.824494L27.6935 0.788969L27.7217 0.747089C27.7712 0.673333 27.8381 0.612911 27.9164 0.571132C27.9948 0.52935 28.0822 0.5075 28.171 0.5075C28.2598 0.5075 28.3472 0.529347 28.4255 0.571132C28.5039 0.612911 28.5708 0.673335 28.6203 0.747089L28.647 0.786824L28.6806 0.820872C28.9552 1.09887 29.2291 1.37499 29.5022 1.65033C30.0437 2.19633 30.5821 2.73924 31.1174 3.28755C31.2493 3.44792 31.3733 3.61458 31.4891 3.78693L31.4858 3.92802C31.369 4.11225 31.2383 4.2874 31.0949 4.45193L12.3873 23.1541Z" fill="#1890FF" strokeWidth="1.015" />
  </svg>
);

const ConfirmEmailPart = () => (
  <UnconfirmedEmailContainer>
    <ContentContainer>
      <HeaderWrapper>
        <Header>
          GetProspect is trusted by 200,000+
          <br />
          professionals worldwide.
        </Header>
        <SubHeader>
          GetProspect is the great tool for LinkedIn prospecting and
          <br />
          leads management
        </SubHeader>
      </HeaderWrapper>

      <BenefitsContainer>
        <BenefitsContent>
          <BenefitsTitle>
            What you get with GetProspect “Free plan”:
          </BenefitsTitle>

          <BenefitsColumnsWrapper>
            <BenefitsColumn>
              <BenefitItem>
                <BenefitItemCircle>
                  <IconCheck />
                </BenefitItemCircle>
                <div>
                  <BenefitItemTitle>50 valid emails / month</BenefitItemTitle>
                </div>
              </BenefitItem>

              <BenefitItem>
                <BenefitItemCircle>
                  <IconCheck />
                </BenefitItemCircle>
                <div>
                  <BenefitItemTitle>100 verifications / month</BenefitItemTitle>
                </div>
              </BenefitItem>

              <BenefitItem>
                <BenefitItemCircle>
                  <IconCheck />
                </BenefitItemCircle>
                <div>
                  <BenefitItemTitle>Unlimited accept-all emails</BenefitItemTitle>
                </div>
              </BenefitItem>

              <BenefitItem>
                <BenefitItemCircle>
                  <IconCheck />
                </BenefitItemCircle>
                <div>
                  <BenefitItemTitle>Unlimited team users</BenefitItemTitle>
                </div>
              </BenefitItem>
            </BenefitsColumn>

            <BenefitsColumn style={{ marginLeft: 46 }}>
              <BenefitItem>
                <BenefitItemCircle>
                  <IconCheck />
                </BenefitItemCircle>
                <div>
                  <BenefitItemTitle>Valid and accurate data</BenefitItemTitle>
                </div>
              </BenefitItem>

              <BenefitItem>
                <BenefitItemCircle>
                  <IconCheck />
                </BenefitItemCircle>
                <div>
                  <BenefitItemTitle>Bulk email verification</BenefitItemTitle>
                </div>
              </BenefitItem>

              <BenefitItem>
                <BenefitItemCircle>
                  <IconCheck />
                </BenefitItemCircle>
                <div>
                  <BenefitItemTitle>No credit card </BenefitItemTitle>
                </div>
              </BenefitItem>

              <BenefitItem>
                <BenefitItemCircle>
                  <IconCheck />
                </BenefitItemCircle>
                <div>
                  <BenefitItemTitle>Cancel anytime</BenefitItemTitle>
                </div>
              </BenefitItem>
            </BenefitsColumn>
          </BenefitsColumnsWrapper>
        </BenefitsContent>

        <BenefitsImage src={icWorldWideWebFlatlinePath} alt="" />
      </BenefitsContainer>
    </ContentContainer>
  </UnconfirmedEmailContainer>
);

export default ConfirmEmailPart;
