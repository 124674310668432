export const loginPlugin = async (closeWindow = false, accessToken, refreshToken) => {
  if (window.chrome?.runtime?.sendMessage) {
    const ids = process.env.REACT_APP_PLUGIN_ID.split(' ');
    if (process.env.REACT_APP_PLUGIN_TEST && ids.length > 1) {
      Promise.allSettled(ids.map((id) => new Promise((resolve) => {
        window.chrome.runtime.sendMessage(id, {
          message: 'login',
          accessToken,
          refreshToken,
        }, (result) => resolve(result));
      })));
    } else {
      window.chrome.runtime.sendMessage(
        process.env.REACT_APP_PLUGIN_ID,
        {
          message: 'login',
          accessToken,
          refreshToken,
        },
      );
    }

    if (closeWindow) window.close();
  }
};

export const logoutPlugin = async () => {
  if (window.chrome?.runtime?.sendMessage) {
    const ids = process.env.REACT_APP_PLUGIN_ID.split(' ');
    if (process.env.REACT_APP_PLUGIN_TEST && ids.length > 1) {
      Promise.allSettled(ids.map((id) => new Promise((resolve) => {
        window.chrome.runtime.sendMessage(id, {
          message: 'logout',
        }, (result) => resolve(result));
      })));
    } else {
      window.chrome.runtime.sendMessage(
        process.env.REACT_APP_PLUGIN_ID,
        {
          message: 'logout',
        },
      );
    }
  }
};
