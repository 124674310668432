import styled from 'styled-components';
import { Input, Drawer } from 'antd';
import { MOBILE_MAX_WIDTH } from 'constants/styles';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const Body = styled.div`
  .ant-collapse-header {
    background-color: #fff !important;
    font-weight: 500;
  }
  padding: 24px;
`;

export const ListNameInput = styled(Input)`
  span {
    color: rgb(0, 164, 189);
  }
  &:hover {
    span {
      display: none;
    }
  }
  
  input {
    &:hover {
      background: rgb(245, 248, 250);
    }
    border: none;
    padding: 4px 0px;
    width: 300px;
    font-size: 20px;
    font-weight: 600;
    &::placeholder {
      color: #7c98b5;
    }
  }
`;

export const Divider = styled.hr`
  background-color: rgb(223, 227, 235);
  height: 1px;
  min-height: 1px;
  width: 100%;
  margin-top: 24px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
`;

export const Container = styled(Drawer)`

  .ant-drawer-content-wrapper {
    width: 600px!important;
    @media (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 100vw;
    }
  }
`;
