import { Drawer } from 'antd';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import React, {
  useMemo,
  useEffect,
  useState,
} from 'react';

import useFetch from 'hooks/useFetch';
import Loader from 'components/loading';
import * as CONSTANTS from 'constants/styles';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { getConnectedAccounts } from 'data/actions/integrations';
import { connectedAccountsSelector } from 'data/selectors/integrations';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { connectedAccountsLoadingSelector, updateSequenceSettingsRemotelyLoaderSelector } from 'data/selectors/loading';
import {
  BodyStyle,
} from '../../createContactBar/styles';
import { ModalTitle, CloseIcon } from '../../modals/baseModal/styles';
import { HeaderStyle, PreviewLoader } from '../styles';
import Form from './form';

const EmailAccountPreviewBar = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const {
    accountId: defaultAccountId,
    sequenceId,
    waitForEmailAccount,
    baseTab,
  } = useSelector(modalOptionsSelector);

  const loadingChangeAccount = useSelector(updateSequenceSettingsRemotelyLoaderSelector);

  const [accountId, setAccountId] = useState(defaultAccountId);

  const { data: connectedAccounts, loading } = useFetch({
    action: getConnectedAccounts,
    selector: connectedAccountsSelector,
    loadingSelector: connectedAccountsLoadingSelector,
    cached: true,
  });

  const closePreviewBar = () => dispatch(toggleModal('email_account_preview_bar', false));

  const emailAccount = useMemo(() => {
    if (loading) return null;
    return connectedAccounts.find((account) => account._id === accountId);
  }, [connectedAccounts, loading, accountId]);

  const smtpError = get(emailAccount, 'smtp.error');
  const imapError = get(emailAccount, 'imap.error');

  useEffect(() => {
    if (smtpError) {
      dispatch(toggleMessage('error', { header: 'SMTP Server error', text: smtpError }));
    } else if (imapError) {
      dispatch(toggleMessage('error', { header: 'IMAP Server error', text: imapError }));
    }
  }, [dispatch, smtpError, imapError]);

  return (
    <Drawer
      title={(
        <ModalTitle white>
          <CloseIcon
            data-close="true"
            onClick={closePreviewBar}
            className="ic_close"
          />
        </ModalTitle>
      )}
      headerStyle={HeaderStyle}
      bodyStyle={{ ...BodyStyle, padding: 0 }}
      placement="right"
      className="ant-white-modal"
      onClose={closePreviewBar}
      visible={visible}
      width={CONSTANTS.DRAWER_WIDTH}
      closable={false}
    >
      {
        (sequenceId ? loading || loadingChangeAccount || (waitForEmailAccount && !emailAccount) : loading || !emailAccount) ? <PreviewLoader><Loader size="large" loading /></PreviewLoader> : (
          <Form
            setAccountId={setAccountId}
            sequenceId={sequenceId}
            baseTab={baseTab}
            connectedAccounts={connectedAccounts}
            emailAccount={emailAccount}
          />
        )
      }
    </Drawer>
  );
};

export default EmailAccountPreviewBar;
