import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import useFetch from 'hooks/useFetch';
import { toggleModal } from 'data/actions/modals';
import { getConnectedAccounts } from 'data/actions/integrations';
import { connectedAccountsSelector } from 'data/selectors/integrations';
import { connectedAccountsLoadingSelector } from 'data/selectors/loading';

const useAddEmailAccount = () => {
  const dispatch = useDispatch();

  const { data: connectedAccounts, loading } = useFetch({
    action: getConnectedAccounts,
    selector: connectedAccountsSelector,
    loadingSelector: connectedAccountsLoadingSelector,
    cached: true,
  });

  const showModal = useCallback(({ sequenceId }) => {
    if (!loading) {
      if (connectedAccounts?.length) {
        dispatch(toggleModal('email_account_preview_bar', true, { sequenceId }));
      } else {
        dispatch(toggleModal('imap_info', true, { sequenceId }));
      }
    }
  }, [dispatch, loading, connectedAccounts]);

  return {
    loading,
    showModal,
    connectedAccounts,
  };
};

export default useAddEmailAccount;
