import styled from 'styled-components';
import {
  Button,
} from 'antd';
import {
  transparentize,
} from 'polished';
import * as CONSTANTS from '../../constants/styles';

export const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: 100%;
  left: 0;
  top: 0;
  display: flex;
  padding-top: ${CONSTANTS.HEADER_HEIGHT}px;
  box-sizing: border-box;
  background: ${CONSTANTS.COLORS.GREY_G5};
  font-family: AvenirNext;
  flex-direction: column;

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    padding-top: 60px;
    position: relative;
  }
  @media(max-width: ${CONSTANTS.TABLET_MAX_WIDTH}px) {
    margin: 0 auto;
  }
`;

export const HeaderContainer = styled.div`
    width: 100%;
    height: ${CONSTANTS.HEADER_HEIGHT}px;
    background: ${CONSTANTS.COLORS.DARKEST_BLUE};
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    padding-left: ${CONSTANTS.DESKTOP_HORIZONTAL_PADDING}px;
    padding-right: ${CONSTANTS.DESKTOP_HORIZONTAL_PADDING}px;
    box-sizing: border-box;
    z-index: 10;
    color: ${CONSTANTS.COLORS.WHITE};
    justify-content: space-between;

    @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
      display: none;
    }
`;

export const BackButton = styled(Button)`
  display: flex;
  height: 40px;
  color: ${CONSTANTS.COLORS.WHITE};
  border-radius: 4px;
  background-color: ${transparentize(0.9, CONSTANTS.COLORS.WHITE)};
  border: none;
  font-weight: 500;
  align-items: center;
  padding-right: 5px;

  &:hover, &:focus {
    border: inherit;
    color: inherit;
    background-color: ${transparentize(0.8, CONSTANTS.COLORS.WHITE)};
  }
`;

export const Logo = styled.img`
  width: 45px;
  margin-right: 15px;
  cursor: pointer;
  transition: transform 0.1s;

  &:active {
    transform: scale(0.9);
  }
`;
