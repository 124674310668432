import { useHistory, Link } from 'react-router-dom';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from 'components/common';
import CodeInput from 'components/codeInput';
import AuthContainer from 'pages/authorization/AuthContainer';
import { resendVerifyEmail, verifyAccountByCode, signOut } from 'data/actions/user';
import { toggleMessage } from 'data/actions/message';
import { toggleModal } from 'data/actions/modals';
import { accountSelector, authTokenSelector } from 'data/selectors/user';
import { verifyAccountByCodeLoaderSelector } from 'data/selectors/loading';
import {
  formBtnStyle, Header, AuthForm, SubHeader,
} from '../styles';
import { emailProviders } from '../enums';
import { CodeWrapper } from './styles';

const UnconfirmedEmail = ({ email }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector(authTokenSelector);
  const { emailVerified } = useSelector(accountSelector);
  const loading = useSelector(verifyAccountByCodeLoaderSelector);

  const emailAddress = localStorage.getItem('tempEmail') || email;

  const NUMBER_STEP = 5;

  const [code, setCode] = useState('');
  const [resetCode, setResetCode] = useState(false);

  const handleSignOut = () => dispatch(signOut(() => {
    history.push('/sign-up');
  }));

  useEffect(() => {
    if (token && emailVerified) {
      history.push('/');
    }
  }, [token, history, emailVerified]);

  const handleResendVerifyEmail = () => {
    setResetCode(true);
    dispatch(toggleMessage('success', { header: `The verification code was successfully sent to ${emailAddress}` }));
    dispatch(resendVerifyEmail(emailAddress));
  };

  const handleChangeEmail = () => {
    dispatch(toggleModal('change_email', true));
  };

  const handleSetCode = useCallback((value) => {
    setResetCode(false);
    setCode(value);
  }, []);

  const onFailedVerifyEmail = () => {
    dispatch(toggleMessage('error', { header: 'Digit code is invalid or expired' }));
  };

  const handleVerifyEmail = () => {
    dispatch(verifyAccountByCode({ code, onFailed: onFailedVerifyEmail }));
  };

  const providerPrefix = email?.slice(email.indexOf('@'), email.length);
  const link = emailProviders[providerPrefix];

  return (
    <AuthContainer content="confirmEmail" percent={Array(NUMBER_STEP).fill(16.7).reduce((a, b) => a + b, 0)}>
      <AuthForm>
        <Header>Verify your email address</Header>
        <SubHeader>
          <span>
            We’ve just send a 6-digit code to
            {' '}
            <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
          </span>
        </SubHeader>
        <CodeWrapper>
          <CodeInput resetCode={resetCode} size={6} updateCode={handleSetCode} />
        </CodeWrapper>
        <SubHeader>
          <span>Haven’t seen the email? Check your spam folder, </span>
          <span>
            {/* eslint-disable-next-line */}
            <a onClick={handleResendVerifyEmail}>resend code</a>
            {' '}
            or
            {' '}
            {/* eslint-disable-next-line */}
            <a onClick={handleChangeEmail}>change email </a>
          </span>
        </SubHeader>
        <Button
          loading={loading}
          disabled={code.split('').length !== 6}
          data-form="true"
          type="primary"
          style={formBtnStyle}
          onClick={handleVerifyEmail}
        >
          Confirm
        </Button>
        {link && (
        <a href={link} target="_blank" rel="noopener noreferrer nofollow">
          <Button type="normal" style={{ width: '100%', marginTop: '10px' }}>
            Open mailbox
          </Button>
        </a>
        )}
        <SubHeader style={{ marginTop: '20px' }}>
          Already have an account?&nbsp;
          <Link onClick={handleSignOut} to="/login">Sign in</Link>
        </SubHeader>
      </AuthForm>
    </AuthContainer>
  );
};

export default UnconfirmedEmail;
