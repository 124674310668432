import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { toggleMessage } from 'data/actions/message';

const useIncognitoMessage = () => {
  const dispatch = useDispatch();

  const showMessage = useCallback(() => {
    dispatch(toggleMessage('error', { text: "You can't register in incognito mode. Please, use another browser or turn off incognito mode", duration: 15 }));
  }, [dispatch]);

  return showMessage;
};

export default useIncognitoMessage;
