import React, { useState } from 'react';
import FullScreenContainer from 'components/fullScreenContainer';
import ImgManage from 'assets/images/uninstall-sad-face-monochromatic.png';
import ImgSuccessful from 'assets/images/uninstall-heart-face-monochromatic.png';
import { Input } from 'antd';
import { sendUninstallResponse } from 'data/actions/plugin';
import { accountSelector, workspaceIdSelector } from 'data/selectors/user';
import { FULLSCREEN_BUTTONS } from 'constants/fullScreenButtons';

import { useSelector, useDispatch } from 'react-redux';

import {
  Radio,
  RadioGroup,
  Button,
} from 'components/common';

import {
  RadioStyle,
  Header,
  Text,
  ContentImg,
  LogoForm,
  SubmitForm,
  UninstallContainer,
} from './styles';

const ExtensionUninstallFeedback = () => {
  const [includeReason, setIncludeReason] = useState('');
  const [descriptionReason, setDescriptionReason] = useState('');
  const [status, setStatus] = useState(false);
  const dispatch = useDispatch();

  const { TextArea } = Input;
  const {
    _id: loggedUserId,
  } = useSelector(accountSelector);

  const currentUserworkspaceId = useSelector(workspaceIdSelector);

  const handleSendReason = () => {
    const body = {
      userId: loggedUserId,
      workspaceId: currentUserworkspaceId,
      message: descriptionReason,
      uninstallReason: includeReason,
    };
    dispatch(sendUninstallResponse(body));
    setStatus(true);
  };

  const renderUninstallPage = () => {
    if (status) {
      return (
        <UninstallContainer style={{ flexDirection: 'column' }}>
          <LogoForm>
            <ContentImg
              src={ImgSuccessful}
              onLoad={(e) => {
                e.target.style.display = 'flex';
                e.target.style.marginLeft = '50px';
              }}
            />
          </LogoForm>
          <h2>Thank you for your feedback</h2>
          <p>We have recive your message and will be glad to see you again!</p>
        </UninstallContainer>
      );
    }
    return (
      <UninstallContainer>
        <LogoForm>
          <ContentImg
            src={ImgManage}
            onLoad={(e) => {
              e.target.style.display = 'flex';
            }}
          />
          <Header> We are sorry to see you go... </Header>
          <Text> Before you go, please let us know how we can improve. </Text>
        </LogoForm>
        <SubmitForm>
          <Header>
            Why are you uninstalling GetProspect extension?
          </Header>
          <RadioGroup
            onChange={(e) => setIncludeReason(e.target.value)}
            value={includeReason}
            style={{ margin: '20px 20px 20px 0' }}
          >
            <Radio style={RadioStyle} value="The extension doesn’t work for me.">
              <Text>The extension doesn’t work for me.</Text>
            </Radio>
            <Radio style={RadioStyle} value="It’s too complicated to use.">
              <Text>It’s too complicated to use.</Text>
            </Radio>
            <Radio style={RadioStyle} value="Email discovery rate/accuracy is too low.">
              <Text>Email discovery rate/accuracy is too low.</Text>
            </Radio>
            <Radio style={RadioStyle} value="I finished the work I have to do.">
              <Text>I finished the work I have to do.</Text>
            </Radio>
            <Radio style={RadioStyle} value="Some features are missing.">
              <Text>Some features are missing.</Text>
            </Radio>
            <Radio style={RadioStyle} value="I switched to another tool.">
              <Text>I switched to another tool.</Text>
            </Radio>
            <Radio style={RadioStyle} value="Other">
              <Text>Other</Text>
            </Radio>
          </RadioGroup>
          <Text style={{ margin: '0 0 10px 0' }}>What can we do better?</Text>
          <TextArea
            rows={4}
            placeholder="How can we improve the experience with the extension?"
            value={descriptionReason}
            size="small"
            onChange={(e) => {
              setDescriptionReason(e.target.value);
            }}
          />
          <Button
            data-form="true"
            type="primary"
            disabled={false}
            onClick={handleSendReason}
            style={{ marginTop: '10px', width: '190px' }}
          >
            Send
          </Button>
        </SubmitForm>
      </UninstallContainer>

    );
  };

  return (
    <FullScreenContainer redirectBack="/contacts/filter/all" typeButton={FULLSCREEN_BUTTONS.BACK}>
      { renderUninstallPage() }
    </FullScreenContainer>
  );
};

export default ExtensionUninstallFeedback;
