import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';

import { toggleModal } from 'data/actions/modals';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { getSequenceLeads, resumeSequenceLeads } from 'data/actions/sequences';
import { Container } from '../deleteStep/styles';
import {
  ModalTitle, CloseIcon, Footer,
} from '../baseModal/styles';

const ResumeSequenceContactsModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { sequenceId, contactIds, setSelectedRowKeys } = useSelector(modalOptionsSelector);

  const handleClose = () => dispatch(toggleModal('resume_sequence_contacts', false));
  const handleResume = () => {
    dispatch(resumeSequenceLeads(sequenceId, contactIds, (result) => {
      if (!result.pending) {
        dispatch(getSequenceLeads({ pageNumber: 1 }));
      }
    }));
    dispatch(toggleModal('resume_sequence_contacts', false));
    if (setSelectedRowKeys) setSelectedRowKeys([]);
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Resume
          {' '}
          {contactIds.length === 1 ? 'contact' : 'contacts'}
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <Container>
        <div>
          You&#39;re about to resume the
          {' '}
          {contactIds.length === 1 ? 'contact' : 'contacts'}
          .
        </div>

      </Container>
      <Footer>
        <Button
          data-form="true"
          type="primary"
          size="large"
          onClick={handleResume}
        >
          Resume
        </Button>
        <Button
          size="large"
          type="normal"
          onClick={handleClose}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default ResumeSequenceContactsModal;
