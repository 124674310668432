import React, { useEffect, useRef } from 'react';

const ClickAwayListener = ({
  as = 'div',
  onClickAway,
  mouseEvent = 'click',
  ...props
}) => {
  const node = useRef(null);

  useEffect(() => {
    function handleEvents(event) {
      if (node.current && node.current.contains(event.target)) {
        return;
      }
      onClickAway(event);
    }

    document.addEventListener(mouseEvent, handleEvents);

    return () => {
      document.removeEventListener(mouseEvent, handleEvents);
    };
  }, [mouseEvent, onClickAway]);

  return React.createElement(as, { ref: node, ...props });
};

export default ClickAwayListener;
