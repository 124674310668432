import React, { useCallback } from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/loading';
import { toggleModal } from 'data/actions/modals';
import { requiredInputs } from 'helpers/companyHelpers';
import { Option, Select, Input } from 'components/common';
import { contactSelector } from 'data/selectors/contacts';
import { addCompaniesToContact } from 'data/actions/contacts';
import { addCompaniesToContactLoaderSelector } from 'data/selectors/loading';
import {
  ContactPropertyContainer,
  PropertyLabel,
  ExistedContact,
  ContactMessage,
  ContactInfo,
  CompanyName,
  CompanyLabel,
  NotRequiredPropertyContainer,
  BlockScreen,
} from '../styles';
import CompanyIcon from '../../companyIcon';

const CreateCompanyBar = ({
  properties = [],
  company,
  updateCompany,
  existedCompany,
}) => {
  const dispatch = useDispatch();

  const contact = useSelector(contactSelector);
  const loading = useSelector(addCompaniesToContactLoaderSelector);

  const requiredProperties = properties.filter(({ name }) => requiredInputs.includes(name));
  const notRequiredProperties = properties.filter(({ name }) => !requiredInputs.includes(name));
  const visibleBlock = (!get(company, 'domain', '').length && !get(company, 'name', '').length) || !!existedCompany;
  const existedCompanyInContact = existedCompany ? get(contact, 'companyRelations', []).find((companyRelation) => companyRelation.company._id === existedCompany._id) : null;

  const handleAddCompany = () => {
    if (!existedCompanyInContact) {
      dispatch(addCompaniesToContact(contact._id, [{ companyId: existedCompany._id }], () => {
        dispatch(toggleModal('contact_preview', true, { contactId: contact._id }));
      }));
    }
  };

  const propertyInputs = useCallback((propertyItems) => propertyItems.map((item) => {
    let inputValue = '';

    if (requiredInputs.includes(item.name)) {
      inputValue = get(company, item.name);
    } else if (existedCompany) {
      inputValue = get(existedCompany, `properties[${item.name}].value`, '');
    } else {
      inputValue = get(company, item.name);
    }

    return (
      <ContactPropertyContainer key={item._id}>
        <PropertyLabel>
          {item.label}
          {(item.required) ? '*' : ''}
        </PropertyLabel>
        {(item.options && item.options.length > 0) ? (
          <Select
            size="large"
            value={get(company, item.name)}
            onChange={(selectedValue) => {
              const newContact = { ...company };
              newContact[item.name] = selectedValue;
              updateCompany(newContact);
            }}
          >
            { item.options.map((option) => <Option value={option.value} key={get(option, '_id')}>{option.label}</Option>) }
          </Select>
        ) : (
          <Input
            size="large"
            value={inputValue}
            onChange={(e) => {
              const newContact = { ...company };
              newContact[item.name] = e.target.value;
              updateCompany(newContact);
            }}
            tabIndex={visibleBlock ? -1 : 1}
          />
        )}
      </ContactPropertyContainer>
    );
  }), [company, updateCompany, visibleBlock, existedCompany]);

  return (
    <>
      {propertyInputs(requiredProperties)}
      <div>
        {
          existedCompany && (
            <ExistedContact>
              <ContactMessage>
                {existedCompanyInContact ? 'Contact has already company:' : 'Gosh, that looks familiar. Would you like to add:'}
              </ContactMessage>
              <Loader loading={loading}>
                <ContactInfo onClick={handleAddCompany} enabled={!existedCompanyInContact}>
                  <CompanyIcon
                    domain={get(existedCompany, 'properties.domain.value')}
                    code={get(existedCompany, '_id')}
                    name={get(existedCompany, 'properties.name.value[0]', 'G').toUpperCase()}
                  />
                  <CompanyName>
                    <CompanyLabel>
                      {get(existedCompany, 'properties.name.value')}
                    </CompanyLabel>
                    <CompanyLabel>{get(existedCompany, 'properties.domain.value')}</CompanyLabel>
                  </CompanyName>
                </ContactInfo>
              </Loader>
            </ExistedContact>
          )
        }
      </div>
      <NotRequiredPropertyContainer>
        <BlockScreen
          visible={visibleBlock}
          existedCompany={!!existedCompany}
        />
        {propertyInputs(notRequiredProperties)}
      </NotRequiredPropertyContainer>
    </>
  );
};

export default CreateCompanyBar;
