import React from 'react';

import Connect from '../../common/components/connect';

const HardApp = ({
  sharedInformation,
  onFinish,
  setNextStep,
  stepNumber,
  onClose,
}) => {
  const steps = {
    1: {
      component: Connect,
      params: {
        description: "We are sorry to hear that. :( <br /> Could you, please, specify what issue you've faced? Let us help you as much as we can. You can contact us and we will try to solve this problem.",
        onNext: setNextStep,
        onClose,
      },
    },
  };

  const step = steps[stepNumber];

  if (!step) {
    const needToAct = !sharedInformation;
    onFinish({ needToAct });
    return null;
  }

  const { component: Component, params = {} } = step;

  // eslint-disable-next-line
  return <Component {...params} />;
};

export default HardApp;
