import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { dateFormat } from 'helpers/dateFormat';
import { getFullName } from 'helpers/getFullName';
import { toggleModal } from 'data/actions/modals';
import { Icon, Radio, RadioGroup } from 'components/common';
import { stepTemplatesSelector } from 'data/selectors/sequences';
import {
  IconContainer,
  InfoItemContainer, InfoItemText, RadioWrapper, TemplateItemContainer, TemplateList, TitleItemText,
} from './styles';

export const StepTemplateItem = ({
  id, name, createdAt, owner, onClick, active,
}) => {
  const dispatch = useDispatch();

  return (
    <TemplateItemContainer active={active} onClick={() => onClick({ target: { value: id } })}>
      <div style={{ display: 'flex' }}>
        <RadioWrapper>
          <Radio size="default" value={id} />
        </RadioWrapper>
        <InfoItemContainer>
          <TitleItemText>{name}</TitleItemText>
          <InfoItemText>
            {dateFormat(moment(createdAt))}
            {', '}
            by
            {' '}
            {getFullName(owner)}
          </InfoItemText>
        </InfoItemContainer>
      </div>
      <IconContainer onClick={() => dispatch(toggleModal('delete_step_template', true, { backModal: 'add_template_step_preview', templateId: id, templateName: name }))}>
        <Icon size="small" type="ic-delete" />
      </IconContainer>
    </TemplateItemContainer>
  );
};

const StepTemplateList = ({ onChange, value }) => {
  const stepTemplates = useSelector(stepTemplatesSelector);

  return (
    <TemplateList>
      <RadioGroup onChange={onChange} value={value}>
        {
          stepTemplates && stepTemplates.length > 0
            ? stepTemplates.map((stepTemplate) => (
              <StepTemplateItem
                active={stepTemplate._id === value}
                key={`step_template_${stepTemplate._id}`}
                onClick={onChange}
                id={stepTemplate._id}
                name={stepTemplate.name}
                updatedAt={stepTemplate.updatedAt}
                owner={stepTemplate.owner}
                createdAt={stepTemplate.createdAt}
              />
            )) : null
        }
      </RadioGroup>
    </TemplateList>
  );
};

export default StepTemplateList;
