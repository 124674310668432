const usePasswordValidation = (password) => {
  const checkedPassword = password || '';
  const validLength = checkedPassword.length >= 8;
  const lowercase = (/[a-z]/.test(checkedPassword));
  const uppercase = (/[A-Z]/.test(checkedPassword));
  const number = (/[0-9]/.test(checkedPassword));
  const space = checkedPassword.includes(' ');
  // eslint-disable-next-line no-useless-escape
  const specialCharter = (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/).test(checkedPassword);
  return {
    valid: validLength && uppercase && lowercase && (specialCharter || number || space),
    length: validLength,
    uppercase,
    lowercase,
    specialCharter: specialCharter || number || space,
  };
};

export default usePasswordValidation;
