import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.BLACK_G1};
  margin-bottom: 5px;
`;

export const Description = styled.div`
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.GREY_G2};
`;
