import styled from 'styled-components';

import { COLORS } from 'constants/styles';
import { NotificationType } from '../notificationsPreviewBar/styles';

export const SearchHistoryType = styled(NotificationType)`
  color: ${COLORS.BLACK_G1};
  display: flex;
  margin-right: 5px;
  word-break: break-all;
  padding: 0;
  font-size: 14px;
`;

export const Field = styled.div`
  ${(props) => props.hasActions && `
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const tagStyles = {
  maxWidth: 114,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginRight: 5,
  opacity: 1,
  color: COLORS.GREY_G1,
  backgroundColor: COLORS.GREY_G14,
  fontSize: 12,
  border: 'none',
  lineHeight: '24px',
  display: 'inline-block',
};

export const TagPrimaryStyles = {
  ...tagStyles,
  color: COLORS.BLACK_G1,
};

export const TagDangerStyles = {
  ...tagStyles,
  color: COLORS.GREY_G2,
  backgroundColor: COLORS.GREY_G14,
};

export const TagMoreStyles = {
  ...TagPrimaryStyles,
  cursor: 'pointer',
};

export const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const FilterName = styled.span`
  margin-right: 2px;
  white-space: nowrap;
`;

export const ListText = styled.span`
  margin-right: 4px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'default')};

  ${(props) => props.disabled && `
    a {
      text-decoration: none !important;
    }
  `}

  &:not(:last-child) {
    &::after {
      content: ',';
    }
  }
`;

export const FieldListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ListContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

export const ListLabel = styled.span`
  margin-right: 10px;
  align-self: flex-start;
  width: 92px;
  color: ${COLORS.GREY_G2};
  font-weight: 400;
  font-size: 14px;
`;

export const FieldDataTitle = styled(ListLabel)``;

export const FieldDataWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;
export const FieldDataDescription = styled.span`
  align-self: flex-end;
  flex: 1;
  color: ${COLORS.BLACK_G1};
  font-size: 14px;
  font-weight: 400;
`;

export const ListsFieldsWrapper = styled.div`
  align-self: flex-end;
  flex: 1;
`;

export const TagsActivityWrapper = styled(ListsFieldsWrapper)`
`;

export const WrapperEmptySections = styled.div`
  .empty-recent-activity {
    background-color: ${COLORS.GREY_G5};
    height: 100%;

    .empty-icon {
      margin-bottom: 0;
    }

    .empty-text {
      color: ${COLORS.GREY_G2};
      font-size: 16px;
      max-width: 300px;
    }

    .empty-header {
      font-size: 20px;
    }
  }
`;

export const DividerStyle = {
  marginTop: 15,
  marginBottom: 15,
};
