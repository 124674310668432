import get from 'lodash/get';
import { createSelector } from 'reselect';

const baseInvoicesSelector = (state) => get(state, 'invoices.invoices', {});
const baseInvoiceSelector = (state) => get(state, 'invoices', {});
export const invoicesSelector = createSelector(
  baseInvoicesSelector,
  (invoices) => invoices,
);

export const countInvoicesSelector = createSelector(
  invoicesSelector,
  (invoices) => invoices.data?.length,
);

export const hadSuccessfullInvoiceSelector = createSelector(
  invoicesSelector,
  (invoices) => get(invoices, 'data', []).some(({ status }) => status === 'approved'),
);

export const isExistsFSInvoiceSelector = createSelector(
  baseInvoiceSelector,
  (invoices) => get(invoices, 'fsInvoiceExists', false),
);
