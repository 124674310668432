import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useRef } from 'react';

import { Logo } from 'components/newIcons';
import useTinyForm from 'hooks/useTinyForm';
import { Label } from 'components/commonStyles';
import { Password, Button } from 'components/common';
import { passwordLengthValidate } from 'helpers/validate';
import { checkPasswordWithToken, resetPasswordWithToken } from 'data/actions/user';
import { toggleMessage } from 'data/actions/message';
import {
  AuthForm,
  fieldStyle,
  Header,
  formBtnStyle,
} from '../../styles';

const EnterPasswordPage = ({ token, match, dashboard }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const resetToken = useRef(dashboard ? (match?.params?.token || false) : token);

  const onSuccess = () => {
    dispatch(toggleMessage('success', { header: 'Password was updated successfully' }));
    return history.push('/');
  };

  const onFailed = (setErrors) => setErrors('password', 'Something went wrong');

  const compareTwoPasswords = useCallback((value, data) => value === data.password, []);

  const {
    data,
    errors,
    handleSubmit,
    handleChange,
    isDisabledForm,
  } = useTinyForm({
    schema: {
      password: {
        value: '',
        dependencies: ['repeatPassword'],
        conditions: [
          {
            label: 'Must contain min 8 symbols',
            condition: passwordLengthValidate,
          },
        ],
      },
      repeatPassword: {
        value: '',
        conditions: [
          {
            label: 'Password and repeat password do not match',
            condition: compareTwoPasswords,
          },
        ],
      },
    },
    onSubmit: ({ data: _data, setErrors }) => dispatch(resetPasswordWithToken({
      newPassword: _data.password,
      token: resetToken.current,
      onSuccess,
      onFailed: () => onFailed(setErrors),
    })),
  });

  useEffect(() => {
    dispatch(checkPasswordWithToken({
      token: resetToken.current,
      onFailed: () => {
        history.push('/verification?invalidToken="true"');
      },
    }));
  }, [dispatch, history]);

  return (
    <AuthForm>
      <Logo />
      <Header> Please, create new password </Header>
      <Label>Password</Label>
      <Password
        name="password"
        autoComplete="off"
        value={data.password}
        onChange={handleChange}
        style={fieldStyle}
        error={errors.password}
        autoFocus
      />
      <Label>Repeat Password</Label>
      <Password
        name="repeatPassword"
        autoComplete="off"
        value={data.repeatPassword}
        onChange={handleChange}
        error={errors.repeatPassword}
        style={fieldStyle}
      />
      <Button
        type="primary"
        disabled={isDisabledForm}
        style={formBtnStyle}
        onClick={handleSubmit}
      >
        {`${dashboard ? 'Create' : 'Update'} password`}
      </Button>
      {!dashboard && (
        <Button
          type="normal"
          style={formBtnStyle}
        >
          <Link to="/login">Back to sign in</Link>
        </Button>
      )}
    </AuthForm>
  );
};

export default EnterPasswordPage;
