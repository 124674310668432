import { COLORS } from 'constants/styles';
import styled from 'styled-components';

export const Hint = styled.span`
  display: block;
  margin-top: -15px;
  padding-left: 15px;
  padding-bottom: 10px;
  color: ${COLORS.GREY_G2};
  font-weight: 500;
`;
