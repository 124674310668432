import styled from 'styled-components';
import * as CONSTANTS from '../../../constants/styles';
import {
  Label,
} from '../baseModal/styles';

export const TabLabelLight = styled(Label)`
  color: ${CONSTANTS.COLORS.GREY_G1};
  font-weight: 300;
  margin: 0;
  cursor: inherit;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .ant-tabs-bar {
    margin-bottom: 0;
  }

  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active.ant-tabs-tab {
    background: ${CONSTANTS.COLORS.WHITE};
    border-bottom: solid 1px ${CONSTANTS.COLORS.WHITE};
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    height: 68px;
    padding: 0 20px;
    line-height: 68px;
    background: ${CONSTANTS.COLORS.GREY_G5};
    border: solid 1px ${CONSTANTS.COLORS.GREY_G4};
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 1ms cubic-bezier(0.645, 0.045, 0.355, 1);
    &:first-child {
      border-radius: 2px 0 0 0;
    }
    &:last-child {
      border-left: none;
      border-radius: 0 2px 0 0;
    } 
  }

  .ant-tabs-ink-bar {
    background-color: ${CONSTANTS.COLORS.GREY_G4};
  }

  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
    height: 68px;
  }

  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container .ant-tabs-nav {
    width: 100%;

    > div {
    display: flex;
      .ant-tabs-tab {
        display: flex;
        align-items: center;
        width: 50%;
        /* border-bottom: solid 1px #DFE2E5; */
        /* margin-bottom: 1px; */
      }
    }
  }
  
  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
      height: 50px;
    }
  
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
      height: 50px;
      
      ${TabLabelLight} {
        display: none;
      }
    }
    
    .ant-tabs-tab:first-child {
      margin-right: 6px !important;
    }
    
    .ant-tabs-tab:last-child {
      border-left: solid 1px ${CONSTANTS.COLORS.GREY_G4} !important;
    }
    
    .ant-tabs-nav-container-scrolling {
      padding: 0;
    }
    
    .ant-tabs-tab-arrow-show {
      display: none;
    }
  }
`;

export const TabLabel = styled(Label)`
  font-weight: 600;
  color: ${CONSTANTS.COLORS.BLACK_G1};
  margin: 0;
  cursor: inherit;
`;

export const TabLabelContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  padding: 0 20px;
  
  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    padding: 0 0 0 5px;
  }
`;
