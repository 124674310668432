export const propertyFields = [
  {
    label: 'Multi-line text',
    value: 'textarea',
  },
  {
    label: 'Single-line text',
    value: 'text',
  },
  {
    label: 'Date picker',
    value: 'date',
  },
  {
    label: 'Number',
    value: 'number',
  },
  {
    label: 'Dropdown select',
    value: 'select',
  },
  {
    label: 'Multiple checkboxes',
    value: 'checkbox',
  },
  {
    label: 'Single checkbox',
    value: 'booleancheckbox',
  },
  {
    label: 'Link',
    value: 'url',
  },
];
