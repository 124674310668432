export const TYPE = Object.freeze({
  IMPORT: 'import',
  ADD_TO_STATIC_LIST: 'add to static list',
  DELETE_FROM_STATIC_LIST: 'delete from static list',
  MOVE_TO_STATIC_LIST: 'move to static list',
  UPDATE: 'update',
  DELETE: 'delete',
  INSIGHT: 'insight',
  EXPORT: 'export',
  RESTORE: 'restore',
  PERMANENT_DELETE: 'permanent delete',
  ADD_TO_SEQUENCE: 'add to sequence',
  DELETE_SEQUENCES: 'delete sequences',
  DELETE_SEQUENCE_CONTACTS: 'delete sequence contacts',
  PAUSE_SEQUENCE_CONTACTS: 'pause sequence contacts',
  RESUME_SEQUENCE_CONTACTS: 'resume sequence contacts',
  PAUSE_SEQUENCES: 'pause sequences',
  RESUME_SEQUENCES: 'resume sequences',
  UNSUBSCRIBE_SEQUENCE_CONTACTS: 'unsubscribe sequence contacts',
});

export const FROM = Object.freeze({
  CONTACTS: 'contacts',
  COMPANIES: 'companies',
  SEARCH: 'search',
  NOTIFICATION: 'notification',
});

export const SOURCE = Object.freeze({
  CONTACT: 'contact',
  COMPANY: 'company',
  CONTACTS: 'contacts',
  COMPANIES: 'companies',
});
