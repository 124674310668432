import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { ACCOUNT_STATUS } from 'data/types/user.types';
import { disableConnectedAccount } from 'data/actions/integrations';

const useUpdateAccountStatus = ({
  _id,
  status,
  accountId,
  countCampaigns,
  connectGmailAccount,
}) => {
  const dispatch = useDispatch();

  const updateAccountStatus = useCallback((checked, e) => {
    e.stopPropagation();
    if (checked) {
      if (status === ACCOUNT_STATUS.DISCONNECT) {
        if (accountId) {
          connectGmailAccount();
        } else {
          dispatch(toggleMessage('info', { header: 'Update SMTP/IMAP settings and try to activate again' }));
          dispatch(toggleModal('email_account_preview_bar', true, { accountId: _id, baseTab: 'smtp_imap' }));
        }
      } else {
        dispatch(disableConnectedAccount(_id, { disabled: false }));
      }
    } else if (countCampaigns === 0) {
      dispatch(disableConnectedAccount(_id, { disabled: true }));
    } else {
      dispatch(toggleModal('disable_email_account', true, { accountId: _id }));
    }
  }, [dispatch, status, _id, accountId, connectGmailAccount, countCampaigns]);

  return updateAccountStatus;
};

export default useUpdateAccountStatus;
