import { Modal } from 'antd';
import get from 'lodash/get';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Input, Button } from 'components/common';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import {
  listsSelector,
} from 'data/selectors/lists';
import { cloneList, setCurrentList } from 'data/actions/lists';
import { toggleMessage } from 'data/actions/message';
import { toggleModal } from 'data/actions/modals';
import { useHistory } from 'react-router-dom';
import {
  CloseIcon,
  ModalTitle,
  Label,
  Warning,
  InputStyle,
  Footer,
} from '../baseModal/styles';

const CloneListModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { data: lists } = useSelector(listsSelector);
  const modalOptions = useSelector(modalOptionsSelector);
  const history = useHistory();

  const listId = get(modalOptions, 'listId', '');
  const currentList = lists.find((item) => item._id === listId);
  const listName = get(currentList, 'name', '');

  const [name, updateName] = useState('');

  useEffect(() => {
    if (listName) updateName(`${listName} clone`);
  }, [listName]);
  const isExist = lists.findIndex((item) => item.name === name);

  const handleCloneList = useCallback(() => {
    dispatch(toggleModal('clone_list_modal', false));
    dispatch(cloneList({ name, listId }, (data) => {
      dispatch(setCurrentList(data, true));
      dispatch(toggleMessage('success', { header: 'List was cloned' }));
      history.push(`/contacts/list/${get(data, '_id')}`);
    }));
  }, [dispatch, listId, name, history]);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Clone the list
          <CloseIcon
            onClick={() => dispatch(toggleModal('clone_list_modal', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={() => dispatch(toggleModal('clone_list_modal', false))}
    >
      <>
        <Label>Name*</Label>
        <Input
          autoFocus
          onChange={(e) => {
            updateName(e.target.value);
          }}
          value={name}
          placeholder="Type the filter name..."
          style={InputStyle}
        />

        {isExist >= 0 && (
          <Warning>
            {`${name} already exists. Please choose a new name.`}
          </Warning>
        )}
      </>
      <Footer>
        <Button
          data-form={!name || isExist >= 0 ? 'false' : 'true'}
          type="primary"
          onClick={handleCloneList}
          disabled={!name || isExist >= 0}
        >
          Save
        </Button>
        <Button type="normal" onClick={() => dispatch(toggleModal('clone_list_modal', false))}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default CloneListModal;
