import capitalize from 'lodash/capitalize';

import { getTextContentOfReactElement } from 'helpers/strings';

const sendNotificationLog = (body) => (dispatch) => {
  dispatch({
    type: 'LOG_NOTIFICATIONS',
    payload: {
      endpoint: 'api/v1/notifications/log',
      method: 'POST',
      body,
    },
  });
};

export const toggleMessage = (type, options) => (dispatch) => {
  dispatch({
    type: 'TOGGLE_MESSAGE',
    data: {
      type,
      options,
    },
  });

  dispatch(sendNotificationLog({
    type,
    header: getTextContentOfReactElement(options?.header || capitalize(type)),
    location: window.location.href,
    text: getTextContentOfReactElement(options?.text || options.header),
  }));
};

export const clearMessage = (index) => (dispatch) => {
  dispatch({
    type: 'CLEAR_MESSAGE',
    data: index,
  });
};
