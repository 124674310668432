// eslint-disable-next-line import/prefer-default-export
export const toggleModal = (type, value, options) => (dispatch) => {
  dispatch({
    type: 'TOGGLE_MODAL',
    data: {
      type,
      value,
      options,
    },
  });
};

export const closeAnyModal = () => (dispatch) => {
  dispatch({
    type: 'TOGGLE_MODAL',
    data: {
      type: null,
      value: false,
    },
  });
};
