import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { disableConnectedAccount } from 'data/actions/integrations';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import {
  ModalTitle, CloseIcon, Footer,
} from '../baseModal/styles';

const DisableEmailAccountModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { accountId } = useSelector(modalOptionsSelector);

  const handleClose = () => dispatch(toggleModal('disable_email_account', false));

  const handleDelete = () => {
    dispatch(disableConnectedAccount(accountId, { disabled: true }));
    handleClose();
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Disable email account
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        Are you sure you want to disable this email account?
        <br />
        All sequences related to this account will be paused
      </div>
      <Footer>
        <Button
          type="danger"
          size="large"
          data-form="true"
          onClick={handleDelete}
        >
          Disable
        </Button>
        <Button
          size="large"
          type="default"
          onClick={handleClose}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default DisableEmailAccountModal;
