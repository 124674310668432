import getRelevantCompanyRelation from 'helpers/table/getRelevantCompanyRelation';
import get from 'lodash/get';
import { createSelector } from 'reselect';

const baseContactsSelector = (state) => get(state, 'contacts.contacts', {});
export const contactsSelector = createSelector(
  baseContactsSelector,
  (contacts) => contacts,
);

export const contactsFiltersSelector = createSelector(
  baseContactsSelector,
  (contacts) => get(contacts, 'filters', []),
);

export const totalContactsSelector = createSelector(
  baseContactsSelector,
  (contacts) => get(contacts, 'totalItems', 0),
);

export const sortContactsSelector = createSelector(
  baseContactsSelector,
  (contacts) => get(contacts, 'sort', {}),
);

const baseContactsFilterSelector = (state) => get(state, 'contacts.savedFilters', []);
export const contactsFilterSelector = createSelector(
  baseContactsFilterSelector,
  (filters) => filters,
);

const baseContactsSelectedListSelector = (state) => get(state, 'contacts.selectedList', {});
export const getSelectedList = createSelector(
  baseContactsSelectedListSelector,
  (selectedList) => selectedList,
);

const baseContactSelector = (state) => get(state, 'contacts.contactPreview', {});
export const contactSelector = createSelector(
  baseContactSelector,
  (contactPreview) => contactPreview,
);

export const contactPopulatedListsSelector = createSelector(
  contactSelector,
  (contactPreview) => get(contactPreview, 'populatedLists', []),
);

export const contactPreviewSequencesSelector = createSelector(
  contactSelector,
  (contactPreview) => get(contactPreview, 'sequences', []),
);

export const contactPreviewHistorySelector = createSelector(
  contactSelector,
  (contactPreview) => get(contactPreview, 'history', []),
);

export const contactPreviewSavedColleaguesSelector = createSelector(
  contactSelector,
  (contactPreview) => get(contactPreview, 'savedColleagues', {}),
);

export const contactPreviewPositionsSelector = createSelector(
  contactSelector,
  (contactPreview) => get(contactPreview, 'contactPositions', []),
);

export const contactPreviewCountriesSelector = createSelector(
  contactSelector,
  (contactPreview) => get(contactPreview, 'contactCountries', []),
);

export const contactPreviewColleaguesSelector = createSelector(
  contactSelector,
  (contactPreview) => get(contactPreview, 'colleagues', {}),
);

export const contactPositions = createSelector(
  contactSelector,
  (contactPreview) => {
    const positions = get(contactPreview, 'companyRelations', []).map((relation) => relation.position).filter(Boolean);
    return positions;
  },
);

export const contactPrimaryPosition = createSelector(
  contactSelector,
  contactPositions,
  (contactPreview, positions) => {
    const companyRelation = getRelevantCompanyRelation(contactPreview.companyRelations);
    const relevantPosition = get(companyRelation, 'position', ' ');
    const primaryPosition = get(contactPreview, 'primaryPosition', {});
    return {
      value: primaryPosition.value || relevantPosition,
      options: [...new Set([...(primaryPosition?.options || []), ...positions])],
    };
  },
);

const baseContactIdSelector = (state) => get(state, 'contacts.contactId', []);
export const contactIdSelector = createSelector(
  baseContactIdSelector,
  (contactId) => contactId,
);

export const contactsSelectedColumnsSelector = createSelector(
  baseContactsSelector,
  (contacts) => contacts.selectedProperties,
);

const baseContactsSavedFiltersSelector = (state) => get(state, 'contacts.showAllSavedFilters', false);
export const contactsIsShowAllSavedFilters = createSelector(
  baseContactsSavedFiltersSelector,
  (showAllSavedFilters) => showAllSavedFilters,
);

const baseSelectedContactsSelector = (state) => get(state, 'contacts.selectedContacts', []);
export const selectedContactsSelector = createSelector(
  baseSelectedContactsSelector,
  (contacts) => contacts,
);

const baseExcludedContactsSelector = (state) => get(state, 'contacts.excludedContacts', []);
export const excludedContactsSelector = createSelector(
  baseExcludedContactsSelector,
  (contacts) => contacts,
);

const baseOnCheckAllChangeSelector = (state) => get(state, 'contacts.onCheckAllChange', []);
export const onCheckAllChangeSelector = createSelector(
  baseOnCheckAllChangeSelector,
  (onCheckAllChange) => onCheckAllChange,
);

const baseContactPropertiesSelector = (state) => get(state, 'properties.properties', {});
export const contactPropertiesSelector = createSelector(
  baseContactPropertiesSelector,
  (properties) => ({
    ...properties,
    data: properties.filter((item) => item.sourceType === 'contact'),
  }),
);

const baseContactsInsightsSelector = (state) => get(state, 'contacts.insights', []);
export const contactsInsightsSelector = createSelector(
  baseContactsInsightsSelector,
  (insights) => insights,
);

export const contactsSearchSelector = createSelector(
  baseContactsSelector,
  (contacts) => contacts.search,
);

const baseBlockedContactsSelector = (state) => get(state, 'contacts.blockedContacts', {});
export const blockedContactsSelector = createSelector(
  baseBlockedContactsSelector,
  (contacts) => contacts,
);

export const blockedContactsSearchSelector = createSelector(
  baseBlockedContactsSelector,
  (contacts) => contacts.search,
);

export const newContactsSelector = (state) => get(state, 'contacts.newContacts', []);
export const contactsSelectAllContacts = (state) => get(state, 'contacts.selectAllContacts', {});

export const selectedAllContactsSelector = createSelector(
  contactsSelectAllContacts,
  (options) => get(options, 'selectAll', false),
);

const baseSelectedInsightContactsSelector = (state) => get(state, 'contacts.selectedInsightContacts', []);
export const selectedInsightContactsSelector = createSelector(
  baseSelectedInsightContactsSelector,
  (insightContacts) => insightContacts,
);

const baseSelectedFilterSelector = (state) => get(state, 'contacts.selectedFilter', {});
export const selectedFilterSelector = createSelector(
  baseSelectedFilterSelector,
  (filter) => filter,
);

const baseSelectedAdditionalFiltersSelector = (state) => get(state, 'contacts.additionalSelectedFilters', []);
export const selectedAdditionalFiltersSelector = createSelector(
  baseSelectedAdditionalFiltersSelector,
  (filter) => filter,
);

export const initialRequestContactsSelector = createSelector(
  baseContactsSelector,
  (contacts) => get(contacts, 'initialRequest', false),
);

export const initialRequestInsightContactsSelector = createSelector(
  baseContactsInsightsSelector,
  (insights) => get(insights, 'initialRequest', false),
);

const baseDeletedContactsSelector = (state) => get(state, 'contacts.deletedContacts', {});
export const deletedContactsSelector = createSelector(
  baseDeletedContactsSelector,
  (deletedContacts) => deletedContacts,
);

export const deletedContactsSearchSelector = createSelector(
  baseDeletedContactsSelector,
  (deletedContacts) => get(deletedContacts, 'search', false),
);

const baseContactsListsRelationsSelector = (state) => get(state, 'contacts.contacts.data', []);
export const contactsListsRelationsSelector = createSelector(
  baseContactsListsRelationsSelector,
  (contacts) => contacts.map((contact) => get(contact, 'listRelations', [])).filter((relation) => relation.length),
);

const baseContactsAnalytics = (state) => get(state, 'contacts.contactsAnalytics');
export const contactsAnalyticsSelector = createSelector(
  baseContactsAnalytics,
  (contactsAnalytics) => contactsAnalytics,
);

export const recentContactsSelector = (state) => get(state, 'contacts.recentContacts', []);
export const inaccurateEmailsSelector = (state) => get(state, 'contacts.inaccurateEmails', []);
export const fastSearchDatabaseContactsSelector = (state) => get(state, 'contacts.fastSearchDatabaseContacts', []);
export const inaccurateDomainsSelector = (state) => get(state, 'contacts.inaccurateDomains', []);

const baseContactSidebarSelector = (state) => get(state, 'contacts.contactPreviewSidebar');
export const contactSidebarSelector = createSelector(
  baseContactSidebarSelector,
  (contactPreviewSidebar) => contactPreviewSidebar,
);

export const contactSidebarPopulatedListsSelector = createSelector(
  contactSidebarSelector,
  (contactPreviewSidebar) => get(contactPreviewSidebar, 'populatedLists', []),
);

export const contactPreviewSidebarSequencesSelector = createSelector(
  contactSidebarSelector,
  (contactPreviewSidebar) => get(contactPreviewSidebar, 'sequences', []),
);

export const contactPreviewSidebarHistorySelector = createSelector(
  contactSidebarSelector,
  (contactPreviewSidebar) => get(contactPreviewSidebar, 'history', []),
);

export const contactPreviewSidebarSavedColleaguesSelector = createSelector(
  contactSidebarSelector,
  (contactPreviewSidebar) => get(contactPreviewSidebar, 'savedColleagues', {}),
);

export const contactPreviewSidebarColleaguesSelector = createSelector(
  contactSidebarSelector,
  (contactPreviewSidebar) => get(contactPreviewSidebar, 'colleagues', {}),
);
