import React from 'react';
import icon from 'assets/images/new-mail.svg';

export default () => (
  <img
    src={icon}
    alt="Mail icon"
    style={{
      display: 'block',
      margin: '15px auto',
      width: '167px',
      height: '73px',
    }}
  />
);
