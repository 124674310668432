import React from 'react';
import { CONTACT_ICON_COLORS } from 'constants/styles';
import { Icon } from './styles';

const PersonIcon = ({
  name,
  code,
  style = {},
  className,
  size = 32,
  fontSize = 12,
  onClick,
}) => {
  const length = CONTACT_ICON_COLORS.length - 1;
  const colorIndex = code ? code.split('').reduce((accumulator, item) => accumulator + item.charCodeAt(), 0) % length : 0;

  return (
    <Icon
      onClick={onClick}
      fontSize={fontSize}
      size={size}
      className={className}
      style={{
        ...style,
        minWidth: size,
        backgroundColor: CONTACT_ICON_COLORS[colorIndex].backgroundColor,
        color: CONTACT_ICON_COLORS[colorIndex].color,
      }}
      translate="no"
    >
      {' '}
      {name}
      {' '}
    </Icon>
  );
};
export default PersonIcon;
