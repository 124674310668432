import { Modal } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Icon } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { changeStatusSequence } from 'data/actions/sequences';
import { SEQUENCE_STATUS_TYPE } from 'data/types/sequences.types';
import { selectedSequenceIdSelector } from 'data/selectors/sequences';
import {
  ModalTitle,
  ProperCloseIcon,
  ColumnFooter,
  HeaderLabel,
  SubHeaderLabel,
} from '../baseModal/styles';
import { ImageContainer } from './styles';

const PauseEditingStepModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const sequenceId = useSelector(selectedSequenceIdSelector);
  const options = useSelector(modalOptionsSelector);

  const buttonRef = useRef(null);

  const handleClose = () => {
    dispatch(toggleModal('pause_editing_step', false));
  };
  const handlePause = () => {
    dispatch(changeStatusSequence({
      sequenceId,
      status: SEQUENCE_STATUS_TYPE.PAUSED,
      onSuccess: () => {
        handleClose();
        if (options?.onSuccess) options.onSuccess();
      },
    }));
  };

  // it's needed to reset focus of iframe
  useEffect(() => {
    setImmediate(() => {
      if (buttonRef?.current?.buttonNode) {
        buttonRef.current.buttonNode.focus();
      }
    });
  }, []);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle white>
          <ProperCloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      wrapClassName="ant-white-modal"
      closable={false}
      width={345}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        <ImageContainer>
          <Icon outlined size={35} fill="primary" type="ic-pause-circle" />
        </ImageContainer>
        <HeaderLabel center style={{ marginBottom: 12 }}>Pause for editing</HeaderLabel>
        <SubHeaderLabel center style={{ marginBottom: 28 }}>
          Your campaign will be paused for editing
          <br />
          Remember to resume it afterwards
        </SubHeaderLabel>
        <ColumnFooter>
          <Button
            ref={buttonRef}
            type="primary"
            onClick={handlePause}
          >
            Pause & Edit
          </Button>
          <Button type="normal" onClick={handleClose}>Cancel</Button>
        </ColumnFooter>
      </div>
    </Modal>
  );
};

export default PauseEditingStepModal;
