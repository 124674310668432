import daysOption from './daysOfWeek';
import genderOption from './gender';
import yearOption from './year';
import monthOption from './monthOfTheYear';
import ageOption from './age';
import employmentOption from './employment';
import countryOption from './country';

const options = [
  daysOption,
  genderOption,
  yearOption,
  monthOption,
  ageOption,
  employmentOption,
  countryOption,
];

export default options;
