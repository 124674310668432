import React, { useState } from 'react';
import {
  Input, Button, Icon,
} from 'antd';
import { useSelector } from 'react-redux';
import usePasswordValidation from 'helpers/hooks';
import { loginPageLoadingSelector } from 'data/selectors/loading';
import Loader from 'components/loading';

import {
  Container,
  Form,
  Label, PasswordChecked, Text,
} from './styles';

const InvitationPage = () => {
  const isLoading = useSelector(loginPageLoadingSelector);

  const [fisrtName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [password, setPassword] = useState();

  const passwordValidation = usePasswordValidation(password);
  const disabled = !(passwordValidation.valid && fisrtName && lastName);

  return (
    <Container>
      <Form>
        <div>
          <h2> Sign in your account </h2>
          <p> Enter your credentials below </p>
        </div>
        <div>
          <Label> First name </Label>
          <Input
            type="text"
            name="first-name"
            placeholder="John"
            value={fisrtName}
            onChange={(e) => setFirstName(e.target.value)}
          />

          <Label> Last name </Label>
          <Input
            type="text"
            name="last-name"
            placeholder="Doe"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />

          <Label> Password </Label>
          <Input
            type="password"
            name="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <PasswordChecked>
            { passwordValidation.length
              ? <Icon type="check-circle" theme="filled" />
              : <Icon type="check-circle" />}
            <Text>At least 8 characters long</Text>
          </PasswordChecked>
          <PasswordChecked>
            { passwordValidation.lowercase
              ? <Icon type="check-circle" theme="filled" />
              : <Icon type="check-circle" />}
            <Text>One lowercase character</Text>
          </PasswordChecked>
          <PasswordChecked>
            { passwordValidation.uppercase
              ? (<Icon type="check-circle" theme="filled" />)
              : (<Icon type="check-circle" />)}
            <Text>One uppercase character</Text>
          </PasswordChecked>
          <PasswordChecked>
            { passwordValidation.specialCharter
              ? (<Icon type="check-circle-o" theme="filled" />)
              : (<Icon type="check-circle" />)}
            <Text> One number, symbol, or whitespace </Text>
          </PasswordChecked>
        </div>
        <Loader loading={isLoading}>
          <Button
            type="primary"
            style={{ width: '100%' }}
            disabled={disabled}
          >
            {isLoading ? '' : ' Sign in'}
          </Button>
        </Loader>
      </Form>
    </Container>
  );
};

export default InvitationPage;
