import get from 'lodash/get';
import { createSelector } from 'reselect';

const baseMessageOptions = (state) => get(state, 'message.messages', []);
export const messageOptionsSelector = createSelector(
  baseMessageOptions,
  (messages) => messages,
);

const baseMessageType = (state) => get(state, 'message.type', '');
export const messageTypeSelector = createSelector(
  baseMessageType,
  (type) => type,
);
