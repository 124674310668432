import { Modal } from 'antd';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Input } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { addNewWorkspace, switchWorkspace } from 'data/actions/user';
import { toggleMessage } from 'data/actions/message';
import { modalsSelector } from 'data/selectors/modals';
import {
  CloseIcon,
  ModalTitle,
  Label,
  InputStyle,
  Footer,
} from '../baseModal/styles';

const CreateWorkspaceModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const [name, updateName] = useState('');

  const onFailedCreatedWorkspace = () => dispatch(toggleMessage('error', { header: 'Something went wrong' }));

  const onSuccessCreatedWorkspace = (workspace) => dispatch(switchWorkspace({ workspaceId: workspace.workspaceId }));

  const handleCreateWorkspace = () => {
    dispatch(addNewWorkspace({ name, onSuccess: onSuccessCreatedWorkspace, onFailed: onFailedCreatedWorkspace }));
    dispatch(toggleModal('create_workspace', false));
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Add a new workspace
          <CloseIcon
            onClick={() => dispatch(toggleModal('create_workspace', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={() => dispatch(toggleModal('create_workspace', false))}
    >
      <div>
        <>
          <Label>Workspace name</Label>
          <Input
            autoFocus
            onChange={(e) => {
              updateName(e.target.value);
            }}
            value={name}
            placeholder="Your new workspace name"
            style={InputStyle}
          />
        </>
        <Footer>
          <Button
            data-form={!name ? 'false' : 'true'}
            type="primary"
            onClick={handleCreateWorkspace}
            disabled={!name}
          >
            Create New Workspace
          </Button>
          <Button type="normal" onClick={() => dispatch(toggleModal('create_workspace', false))}>Cancel</Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default CreateWorkspaceModal;
