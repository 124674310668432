import styled from 'styled-components';
import * as CONSTANTS from '../../../constants/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #33475b;
  flex: 0 0 70%;
`;

export const EmailImg = styled.img`
  width: 100px;
  margin: 10px 0;
`;

export const Text = styled.div`
  width: 450px;
  text-align: center;
  margin: 5px 0;
`;

export const ButtonStyle = {
  margin: '20px 0',
};

export const ResendButton = styled.button`
  border: none;
  color: #0092ff;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    opacity: .5;
  }
`;

export const Sidebar = styled.div`
  background-color: ${CONSTANTS.COLORS.DARKEST_BLUE};;
  flex: 0 0 30%;
`;

export const Warning = styled.h3`
  color: red;
`;

export const Logo = styled.h3`
  color: #fff;
  font-weight: 600;
  background: #0092ff;
  padding: 10px;
  border-radius: 50%;
  width: 100px;
  font-size: 50px;
  text-align: center;
  height: 100px;
  margin: 0 auto;
  margin-top: 50px;
`;

export const CodeWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
`;
