import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const FILES_HEIGHT = 70;

export const Container = styled.div`
  display: flex;
  height: ${FILES_HEIGHT}px;
  width: 350px;
  overflow-y: auto;
  flex-direction: column;
`;

export const FileContainer = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  padding: 4px 10px 4px 4px;

  &:hover {
    background-color: ${COLORS.GREY_G5};
  }
`;

export const FilePartContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FileNameStyles = {
  fontWeight: 500,
  fontSize: '14px',
  color: COLORS.GREY_G1,
  marginRight: '4px',
  width: '220px',
};

export const FileIconBox = styled.div`
  padding: 2px;
  width: 24px;
  height: 24px;
  background: ${COLORS.GREY_G4};
  border-radius: 2px;
  margin-right: 4px;
`;

export const FileSize = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.GREY_G2};
`;
