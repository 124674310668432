import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
} from 'antd';

import { Button } from 'components/common';
import { stopSearch } from 'data/actions/search';
import { toggleModal } from 'data/actions/modals';
import { SOURCE_TYPE } from 'data/types/source.types';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import {
  ModalTitle,
  CloseIcon,
  Footer,
  Label,
} from '../baseModal/styles';

const StopSearchModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const {
    sourceType,
    searchId,
    backModal,
    backOptions,
  } = useSelector(modalOptionsSelector);

  const name = sourceType === SOURCE_TYPE.CONTACT ? 'Leads' : 'Companies';

  const handleClose = () => {
    if (backModal) {
      dispatch(toggleModal(backModal, true, backOptions));
    } else {
      dispatch(toggleModal('stop_search', false));
    }
  };

  const handleStop = () => dispatch(stopSearch(searchId, () => {
    handleClose();
  }));

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Stop search
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      headerStyle={{ background: 'red' }}
      onCancel={handleClose}
      closeIcon={<div />}
    >
      <>
        <Label>
          You&#39;re about to stop
          {' '}
          {name}
          {' '}
          search
        </Label>
      </>
      <Footer>
        <Button
          data-form="true"
          type="danger"
          size="large"
          onClick={handleStop}
        >
          Stop
        </Button>
        <Button
          size="large"
          type="default"
          onClick={handleClose}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default StopSearchModal;
