import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import LastStepPage from 'pages/authorization/lastStep';

const SignUpLastStepLayout = () => (
  <Switch>
    <Route
      path="/last-step"
      component={LastStepPage}
    />
    <Redirect to="/last-step" />
  </Switch>
);

export default SignUpLastStepLayout;
