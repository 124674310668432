import React from 'react';
import { useSelector } from 'react-redux';

import { cancelSubscriptionSelector } from 'data/selectors/subscriptions';
import Connect from '../../common/components/connect';
import Choose from '../../common/components/choose';

const MissingFeatures = ({
  onFinish,
  setNextStep,
  stepNumber,
  onClose,
}) => {
  const missingFeatures = useSelector(cancelSubscriptionSelector);

  const steps = {
    1: {
      component: Choose,
      params: {
        title: 'Choose missing features',
        type: 'checkbox',
        array: [
          'Phone numbers',
          'Sequences',
          'Private emails',
          'Other',
        ],
        minOtherSymbols: 10,
        field: 'features',
        onNext: setNextStep,
        onClose,
      },
    },
    2: {
      component: Connect,
      params: {
        description: "Thank you very much for the information. We'd like to discuss your suggestions to see how we can implement them into our product. Choose the best way to contact you.",
        onNext: setNextStep,
        onClose,
      },
    },
  };

  const step = steps[stepNumber];

  if (!step) {
    const needToAct = !missingFeatures.sharedInformation;
    onFinish({ needToAct });
    return null;
  }

  const { component: Component, params = {} } = step;

  // eslint-disable-next-line
  return <Component {...params} />;
};

export default MissingFeatures;
