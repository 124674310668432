import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';

import { Icon } from 'components/common';
import { signOut, getWorkspaceActivity } from 'data/actions/user';
import logoIcon from 'assets/images/logo-new.svg';
import { FULLSCREEN_BUTTONS } from 'constants/fullScreenButtons';

import {
  Container,
  HeaderContainer,
  BackButton,
  Logo,
} from './styles';

const FullScreenContainer = ({
  children,
  typeButton,
  redirectBack,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const redirectToHome = () => {
    if (redirectBack) {
      history.push(redirectBack);
    } else {
      dispatch(getWorkspaceActivity({
        onSuccess: (data) => {
          const redirectPage = get(data.find((item) => get(item, 'type', '') === 'page_view'), 'value', false);

          if (redirectPage === history?.location?.pathname) {
            return history.push('/');
          }
          if (redirectPage) {
            return history.push(redirectPage);
          }
          return undefined;
        },
        onFailed: () => history.push('/'),
      }));
    }
  };
  const logOut = () => dispatch(signOut());

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Container {...props}>
      <HeaderContainer className="fullscreen-header">
        <Logo src={logoIcon} onClick={() => (history.push('/'))} />
        {typeButton === FULLSCREEN_BUTTONS.BACK && (
          <BackButton onClick={redirectToHome}>
            Back to dashboard
            <Icon type="ic-chevron-right" fill="white" />
          </BackButton>
        )}
        {typeButton === FULLSCREEN_BUTTONS.LOG_OUT && (
          <BackButton onClick={logOut}>
            Log out
            <Icon style={{ marginLeft: 5 }} size="small" fill="white" type="ic-logout" />
          </BackButton>
        )}
      </HeaderContainer>
      {children}
    </Container>
  );
};

export default FullScreenContainer;
