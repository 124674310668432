import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { Input, Select, Option } from 'components/common';
import { getPropertyGroups } from 'data/actions/properties';
import { modalOptionsSelector } from 'data/selectors/modals';

import {
  PropertiesContainer,
  PropertyItem,
  Label,
} from '../../styles';

const BasicInformation = ({
  sourceType,
  property,
  updateProperty,
  groups,
  fields,
  errors,
}) => {
  const dispatch = useDispatch();
  const [groupsOptions, setGroupsOptions] = useState([]);
  const [headerLabel, setHeaderLabel] = useState('');
  const columnHeader = get(useSelector(modalOptionsSelector), 'columnHeader');

  useEffect(() => {
    dispatch(getPropertyGroups());
  }, [dispatch]);

  useEffect(() => {
    setGroupsOptions(groups.map((group) => ({
      name: group.name,
      sourceType: group.sourceType,
    })));
  }, [groups]);

  useEffect(() => {
    if (columnHeader) {
      updateProperty({
        ...property,
        label: columnHeader,
      });
    }
  }, []); // eslint-disable-line

  const handleChangeInput = (item, value) => {
    if (headerLabel && item.name === 'label') setHeaderLabel('');
    const { name } = item;
    const newProperty = { ...property };
    if (name === 'name') newProperty.label = value;
    newProperty[name] = value;
    updateProperty(newProperty);
  };

  const handleSearch = (value) => {
    let repeats = false;
    const newGroupsOptions = groups
      .map((group) => ({
        name: group.name,
        sourceType: group.sourceType,
      }))
      .filter((group) => {
        if (group.name === value) {
          repeats = true;
        }
        return group.name.includes(value);
      });
    if (value && !repeats) newGroupsOptions.push({ name: value, sourceType: property.sourceType });
    setGroupsOptions(newGroupsOptions);
  };

  useEffect(() => {
    setHeaderLabel(columnHeader);
  }, [columnHeader]);

  return fields.map((item, indx) => {
    const options = item.name === 'group' ? groupsOptions
      .filter((group) => group.sourceType === property.sourceType)
      .map((group) => group.name) : item.options;

    return (
      <PropertiesContainer key={`property_group_${indx + 1}`}>
        <PropertyItem>
          <Label>
            {item.label}
            {item.required ? '*' : ''}
          </Label>
          {
            (options) ? (
              <Select
                style={item.name === 'sourceType' ? { textTransform: 'capitalize' } : {}}
                allowClear={item.name === 'group'}
                placeholder={item.placeholder}
                showSearch={item.name === 'group'}
                onSearch={(value) => handleSearch(value)}
                size="large"
                defaultValue="Choose an option"
                disabled={sourceType && item.name === 'sourceType'}
                value={sourceType && item.name === 'sourceType' ? sourceType : get(property, item.name)}
                onChange={(value) => {
                  const newProperty = { ...property };
                  newProperty[item.name] = value;
                  updateProperty(newProperty);
                }}
              >
                {options.map((option, index) => (
                  <Option
                    style={item.name === 'sourceType' ? { textTransform: 'capitalize' } : {}}
                    value={option}
                    key={`property_item_${index + 1}_option_${option}`}
                  >
                    {option}
                  </Option>
                ))}
              </Select>
            ) : (
              <Input
                error={errors[item.name]}
                placeholder={item.placeholder}
                size="large"
                value={headerLabel && item.name === 'label' ? columnHeader : get(property, item.name)}
                onChange={(e) => handleChangeInput(item, e.target.value)}
              />
            )
          }
        </PropertyItem>
      </PropertiesContainer>
    );
  });
};
export default BasicInformation;
