import { SEARCH_KIND } from 'constants/searchContent';
import { SOURCE_TYPE } from 'data/types/source.types';
import { searchIconData } from 'data/types/searchIcon.data';

export const getSearchIcon = ({ search }) => {
  let iconData;
  const source = search.source || search.sourceType;
  if (search.status === 'failed') {
    iconData = searchIconData.ERROR;
  } else if (search.saved || search.kind === SEARCH_KIND.SEARCH_HISTORY) {
    iconData = source === SOURCE_TYPE.CONTACT ? searchIconData.LEAD_SAVED : searchIconData.COMPANY_SAVED;
  } else if (search.kind === SEARCH_KIND.SEARCH) {
    iconData = searchIconData.SAVED_FILTERS;
  } else if (search.kind === SEARCH_KIND.USER_SEARCH) {
    iconData = source === SOURCE_TYPE.CONTACT ? searchIconData.LEAD_SEARCH : searchIconData.COMPANY_SEARCH;
  }

  return iconData;
};
