import styled from 'styled-components';

export const EditorContainer = styled.div`
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;
