import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
} from 'antd';
import get from 'lodash/get';

import * as CONSTANTS from 'constants/styles';

import {
  Button,
} from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { modalsSelector } from 'data/selectors/modals';
import { addCompanyLoaderSelector } from 'data/selectors/loading';
import { companyPropertiesSelector } from 'data/selectors/companies';
import { requiredInputs, searchSimilarCompany } from 'helpers/companyHelpers';
import { addCompany, searchCompany } from 'data/actions/companies';
import { renameLabel } from 'helpers/renameLabel';
import Component from '../../createContactBar/component';
import CompanyIcon from '../../companyIcon';

import {
  Footer,
  ButtonStyle,
} from './styles';
import {
  BodyStyle,
  ContactInfo,
  ContactPropertyContainer,
  ContactMessage,
  ContactName,
  ExistedContact,
  LeadName,
} from '../../createContactBar/styles';
import {
  CloseIcon,
  ModalTitle,
  Label,
  HeaderStyle,
} from '../baseModal/styles';
import {
  BlockScreen,
  NotRequiredPropertyContainer,
} from '../../addCompaniesToContactBar/styles';

const CreateCompany = () => {
  const dispatch = useDispatch();
  const focusInputRef = useRef();
  const properties = get(useSelector(companyPropertiesSelector), 'data', []);
  const { visible } = useSelector(modalsSelector);
  const loading = useSelector(addCompanyLoaderSelector);

  const [company, updateCompany] = useState({});
  const [existedCompany, setExistedCompany] = useState(null);
  const [animationDone, setAnimationDone] = useState(false);

  const isValid = properties
    .filter((item) => item.required)
    .every((item) => company[item.name] && company[item.name].length > 0) && !existedCompany;
  const requiredProperties = properties.filter(({ name }) => requiredInputs.includes(name));
  const notRequiredProperties = properties.filter(({ name }) => !requiredInputs.includes(name));
  const visibleBlock = (!get(company, 'domain', '').length && !get(company, 'name', '').length) || !!existedCompany;

  const searchSimilarCompanyDebounce = useCallback(debounce((name, domain) => {
    searchSimilarCompany({ name, domain }, (filters) => dispatch(searchCompany({
      filters,
      onSuccess: (data) => {
        if (data?.data?.length) setExistedCompany(data.data[0]);
        else setExistedCompany(null);
      },
    })));
  }, 500), [dispatch, setExistedCompany]);

  useEffect(() => {
    if (company.domain || company.name) {
      searchSimilarCompanyDebounce(company.name, company.domain);
    } else {
      setExistedCompany(null);
    }
  }, [company.name, company.domain, setExistedCompany, searchSimilarCompanyDebounce]);

  useEffect(() => {
    if (animationDone && focusInputRef?.current) {
      focusInputRef.current.focus();
    }
  }, [animationDone]);

  const propertyInputs = useCallback((propertyItems) => propertyItems.map((item) => {
    let inputValue = '';

    if (requiredInputs.includes(item.name)) {
      inputValue = get(company, item.name);
    } else if (existedCompany) {
      inputValue = get(existedCompany, `properties[${item.name}].value`, '');
    } else {
      inputValue = get(company, item.name);
    }

    return (
      <ContactPropertyContainer key={item._id}>
        <Label>
          {renameLabel(item.label, item?.default)}
          {(item.required) ? '*' : ''}
        </Label>
        <Component
          data={company}
          defaultInputValue={inputValue}
          item={item}
          visibleBlock={visibleBlock}
          focusInputRef={(item.name === 'domain') ? focusInputRef : null}
          handleChange={(e) => {
            const newContact = { ...company };
            newContact[item.name] = e.target.value;
            updateCompany(newContact);
          }}
        />
      </ContactPropertyContainer>
    );
  }), [company, updateCompany, focusInputRef, visibleBlock, existedCompany]);

  const companyIconName = get(existedCompany, 'properties.name.value[0]', '')
    || get(existedCompany, 'properties.domain.value[0]', 'G').toUpperCase();

  return (
    <Drawer
      title={(
        <ModalTitle>
          Create company
          <CloseIcon
            data-close="true"
            onClick={() => dispatch(toggleModal('create_company_preview', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      placement="right"
      headerStyle={HeaderStyle}
      bodyStyle={BodyStyle}
      onClose={() => dispatch(toggleModal('create_company_preview', false))}
      closable={false}
      visible={visible}
      width={CONSTANTS.DRAWER_WIDTH}
      afterVisibleChange={(currentVisible) => {
        setAnimationDone(currentVisible);
      }}
    >
      <div>
        {propertyInputs(requiredProperties)}
        <div>
          {
            existedCompany && (
              <ExistedContact>
                <ContactMessage>
                  Gosh, that looks familiar. Did you mean:
                </ContactMessage>
                <ContactInfo
                  onClick={() => {
                    dispatch(toggleModal('company_preview', true, { companyId: existedCompany._id }));
                  }}
                >
                  <CompanyIcon
                    domain={get(existedCompany, 'properties.domain.value')}
                    name={companyIconName}
                    code={get(existedCompany, '_id')}
                  />
                  <ContactName>
                    <LeadName>
                      {get(existedCompany, 'properties.name.value')}
                    </LeadName>
                    <LeadName>{get(existedCompany, 'properties.domain.value')}</LeadName>
                  </ContactName>
                </ContactInfo>
              </ExistedContact>
            )
          }
        </div>
        <NotRequiredPropertyContainer>
          <BlockScreen
            visible={visibleBlock}
            existedCompany={!!existedCompany}
          />
          {propertyInputs(notRequiredProperties)}
        </NotRequiredPropertyContainer>
      </div>
      <Footer>
        <Button
          data-form={!isValid || loading ? 'false' : 'true'}
          type="primary"
          size="large"
          style={ButtonStyle}
          loading={loading}
          disabled={!isValid}
          onClick={() => {
            const companyProperties = Object.keys(company)
              .map((key) => ({ property: key, value: company[key] }));
            dispatch(addCompany({
              properties: companyProperties,
              onSuccess: () => {
                dispatch(toggleModal('create_company_preview', false));
              },
            }));
          }}
        >
          Create company
        </Button>
        <Button
          type="normal"
          size="large"
          style={ButtonStyle}
          onClick={() => dispatch(toggleModal('create_company_preview', false))}
        >
          Cancel
        </Button>
      </Footer>
    </Drawer>
  );
};

export default CreateCompany;
