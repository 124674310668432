export const INSIGHTS_TYPE = Object.freeze({
  INSIGHTS: 'insights',
  SAVED_INSIGHTS: 'savedInsights',
  NEW_INSIGHTS: 'newInsights',
});

export const INSIGHT_INDEXES = Object.freeze({
  newInsights: 0,
  savedInsights: 1,
  insights: 2,
});
