import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/loading';
import { Icon } from 'components/common';
import { COLORS } from 'constants/styles';
import { stopPropagation } from 'helpers/dom';
import { toggleModal } from 'data/actions/modals';
import { formatBytes } from 'helpers/calcHelpers';
import EllipsisText from 'components/ellipsisText';
import fileDownloadByUrl from 'helpers/fileDownloadByUrl';
import useShowPauseWarningIfNeeded from 'hooks/sequences/useShowPauseWarningIfNeeded';
import { deleteFilesFromStepLoaderSelector, uploadImagesToCDNLoaderSelector } from 'data/selectors/loading';
import {
  Container,
  FileContainer,
  FileIconBox,
  FilePartContainer,
  FileSize,
  FileNameStyles,
} from './styles';

const Attachments = ({
  templateId,
  templateName,
  stepId,
  version,
  disabled,
  attachments = [],
  previousModal,
  content,
  subject,
}) => {
  const dispatch = useDispatch();

  const showPauseWarningIfNeeded = useShowPauseWarningIfNeeded();

  const loading = useSelector(deleteFilesFromStepLoaderSelector);
  const loadingFiles = useSelector(uploadImagesToCDNLoaderSelector);

  const handleDeleteFile = showPauseWarningIfNeeded((fileId, fileName) => dispatch(toggleModal('delete_file_from_step', true, {
    templateId,
    version,
    stepId,
    fileId,
    fileName,
    previousModal,
    content,
    subject,
    templateName,
  })));

  if (!attachments.length) return null;

  return (
    <Loader style={{ width: '350px' }} loading={loading || loadingFiles}>
      <Container>
        {
          attachments.map((attachment) => (
            <FileContainer key={attachment._id} onClick={() => fileDownloadByUrl(attachment.url, attachment.name)}>
              <FilePartContainer>
                <FileIconBox>
                  <Icon size="small" fill={COLORS.GREY_G1} type="ic-attach-file" />
                </FileIconBox>
                <EllipsisText style={FileNameStyles} title={attachment.name}>
                  {attachment.name}
                </EllipsisText>
                <FileSize>
                  {formatBytes(attachment.size, 1)}
                </FileSize>
              </FilePartContainer>
              {
                !disabled && (
                  <FilePartContainer>
                    <Icon onClick={stopPropagation(() => handleDeleteFile(attachment._id, attachment.name))} size="small" fill={COLORS.PRIMARY} type="ic-close" />
                  </FilePartContainer>
                )
              }
            </FileContainer>
          ))
        }
      </Container>
    </Loader>
  );
};

export default Attachments;
