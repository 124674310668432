import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ModalsController from 'components/modalsController';
import UnconfirmedEmail from 'pages/authorization/unconfirmedEmail';

const EmailConfirmationLayout = ({ decodedToken }) => (
  <>
    <Switch>
      <Route
        path="/email-confirmation"
        render={() => <UnconfirmedEmail email={decodedToken?.email} />}
      />
      <Redirect to="/email-confirmation" />
    </Switch>
    <ModalsController />
  </>
);

export default EmailConfirmationLayout;
