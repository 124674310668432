import React from 'react';

import { CustomCheckbox as CheckBox } from 'components/commonStyles';

const CustomCheckbox = ({
  size = 'large',
  onChange,
  checked,
  children,
  ...props
}) => (
  <CheckBox size={size} checked={checked} onChange={onChange} {...props}> { /* eslint-disable-line */ }
    {children}
  </CheckBox>
);

export default CustomCheckbox;
