export const TOUR = Object.freeze({
  DEMO_SEARCH_LEADS: 'demo-search-leads',
  ENRICH_DATA: 'enrich-data',
  SEARCH_LEADS_DATABASE: 'search-leads-database',
  SEARCH_IMPORT_LEADS: 'search-import-leads',
  ORGANIZE_INTEGRATIONS: 'organize-integrations',
  ORGANIZE_FILTERS: 'organize-filters',
  ORGANIZE_LISTS: 'organize-lists',
  PLUGIN_DEMO: 'plugin-demo',
  SAVE_LEADS: 'save-leads',
});
