import React from 'react';
import { useSelector } from 'react-redux';

import {
  Icon,
} from 'components/common';
import {
  existFastSpringCardSelector, cardsSelector,
} from 'data/selectors/cards';
import {
  FormButton,
  FSPaymentContainer,
  PayBtnWrapper,
} from '../styles';

const PaymentButton = ({
  isProcessing,
  checkout,
  bulkId,
  fsCheckoutInit,
}) => {
  const existsFastSpringCard = useSelector(existFastSpringCardSelector);
  const cards = useSelector(cardsSelector);

  return (
    <FSPaymentContainer>
      <PayBtnWrapper>
        <FormButton
          data-form={isProcessing ? 'false' : 'true'}
          type="primary"
          onClick={checkout}
          disabled={isProcessing}
        >
          <Icon type="ic-lock" fill={isProcessing ? 'rgba(0, 0, 0, 0.25)' : 'white'} />
          {bulkId ? 'Pay for bulk' : 'Pay and upgrade my plan'}
        </FormButton>
      </PayBtnWrapper>
      {!existsFastSpringCard && cards.length ? (
        <PayBtnWrapper>
          <FormButton
            data-form={isProcessing ? 'false' : 'true'}
            type="normal"
            onClick={fsCheckoutInit}
            disabled={isProcessing}
          >
            Add card
          </FormButton>
        </PayBtnWrapper>
      ) : null}
    </FSPaymentContainer>
  );
};

export default PaymentButton;
