import { Modal } from 'antd';
import React, { useState } from 'react';

import { Button, TextArea } from 'components/common';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../../../../modals/baseModal/styles';

const MINIMUM_SYMBOLS = 10;
const MINIMUM_SPACES = 1;

const SpecifyInformation = ({
  title,
  placeholder = `Minimum ${MINIMUM_SYMBOLS} symbols and ${MINIMUM_SPACES + 1} words`,
  description,
  onClose,
  onNext,
  field,
}) => {
  const [information, setInformation] = useState('');

  const words = information.trim().split(' ').filter(Boolean);
  const isValid = words.reduce((a, b) => a + b.length, 0) >= MINIMUM_SYMBOLS && words.length > MINIMUM_SPACES;

  return (
    <Modal
      visible
      title={(
        <ModalTitle>
          {title}
          <CloseIcon
            onClick={onClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={600}
      footer={false}
      onCancel={onClose}
    >
      <div>
        {/* eslint-disable-next-line */}
        {description && <div style={{ marginBottom: 20 }} dangerouslySetInnerHTML={{ __html: description }} />}
        <TextArea
          placeholder={placeholder}
          value={information}
          onChange={(e) => setInformation(e.target.value)}
        />
        <Footer>
          <Button
            data-form="true"
            type="primary"
            disabled={!isValid}
            onClick={() => onNext({ [field]: information })}
          >
            Next
          </Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default SpecifyInformation;
