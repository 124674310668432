import React from 'react';
import { Modal } from 'antd';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';

import { toggleModal } from 'data/actions/modals';
import { deletePropertyGroups } from 'data/actions/properties';
import { deleteGroupsFromPreviewConfiguration } from 'data/actions/user';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { groupsSelector, selectedPropertyGroupsSelector } from 'data/selectors/properties';
import {
  ModalTitle,
  Label,
  CloseIcon,
  Footer,
} from '../baseModal/styles';

const DeletePropertyGroupsModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const groups = useSelector(groupsSelector);
  const selectedPropertyGroups = useSelector(selectedPropertyGroupsSelector);
  const groupNames = selectedPropertyGroups.map((groupId) => get(groups.find((group) => groupId === group._id), 'name'));
  const { setSelectedRowKeys, groupSourceType, groupName } = useSelector(modalOptionsSelector);

  const handleDeletePropertyGroup = () => {
    const names = groupName ? [groupName] : groupNames;

    if (setSelectedRowKeys) setSelectedRowKeys([]);
    dispatch(deletePropertyGroups({ sourceType: groupSourceType, names }));
    dispatch(deleteGroupsFromPreviewConfiguration(names, groupSourceType));
    dispatch(toggleModal('delete_property_groups', false));
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Delete property group(s)
          <CloseIcon
            onClick={() => dispatch(toggleModal('delete_property_groups', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={450}
      footer={false}
      headerStyle={{ background: 'red' }}
      onCancel={() => dispatch(toggleModal('delete_property_groups', false))}
      closeIcon={<div />}
    >
      <>
        <Label>
          {`Delete ${groupName ? 1 : selectedPropertyGroups.length} property group(s)? `}
          This action cannot be undone. The property group(s) will be deleted.
        </Label>
      </>
      <Footer>
        <Button
          data-form={!selectedPropertyGroups.length && !groupName ? 'false' : 'true'}
          type="danger"
          size="large"
          onClick={handleDeletePropertyGroup}
          disabled={!selectedPropertyGroups.length && !groupName}
        >
          Delete
        </Button>
        <Button
          size="large"
          type="default"
          onClick={() => dispatch(toggleModal('delete_property_groups', false))}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default DeletePropertyGroupsModal;
