import styled from 'styled-components';

export const MenuItem = styled.button`
  cursor: pointer;
  display: block;
  min-height: 42px;
  padding: 8px 20px;
  background-color: ${(props) => (props.active ? '#d1e9ff' : '#fff')};
  text-align: left;
  border: none;
  outline: none;
  width: 100%;
  height: 22px;
  font-family: 'AvenirNext';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.25px;
  color: ${(props) => (props.active ? '#1890ff' : '#071427')};
  font-weight: ${(props) => (props.active ? '600' : 'normal')};

  &:hover {
    background: #e5f5f8;
    color: ${(props) => (props.active ? '#1890ff' : '#33475b')};
  }
`;
