import React, { useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  isAuthSelector,
  extensionAuthActiveSelector,
} from 'data/selectors/user';

import WhiteLoading from 'components/whiteLoading';
import useLoginPlugin from 'hooks/useLoginPlugin';

const AuthHandler = () => {
  const history = useHistory();
  const isAuthorized = useSelector(isAuthSelector);
  const isExtensionAuthActive = useSelector(extensionAuthActiveSelector);
  const loginPlugin = useLoginPlugin();

  useEffect(() => {
    if (isAuthorized) {
      if (isExtensionAuthActive) {
        loginPlugin(true);
      } else {
        history.push('/');
      }
    }
  }, [isExtensionAuthActive, isAuthorized, loginPlugin, history]); // eslint-disable-line

  return <WhiteLoading height="100vh" width="100%" />;
};

export default memo(AuthHandler);
