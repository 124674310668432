import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'react-fast-compare';
import React, { useCallback } from 'react';
import { getFilterOptionsSelector } from 'data/selectors/search';
import { COUNT_OPTIONS_TYPES } from 'data/types/search.filters.types';
import { toggleModal } from 'data/actions/modals';
import {
  FilterEditorContainer, FilterAddItemLink, FilterAddItemContainer, FilterAddFromFile,
} from 'components/sidebar/styles';
import FiltersInput from './filtersInput';

const ListFilter = ({
  filter,
  filters,
  showOptionsList,
  setShowOptionsList,
}) => {
  const dispatch = useDispatch();
  const optionsSelector = getFilterOptionsSelector(filter.property);
  const options = useSelector(optionsSelector);
  const { applyImportFile } = filter;

  const includedOptions = filter.isDynamic ? get(filters, 'included.value', []) : options.filter((option) => get(filters, 'included.value', []).some((includedValue) => isEqual(includedValue, get(option, 'value', option))));
  const excludedOptions = filter.isDynamic ? get(filters, 'excluded.value', []) : options.filter((option) => get(filters, 'excluded.value', []).some((excludedValue) => isEqual(excludedValue, get(option, 'value', option))));

  const showListClick = useCallback(() => {
    const hasOption = includedOptions.length > 0 || excludedOptions.length > 0;
    setShowOptionsList((filter?.count === COUNT_OPTIONS_TYPES.SINGLE && !hasOption) || (filter?.count !== COUNT_OPTIONS_TYPES.SINGLE));
  }, [setShowOptionsList, includedOptions, excludedOptions, filter]);

  const showImportModal = useCallback(() => {
    dispatch(toggleModal('import_search_filter', true, { filterLabel: filter.label.toLowerCase(), property: filter.property }));
  }, [dispatch, filter]);

  const isShowAddLabel = !showOptionsList && filter?.count !== COUNT_OPTIONS_TYPES.SINGLE;
  const isImportFilterContains = get(filters, 'included.import') || get(filters, 'excluded.import');
  const filterContainFile = includedOptions.some((item) => typeof item === 'object') || excludedOptions.some((item) => typeof item === 'object');

  return (
    <FilterEditorContainer onClick={showListClick}>
      { isShowAddLabel && (
      <FilterAddItemContainer>
        <FilterAddItemLink onClick={showListClick}>{`Add ${get(filter, 'label', '').toLowerCase()}`}</FilterAddItemLink>
        {!isImportFilterContains && applyImportFile && !filterContainFile
          ? (
            <FilterAddFromFile onClick={showImportModal}>
              Import from file
            </FilterAddFromFile>
          ) : (<></>)}
      </FilterAddItemContainer>
      )}
      <FiltersInput showOptionsList={showOptionsList} filter={filter} hasIncluded={includedOptions.length > 0} />
      {!isShowAddLabel && !isImportFilterContains && applyImportFile && !filterContainFile && (
      <FilterAddFromFile onClick={showImportModal}>
        Import from file
      </FilterAddFromFile>
      )}
    </FilterEditorContainer>
  );
};

export default ListFilter;
