import React from 'react';
import { Spin, Icon } from 'antd';

const antIcon = <Icon type="loading" spin />;

const Loader = ({
  children,
  loading = false,
  size,
  style,
  Component,
}) => {
  if (Component) {
    return (
      <Component style={{ maxHeight: '100%', ...style }} spinning={loading} size={size || 'default'} indicator={antIcon}>
        {children}
      </Component>
    );
  }

  return (
    <Spin style={{ maxHeight: '100%', ...style }} spinning={loading} size={size || 'default'} indicator={antIcon}>
      {children}
    </Spin>
  );
};

export default Loader;
