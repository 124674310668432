import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import googleChromePath from 'assets/images/google_chrome.png';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';
import { TOUR } from 'data/types/tours.types';
import { skipLastStep, updateSignUpReport, updateUserSettings } from 'data/actions/user';
import {
  showLastStepSelector,
  pluginInstalledSelector,
  userEmailSelector,
  userSettingsSelector,
  skipLeadsTourSelector,
} from 'data/selectors/user';
import newChrome from 'assets/images/new_chrome.svg';
import IcLinkedin from 'assets/images/linkedin-white-logo.png';
import useLoginPlugin from 'hooks/useLoginPlugin';
import AuthContainer from '../AuthContainer';
import {
  AuthForm,
  Header,
  SubHeader,
} from '../styles';
import {
  ButtonsContainer,
  Image,
  HeaderContainer,
  OrContainer,
  ChromeImageStyle,
  LinkedinImageStyle,
} from './styles';

const LastStepPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const showLastStep = useSelector(showLastStepSelector);
  const skipLeadsTour = useSelector(skipLeadsTourSelector);
  const pluginInstalled = useSelector(pluginInstalledSelector);
  const email = useSelector(userEmailSelector);
  const { completedSteps } = useSelector(userSettingsSelector);
  const loginPlugin = useLoginPlugin();

  useEffect(() => loginPlugin(), [loginPlugin]);

  if (!showLastStep) return <Redirect to="/contacts" />;

  const redirectToPlugin = () => {
    window.open(process.env.REACT_APP_PLUGIN_URL, '_blank');
  };

  const viewMyAccount = (showLeadTour) => {
    if (email) dispatch(updateSignUpReport({ email, database: true }));
    dispatch(skipLastStep(showLeadTour));
    const historyPath = skipLeadsTour ? '/search/contact' : '/search/contact?tour=search-leads-database';

    if (!completedSteps.includes(TOUR.SAVE_LEADS) && skipLeadsTour) {
      completedSteps.push(TOUR.SAVE_LEADS);
      dispatch(updateUserSettings({ completedSteps }));
    }
    history.push(historyPath);
  };

  const handleClickButton = () => {
    if (email) dispatch(updateSignUpReport({ email, extension: true }));
    if (pluginInstalled) {
      if (!skipLeadsTour) {
        localStorage.setItem('showDemoSearchLeads', '1');
      }
      window.open('https://www.linkedin.com/search/results/people/', '_self');
    } else {
      redirectToPlugin();
    }
  };

  return (
    <AuthContainer content="finish" finish percent={100}>
      <AuthForm
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <HeaderContainer>
          <Header>
            {pluginInstalled ? "Welcome 🎉 Let's get started" : 'Pick your first step:'}
          </Header>
          <SubHeader>Get leads from LinkedIn with Chrome extension</SubHeader>
        </HeaderContainer>
        <Image src={googleChromePath} alt="" />
        <ButtonsContainer>
          <Button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={handleClickButton} size="large" type="primary">
            {pluginInstalled
              ? <img style={LinkedinImageStyle} src={IcLinkedin} alt="Linkedin" />
              : <img style={ChromeImageStyle} src={newChrome} alt="Chrome extension" />}
            <span>
              {pluginInstalled ? 'Add leads from Linkedin' : 'Add to chrome'}
            </span>
          </Button>
          <OrContainer>or</OrContainer>
          <Button onClick={() => viewMyAccount(true)} size="large" type="normal">Search in Database</Button>
        </ButtonsContainer>
      </AuthForm>
    </AuthContainer>
  );
};

export default LastStepPage;
