import React, { useState } from 'react';
import get from 'lodash/get';
import useResetParams from 'hooks/useResetParams';
import {
  Icon,
  Tooltip,
} from 'antd';
import { addOptionToExcludedFilters, removeOptionFromFilters } from 'data/actions/search';
import { COMPANY_FOUNDED_AT, COMPANY_SIZE } from 'data/types/search.filters.types';
import {
  FilterTag,
  FilterOperatorTitle,
  TagsContainer,
} from '../../../sidebar/styles';

const IncludedFilters = ({
  property, options, excludedOp, excluded, handleClose,
}) => {
  const [showTooltip, setShowTooltip] = useState();
  const resetParamsAfterRemoveFilter = useResetParams((element) => removeOptionFromFilters({ element, property }));
  const resetParamsAfterAddFilter = useResetParams((element) => addOptionToExcludedFilters({ property, excludedOp, element }));

  const closeFilter = (element) => {
    resetParamsAfterRemoveFilter(element);
    handleClose();
  };

  return (
    <TagsContainer>
      <FilterOperatorTitle>Included:</FilterOperatorTitle>
      {options && ('map' in options) ? options.map((item) => {
        const element = get(item, 'printedData', item);
        const count = get(item, 'count');
        let filterLabel = get(element, 'label', `${Array.isArray(element) ? element[0] : element}${count ? ` - ${count}` : ''}`);

        if (Array.isArray(element) && element?.length === 3 && property === COMPANY_FOUNDED_AT) {
          filterLabel = `${element[0]} - ${element[1]}`;

          if (element[0] && !element[1]) {
            filterLabel = `> ${element[0]}`;
          }
          if (!element[0] && element[1]) {
            filterLabel = `< ${element[1]}`;
          }
        }
        if (property === COMPANY_SIZE) {
          const [, max] = filterLabel.split('-');

          if (!max) {
            filterLabel = `${filterLabel} and more`;
          }
        }
        const isCategory = Array.isArray(get(item, 'matching'));
        const filterTag = (
          <FilterTag
            type="primary"
            closable
            onClose={() => {
              const elementToRemove = isCategory ? { value: get(element, 'value', element), isCategory: true } : get(element, 'value', element);
              closeFilter(elementToRemove);
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <p style={!excluded ? { width: '100%' } : {}}>
              {filterLabel}
            </p>
            {excluded && (
            <Icon
              onClick={() => {
                resetParamsAfterAddFilter(get(element, 'value', element));
              }}
              className="icon-tag"
              type="stop"
            />
            ) }
          </FilterTag>
        );
        const tooltipKey = `included_filter_tag_${get(element, 'label', element)}`;

        return (
          <Tooltip
            visible={showTooltip === tooltipKey}
            onVisibleChange={(showed) => {
              if (filterLabel?.length > 31 && showed) {
                setShowTooltip(tooltipKey);
              } else {
                setShowTooltip(false);
              }
            }}
            onClick={(e) => e.stopPropagation()}
            placement="right"
            title={filterLabel}
            key={tooltipKey}
          >
            {filterTag}
          </Tooltip>
        );
      })
        : <></>}
    </TagsContainer>
  );
};

export default IncludedFilters;
