import styled from 'styled-components';

import * as CONSTANTS from 'constants/styles';
import { Button, Select } from 'components/common';

export const Footer = styled.div`
  width: 100%;
  margin-top: 36px;

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
  }
`;

export const InputNameWrapper = styled.div`
  margin: 0 5px 10px 0;
  width: 100%;

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    display: flex;
    margin: 0 0px 15px 0;
    flex-direction: column;
  }
`;

export const SelectFrequencyWrapper = styled.div`
  margin: 0 0 10px 20px;

  .ant-select-selection--single {
    display: flex;
    align-items: center;
    @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
      margin-left: 0;
    }
  }

  label {
    @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
      margin-left: 0;
    }
  }

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ModalSelect = styled(Select)`
  text-transform: capitalize;
  width: 270px;
  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    width: 100%;
  }
`;

export const inputStyles = {
  width: '100%',
  marginTop: '10px',
};

export const OptionStyle = {
  textTransform: 'capitalize',
};

export const Description = styled.div`
  font-family: AvenirNext;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 20px;
`;

export const Container = styled.div``;

export const ModalButton = styled(Button)`
  &:first-child {
    margin-right: 10px;
  }
  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    width: 50%;
    margin-right: 0;
  }
`;
