import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';

import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { modalsSelector } from 'data/selectors/modals';
import { deleteUser, signOut } from 'data/actions/user';
import {
  ModalTitle, CloseIcon, Footer,
} from '../baseModal/styles';

const DeleteAccount = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);

  const onSuccess = () => {
    dispatch(signOut());
  };

  const onFailed = () => {
    dispatch(toggleMessage('error', { header: "An unknown error has occurred. The account haven't been removed" }));
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Delete the account
          <CloseIcon
            onClick={() => dispatch(toggleModal('delete_account', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={() => dispatch(toggleModal('delete_account', false))}
    >
      <div>
        Are you sure you want to delete this account?
      </div>
      <Footer>
        <Button
          type="danger"
          size="large"
          data-form="true"
          onClick={() => {
            dispatch(deleteUser(onSuccess, onFailed));
            dispatch(toggleModal('delete_account', false));
          }}
        >
          Delete
        </Button>
        <Button
          size="large"
          type="default"
          onClick={() => dispatch(toggleModal('delete_account', false))}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default DeleteAccount;
