import React from 'react';
import get from 'lodash/get';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from 'components/common';

import { toggleModal } from 'data/actions/modals';
import { SOURCE_TYPE } from 'data/types/source.types';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import getFilterActions from 'helpers/getFilterActionsBySourceType';
import {
  Footer,
  Label,
  ListName,
} from './styles';

import {
  ModalTitle,
  CloseIcon,
} from '../baseModal/styles';

const DeleteFilterModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const history = useHistory();

  const { filterId, sourceType = SOURCE_TYPE.CONTACT } = useSelector(modalOptionsSelector);
  const {
    actionDelete,
    filterSelector,
    actionToggleShowAllSavedFilters,
  } = getFilterActions(sourceType);

  const savedFilters = useSelector(filterSelector);
  const currentFilterName = get(savedFilters.find((item) => item._id === filterId), 'name', 'the view');

  const onSuccessDeleteFilter = () => {
    history.push(`/${sourceType === SOURCE_TYPE.CONTACT ? 'contacts' : 'companies'}/filter/all`);
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Delete this saved filter?
          <CloseIcon
            onClick={() => dispatch(toggleModal('delete_filter', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={460}
      footer={false}
      onCancel={() => dispatch(toggleModal('delete_filter', false))}
    >
      <Label>
        You are about to delete
        {' '}
        <ListName>
          {currentFilterName}
        </ListName>
      </Label>
      <Footer>
        <Button
          type="danger"
          size="large"
          data-form="true"
          onClick={() => {
            dispatch(actionToggleShowAllSavedFilters(false));
            dispatch(toggleModal('delete_filter', false));
            dispatch(actionDelete(filterId, onSuccessDeleteFilter));
          }}
        >
          Delete
        </Button>
        <Button
          size="large"
          type="default"
          onClick={() => dispatch(toggleModal('delete_filter', false))}
        >
          Cancel
        </Button>
      </Footer>
    </Modal>
  );
};

export default DeleteFilterModal;
