export const requiredInputs = [
  'name', 'domain',
];

export const searchSimilarCompany = (company = {}, searchAction = () => {}) => {
  let filters = [];

  if (company.domain && company.name) {
    filters = [
      { property: 'domain', operator: 'EQ', value: company.domain },
      { property: 'name', operator: 'EQ', value: company.name },
    ];
  } else if (company.domain) {
    filters = [
      { property: 'domain', operator: 'EQ', value: company.domain },
    ];
  } else {
    filters = [
      { property: 'name', operator: 'EQ', value: company.name },
    ];
  }

  searchAction(filters);
};
