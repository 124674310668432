import React, { useState, useEffect } from 'react';
import {
  Modal,
} from 'antd';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import {
  Button,
  Select,
  Option,
} from 'components/common';
import { contactPropertiesSelector } from 'data/selectors/contacts';
import {
  editContactsProperty,
} from 'data/actions/contacts';

import {
  Container,
  InputContainer,
  Wide,
} from './styles';

import {
  ModalTitle,
  CloseIcon,
  Footer,
} from '../modals/baseModal/styles';
import EditableProperty from '../editableProperty';

const EditContactsProperty = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const {
    setSelectedRowKeys,
    excludedRowKeys,
    selectedItems,
    selectedAll,
    filters,
    search,
  } = useSelector(modalOptionsSelector);
  const properties = get(useSelector(contactPropertiesSelector), 'data', []).filter((property) => !property.custom);

  const [property, updateProperty] = useState(null);
  const [value, updateValue] = useState(null);

  useEffect(() => {
    updateProperty(null);
    updateValue(null);
  }, [visible]);

  useEffect(() => {
    updateValue(null);
  }, [property]);

  const handleClose = () => dispatch(toggleModal('contact_edit_columns', false));

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Bulk edit contacts
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <Container>
        <InputContainer>
          <Select
            style={Wide}
            defaultValue="Select property to edit"
            onChange={(propertyValue) => {
              updateProperty(properties.find(({ name }) => name === propertyValue));
            }}
          >
            {properties.filter((item) => !item.unique).map((item, index) => <Option key={`${item._id}-${index + 1}`} value={item.name}>{ item.label }</Option>)}
          </Select>
        </InputContainer>

        { /* eslint-disable-next-line */ }
        { property && <EditableProperty {...property} value={value} updateValue={updateValue} />}

      </Container>
      <Footer>
        <Button
          data-form={!value ? 'false' : 'true'}
          type="primary"
          size="large"
          disabled={!value}
          onClick={() => {
            dispatch(editContactsProperty({
              properties: [{ property: property.name, value }],
              selectedItems,
              excludedIds: excludedRowKeys,
              selectedAll,
              filters,
              search,
              onSuccess: () => {
                if (setSelectedRowKeys) setSelectedRowKeys([]);
                handleClose();
              },
            }));
          }}
        >
          Save
        </Button>
        <Button
          type="normal"
          onClick={handleClose}
          size="large"
        >
          Cancel
        </Button>
      </Footer>
    </Modal>
  );
};

export default EditContactsProperty;
