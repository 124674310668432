import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { toggleMessage } from 'data/actions/message';
import { Redirect, useLocation } from 'react-router-dom';

const Verification = (redirectUrl) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const { invalidToken, expiredToken } = Object.fromEntries(new URLSearchParams(location.search));
    if (invalidToken) {
      dispatch(toggleMessage('error', {
        header: 'Your verification code is not valid',
        duration: 10,
      }));
    } else if (expiredToken) {
      dispatch(toggleMessage('error', {
        header: 'Token has expired',
        duration: 10,
      }));
    }
  }, [dispatch, location]);

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  return <></>;
};

export default Verification;
