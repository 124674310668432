import React from 'react';
import { useSelector } from 'react-redux';

import { modalsSelector } from 'data/selectors/modals';
import DeleteTeammatesModal from 'components/modals/deleteTeammatesModal';
import DeleteBulkModal from 'components/modals/deleteBulkVerificationModal';
import SelectAllListsModal from 'components/modals/selectAllListsModal';
import PauseSubscriptionModal from 'components/modals/pauseSubscriptionModal';
import PreCancelSubscriptionModal from 'components/modals/preCancelSubscriptionModal';
import ScoreNPSModal from 'components/modals/scoreNPS';
import CreateContactBar from '../createContactBar';
import CreatePropertyBar from '../createPropertyBar';
import CreateFilterModal from '../modals/createFilterModal';
import PropertyListEditor from '../propertyListEditor';
import RenameFilterModal from '../modals/renameFilterModal';
import DeleteFilterModal from '../modals/deleteFilterModal';
import CloneFilterModal from '../modals/cloneFilterModal';
import ListDetailsBar from '../modals/listDetailsModal';
import MoveFolderModal from '../modals/moveFolderModal';
import MoveListToFolder from '../modals/moveListToFolder';
import NotificationBar from '../previewBars/notificationsPreviewBar';
import CreateFolderModal from '../modals/createFolderModal';
import DeleteListModal from '../modals/deleteListModal';
import DeleteFolderModal from '../modals/deleteFolderModal';
import EditContactsProperty from '../editContactsProperty';
import DeleteContacts from '../deleteContacts';
import AddToStaticListModal from '../modals/addToStaticListModal';
import CreateCompanyFilterModal from '../modals/createCompanyFilterModal';
import CreateCompany from '../modals/createCompanyModal';
import EditCompaniesProperty from '../editCompaniesProperty';
import DeleteCompanies from '../deleteCompanies';
import AddCompaniesToContact from '../addCompaniesToContactBar';
import Export from '../modals/exportModal';
import CreateUser from '../modals/createUserModal';
import ListPageMobileMenu from '../modals/listPageMobileMenu';
import CreateListMobileMenu from '../modals/createListMobileMenu';
import CloneListModal from '../modals/cloneListModal';
import AddPropertiesToGroupModal from '../modals/addPropertiesToGroup';
import DeletePropertiesModal from '../modals/deletePropertiesModal';
import SearchFiltersMobileMenu from '../modals/searchFiltersMobileModal';
import SaveSearch from '../modals/saveSearchModal';
import ChangePassword from '../modals/changePassword';
import BillingModal from '../modals/billingModal';
import EditAddressModal from '../modals/editAddressModal';
import CreateListModal from '../modals/createListModal';
import RenameListModal from '../modals/renameListModal';
import DeleteStep from '../modals/deleteStep';
import SaveFromSearchModal from '../modals/saveFromSearchModal';
import AddTemplateStepPreviewBar from '../previewBars/addTemplateStepPreviewBar';
import RestorePropertiesModal from '../modals/restorePropertiesModal';
import DeleteAccount from '../modals/deleteAccountModal';
import OnboardingVideoModal from '../modals/onboardingVideoModal';
import CreateWorkspaceModal from '../modals/createNewWorkspace';
import EditPropertyPreviewBar from '../previewBars/editPropertyPreviewBar';
import SelectAllContactsModal from '../modals/selectAllContactsModal';
import SelectAllCompaniesModal from '../modals/selectAllCompaniesModal';
import CreatePropertyGroupPreviewBar from '../previewBars/createPropertyGroupPreviewBar';
import DeletePropertyGroupsModal from '../modals/deletePropertyGroupsModal';
import RenamePropertyGroupModal from '../modals/renamePropertyGroupModal';
import RenameWorkspaceModal from '../modals/renameWorkspaceModal';
import DeleteWorkspaceModal from '../modals/deleteWorkspaceModal/deleteWorkspaceModal';
import AppConnectionModal from '../modals/appConnectionModal';
import CancelSubscriptionModal from '../modals/cancelSubscriptionModal';
import DeleteSavedSearchModal from '../modals/deleteSavedSearchModal';
import RenameSavedSearchModal from '../modals/renameSavedSearchModal';
import DeleteFromStaticList from '../modals/deleteFromStaticList';
import MoveToStaticListModal from '../modals/moveToStaticListModal';
import ExportConfigurationEditor from '../modals/exportConfigurationEditor';
import VerifyAccountModal from '../modals/verifyAccountModal';
import CreateEmailTemplateModal from '../modals/createEmailTemplate';
import BulkVerificationModal from '../modals/bulkVerificationModal';
import ChangeEmailModal from '../modals/changeEmailModal';

import useOpenPreview from './useOpenPreview';
import ChangeWorkspaceAdminModal from '../modals/changeWorkspaceAdmin';
import ImportSearchFilterModal from '../modals/importSearchFilter';
import CreateSequenceScheduleModal from '../modals/createSequenceScheduleModal';
import SearchesPreviewBar from '../previewBars/searchesPreviewBar';
import StopSearchModal from '../modals/stopSearch';
import DeleteApiKey from '../modals/deleteApiKeyModal';
import DeleteStepVersionModal from '../modals/deleteStepVersionModal';
import DeleteSequenceModal from '../modals/deleteSequenceModal';
import AddToSequenceModal from '../modals/addToSequenceModal';
import DeleteSequenceContactsModal from '../modals/deleteSequenceContactsModal';
import ResumeSequenceContactsModal from '../modals/resumeSequenceContactsModal';
import PauseSequenceContactsModal from '../modals/pauseSequenceContactsModal';
import DeleteSequenceScheduleModal from '../modals/deleteSequenceSchedule';
import PreviewSequenceStepModal from '../modals/previewSequenceStepModal';
import CloneSequenceModal from '../modals/cloneSequenceModal';
import DisableStepVersionModal from '../modals/disableStepVersionModal';
import UnsubscribeSequenceContactsModal from '../modals/unsubscribeSequenceContactsModal';
import DeleteEmailAccountModal from '../modals/deleteEmailAccountModal';
import DisableEmailAccountModal from '../modals/disableEmailAccountModal';
import ResumeSequencesModal from '../modals/resumeSequencesModal';
import PauseSequencesModal from '../modals/pauseSequencesModal';
import EmailAccountPreviewBar from '../previewBars/emailAccountPreviewBar';
import ConfirmModal from '../modals/confirmModal';
import VerifyPhoneModal from '../modals/verifyPhoneModal';
import ContactsFiltersMobileMenu from '../modals/contactsFiltersMobileModal';
import AddEmailAccountModal from '../modals/addEmailAccountModal';
import DeleteFileFromStepModal from '../modals/deleteFileFromStepModal';
import AddLeadsToSequence from '../modals/addLeadsToSequenceModal';
import LaunchSequenceModal from '../modals/launchSequenceModal';
import SendTestCampaignModal from '../modals/sendTestCampaignModal';
import PauseEditingStepModal from '../modals/pauseEditingStepModal';
import LaunchSequenceSimpleModal from '../modals/launchSequenceSimpleModal';
import TestPreviewSequenceStepModal from '../modals/testPreviewSequenceStepModal';
import SimpleExportModal from '../modals/simpleExportModal';
import ManagerCancelSubscriptionModal from '../stepModals/managerCancelSubscriptionModal';
import ManagerDeleteAccountModal from '../stepModals/managerDeleteAccount';
import NewDeleteAccountModal from '../modals/newDeleteAccountModal';
import ConfirmationDeleteAccountModal from '../modals/confirmationDeleteAccountModal';
import DeleteStepTemplateModal from '../modals/deleteStepTemplateModal';
import SelectAllSequenceLeadsModal from '../modals/selectAllSequenceLeadsModal';
import SendTestEmailModal from '../modals/sendTestEmailModal';
import EditContactMainInfoPreviewBar from '../previewBars/editContactMainInfoPreviewBar';
import EditContactDetailsPreviewBar from '../previewBars/editContactDetailsPreviewBar';
import NewContactPreviewBar from '../previewBars/newContactPreviewBar';
import NotSupportPhoneNumbersModal from '../modals/notSupportPhoneNumbersModal';
import NewCompanyPreviewBar from '../previewBars/newCompanyPreviewBar';
import EditCompanyMainInfoPreviewBar from '../previewBars/editCompanyMainInfoPreviewBar';
import AddGoogleAccountModal from '../modals/addGoogleAccountModal';
import AddUnsubscriptionsModal from '../modals/addUnsubscriptionsModal';
import DeleteUnsubscribesModal from '../modals/deleteUnsubscribesModal';
import ImapInfoModal from '../modals/imapInfoModal';
import AppPasswordInfoModal from '../modals/appPasswordInfoModal';
import AddAppPasswordModal from '../modals/addAppPassword';
import OutOfLimitsModal from '../modals/outOfLimits';

const ModalsController = () => {
  const { type, visible } = useSelector(modalsSelector);

  useOpenPreview(type, visible);

  switch (type) {
    case 'create_sequence_schedule':
      return <CreateSequenceScheduleModal />;

    case 'create_email_template':
      return <CreateEmailTemplateModal />;

    case 'move_to_static_list':
      return <MoveToStaticListModal />;

    case 'delete_from_static_list':
      return <DeleteFromStaticList />;

    case 'rename_saved_search':
      return <RenameSavedSearchModal />;

    case 'delete_saved_search':
      return <DeleteSavedSearchModal />;

    case 'cancel_subscription':
      return <CancelSubscriptionModal />;

    case 'pause_subscription':
      return <PauseSubscriptionModal />;

    case 'pre_cancel_subscription':
      return <PreCancelSubscriptionModal />;

    case 'rename_property_group':
      return <RenamePropertyGroupModal />;

    case 'delete_property_groups':
      return <DeletePropertyGroupsModal />;

    case 'create_property_group_preview':
      return <CreatePropertyGroupPreviewBar />;

    case 'select_all_companies':
      return <SelectAllCompaniesModal />;

    case 'select_all_contacts':
      return <SelectAllContactsModal />;

    case 'select_all_lists':
      return <SelectAllListsModal />;

    case 'delete_account':
      return <DeleteAccount />;

    case 'restore_properties':
      return <RestorePropertiesModal />;

    case 'add_template_step_preview':
      return <AddTemplateStepPreviewBar />;

    case 'delete_step':
      return <DeleteStep />;

    case 'create_contact_preview':
      return <CreateContactBar />;

    case 'create_property_preview':
      return <CreatePropertyBar />;

    case 'edit_property_preview':
      return <EditPropertyPreviewBar />;

    case 'create_filter':
      return <CreateFilterModal />;

    case 'edit_columns':
      return <PropertyListEditor />;

    case 'rename_filter':
      return <RenameFilterModal />;

    case 'delete_filter':
      return <DeleteFilterModal />;

    case 'clone_filter':
      return <CloneFilterModal />;

    case 'list_details':
      return <ListDetailsBar />;

    case 'move_folder':
      return <MoveFolderModal />;

    case 'move_list_to_folder':
      return <MoveListToFolder />;

    case 'show_notifications_preview':
      return <NotificationBar />;

    case 'create_folder':
      return <CreateFolderModal />;

    case 'delete_lists':
      return <DeleteListModal />;

    case 'delete_folder':
      return <DeleteFolderModal />;

    case 'contact_edit_columns':
      return <EditContactsProperty />;

    case 'delete_contacts':
      return <DeleteContacts />;

    case 'restore_contacts':
      return <DeleteContacts />;

    case 'add_to_static_list':
      return <AddToStaticListModal />;

    case 'create_company_filter':
      return <CreateCompanyFilterModal />;

    case 'create_company_preview':
      return <CreateCompany />;

    case 'companies_edit_columns':
      return <EditCompaniesProperty />;

    case 'delete_companies':
      return <DeleteCompanies />;

    case 'add_company_to_contact_preview':
      return <AddCompaniesToContact />;

    case 'export':
      return <Export />;

      // case 'company_preview':
      //   return <CompanyPreviewBar />;

    case 'create_user':
      return <CreateUser />;

    case 'list_page_mobile_menu':
      return <ListPageMobileMenu type={type} />;

    case 'create_list_mobile_menu':
      return <CreateListMobileMenu type={type} />;

    case 'clone_list_modal':
      return <CloneListModal />;

    case 'add_properties_to_group':
      return <AddPropertiesToGroupModal />;

    case 'delete_properties':
      return <DeletePropertiesModal />;

    case 'add_search_filters_mobile_menu':
      return <SearchFiltersMobileMenu type={type} />;

    case 'save_search':
      return <SaveSearch />;

    case 'change_password':
      return <ChangePassword />;

    case 'billing':
      return <BillingModal />;

    case 'edit_address':
      return <EditAddressModal />;

    case 'create_list':
      return <CreateListModal />;

    case 'rename_list':
      return <RenameListModal />;

    case 'delete_teammates':
      return <DeleteTeammatesModal />;

    case 'save_from_search':
      return <SaveFromSearchModal />;

    case 'show_info_video':
      return <OnboardingVideoModal />;

    case 'create_workspace':
      return <CreateWorkspaceModal />;

    case 'rename_workspace':
      return <RenameWorkspaceModal />;

    case 'change_workspace_admin':
      return <ChangeWorkspaceAdminModal />;

    case 'delete_workspace':
      return <DeleteWorkspaceModal />;

    case 'app_connection':
      return <AppConnectionModal />;

    case 'export-configuration-editor':
      return <ExportConfigurationEditor />;

    case 'verify_account':
      return <VerifyAccountModal />;

    case 'import_search_filter':
      return <ImportSearchFilterModal />;

    case 'bulk_verification':
      return <BulkVerificationModal />;

    case 'delete_bulk_verification':
      return <DeleteBulkModal />;

    case 'searches_preview':
      return <SearchesPreviewBar />;

    case 'stop_search':
      return <StopSearchModal />;

    case 'delete_api_key':
      return <DeleteApiKey />;

    case 'delete_step_version':
      return <DeleteStepVersionModal />;

    case 'delete_sequence':
      return <DeleteSequenceModal />;

    case 'add_to_sequence':
      return <AddToSequenceModal />;

    case 'delete_sequence_contacts':
      return <DeleteSequenceContactsModal />;

    case 'resume_sequence_contacts':
      return <ResumeSequenceContactsModal />;

    case 'pause_sequence_contacts':
      return <PauseSequenceContactsModal />;

    case 'delete_sequence_schedule':
      return <DeleteSequenceScheduleModal />;

    case 'preview_sequence_step':
      return <PreviewSequenceStepModal />;

    case 'test_preview_sequence_step':
      return <TestPreviewSequenceStepModal />;

    case 'clone_sequence':
      return <CloneSequenceModal />;

    case 'disable_step_version':
      return <DisableStepVersionModal />;

    case 'unsubscribe_sequence_contacts':
      return <UnsubscribeSequenceContactsModal />;

    case 'delete_email_account':
      return <DeleteEmailAccountModal />;

    case 'disable_email_account':
      return <DisableEmailAccountModal />;

    case 'resume_sequences':
      return <ResumeSequencesModal />;

    case 'pause_sequences':
      return <PauseSequencesModal />;

    case 'email_account_preview_bar':
      return <EmailAccountPreviewBar />;

    case 'confirm':
      return <ConfirmModal />;

    case 'verify_phone':
      return <VerifyPhoneModal />;

    case 'contacts_filters_mobile':
      return <ContactsFiltersMobileMenu />;

    case 'manager_cancel_subscription':
      return <ManagerCancelSubscriptionModal />;

    case 'change_email':
      return <ChangeEmailModal />;

    case 'add_email_account':
      return <AddEmailAccountModal />;

    case 'delete_file_from_step':
      return <DeleteFileFromStepModal />;

    case 'add_leads_to_sequence':
      return <AddLeadsToSequence />;

    case 'launch_sequence':
      return <LaunchSequenceModal />;

    case 'send_test_campaign':
      return <SendTestCampaignModal />;

    case 'pause_editing_step':
      return <PauseEditingStepModal />;

    case 'launch_sequence_simple':
      return <LaunchSequenceSimpleModal />;

    case 'simple_export':
      return <SimpleExportModal />;

    case 'manager_delete_account':
      return <ManagerDeleteAccountModal />;

    case 'new_delete_account':
      return <NewDeleteAccountModal />;

    case 'confirmation_delete_account':
      return <ConfirmationDeleteAccountModal />;

    case 'delete_step_template':
      return <DeleteStepTemplateModal />;

    case 'select_all_sequence_leads':
      return <SelectAllSequenceLeadsModal />;

    case 'send_test_email':
      return <SendTestEmailModal />;

    case 'edit_contact_preview_bar':
      return <EditContactMainInfoPreviewBar />;

    case 'edit_contact_details_preview_bar':
      return <EditContactDetailsPreviewBar />;

    case 'contact_preview':
      return <NewContactPreviewBar />;

    case 'not_support_phone_numbers':
      return <NotSupportPhoneNumbersModal />;

    case 'company_preview':
      return <NewCompanyPreviewBar />;

    case 'edit_company_preview_bar':
      return <EditCompanyMainInfoPreviewBar />;

    case 'score_nps':
      return <ScoreNPSModal />;

    case 'add_google_account':
      return <AddGoogleAccountModal />;

    case 'add_unsubscriptions':
      return <AddUnsubscriptionsModal />;

    case 'delete_unsubscribes':
      return <DeleteUnsubscribesModal />;

    case 'imap_info':
      return <ImapInfoModal />;

    case 'app_password_info':
      return <AppPasswordInfoModal />;

    case 'add_app_password':
      return <AddAppPasswordModal />;

    case 'out_of_limits':
      return <OutOfLimitsModal />;

    default:
      return null;
  }
};

export default ModalsController;
