import get from 'lodash/get';
import { ACCOUNT_EVENTS } from '../types/event.types';
import * as CONSTANTS from '../../constants/paymentGate';

const initialState = {
  cards: [],
  tax: 0,
  checkoutStatus: CONSTANTS.STATUSES.READY,
  merchantId: null,
  fondyPfToken: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_CARDS': {
      return {
        ...state,
        cards: action.payload,
      };
    }
    case 'DELETE_CARD': {
      const { cardId } = action;
      const newCards = state.cards.filter((item) => item._id !== cardId);
      newCards.forEach((card, index) => {
        if (!index) {
          card.isDefault = true;
        }
      });
      return {
        ...state,
        cards: newCards,
      };
    }
    case 'SAVE_BLUESNAP_CARD': {
      return {
        ...state,
        cards: [...state.cards, action.payload],
      };
    }
    case 'SAVE_FONDY_CARD': {
      return {
        ...state,
        cards: [...state.cards, action.payload],
        checkoutStatus: CONSTANTS.STATUSES.COMPLETE,
      };
    }
    case ACCOUNT_EVENTS.SUBSCRIPTION_FAST_SPRING_EVENT:
    case ACCOUNT_EVENTS.SUBSCRIPTION_EVENT: {
      try {
        const data = JSON.parse(action.payload);
        return {
          ...state,
          cards: data.cards,
          checkoutStatus: CONSTANTS.STATUSES.COMPLETE,
        };
      } catch (err) {
        return {
          ...state,
          checkoutStatus: CONSTANTS.STATUSES.COMPLETE,
        };
      }
    }
    case 'GET_MERCHANT': {
      const merchantId = action.payload;
      return {
        ...state,
        merchantId,
      };
    }
    case 'SET_CHECKOUT_STATUS': {
      return {
        ...state,
        checkoutStatus: action.data,
      };
    }
    case 'GET_PAYMENT_FIELD_TOKEN': {
      return {
        ...state,
        fondyPfToken: action.payload,
      };
    }
    case 'SET_ACCOUNT_CARD': {
      const defaultCard = action.payload;
      const cards = state.cards.map((card) => {
        card.isDefault = false;
        if (get(defaultCard, '_id') === get(card, '_id')) card.isDefault = true;
        return card;
      });
      return {
        ...state,
        cards,
      };
    }
    default: return state;
  }
};
