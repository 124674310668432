import { Tooltip } from 'antd';
import React, { useState } from 'react';

import { EllipsisSpan } from 'components/commonStyles';

const SimpleEllipsisText = ({
  title,
  placement = 'top',
  children,
  className,
  showCondition = () => true,
}) => {
  const [visibleTooltip, setVisibleTooltip] = useState(false);

  return (
    <Tooltip placement={placement} title={title} visible={visibleTooltip} onVisibleChange={(visible) => showCondition() && setVisibleTooltip(visible)}>
      <EllipsisSpan className={className}>{children}</EllipsisSpan>
    </Tooltip>
  );
};

export default SimpleEllipsisText;
