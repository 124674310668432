import get from 'lodash/get';
import jwt from 'jsonwebtoken';

import StorageArray from 'helpers/storageArray';
import { createImportFilter } from 'helpers/filterFactory';
import * as SearchFiltersTypes from 'data/types/search.filters.types';
import { INSIGHTS_REQUEST_TYPE } from 'data/types/insights.request.type';
import { currentCompanyTechnologiesSelector } from 'data/selectors/companies';
import { createUserSearch } from './common';
import { createBulkOperation } from './bulkOperations';
import { updateLoading } from './loading';
import { getSavedInsightContacts } from './contacts';

export const getCompaniesFilters = () => (dispatch, getState) => {
  const state = getState();
  const savedFilters = get(state, 'companies.savedFilters', []);

  if (!savedFilters.length) {
    dispatch({
      type: 'GET_COMPANIES_FILTERS',
      payload: {
        endpoint: 'api/v1/filters',
        method: 'GET',
        params: {
          sourceType: 'company',
        },
        delayLoad: 0,
      },
      components: ['companiesFilters'],
    });
  }
};

export const toggleShowAllSavedFilters = (value) => (dispatch) => {
  dispatch({
    type: 'TOGGLE_SHOW_ALL_SAVED_FILTERS',
    data: { value },
  });
};

export const createCompaniesFilter = (name, filters, onSuccess) => (
  (dispatch) => {
    dispatch({
      type: 'CREATE_COMPANIES_FILTER',
      payload: {
        endpoint: 'api/v1/filters',
        method: 'POST',
        body: {
          name,
          filters,
          sourceType: 'company',
        },
        delayLoad: 0,
        showResponseError: true,
      },
      onSuccess,
      components: ['companiesFilters'],
    });
  }
);

export const updateCompaniesList = (filterId, filters) => (dispatch) => {
  dispatch({
    type: 'UPDATE_COMPANIES_LIST',
    payload: {
      endpoint: `api/v1/filters/${filterId}`,
      method: 'PATCH',
      body: {
        filters,
      },
    },
  });
};

export const deleteCompaniesFilter = (filterId, onSuccess) => (
  (dispatch) => {
    dispatch({
      type: 'DELETE_COMPANIES_FILTER',
      payload: {
        endpoint: `api/v1/filters/${filterId}`,
        method: 'DELETE',
      },
      filterId,
      onSuccess,
    });
  }
);

export const setCompaniesInitialRequest = (initialRequest) => (dispatch) => {
  dispatch({
    type: 'SET_COMPANIES_INITIAL_REQUEST',
    data: {
      initialRequest,
    },
  });
};

export const setInsightsCompaniesInitialRequest = (initialRequest) => (dispatch) => {
  dispatch({
    type: 'SET_INSIGHTS_COMPANIES_INITIAL_REQUEST',
    data: {
      initialRequest,
    },
  });
};

export const getAllCompanies = (onFailed = () => {}) => (dispatch) => {
  const onFailedDefault = (error) => {
    dispatch(setCompaniesInitialRequest(false));
    onFailed(error);
  };

  dispatch({
    type: 'GET_ALL_COMPANIES',
    payload: {
      endpoint: 'api/v1/companies/company',
      method: 'GET',
    },
    onFailed: onFailedDefault,
  });
};

export const getCompaniesCount = (body, params, deleted) => (dispatch) => {
  dispatch({
    type: deleted ? 'GET_DELETED_COMPANIES_COUNT' : 'GET_COMPANIES_COUNT',
    payload: {
      endpoint: 'api/v1/companies/company/search/count',
      method: 'POST',
      body,
      params,
      delayLoad: 700,
      cancelPrevRequests: true,
    },
    components: ['companiesCount'],
  });
};

export const searchCompanies = ({
  disableRefreshState,
  findCount = true,
  components,
  onSuccess = () => {},
  onFailed = () => {},
  deleted = false,
} = {}) => (dispatch, getState) => {
  const state = getState();
  const {
    pageSize, page, filters, sort, search,
  } = deleted ? get(
    state, 'companies.deletedCompanies',
  ) : get(
    state, 'companies.companies',
  );

  const params = {
    pageSize,
    pageNumber: page,
    sort: sort.column || '',
    order: sort.order || '',
  };
  const body = {
    filters, search, includeRelations: true, deleted,
  };

  const onFailedDefault = (data) => {
    dispatch(setCompaniesInitialRequest(false));
    onFailed(data);
  };

  dispatch({
    type: deleted ? 'SEARCH_DELETED_COMPANIES' : 'SEARCH_COMPANIES',
    payload: {
      endpoint: 'api/v1/companies/company/search',
      method: 'POST',
      body,
      params,
      delayLoad: 700,
      cancelPrevRequests: true,
    },
    components: deleted ? ['deletedItemsTable'] : (components || ['companiesTable', 'companiesListTable', 'searchCompanies']),
    refreshState: !disableRefreshState,
    onSuccess,
    onFailed: onFailedDefault,
  });
  if (findCount) {
    dispatch(getCompaniesCount(body, params, deleted));
  }
};

export const fastSearchDatabaseCompanies = ({ filters = [], components = [] }) => (dispatch) => {
  const params = {
    pageSize: 25, pageNumber: 1, sort: '', order: '',
  };
  const body = {
    filters, search: {}, includeRelations: true, deleted: false,
  };

  dispatch({
    type: 'FAST_SEARCH_DATABASE_COMPANIES',
    payload: {
      endpoint: 'api/v1/companies/company/search',
      method: 'POST',
      body,
      params,
      delayLoad: 0,
      cancelPrevRequests: true,
    },
    components: components || ['fastSearchCompaniesTab'],
  });
};

export const searchCompany = ({
  filters,
  onSuccess = () => {},
  onFailed = () => {},
  components = [],
} = {}) => (dispatch) => {
  dispatch({
    type: 'SEARCH_COMPANY',
    payload: {
      endpoint: 'api/v1/companies/company/search',
      method: 'POST',
      body: { filters },
    },
    components,
    onSuccess,
    onFailed,
  });
};

export const setCompaniesParams = ({
  filters,
  pageSize,
  page,
  sort,
  search,
  components,
  disableRefreshState,
  importId,
  findCount,
  disableSearch = false,
  onSuccess = () => {},
  onFailed = () => {},
  usePrevFilters = false,
} = {}) => (dispatch, getState) => {
  const state = getState();
  const currentPageSize = get(state, 'companies.companies.pageSize');
  const currentPage = get(state, 'companies.companies.page');
  let currentFilters = get(state, 'companies.companies.filters', []);
  const currentSearch = get(state, 'companies.companies.search');
  const currentSort = get(state, 'companies.companies.sort');
  const importFilter = [createImportFilter(importId)];

  if (!usePrevFilters) {
    if (importId) {
      currentFilters = importFilter;
    } else if (filters) {
      currentFilters = filters;
    }
  }

  const data = {
    pageSize: pageSize || currentPageSize,
    page: pageSize > currentPageSize ? 1 : page || currentPage,
    sort: sort === false ? {} : {
      column: sort?.column || currentSort.column || '',
      order: sort?.order || currentSort.order || '',
    },
    filters: currentFilters,
    search: search || currentSearch,
  };
  dispatch({
    type: 'SET_COMPANIES_PARAMS',
    data,
  });

  if (!disableSearch) {
    dispatch(searchCompanies({
      findCount, components, disableRefreshState, onSuccess, onFailed,
    }));
  }
};

export const setDeletedCompaniesParams = ({
  filters,
  pageSize,
  page,
  sort,
  search,
  components,
  disableRefreshState,
  importId,
  findCount,
  disableSearch = false,
  onSuccess = () => {},
  usePrevFilters = false,
  onFailed = () => {},
} = {}) => (dispatch, getState) => {
  const state = getState();
  const currentPageSize = get(state, 'companies.deletedCompanies.pageSize');
  const currentPage = get(state, 'companies.deletedCompanies.page');
  let currentFilters = get(state, 'companies.deletedCompanies.filters', []);
  const currentSearch = get(state, 'companies.deletedCompanies.search');
  const currentSort = get(state, 'companies.deletedCompanies.sort');
  const importFilter = [createImportFilter(importId)];

  if (!usePrevFilters) {
    if (importId) {
      currentFilters = importFilter;
    } else if (filters) {
      currentFilters = filters;
    }
  }

  const data = {
    pageSize: pageSize || currentPageSize,
    page: pageSize > currentPageSize ? 1 : page || currentPage,
    sort: sort || currentSort,
    filters: currentFilters,
    search: search || currentSearch,
  };
  dispatch({
    type: 'SET_DELETED_COMPANIES_PARAMS',
    data,
  });

  if (!disableSearch) {
    dispatch(searchCompanies({
      findCount,
      components,
      disableRefreshState,
      onSuccess,
      onFailed,
      deleted: true,
    }));
  }
};

export const addCompany = ({
  properties,
  source = 'crm',
  onSuccess = () => {},
  onFailed = () => {},
}) => (dispatch) => {
  dispatch({
    type: 'ADD_COMPANY',
    payload: {
      endpoint: 'api/v1/companies/company',
      method: 'POST',
      body: {
        properties,
        source,
      },
      showResponseError: true,
      delayLoad: 0,
    },
    components: ['addCompanyLoader'],
    onSuccess,
    onFailed,
  });
};

export const toggleSelectAllCompanies = (value, deleted = false) => (dispatch) => {
  dispatch({
    type: 'TOGGLE_SELECT_ALL_COMPANIES',
    data: {
      value,
      deleted,
    },
  });
};

export const setSelectedCompanies = (selectedCompanies) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_COMPANIES',
    data: selectedCompanies,
  });
};

export const excludeCompanies = (excludedCompanies) => (dispatch) => {
  dispatch({
    type: 'EXCLUDE_COMPANIES',
    data: excludedCompanies,
  });
};

export const editCompaniesProperty = (properties, companyIds, excludedIds, onSuccess) => (
  (dispatch, getState) => {
    const state = getState();
    const filters = get(state, 'companies.companies.filters', []);
    const search = get(state, 'companies.companies.search');

    const params = {
      selectAll: get(state, 'companies.selectAllCompanies.selectAll', false),
    };

    const onSuccessProxy = (data) => {
      if (data._id) {
        dispatch(createBulkOperation({ _id: data._id, sourceType: 'companies', type: 'update' }));
      }
      onSuccess(data);
    };

    const itemIds = params.selectAll ? [] : companyIds;

    dispatch({
      type: 'EDIT_COMPANIES_PROPERTY',
      payload: {
        endpoint: 'api/v1/bulk-operations',
        method: 'POST',
        body: {
          type: 'update',
          sourceType: 'companies',
          properties,
          filters,
          itemIds,
          search,
          excludedIds,
        },
        showResponseError: true,
      },
      properties,
      itemIds,
      onSuccess: onSuccessProxy,
    });
  }
);

export const updateCompaniesConditions = (objectIdList, excludedIds, deleted = false, operationType = 'delete', onSuccess) => (
  (dispatch, getState) => {
    const state = getState();
    const filters = deleted ? get(state, 'companies.deletedCompanies.filters', []) : get(state, 'companies.companies.filters', []);
    const search = deleted ? get(state, 'companies.deletedCompanies.search') : get(state, 'companies.companies.search');

    const params = {
      selectAll: deleted ? get(state, 'companies.deletedCompanies.selectAll', false) : get(state, 'companies.selectAllCompanies.selectAll', false),
    };

    const onSuccessProxy = (data) => {
      if (data._id) {
        dispatch(createBulkOperation({ _id: data._id, sourceType: 'companies', type: operationType }));
      }
      onSuccess(data);
      if (excludedIds.length) dispatch(setCompaniesParams({ page: 1 }));
    };

    dispatch({
      type: 'DELETE_COMPANIES',
      payload: {
        endpoint: 'api/v1/bulk-operations',
        method: 'POST',
        body: {
          type: operationType,
          itemIds: params.selectAll ? [] : objectIdList,
          sourceType: 'companies',
          filters,
          search,
          excludedIds,
        },
      },
      companiesIds: objectIdList,
      onSuccess: onSuccessProxy,
    });

    dispatch({
      type: 'CHECK_ENRICH_COMPANY_WHEN_DELETED',
      data: {
        companyIds: objectIdList,
      },
    });
  }
);

export const getBlockedCompanies = () => (dispatch, getState) => {
  const state = getState();
  const pageSize = get(state, 'companies.blockedCompanies.pageSize');
  const page = get(state, 'companies.blockedCompanies.page');
  const search = get(state, 'companies.blockedCompanies.search');

  dispatch({
    type: 'GET_BLOCKED_COMPANIES',
    payload: {
      endpoint: 'api/v1/insights/search/blocked/companies/search',
      method: 'POST',
      params: { pageNumber: page, pageSize },
      body: {
        search,
      },
      cancelPrevRequests: true,
    },
  });
};

export const setBlockedCompaniesParams = (props) => (dispatch, getState) => {
  const state = getState();
  const currentPageSize = get(state, 'companies.blockedCompanies.pageSize');
  const currentPage = get(state, 'companies.blockedCompanies.page');
  const currentSearch = get(state, 'companies.blockedCompanies.search');

  const data = {
    pageSize: props?.pageSize || currentPageSize,
    page: props?.page || currentPage,
    search: props?.search || currentSearch,
  };

  dispatch({
    type: 'SET_BLOCKED_COMPANIES_PARAMS',
    data,
  });

  dispatch(getBlockedCompanies());
};

export const deleteBlockedCompany = (companyId) => (dispatch, getState) => {
  const state = getState();
  const { page: currentPage, pageSize: currentPageSize } = state.companies.blockedCompanies;
  const onSuccessDelete = () => {
    const page = state.companies.blockedCompanies.data.length - 1 ? currentPage : currentPage - 1;
    if (state.companies.blockedCompanies.data.length - 1 <= 1 && state.companies.blockedCompanies.totalItems >= currentPageSize) {
      dispatch(setBlockedCompaniesParams({ page: page < 1 ? 1 : page }));
    }
  };
  dispatch({
    type: 'DELETE_BLOCKED_COMPANY',
    payload: {
      endpoint: `api/v1/insights/search/blocked/companies/${companyId}`,
      method: 'DELETE',
      delayLoad: 0,
    },
    onSuccess: onSuccessDelete,
    companyId,
    components: ['deleteBlockedCompanyLoader'],
  });
};

export const addBlockedCompanies = (domains) => (dispatch) => {
  dispatch({
    type: 'ADD_BLOCKED_COMPANIES',
    payload: {
      endpoint: 'api/v1/insights/search/blocked/companies',
      method: 'POST',
      body: {
        domains,
      },
      showResponseError: true,
    },
  });
};

export const setCompanyId = (companyId) => (dispatch) => {
  dispatch({
    type: 'SET_COMPANY_ID',
    data: {
      companyId,
    },
  });
};

export const updateCompany = (companyId, properties) => (dispatch) => {
  dispatch({
    type: 'UPDATE_COMPANY',
    payload: {
      endpoint: `api/v1/companies/company/${companyId}`,
      method: 'PATCH',
      body: {
        properties,
      },
    },
    components: ['updatePreviewBarButton'],
  });
};

export const searchInsightCompanies = ({
  filters, pageSize, page, sort, onSuccess = () => {}, onFailed = () => {},
}) => (dispatch, getState) => {
  const state = getState();
  let currentPageSize = get(state, 'companies.insights.pageSize');
  const currentPage = get(state, 'companies.insights.page');
  const currentFilters = get(state, 'companies.insights.filters');
  const tableMeta = localStorage.getItem('tableMeta');

  if (tableMeta) {
    const parsedTableMeta = JSON.parse(tableMeta);
    currentPageSize = get(parsedTableMeta, 'companyInsight.pageSize', currentPageSize);
  }

  const params = {
    pageSize: pageSize || currentPageSize,
    pageNumber: page || currentPage,
  };

  let debounce;
  if (page === currentPage) {
    debounce = {
      time: 1000,
    };
  }

  if (sort) {
    params.sort = sort.column;
    params.order = sort.order;
  }

  const onFailedDefault = (data) => {
    dispatch(setInsightsCompaniesInitialRequest(false));
    onFailed(data);
  };

  const filtersTypes = SearchFiltersTypes.COMPANY_SEARCH_FILTERS;
  const filtersItem = filters ? [...filters] : [...currentFilters];
  const updatedFilters = filtersItem.filter((filter) => filtersTypes.includes(filter.property));

  dispatch({
    type: 'SEARCH_INSIGHT_COMPANIES',
    payload: {
      endpoint: 'api/v1/insights/search/companies',
      method: 'POST',
      body: {
        filters: updatedFilters,
      },
      params,
      delayLoad: 700,
      cancelPrevRequests: true,
      showResponseError: true,
    },
    debounce,
    onSuccess,
    onFailed: onFailedDefault,
    components: ['searchCompanyInsights'],
  });
};

export const getSavedInsightCompanies = ({
  type = 'GET_SAVED_INSIGHT_COMPANIES',
  data = [],
  onlyEnrich = false,
  isSidebar,
}) => (dispatch) => {
  const insightIds = data.map((el) => el.id || el._id);
  const linkedinUrls = data.map((el) => el.linkedinUrl).filter((el) => !!el);
  const domains = data.map((el) => el.domain).filter((el) => !!el);

  dispatch({
    type,
    payload: {
      endpoint: 'api/v1/insights/search/companies/saved',
      method: 'POST',
      body: {
        insightIds,
        linkedinUrls,
        domains,
      },
    },
    isSidebar,
    insightIds,
    onlyEnrich,
  });
};

export const renameCompaniesFilter = (name, filterId) => (dispatch) => {
  dispatch({
    type: 'RENAME_COMPANIES_FILTER',
    payload: {
      endpoint: `api/v1/filters/${filterId}`,
      method: 'PATCH',
      body: {
        name,
      },
    },
  });
};

export const cloneCompaniesFilter = (name, filters, onSuccess) => (
  (dispatch) => {
    dispatch({
      type: 'CLONE_COMPANIES_FILTER',
      payload: {
        endpoint: 'api/v1/filters',
        method: 'POST',
        body: {
          name,
          filters,
          sourceType: 'company',
        },
        delayLoad: 0,
      },
      onSuccess,
      components: ['companiesFilters'],
    });
  }
);

export const getCompanyById = ({
  companyId,
  isSidebar,
  onSuccess = () => {},
  onFailed = () => {},
}) => (dispatch) => {
  dispatch({
    type: 'GET_COMPANY_BY_ID',
    payload: {
      endpoint: `api/v1/companies/company/${companyId}`,
      method: 'GET',
      delayLoad: 0,
    },
    isSidebar,
    components: [isSidebar ? 'companySidebarLoading' : 'previewCompany_company_data'],
    onSuccess,
    onFailed,
  });
};

export const findSuggestedCompanies = ({
  query = '',
  limit = 10,
  searchInsights = true,
  components = ['AddToContactCompanies'],
  onSuccess = () => {},
}) => (dispatch) => {
  dispatch({
    type: 'GET_SUGGESTED_COMPANIES',
    payload: {
      endpoint: 'api/v1/companies/company/search',
      method: 'GET',
      params: {
        q: query,
        limit,
        searchInsights,
      },
    },
    components,
    onSuccess,
  });
};

export const findCompaniesWithName = ({
  name = '',
  limit = 10,
  components = [],
  onSuccess = () => {},
}) => (dispatch) => {
  dispatch({
    type: 'GET_SUGGESTED_COMPANIES_WITH_NAME',
    payload: {
      endpoint: 'api/v1/companies/company/search/query',
      method: 'GET',
      params: {
        name,
        limit,
      },
      cancelPrevRequests: true,
    },
    components,
    onSuccess,
  });
};

export const saveCompanyFromInsights = (insightId, onSuccess = () => {}, onFailed = () => {}) => (dispatch, getState) => {
  const state = getState();
  const token = get(state, 'user.auth.token');
  const userId = get(jwt.decode(token), '_id');
  const workspaceId = get(jwt.decode(token), 'workspaceId');
  const lastUserFilters = get(state, 'search.searchesHistory.data[0]');
  const lastUserSearchId = get(state, 'search.lastUserSearchId');
  const storage = new StorageArray('userSearchCompany', {
    kind: sessionStorage,
  });
  const foundFilters = storage.findItem([
    { field: '_id', value: userId },
    { field: 'workspaceId', value: workspaceId },
  ]);

  const load = (loading) => {
    if (lastUserFilters?._id !== lastUserSearchId && foundFilters) {
      dispatch(updateLoading(['createUserSearchLoading'], loading));
    }
  };

  dispatch({
    type: 'UPDATE_INSIGHTS_COMPANY_STATUS',
    data: {
      isSaving: true,
      insightIds: [insightId],
    },
  });

  const onSuccessProxy = (result) => {
    load(false);
    onSuccess(result);
  };

  const onFailedProxy = (err) => {
    load(false);
    dispatch({
      type: 'UPDATE_INSIGHTS_COMPANY_STATUS',
      data: {
        status: null,
        isSaving: false,
        insightIds: [insightId],
      },
    });
    onFailed(err);
  };

  const saveCompany = (userSearchId) => {
    dispatch({
      type: 'SAVE_COMPANY_FROM_INSIGHTS',
      payload: {
        endpoint: `api/v1/companies/company/save/insight/${insightId}`,
        method: 'POST',
        body: {
          userSearchId,
        },
        showResponseError: true,
      },
      userSearchId,
      insightId,
      onSuccess: onSuccessProxy,
      onFailed: onFailedProxy,
    });
  };

  if (lastUserFilters && lastUserFilters._id === lastUserSearchId) {
    saveCompany(lastUserSearchId);
  } else if (foundFilters) {
    try {
      if (foundFilters.filters?.length) {
        dispatch(createUserSearch({
          ...foundFilters,
          onSuccess: ({ _id: userSearchId }) => {
            saveCompany(userSearchId);
          },
          onFailed: onFailedProxy,
        }));
      }
    } finally {
      storage.removeItem([
        { field: '_id', value: userId },
        { field: 'workspaceId', value: workspaceId },
      ]);
    }
  } else {
    saveCompany();
  }
};

export const bulkSaveCompaniesFromInsights = ({ insightIds, bulk }) => (dispatch) => {
  dispatch({
    type: 'BULK_SAVE_COMPANIES_FROM_INSIGHTS',
    data: {
      bulk,
      insightIds,
    },
  });
};

export const setSelectedInsightCompanies = (selectedInsightCompanies) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_INSIGHT_COMPANIES',
    data: selectedInsightCompanies,
  });
};

export const clearCurrentCompany = (companyId) => (dispatch) => {
  dispatch({
    type: 'CLEAR_CURRENT_COMPANY',
    data: { companyId },
  });
};

export const selectFilterCompany = (filter) => (dispatch) => dispatch({
  type: 'SELECT_FILTER_COMPANY',
  data: filter,
});

export const findCompanyByDomain = (domain, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'FIND_COMPANY_BY_DOMAIN',
    payload: {
      endpoint: 'api/v1/companies/company/search/domain',
      method: 'GET',
      params: { domain, limit: 1 },
    },
    onSuccess,
    onFailed,
  });
};

export const getLastThreeContacts = (companyId) => (dispatch) => {
  dispatch({
    type: 'GET_LAST_THREE_CONTACTS',
    payload: {
      endpoint: `api/v1/companies/company/${companyId}/last-contacts`,
      method: 'GET',
    },
  });
};

export const getPopularInsightCompanies = () => (dispatch) => {
  const params = {
    pageSize: 20,
    pageNumber: 1,
  };

  dispatch({
    type: 'GET_POPULAR_INSIGHTS_COMPANIES',
    payload: {
      endpoint: 'api/v1/insights/search/companies',
      method: 'POST',
      body: {
        filters: [],
      },
      params,
      delayLoad: 700,
      cancelPrevRequests: true,
    },
    components: [''],
  });
};

export const clearCompaniesSearch = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_COMPANIES_SEARCH',
    data: {},
  });
};

export const getInaccurateFields = ({
  entityIds,
}) => (dispatch) => {
  dispatch({
    type: 'GET_INACCURATE_DOMAINS',
    payload: {
      endpoint: 'api/v1/inaccurate-field/company',
      method: 'GET',
      params: {
        entityIds,
      },
      delayLoad: 0,
      showResponseError: true,
    },
    entityIds,
    components: ['inaccurateFieldLoading'],
  });
};

export const getCompanyCompetitors = ({
  domain,
  isSidebar,
}) => (dispatch) => {
  dispatch({
    type: 'GET_COMPANY_COMPETITORS',
    payload: {
      endpoint: 'api/v1/companies/company/competitors',
      method: 'GET',
      params: {
        domain,
      },
      delayLoad: 0,
      showResponseError: true,
    },
    onSuccess: (competitors) => {
      dispatch(getSavedInsightCompanies({ type: 'GET_SAVED_INSIGHT_COMPETITORS', isSidebar, data: competitors }));
    },
    isSidebar,
    components: [isSidebar ? 'getCompanyCompetitorsSidebarLoading' : 'getCompanyCompetitorsLoading'],
  });
};

export const getCompanyContactPositions = ({
  domain,
  position,
  onSuccess,
  onFailed,
}) => (dispatch) => {
  dispatch({
    type: 'GET_COMPANY_CONTACT_POSITIONS',
    payload: {
      endpoint: 'api/v1/insights/analytics/company/contact-positions',
      method: 'GET',
      params: {
        domain,
        position,
        limit: 100,
      },
      delayLoad: 0,
    },
    components: ['getCompanyContactPositionsLoading'],
    onSuccess,
    onFailed,
  });
};

export const getCompanyContactCountries = (domain) => (dispatch) => {
  dispatch({
    type: 'GET_COMPANY_CONTACT_COUNTRIES',
    payload: {
      endpoint: 'api/v1/insights/analytics/company/contact-countries',
      method: 'GET',
      params: {
        domain,
      },
      delayLoad: 0,
    },
    components: ['getCompanyContactCountriesLoading'],
  });
};

export const getCompanyContactDepartments = (domain) => (dispatch) => {
  dispatch({
    type: 'GET_COMPANY_CONTACT_DEPARTMENTS',
    payload: {
      endpoint: 'api/v1/insights/analytics/company/contact-departments',
      method: 'GET',
      params: {
        domain,
      },
      delayLoad: 0,
    },
    components: ['getCompanyContactCountriesLoading'],
  });
};

export const getCurrentCompanyTechnologies = ({
  technologyIds,
  isSidebar,
}) => (dispatch) => {
  dispatch({
    type: 'GET_CURRENT_COMPANY_TECHNOLOGIES',
    payload: {
      endpoint: 'api/v1/technologies',
      params: {
        technologyIds,
      },
      method: 'GET',
      delayLoad: 0,
    },
    isSidebar,
    components: ['currentCompanyTechnologiesLoader'],
  });
};

export const searchTechnologies = (value, onFinish) => (dispatch, getState) => {
  const state = getState();
  const technologies = currentCompanyTechnologiesSelector(state);

  dispatch({
    type: 'SEARCH_TECHNOLOGIES',
    payload: {
      endpoint: 'api/v1/technologies/search',
      method: 'POST',
      body: {
        search: {
          value,
          properties: ['name'],
        },
      },
      delayLoad: 0,
      params: {
        pageNumber: 1,
        pageSize: 100,
      },
    },
    debounce: {
      time: 500,
    },
    onSuccess: onFinish,
    onFailed: onFinish,
    technologies,
    components: ['searchTechnologiesLoader'],
  });
};

export const searchCompanyEmployers = ({
  filters,
  saved,
  search,
  page = 1,
  pageSize = 100,
  isSidebar,
}) => (dispatch) => {
  const params = {
    pageSize,
    pageNumber: page,
  };

  if (saved) {
    dispatch({
      type: 'SEARCH_COMPANY_EMPLOYEES',
      payload: {
        endpoint: 'api/v1/contacts/contact/search',
        method: 'POST',
        body: {
          search,
          filters,
          deleted: false,
          includeRelations: true,
          includes: [
            'properties.firstName',
            'properties.lastName',
            'companyRelations.company',
            'companyRelations.position',
            'companyRelations.email',
            'createdAt',
            'updatedAt',
            'companyRelations._id',
            'properties.linkedinUrl',
            'properties.name',
            'properties.email',
            'properties.createdAt',
          ],
        },
        params: {
          ...params,
          includeCount: true,
        },
        delayLoad: 0,
      },
      isSidebar,
      saved,
      components: [isSidebar ? 'searchCompanySavedContactInsightsSidebar' : 'searchCompanySavedContactInsights'],
    });
  } else {
    const requestType = INSIGHTS_REQUEST_TYPE.EXCLUDED;

    dispatch({
      type: 'SEARCH_COMPANY_EMPLOYEES',
      payload: {
        endpoint: 'api/v1/insights/search/contacts',
        method: 'POST',
        body: {
          filters,
          requestType,
        },
        params,
        delayLoad: 0,
      },
      isSidebar,
      components: [isSidebar ? 'searchCompanyContactInsightsSidebar' : 'searchCompanyContactInsights'],
      onSuccess: ({ data }) => {
        dispatch(getSavedInsightContacts({ data }));
      },
    });
  }
};

export const getCompanyKeywords = (query = '', limit = 5) => (dispatch) => {
  dispatch({
    type: 'GET_COMPANY_KEYWORDS',
    payload: {
      endpoint: `api/v1/insights/search/company/keyword/autocomplete?q=${query}&limit=${limit}`,
      method: 'GET',
      delayLoad: 0,
    },
    components: ['getCompanyKeywordsLoading'],
  });
};

export const searchCompanyTechnologies = ({
  value,
  onSuccess,
} = {}) => (dispatch, getState) => {
  const state = getState();
  const technologies = currentCompanyTechnologiesSelector(state);

  dispatch({
    type: 'SEARCH_COMPANY_TECHNOLOGIES',
    payload: {
      endpoint: 'api/v1/technologies/search',
      method: 'POST',
      body: {
        search: {
          value,
          properties: ['name'],
        },
      },
      delayLoad: 0,
      params: {
        pageNumber: 1,
        pageSize: 100,
      },
    },
    onSuccess,
    technologies,
    components: ['searchCompanyTechnologiesLoader'],
  });
};

export const addPopulatedTechnology = (technology) => (dispatch) => {
  dispatch({
    type: 'ADD_POPULATED_TECHNOLOGY',
    data: {
      technology,
    },
  });
};
