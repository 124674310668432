import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'antd';
import get from 'lodash/get';

import { Search } from 'components/common';
import { contactPropertiesSelector } from 'data/selectors/contacts';
import {
  GroupName,
  PropertiesGroup,
  PropertyItem,
  GroupTitle,
  SearchForm,
  SearchFormContainer,
  BackIcon,
  Back,
} from './styles';
import FilterEditor from './filterEditor';

const PropertiesList = ({ filters, toggleShowFilters, showFilters }) => {
  const properties = get(useSelector(contactPropertiesSelector), 'data', []);

  const [mode, setMode] = useState('all');
  const [editableFilter, setEditableFilter] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [searchName, handleChange] = useState('');

  useEffect(() => {
    const data = properties.filter((item) => get(item, 'label', '')
      .toLowerCase()
      .startsWith(searchName));
    setDataSource(data);
  }, [properties, searchName]);
  return (
    <>
      {(!showFilters && mode !== 'editor') && (
        <Back onClick={() => toggleShowFilters(true)}>
          <Icon type="left" style={BackIcon} />
          <span>Back</span>
        </Back>
      )}
      {
        mode === 'editor' ? (
          <FilterEditor
            filter={editableFilter}
            back={() => setMode('all')}
            filters={filters}
            toggleShowFilters={toggleShowFilters}
          />
        )
          : (
            <>
              <GroupTitle>Contact properties</GroupTitle>
              <SearchFormContainer>
                <Search
                  size="default"
                  placeholder="Search"
                  style={SearchForm}
                  value={searchName}
                  onChange={(e) => handleChange(e.target.value.toLowerCase())}
                />
              </SearchFormContainer>
              <PropertiesGroup>
                <GroupName>All properties</GroupName>
                {
                  dataSource.map((item) => (
                    <PropertyItem
                      key={`property_item_${item._id}`}
                      disabled={filters.find((filter) => filter.property === item.name)}
                      onClick={() => {
                        setEditableFilter(item);
                        setMode('editor');
                      }}
                    >
                      {item.label}
                    </PropertyItem>
                  ))
                }
              </PropertiesGroup>
            </>
          )
        }
    </>
  );
};

export default PropertiesList;
