import React from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { toggleModal } from 'data/actions/modals';
import AddRecipientsToSequence from 'components/addRecipientsToSequence';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import {
  ModalTitle,
  ProperCloseIcon,
} from '../baseModal/styles';

const AddLeadsToSequenceModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const { sequenceId } = useSelector(modalOptionsSelector);

  const handleClose = () => dispatch(toggleModal('add_leads_to_sequence', false));

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle white>
          <ProperCloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      wrapClassName="ant-white-modal"
      closable={false}
      width={560}
      footer={false}
      onCancel={handleClose}
    >
      <div style={{ height: 247, width: '100%' }}>
        <AddRecipientsToSequence onSelect={handleClose} sequenceId={sequenceId} />
      </div>
    </Modal>
  );
};

export default AddLeadsToSequenceModal;
