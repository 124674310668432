import styled from 'styled-components';

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  top: 30px;
  right: 30px;
  width: 400px;
  z-index: 9999;
`;
