import styled from 'styled-components';

import * as CONSTANTS from 'constants/styles';

export const PopoverTitle = styled.a`
  ${(props) => (props.hoverChangeDisplay
    ? ` display: ${props.visible ? 'flex !important' : 'none'}; `
    : '')}
  align-items: center;
  font-size: 12px;
  margin-top: 5px;
  color: #0091ae;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #1890ff;

  .material-icons {
    transition: transform 0.5s ease;
    ${(props) => (props.animate ? 'transform: rotate(180deg);' : 'transform: rotate(0deg);')}
  }
`;

export const MenuItem = styled.button`
  cursor: pointer;
  color: #33475b;
  display: block;
  min-height: 40px;
  padding: 8px 20px;
  background-color: #fff;
  line-height: 24px;
  text-align: left;
  margin: 0 -16px;
  width: calc(100% + 32px);
  background: no-repeat;
  border: none;
  outline: none;
  min-width: 180px;

  &:hover {
    background-color: ${(props) => (props.isActivity ? CONSTANTS.COLORS.GREY_G14 : CONSTANTS.COLORS.BLUE_B7)};
  }

  &:disabled {
    opacity: .6;
    cursor: not-allowed;
  }
`;

export const Divider = styled.hr`
  background-color: rgb(223, 227, 235);
  height: 1px;
  border-width: 0px;
  min-height: 1px;
  margin: 8px -16px;
  width: calc(100% + 32px);
`;

export const IconCaretDown = {
  color: '#7c98b6',
  marginLeft: '4px',
  fontSize: '10px',
};
