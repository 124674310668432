import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleModal } from 'data/actions/modals';
import { ConfirmationModal } from 'components/common';
import { modalOptionsSelector } from 'data/selectors/modals';
import { deleteAllTeammates, deleteTeammate } from 'data/actions/teammates';

const DeleteTeammatesModal = () => {
  const dispatch = useDispatch();

  const { isDeleteAll = false, relationId, teammateName = 'this user' } = useSelector(modalOptionsSelector);

  const onSubmit = () => {
    if (isDeleteAll) {
      dispatch(deleteAllTeammates());
    } else {
      dispatch(deleteTeammate({ relationId }));
    }
  };

  const handleClose = () => dispatch(toggleModal('delete_teammates', false));

  return (
    <ConfirmationModal
      title="Delete teammates"
      type="danger"
      onClose={handleClose}
      onSubmit={onSubmit}
      label={isDeleteAll
        ? <p>Delete the whole team?</p>
        : <p>{`Delete ${teammateName} from the team?`}</p>}
    />
  );
};

export default DeleteTeammatesModal;
