import { Card } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import noTasksV2Image from 'assets/images/no-tasks-v2.svg';

import Loader from 'components/loading';
import { Icon } from 'components/common';
import * as CONSTANTS from 'constants/styles';
import { toggleModal } from 'data/actions/modals';
import { EmptySection } from 'components/emptySection/emptySection';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import {
  getManagedSearchesHistory, getSearches,
} from 'data/actions/search';
import {
  ModalTitle,
  CloseIcon,
  HeaderStyle,
} from 'components/modals/baseModal/styles';
import { sortDatasetByMonths } from 'helpers/arrays';
import { SOURCE_TYPE } from 'data/types/source.types';
import { SEARCH_CONTENT, SEARCH_KIND } from 'constants/searchContent';
import { searchesPreviewLoadingSelector } from 'data/selectors/loading';
import {
  initialRequestSearchesPreviewBarSelector,
  lastUserSearchIdSelector,
  searchesHistorySelector,
  searchesSelector,
  searchFiltersSelector,
  sourceTypeInsightsSelector,
} from 'data/selectors/search';
import {
  Container,
  DrawerWrapper,
  TitleStyle,
  Drawer,
  DrawerStyle,
  ButtonShowMore,
  ContentWrapper,
} from '../notificationsPreviewBar/styles';
import SearchContent from './searchContent';
import { WrapperEmptySections } from './styles';

const SearchesPreviewBar = () => {
  const dispatch = useDispatch();

  const {
    data: searchHistories = [],
    totalPages = 0,
    page = 0,
  } = useSelector(searchesHistorySelector);
  const {
    data: searches = [],
    totalPages: searchTotalPages = 0,
    page: searchPage = 0,
  } = useSelector(searchesSelector);
  const { visible } = useSelector(modalsSelector);
  const {
    loadSearches = true,
    show = SEARCH_CONTENT.RECENT_ACTIVITY,
    scrollTop = 0,
  } = useSelector(modalOptionsSelector);
  const searchFilters = useSelector(searchFiltersSelector());
  const sourceType = useSelector(sourceTypeInsightsSelector);
  const loading = useSelector(searchesPreviewLoadingSelector);
  const lastUserSearchId = useSelector(lastUserSearchIdSelector);
  const initialRequest = useSelector(initialRequestSearchesPreviewBarSelector);

  const listRef = useRef();

  const data = [];
  if (show === SEARCH_CONTENT.RECENT_ACTIVITY) {
    const hasVirtualProperty = searchFilters.length
      && searchFilters.filter((filter) => filter.property !== 'email').length
      && !lastUserSearchId;
    if (hasVirtualProperty) {
      data.push({
        name: sourceType === SOURCE_TYPE.CONTACT ? 'Lead search' : 'Company search',
        sourceType,
        filters: searchFilters,
        kind: SEARCH_KIND.USER_SEARCH,
        createdAt: new Date().toISOString(),
      });
    }
    data.push(...searchHistories.map((search) => {
      let name;
      if (SEARCH_KIND.SEARCH_HISTORY === search.kind || search.saved) {
        name = search.source === SOURCE_TYPE.CONTACT ? 'Leads saved' : 'Companies saved';
      } else {
        name = search.source === SOURCE_TYPE.CONTACT ? 'Lead search' : 'Company search';
      }
      return {
        ...search,
        name,
        kind: search.kind ? search.kind : SEARCH_KIND.SEARCH_HISTORY,
      };
    }));
  }
  data.push(...searches.map((search) => ({ ...search, kind: SEARCH_KIND.SEARCH })));

  const sortedNotifications = sortDatasetByMonths(data, 'createdAt');
  const sortedNotificationsKeys = Object.keys(sortedNotifications);
  const showSearchesHistoryShowMore = totalPages !== page && totalPages !== 0;
  const showSearchesShowMore = searchTotalPages !== searchPage && searchTotalPages !== 0;
  const showButtonShowMore = show === SEARCH_CONTENT.RECENT_ACTIVITY ? (
    showSearchesHistoryShowMore || showSearchesShowMore
  ) : showSearchesShowMore;

  const handleClose = () => dispatch(toggleModal('searches_preview', false));

  useEffect(() => {
    if (loadSearches) {
      if (show === SEARCH_CONTENT.RECENT_ACTIVITY) {
        dispatch(getManagedSearchesHistory({
          pageSize: 100,
        }));
      }
      dispatch(getSearches({
        pageSize: 100,
      }));
    }
  }, [dispatch, loadSearches, show]);

  return (
    <DrawerWrapper>
      <Drawer
        title={(
          <ModalTitle
            isRecent={show === SEARCH_CONTENT.RECENT_ACTIVITY}
          >
            {show === SEARCH_CONTENT.RECENT_ACTIVITY ? 'Recent Activity' : 'Saved searches'}
            <CloseIcon
              data-close="true"
              onClick={handleClose}
              className="ic_close"
            />
          </ModalTitle>
        )}
        placement="right"
        closable={false}
        onClose={handleClose}
        headerStyle={HeaderStyle}
        bodyStyle={DrawerStyle}
        visible={visible}
        width={CONSTANTS.DRAWER_WIDTH}
      >
        <Loader loading={loading}>
          <Container
            id="searches-list"
            disablePadding="true"
            ref={(ref) => {
              if (!listRef.current) {
                ref.scrollTop = scrollTop;
                listRef.current = ref;
              }
            }}
            greyBg={!sortedNotificationsKeys?.length}
          >
            {sortedNotificationsKeys?.length ? (
              <>
                {Object.keys(sortedNotifications).map((key, index) => (
                  <Card
                    headStyle={{
                      background: '#f7f8fa',
                      minHeight: '0px',
                    }}
                    type="inner"
                    title={(
                      <TitleStyle
                        isActivity
                      >
                        {key}
                      </TitleStyle>
                    )}
                    key={`search-history-card-${index + 1}`}
                  >
                    <ContentWrapper>
                      { sortedNotifications[key].map((search, i) => (
                        <div key={`search-history-item-${index + i}`}>
                          <SearchContent search={search} />
                        </div>
                      )) }
                    </ContentWrapper>
                  </Card>
                ))}
                {
                  showButtonShowMore && (
                    <ButtonShowMore
                      data-form="true"
                      type="primary"
                      onClick={() => {
                        if (show === SEARCH_CONTENT.RECENT_ACTIVITY) {
                          if (showSearchesHistoryShowMore) {
                            dispatch(getManagedSearchesHistory({ page: page + 1, pageSize: 100, erasePreviousData: false }));
                          } if (showSearchesShowMore) {
                            dispatch(getSearches({ page: searchPage + 1, pageSize: 100, erasePreviousData: false }));
                          }
                        } else if (show === SEARCH_CONTENT.SAVED_FILTERS) {
                          dispatch(getSearches({ page: searchPage + 1, pageSize: 100, erasePreviousData: false }));
                        }
                      }}
                    >
                      <span>Show more</span>
                      <Icon type="ic-expand-more" className="ic_add" />
                      <Loader loading={loading} />
                    </ButtonShowMore>
                  )
                }
              </>
            ) : (
              !initialRequest && (
                <WrapperEmptySections>
                  <EmptySection
                    style={{
                      margin: 0, boxShadow: 'none', border: 'none',
                    }}
                    icon={noTasksV2Image}
                    text="Start a new search using filters, and your activity will be saved here for the next time you'll need it."
                    header="No recent activity"
                    className="empty-recent-activity"
                  />
                </WrapperEmptySections>
              )
            )}
          </Container>
        </Loader>
      </Drawer>
    </DrawerWrapper>
  );
};

export default SearchesPreviewBar;
