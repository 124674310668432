export const authorizeUser = ({
  clientId, state, redirectUri, workspaceId,
}) => (dispatch) => {
  dispatch({
    type: 'AUTHORIZE_USER',
    payload: {
      endpoint: 'oauth2/authorize',
      method: 'POST',
      delayLoad: 0,
      body: {
        clientId,
        redirectUri,
        workspaceId,
      },
    },
    onSuccess: (data) => {
      if (data?.code) window.location.replace(`${redirectUri}?code=${data?.code}&state=${state}&access_type=offline&prompt=consent`);
      else window.close();
    },
    onFailed: () => window.close(),
    components: ['oauth2pageLoading'],
  });
};

export const getOAuthAppType = ({ clientId, onSuccess = () => {}, onFailed = () => {} }) => (dispatch) => {
  dispatch({
    type: 'GET_OAUTH_APP_TYPE',
    payload: {
      endpoint: 'oauth2/app-type',
      method: 'GET',
      params: {
        clientId,
      },
      delayLoad: 0,
    },
    components: ['oauth2pageLoading'],
    onSuccess,
    onFailed,
  });
};
