import { Modal } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { Container } from '../deleteStep/styles';
import {
  ModalTitle, CloseIcon, Footer,
} from '../baseModal/styles';

const ConfirmModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const {
    header = 'Confirm',
    body = 'Do you confirm?',
    callback = () => {},
    confirmBtnTxt = 'Yes',
    declineBtnTxt = 'No',
  } = useSelector(modalOptionsSelector);

  const handleClose = useCallback(() => {
    dispatch(toggleModal('confirm', false));
  }, [dispatch]);

  const handleConfirm = (result) => {
    handleClose();
    callback(result);
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          {header}
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <Container>
        {body}
      </Container>
      <Footer>
        <Button
          data-form="true"
          type="primary"
          size="large"
          onClick={() => handleConfirm(true)}
        >
          {confirmBtnTxt}
        </Button>
        <Button
          size="large"
          type="normal"
          onClick={() => handleConfirm(false)}
        >
          {declineBtnTxt}
        </Button>
      </Footer>
    </Modal>
  );
};

export default ConfirmModal;
