import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import startCase from 'lodash/startCase';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';
import { addFilterMatchHeader } from 'data/actions/search';
import { searchImportFilterPreprocess } from 'data/selectors/search';
import generateColumns from './generateColumns';
import { MatchedTableContainer } from './styles';

const MatchStep = ({ filterLabel }) => {
  const preprocessResult = useSelector(searchImportFilterPreprocess);
  const [matchedHeader, setMatchedHeader] = useState(preprocessResult?.matchedHeader);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addFilterMatchHeader({ matchedHeader }));
  }, [dispatch, matchedHeader]);

  const data = [
    { filterName: startCase(filterLabel), fileHeaders: preprocessResult?.headers ?? [], key: nanoid() },
  ];

  useEffect(() => {
    if (preprocessResult?.headers?.length === 1) {
      setMatchedHeader(preprocessResult.headers[0]);
    }
  }, []); // eslint-disable-line

  return (
    <>
      <MatchedTableContainer>
        <Table
          rowKey="key"
          columns={generateColumns({ fileHeaders: preprocessResult?.headers ?? [], matchedHeader, setMatchedHeader })}
          dataSource={data}
          pagination={false}
          scroll={{ x: 'max-content' }}
          style={{ width: '100%' }}
        />
      </MatchedTableContainer>
    </>
  );
};

export default MatchStep;
