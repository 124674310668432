import React from 'react';
import logo from 'assets/images/logo-new.svg';

export default () => (
  <img
    src={logo}
    alt="Logo"
    style={{
      display: 'block',
      margin: '10px auto 20px auto',
      width: '57px',
    }}
  />
);
