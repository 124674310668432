import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';

import { toggleModal } from 'data/actions/modals';
import { deleteStepTemplate } from 'data/actions/sequences';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';

import {
  ModalTitle,
  Label,
  CloseIcon,
  Footer,
} from '../baseModal/styles';

const DeleteStepTemplateModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const {
    templateName,
    templateId,
    backModal,
  } = useSelector(modalOptionsSelector);

  const handleClose = () => {
    if (backModal) {
      dispatch(toggleModal(backModal, true));
    } else {
      dispatch(toggleModal('delete_step_template', false));
    }
  };
  const handleDelete = () => {
    dispatch(deleteStepTemplate({ templateId }));
    handleClose();
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Delete the template
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={450}
      footer={false}
      onCancel={handleClose}
      closeIcon={<div />}
    >
      <>
        <Label>
          {`Delete the template ${templateName}?`}
        </Label>
      </>
      <Footer>
        <Button
          data-form="true"
          type="danger"
          size="large"
          onClick={handleDelete}
        >
          Delete
        </Button>
        <Button
          size="large"
          type="default"
          onClick={handleClose}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default DeleteStepTemplateModal;
