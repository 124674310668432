import moment from 'moment';
import lowerCase from 'lodash/lowerCase';
import capitalize from 'lodash/capitalize';
import { Tooltip, DatePicker } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { isWidePC } from 'helpers/isPC';
import isTablet from 'helpers/isTablet';
import isMobile from 'helpers/isMobile';
import { COLORS } from 'constants/styles';
import { addSeparators } from 'helpers/numbers';
import { isNumber } from 'helpers/regularExpressions';
import {
  Option, Icon, Input, Select, TextArea,
} from 'components/common';
import { getValueOfInput, stopPropagation } from 'helpers/dom';
import ClickAwayListener from 'components/common/clickAwayListener';
import {
  PropertyContainer, Label, Value, ShowMore, TreeDots, ArrowContainer,
} from './styles';
import CopyProperty from './copyProperty';
import DislikeProperty from './dislikeProperty';
import EditProperty from './editProperty';

const Property = ({
  name,
  icon: IconProp,
  copy,
  label,
  error,
  bounded,
  options,
  copyText,
  readOnly,
  fieldType,
  inaccurate,
  fontWeight,
  dislikeText,
  placeholder,
  renderValue,
  defaultValue,
  clickProperty,
  ignoreAdaptive,
  updateProperty,
  renderEditForm,
  dislikeProperty,
  toggleVisibleForm,
  hasTabletDirection,
  renderEditFormLabel,
}) => {
  const MAX_VALUE_LENGTH = isWidePC() ? 50 : 90;

  const [copied, setCopied] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [editable, setEditable] = useState(false);
  const [visibleCopy, toggleVisibleCopy] = useState(false);
  const [visibleShowMore, setVisibleShowMore] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const valueRef = useRef();

  const handleUpdateProperty = () => {
    setEditable(false);
    setShowMore(false);
    if (value !== defaultValue) {
      updateProperty({ property: name, value });
    }
  };

  const handleUpdateSelectProperty = (_value) => {
    setValue(_value);
    updateProperty({ property: name, value: _value });
    setImmediate(() => {
      setEditable(false);
    });
  };

  const getContainerInputStyles = () => {
    if (!ignoreAdaptive && isTablet() && !isMobile()) {
      return {};
    }
    return {
      marginLeft: -12,
      marginTop: ['textarea'].includes(fieldType) ? 0 : -0.5,
    };
  };

  const renderTextValue = () => {
    if (!value && placeholder) {
      return placeholder;
    }
    if (fieldType === 'select' && options) {
      return options.find((option) => option.value === value)?.label;
    }
    if (fieldType === 'checkbox' && Array.isArray(value)) {
      return value.join(', ');
    }
    if (fieldType === 'date' && value) {
      return moment(value).format('MMMM DD, YYYY h:mm A');
    }
    if (fieldType === 'number' && name !== 'foundedAt') {
      return addSeparators(value);
    }
    if (fieldType === 'password') {
      return value.replace(/./g, '*');
    }
    if (renderValue) {
      return renderValue(value);
    }
    if (updateProperty) {
      if (!showMore && visibleShowMore) {
        return value.slice(0, MAX_VALUE_LENGTH);
      }
      return value;
    }
    return defaultValue;
  };

  const renderInput = () => {
    switch (fieldType) {
      case 'date': {
        return (
          <DatePicker
            open
            style={{ width: '100%' }}
            value={value ? moment(value).utc() : undefined}
            onChange={(date) => {
              handleUpdateSelectProperty(date ? date.utc().toISOString() : date);
            }}
          />
        );
      }
      case 'textarea': {
        return (
          <TextArea
            autoFocus
            autoSize={{
              minRows: 1,
            }}
            style={{ width: '100%', resize: 'none' }}
            value={value}
            onChange={getValueOfInput(setValue)}
            onPressEnter={handleUpdateProperty}
          />
        );
      }
      case 'booleancheckbox': {
        return (
          <Select
            open
            size="default"
            value={value}
            style={{ width: '100%' }}
            onChange={handleUpdateSelectProperty}
          >
            <Option value="yes">Yes</Option>
            <Option value="no">No</Option>
          </Select>
        );
      }
      case 'checkbox':
      case 'select': {
        return (
          <Select
            open
            mode={fieldType === 'checkbox' ? 'multiple' : ''}
            size="default"
            style={{ width: '100%' }}
            value={value}
            onChange={handleUpdateSelectProperty}
          >
            { (options || []).map((option) => <Option value={option.value} key={option._id ?? option.value}>{option.label}</Option>) }
          </Select>
        );
      }
      default: {
        return (
          <Input
            style={{ width: '100%' }}
            autoFocus
            size="default"
            value={value}
            onChange={getValueOfInput((_value) => {
              if (fieldType === 'number') {
                if (isNumber(_value) || !_value) {
                  setValue(Number(_value));
                }
                return;
              }
              setValue(_value);
            })}
            onPressEnter={handleUpdateProperty}
          />
        );
      }
    }
  };

  const isShowCopy = () => {
    if (copy === 'icon' && value) {
      if (fieldType !== 'textarea') {
        return true;
      }
      if (showMore && value?.length >= MAX_VALUE_LENGTH) {
        return false;
      }
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (fieldType === 'textarea' && valueRef.current?.scrollHeight > (valueRef.current?.clientHeight + 5)) {
      setVisibleShowMore(true);
    } else {
      setVisibleShowMore(false);
    }
  }, [fieldType, editable]);

  useEffect(() => {
    if (bounded) {
      setValue(defaultValue);
    }
  }, [bounded, defaultValue]);

  return (
    <PropertyContainer
      hasHover={!readOnly}
      hasValueHoverColor={copy === 'text'}
      hasTabletDirection={hasTabletDirection}
      onClick={() => {
        if (clickProperty) {
          clickProperty({ toggleVisibleForm });
          return;
        }
        if (copy === 'text') {
          setCopied(true);
          navigator.clipboard.writeText(copyText ?? value);
          return;
        }
        if (!readOnly) {
          setEditable(true);
        }
      }}
    >
      {
        IconProp ? (
          <>
            {
              typeof IconProp === 'string' ? (
                <Icon style={{ marginRight: 10, paddingTop: 7 }} fill={COLORS.GREY_G2} size="smallest" type={IconProp} />
              ) : IconProp
            }
          </>
        ) : (
          <Label error={error} title={label || capitalize(lowerCase(name))}>
            {label || capitalize(lowerCase(name))}
          </Label>
        )
      }
      {
        editable ? (
          <ClickAwayListener
            style={{ width: '100%', ...getContainerInputStyles() }}
            onClickAway={handleUpdateProperty}
          >
            {renderInput()}
          </ClickAwayListener>
        ) : (
          <Value
            primary={clickProperty ? 'true' : ''}
            ref={valueRef}
            showMore={showMore}
            fieldType={fieldType}
            readOnly={readOnly}
            error={error}
            gray={readOnly || (!value && placeholder)}
            fontWeight={fontWeight}
          >
            <Tooltip
              visible={visibleCopy}
              onVisibleChange={(flag) => {
                toggleVisibleCopy(flag);
                if (!flag) setCopied(false);
              }}
              placement="bottom"
              title={!clickProperty && copy === 'text' ? `${copied ? 'Copied' : 'Copy'}` : ''}
            >

              <span className={`property--text ${copied ? 'animated' : ''}`} style={{ display: 'block', width: '100%' }}>
                {renderTextValue()}
                {
                  visibleShowMore && (
                    <>
                      {
                        !showMore && (
                          <TreeDots>...</TreeDots>
                        )
                      }
                      &nbsp;
                      <ShowMore
                        onClick={stopPropagation(() => {
                          setShowMore(!showMore);
                        })}
                      >
                        <span>{`Show ${showMore ? 'less' : 'more'}`}</span>
                      </ShowMore>
                    </>
                  )
                }
              </span>
            </Tooltip>
            {
              !editable && fieldType === 'select' && (
                <ArrowContainer>
                  <Icon
                    type="ic-keyboard-arrow-down"
                  />
                </ArrowContainer>
              )
            }
            {
              dislikeProperty && !clickProperty && (
                <DislikeProperty
                  defaultValue={dislikeText || defaultValue}
                  inaccurate={inaccurate}
                  dislikeProperty={dislikeProperty}
                  style={{ marginTop: 3, marginRight: renderEditForm ? 30 : 0 }}
                />
              )
            }
            {
              isShowCopy() && (
                <CopyProperty value={value} />
              )
            }
            {
              renderEditForm && (
                <EditProperty style={{ marginTop: 3 }} renderEditForm={renderEditForm} label={renderEditFormLabel} />
              )
            }
          </Value>
        )
      }
    </PropertyContainer>
  );
};

export default Property;
