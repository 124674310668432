import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const SignatureContainer = styled.div`
  height: 120px;
  width: 100%;
  color: ${COLORS.GREY_G2};
  font-weight: 400;
  font-size: 14px;
`;

export const Container = styled.div`
  display: flex;
  width: calc(335px + 18px + 50px);
  justify-content: space-between;
  cursor: pointer;

  .signature-icon .material-icons {
    display: none;
    margin-top: 5px;
  }

  &[disabled] {
    pointer-events: none;
      
    ${SignatureContainer} {
      color: ${COLORS.GREY_G1};
    }
  }

  &:hover {
    background-color: ${COLORS.GREY_G5};

    .signature-icon {
      display: block;
    }
  }
`;
