export const ENRICHMENT_SOURCE = Object.freeze({
  CONTACT: 'contact',
  COMPANY: 'company',
});

export const ENRICHMENT_TYPE = Object.freeze({
  EMAIL: 'email',
  INSIGHTS: 'insights',
  EMAIL_VERIFICATION: 'emailVerification',
  PHONE_SEARCH: 'phoneSearch',
});
