import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';

import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { pushConnectedAccount } from 'data/actions/integrations';
import { updateSequenceAccount, updateSequenceSettingsRemotely } from 'data/actions/sequences';

const useGoogleOauth = ({
  sequenceId,
} = {}) => {
  const dispatch = useDispatch();

  const connectGmailAccount = useCallback(() => {
    const buildOauthRequestUrl = () => {
      const googleApClientId = process.env.REACT_APP_GMAIL_CLIENT_ID;
      const baseUrl = 'https://accounts.google.com/o/oauth2/auth';
      const redirectUri = `${process.env.REACT_APP_WEBSITE_APP_URL}/oauth/google/verify`;
      const params = new URLSearchParams({
        redirect_uri: redirectUri,
        prompt: 'consent',
        response_type: 'code',
        client_id: googleApClientId,
        scope: 'email https://www.googleapis.com/auth/userinfo.profile  https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send',
        include_granted_scopes: true,
        state: 'connect-gmail',
        access_type: 'offline',
      });
      return `${baseUrl}?${params}`;
    };
    const url = buildOauthRequestUrl();
    window.open(url, '_blank', 'top=138,left=249,width=600,height=560');
  }, []);

  const connectGoogleSheets = useCallback(() => {
    const buildOauthRequestUrl = () => {
      const clientId = process.env.REACT_APP_GOOGLE_SHEETS_CLIENT_ID;
      const baseUrl = 'https://accounts.google.com/o/oauth2/auth';
      const redirectUri = `${process.env.REACT_APP_WEBSITE_APP_URL}/oauth/sheets/verify`;
      const params = new URLSearchParams({
        access_type: 'offline',
        ux_mode: 'redirect',
        client_id: clientId,
        redirect_uri: redirectUri,
        response_type: 'code',
        scope: 'email profile https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive.file',
        include_granted_scopes: false,
        state: 'connect-google-sheets',
      });
      return `${baseUrl}?${params}`;
    };
    const url = buildOauthRequestUrl();

    return url;
  }, []);

  useEffect(() => {
    const connectedAppsChannel = new BroadcastChannel('connected_apps_channel');
    connectedAppsChannel.onmessage = (event) => {
      if (event?.data?.status === 'error') dispatch(toggleMessage('error', { text: event?.data?.data }));
      else {
        dispatch(pushConnectedAccount(event?.data?.data));
        dispatch(toggleMessage('short', { text: 'Email account saved' }));
        const accountId = event?.data?.data?._id;
        if (event?.data?.data && sequenceId) {
          const { data: account } = event.data;
          dispatch(updateSequenceSettingsRemotely({
            sequenceId,
            data: { account: account._id },
            onSuccess: () => {
              dispatch(updateSequenceAccount({ sequenceId, account }));
            },
          }));
        }
        if (accountId) {
          dispatch(toggleModal('email_account_preview_bar', true, { accountId }));
        }
      }
    };

    return () => {
      connectedAppsChannel.close();
    };
  }, [dispatch, sequenceId]);

  return { connectGmailAccount, connectGoogleSheets };
};

export default useGoogleOauth;
