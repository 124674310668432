import styled from 'styled-components';
import { transparentize } from 'polished';

import {
  Button, Icon, Select, Radio, RadioGroup,
} from 'components/common';
import { PaymentIconContainer } from 'components/planAndBillingTabs/components/styles';
import * as CONSTANTS from '../../constants/styles';

export const PayerRadioGroup = styled(RadioGroup)`
  display: flex;

  label {
    display: flex;
    align-items: center;
  }

  label:first-child {
    margin-right: 20px;
  }
`;
export const PayerRadio = styled(Radio)``;

export const PayerWrapper = styled.div`
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid ${CONSTANTS.COLORS.GREY_G4};
`;

export const ContentRow = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 15px;
`;

export const InputPanel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-top: none;
  border-right: 1px solid ${CONSTANTS.COLORS.GREY_G4};
  border-left: 1px solid ${CONSTANTS.COLORS.GREY_G4};
  border-bottom: 1px solid ${CONSTANTS.COLORS.GREY_G4};
  color: ${CONSTANTS.COLORS.BLACK_G1};

  ${ContentRow} {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .ant-select-arrow {
    top: 40%;
  }

  .ant-input-affix-wrapper {
    max-height: 40px;
  }
  
  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    padding-top: 15px;
  }
`;

export const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${CONSTANTS.COLORS.GREY_G1};
  margin-bottom: 5px;
`;

export const ErrorText = styled.span`
  color: ${CONSTANTS.COLORS.RED};
  font-size: 13px;
  font-weight: 700;
`;

export const ContentCol = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  flex: ${(props) => (props.flex ? props.flex : 1)};

  &:last-child {
    margin-right: 0;
  }
`;

export const LabelItalic = styled(Label)`
  display: inline;
  font-style: italic;
  font-weight: 300;
  margin-bottom: 0;
  color: ${CONSTANTS.COLORS.GREY_G2};
`;

export const FormButton = styled(Button)`
  border-radius: 4px;
  box-shadow: none;
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${transparentize(0.15, CONSTANTS.COLORS.BLACK)};
  margin: 0;
`;

export const TextBold = styled(Text)`
  font-weight: 600;

  &:first-child {
    margin-bottom: 20px;
  }
  
  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    &:first-child {
      margin-bottom: 15px;
    }
  }
`;

export const TextBoldBlue = styled(TextBold)`
  color: ${(props) => (props.limited ? CONSTANTS.COLORS.BLACK_WITH_OPACITY_B2 : CONSTANTS.COLORS.PRIMARY)};
  margin-bottom: 0 !important;
  cursor: ${(props) => (props.limited ? 'not-allowed' : 'pointer')};
  width: fit-content;
`;

export const OrderPanel = styled(InputPanel)`
  background-color: ${CONSTANTS.COLORS.GREY_G5};
`;

export const TextRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BillingItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 2px;
  margin-bottom: 20px;
  cursor: pointer;
`;

export const BillingSubItem = styled.div`
  display: inline-flex;
  flex-direction: column;

  &:nth-child(2) {
    padding-left: 20px;
    flex: 1;
  }
`;

export const TitleBlack = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: ${CONSTANTS.COLORS.BLACK};
  margin-bottom: 0;
`;

export const SubTitle = styled.span`
  font-size: 14px;
  color: ${CONSTANTS.COLORS.GREY_G1};
  display: flex;
`;

export const ShowCards = styled(Icon).attrs(() => ({
  type: 'ic-arrow-drop-down',
}))`
  cursor: pointer;
`;

export const MenuItem = styled.button`
  cursor: pointer;
  color: ${transparentize(0.35, CONSTANTS.COLORS.BLACK)};
  display: block;
  padding: 12px;
  background: ${(props) => (props.active ? transparentize(0.35, CONSTANTS.COLORS.GREY_G5) : CONSTANTS.COLORS.WHITE)};
  line-height: 24px;
  text-align: left;
  border: none;
  outline: none;
  width: 430px;
  border-bottom: 1px solid #ddd;

  &:hover {
    background: ${CONSTANTS.COLORS.GREY_G5};
    &:first-child {
      border-radius: 4px 4px 0 0;
    }
    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }
`;

export const Menu = styled.div`
  position: relative;
  background-color: ${CONSTANTS.COLORS.WHITE};
  border: 1px solid ${CONSTANTS.COLORS.GREY_G6};
  padding-bottom: 0;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2) !important;
  max-height: 300px;
  overflow-y: scroll;
`;

export const PaymentSelect = styled(Select)`
  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    max-width: 100% !important;
  }
`;

export const PaymentCardDataContainer = styled(ContentRow)`
  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    ${ContentCol}:first-child {
      flex: 0 0 100%;
      margin: 0 0 15px 0 !important;
    }
    
    ${ContentCol} {
      flex: 1;
      margin: 0;
    }
    
    ${ContentCol}:last-child {
      margin-left: 15px;
    }
  }
`;

export const CountryCardDataContainer = styled(PaymentCardDataContainer)`
    ${ContentCol}:last-child {
      margin: 0;
    }
    
    ${ContentCol}:first-child {
      margin: 0 15px 0 0;
      width: 100%;
    }
`;

export const PaymentButtonContainer = styled.div`
  margin-top: 20px;

  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    
    ${FormButton} {
      flex: 1;
    }
    
    ${FormButton}:first-child {
      margin-right: 15px;
    }
  }
`;

export const FSPaymentContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;

  button:last-child {
    margin-top: 10px;
  }
`;

export const PayBtnWrapper = styled.span`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    span {
      :first-child {
        margin-right: 13px;
      }
      :last-child {
        font-size: 13px;
        font-weight: 600;
      }
    }
  }
`;
export const FastSpringWrapper = styled.div``;
export const BillingWrapper = styled.div`
  padding: 20px;
`;
export const BillingBtnWrapper = styled.div`
  border-top: 1px solid ${CONSTANTS.COLORS.GREY_G4};
  background-color: ${CONSTANTS.COLORS.GREY_G5};
  padding: 5px 25px 15px 25px;

  ${LabelItalic} {
    font-weight: 500;
  }

  strong {
    font-weight: 700;
  }

  button {
    margin-top: 0;
  }
`;
export const PaymentCardWrapper = styled.div``;
export const BillingHeader = styled.h2`
  margin: 0;
  font-size: 16px;
  color: #4F5964;
  font-weight: 600;
`;
export const PaymentIconWrapper = styled(PaymentIconContainer)``;
export const PaymentIcon = styled.img`
  width: 100%;
`;
export const PaymentDescriptionGrey = styled.span`
  font-size: 14px;
  color: ${CONSTANTS.COLORS.GREY_G1};
  font-weight: 500;
`;

export const PaymentDescriptionGreyLite = styled(PaymentDescriptionGrey)`
  color: ${CONSTANTS.COLORS.GREY_G2};
  font-weight: 500;
`;

export const PaymentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;

  ${PaymentDescriptionGrey} {
    margin-right: 10px;
  }

  ${PaymentDescriptionGreyLite} {
    margin: 0;
  }

  ${PaymentIconWrapper} {
    margin-right: 10px;
  }
`;

export const BillingSubHeader = styled(BillingHeader)`
  font-size: 14px;
`;

export const PeriodWrapper = styled.div`
  ${BillingHeader} {
    margin-bottom: 10px;
  }
`;

export const PlanDescription = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const PlanDetails = styled.div`
  max-width: 184px;
  margin-right: 30px;

  > * {
    display: block;
    width: 100%;
  }

  ${BillingSubHeader} {
    margin-bottom: 5px;
  }
`;

export const SwitchHeader = styled.span`
  font-size: 12px;
  color: ${CONSTANTS.COLORS.GREY_G1};
  font-weight: 600;
`;
export const SwitchDiscount = styled.span`
  font-size: 11px;
  font-weight: 500;
  color: ${CONSTANTS.COLORS.RED};
`;
export const SwitchDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${SwitchDiscount} {
    line-height: 12px;
  }

  ${SwitchHeader} {
    line-height: 14px;
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  width: 185px;
  height: 40px;
  background-color: ${(props) => (props.isActive ? CONSTANTS.COLORS.PRIMARY_P1 : CONSTANTS.COLORS.GREY_G5)};
  border: 1px solid ${(props) => (props.isActive ? CONSTANTS.COLORS.PRIMARY : CONSTANTS.COLORS.GREY_G5)};
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 5px;
  transition: all 0.2s;

  :hover {
    background-color: ${CONSTANTS.COLORS.PRIMARY_P1};
    border: 1px solid ${CONSTANTS.COLORS.PRIMARY};
  }

  :last-child {
    margin-bottom: 0;
  }

  .ant-radio-wrapper {
    margin: 0;
  }
`;

export const PlanCycleSwitcher = styled.div`
  display: flex;
`;
export const SummaryTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;

  :first-child {
    font-weight: 600;
  }

  :last-child {
    font-weight: 700;
  }

  ${(props) => (props.showBorder ? `
    border-bottom: 1px solid #EFF0F2;
    padding-bottom: 15px;
  ` : '')}
`;

export const SummaryRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
`;

export const SummaryWrapper = styled.div`
  margin-top: 20px;

  ${SummaryRow} {
    margin-bottom: 5px;
  }
`;
export const SummaryRowItem = styled.div`
  font-size: 14px;
  color: ${(props) => (props.lite ? CONSTANTS.COLORS.GREY_G2 : CONSTANTS.COLORS.GREY_G1)};

  .ant-skeleton-title {
    margin: 0;
    width: 90px;
  }
`;
export const QuantityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${CONSTANTS.COLORS.GREY_G4};
  border-radius: 4px;
  width: 75px;
  height: 28px;

  .material-icons {
    cursor: pointer;
    user-select: none;
  }
`;
export const QuantityController = styled.div`
  color: ${CONSTANTS.COLORS.GREY_G4};
`;

export const QuantityCount = styled.span`
  font-weight: 600;
  color: ${CONSTANTS.COLORS.BLACK_G1};
  font-size: 12px;
  margin: 0 2px;
  width: 15px;
  text-align: center;
`;

export const DropDownLabel = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #1890FF;
  cursor: pointer;

  span {
    font-size: 18px !important;
  }
`;
