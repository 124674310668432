import { Popover } from 'antd';
import React, { useState } from 'react';

import { Icon } from 'components/common';
import { stopPropagation } from 'helpers/dom';
import { IconContainer } from './styles';

const EditProperty = ({ renderEditForm, style, label }) => {
  const [visibleForm, toggleVisibleForm] = useState(false);

  return (
    <Popover
      visible={visibleForm}
      onVisibleChange={toggleVisibleForm}
      placement="rightTop"
      content={renderEditForm({ visibleForm, toggleVisibleForm })}
      trigger="click"
      overlayClassName="list_popover hide-popover-arrow"
      align={{
        offset: [10, -10],
      }}
    >
      {label || (
        <IconContainer visible={visibleForm} onClick={stopPropagation()} style={style}>
          <Icon outlined fill="primary" size="smallest" type="ic-edit" />
        </IconContainer>
      )}
    </Popover>
  );
};

export default EditProperty;
