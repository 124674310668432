import React from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

import { Button } from 'components/common';
import { subscriptionsSelector } from 'data/selectors/subscriptions';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../../../../modals/baseModal/styles';

const Discount = ({
  percent,
  discountCountMonths,
  onNext,
  onClose,
}) => {
  const { plan: { planName } } = useSelector(subscriptionsSelector);

  return (
    <Modal
      visible
      title={(
        <ModalTitle>
          Continue using GetProspect
          <CloseIcon
            onClick={onClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={onClose}
    >
      <div>
        <p>
          We would like to make a special offer: your current
          {' '}
          <strong>{planName}</strong>
          {' '}
          plan with a
          {' '}
          <strong>
            {percent}
            %
          </strong>
          {' '}
          discount
          <strong>
            {
              discountCountMonths ? ` for ${discountCountMonths} months.` : ''
            }
          </strong>
        </p>
        <Footer>
          <Button
            data-form="true"
            type="normal"
            onClick={() => onNext({})}
          >
            Cancel anyway
          </Button>
          <Button
            data-form="true"
            type="primary"
            onClick={() => onNext({ discount: percent, discountCountMonths })}
          >
            Get my
            {' '}
            {percent}
            %
            {' '}
            discount
          </Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default Discount;
