import * as CONSTANTS from 'constants/styles';

import VisaIcon from 'assets/images/visa.png';
import MasterCardIcon from 'assets/images/mastercard.png';

export const subscriptionStatusColors = Object.freeze({
  active: CONSTANTS.COLORS.GREEN_G5,
  disabled: CONSTANTS.COLORS.RED,
  pause: CONSTANTS.COLORS.YELLOW,
});
// ['created', 'processing', 'declined', 'approved', 'expired', 'reversed']
export const invoiceStatusData = Object.freeze({
  approved: {
    label: 'Paid',
    color: CONSTANTS.COLORS.GREEN_G3,
    backgroundColor: CONSTANTS.COLORS.GREEN_G6,
  },
  reversed: {
    label: 'Reversed',
    color: CONSTANTS.COLORS.YELLOW_Y3,
    backgroundColor: CONSTANTS.COLORS.YELLOW_Y2,
  },
  expired: {
    label: 'Pending',
    color: CONSTANTS.COLORS.YELLOW_Y3,
    backgroundColor: CONSTANTS.COLORS.YELLOW_Y2,
  },
  created: {
    label: 'Pending',
    color: CONSTANTS.COLORS.YELLOW_Y3,
    backgroundColor: CONSTANTS.COLORS.YELLOW_Y2,
  },
  processing: {
    label: 'Pending',
    color: CONSTANTS.COLORS.YELLOW_Y3,
    backgroundColor: CONSTANTS.COLORS.YELLOW_Y2,
  },
  declined: {
    label: 'Declined',
    color: CONSTANTS.COLORS.WHITE,
    backgroundColor: CONSTANTS.COLORS.RED_D3,
  },
  unpaid: {
    label: 'Pending',
    color: CONSTANTS.COLORS.YELLOW_Y3,
    backgroundColor: CONSTANTS.COLORS.YELLOW_Y2,
  },
});

export const cardIconStyles = {
  visa: {
    maxWidth: 21.85,
    height: 6.89,
  },
  mastercard: {
    maxWidth: 18,
    height: 10,
  },
};

export const planDescriptionMap = [
  { key: 'maxDiscoveredEmails', label: 'valid emails' },
  { key: 'emailVerifications', label: 'verifications' },
  // { key: 'activeLeads', label: 'active leads' },
];

export const planDescriptionMapSecondRow = [
  // { key: 'emailsMonthly', label: 'emails monthly' },
];

export const cardIcon = {
  visa: VisaIcon,
  mastercard: MasterCardIcon,
};

export const REASON_TYPE = Object.freeze({
  FINISHED_WORK: 'finishedWork',
  ANOTHER_TOOL: 'anotherTool',
  POOR_EMAILS_ACCURACY: 'poorEmailsAccuracy',
  POOR_EMAILS_DISCOVERY_RATE: 'poorEmailsDiscoveryRate',
  MISSING_FEATURES: 'missingFeatures',
  EXPENSIVE_SUBSCRIPTION: 'expensiveSubscription',
  ANOTHER_TEAM: 'anotherTeam',
  OTHER: 'other',
});

export const SUBSCRIPTION_STATUS = Object.freeze({
  ACTIVE: 'active',
  DISABLED: 'disabled',
  PAUSE: 'pause',
});

export const SHARED_INFORMATION = Object.freeze({
  BOOKING_DEMO: 'bookingDemo',
  CHAT: 'chat',
});

export const PLAN_IDS = Object.freeze({
  LIMITED: 20,
  FREE: 1,
  STARTER: 31,
  BASIC: 32,
  PLUS: 33,
  PRO: 34,
});

export const PLANS_WITH_DISCOUNT = Object.freeze({
  STARTER: PLAN_IDS.STARTER,
  BASIC: PLAN_IDS.BASIC,
  PLUS: PLAN_IDS.PLUS,
  PRO: PLAN_IDS.PRO,
});

export const PLANS_WITH_DISCOUNT_ARRAY = Object.values(PLANS_WITH_DISCOUNT);

export const EXPENSIVE_PLANS = Object.freeze({
  PLUS: PLAN_IDS.PLUS,
  PRO: PLAN_IDS.PRO,
});

export const EXPENSIVE_PLANS_ARRAY = Object.values(EXPENSIVE_PLANS);
