export const exportData = (data, onSuccess = () => {}) => (dispatch) => {
  dispatch({
    type: 'EXPORT_DATA',
    payload: {
      endpoint: 'api/v1/export',
      method: 'POST',
      body: data,
      delayLoad: 0,
      showResponseError: true,
    },
    components: ['exportDataLoader'],
    onSuccess,
  });
};

export const exportSimpleData = (data, onSuccess = () => {}) => (dispatch) => {
  dispatch({
    type: 'EXPORT_SIMPLE_DATA',
    payload: {
      endpoint: 'api/v1/simple-export',
      method: 'POST',
      body: data,
      delayLoad: 0,
      showResponseError: true,
    },
    components: ['exportDataLoader'],
    onSuccess,
  });
};

export const downloadExportFile = ({ _id, url, name }) => (dispatch) => {
  dispatch({
    type: 'DOWNLOAD_EXPORT_FILE',
    data: { _id, url, name },
  });
};
