import styled from 'styled-components';

import { TABLET_MAX_WIDTH, SHADOWS } from 'constants/styles';
import { Footer as BaseFooter } from '../baseModal/styles';

export const Container = styled.div`
  ${(props) => !props.noShadow && `
    @media (max-width: ${TABLET_MAX_WIDTH}px) {
      box-shadow: ${SHADOWS.BLACK_B3};
    }
  `}
`;

export const Footer = styled(BaseFooter)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
