const ageOption = {
  name: 'Age',
  data: [
    {
      label: 'Under 18',
      value: 'Under 18',
    },
    {
      label: '18-24',
      value: '18-24',
    },
    {
      label: '25-34',
      value: '25-34',
    },
    {
      label: '35-44',
      value: '35-44',
    },
    {
      label: '45-54',
      value: '45-54',
    },
    {
      label: '55-64',
      value: '55-64',
    },
    {
      label: '65 or above',
      value: '65 or above',
    },
    {
      label: 'I prefer not to say',
      value: 'I prefer not to say',
    },
  ],
};

export default ageOption;
