import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { recentActivitySelector } from 'data/selectors/user';
import { createOrUpdateUserRecentActivity } from 'data/actions/user';

const useRecentActivity = () => {
  const dispatch = useDispatch();
  const recentActivity = useSelector(recentActivitySelector);

  useEffect(() => {
    dispatch(createOrUpdateUserRecentActivity(recentActivity));
  }, [recentActivity, dispatch]);
};

export default useRecentActivity;
