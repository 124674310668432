import { Tooltip } from 'antd';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import Editor from 'components/editor';
import useSubscribeActions from 'hooks/useSubscribeActions';
import { HeaderWrapper } from 'components/sequenceInfo/tabs/steps/stepEditor/styles';
import {
  EditorContainer,
  PanelContainer,
  PanelHeader,
  PanelInput,
} from './styles';
import Attachments from './attachments';

const Step = ({
  id,
  version,
  stepId,
  templateName,
  templateId,
  subject,
  content,
  disabled,
  style,
  error,
  setEditor,
  index,
  attachments,
  height,
  bordered = true,
  hideSubjectLabel,
  processing,
  previousModal,
  customSubjectPlaceholder,
  onChange = () => {},
  showPauseWarningIfNeeded = () => () => {},
}) => {
  const editorRef = useRef();

  const [loading, setLoading] = useState(true);
  const [lastFocus, setLastFocus] = useState('subject');

  const getSubjectPlaceholder = () => {
    if (customSubjectPlaceholder) return customSubjectPlaceholder;
    return 'Leave the subject blank to follow up in the same thread';
  };

  useEffect(() => {
    if (!loading && setEditor) setEditor(editorRef.current);
  }, [loading, setEditor]);

  useSubscribeActions({
    actions: 'INSERT_TOKEN_INTO_EDITOR',
    callback: ({ data: { token } }) => {
      if (lastFocus === 'subject') {
        onChange({ subject: `${subject} {{${token}}}` });
      } else if (lastFocus === 'editor' && editorRef?.current) {
        editorRef.current.execCommand(
          'mceInsertContent',
          false,
          `{{${token}}}`,
        );
      }
    },
  }, [lastFocus, onChange]);

  useSubscribeActions({
    actions: 'INSERT_UNSUBSCRIBE_LINK_INTO_EDITOR',
    callback: () => {
      editorRef.current.execCommand(
        'mceInsertContent',
        false,
        '<a style="text-decoration:underline;" target="_blank" href="{{unsubscribe}}/unsubscribe/test" class="unsubscribe">Unsubscribe</a>',
      );
    },
  });

  return (
    <Tooltip visible={Boolean(error)} placement="top" title={error}>
      <EditorContainer bordered={bordered} error={error} style={style}>
        <PanelContainer onClick={showPauseWarningIfNeeded()}>
          {
            !hideSubjectLabel && (
              <HeaderWrapper style={{ marginRight: '5px' }}>
                <PanelHeader>Subject</PanelHeader>
              </HeaderWrapper>
            )
          }
          <PanelInput
            autoFocus
            id={`subject-${stepId || index}`}
            disabled={disabled || processing}
            placeholder={getSubjectPlaceholder()}
            value={subject}
            onChange={(e) => onChange({ content, subject: e.target.value })}
            onFocus={() => setLastFocus('subject')}
          />
        </PanelContainer>
        <Editor
          id={id}
          height={height}
          placeholder="Type here ..."
          onLoaded={() => setLoading(false)}
          ref={editorRef}
          disabled={disabled}
          processing={processing}
          content={content}
          onChange={(value) => {
            onChange({ content: value, subject });
          }}
          onFocus={() => setLastFocus('editor')}
          showPauseWarningIfNeeded={showPauseWarningIfNeeded}
          templateId={templateId}
        />
        <Attachments
          templateName={templateName}
          templateId={templateId}
          version={version}
          disabled={disabled || processing}
          stepId={stepId}
          attachments={attachments}
          previousModal={previousModal}
          content={content}
          subject={subject}
        />
      </EditorContainer>
    </Tooltip>
  );
};

export default Step;
