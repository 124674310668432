import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as CONSTANTS from 'constants/styles';

import { NameContainer } from '../baseTable/styles';
import Icon from '../../common/icon';

export const Name = styled(NameContainer)`
  justify-content: space-between;
  align-items: center;
`;

export const NameItem = styled.a``;

export const IconCaretDown = {
  color: '#7c98b6',
  marginLeft: '4px',
  fontSize: '10px',
};

export const BreadcrumbsItem = styled(Link)`
  display: flex;
  align-items: center;
`;

export const BreadcrumbsChevron = styled(Icon)``;
export const ImageChevron = styled.img``;

export const BreadcrumbsButtonItem = styled.button`
  display: contents;
  font-size: 14px;
  background-color: transparent;
  border: none;
  outline: none;
  position: relative;
  padding: 0;
  color: ${(props) => (props.currentItem ? CONSTANTS.COLORS.BLACK_G1 : CONSTANTS.COLORS.GREY_G2)};
  cursor: ${(props) => (props.currentItem ? 'not-allowed' : 'pointer')};

  &:not(:last-child) {
    &:after {
      content: " / ";
      font-size: 14px;
    }
  }
  
  ${(props) => !props.currentItem && `
    :hover {
      color: ${CONSTANTS.COLORS.PRIMARY};
      
      &:after {
        color: ${CONSTANTS.COLORS.GREY_G2};
      }
    }
  `}
`;

export const BreadcrumbsContainer = styled.div`
  padding: 10px;
  flex-direction: row;
  align-items: center;
  margin-left: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  direction: rtl;
  padding-left: 0;
`;

export const FolderIcon = styled.img`
  width: 20px;
  height: 16px;
  margin-right: 2px;
  vertical-align: text-bottom;
`;

export const LinkStyles = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
};
