import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { Upload, Icon } from 'antd';
import { DraggerContainer, UploadText } from 'components/uploadFile/styles';
import { UploadFileLoading, UploadFileResult, UploadFileBar } from 'components/uploadFile/uploadSubComponents';
import { searchImportFilterPreprocess } from 'data/selectors/search';
import { importFilePreSigned } from 'data/actions/import';
import { IMPORT_DISPATCH_TYPE } from 'data/types/import.types';
import { clearSearchImportPreprocess } from 'data/actions/search';
import { ProcessedFileContainer, ImportSearchCloseIcon } from './styles';

const { Dragger } = Upload;

const labelMapping = {
  domain: 'domains',
  'company name': 'company names',
};

const UploadStep = ({ property, filterLabel }) => {
  const [fileName, setFileName] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const preprocessResult = useSelector(searchImportFilterPreprocess);

  const onChange = useCallback((info) => {
    setFileName(get(info, 'file.name'));
  }, []);

  const uploadRequestHandler = useCallback(({ file }) => {
    const data = {
      file,
      dispatchType: IMPORT_DISPATCH_TYPE.IMPORT_FILE_SEARCH_FILTER_PREPROCESS,
      filterProperty: property,
    };
    dispatch(importFilePreSigned({
      file: data,
      onError: () => setLoading(false),
      onSuccess: () => setLoading(false),
    }));
    setLoading(true);
  }, [dispatch, property]);

  const closeHandler = useCallback(() => {
    dispatch(clearSearchImportPreprocess());
  }, [dispatch]);

  const renderUploadState = () => {
    if (loading) return <UploadFileLoading fileName={fileName} />;
    if (preprocessResult) {
      return (
        <ProcessedFileContainer>
          <UploadFileResult fileName={get(preprocessResult, 'fileName')} />
          <Icon type="close" style={ImportSearchCloseIcon} onClick={closeHandler} />
        </ProcessedFileContainer>
      );
    }
    return (
      <UploadFileBar renderData={() => (
        <>
          <UploadText>
            Drag and drop or click
          </UploadText>
          <UploadText>
            to choose a CSV file for upload.
          </UploadText>
          <UploadText>
            {`(50,000 ${labelMapping[filterLabel]} max.)`}
          </UploadText>
        </>
      )}
      />
    );
  };

  return (
    <>
      <DraggerContainer loaded={!!preprocessResult}>
        <Dragger
          name="file"
          accept="text/csv"
          customRequest={uploadRequestHandler}
          onChange={onChange}
          multiple={false}
          showUploadList={false}
          disabled={loading || !!preprocessResult}
        >
          { renderUploadState() }
        </Dragger>
      </DraggerContainer>
    </>
  );
};

export default UploadStep;
