export const Progress = Object.freeze({
  IN_PROGRESS: 'in_progress',
  FAILED: 'failed',
  DONE: 'done',
  STOPPED: 'stopped',
});

export const ASYNC_BOOLEAN = Object.freeze({
  TRUE: true,
  FALSE: false,
  LOADING: 'loading',
  ERROR: 'error',
});
