import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const AddFilter = styled.button`
  background: none;
  border: none;  
  cursor: pointer;
  display: flex;
  padding: 0;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.25px;
  color: ${COLORS.PRIMARY};

  img {
    width: 22px;
  }
    
  span {
    line-height: 1;
  }

  &:hover {
    text-decoration: underline;

    ${(props) => (props.hasGrayHover && `
      background: ${COLORS.GREY_G5};
      border-radius: 2px;
    `)}
    
    ${(props) => (props.hasGrayHover && `
      background: ${COLORS.GREY_G5};
      border-radius: 2px;
    `)}

    span:last-child {
      text-decoration: underline;
    }
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      text-decoration: initial;
    }
  }

  &:focus {
    outline: 0;
  }
`;
