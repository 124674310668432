import moment from 'moment';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import isEqual from 'react-fast-compare';
import includes from 'lodash/includes';

import { SOURCE_TYPE } from 'data/types/source.types';
import { PageSizes } from 'data/types/tableMeta.types';
import { ACCOUNT_EVENTS } from 'data/types/event.types';
import * as SearchFiltersTypes from 'data/types/search.filters.types';
import { createOnlyIncludedFilter } from 'helpers/filterFactory';
import { domainPrintedDataMap } from 'helpers/domainPrintedDataMap';
import { FILTER_SPLIT_LIMIT, SPLIT_TOKENS_REGEXP, multipleValProps } from 'data/types/search.filters.types';
import { INSIGHTS_TYPE } from 'data/types/insights.types';
import { Progress } from 'data/types/progress.types';
import { uniqBy } from 'lodash';
import { validateSearchFilters } from 'helpers/validateSearchFilters';

const initialState = {
  searchOptions: {
    data: [],
    totalItems: 0,
    pageSize: PageSizes.DEFAULT_SEARCHES_PAGE_SIZE,
    page: 1,
    sort: {},
  },
  searches: {
    data: [],
    totalItems: 0,
    pageSize: PageSizes.DEFAULT_SEARCHES_PAGE_SIZE,
    page: 1,
    sort: {},
    initialRequest: true,
  },
  dashboardSearches: {
    data: [],
    pageSize: 4,
    page: 1,
    totalItems: 0,
    initialRequest: true,
  },
  dashboardSearchesHistory: {
    data: [],
    pageSize: 4,
    page: 1,
    totalItems: 0,
    initialRequest: true,
  },
  searchesHistory: {
    data: [],
    pageSize: PageSizes.DEFAULT_SEARCHES_PAGE_HISTORY,
    sort: {
      column: '',
      order: '',
    },
    page: 1,
    totalItems: 0,
    initialRequest: true,
  },
  shortSearches: {
    data: [],
    totalItems: 0,
    pageSize: PageSizes.DEFAULT_SEARCHES_PAGE_SIZE,
    page: 1,
    sort: {},
    initialRequest: true,
  },
  shortSearchesHistory: {
    data: [],
    pageSize: PageSizes.DEFAULT_SEARCHES_PAGE_HISTORY,
    sort: {
      column: '',
      order: '',
    },
    page: 1,
    totalItems: 0,
    initialRequest: true,
  },
  sourceType: SOURCE_TYPE.CONTACT,
  selectedContactTab: INSIGHTS_TYPE.NEW_INSIGHTS,
  selectedCompanyTab: INSIGHTS_TYPE.INSIGHTS,
  locations: [],
  positions: [],
  industries: [],
  filters: validateSearchFilters([], false),
  countries: {
    data: [],
    totalItems: 0,
    totalPages: 0,
    page: 1,
    pageSize: PageSizes.DEFAULT_COUNTRIES_PAGE_SIZE,
  },
  userCountry: {},
  filterOptions: {},
  filterWithDefaultOptions: {},
  autocompleteFilterOptions: {},
  searchImport: null,
  totalFilters: 0,
  initRecentActivities: false,
  lastUserSearchId: null,
  validOnly: false,
  limits: {
    companies: { availableCompanies: 0, limitCompanies: 0 },
  },
  fastSearchData: {},
  searchError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SEARCH_ERROR': {
      return {
        ...state,
        searchError: action.data.error,
      };
    }
    case 'GET_COMPANIES_LIMIT': {
      return {
        ...state,
        limits: {
          ...state.limits,
          companies: action.payload,
        },
      };
    }
    case 'SAVE_SEARCH': {
      const data = action.payload;

      if (data) {
        return {
          ...state,
          searches: {
            ...state.searches,
            data: [{ ...data }, ...state.searches.data],
            totalItems: state.searches.totalItems + 1,
          },
          // shortSearches: {
          //   ...state.shortSearches,
          //   data: [{ ...data }, ...state.shortSearches.data],
          //   totalItems: state.shortSearches.totalItems + 1,
          // },
          // shortSearchesHistory: {
          //   ...state.shortSearchesHistory,
          //   data: [...state.shortSearchesHistory.data.filter((searchHistory) => !(data?.searchId === searchHistory?._id))],
          //   totalItems: state.shortSearchesHistory.totalItems + 1,
          // },
          lastUserSearchId: data._id,
          dashboardSearches: {
            ...state.searches,
            data: [{ ...data }, ...state.dashboardSearches.data],
            totalItems: state.dashboardSearches.totalItems + 1,
          },
        };
      }

      return {
        ...state,
      };
    }
    case 'UPDATE_DEFAULT_SEARCH_PROPERTIES': {
      const { data } = action;
      const updatedOptions = { ...state.filterOptions };
      const updatedFilterWithDefaultOptions = { ...state.filterWithDefaultOptions };

      Object.keys(data).forEach((property) => {
        const defaultData = data[property] || [];
        const existsPropertyData = updatedOptions?.[property];

        if (existsPropertyData?.length) return false;
        updatedOptions[property] = [...defaultData];
        updatedFilterWithDefaultOptions[property] = Boolean(defaultData?.length);
        return true;
      });

      return {
        ...state,
        filterOptions: updatedOptions,
        filterWithDefaultOptions: updatedFilterWithDefaultOptions,
      };
    }
    case 'CREATE_INSIGHTS_SEARCH': {
      const lastUserSearchId = action.payload?._id || action.payload?.search?.id;
      return {
        ...state,
        lastUserSearchId,
      };
    }

    case 'GET_DASHBOARD_SEARCHES': {
      return {
        ...state,
        dashboardSearches: {
          ...state.dashboardSearches,
          data: action.payload.data,
          ...action.payload.meta,
          initialRequest: false,
        },
      };
    }

    case 'GET_SEARCHES': {
      const { erasePreviousData } = action;

      return {
        ...state,
        searches: {
          ...state.searches,
          data: erasePreviousData ? action.payload.data : [...state.searches.data, ...action.payload.data],
          ...action.payload.meta,
          initialRequest: false,
        },
      };
    }

    case 'GET_SAVED_SEARCH_OPTIONS': {
      return {
        ...state,
        searchOptions: {
          ...state.searchOptions,
          ...action.payload,
        },
      };
    }
    case 'GET_POSITIONS': {
      return {
        ...state,
        positions: action.payload,
      };
    }
    case 'GET_INDUSTRIES': {
      return {
        ...state,
        industries: action.payload,
      };
    }
    case 'IMPORT_FILE_SEARCH_FILTER_PREPROCESS': {
      const { payload, originalName: fileName, fileUrl } = action;

      return {
        ...state,
        searchImport: {
          ...state.searchImport,
          ...payload,
          fileUrl: `${fileUrl}/${get(action, 'key', '')}`,
          fileName,
        },
      };
    }
    case 'CLEAR_SEARCH_IMPORT_PREPROCESS': {
      return {
        ...state,
        searchImport: initialState.searchImport,
      };
    }
    case 'ADD_MATCHING_FILTER_HEADER': {
      const { matchedHeader } = action.data;
      return {
        ...state,
        searchImport: {
          ...state.searchImport,
          matchedHeader,
        },
      };
    }
    case 'UPDATE_INCLUDE_UNKNOWN_FILTERS': {
      const { property, includedUnknown } = action.data;
      const newFilters = [...state.filters];
      const filterItemIndex = state.filters.findIndex((filter) => filter.property === property);
      const updatedFilter = { ...state.filters[filterItemIndex] };

      if (filterItemIndex > -1) {
        updatedFilter.included.value = get(updatedFilter, 'included.value', []).map((item) => {
          if (Array.isArray(item) && item.length === 3) {
            item[2] = includedUnknown;
          }
          return item;
        });

        newFilters[filterItemIndex] = updatedFilter;
      }
      return {
        ...state,
        filters: validateSearchFilters(newFilters, state.validOnly),
        initRecentActivities: true,
        lastUserSearchId: null,
        searchError: null,
      };
    }
    case 'ADD_OPTION_TO_INCLUDED_FILTERS': {
      const {
        property, includedOp, splittable = true, isImportFileOption = false, count = 0, delimiter, updateOnly = false, checkboxes = [],
      } = action.data;
      let { element } = action.data;
      const hasMultipleOptions = Array.isArray(element) ? false : splittable && element.search(SPLIT_TOKENS_REGEXP) !== -1;
      const isTreeProperty = [SearchFiltersTypes.CONTACT_DEPARTMENTS, SearchFiltersTypes.COMPANY_INDUSTRY].includes(property);
      const isMultipleProp = multipleValProps.includes(property);

      const filterKeywordsMap = (itemElement) => {
        const searchPropertyWithKeywords = (array) => {
          let foundPropertyKeywords;

          const searchByKeywords = (item) => {
            if (foundPropertyKeywords) return null;
            const foundKeywords = get(item, 'keywords');

            if (!foundKeywords && item?.children) {
              const childWithKeywords = item.children?.find((child) => searchByKeywords(child));
              return childWithKeywords;
            }
            foundPropertyKeywords = foundKeywords && item?.name === itemElement ? item : null;

            return foundPropertyKeywords;
          };

          array.some(searchByKeywords);
          return foundPropertyKeywords;
        };
        const propertyKeywords = isTreeProperty ? searchPropertyWithKeywords(state.filterOptions[property]) : null;

        return propertyKeywords ? ({
          matching: propertyKeywords,
          printedData: itemElement,
        }) : ({
          matching: itemElement,
          printedData: itemElement,
        });
      };

      const filterFileMap = (item) => ({
        count,
        delimiter,
        file: state.searchImport.fileUrl,
        matching: {
          [item]: state.searchImport.matchedHeader,
        },
        printedData: state.searchImport.fileName,
      });
      const filterItemIndex = state.filters.findIndex((filter) => filter.property === property);

      if (filterItemIndex !== -1) {
        const updatedFilter = { ...state.filters[filterItemIndex] };
        let filterFile = null;
        if (updatedFilter.excluded?.value) {
          filterFile = updatedFilter.excluded.value.find((item) => get(item, 'printedData', '') === element && !isTreeProperty);
        }

        const includeExcludeCheck = (optionItem) => {
          let filterWithKeywords;
          let isExcluded = updatedFilter.excluded?.value && updatedFilter.excluded.value.includes(optionItem);
          let foundMultipleItemInExcluded;

          if (!isExcluded) {
            isExcluded = updatedFilter.excluded?.value && updatedFilter.excluded.value.map((item) => get(item, 'printedData')).filter(Boolean).includes(optionItem);

            if (isTreeProperty) {
              if (isExcluded) {
                filterWithKeywords = updatedFilter.excluded.value.find((item) => get(item, 'printedData', '') === optionItem);
              } else {
                isExcluded = updatedFilter.excluded.value.some((item) => (get(item, 'printedData') === optionItem
                || Array.isArray(item?.matching) ? item?.matching?.some((subItem) => get(subItem, 'name', subItem) === optionItem) : get(item, 'matching.name', get(item, 'matching')) === optionItem));
              }
            }
          }

          if (isExcluded && updatedFilter.excluded?.value) {
            updatedFilter.excluded.value = updatedFilter.excluded.value.filter((item) => get(item, 'printedData', item) !== optionItem);
            // for autocomplite
            if (isTreeProperty) {
              updatedFilter.excluded.value = updatedFilter.excluded.value.filter((item) => {
                if (Array.isArray(item?.matching)) {
                  const foundedCategory = item?.matching.find((subItem) => get(subItem, 'name', subItem) === optionItem);

                  if (foundedCategory) {
                    const newElements = state.filterOptions[property].find((option) => option?.name === item?.printedData)?.children?.map((option) => get(option, 'name', option));

                    if (Array.isArray(newElements)) {
                      element = newElements;
                      const transformedItems = newElements.map((option) => filterKeywordsMap(option));
                      updatedFilter.included.value.push(...transformedItems);
                    }
                  }
                  return !foundedCategory;
                }
                return true;
              });
            }
          }

          if (isMultipleProp && !isExcluded && updatedFilter.excluded?.value) {
            updatedFilter.excluded.value = updatedFilter.excluded.value.filter((item) => {
              let res = false;

              if (Array.isArray(item)) {
                if (item.length === 2) {
                  res = item[0] === optionItem;
                }
                // else if (item.length === 3 && Array.isArray(optionItem)) {
                //   res = item[0] === optionItem?.[0] && item[1] === optionItem?.[1];
                // }
              }

              if (res) {
                foundMultipleItemInExcluded = item;
              }
              return !res;
            });
          }

          let isIncluded = updatedFilter.included.value.includes(optionItem);
          if (!isIncluded) isIncluded = updatedFilter.included.value && updatedFilter.included.value.map((item) => get(item, 'printedData')).filter(Boolean).includes(optionItem);

          // for autocomplite
          if (isTreeProperty && !isIncluded) {
            isIncluded = updatedFilter.included.value.some((item) => (get(item, 'printedData') === optionItem
            || Array.isArray(item?.matching) ? item?.matching?.some((subItem) => get(subItem, 'name', subItem) === optionItem) : get(item, 'matching.name', get(item, 'matching')) === optionItem));
          }

          if (isMultipleProp && !isIncluded) {
            isIncluded = updatedFilter.included.value.some((item) => {
              let res = false;

              if (Array.isArray(item)) {
                if (item.length === 2) {
                  res = item[0] === optionItem;
                }
                // else if (item.length === 3 && Array.isArray(optionItem)) {
                //   res = item[0] === optionItem?.[0] && item[1] === optionItem?.[1];
                // }
              }
              return res;
            });
          }

          if (!isIncluded) {
            let dataToPush = optionItem;

            if (isMultipleProp && foundMultipleItemInExcluded) {
              dataToPush = foundMultipleItemInExcluded;
            }

            if (filterFile) {
              dataToPush = filterFile;
            }

            if (isTreeProperty && filterWithKeywords?.printedData === optionItem) {
              dataToPush = filterWithKeywords;
            } else if (isTreeProperty && !filterWithKeywords) {
              filterWithKeywords = filterKeywordsMap(optionItem);

              if (filterWithKeywords) {
                dataToPush = filterWithKeywords;
              }
            }

            if (updateOnly) {
              updatedFilter.included.value = [dataToPush];
            } else {
              updatedFilter.included.value.push(dataToPush);
            }
          }
        };

        if (hasMultipleOptions) {
          const splitOptions = uniq(element.split(SPLIT_TOKENS_REGEXP)).slice(0, FILTER_SPLIT_LIMIT - 1).filter(Boolean);

          splitOptions.forEach((splitOption) => {
            includeExcludeCheck(splitOption);
          });
        } else if (isImportFileOption) {
          updatedFilter.included.value.push(filterFileMap(element));
        } else if (Array.isArray(element) && !multipleValProps.includes(property)) {
          let dataToCheck = element;

          if (isTreeProperty && Array.isArray(element)) {
            dataToCheck = dataToCheck.filter((elemntItem) => !state.filters[filterItemIndex]?.excluded?.value?.some((option) => elemntItem === option.printedData)
              && !state.filters[filterItemIndex]?.included?.value?.some((option) => elemntItem === option.printedData));
          }
          dataToCheck.forEach((elementItem) => {
            includeExcludeCheck(elementItem);
          });
        } else {
          includeExcludeCheck(element);
        }

        const newFilters = [...state.filters];
        newFilters[filterItemIndex] = updatedFilter;

        if (property === 'company.domain') {
          newFilters[filterItemIndex].included.value = uniq(get(newFilters[filterItemIndex], 'included.value', []).map((item) => {
            if (typeof item === 'object') return item;
            return domainPrintedDataMap(item).domain;
          }));
          newFilters[filterItemIndex].included.map = get(newFilters[filterItemIndex], 'included.value', []).filter((item) => typeof item !== 'object').map(domainPrintedDataMap);
          newFilters[filterItemIndex].excluded.map = get(newFilters[filterItemIndex], 'excluded.value', []).filter((item) => typeof item !== 'object').map(domainPrintedDataMap);
        } else if (isTreeProperty) {
          let propFilterOptionCategory = state.filterOptions[property]
            .find((option) => option?.children.some((optionChild) => (optionChild?.name ?? optionChild) === (Array.isArray(element) ? element[0] : element)));
          const filterIndexesToRemove = [];

          if (propFilterOptionCategory) {
            const areAllOptionsCategory = propFilterOptionCategory?.children?.every((childOption) => {
              const indexFilterToRemove = newFilters[filterItemIndex].included.value
                .findIndex((filterItem) => (filterItem?.matching?.name ?? filterItem?.matching) === (childOption?.name ?? childOption));
              return indexFilterToRemove > -1 ? filterIndexesToRemove.push(indexFilterToRemove) && true : false;
            });

            if (areAllOptionsCategory) {
              filterIndexesToRemove.sort((a, b) => b - a);
              filterIndexesToRemove.forEach((index) => newFilters[filterItemIndex].included.value.splice(index, 1));
              newFilters[filterItemIndex].included.value.push({
                matching: propFilterOptionCategory.children,
                printedData: propFilterOptionCategory.name,
              });
            }
            // for autocomplite
          } else if (!Array.isArray(element)) {
            propFilterOptionCategory = state.filterOptions[property]
              .find((option) => option?.name === element);

            if (propFilterOptionCategory?.children) {
              const itemsToCheck = propFilterOptionCategory.children?.map((option) => get(option, 'name', option));
              const indexFilterToRemove = newFilters[filterItemIndex].included.value
                .findIndex((item) => (item?.printedData ?? item) === element);

              if (indexFilterToRemove > -1) {
                newFilters[filterItemIndex].included.value.splice(indexFilterToRemove, 1);
              }

              if (itemsToCheck.length) {
                newFilters[filterItemIndex].included.value = newFilters[filterItemIndex].included.value.filter((item) => !itemsToCheck.some((checkItem) => get(item, 'printedData', item) === checkItem));
                newFilters[filterItemIndex].excluded.value = newFilters[filterItemIndex].excluded.value.filter((item) => !itemsToCheck.some((checkItem) => get(item, 'printedData', item) === checkItem));
              }
              newFilters[filterItemIndex].included.value.push({
                matching: propFilterOptionCategory.children,
                printedData: propFilterOptionCategory.name,
              });
            }
          }
        }
        return {
          ...state,
          filters: validateSearchFilters(newFilters, state.validOnly),
          initRecentActivities: true,
          lastUserSearchId: null,
          searchError: null,
        };
      }
      let elementToInclude = Array.isArray(element) ? element : [element];

      if (multipleValProps.includes(property)) {
        elementToInclude = [element];
      }

      const filterItem = {
        property,
        included: {
          operator: includedOp,
          value: !hasMultipleOptions ? elementToInclude : uniq(element.split(SPLIT_TOKENS_REGEXP)).slice(0, FILTER_SPLIT_LIMIT - 1).filter(Boolean),
        },
        excluded: {
          value: [],
        },
        checkboxes,
      };

      if (isImportFileOption) {
        filterItem.included.value = get(filterItem, 'included.value', []).map(filterFileMap);
      }

      if (isTreeProperty) {
        filterItem.included.value = get(filterItem, 'included.value', []).map(filterKeywordsMap);
      }

      if (isTreeProperty) {
        let propFilterOptionCategory = state.filterOptions[property]
          .find((option) => option?.children.some((optionChild) => (optionChild?.name ?? optionChild) === (Array.isArray(element) ? element[0] : element)));
        const filterIndexesToRemove = [];

        if (propFilterOptionCategory) {
          propFilterOptionCategory.children.forEach((childOption) => {
            const indexFilterToRemove = filterItem.included.value
              .findIndex((item) => (item?.matching?.name ?? item?.matching) === (childOption?.name ?? childOption));

            if (indexFilterToRemove > -1) {
              filterIndexesToRemove.push(indexFilterToRemove);
            }
          });

          if (Array.isArray(element) || propFilterOptionCategory?.children?.length === 1) {
            filterIndexesToRemove.sort((a, b) => b - a);
            filterIndexesToRemove.forEach((index) => filterItem.included.value.splice(index, 1));
            filterItem.included.value.push({
              matching: propFilterOptionCategory.children,
              printedData: propFilterOptionCategory.name,
            });
          }
          // for autocomplite
        } else if (!Array.isArray(element)) {
          propFilterOptionCategory = state.filterOptions[property]
            .find((option) => option?.name === element);

          if (propFilterOptionCategory?.children) {
            const indexFilterToRemove = filterItem.included.value
              .findIndex((item) => (item?.printedData ?? item) === element);

            if (indexFilterToRemove > -1) {
              filterItem.included.value.splice(indexFilterToRemove, 1);
            }
            filterItem.included.value.push({
              matching: propFilterOptionCategory.children,
              printedData: propFilterOptionCategory.name,
            });
          }
        }
      }

      if (property === 'company.domain') {
        filterItem.included.value = uniq(filterItem.included.value.map((item) => {
          if (typeof item === 'object') return item;
          return domainPrintedDataMap(item).domain;
        }));
        filterItem.included.map = get(filterItem, 'included.value', []).filter((item) => typeof item !== 'object').map(domainPrintedDataMap);
        filterItem.excluded.map = get(filterItem, 'excluded.value', []).filter((item) => typeof item !== 'object').map(domainPrintedDataMap);
      }
      const newFilters = [...state.filters, filterItem];

      return {
        ...state,
        filters: validateSearchFilters(newFilters, state.validOnly),
        initRecentActivities: true,
        lastUserSearchId: null,
        searchError: null,
      };
    }

    case 'UPDATE_FILTERS_CHECKBOXES': {
      const { property, checkboxes } = action.data;
      const filterItemIndex = state.filters.findIndex((filter) => filter.property === property);

      if (filterItemIndex !== -1) {
        const updatedFilter = { ...state.filters[filterItemIndex] };
        updatedFilter.checkboxes = checkboxes;
        const newFilters = [...state.filters];
        newFilters[filterItemIndex] = updatedFilter;

        return {
          ...state,
          filters: validateSearchFilters(newFilters, state.validOnly),
          initRecentActivities: true,
          lastUserSearchId: null,
          searchError: null,
        };
      }

      return state;
    }

    case 'SET_SAVED_SEARCH_FILTER': {
      const newFilters = action.data.saveExisting ? uniqBy([...state.filters, ...action.data.filters], 'property') : uniqBy([...action.data.filters], 'property');
      if (newFilters.find((filter) => filter.property === 'email')) {
        newFilters.push({
          property: 'email',
          included: {
            operator: 'EMAIL_STATUS',
            value: SearchFiltersTypes.EMAIL_FILTER_STATUS.VALID,
          },
        });
      }
      return {
        ...state,
        filters: validateSearchFilters(newFilters, state.validOnly),
        initRecentActivities: action.data.initRecentActivities,
        lastUserSearchId: action.data.searchId,
        searchError: null,
      };
    }

    case 'GET_SINGLE_SAVED_SEARCH': {
      const data = action.payload;

      if (data) {
        return {
          ...state,
          filters: validateSearchFilters([...get(data, 'filters', [])], state.validOnly),
          searchError: null,
        };
      }

      return {
        ...state,
      };
    }

    case 'ADD_OPTION_TO_EXCLUDED_FILTERS': {
      const {
        property, excludedOp, element,
      } = action.data;

      const filterItemIndex = state.filters.findIndex((filter) => filter.property === property);
      const isTreeProperty = [SearchFiltersTypes.CONTACT_DEPARTMENTS, SearchFiltersTypes.COMPANY_INDUSTRY].includes(property);

      if (filterItemIndex !== -1) {
        const updatedFilter = { ...state.filters[filterItemIndex] };
        const filterFile = updatedFilter.included.value.find((item) => get(item, 'printedData') === element);
        let isIncluded = updatedFilter.included.value && includes(updatedFilter.included.value, element);
        if (!isIncluded) isIncluded = updatedFilter.included.value && updatedFilter.included.value.map((item) => get(item, 'printedData')).filter(Boolean).includes(element);
        if (isIncluded) updatedFilter.included.value = updatedFilter.included.value.filter((item) => !isEqual(get(item, 'printedData', item), element));

        if (!get(updatedFilter, 'excluded.operator')) updatedFilter.excluded.operator = excludedOp;
        let isExcluded = updatedFilter.excluded.value && includes(updatedFilter.excluded.value, element);
        isExcluded = updatedFilter.excluded.value && updatedFilter.excluded.value.find((item) => get(item, 'printedData') === element);

        if (!isExcluded) {
          let dataToPush = element;

          if (filterFile) {
            dataToPush = filterFile;
          }
          updatedFilter.excluded.value.push(dataToPush);
        }

        const newFilters = [...state.filters];
        newFilters[filterItemIndex] = updatedFilter;

        if (property === 'company.domain') {
          newFilters[filterItemIndex].included.map = get(newFilters[filterItemIndex], 'included.value', []).filter((item) => typeof item !== 'object').map(domainPrintedDataMap);
          newFilters[filterItemIndex].excluded.map = get(newFilters[filterItemIndex], 'excluded.value', []).filter((item) => typeof item !== 'object').map(domainPrintedDataMap);
        }

        if (isTreeProperty) {
          const propFilterOptionCategory = state.filterOptions[property]
            .find((option) => option?.children.some((optionChild) => (optionChild?.name ?? optionChild) === (Array.isArray(element) ? element[0] : element)));
          const filterIndexesToRemove = [];

          if (propFilterOptionCategory) {
            propFilterOptionCategory.children.forEach((childOption) => {
              const indexFilterToRemove = newFilters[filterItemIndex].excluded.value
                .findIndex((item) => (item?.matching?.name ?? item?.matching) === (childOption?.name ?? childOption));

              if (indexFilterToRemove > -1) {
                filterIndexesToRemove.push(indexFilterToRemove);
              }
            });

            if (propFilterOptionCategory.children.length === filterIndexesToRemove.length) {
              filterIndexesToRemove.sort((a, b) => b - a);
              filterIndexesToRemove.forEach((index) => newFilters[filterItemIndex].excluded.value.splice(index, 1));
              newFilters[filterItemIndex].excluded.value.push({
                matching: propFilterOptionCategory.children,
                printedData: propFilterOptionCategory.name,
              });
            }
          }
        }

        return {
          ...state,
          filters: validateSearchFilters(newFilters, state.validOnly),
          initRecentActivities: true,
          lastUserSearchId: null,
          searchError: null,
        };
      }
      const filterItem = {
        property,
        excluded: {
          operator: excludedOp,
          value: [element],
        },
        included: {
          value: [],
        },
      };

      if (property === 'company.domain') {
        filterItem.included.map = get(filterItem, 'included.value', []).filter((item) => typeof item !== 'object').map(domainPrintedDataMap);
        filterItem.excluded.map = get(filterItem, 'excluded.value', []).filter((item) => typeof item !== 'object').map(domainPrintedDataMap);
      }

      const newFilters = [...state.filters, filterItem];

      return {
        ...state,
        filters: validateSearchFilters(newFilters, state.validOnly),
        initRecentActivities: true,
        searchError: null,
      };
    }

    case 'REMOVE_PROPERTY_FROM_FILTERS': {
      const { property } = action.data;
      const filterItemIndex = state.filters.findIndex((filter) => filter.property === property);
      const newFilters = [...state.filters];

      if (filterItemIndex > -1) {
        newFilters.splice(filterItemIndex, 1);
      }
      return {
        ...state,
        filters: validateSearchFilters(newFilters, state.validOnly),
        searchError: null,
      };
    }

    case 'REMOVE_OPTION_FROM_FILTERS': {
      let { element } = action.data;
      const { property } = action.data;
      const filterItemIndex = state.filters.findIndex((filter) => filter.property === property);
      const isTreeProperty = [SearchFiltersTypes.CONTACT_DEPARTMENTS, SearchFiltersTypes.COMPANY_INDUSTRY].includes(property);

      if (filterItemIndex !== -1) {
        const updatedFilter = { ...state.filters[filterItemIndex] };
        const propFilterOptionCategory = isTreeProperty ? state.filterOptions[property]
          .find((option) => option?.children.some((optionChild) => (optionChild?.name ?? optionChild) === (Array.isArray(element) ? element[0] : element))) : null;
        const isCategoryElement = element?.isCategory ?? false;

        if (isCategoryElement) {
          element = element?.value;
        }

        const filterFn = (item) => {
          if (Array.isArray(element) && !multipleValProps.includes(property)) {
            return !element.some((elementItem) => isEqual(get(item, 'printedData', item), elementItem));
          }

          return !isEqual(get(item, 'printedData', item), element);
        };
        const replaceCategory = (filter) => {
          const filterOptionsToInsert = propFilterOptionCategory?.children?.map((item) => ({ printedData: (item?.name ?? item), matching: item }));

          const indexToReplace = filter.value.findIndex((item) => {
            if (!Array.isArray(item?.matching)) return false;
            return item?.matching?.some((subItem) => isEqual(get(subItem, 'name', subItem), element));
          });

          if (indexToReplace > -1) {
            filter.value.splice(indexToReplace, 1, ...filterOptionsToInsert);
          }
        };
        if (propFilterOptionCategory && !isCategoryElement) {
          const categoryName = propFilterOptionCategory.name;
          const isCurrentElemCategoryExists = updatedFilter.included.value.some((item) => get(item, 'printedData', item) === categoryName && Array.isArray(item?.matching))
            || updatedFilter.excluded.value.some((item) => get(item, 'printedData', item) === categoryName && Array.isArray(item?.matching));

          if (isCurrentElemCategoryExists) {
            replaceCategory(updatedFilter.included);

            if (updatedFilter.excluded?.value) {
              replaceCategory(updatedFilter.excluded);
            }
          }
        }
        updatedFilter.included.value = updatedFilter.included.value.filter(filterFn);
        if (updatedFilter.excluded?.value) {
          updatedFilter.excluded.value = updatedFilter.excluded.value.filter(filterFn);
        }

        const isIncludedEmpty = get(updatedFilter, 'included.value.length', 0) === 0;
        const isExcludedEmpty = get(updatedFilter, 'excluded.value.length', 0) === 0;

        const newFilters = [...state.filters];

        if (isIncludedEmpty && isExcludedEmpty) {
          newFilters.splice(filterItemIndex, 1);
        } else newFilters[filterItemIndex] = updatedFilter;

        return {
          ...state,
          filters: validateSearchFilters(newFilters, state.validOnly),
          lastUserSearchId: null,
          searchError: null,
        };
      }

      return state;
    }

    case 'GET_SENIORITY_OPTIONS': {
      const updatedOptions = { ...state.filterOptions };
      updatedOptions[SearchFiltersTypes.CONTACT_SENIORITY] = [
        'Owner',
        'Partner',
        'Chief Officer',
        'VP',
        'Director',
        'Senior',
        'Manager',
        'Intern',
        'Unpaid',
      ];

      return {
        ...state,
        filterOptions: updatedOptions,
      };
    }

    case 'GET_COMPANY_TYPE_OPTIONS': {
      const updatedOptions = { ...state.filterOptions };
      updatedOptions[SearchFiltersTypes.COMPANY_TYPE] = [
        'Private',
        'Public',
        'Education',
        'Government',
        'Nonprofit',
      ];

      return {
        ...state,
        filterOptions: updatedOptions,
      };
    }

    case 'GET_EMAIL_OPTIONS': {
      const { validOnly } = action.data;
      const updatedOptions = { ...state.filterOptions };
      updatedOptions[SearchFiltersTypes.CONTACT_EMAIL] = [];
      updatedOptions[SearchFiltersTypes.CONTACT_EMAIL].push(
        {
          label: 'All contacts',
          value: SearchFiltersTypes.EMAIL_FILTER_STATUS.ALL_CONTACTS,
          default: true,
        },
        {
          label: 'With emails',
          value: validOnly ? SearchFiltersTypes.EMAIL_FILTER_STATUS.VALID : SearchFiltersTypes.EMAIL_FILTER_STATUS.ALL,
        },
      );

      return {
        ...state,
        filterOptions: updatedOptions,
        validOnly,
      };
    }

    case 'GET_POSITION_OPTIONS': {
      const updatedOptions = { ...state.filterOptions };

      updatedOptions[SearchFiltersTypes.CONTACT_POSITION] = action.payload.map((item) => ({
        value: item.name,
        label: item.name,
      }));

      return {
        ...state,
        filterOptions: updatedOptions,
      };
    }

    case 'AUTOCOMPLETE_CONTACT_NAME_OPTIONS': {
      const { name = '' } = action.data;
      const updatedContactNamesOptions = { ...state.autocompleteFilterOptions };
      const contactNamesData = (state?.filterOptions?.[SearchFiltersTypes.CONTACT_NAME] ?? []);
      let dataToUpdate = [];

      dataToUpdate = contactNamesData.map((item) => {
        const keywordNameParts = item.split(' ');
        const isMatchedWithKeyword = keywordNameParts.some((part) => new RegExp(`^${name}.*`, 'i').test(part));

        return isMatchedWithKeyword ? item : null;
      }).filter((item) => item);

      updatedContactNamesOptions[SearchFiltersTypes.CONTACT_NAME] = dataToUpdate;

      return {
        ...state,
        autocompleteFilterOptions: updatedContactNamesOptions,
      };
    }

    case 'AUTOCOMPLETE_CONTACT_KEYWORDS_OPTIONS': {
      const { name = '' } = action.data;
      const updatedKeywordsOptions = { ...state.autocompleteFilterOptions };
      const keywordsData = (state?.filterOptions?.[SearchFiltersTypes.KEYWORDS] ?? []);
      let dataToUpdate = [];

      dataToUpdate = keywordsData.map((item) => {
        const keywordNameParts = item.split(' ');
        const isMatchedWithKeyword = keywordNameParts.some((part) => new RegExp(`^${name}.*`, 'i').test(part));

        return isMatchedWithKeyword ? item : null;
      }).filter((item) => item);

      updatedKeywordsOptions[SearchFiltersTypes.KEYWORDS] = dataToUpdate;

      return {
        ...state,
        autocompleteFilterOptions: updatedKeywordsOptions,
      };
    }

    case 'AUTOCOMPLETE_DEPARTMENT_OPTIONS': {
      const { name = '' } = action.data;
      const updatedDepartmentOptions = { ...state.autocompleteFilterOptions };
      const departmentsData = (state?.filterOptions?.[SearchFiltersTypes.CONTACT_DEPARTMENTS] ?? []);
      let dataToUpdate = [];

      dataToUpdate = departmentsData.map((item) => {
        const updatedItem = { ...item };
        const categoryNameParts = get(updatedItem, 'name', '').split(' ');
        const isMatchedWithCategory = categoryNameParts.some((part) => new RegExp(`^${name}.*`, 'i').test(part));

        if (!isMatchedWithCategory) {
          updatedItem.children = updatedItem.children.filter((child) => {
            const childNameParts = get(child, 'name', '').split(' ');
            return childNameParts.some((childPart) => new RegExp(`^${name}.*`, 'i').test(childPart));
          });
        }
        return updatedItem;
      }).filter((item) => item?.children?.length);

      dataToUpdate.forEach((item) => {
        const categoryData = item?.children;
        categoryData.sort((a, b) => a?.name?.localeCompare(b?.name));
      });
      dataToUpdate.sort((a, b) => a?.name?.localeCompare(b?.name));

      updatedDepartmentOptions[SearchFiltersTypes.CONTACT_DEPARTMENTS] = dataToUpdate;

      return {
        ...state,
        autocompleteFilterOptions: updatedDepartmentOptions,
      };
    }

    case 'GET_DEPARTMENTS_OPTIONS': {
      const updatedOptions = { ...state.filterOptions };

      const dataToUpdate = action.payload.map((department) => {
        const updatedDepartment = { ...department, children: department.subDepartments };
        delete updatedDepartment.subDepartments;
        return updatedDepartment;
      });

      dataToUpdate.forEach((item) => {
        const categoryData = item?.children;
        categoryData.sort((a, b) => a?.name?.localeCompare(b?.name));
      });
      dataToUpdate.sort((a, b) => a?.name?.localeCompare(b?.name));

      updatedOptions[SearchFiltersTypes.CONTACT_DEPARTMENTS] = dataToUpdate;

      return {
        ...state,
        filterOptions: updatedOptions,
        autocompleteFilterOptions: {
          ...state.autocompleteFilterOptions,
          [SearchFiltersTypes.CONTACT_DEPARTMENTS]: [...dataToUpdate],
        },
      };
    }

    case 'AUTOCOMPLETE_POSITION_OPTIONS': {
      const suggestions = action.payload;
      const updatedOptions = { ...state.filterOptions };

      updatedOptions[SearchFiltersTypes.CONTACT_POSITION] = suggestions.map((item) => ({
        value: item,
        label: item,
      }));
      return {
        ...state,
        filterOptions: updatedOptions,
      };
    }

    case 'GET_CONTACT_LOCATION_OPTIONS': {
      const updatedOptions = { ...state.filterOptions };

      updatedOptions[SearchFiltersTypes.CONTACT_LOCATION] = action.payload.map((item) => ({
        value: [item.name, item.coordinates],
        label: item.name,
      }));

      return {
        ...state,
        filterOptions: updatedOptions,
      };
    }

    case 'GET_COMPANY_LOCATION_OPTIONS': {
      const updatedOptions = { ...state.filterOptions };

      updatedOptions[SearchFiltersTypes.COMPANY_LOCATION] = action.payload.map((item) => ({
        value: [item.name, item.coordinates],
        label: item.name,
      }));

      return {
        ...state,
        filterOptions: updatedOptions,
      };
    }

    case 'AUTOCOMPLETE_INDUSTRIES_OPTIONS': {
      const { name = '' } = action.data;
      const updatedDepartmentOptions = { ...state.autocompleteFilterOptions };
      const industriesData = (state?.filterOptions?.[SearchFiltersTypes.COMPANY_INDUSTRY] ?? []);
      let dataToUpdate = [];

      dataToUpdate = industriesData.map((item) => {
        const updatedItem = { ...item };
        const categoryNameParts = get(updatedItem, 'name', '').split(' ');
        const isMatchedWithCategory = categoryNameParts.some((part) => new RegExp(`^${name}.*`, 'i').test(part));

        if (!isMatchedWithCategory) {
          updatedItem.children = updatedItem.children.filter((child) => {
            const childName = get(child, 'name', child);

            if (typeof childName === 'string') {
              const childNameParts = childName.split(' ');
              return childNameParts.some((childPart) => new RegExp(`^${name}.*`, 'i').test(childPart));
            }
            return new RegExp(`^${name}.*`).test(childName);
          });
        }

        return updatedItem;
      }).filter((item) => item?.children?.length);

      dataToUpdate.forEach((item) => {
        const categoryData = item?.children;
        categoryData.sort((a, b) => a?.localeCompare(b));
      });
      dataToUpdate.sort((a, b) => a?.name?.localeCompare(b?.name));

      updatedDepartmentOptions[SearchFiltersTypes.COMPANY_INDUSTRY] = dataToUpdate;

      return {
        ...state,
        autocompleteFilterOptions: updatedDepartmentOptions,
      };
    }

    case 'GET_INDUSTRY_OPTIONS': {
      const updatedOptions = { ...state.filterOptions };
      const dataToUpdate = action.payload.reduce((prev, currentItem) => {
        let prevData = prev;

        (currentItem?.categories ?? ['Other']).forEach((category) => {
          const existedCategory = prevData.find((item) => item.name === category);

          if (existedCategory) {
            existedCategory.children.push(get(currentItem, 'en_US'));
            return prevData;
          }

          prevData = [...prev, { name: category, children: [get(currentItem, 'en_US')] }];
          return prevData;
        });

        return prevData;
      }, []);

      dataToUpdate.forEach((item) => {
        const categoryData = item?.children;
        categoryData.sort((a, b) => a?.localeCompare(b));
      });
      dataToUpdate.sort((a, b) => a?.name?.localeCompare(b?.name));
      updatedOptions[SearchFiltersTypes.COMPANY_INDUSTRY] = dataToUpdate;
      // updatedOptions[SearchFiltersTypes.COMPANY_INDUSTRY] = action.payload.map((item) => get(item, 'en_US'));

      return {
        ...state,
        filterOptions: updatedOptions,
        autocompleteFilterOptions: {
          ...state.autocompleteFilterOptions,
          [SearchFiltersTypes.COMPANY_INDUSTRY]: [...dataToUpdate],
        },
      };
    }

    case 'GET_COMPANY_NAME_OPTIONS': {
      const updatedOptions = { ...state.filterOptions };
      updatedOptions[SearchFiltersTypes.COMPANY_NAME] = action.data.map((item) => ({
        value: item.name,
        domain: item.domain,
        name: item.name,
      }));

      return {
        ...state,
        filterOptions: updatedOptions,
      };
    }

    case 'GET_COMPANY_DOMAIN_OPTIONS': {
      const updatedOptions = { ...state.filterOptions };
      updatedOptions[SearchFiltersTypes.COMPANY_DOMAIN] = action.data.map((item) => item.domain);

      return {
        ...state,
        filterOptions: updatedOptions,
      };
    }

    case 'GET_EMPLOYEES_OPTIONS': {
      const { filterOptions } = state;
      filterOptions[SearchFiltersTypes.COMPANY_SIZE] = [
        '1 - 10',
        '11 - 20',
        '21 - 50',
        '51 - 100',
        '101 - 200',
        '201 - 500',
        '501 - 1000',
        '1001 - 2000',
        '2001 - 5000',
        '5001 - 10000',
        '10000',
      ];

      return { ...state, filterOptions };
    }

    case 'GET_LAST_UPDATED_OPTIONS': {
      const { sourceType, customOptions } = action.data;
      const date = moment();
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          [sourceType === SOURCE_TYPE.CONTACT ? SearchFiltersTypes.CONTACT_UPDATED_AT : SearchFiltersTypes.COMPANY_UPDATED_AT]: [
            {
              label: '< 3 months ago',
              value: [[date.clone().subtract(3, 'M').startOf('day').toISOString(), 'now']],
            },
            {
              label: '< 6 months ago',
              value: [[date.clone().subtract(6, 'M').startOf('day').toISOString(), 'now']],
            },
            {
              label: '< 1 year ago',
              value: [[date.clone().subtract(1, 'y').startOf('day').toISOString(), 'now']],
            },
            {
              label: '< 2 years ago',
              value: [[date.clone().subtract(2, 'y').startOf('day').toISOString(), 'now']],
            },
            {
              label: '< 3 years ago',
              value: [[date.clone().subtract(3, 'y').startOf('day').toISOString(), 'now']],
            },
            ...customOptions,
          ],
        },
      };
    }

    case 'GET_TECHNOLOGIES_OPTIONS': {
      const updatedOptions = { ...state.filterOptions };
      updatedOptions[SearchFiltersTypes.COMPANY_TECHNOLOGIES] = action.payload.map((item) => ({
        label: item.name,
        value: [item.name, item._id],
        domain: item?.website ? new URL(item.website).hostname : '',
      }));

      return {
        ...state,
        filterOptions: updatedOptions,
      };
    }

    case 'GET_COMPANY_KEYWORD_OPTIONS': {
      const updatedOptions = { ...state.filterOptions };
      updatedOptions[SearchFiltersTypes.COMPANY_KEYWORDS] = action.payload.map((item) => ({
        label: item.name,
        value: item.name,
      }));

      return {
        ...state,
        filterOptions: updatedOptions,
      };
    }

    case 'GET_COUNTRIES': {
      const { data, meta } = action.payload;
      return {
        ...state,
        countries: {
          ...state.countries,
          data: meta.page > 1 ? [...state.countries.data, ...data] : data,
          ...meta,
        },
      };
    }
    case 'CLEAR_SEARCH_FILTER': {
      const { sourceType } = action.data;
      return {
        ...state,
        filters: validateSearchFilters(sourceType ? state.filters.filter((filter) => !filter.property.includes(sourceType)) : [], state.validOnly),
        lastUserSearchId: null,
        searchError: null,
      };
    }

    case 'REPLACE_SEARCH_FILTER': {
      const { operator, property, value } = action.data;
      if (!value) {
        return {
          ...state,
          filters: validateSearchFilters(state.filters.filter((filter) => filter.property !== property), state.validOnly),
          searchError: null,
        };
      }
      const existsFilter = state.filters.some((filter) => filter.property === property);
      if (existsFilter) {
        return {
          ...state,
          filters: validateSearchFilters(state.filters.map((filter) => {
            if (filter.property === property) {
              return {
                ...filter,
                included: {
                  ...filter.included,
                  value,
                },
              };
            }
            return filter;
          }), state.validOnly),
          searchError: null,
        };
      }
      return {
        ...state,
        filters: validateSearchFilters([...state.filters, createOnlyIncludedFilter({ operator, property, value })], state.validOnly),
        searchError: null,
      };
    }

    case 'SET_INSIGHTS_SOURCE_TYPE':
      return { ...state, sourceType: action.data.sourceType };

    case 'SET_SELECTED_CONTACT_TAB':
      return { ...state, selectedContactTab: action.data.selectedTab };

    case 'SET_SELECTED_COMPANY_TAB':
      return { ...state, selectedCompanyTab: action.data.selectedTab };

    case 'SET_INITIAL_REQUEST_SEARCH': {
      const { type, initialRequest } = action.data;

      return {
        ...state,
        [type]: {
          ...state[type],
          initialRequest,
        },
      };
    }

    case 'GET_DASHBOARD_SEARCHES_HISTORY': {
      const { data, meta } = action.payload;

      return {
        ...state,
        dashboardSearchesHistory: {
          ...state.dashboardSearchesHistory,
          data,
          ...meta,
          initialRequest: false,
        },
      };
    }

    case 'GET_MANAGED_SEARCHES_HISTORY': {
      const { erasePreviousData } = action;
      const { data, meta } = action.payload;

      return {
        ...state,
        searchesHistory: {
          ...state.searchesHistory,
          data: erasePreviousData ? data : [...state.searchesHistory.data, ...data],
          ...meta,
          initialRequest: false,
        },
      };
    }

    case 'GET_SHORT_MANAGED_SEARCHES_HISTORY': {
      const { erasePreviousData, savePrevMetaData } = action;
      const { data, meta } = action.payload;

      const pageMetaData = {
        page: meta.page,
        pageSize: meta.pageSize,
        totalPages: meta.totalPages,
      };

      delete meta.page;
      delete meta.pageSize;
      delete meta.totalPages;

      const finalData = {
        ...state,
        shortSearchesHistory: {
          ...state.shortSearchesHistory,
          data: erasePreviousData ? data : [...state.shortSearchesHistory.data, ...data],
          ...meta,
          initialRequest: false,
        },
      };
      if (!savePrevMetaData) {
        finalData.shortSearchesHistory = {
          ...finalData.shortSearchesHistory,
          ...pageMetaData,
        };
      }

      return finalData;
    }

    case 'GET_SHORT_SEARCHES': {
      const { erasePreviousData, savePrevMetaData } = action;
      const { data, meta } = action.payload;

      const pageMetaData = {
        page: meta.page,
        pageSize: meta.pageSize,
        totalPages: meta.totalPages,
      };

      delete meta.page;
      delete meta.pageSize;
      delete meta.totalPages;

      const finalData = {
        ...state,
        shortSearches: {
          ...state.shortSearches,
          data: erasePreviousData ? data : [...state.shortSearches.data, ...data],
          ...meta,
          initialRequest: false,
        },
      };

      if (!savePrevMetaData) {
        finalData.shortSearches = {
          ...finalData.shortSearches,
          ...pageMetaData,
        };
      }

      return finalData;
    }

    case 'CLEAR_SEARCH_HISTORIES': {
      return {
        ...state,
        searchesHistory: { ...initialState.searchesHistory },
      };
    }

    case ACCOUNT_EVENTS.SEARCHES_EVENT: {
      const { eventSearchesStatuses = [] } = JSON.parse(action.payload);

      if (!eventSearchesStatuses.length) {
        return {
          ...state,
        };
      }

      const updatedData = state.searchesHistory.data.map((item) => {
        const eventData = eventSearchesStatuses.find((eventItem) => eventItem.searchId === item._id);

        if (!eventData) {
          return item;
        }
        if (eventData.saved) {
          if (eventData.status === Progress.DONE) {
            item.saved = eventData.saved;
          } else {
            item.saved += eventData.saved;
          }
        }
        if (eventData.status) {
          item.status = eventData.status;
        }
        if (eventData.note) {
          item.note = eventData.note;
        }
        return item;
      });
      return {
        ...state,
        searchesHistory: {
          ...state.searchesHistory,
          data: updatedData,
        },
      };
    }

    case 'DELETE_SAVED_SEARCH': {
      const { savedSearchId } = action;
      return {
        ...state,
        searches: {
          ...state.searches,
          totalItems: state.searches.totalItems - 1,
          data: state.searches.data.filter((savedSearch) => savedSearch._id !== savedSearchId),
        },
        shortSearches: {
          ...state.shortSearches,
          totalItems: state.shortSearches.totalItems - 1,
          data: state.shortSearches.data.filter((savedSearch) => savedSearch._id !== savedSearchId),
        },
        dashboardSearches: {
          ...state.dashboardSearches,
          totalItems: state.dashboardSearches.totalItems - 1,
          data: state.dashboardSearches.data.filter((savedSearch) => savedSearch._id !== savedSearchId),
        },
      };
    }

    case 'RENAME_SAVED_SEARCH': {
      const { savedSearchId, newName } = action;
      return {
        ...state,
        searches: {
          ...state.searches,
          data: state.searches.data.map((savedSearch) => (savedSearch._id === savedSearchId ? { ...savedSearch, name: newName } : savedSearch)),
        },
        shortSearches: {
          ...state.shortSearches,
          data: state.shortSearches.data.map((savedSearch) => (savedSearch._id === savedSearchId ? { ...savedSearch, name: newName } : savedSearch)),
        },
      };
    }

    case 'REDUCED_TOTAL_FILTERS': {
      const filtersCount = state?.filters?.reduce((prev, cur) => {
        const reduceFunc = (p, c) => {
          if (typeof c === 'object') {
            if ('file' in c) {
              return p + c?.count ?? 0;
            }
          }

          return p + 1;
        };

        const includedLength = cur?.included?.value?.reduce(reduceFunc, 0) ?? 0;
        const excludedLength = cur?.excluded?.value?.reduce(reduceFunc, 0) ?? 0;

        return prev + includedLength + excludedLength;
      }, 0);

      return {
        ...state,
        totalFilters: filtersCount,
      };
    }

    case 'CREATE_USER_SEARCH': {
      const { _id } = action.payload;
      return {
        ...state,
        lastUserSearchId: _id,
        searchesHistory: {
          ...state.searchesHistory,
          data: [action.payload, ...state.searchesHistory.data],
          totalItems: state.searchesHistory.totalItems + 1,
        },
        shortSearchesHistory: {
          ...state.shortSearchesHistory,
          data: [action.payload, ...state.shortSearchesHistory.data],
          totalItems: state.shortSearchesHistory.totalItems + 1,
        },
      };
    }

    case 'SAVE_COMPANY_FROM_INSIGHTS':
    case 'SAVE_CONTACT_FROM_INSIGHTS': {
      const { userSearchId } = action.payload;

      if (!userSearchId) {
        return state;
      }

      return {
        ...state,
        searchesHistory: {
          ...state.searchesHistory,
          data: state.searchesHistory.map((search) => {
            if (search._id === userSearchId) {
              return {
                ...search,
                saved: search.saved ? search.saved + 1 : 1,
              };
            }
            return search;
          }),
        },
      };
    }

    case 'FAST_SEARCH_INSIGHT_CONTACTS_AND_COMPANIES': {
      return {
        ...state,
        fastSearchData: action.payload,
      };
    }

    case 'CLEAR_FAST_SEARCH_INSIGHT__CONTACTS_AND_COMPANIES': {
      return {
        ...state,
        fastSearchData: {},
      };
    }

    case 'SIGN_OUT':
    case 'SWITCH_WORKSPACE': {
      return initialState;
    }

    default:
      return state;
  }
};
