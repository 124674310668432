import {
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { useState } from 'react';
import SignUpPage from 'pages/authorization/signUp';
import LetterSendingPage from 'pages/authorization/passwordReset/LetterSendingPage';
import LoginPage from 'pages/authorization/login';
import InvitationPage from 'pages/invitation';
import PasswordResetPage from 'pages/authorization/passwordReset';
import AccountRestore from 'pages/authorization/accountRestore';
import ModalsController from 'components/modalsController';

const LoginLayout = () => {
  const [emailRstValue, setRstEmail] = useState('');
  const history = useHistory();
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Switch>
          <Route path="/sign-up" component={SignUpPage} />
          <Route path="/reset-letter" component={LetterSendingPage} />
          <Route exact path="/restore/:email/:token" component={AccountRestore} />
          <Route
            exact
            path="/login"
            render={() => {
              if (window.location.pathname !== '/login') history.push('/login');
              return <LoginPage emailFormData={setRstEmail} />;
            }}
          />
          <Route exact path="/login/invitation" component={InvitationPage} />
          <Route exact path="/password/reset" render={(props) => <PasswordResetPage match={props.match} history={props.history} emailRstValue={emailRstValue} />} />
          <Route exact path="/password/reset/:token" component={PasswordResetPage} />
          <Redirect to="/login" />
        </Switch>
        <ModalsController />
      </GoogleOAuthProvider>
    </GoogleReCaptchaProvider>
  );
};

export default LoginLayout;
