import React, { useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
} from 'antd';

import { Button, TextArea } from 'components/common';
import { getValueOfInput } from 'helpers/dom';
import { modalsSelector } from 'data/selectors/modals';
import { nspScoreFeedbackSelector } from 'data/selectors/user';
import { toggleModal } from 'data/actions/modals';
import { lastNspScoreFeedbackSelector } from 'data/selectors/loading';
import { saveFeedback, updateFeedback } from 'data/actions/user';
import Loader from 'components/loading';
import {
  CloseIcon,
} from '../baseModal/styles';
import {
  Title,
  Body,
  MarksContainer,
  MarkItem,
  MarkComment,
  MarkCommentsContainer,
  Footer,
  RemindMeLater,
} from './styles';

const marks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const ScoreNPSModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const nspScoreFeedback = useSelector(nspScoreFeedbackSelector);
  const isLoadingNpsScore = useSelector(lastNspScoreFeedbackSelector);

  const [score, setScore] = useState(0);
  const [feedbackId, setFeedbackId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [step, setStep] = useState('mark');

  const handleClose = () => {
    dispatch(toggleModal('score_nps', false));
    const expiryDate = (!isLoadingNpsScore && !nspScoreFeedback) || (nspScoreFeedback && moment(nspScoreFeedback?.expiryDate).diff(nspScoreFeedback?.createdAt, 'month') > 1)
      ? moment().add(7, 'day').format()
      : moment().add(3, 'month').format();
    dispatch(saveFeedback({ type: 'nps-score-remind', expiryDate }));
  };

  const onSuccessSaveFeedback = (data) => {
    if (data?._id) setFeedbackId(data?._id);
    setIsLoading(false);
    setStep('comment');
  };

  const handleSubmit = () => {
    if (step === 'mark') {
      setIsLoading(true);
      dispatch(saveFeedback({ type: 'nps-score', score }, onSuccessSaveFeedback));
    } else if (feedbackId) {
      dispatch(updateFeedback(feedbackId, { type: 'nps-score', score, comment }));
      dispatch(toggleModal('score_nps', false));
    } else {
      dispatch(saveFeedback({ type: 'nps-score', score, comment }));
      dispatch(toggleModal('score_nps', false));
    }
  };

  return (
    <Modal
      mask={false}
      visible={visible}
      title={null}
      closable={false}
      width={400}
      footer={false}
      style={{
        top: 'auto', bottom: '10px', right: '30px', marginTop: 'auto', position: 'absolute',
      }}
      className="nps-score-modal"
    >
      <Body>
        <CloseIcon
          onClick={handleClose}
          className="ic_close"
        />
        <Title>
          {
            step === 'mark'
              ? 'How likely are you to recommend us to your friends and colleagues?'
              : 'Thank you! One more question...  Why did you choose this score?'
          }
        </Title>
        {
          step === 'mark'
            ? (
              <>
                <MarksContainer>
                  {marks.map((item) => (
                    <MarkItem active={score === item} key={`nps-score-mark-${item}`} onClick={() => setScore(item)}>
                      {item}
                    </MarkItem>
                  ))}
                </MarksContainer>
                <MarkCommentsContainer>
                  <MarkComment>Not likely</MarkComment>
                  <MarkComment>Extremely likely</MarkComment>
                </MarkCommentsContainer>
              </>
            )
            : (
              <>
                <TextArea
                  autoFocus
                  autoSize={{
                    minRows: 3,
                    maxRows: 3,
                  }}
                  style={{
                    width: '100%',
                    resize: 'none',
                    minHeight: '85px !important',
                    marginTop: '30px',
                    maxHeight: '85px',
                    color: '#071427',
                  }}
                  value={comment}
                  onChange={getValueOfInput(setComment)}
                />
              </>
            )
        }
      </Body>
      <Footer>
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={isLoading || score < 1}
        >
          {isLoadingNpsScore || isLoading ? <Loader loading={isLoading} /> : 'Submit'}
        </Button>
        {step === 'mark' && <RemindMeLater onClick={handleClose}>Remind me later</RemindMeLater>}
      </Footer>
    </Modal>
  );
};

export default ScoreNPSModal;
