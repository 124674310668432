import { ACCOUNT_EVENTS } from 'data/types/event.types';
import { PageSizes } from 'data/types/tableMeta.types';
import { isEmpty, get } from 'lodash';
import jwt from 'jsonwebtoken';

const { workspaceId } = localStorage.getItem('accessToken') ? jwt.decode(localStorage.getItem('accessToken')) : { workspaceId: undefined };

const initialState = {
  emails: workspaceId && localStorage.getItem(`singleVerifications_${workspaceId}`) ? JSON.parse(localStorage.getItem(`singleVerifications_${workspaceId}`)) : [],
  preprocess: {
    loading: false,
    inProgress: false,
    loadedFile: {},
    fileName: '',
    fileUrl: '',
    emailColumns: [],
  },
  bulks: [],
  totalCount: 0,
  page: 1,
  pageSize: PageSizes.DEFAULT_VERIFICATIONS_PAGE_SIZE,
  events: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'VERIFICATIONS_ADD_BULK': {
      const bulks = [...state.bulks];
      bulks.unshift(action.data);
      if (bulks.length > state.pageSize) {
        bulks.pop();
      }
      state.totalCount += 1;
      return {
        ...state,
        bulks,
      };
    }
    case 'VERIFICATIONS_DELETE_BULK': {
      const bulkId = action.data;
      const newBulks = state.bulks.filter((bulk) => bulk._id !== bulkId);

      return {
        ...state,
        bulks: newBulks,
      };
    }
    case 'GET_VERIFICATIONS_BULKS': {
      const { totalCount, data: bulks } = action.payload;
      return {
        ...state,
        totalCount,
        bulks,
      };
    }
    case 'SET_VERIFICATIONS_PARAMS': {
      return {
        ...state,
        ...action.data,
      };
    }
    case 'SET_BULK_PREPROCESS': {
      const preprocess = { ...state.preprocess, ...action.data };
      return {
        ...state,
        preprocess,
      };
    }
    case 'SET_BULK_IN_PROGRESS': {
      const bulks = [...state.bulks];
      bulks.map((bulk) => {
        if (bulk._id === action.data.bulkId) {
          bulk.inProgress = action.data.status;
        }
        return bulk;
      });

      return {
        ...state,
        bulks,
      };
    }
    case 'SET_EMAILS_PROPERTY': {
      action.data.map((email) => {
        email.status = 'processing';
        return email;
      });

      return {
        ...state,
        emails: [...action.data, ...state.emails],
      };
    }
    case 'SYNC_EMAILS_PROPERTY': {
      return {
        ...state,
        emails: action.data,
      };
    }
    case ACCOUNT_EVENTS.EMAIL_STATUS_EVENT: {
      const { statusInstance } = JSON.parse(action.payload);
      const emails = [...state.emails];
      const instanceIndex = emails.findIndex((email) => email.id === statusInstance.id);
      if (instanceIndex !== -1) {
        Object.assign(emails[instanceIndex], statusInstance);
        localStorage.setItem(`singleVerifications_${workspaceId}`, JSON.stringify(emails));
      }

      return {
        ...state,
        emails,
      };
    }
    case ACCOUNT_EVENTS.VERIFICATION_FILE_EVENT: {
      const { statusInstance } = JSON.parse(action.payload);
      const bulks = [...state.bulks];

      bulks.map((bulk) => {
        const { bulkId, verificatedFileUrl, ...verificationInfo } = statusInstance;

        if (bulk._id === bulkId) {
          bulk.verificationInfo = verificationInfo;

          if (!isEmpty(verificatedFileUrl)) {
            bulk.verificatedFileUrl = verificatedFileUrl;
            bulk.inProgress = false;
          }
        }

        return bulk;
      });

      return {
        ...state,
        bulks,
      };
    }

    case ACCOUNT_EVENTS.FAST_SPRING_VERIFICATIONS_EVENT: {
      const data = JSON.parse(action.payload);
      const sameEventData = get(state, 'events', []).some((event) => event.bulkId === data?.bulkId);
      let updatedEvents = [...state?.events];

      if (!sameEventData) {
        updatedEvents = [...state?.events, { ...data }];
      }

      return {
        ...state,
        events: updatedEvents,
      };
    }

    case 'REMOVE_VERIFICATIONS_EVENTS': {
      const { items } = action.data;
      const updatedEvents = get(state, 'events', []).filter((event) => !items.includes(event?.bulkId));

      return {
        ...state,
        events: updatedEvents,
      };
    }

    case 'SIGN_OUT':
      return {
        ...initialState,
      };
    case 'SWITCH_WORKSPACE': {
      let { emails } = state;
      if (action.payload.token) {
        const key = localStorage.getItem(`singleVerifications_${jwt.decode(action.payload.token)?.workspaceId}`);
        if (key) {
          emails = JSON.parse(key);
        }
      }
      return {
        ...initialState,
        emails: emails || [],
      };
    }

    default:
      return state;
  }
};
