import moment from 'moment';
import { useEffect, useState } from 'react';

const useWorkTime = () => {
  // eslint-disable-next-line
  const [_, setUpdated] = useState(false);

  const worksFrom = 9;
  const worksUntil = 23;
  const time = moment().tz('Europe/Kiev');
  const day = time.day();
  const hours = time.hours();
  const minutes = time.minutes();

  const WEEKEND_DAYS = [6, 7];

  const areWorkersAvailable = !WEEKEND_DAYS.includes(day) && ((hours >= worksFrom && hours < worksUntil) || (hours === worksFrom && minutes > 0));

  const differenceInHoursFrom = hours - worksFrom;
  const differenceInHoursUntil = hours - worksUntil;

  const localTimeZone = moment.tz.guess();
  const localTimeFrom = moment().tz(localTimeZone);
  const localTimeUntil = moment().tz(localTimeZone);

  const localWorksFrom = differenceInHoursFrom > 0 ? localTimeFrom.subtract(differenceInHoursFrom, 'h').format('hh A:mm').split(':')[0] : localTimeFrom.add(Math.abs(differenceInHoursFrom), 'h').format('hh A:mm').split(':')[0];
  const localWorksUntil = differenceInHoursUntil > 0 ? localTimeUntil.subtract(differenceInHoursUntil, 'h').format('hh A:mm').split(':')[0] : localTimeUntil.add(Math.abs(differenceInHoursUntil), 'h').format('hh A:mm').split(':')[0];

  useEffect(() => {
    const interval = setInterval(() => {
      setUpdated((prev) => !prev);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return {
    areWorkersAvailable,
    worksFrom,
    worksUntil,
    localTimeZone,
    localWorksFrom,
    localWorksUntil,
  };
};

export default useWorkTime;
