import get from 'lodash/get';

export const getInvoices = ({
  pageSize, pageNumber, sort, onSuccess = () => {},
}) => (dispatch, getState) => {
  const state = getState();
  const currentPageSize = get(state, 'invoices.invoices.size');
  const currentPageNumber = get(state, 'invoices.invoices.page');

  const params = {
    pageSize: pageSize || currentPageSize,
    pageNumber: pageNumber || currentPageNumber,
  };

  if (sort) {
    params.sort = sort.column;
    params.order = sort.order;
  }

  dispatch({
    type: 'GET_INVOICES',
    payload: {
      endpoint: 'api/v1/invoices',
      method: 'GET',
      params,
    },
    components: ['invoiceLoader'],
    onSuccess,
  });
};

export const downloadInvoice = (objectId, invoiceId) => (dispatch) => {
  dispatch({
    type: 'DOWNLOAD_INVOICE',
    payload: {
      endpoint: `api/v1/invoices/download/${window.btoa(objectId)}`,
      method: 'GET',
      responseType: 'blob',
    },
    invoiceId,
  });
};

export const checkFSInvoice = () => (dispatch) => {
  dispatch({
    type: 'CHECK_FS_INVOICE',
    payload: {
      endpoint: 'api/v1/invoices/fs-check',
      method: 'GET',
    },
  });
};
