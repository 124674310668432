import fileDownloadByUrl from 'helpers/fileDownloadByUrl';

const initialState = {
  activeExports: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'EXPORT_DATA': {
      return {
        ...state,
        activeExports: [...state.activeExports, action.payload],
      };
    }
    case 'DOWNLOAD_EXPORT_FILE': {
      const { _id, url, name } = action.data;
      if (state.activeExports.some((item) => item._id === _id)) {
        fileDownloadByUrl(url, name);
        return {
          ...state,
          activeExports: state.activeExports.filter((item) => item._id !== _id),
        };
      }
      return state;
    }
    case 'SIGN_OUT':
    case 'SWITCH_WORKSPACE': {
      return initialState;
    }
    default:
      return state;
  }
};
