import React from 'react';
import {
  Modal,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';

import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { selectedPropertiesSelector } from 'data/selectors/properties';
import { restoreProperties } from 'data/actions/properties';

import {
  ModalTitle,
  Label,
  CloseIcon,
  Footer,
} from '../baseModal/styles';

const RestorePropertiesModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const selectedProperties = useSelector(selectedPropertiesSelector);
  const { setSelectedRowKeys } = useSelector(modalOptionsSelector);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Restore properties
          <CloseIcon
            onClick={() => dispatch(toggleModal('restore_properties', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={450}
      footer={false}
      onCancel={() => dispatch(toggleModal('restore_properties', false))}
    >
      <>
        <Label>
          {`Restore these ${selectedProperties.length} properties? `}
          This action can&apos;t be undone. The properties will be restored.
        </Label>
      </>
      <Footer>
        <Button
          data-form={!selectedProperties.length ? 'false' : 'true'}
          type="danger"
          size="large"
          onClick={() => {
            if (setSelectedRowKeys) setSelectedRowKeys([]);
            dispatch(restoreProperties(selectedProperties));
            dispatch(toggleModal('restore_properties', false));
          }}
          disabled={!selectedProperties.length}
        >
          Restore
        </Button>
        <Button
          size="large"
          type="default"
          onClick={() => dispatch(toggleModal('restore_properties', false))}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default RestorePropertiesModal;
