import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button } from 'components/common';
import { NewMailIcon } from 'components/newIcons';
import { emailResetPassword } from 'data/actions/user';
import TimerButton from 'components/common/timerButton';
import AuthContainer from 'pages/authorization/AuthContainer';
import { TIME_RELOAD_FORGOT_PASSWORD } from 'constants/timers';
import { formBtnStyle, Header, AuthForm } from '../../styles';
import { emailProviders } from '../../enums';

const LetterSendingPage = ({ history }) => {
  const dispatch = useDispatch();
  const email = history.location.state?.email;
  const providerPrefix = email?.slice(email.indexOf('@'), email.length);
  const link = emailProviders[providerPrefix];

  const handleResendEmail = () => {
    dispatch(emailResetPassword({ email }));
  };

  return (
    <AuthContainer>
      <AuthForm>
        <NewMailIcon />
        <Header> Check your inbox </Header>
        <p> We sent you a recovery link. </p>
        <p> Please, be sure to check your spam folder too. </p>
        {link
          && (
          <Button type="normal" style={formBtnStyle}>
            <a href={link} target="_blank" rel="noopener noreferrer nofollow">
              Go to Email
            </a>
          </Button>
          )}
        <p>
          Can&apos;t see the email?
          <TimerButton
            type="normal"
            text="Resend email"
            timeReload={TIME_RELOAD_FORGOT_PASSWORD}
            style={formBtnStyle}
            onClick={handleResendEmail}
          />
        </p>
        <Link to="/login">Back to sign in</Link>
      </AuthForm>
    </AuthContainer>
  );
};

export default LetterSendingPage;
