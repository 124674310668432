import get from 'lodash/get';
import React, { useState } from 'react';
import {
  Popover,
  Table,
  Icon,
} from 'antd';

import { Select, Option } from 'components/common';

import {
  PropertiesContainer,
  CustomFooterOption,
  PopupOption,
  PropertyItem,
  Label,
  OptionsButtonsContainer,
  TableSelectPropertiesWrapper,
} from '../../styles';

import options from './options';
import fields from '../../propertyFields';
import columns from './columns';

const PropertyType = ({
  property,
  updateProperty,
  setPropertyEnumerableData,
  propertyEnumerableData,
}) => {
  const [selectedPopupOption, setSelectedPopupOption] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      {
        fields.secondStep.map((item, indx) => (
          <PropertiesContainer key={`property_group_${indx + 1}`}>
            <PropertyItem>
              <Label>
                {item.label}
              </Label>
              <Select
                placeholder={item.placeholder}
                size="large"
                defaultValue="Choose an option"
                value={get(property, item.name)}
                onChange={(value) => {
                  const newProperty = { ...property };
                  newProperty[item.name] = value;
                  updateProperty(newProperty);
                }}
              >
                { item.options.map((option, index) => <Option value={option.value} key={`property_item_${index + 1}_option_${option.value}`}>{option.label}</Option>) }
              </Select>
            </PropertyItem>
          </PropertiesContainer>
        ))
      }
      {(['select', 'checkbox'].includes(property.fieldType)) && (
      <div>
        <TableSelectPropertiesWrapper>
          <Table
            columns={columns(propertyEnumerableData, setPropertyEnumerableData)}
            dataSource={propertyEnumerableData}
            scroll={{ x: 'max-content' }}
            rowKey={(record) => `table-selected-properties-${record.key}`}
            pagination={propertyEnumerableData?.length > 10}
            size="middle"
          />
        </TableSelectPropertiesWrapper>

        <OptionsButtonsContainer>
          <CustomFooterOption
            onClick={() => {
              const data = [...propertyEnumerableData];
              data.push({ internalValue: null, label: null, key: data.length });
              setPropertyEnumerableData(data);
            }}
          >
            <Icon type="plus" />
            Add an option
          </CustomFooterOption>
          <Popover
            onVisibleChange={(value) => setShowPopup(value)}
            visible={showPopup}
            style={{ width: '160px' }}
            content={(
              <div>
                <Select
                  style={{ width: '172px' }}
                  size="default"
                  defaultValue="Choose options"
                  onChange={(value) => {
                    setSelectedPopupOption(options.find((item) => item.name === value).data.map((item, i) => ({ label: item.label, internalValue: item.value, key: i })));
                  }}
                >
                  { options.map((option, index) => <Option key={`create-property-bar-option${index + 1}`} value={option.name}>{option.name}</Option>) }
                </Select>
                <PopupOption disabled={!selectedPopupOption} onClick={() => { setPropertyEnumerableData(selectedPopupOption); setShowPopup(false); }}>Load options</PopupOption>
                <PopupOption onClick={() => { setShowPopup(false); }}>Close</PopupOption>

              </div>
            )}
            title="Load options"
            trigger="click"
          >
            <CustomFooterOption>
              <Icon type="bars" />
              Load options...
            </CustomFooterOption>
          </Popover>
          <CustomFooterOption
            onClick={() => {
              setPropertyEnumerableData([{ internalValue: null, label: null, key: 0 }]);
            }}
          >
            <Icon type="delete" theme="filled" />
            Clear all
          </CustomFooterOption>
        </OptionsButtonsContainer>
      </div>
      )}
    </>
  );
};

export default PropertyType;
