export const createListFilter = (listId) => ({
  property: 'listRelations',
  included: {
    operator: 'CONTAINS_ANY',
    value: [listId],
  },
});

export const createImportFilter = (importId) => ({
  property: 'import',
  operator: 'EQ',
  value: importId,
});

export const createOnlyIncludedFilter = ({ property, operator, value }) => ({
  property,
  included: {
    operator,
    value,
  },
});

export const createEmailSearchFilter = (status) => ({
  property: 'email',
  included: {
    operator: 'EMAIL_STATUS',
    value: [status],
  },
});

export const generateContactFilters = ({
  companyId,
  position,
  location,
  department,
  departments,
}) => {
  const filters = [];
  if (companyId) {
    filters.push({
      included: { operator: 'CONTAINS_ANY', value: [companyId] },
      property: 'companyRelations.company',
    });
  }
  if (position) {
    filters.push({
      operator: 'LIKE',
      value: position,
      property: 'companyRelations.position',
    });
  }
  if (department) {
    const foundDepartment = departments.find(({ name }) => name === department);
    if (foundDepartment) {
      filters.push({
        operator: 'LIKE',
        value: foundDepartment.children.map((child) => child.keywords).flat(),
        property: 'companyRelations.position',
      });
    }
  }
  if (location) {
    filters.push({
      operator: 'LIKE',
      value: location,
      property: 'location',
    });
  }
  return filters;
};
