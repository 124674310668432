import get from 'lodash/get';
import { createSelector } from 'reselect';

const baseNotificationsSelector = (state) => get(state, 'notifications', {});
// eslint-disable-next-line import/prefer-default-export
export const notificationsSelector = createSelector(
  baseNotificationsSelector,
  (notifications) => get(notifications, 'notifications.data', []),
);

export const metaNotificationsSelector = createSelector(
  baseNotificationsSelector,
  (notifications) => {
    const totalPages = get(notifications, 'notifications.totalPages', 0);
    const page = get(notifications, 'notifications.page', 0);
    const pageSize = get(notifications, 'notifications.pageSize', 0);
    const unreadCount = get(notifications, 'notifications.unreadCount', 0);
    return {
      page,
      pageSize,
      totalPages,
      unreadCount,
    };
  },
);

export const popUpNotificationSelector = createSelector(
  baseNotificationsSelector,
  (notifications) => get(notifications, 'popUpNotification', null),
);
