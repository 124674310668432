import styled from 'styled-components';
import { COLORS } from 'constants/styles';

import arrowLeft from 'assets/images/keyboard-arrow-left.svg';

export const Container = styled.div`
  z-index: 3;
`;

export const SitesContainer = styled.div`
  display: flex;
  margin-right: 60px;
`;

export const Sites = styled.div`
  display: flex;
  overflow-x: hidden;
  overflow-y: none;
  margin: 0 20px;
`;

export const SiteContainer = styled.div`
  flex: 0 0 100%;
`;

export const SiteText = styled.p`
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: ${COLORS.DARKEST_BLUE};
  margin-bottom: 25px;
`;

export const KeyboardArrow = styled.img.attrs(() => ({
  src: arrowLeft,
}))`
  cursor: pointer;
  transform: ${(props) => (props.side === 'right' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const ImageCite = styled.img`
  border-radius: 50%;
  width: 45px;
  height: 45px;
`;

export const ImageCompany = styled.img`
  width: 116px;
  height: 35px;
  margin-left: 60px;
  mix-blend-mode: luminosity;
  opacity: 0.35;
`;

export const PersonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

export const PersonName = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.BLACK_G1};
`;

export const PersonPosition = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${COLORS.GREY_G1};
`;
