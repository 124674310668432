import React from 'react';
import { Progress } from 'antd';
import { Button, Icon } from 'components/common';
import { upperCaseFirst } from 'helpers/upperCaseFirst';
import {
  CloseMessageImg,
  MessageHeaderWrapper,
  MessageContainer,
  MessageTextWrapper,
  MessageButtonWrapper,
  MessageContentWrapper,
  MessageHeader,
} from './styles';

const DefaultMessageTemplate = ({ data }) => {
  const {
    type,
    text,
    header,
    close,
    link,
    primaryButtonOptions,
    secondaryButtonOptions,
    ref,
    typeColor,
    icon,
    visible,
    progress,
    togglePauseProgress,
    handleButtonAction,
    isHTML,
  } = data;

  return (
    <MessageContainer
      className="allow-demo-click"
      visible={visible}
      ref={ref}
      onMouseEnter={() => togglePauseProgress(true)}
      onMouseLeave={() => togglePauseProgress(false)}
    >
      <MessageTextWrapper>
        <MessageHeaderWrapper>
          <div style={{ marginTop: 2 }}>
            {typeof icon === 'string' ? <Icon type={icon} fill={typeColor} /> : icon}
          </div>
          <MessageHeader style={{ marginLeft: typeof icon === 'string' ? 10 : 0 }}>{header || upperCaseFirst(type)}</MessageHeader>
          <CloseMessageImg onClick={close} />
        </MessageHeaderWrapper>
        {(text || (link?.url && link?.text)) && (
          <MessageContentWrapper>
            {/* eslint-disable-next-line */}
            {isHTML ? <div dangerouslySetInnerHTML={{ __html: text }} /> : text}
            {!!link && !!link.url && !!link.text ? (
              <a style={{ marginLeft: '5px' }} href={link.url}>
                {link.text}
              </a>
            ) : <></>}
          </MessageContentWrapper>
        )}
        {(primaryButtonOptions || secondaryButtonOptions) && (
          <MessageButtonWrapper>
            {primaryButtonOptions && <Button style={{ color: typeColor, borderColor: typeColor, marginRight: 10 }} type="normal" onClick={() => handleButtonAction(primaryButtonOptions)}>{primaryButtonOptions.text}</Button>}
            {secondaryButtonOptions && <Button type="normal" onClick={() => handleButtonAction(secondaryButtonOptions)}>{secondaryButtonOptions.text}</Button>}
          </MessageButtonWrapper>
        )}
      </MessageTextWrapper>
      <Progress percent={progress} strokeWidth={2} showInfo={false} strokeColor={typeColor} trailColor="#dfe2e5" strokeLinecap="square" />
    </MessageContainer>
  );
};

export default DefaultMessageTemplate;
