import React, { useState } from 'react';

import useTimer from 'hooks/useTimer';
import { Text } from './styles';

const TimerText = ({
  children,
  onClick,
  timeReload,
  style,
  disabled,
  startZero,
  maxAttempts,
  Component = Text,
}) => {
  const [attempts, setAttempts] = useState(0);
  const [time, resetTime] = useTimer({ timeReload, startZero });
  const isDisabled = time !== 0 || disabled;

  const onClickProxy = () => {
    if (isDisabled) return;
    if (attempts === maxAttempts || !maxAttempts) {
      resetTime();
    }
    if (onClick && time === 0) {
      onClick();
    }
    if (maxAttempts) setAttempts(attempts === maxAttempts ? 0 : attempts + 1);
  };

  return (
    <Component onClick={onClickProxy} style={style} disabled={time !== 0 || disabled}>
      {children}
      &nbsp;
      {
        time !== 0 ? (
          <>
            (
            {time}
            )
          </>
        ) : null
      }
    </Component>
  );
};

export default TimerText;
