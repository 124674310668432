import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { SOURCE_TYPE } from 'data/types/source.types';
import { deleteProperties } from 'data/actions/properties';
import { updateWorkspaceSettings } from 'data/actions/user';
import { exportColumnsConfigurationSelector } from 'data/selectors/user';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { propertiesSelector, selectedPropertiesSelector } from 'data/selectors/properties';
import {
  ModalTitle,
  Label,
  CloseIcon,
  Footer,
} from '../baseModal/styles';

const DeletePropertiesModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const properties = useSelector(propertiesSelector);
  const selectedProperties = useSelector(selectedPropertiesSelector);
  const currentColumns = useSelector(exportColumnsConfigurationSelector);
  const { sourceType, setSelectedRowKeys, existedDefaultProperties } = useSelector(modalOptionsSelector);

  const getUpdatedRequest = () => {
    const configurationSourceType = sourceType === SOURCE_TYPE.CONTACT ? 'contacts' : 'companies';
    const futureProperties = properties.filter((property) => currentColumns[configurationSourceType].some((column) => property.name === column.propertyName) && !selectedProperties.includes(property._id));
    const exportColumnsConfiguration = {
      ...currentColumns,
      [configurationSourceType]: futureProperties.map((item) => ({ propertyName: item.name, sourceType })),
    };
    return { exportColumnsConfiguration };
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Delete properties
          <CloseIcon
            onClick={() => dispatch(toggleModal('delete_properties', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={450}
      footer={false}
      onCancel={() => dispatch(toggleModal('delete_properties', false))}
      closeIcon={<div />}
    >
      <>
        <Label>
          {`Delete these ${selectedProperties.length} properties? `}
          This action can&apos;t be undone. The properties will be deleted.
        </Label>
      </>
      <Footer>
        <Button
          data-form={selectedProperties.length ? 'true' : 'false'}
          type="danger"
          size="large"
          onClick={() => {
            if (setSelectedRowKeys) setSelectedRowKeys([]);
            dispatch(deleteProperties(selectedProperties, () => {
              dispatch(updateWorkspaceSettings(getUpdatedRequest()));
              dispatch(toggleModal('delete_properties', false));
              if (existedDefaultProperties) {
                dispatch(toggleMessage('warning', { header: "Default properties weren't deleted" }));
              }
            }));
          }}
          disabled={!selectedProperties.length}
        >
          Delete
        </Button>
        <Button
          size="large"
          type="default"
          onClick={() => dispatch(toggleModal('delete_properties', false))}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default DeletePropertiesModal;
