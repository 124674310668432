export const getCards = () => (dispatch) => {
  dispatch({
    type: 'GET_ALL_CARDS',
    payload: {
      endpoint: 'api/v1/payment/cards',
      method: 'GET',
      delayLoad: 0,
    },
    components: ['cardsLoading'],
  });
};

export const deleteCard = (cardId, onSuccess = () => {}) => (dispatch) => {
  dispatch({
    type: 'DELETE_CARD',
    payload: {
      endpoint: `api/v1/payment/cards/${cardId}`,
      method: 'DELETE',
    },
    onSuccess: (data) => onSuccess(data),
    cardId,
  });
};

export const getBluesnapPaymentFieldToken = (onSuccess, onError) => (dispatch) => {
  dispatch({
    type: 'GET_PAYMENT_FIELD_TOKEN',
    payload: {
      endpoint: 'api/v1/payment/cards/bluesnap/payment-fields-token',
      method: 'GET',
      delayLoad: 0,
    },
    components: ['cardsLoading'],
    onSuccess: (data) => onSuccess(data),
    onFailed: (error) => onError(error),
  });
};

export const saveBluesnapCard = (card, onSuccess, onError) => (dispatch) => {
  dispatch({
    type: 'SAVE_BLUESNAP_CARD',
    payload: {
      endpoint: 'api/v1/payment/cards/bluesnap',
      method: 'POST',
      body: {
        ...card,
      },
      delayLoad: 0,
    },
    components: ['cardsLoading'],
    onSuccess: (data) => onSuccess(data),
    onFailed: (error) => onError(error),
  });
};

export const getFondyPaymentFieldToken = () => (dispatch) => {
  dispatch({
    type: 'GET_PAYMENT_FIELD_TOKEN',
    payload: {
      endpoint: 'api/v1/payment/cards/fondy/payment-fields-token',
      method: 'GET',
      delayLoad: 0,
    },
    components: ['cardsLoading'],
  });
};

export const saveFondyCard = (card, onSuccess) => (dispatch) => {
  dispatch({
    type: 'SAVE_FONDY_CARD',
    payload: {
      endpoint: 'api/v1/payment/cards/fondy',
      method: 'POST',
      body: {
        ...card,
      },
      delayLoad: 0,
    },
    components: ['cardsLoading'],
    onSuccess: (data) => onSuccess(data),
  });
};

export const getMerchant = () => (dispatch) => {
  dispatch({
    type: 'GET_MERCHANT',
    payload: {
      endpoint: 'api/v1/payment/cards/fondy/merchant',
      method: 'GET',
      delayLoad: 0,
    },
    components: ['cardsLoading'],
  });
};

export const getTax = (state, sku, onSuccess) => (dispatch) => {
  dispatch({
    type: 'GET_TAX',
    payload: {
      endpoint: `api/v1/payment/cards/bluesnap/tax?state=${state}&sku=${sku}`,
      method: 'GET',
      delayLoad: 0,
    },
    components: ['cardsLoading'],
    onSuccess: (data) => onSuccess(data),
  });
};

export const setCheckoutStatus = (status) => (dispatch) => {
  dispatch({
    type: 'SET_CHECKOUT_STATUS',
    data: status,
  });
};

export const setAccountCard = (cardId) => (dispatch) => {
  dispatch({
    type: 'SET_ACCOUNT_CARD',
    payload: {
      endpoint: `api/v1/payment/cards/${cardId}`,
      method: 'PATCH',
      delayLoad: 0,
    },
    components: ['cardsLoading'],
  });
};
