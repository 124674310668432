const startYear = 1940;
const finishYear = new Date().getFullYear();

const YearOption = {
  name: 'Year',
  data: [],
};

for (let groupIndex = startYear; groupIndex < finishYear + 1; groupIndex += 1) {
  YearOption.data.push({
    label: `${groupIndex}`,
    value: `${groupIndex}`,
  });
}

export default YearOption;
