import get from 'lodash/get';
import { nanoid } from 'nanoid';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

import { PageSizes } from 'data/types/tableMeta.types';
import {
  SEQUENCE_DRAFT_STATUS,
  SEQUENCE_MESSAGE_STATUS_TYPE,
  SEQUENCE_STATUS_TYPE,
  STEP_STATUS,
} from 'data/types/sequences.types';
import { addFolderToParent, deleteFolderFromParent, getFolderById } from 'helpers/folderTree';

const leadsInitialState = {
  data: [],
  totalItems: 0,
  pageSize: PageSizes.DEFAULT_SEQUENCE_LEADS_PAGE_SIZE,
  page: 1,
  sort: {
    column: '',
    order: '',
  },
  search: {},
  filters: [],
  statuses: {
    sent: 0,
    opened: 0,
    clicked: 0,
    replied: 0,
    bounced: 0,
    'opt-out': 0,
    invalid: 0,
  },
  selectAll: false,
  selectedStatus: null,
  initialRequest: true,
};

const initialState = {
  templateTeammates: [],
  sequences: {
    data: [],
    totalItems: 0,
    pageSize: PageSizes.DEFAULT_SEQUENCES_PAGE_SIZE,
    page: 1,
    sort: {
      column: '',
      order: '',
    },
    search: {
      properties: ['name'],
      value: '',
    },
    filters: [],
    initialRequest: true,
  },
  dashboardSequences: {
    data: [],
    totalItems: 0,
    pageSize: 5,
    page: 1,
    initialRequest: true,
  },
  selectedLeads: [],
  optOutContacts: {
    data: [],
    totalItems: 0,
    pageSize: PageSizes.DEFAULT_SEQUENCES_PAGE_SIZE,
    page: 1,
    sort: {
      column: '',
      order: '',
    },
    search: {
      properties: ['email'],
      value: '',
    },
    initialRequest: true,
  },
  schedules: {
    data: [],
    saved: true,
    lastSchedule: null,
  },
  selectedSequence: {},
  stepTemplates: {
    data: [],
    filters: {},
  },
  saved: true,
  isAdditingLeads: false,
  contactSequences: {},
  folders: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_CONTACTS_TO_SEQUENCE': {
      return {
        ...state,
        contactSequences: isEmpty(state.contactSequences) ? {} : {
          ...state.contactSequences,
          data: state.contactSequences.data.filter((sequence) => sequence._id !== action.sequenceId),
        },
      };
    }
    case 'SEARCH_CONTACT_SEQUENCES': {
      return {
        ...state,
        contactSequences: {
          ...action.payload,
          data: action.payload.data.filter((sequence) => !action.sequences.some((item) => item._id === sequence._id)),
        },
      };
    }
    case 'SET_ADDITING_LEADS': {
      return {
        ...state,
        isAdditingLeads: action.data.status,
      };
    }
    case 'SET_CUSTOMIZE_EMAIL': {
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          customizeEmail: {
            ...(state.selectedSequence.customizeEmail || {}),
            ...action.data,
          },
        },
      };
    }
    case 'TOGGLE_SELECT_ALL_SEQUENCE_LEADS': {
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          leads: {
            ...state.selectedSequence.leads,
            selectAll: action.data.value,
          },
        },
      };
    }
    case 'SET_LEAD_REVIEW': {
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          leadReview: action.data,
          customizeEmail: {
            ...(state.selectedSequence.customizeEmail || {}),
            wholeSequence: !state.selectedSequence.activeStep.contacts.find((item) => item.contact === action.data.lead?._id),
          },
        },
      };
    }
    case 'UPDATE_SEQUENCE_DRAFT_STATUS': {
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          status: action.draftStatus === SEQUENCE_DRAFT_STATUS.DONE ? SEQUENCE_STATUS_TYPE.ACTIVE : SEQUENCE_STATUS_TYPE.DRAFT,
          draftStatus: action.draftStatus,
        },
      };
    }
    case 'SET_SAVED_SEQUENCE': {
      return {
        ...state,
        saved: action.data.saved,
      };
    }
    case 'GET_TEMPLATE_TEAMMATES': {
      return {
        ...state,
        templateTeammates: action.payload,
      };
    }
    case 'ADD_OPT_OUT_CONTACTS': {
      return {
        ...state,
        optOutContacts: {
          ...state.optOutContacts,
          data: [...action.payload.map((item) => ({ ...item, source: 'Me' })), ...state.optOutContacts.data],
        },
      };
    }
    case 'DELETE_OPT_OUT_CONTACTS': {
      const { emails } = action;
      return {
        ...state,
        optOutContacts: {
          ...state.optOutContacts,
          data: state.optOutContacts.data.filter((contact) => !emails.includes(contact.email)),
        },
      };
    }
    case 'GET_OPT_OUT_CONTACTS': {
      return {
        ...state,
        optOutContacts: {
          data: action.payload.data,
          ...action.payload.meta,
          initialRequest: false,
        },
      };
    }
    case 'GET_SEQUENCES': {
      const { filters = [], search = {} } = action;
      return {
        ...state,
        sequences: {
          data: action.payload.data,
          ...action.payload.meta,
          filters: [
            ...filters,
          ],
          search: {
            ...search,
          },
          initialRequest: false,
        },
      };
    }
    case 'GET_DASHBOARD_SEQUENCES': {
      return {
        ...state,
        dashboardSequences: {
          data: action.payload.data,
          ...action.payload.meta,
          initialRequest: false,
        },
      };
    }
    case 'GET_SEQUENCES_ANALYTICS': {
      const sequencesAnalytics = action.payload;
      const updateSequence = (sequence) => {
        const sequenceAnalytics = sequencesAnalytics.find((item) => item._id === sequence._id);
        if (sequenceAnalytics) {
          return {
            ...sequence,
            ...sequenceAnalytics,
            totalMessages: sequence.steps.length * sequenceAnalytics.leads,
          };
        }
        return {
          ...sequence,
          totalMessages: 0,
          leads: 0,
          sent: 0,
          opened: 0,
          clicked: 0,
          replied: 0,
        };
      };

      return {
        ...state,
        dashboardSequences: {
          ...state.dashboardSequences,
          data: state.dashboardSequences.data.map(updateSequence),
        },
        sequences: {
          ...state.sequences,
          data: state.sequences.data.map(updateSequence),
        },
      };
    }
    case 'GET_SEQUENCE_RELATION_ANALYTICS': {
      const sequencesAnalytics = action.payload;
      const updateSequence = (item) => {
        const sequenceAnalytics = sequencesAnalytics.find((_item) => _item._id === item.sequence._id);
        if (sequenceAnalytics) {
          return {
            ...item,
            sequence: {
              ...item.sequence,
              ...sequenceAnalytics,
              totalMessages: item.sequence.steps.length * sequenceAnalytics.leads,
            },
          };
        }
        return {
          ...item,
          sequence: {
            ...item.sequence,
            totalMessages: 0,
            leads: 0,
            sent: 0,
            opened: 0,
            clicked: 0,
            replied: 0,
          },
        };
      };

      return {
        ...state,
        foldersRelation: state.foldersRelation.map(updateSequence),
      };
    }
    case 'SET_ENROLLMENT_DATE': {
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          stats: {
            ...state.selectedSequence.stats,
            enrollmentDate: {
              ...state.selectedSequence.stats.enrollmentDate,
              ...action.data,
            },
          },
        },
      };
    }
    case 'SET_ENROLLMENT_CONTACTS': {
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          stats: {
            ...state.selectedSequence.stats,
            enrollmentContacts: action.data,
          },
        },
      };
    }
    case 'GET_SEQUENCE_SENT_MAILS': {
      const updatedSteps = state.selectedSequence.steps.map((step, index) => {
        if (action.payload.stepStatuses[index]) {
          return {
            ...step,
            status: action.payload.stepStatuses[index],
          };
        }
        return step;
      });
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          steps: updatedSteps,
          activeStep: updatedSteps.find((step) => {
            if (step._id === state.selectedSequence.activeStep._id) {
              return true;
            }
            return false;
          }),
        },
      };
    }
    case 'GET_SEQUENCE_STATS_ENGAGED_AUDIENCE': {
      const columns = action.payload;
      const { filterBy } = action;
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          stats: {
            ...state.selectedSequence.stats,
            engagedAudience: {
              data: columns,
              filterBy,
            },
          },
        },
      };
    }
    case 'GET_SEQUENCE_STATS_AUDIENCE_DETAILS': {
      const { filterBy } = action;
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          stats: {
            ...state.selectedSequence.stats,
            audienceDetails: {
              data: action.payload,
              filterBy,
            },
          },
        },
      };
    }
    case 'GET_SEQUENCE_LEADS_STATS': {
      const [stats] = action.payload;
      if (stats) {
        delete stats._id;
        return {
          ...state,
          selectedSequence: {
            ...state.selectedSequence,
            leads: {
              ...state.selectedSequence.leads,
              statuses: {
                ...(state.selectedSequence.leads.statuses || {}),
                ...stats,
                totalMessages: state.selectedSequence.steps.length * stats.leads,
              },
            },
          },
        };
      }
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          leads: {
            ...state.selectedSequence.leads,
            statuses: action.contactStatusFields.reduce((obj, status) => {
              obj[status] = 0;
              return obj;
            }, {}),
          },
        },
      };
    }
    case 'RESUME_SEQUENCES': {
      const { pending } = action.payload;
      const { sequenceIds } = action;

      if (pending) return state;
      return {
        ...state,
        sequences: {
          ...state.sequences,
          data: state.sequences.data.map((sequence) => {
            const isResumed = sequenceIds.some((sequenceId) => sequenceId === sequence._id);
            if (isResumed) return { ...sequence, status: SEQUENCE_STATUS_TYPE.ACTIVE };
            return sequence;
          }),
        },
      };
    }
    case 'PAUSE_SEQUENCES': {
      const { pending } = action.payload;
      const { sequenceIds } = action;

      if (pending) return state;
      return {
        ...state,
        sequences: {
          ...state.sequences,
          data: state.sequences.data.map((sequence) => {
            const isResumed = sequenceIds.some((sequenceId) => sequenceId === sequence._id);
            if (isResumed) return { ...sequence, status: SEQUENCE_STATUS_TYPE.PAUSED };
            return sequence;
          }),
        },
      };
    }
    case 'GET_SEQUENCE_STATS': {
      const [stats] = action.payload;
      if (stats) {
        delete stats._id;
        return {
          ...state,
          selectedSequence: {
            ...state.selectedSequence,
            stats: {
              ...state.selectedSequence.stats,
              statuses: {
                ...state.selectedSequence.stats.statuses,
                ...stats,
                totalMessages: state.selectedSequence.steps.length * stats.leads,
              },
            },
          },
        };
      }
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          stats: {
            ...state.selectedSequence.stats,
            statuses: action.contactStatusFields.reduce((obj, status) => {
              obj[status] = 0;
              return obj;
            }, {}),
          },
        },
      };
    }
    case 'GET_SEQUENCE_STEPS_STATS': {
      const { steps } = state.selectedSequence;
      const getEmptyVersion = () => ({
        bounced: 0,
        clicked: 0,
        opened: 0,
        'opt-out': 0,
        replied: 0,
        invalid: 0,
        sent: 0,
        interested: 0,
      });
      const statsSteps = steps.map((step, foundStepIndex) => {
        const foundVersions = step.versions;
        const foundVersionsKeys = Object.keys(foundVersions);
        const foundPartStep = action.payload.find((item) => item._id === step._id) || { versions: [] };
        const baseStatuses = [
          SEQUENCE_MESSAGE_STATUS_TYPE.SENT,
          SEQUENCE_MESSAGE_STATUS_TYPE.SCHEDULED,
          SEQUENCE_MESSAGE_STATUS_TYPE.REPLIED,
          SEQUENCE_MESSAGE_STATUS_TYPE.CLICKED,
          SEQUENCE_MESSAGE_STATUS_TYPE.INTERESTED,
          SEQUENCE_MESSAGE_STATUS_TYPE.OPENED,
          SEQUENCE_MESSAGE_STATUS_TYPE.BOUNCED,
          SEQUENCE_MESSAGE_STATUS_TYPE['OPT-OUT'],
          SEQUENCE_MESSAGE_STATUS_TYPE.INVALID,
        ];
        const versions = cloneDeep(foundPartStep.versions);
        const firstActiveVersion = foundVersionsKeys.find((versionKey) => !foundVersions[versionKey].disabled);
        return {
          _id: step._id,
          name: foundVersions[firstActiveVersion]?.subject,
          versions: foundVersionsKeys
            .map((version) => {
              const versionAnalytics = foundPartStep.versions.find((item) => version === item.name) || getEmptyVersion();
              return {
                subject: foundVersions[version]?.subject,
                name: version,
                disabled: foundVersions[version]?.disabled || false,
                statuses: { ...versionAnalytics },
              };
            }),
          stepId: foundStepIndex + 1,
          statuses: baseStatuses.reduce((obj, status) => {
            const accumulatedStatus = versions
              .map((item) => item[status])
              .reduce((accumulatedValue, value) => accumulatedValue + value, 0);
            obj[status] = Number.isNaN(accumulatedStatus) ? 0 : accumulatedStatus;
            return obj;
          }, {}),
        };
      });
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          stats: {
            ...state.selectedSequence.stats,
            steps: {
              data: statsSteps,
            },
          },
        },
      };
    }
    case 'GET_SEQUENCE_FOLDERS':
      return { ...state, folders: action.payload };
    case 'GET_COUNT_SENT_MAILS_PER_DAY': {
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          countSentMailsPerDay: action.payload.count,
        },
      };
    }
    case 'CREATE_SEQUENCE':
    case 'GET_SELECTED_SEQUENCE': {
      const data = (action.payload || action.data);
      const selectedSequence = {
        ...data,
        settings: {
          ...data.settings,
          saved: true,
        },
        activeStep: action.keepLeads ? {
          ...data.steps.find((item) => item._id === state.selectedSequence.activeStep._id),
          activeVersion: 'A',
        } : {
          ...data.steps[0],
          activeVersion: 'A',
        },
        steps: data.steps.map((step) => ({
          ...step,
          activeVersion: Object.keys(step.versions).find((letter) => !step.versions[letter].disabled),
          saved: true,
        })),
        leads: action.keepLeads ? state.selectedSequence.leads : { ...leadsInitialState },
        leadReview: action.keepLeads ? state.selectedSequence.leadReview : null,
        customizeEmail: action.keepLeads ? state.selectedSequence.customizeEmail : {
          editMode: false,
          wholeSequence: true,
        },
        stats: action.keepLeads ? state.selectedSequence.stats : {
          statuses: {
            sent: 0,
            opened: 0,
            clicked: 0,
            replied: 0,
            bounced: 0,
            'opt-out': 0,
            invalid: 0,
          },
        },
      };
      const sequences = { ...state.sequences };
      if (action.type === 'CREATE_SEQUENCE') {
        sequences.data = [data, ...sequences.data];
        sequences.totalItems += 1;
      }
      return {
        ...state,
        sequences,
        selectedSequence,
        isAdditingLeads: false,
      };
    }
    case 'REMOVE_STEP': {
      const { stepId } = action;
      const indexRemovedStep = state.selectedSequence.steps.findIndex((step) => step._id === stepId);
      const steps = state.selectedSequence.steps.filter((step) => step._id !== stepId);
      let { activeStep } = state.selectedSequence;
      if (steps.length > 0) {
        if (activeStep?._id === stepId) {
          activeStep = steps[indexRemovedStep] || steps[indexRemovedStep - 1] || steps[indexRemovedStep + 1];
        }
      }
      return { ...state, selectedSequence: { ...state.selectedSequence, steps, activeStep } };
    }
    case 'UPDATE_STEP_REMOTELY': {
      const { stepId, version } = action;

      const foundStep = state.selectedSequence.steps.find((step) => {
        if (step._id === stepId) {
          return true;
        }
        return false;
      });

      let updatedStats = state.selectedSequence.stats;
      if (updatedStats?.steps?.data) {
        updatedStats = {
          ...state.selectedSequence.stats,
          steps: {
            ...state.selectedSequence.stats.steps,
            data: state.selectedSequence.stats.steps.data.map((step) => {
              if (step._id === stepId) {
                return {
                  ...step,
                  _id: step._id,
                  name: version === 'A' ? foundStep?.versions?.A?.subject : step.name,
                  versions: step.versions
                    .map((item) => {
                      if (version === item.name) {
                        return {
                          ...item,
                          subject: foundStep?.versions[version]?.subject,
                        };
                      }
                      return item;
                    }),
                };
              }
              return step;
            }),
          },
        };
      }

      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          steps: state.selectedSequence.steps.map((step) => {
            if (step._id === stepId) {
              return {
                ...step,
                saved: true,
              };
            }
            return step;
          }),
          stats: updatedStats,
        },
      };
    }
    case 'ADD_ATTACHMENTS_TO_STEP': {
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          steps: state.selectedSequence.steps.map((step) => {
            if (step._id === action.stepId) {
              return {
                ...step,
                ...(action.contactId ? {
                  contacts: step.contacts.map((item) => {
                    if (item.contact === action.contactId) {
                      return {
                        ...item,
                        versions: {
                          ...item.versions,
                          [action.version]: {
                            ...item.versions[action.version],
                            attachments: action.resetAttachments ? action.payload : [...action.payload, ...(item.versions[action.version].attachments || [])],
                          },
                        },
                      };
                    }
                    return item;
                  }),
                } : {
                  versions: {
                    ...step.versions,
                    [action.version]: {
                      ...step.versions[action.version],
                      attachments: action.resetAttachments ? action.payload : [...action.payload, ...(step.versions[action.version].attachments || [])],
                    },
                  },
                }),
              };
            }
            return step;
          }),
          activeStep: state.selectedSequence.activeStep?._id === action.stepId ? {
            ...state.selectedSequence.activeStep,
            ...(action.contactId ? {
              contacts: state.selectedSequence.activeStep.contacts.map((item) => {
                if (item.contact === action.contactId) {
                  return {
                    ...item,
                    versions: {
                      ...item.versions,
                      [action.version]: {
                        ...item.versions[action.version],
                        attachments: action.resetAttachments ? action.payload : [...action.payload, ...(item.versions[action.version].attachments || [])],
                      },
                    },
                  };
                }
                return item;
              }),
            } : {
              versions: {
                ...state.selectedSequence.activeStep.versions,
                [action.version]: {
                  ...state.selectedSequence.activeStep.versions[action.version],
                  attachments: action.resetAttachments ? action.payload : [...action.payload, ...(state.selectedSequence.activeStep.versions[action.version].attachments || [])],
                },
              },
            }),
          } : state.selectedSequence.activeStep,
        },
      };
    }
    case 'DELETE_ATTACHMENTS_FROM_STEP': {
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          steps: state.selectedSequence.steps.map((step) => {
            if (step._id === action.stepId) {
              return {
                ...step,
                ...(action.contactId ? {
                  contacts: step.contacts.map((item) => {
                    if (item.contact === action.contactId) {
                      return {
                        ...item,
                        versions: {
                          ...item.versions,
                          A: {
                            ...item.versions.A,
                            attachments: (item.versions.A.attachments || []).filter(({ _id }) => !action.attachmentIds.includes(_id)),
                          },
                        },
                      };
                    }
                    return item;
                  }),
                } : {
                  versions: Object.keys(step.versions).reduce((obj, version) => {
                    obj[version] = step.versions[version];
                    obj[version].attachments = (step.versions[version].attachments || []).filter(({ _id }) => !action.attachmentIds.includes(_id));
                    return obj;
                  }, {}),
                }),
              };
            }
            return step;
          }),
          activeStep: state.selectedSequence.activeStep?._id === action.stepId ? {
            ...state.selectedSequence.activeStep,
            ...(action.contactId ? {
              contacts: state.selectedSequence.activeStep.contacts.map((item) => {
                if (item.contact === action.contactId) {
                  return {
                    ...item,
                    versions: {
                      ...item.versions,
                      A: {
                        ...item.versions.A,
                        attachments: (item.versions.A.attachments || []).filter(({ _id }) => !action.attachmentIds.includes(_id)),
                      },
                    },
                  };
                }
                return item;
              }),
            } : {
              versions: Object.keys(state.selectedSequence.activeStep.versions).reduce((obj, version) => {
                obj[version] = state.selectedSequence.activeStep.versions[version];
                obj[version].attachments = (state.selectedSequence.activeStep.versions[version].attachments || []).filter(({ _id }) => !action.attachmentIds.includes(_id));
                return obj;
              }, {}),
            }),
          } : state.selectedSequence.activeStep,
        },
      };
    }
    case 'UPDATE_STEPS': {
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          steps: state.selectedSequence.steps.map((step) => ({
            ...step,
            saved: true,
          })),
          settings: {
            ...state.selectedSequence.settings,
            saved: true,
          },
        },
      };
    }
    case 'SET_SELECTED_LEADS': {
      return {
        ...state,
        selectedLeads: [...action.data],
      };
    }
    case 'ADD_STEP': {
      const { activeVersion, indexPreviousStepId } = action;
      const newStep = {
        ...action.payload,
        status: STEP_STATUS.TO_SEND,
        saved: true,
        activeVersion,
      };
      const steps = [...state.selectedSequence.steps.slice(0, indexPreviousStepId), { ...newStep }, ...state.selectedSequence.steps.slice(indexPreviousStepId)];
      let { activeStep } = state.selectedSequence;
      if (steps.length === 1) {
        [activeStep] = steps;
      }
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          steps,
          activeStep,
        },
      };
    }
    case 'RAISE_STEP': {
      const steps = [...state.selectedSequence.steps];
      const currentStepIndex = steps.findIndex((step) => step._id === action.data.id);
      const currentStep = { ...steps[currentStepIndex] };
      let replacedStep;
      let replacedStepIndex;

      if (action.data.where === 'UP') {
        replacedStep = { ...steps[currentStepIndex - 1] };
        replacedStepIndex = currentStepIndex - 1;
      } else if (action.data.where === 'DOWN') {
        replacedStep = { ...steps[currentStepIndex + 1] };
        replacedStepIndex = currentStepIndex + 1;
      }

      if (replacedStep && Object.keys(replacedStep).length !== 0 && replacedStep.constructor === Object) {
        steps[replacedStepIndex] = currentStep;
        steps[currentStepIndex] = replacedStep;
      }

      return { ...state, selectedSequence: { ...state.selectedSequence, steps: [...steps] } };
    }
    case 'RENAME_SEQUENCE':
      return {
        ...state,
        selectedSequence: { ...state.selectedSequence, name: action.name },
        sequences: {
          ...state.sequences,
          data: state.sequences.data.map((sequence) => {
            if (sequence._id === action.sequenceId) {
              return {
                ...sequence,
                name: action.name,
              };
            }
            return sequence;
          }),
        },
      };
    case 'GET_STEP_TEMPLATES':
      return { ...state, stepTemplates: { filters: action.filters, data: action.payload } };
    case 'DELETE_STEP_TEMPLATE': {
      return {
        ...state,
        stepTemplates: {
          ...state.stepTemplates,
          data: state.stepTemplates.data.filter((template) => template._id !== action.templateId),
        },
      };
    }
    case 'CLEAR_SEQUENCE':
      return { ...state, selectedSequence: initialState.selectedSequence };
    case 'UPDATE_SEQUENCE_SETTINGS_REMOTELY': {
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          settings: {
            ...state.selectedSequence.settings,
            ...action.updatedData,
          },
        },
      };
    }
    case 'UPDATE_SEQUENCE_ACCOUNT': {
      return {
        ...state,
        sequences: {
          ...state.sequences,
          data: state.sequences.data.map((sequence) => {
            if (sequence._id === action.data.sequenceId) {
              return {
                ...sequence,
                settings: {
                  ...sequence.settings,
                  account: action.data.account,
                },
              };
            }
            return sequence;
          }),
        },
      };
    }
    case 'UPDATE_SEQUENCE_SETTINGS': {
      const { updateRemotely } = action;
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          settings: {
            ...state.selectedSequence.settings,
            ...action.data,
            saved: updateRemotely,
          },
        },
        schedules: {
          ...state.schedules,
          saved: true,
        },
      };
    }
    case 'UPDATE_STEP': {
      const { step: updatedStep } = action.data;
      if (!state.selectedSequence?.steps) return state;
      const steps = [...state.selectedSequence.steps];
      const neededStepIndex = steps.findIndex((item) => item._id === updatedStep._id);
      if (neededStepIndex === -1) {
        return { ...state };
      }
      steps[neededStepIndex] = {
        ...steps[neededStepIndex],
        ...updatedStep,
        saved: false,
      };
      let { activeStep } = state.selectedSequence;
      if (activeStep) {
        const indexActiveStep = steps.findIndex((item) => item._id === activeStep._id);
        if (indexActiveStep === neededStepIndex) {
          activeStep = steps[neededStepIndex];
        }
      }
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          activeStep,
          steps,
        },
      };
    }
    case 'CHANGE_STATUS_SEQUENCE': {
      return {
        ...state,
        sequences: {
          ...state.sequences,
          data: state.sequences.data.map((sequence) => {
            if (sequence._id === action.sequenceId) {
              return {
                ...sequence,
                status: action.status,
              };
            }
            return sequence;
          }),
        },
        selectedSequence: {
          ...state.selectedSequence,
          status: action.status,
        },
      };
    }
    case 'GET_SEQUENCE_LEADS': {
      const { search } = action;
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          leads: {
            ...state.selectedSequence.leads,
            data: action.payload.data,
            ...action.payload.meta,
            search: {
              ...search,
            },
            initialRequest: false,
          },
        },
      };
    }
    case 'SET_SEQUENCE_LEAD_EVENT': {
      const { selectedStatus } = action.data;
      const previousSelectedStatus = state.selectedSequence.leads.selectedStatus;
      let filters = [...(state.selectedSequence.leads.filters || [])];
      let hasStatusFilter = false;
      if (previousSelectedStatus === selectedStatus) {
        filters = filters.filter((filter) => {
          if (filter.property === 'events') {
            if (filter.included.value?.length === 1) {
              return false;
            }
            filter.included.value = filter.included.value.filter((value) => ![selectedStatus].includes(value.value));
          }
          return true;
        });
      } else {
        filters.forEach((filter) => {
          if (filter.property === 'events') {
            filter.included.value = filter.included.value.filter((value) => ![previousSelectedStatus, selectedStatus].includes(value.value));
            filter.included.value.push({
              _id: nanoid(),
              value: selectedStatus,
            });
            hasStatusFilter = true;
          }
        });
        if (!hasStatusFilter) {
          filters.push({
            name: 'Events',
            property: 'events',
            included: {
              operator: 'CONTAINS_ANY',
              value: [{
                _id: nanoid(),
                value: selectedStatus,
              }],
            },
          });
        }
      }
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          leads: {
            ...state.selectedSequence.leads,
            filters,
            selectedStatus: previousSelectedStatus === selectedStatus ? undefined : selectedStatus,
          },
        },
      };
    }
    case 'SET_SEQUENCE_LEAD_STATUS': {
      const { selectedStatus } = action.data;
      const previousSelectedStatus = state.selectedSequence.leads.selectedStatus;
      let filters = [...(state.selectedSequence.leads.filters || [])];
      let hasStatusFilter = false;
      if (previousSelectedStatus === selectedStatus) {
        filters = filters.filter((filter) => {
          if (filter.property === 'status') {
            if (filter.included.value?.length === 1) {
              return false;
            }
            filter.included.value = filter.included.value.filter((value) => ![selectedStatus].includes(value.value));
          }
          return true;
        });
      } else {
        filters.forEach((filter) => {
          if (filter.property === 'status') {
            filter.included.value = filter.included.value.filter((value) => ![previousSelectedStatus, selectedStatus].includes(value.value));
            filter.included.value.push({
              _id: nanoid(),
              value: selectedStatus,
            });
            hasStatusFilter = true;
          }
        });
        if (!hasStatusFilter) {
          filters.push({
            name: 'Status',
            property: 'status',
            included: {
              operator: 'CONTAINS_ANY',
              value: [{
                _id: nanoid(),
                value: selectedStatus,
              }],
            },
          });
        }
      }
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          leads: {
            ...state.selectedSequence.leads,
            filters,
            selectedStatus: previousSelectedStatus === selectedStatus ? undefined : selectedStatus,
          },
        },
      };
    }
    case 'ADD_SEQUENCE_LEAD_FILTER': {
      let hasFilter = false;
      const filters = [...(state.selectedSequence.leads.filters || [])];
      filters.forEach((filter) => {
        if (filter.property === action.data.property && filter.included.value) {
          hasFilter = true;
          filter.included.value.push(...action.data.included.value);
        }
      });
      if (hasFilter) {
        return state;
      }
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          leads: {
            ...state.selectedSequence.leads,
            filters: [...(state.selectedSequence.leads.filters || []), action.data],
          },
        },
      };
    }
    case 'REMOVE_SEQUENCE_LEAD_FILTER': {
      const filters = [...(state.selectedSequence.leads.filters || [])];
      const { selectedStatus } = state.selectedSequence.leads;
      let foundValue;
      filters.forEach((filter, index) => {
        const _foundValue = filter.included.value.find((value) => value._id === action.data.id);
        if (filter.included && _foundValue) {
          foundValue = _foundValue;
          if (filter.included.value.length === 1) {
            filters.splice(index, 1);
          } else {
            filter.included.value = filter.included.value.filter((value) => value._id !== action.data.id);
          }
        }
      });
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          leads: {
            ...state.selectedSequence.leads,
            filters,
            selectedStatus: foundValue?.value === selectedStatus ? null : selectedStatus,
          },
        },
      };
    }
    case 'CHANGE_POSITION_STEP': {
      const { oldIndex, newIndex } = action.data;
      const newSteps = [...state.selectedSequence.steps];
      const temp = newSteps[oldIndex];
      newSteps[oldIndex] = newSteps[newIndex];
      newSteps[newIndex] = temp;
      return { ...state, selectedSequence: { ...state.selectedSequence, steps: newSteps } };
    }
    case 'ADD_STEP_VERSION': {
      const steps = [...state.selectedSequence.steps];
      const neededStepIndex = steps.findIndex((step) => step._id === action.data.id);
      if (neededStepIndex === -1) {
        return { ...state };
      }
      const versionLetters = Object.keys(steps[neededStepIndex].versions);
      const lastVersion = versionLetters[versionLetters.length - 1];
      const newLetter = String.fromCharCode(lastVersion.charCodeAt() + 1);
      steps[neededStepIndex].activeVersion = newLetter;
      steps[neededStepIndex].saved = false;
      steps[neededStepIndex].versions = {
        ...steps[neededStepIndex].versions,
        [newLetter]: {
          content: '',
          subject: '',
        },
      };
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          steps,
          activeStep: {
            ...state.selectedSequence.activeStep,
            versions: {
              ...state.selectedSequence.activeStep.versions,
              [newLetter]: {
                content: '',
                subject: '',
              },
            },
          },
        },
      };
    }
    case 'COPY_STEP_VERSION': {
      const steps = [...state.selectedSequence.steps];
      const neededStepIndex = steps.findIndex((step) => step._id === action.data.id);
      if (neededStepIndex === -1) {
        return { ...state };
      }
      if (!steps[neededStepIndex].versions[action.data.version]) {
        return { ...state };
      }
      const versionLetters = Object.keys(steps[neededStepIndex].versions);
      const lastVersion = versionLetters[versionLetters.length - 1];
      const newLetter = String.fromCharCode(lastVersion.charCodeAt() + 1);
      steps[neededStepIndex].activeVersion = newLetter;
      steps[neededStepIndex].saved = false;
      const newVersion = {
        content: steps[neededStepIndex].versions[action.data.version].content,
        subject: steps[neededStepIndex].versions[action.data.version].subject,
      };
      steps[neededStepIndex].versions = {
        ...steps[neededStepIndex].versions,
        [newLetter]: newVersion,
      };
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          steps,
          activeStep: {
            ...state.selectedSequence.activeStep,
            versions: {
              ...state.selectedSequence.activeStep.versions,
              [newLetter]: newVersion,
            },
          },
        },
      };
    }
    case 'SELECT_STEP_ACTIVE_VERSION': {
      const steps = [...state.selectedSequence.steps];
      const neededStepIndex = steps.findIndex((step) => step._id === action.data.id);
      if (neededStepIndex === -1) {
        return { ...state };
      }
      steps[neededStepIndex].activeVersion = action.data.letter;
      return { ...state, selectedSequence: { ...state.selectedSequence, steps, activeStep: { ...steps[neededStepIndex] } } };
    }
    case 'DISABLE_STEP_VERSION':
    case 'DELETE_STEP_VERSION': {
      const {
        steps,
        activeVersion,
        type,
        stepId,
        letter,
      } = action;
      const newState = { ...state };
      if (state.selectedSequence.stats) {
        let stepsData = [];
        if (type === 'DISABLE_STEP_VERSION') {
          stepsData = state.selectedSequence.stats.steps.data.map((step) => {
            const foundStep = steps.find(({ _id }) => _id === step._id);
            if (foundStep) {
              step.versions = step.versions.map((version) => {
                const foundVersion = foundStep.versions[version.name];
                if (foundVersion) {
                  return {
                    ...version,
                    disabled: foundVersion.disabled,
                  };
                }
                return version;
              });
            }
            return step;
          });
        } else if (type === 'DELETE_STEP_VERSION') {
          stepsData = state.selectedSequence.stats.steps.data.map((step) => {
            if (step._id === stepId) {
              const versions = step.versions.filter((item) => item.name !== letter).map((item, index) => ({
                ...step.versions[index],
                subject: item.subject,
                name: String.fromCharCode(65 + index),
              }));
              return {
                ...step,
                versions,
              };
            }
            return step;
          });
        }
        newState.selectedSequence.stats.steps.data = stepsData;
      }
      return {
        ...newState,
        selectedSequence: {
          ...state.selectedSequence,
          activeStep: {
            ...steps.find((step) => step._id === state.selectedSequence.activeStep._id),
            activeVersion,
          },
          steps,
        },
      };
    }
    case 'ADD_SEQUENCE_SCHEDULE': {
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          settings: {
            ...state.selectedSequence.settings,
            schedule: action.payload._id,
          },
        },
        schedules: {
          ...state.schedules,
          data: [...state.schedules.data, action.payload],
        },
      };
    }
    case 'UPDATE_SEQUENCE_SCHEDULE': {
      const { updateRemotely } = action;
      if (updateRemotely) {
        const { schedule, defaultSchedule } = state.selectedSequence.settings;
        const currentSchedule = schedule || defaultSchedule;
        const activeSchedule = state.schedules.data.find((item) => item._id === currentSchedule);
        return {
          ...state,
          schedules: {
            ...state.schedules,
            lastSchedule: activeSchedule,
            saved: true,
          },
        };
      }
      return {
        ...state,
        schedules: {
          ...state.schedules,
          data: state.schedules.data.map((schedule) => {
            if (schedule._id === action.data._id) {
              return { ...schedule, ...action.data };
            }
            return schedule;
          }),
          saved: false,
        },
      };
    }
    case 'REMOVE_SEQUENCE_SCHEDULE': {
      const leftSchedules = state.schedules.data.filter((schedule) => schedule._id !== action.scheduleId);
      const defaultSchedule = leftSchedules.find((schedule) => schedule.sequence)._id;
      return {
        ...state,
        schedules: {
          ...state.schedules,
          data: leftSchedules,
        },
        selectedSequence: {
          ...state.selectedSequence,
          settings: {
            ...state.selectedSequence.settings,
            schedule: defaultSchedule,
          },
        },
      };
    }
    case 'UPDATE_SEQUENCE_SCHEDULE_DAY_STATUS': {
      const schedules = [...state.schedules.data];
      const neededScheduleIndex = schedules.findIndex((schedule) => schedule._id === action.data._id);
      if (neededScheduleIndex === -1) {
        return { ...state };
      }
      schedules[neededScheduleIndex].days = schedules[neededScheduleIndex].days.map((day) => {
        if (day.name === action.data.scheduleDay) {
          return { ...day, active: action.data.status };
        }
        return { ...day };
      });
      return {
        ...state,
        schedules: {
          ...state.schedules,
          data: schedules,
          saved: false,
        },
      };
    }
    case 'GET_SEQUENCE_SCHEDULES': {
      const { schedule, defaultSchedule } = state.selectedSequence.settings;
      const currentSchedule = schedule || defaultSchedule;
      const activeSchedule = action.payload.find((item) => item._id === currentSchedule);
      return {
        ...state,
        schedules: {
          data: action.payload,
          saved: true,
          lastSchedule: cloneDeep(activeSchedule),
        },
      };
    }
    case 'ADD_SEQUENCE_SCHEDULE_TIME': {
      const schedules = [...state.schedules.data];
      const neededScheduleIndex = schedules.findIndex((schedule) => schedule._id === action.data._id);
      if (neededScheduleIndex === -1) {
        return { ...state };
      }
      schedules[neededScheduleIndex].days = schedules[neededScheduleIndex].days.map((day) => {
        if (day.name === action.data.scheduleDay) {
          return { ...day, times: [...day.times, action.data.time] };
        }
        return { ...day };
      });
      return {
        ...state,
        schedules: {
          ...state.schedules,
          data: schedules,
          saved: false,
        },
      };
    }
    case 'UPDATE_SEQUENCE_SCHEDULE_TIME': {
      const schedules = [...state.schedules.data];
      const neededScheduleIndex = schedules.findIndex((schedule) => schedule._id === action.data._id);
      if (neededScheduleIndex === -1) {
        return { ...state };
      }
      schedules[neededScheduleIndex].days = schedules[neededScheduleIndex].days.map((day) => {
        if (day.name === action.data.scheduleDay) {
          return {
            ...day,
            times: day.times.map((time) => {
              if (time._id === action.data.time._id) {
                return { ...time, ...action.data.time };
              }
              return { ...time };
            }),
          };
        }
        return { ...day };
      });
      return {
        ...state,
        schedules: {
          ...state.schedules,
          data: schedules,
          saved: state.schedules.saved === false ? false : action.data.saved,
        },
      };
    }
    case 'REMOVE_SEQUENCE_SCHEDULE_TIME': {
      const schedules = [...state.schedules.data];
      const neededScheduleIndex = schedules.findIndex((schedule) => schedule._id === action.data._id);
      if (neededScheduleIndex === -1) {
        return { ...state };
      }
      schedules[neededScheduleIndex].days = schedules[neededScheduleIndex].days.map((day) => {
        if (day.name === action.data.scheduleDay) {
          return { ...day, times: day.times.filter((time) => time._id !== action.data.timeId) };
        }
        return { ...day };
      });
      return {
        ...state,
        schedules: {
          ...state.schedules,
          data: schedules,
          saved: false,
        },
      };
    }
    case 'COPY_SEQUENCE_SCHEDULE_TIME': {
      const schedules = [...state.schedules.data];
      const neededScheduleIndex = schedules.findIndex((schedule) => schedule._id === action.data._id);
      if (neededScheduleIndex === -1) {
        return { ...state };
      }
      const { times } = schedules[neededScheduleIndex].days.find((day) => day.name === action.data.scheduleDay);
      schedules[neededScheduleIndex].days = schedules[neededScheduleIndex].days.map((day) => {
        if (action.data.days.includes(day.name)) {
          return { ...day, times: times.map((time) => ({ ...time, _id: nanoid() })) };
        }
        return { ...day };
      });
      return {
        ...state,
        schedules: {
          ...state.schedules,
          data: schedules,
          saved: false,
        },
      };
    }
    case 'SELECT_SEQUENCE_ACTIVE_STEP': {
      const activeStep = state.selectedSequence.steps.find((step) => step._id === action.data.stepId);
      const { leadReview } = state.selectedSequence;
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          activeStep,
          customizeEmail: {
            ...(state.selectedSequence.customizeEmail || {}),
            wholeSequence: leadReview ? !activeStep.contacts.find((item) => item.contact === leadReview.lead._id.contactId) : false,
          },
        },
      };
    }
    case 'RESET_SEQUENCE_SCHEDULE': {
      const { schedule, defaultSchedule } = state.selectedSequence.settings;
      const currentSchedule = schedule || defaultSchedule;
      return {
        ...state,
        schedules: {
          ...state.schedules,
          data: state.schedules.data.map((item) => {
            if (item._id === currentSchedule) {
              return cloneDeep(state.schedules.lastSchedule);
            }
            return item;
          }),
          saved: true,
        },
      };
    }
    case 'UPDATE_SEQUENCE_CONTACT_STATUS': {
      if (state.selectedSequence?._id !== action.sequenceId) {
        return state;
      }
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          leads: {
            ...state.selectedSequence.leads,
            data: state.selectedSequence.leads.data.map((lead) => {
              if (lead._id === action.contactId) {
                return {
                  ...lead,
                  status: action.status,
                };
              }
              return lead;
            }),
          },
        },
      };
    }
    case 'UPDATE_CONTACT': {
      if (!state.selectedSequence?.leads?.data?.length) return state;

      const { status } = action;
      const { data } = action.payload;

      if (status !== 'updated') return state;

      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          leads: {
            ...state.selectedSequence.leads,
            data: state.selectedSequence.leads.data.map((lead) => {
              if (data._id === lead._id) {
                return {
                  ...lead,
                  properties: data.properties,
                };
              }
              return lead;
            }),
          },
        },
      };
    }
    case 'RESET_SEQUENCE': {
      return {
        ...state,
        selectedSequence: {
          ...state.selectedSequence,
          ...action.data,
        },
      };
    }
    case 'CREATE_FOLDER': {
      if (action.kind !== 'sequence') return state;
      const parent = get(action.payload, 'parent', '');
      const isRoot = get(action, 'parent', 'root') === 'root';
      const newFolders = !isRoot
        ? addFolderToParent(parent, state.folders, action.payload)
        : {
          name: 'root',
          children: [...get(state, 'folders.children', []), action.payload],
        };
      return {
        ...state,
        folders: newFolders,
      };
    }
    case 'GET_FOLDER_SEQUENCES_RELATION': {
      return {
        ...state,
        foldersRelation: action.payload,
      };
    }
    case 'MOVE_LISTS_TO_FOLDER': {
      const { folderId, entityIds, kind } = action;

      if (kind !== 'sequence') return state;

      if (folderId === 'All lists') {
        const newRelations = state.foldersRelation.filter((item) => !entityIds.includes(get(item, 'list._id')));
        return {
          ...state,
          foldersRelation: newRelations,
        };
      }

      const payload = get(action, 'payload', []);
      const newFoldersRelation = [...state.foldersRelation];

      payload.forEach((element) => {
        const index = newFoldersRelation.findIndex((item) => get(item, 'list._id') === get(element, 'list._id'));
        if (index > -1) newFoldersRelation[index].folder = get(element, 'folder');
        else newFoldersRelation.push(element);
      });

      return {
        ...state,
        foldersRelation: newFoldersRelation,
      };
    }
    case 'REMOVE_SEQUENCES': {
      const { sequenceIds } = action;
      const newFoldersRelations = sequenceIds.length ? state.foldersRelation.filter((item) => !sequenceIds.includes(get(item, 'sequence._id'))) : [];
      return {
        ...state,
        foldersRelation: newFoldersRelations,
      };
    }
    case 'MOVE_LISTS_WITH_FOLDERS_TO_FOLDER': {
      if (action.kind !== 'sequence') return state;
      const [folders = [], sequenceRelations = []] = action.payload;
      const { root, entityIds } = action.requestData;
      let newFolders = state.folders;
      let newFoldersRelation = [...state.foldersRelation];

      if (folders.length) {
        newFolders = folders.reduce((prev, current) => {
          const folderId = get(current, '_id', '');
          const parent = get(current, 'parent', '');
          const tree = getFolderById(folderId, prev);
          tree.parent = parent;
          const newDeletedFolders = deleteFolderFromParent(folderId, prev);
          return addFolderToParent(parent, newDeletedFolders, tree);
        }, state.folders);
      }

      if (root) {
        newFoldersRelation = state.foldersRelation.filter((item) => !entityIds.includes(get(item, 'sequence._id')));

        return {
          ...state,
          folders: newFolders,
          foldersRelation: newFoldersRelation,
        };
      }

      if (sequenceRelations.length) {
        sequenceRelations.forEach((element) => {
          const index = newFoldersRelation.findIndex((item) => get(item, 'sequence._id') === get(element, 'sequence._id'));
          if (index > -1) newFoldersRelation[index].folder = get(element, 'folder');
          else newFoldersRelation.push(element);
        });
      }

      return {
        ...state,
        folders: newFolders,
        foldersRelation: newFoldersRelation,
      };
    }

    case 'DELETE_FOLDERS': {
      const { folderId } = action;
      const newFolders = deleteFolderFromParent(folderId, state.folders);
      return {
        ...state,
        folders: folderId === 'all' ? {} : newFolders,
      };
    }

    case 'SIGN_OUT':
    case 'SWITCH_WORKSPACE': {
      return initialState;
    }
    default:
      return state;
  }
};
