import get from 'lodash/get';
import { Drawer } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button, Icon, Input, Option, Select,
} from 'components/common';
import useTinyForm from 'hooks/useTinyForm';
import AddFilter from 'components/addFilter';
import * as CONSTANTS from 'constants/styles';
import { getValueOfInput } from 'helpers/dom';
import { validateUrl } from 'helpers/validate';
import { toggleModal } from 'data/actions/modals';
import { updateCompany } from 'data/actions/companies';
import { modalsSelector } from 'data/selectors/modals';
import { currentCompanySelector } from 'data/selectors/companies';
import { updatePreviewBarButtonSelector } from 'data/selectors/loading';
import { Label, EditWrapper } from 'components/entityInfo/common/styles';
import {
  Footer,
  ButtonStyle,
  BodyStyle,
} from '../../createContactBar/styles';
import { ModalTitle, CloseIcon } from '../../modals/baseModal/styles';
import { HeaderStyle } from '../styles';

const socialNames = ['facebook', 'twitter', 'youtube', 'instagram'];

const EditCompanyMainInfoPreviewBar = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const company = useSelector(currentCompanySelector);
  const loading = useSelector(updatePreviewBarButtonSelector);

  const closePreviewBar = () => dispatch(toggleModal('edit_company_preview_bar', false));

  const [socials, setSocials] = useState(socialNames.map((name, index) => ({
    id: index,
    name,
    value: get(company, `properties.social.value.${name}`),
  })).filter(({ value }) => typeof value !== 'undefined'));

  const allSocialsFilled = socials.length === 4 && socials.every((social) => social.value);

  const {
    data,
    errors,
    handleChange,
    handleSubmit,
  } = useTinyForm({
    schema: {
      name: {
        value: get(company, 'properties.name.value', ''),
      },
      domain: {
        value: get(company, 'properties.domain.value', ''),
      },
      linkedinUrl: {
        value: get(company, 'properties.linkedinUrl.value', ''),
        conditions: [
          {
            label: 'Must be valid url',
            condition: useCallback((link) => validateUrl(link, true), []),
          },
        ],
      },
    },
    onSubmit: (result) => {
      const properties = [
        {
          property: 'name',
          value: result.data.name,
        },
        {
          property: 'domain',
          value: result.data.domain,
        },
        {
          property: 'linkedinUrl',
          value: result.data.linkedinUrl,
        },
      ].filter(({ property, value }) => {
        if (get(company, `properties.${property}.value`) === value) {
          return false;
        }
        return true;
      });
      properties.push({
        property: 'social',
        value: socials.reduce((obj, item) => {
          obj[item.name] = item.value;
          return obj;
        }, {}),
      });
      dispatch(updateCompany(
        company._id,
        properties,
      ));
    },
  });

  return (
    <Drawer
      title={(
        <ModalTitle>
          Edit company
          <CloseIcon
            data-close="true"
            onClick={closePreviewBar}
            className="ic_close"
          />
        </ModalTitle>
      )}
      headerStyle={HeaderStyle}
      bodyStyle={BodyStyle}
      placement="right"
      onClose={closePreviewBar}
      visible={visible}
      width={CONSTANTS.DRAWER_WIDTH}
      closable={false}
      zIndex={CONSTANTS.Z_INDEXES.MOBILE_MODAL}
    >
      <EditWrapper style={{ padding: 0, width: '100%' }}>
        <div>
          <Label>
            Name
          </Label>
          <Input name="name" onChange={handleChange} value={data.name} size="default" />
        </div>
        <div>
          <Label>
            Domain
          </Label>
          <Input name="domain" onChange={handleChange} value={data.domain} size="default" />
        </div>
        <div style={{ marginBottom: errors.linkedinUrl ? 10 : 0 }}>
          <Label>
            LinkedIn link
          </Label>
          <Input error={errors.linkedinUrl} name="linkedinUrl" onChange={handleChange} value={data.linkedinUrl} size="default" />
        </div>
        <div>
          <Label>
            Social network
          </Label>
          {
            socials.map((social) => (
              <div style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }} key={`social_${social.id}`}>
                <Select
                  size="default"
                  value={social.name}
                  onChange={(value) => {
                    setSocials((prev) => prev.map((item) => {
                      if (item.name === social.name) {
                        return {
                          ...item,
                          name: value,
                        };
                      }
                      return item;
                    }));
                  }}
                  style={{ width: 110, minWidth: 110, maxWidth: 110 }}
                >
                  {
                    socialNames.filter((name) => !socials.map((item) => item.name).includes(name)).map((name) => (
                      <Option key={name} value={name}>{name}</Option>
                    ))
                  }
                </Select>
                <Input
                  onChange={getValueOfInput((value) => {
                    setSocials((prev) => prev.map((item) => {
                      if (item.name === social.name) {
                        return {
                          ...item,
                          value,
                        };
                      }
                      return item;
                    }));
                  })}
                  value={social.value}
                  size="default"
                  style={{ width: '120%', marginRight: 10 }}
                />
                <Icon
                  onClick={() => {
                    setSocials((prev) => prev.filter(({ name }) => name !== social.name));
                  }}
                  size={20}
                  type="ic-delete"
                />
              </div>
            ))
          }
        </div>
        {
          !allSocialsFilled && (
            <AddFilter
              label="Add social network"
              onClick={() => {
                setSocials((prev) => {
                  const prevNames = prev.map(({ name }) => name);
                  const newSocialName = socialNames.find((name) => !prevNames.includes(name));
                  const lastId = prevNames[prevNames.length - 1]?.id;
                  return [...prev, {
                    id: typeof lastId !== 'undefined' ? lastId + 1 : 0,
                    name: newSocialName,
                    value: '',
                  }];
                });
              }}
            />
          )
        }
      </EditWrapper>
      <Footer>
        <Button
          data-form="true"
          type="primary"
          size="large"
          style={ButtonStyle}
          loading={loading}
          onClick={handleSubmit}
        >
          Save
        </Button>
        <Button
          type="normal"
          size="large"
          onClick={closePreviewBar}
        >
          Cancel
        </Button>
      </Footer>
    </Drawer>
  );
};

export default EditCompanyMainInfoPreviewBar;
