import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const CodeWrapper = styled.div`
  margin: 20px 0;
`;

export const SubHeader = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${COLORS.GREY_G1};
  margin-bottom: 0;
`;

export const Header = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: ${COLORS.BLACK_G1};
`;

export const InputWrapper = styled.div`
  margin-top: 20px;
`;
