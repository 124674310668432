import React, { useRef } from 'react';
import { Label } from '@newlab-io/getprospect-react-components/lib/ui';

import Editor from 'components/editor';
import { EDITOR_SKIN } from 'data/types/sequences.types';

const Signature = ({
  error,
  signature,
  handleChange,
}) => {
  const editorRef = useRef();

  const updateProperty = (value) => {
    handleChange({ target: { name: 'signature', value } });
  };

  return (
    <div style={{ margin: '0 10px', cursor: 'pointer' }}>
      <Label as="div" size="small" kind="quaternary" style={{ marginBottom: 5 }} fontWeight={500}>Signature</Label>
      <Editor
        ref={editorRef}
        bordered
        rounded
        skin={EDITOR_SKIN.SIGNATURE}
        bodyMarginLeft={12}
        iconSize={18}
        toolbar="bold italic underline forecolor alignleft aligncenter alignright alignjustify link removeformat image fontselect"
        height={200}
        placeholder="Paste or type your signature"
        content={signature}
        error={error}
        onChange={updateProperty}
      />
    </div>
  );
};

export default Signature;
