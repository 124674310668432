import styled from 'styled-components';
import * as CONSTANTS from 'constants/styles';

import { Icon } from 'components/common';

export const MenuItem = styled.button`
  cursor: pointer;
  color: #33475b;
  display: block;
  min-height: 40px;
  padding: 8px 20px;
  background-color: #fff;
  line-height: 24px;
  text-align: left;
  width: 100%;
  background: no-repeat;
  border: none;
  outline: none;
  min-width: 180px;

  &:hover {
    background-color: ${(props) => (props.isActivity ? CONSTANTS.COLORS.GREY_G14 : CONSTANTS.COLORS.BLUE_B7)};
  }

  &:disabled {
    opacity: .6;
    cursor: not-allowed;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:not(:hover) {
    transition: all .2s ease-out;
  }
`;

export const MobileMenuItem = styled(MenuItem)`
  display: none;

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    display: block;
  }
`;

export const Divider = styled.hr`
  background-color: rgb(223, 227, 235);
  height: 1px;
  border-width: 0px;
  min-height: 1px;
  margin: 8px -16px;
  width: calc(100% + 32px);
`;

export const IconCaretDown = styled(Icon).attrs(() => ({
  type: 'ic-arrow-drop-down',
}))`
  margin-left: 2px;
  transform: ${(props) => (props.rotate ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const Button = styled.button`
  display: ${(props) => (props.visible ? 'flex !important' : 'none')};
  top: -7px;
  position: absolute;
  width: 80px;
  height: 32px;
  border-radius: 4px;
  border: solid 1px ${(props) => (props.visible ? CONSTANTS.COLORS.PRIMARY : CONSTANTS.COLORS.GREY_G4)} !important;
  background-color: ${CONSTANTS.COLORS.WHITE} !important;

  ${(props) => props.visible && `
    .material-icons {
      color: ${CONSTANTS.COLORS.PRIMARY};
    }
  `}

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    display: none !important;
  }
`;

export const MobileButtonContainer = styled.div`
  display: none;
  padding: 8px;
  border-radius: 5px;
  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    display: flex;
  }
`;

export const MobileMoreButton = styled(Icon).attrs(() => ({
  type: 'ic-more-vert',
  fill: '#626262',
}))``;

export const PopoverTitle = styled.a`
  display: flex;
  cursor: pointer;
  font-size: 12px;
  margin-top: 5px;
  color: #0091ae;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #1890ff;
  transition: transform 0.5s ease;

  ${(props) => (props.animate ? 'transform: rotate(180deg);' : 'transform: rotate(0deg);')}
`;
