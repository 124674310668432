import { Modal, Tooltip } from 'antd';
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Step from 'components/step';
import { Button } from 'components/common';
import engine from 'helpers/liquid.engine';
import close from 'assets/images/ic-close.svg';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { removeSignature } from 'helpers/sequences/signature';
import { createStepTemplateLoaderSelector } from 'data/selectors/loading';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import InsertToken from 'components/sequenceInfo/tabs/steps/stepEditor/actions/insertToken';
import {
  addAttachmentsToTemplate,
  createStepTemplate,
  deleteStepTemplate,
  updateStepTemplate,
} from 'data/actions/sequences';
import InsertUnsubscribe from 'components/sequenceInfo/tabs/steps/stepEditor/actions/insertUnsubscribe';
import BaseAttachFile from 'components/sequenceInfo/tabs/steps/stepEditor/actions/attachFile/baseAttachFile';

import {
  Footer,
  Container,
} from './styles';
import {
  CloseIcon,
  ModalTitle,
} from '../baseModal/styles';
import {
  PanelInput,
} from '../../step/styles';
import {
  PanelContainer,
} from '../../sequenceInfo/tabs/steps/stepEditor/styles';

const CreateEmailTemplateModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const {
    subject = '',
    content = '',
    templateName = '',
    templateId: baseTemplateId,
    ...rest
  } = useSelector(modalOptionsSelector);
  const loading = useSelector(createStepTemplateLoaderSelector);

  const [templateId, setTemplateId] = useState(baseTemplateId);
  const [editorRef, setEditorRef] = useState(null);
  const [template, setTemplate] = useState({
    subject,
    content,
    name: templateName,
    attachments: [],
  });
  const [error, setError] = useState();

  const requiredError = Object.values(template).every(Boolean) ? '' : 'Fields name, subject and content are required';

  const handleClose = () => {
    if (templateId) {
      dispatch(deleteStepTemplate({
        templateId,
      }));
    }
    dispatch(toggleModal('create_email_template', false));
  };

  const handleCreateTemplate = () => {
    dispatch(updateStepTemplate({
      templateId,
      ...template,
      content: removeSignature(template.content),
      onSuccess: () => {
        dispatch(toggleModal('add_template_step_preview', true, { ...template, ...rest }));
        dispatch(toggleMessage('success', { header: 'The template has been saved successfully' }));
      },
    }));
  };

  const handleUpdateTemplate = useCallback((step) => {
    setTemplate((prev) => ({ ...prev, ...step }));
  }, []);

  useEffect(() => {
    Promise.all([
      engine.parseAndRender(template.subject),
      engine.parseAndRender(template.content),
    ])
      .then(() => setError())
      .catch((_error) => setError(_error.message));
  }, [template.subject, template.content]);

  useEffect(() => {
    if (!baseTemplateId) {
      dispatch(createStepTemplate({
        ...template,
        content: removeSignature(template.content),
        onSuccess: ({ _id }) => {
          setTemplateId(_id);
        },
      }));
    }
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle white>
          New template
          <CloseIcon
            src={close}
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      wrapClassName="ant-white-modal"
      closable={false}
      width={1050}
      footer={false}
      onCancel={handleClose}
    >
      <Container>
        <PanelContainer>
          <PanelInput
            autoFocus
            placeholder="Name"
            value={template.name}
            onChange={(e) => {
              e.persist();
              setTemplate((prev) => ({ ...prev, name: e.target.value }));
            }}
          />
        </PanelContainer>
        <Step
          id="template_sequence"
          templateName={template.name}
          templateId={templateId}
          bordered={false}
          customSubjectPlaceholder="Subject"
          hideSubjectLabel
          style={{ padding: '0', borderBottom: 0 }}
          height={287}
          content={template.content}
          subject={template.subject}
          attachments={template.attachments}
          previousModal="create_email_template"
          setEditor={(editor) => {
            if (!editorRef) setEditorRef(editor);
          }}
          onChange={handleUpdateTemplate}
        />
      </Container>
      <Footer>
        <div>
          <Tooltip title={error || requiredError}>
            <Button
              data-form={!requiredError && !error ? 'true' : 'false'}
              type="primary"
              onClick={handleCreateTemplate}
              disabled={requiredError || error}
            >
              Save template
            </Button>
          </Tooltip>
          <Button type="normal" onClick={handleClose}>Cancel</Button>
        </div>
        <div style={{ display: 'flex' }}>
          <InsertUnsubscribe
            loadingEditor={!editorRef}
          />
          <InsertToken
            loadingEditor={!editorRef}
          />
          <BaseAttachFile
            loading={loading}
            prefix={`templates/${templateId}/`}
            countFiles={template.attachments.length}
            onUploadFiles={(newFiles) => {
              dispatch(addAttachmentsToTemplate({
                templateId,
                attachments: newFiles,
                onSuccess: (newAttachments) => {
                  setTemplate({ ...template, attachments: [...newAttachments, ...template.attachments] });
                  dispatch(toggleMessage('success', {
                    header: `Files ${newFiles.map(({ name: fileName }) => fileName).join(', ')} have been uploaded successfully`,
                  }));
                },
              }));
            }}
          />
        </div>
      </Footer>
    </Modal>
  );
};

export default CreateEmailTemplateModal;
