import React from 'react';
import UploadStep from './stepsComponents/uploadStep';
import MatchStep from './stepsComponents/matchStep';

export const stepsData = (props) => [
  {
    title: 'Import',
    // eslint-disable-next-line react/jsx-props-no-spreading
    content: <UploadStep {...props} />,
  },
  {
    title: 'Match',
    // eslint-disable-next-line react/jsx-props-no-spreading
    content: <MatchStep {...props} />,
  },
];
