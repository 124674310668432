import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { validateOAuth2Link } from 'helpers/validate';
import { isAuthSelector } from '../data/selectors/user';

const useOAuthLink = () => {
  const link = window.location.href;
  const isAuth = useSelector(isAuthSelector);

  useEffect(() => {
    if ((!isAuth || !sessionStorage.getItem('oauth2link')) && validateOAuth2Link(link) && window.location.href !== link) {
      sessionStorage.setItem('oauth2link', link);
    }
  }, []); // eslint-disable-line
};

export default useOAuthLink;
