import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Popover,
} from 'antd';

import { Icon } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { Progress } from 'data/types/progress.types';
import { SOURCE_TYPE } from 'data/types/source.types';
import { INSIGHTS_TYPE } from 'data/types/insights.types';
import { setSavedSearchFilter, setSelectedContactTab } from 'data/actions/search';
import {
  IconCaretDown,
  Button,
  MenuItem,
  MobileButtonContainer,
  MobileMoreButton,
} from '../listAllOptions/styles';

const SavedHistoryOptions = ({
  name,
  searchId,
  filters,
  status,
  sourceType,
  titleExists = true,
  placement = 'bottomRight',
  backModal,
  backOptions,
  backScrollListId,
  hasArrow,
  setIsShowedOptions,
  align = {
    offset: ['0px', '-13px'],
  },
  onChange,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [visible, toggleVisible] = useState(false);

  useEffect(() => {
    if (setIsShowedOptions) {
      setIsShowedOptions(visible);
    }
  }, [setIsShowedOptions, visible]);

  const newBackOptions = backOptions ? { ...backOptions } : undefined;
  if (newBackOptions && backScrollListId) {
    newBackOptions.scrollTop = document.getElementById(backScrollListId)?.scrollTop;
  }

  const content = [
    <MenuItem
      onClick={
        () => {
          toggleVisible(false);
          dispatch(toggleModal('save_search', true, {
            sourceType,
            searchId,
            filters,
            backModal,
            backOptions: newBackOptions,
            onChange,
          }));
        }
      }
    >
      Save
    </MenuItem>,
  ];

  if (sourceType === SOURCE_TYPE.CONTACT) {
    content.push(
      <MenuItem
        onClick={
          () => {
            toggleVisible(false);
            dispatch(setSavedSearchFilter({
              filters, initRecentActivities: true, searchId,
            }));
            dispatch(setSelectedContactTab(INSIGHTS_TYPE.SAVED_INSIGHTS));
            history.push('/search/contact');
          }
        }
      >
        Show saved leads
      </MenuItem>,
    );
  }

  if (status === Progress.IN_PROGRESS) {
    content.push(
      <MenuItem
        onClick={
          () => {
            toggleVisible(false);
            dispatch(toggleModal('stop_search', true, {
              name,
              searchId,
              sourceType,
              backModal,
              backOptions: newBackOptions,
            }));
          }
        }
      >
        Stop
      </MenuItem>,
    );
  }

  const contentWithKeys = content.map((item, index) => ({
    ...item,
    key: `list-options-${index}`,
  }));

  return (
    <Popover
      onVisibleChange={toggleVisible}
      visible={visible}
      placement={placement}
      content={contentWithKeys}
      trigger="click"
      align={align}
      overlayClassName={`list_popover ${hasArrow ? '' : 'hide-popover-arrow'}`}
      getPopupContainer={(node) => node}
    >
      <div style={{ height: 28 }}>
        {
          titleExists ? (
            <>
              <Button visible={visible} className="more_button" type="button">
                Actions
                <IconCaretDown rotate={visible} type="caret-down" />
              </Button>
              <MobileButtonContainer className="mobile_more_button_container">
                <MobileMoreButton className="mobile_more_button" />
              </MobileButtonContainer>
            </>
          ) : (
            <Icon style={{ cursor: 'pointer' }} type="ic-more-vert" />
          )
        }
      </div>
    </Popover>
  );
};

export default SavedHistoryOptions;
