import styled from 'styled-components';
import {
  Drawer as DrawerA,
  Divider as DividerA,
} from 'antd';
import { COLORS } from 'constants/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  height: calc(100vh - 55px);
  ${(props) => (!props.greyBg ? `background-color: ${COLORS.GREY_G5};` : '')}

  .ant-card, .ant-card-body {
    border-radius: 0 !important;
    padding: 0;
  }

  .ant-card-bordered {
    border: none;
  }

  ${(props) => (props.disablePadding ? `
    .ant-card-body {
      padding: 0 !important;
      background-color: ${COLORS.GREY_G5};
    }
  ` : '')}
`;

export const ContentWrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  background-color: ${COLORS.GREY_G5};

  > div:last-child > div {
    margin-bottom: 0;
  }
`;

export const Divider = styled(DividerA)`
  width: 100%;
  margin: -1px;
`;

export const DrawerStyle = {
  padding: 0,
  overflow: 'auto',
};

export const DrawerWrapper = styled.div`
  .ant-card-type-inner .ant-card-head{
    background: #dfe2e5;
  }

  div > .ant-drawer-title {
    color: #ffffff !important;
  }

  .ant-drawer-close {
    height: 76px;
  }
`;

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 24px;

  &:hover {
    cursor: ${({ isLink }) => (isLink ? 'pointer' : 'unset')};
    background-color: rgba(190, 209, 238, 0.3);
  }
`;

export const SearchActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  border-radius: 4px;
  padding: 15px 10px 10px 10px;
  border: 1px solid ${COLORS.GREY_G14};
  margin-bottom: 10px;

  .activity-title {
    max-width: 145px;
    font-weight: 500;
  }
`;

export const ActivityHeader = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  max-height: 45px;
`;

export const HeaderIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
export const HeaderControls = styled.div`
  display: flex;
  align-items: center;

  > button:hover {
    box-shadow: none;
    border-color: ${COLORS.PRIMARY};

    span {
      color: ${COLORS.PRIMARY};
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
`;

export const OptionsWrapper = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;

  ${(props) => (props.showing ? `background-color: ${COLORS.GREY_G5};` : '')}

  :hover {
    background-color: ${COLORS.GREY_G5};
    transition: all .5s;
  }

  > div {
    width: 100%;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ActivityLoadBtn = {
  width: 95,
  height: 32,
  padding: 0,
  textAlign: 'center',
  color: COLORS.BLACK_G1,
};

export const Circle = styled.div`
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  background-color: ${(props) => (props.fill ? props.fill : 'rgba(190, 209, 238, 0.3)')};
  border-radius: 50%;
  display: flex;
  margin-right: 20px;
`;

export const ActivityCircle = styled(Circle)`
  margin-right: 10px;
  margin-left: 5px;
  cursor: default;
`;

export const NotificationType = styled.div`
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: ${COLORS.BLACK_G1};
`;

export const Data = styled.div`
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${COLORS.GREY_G1};
  overflow-wrap: anywhere;
  
  a {
    text-decoration: underline;
  }
`;

export const ActivityDataWrapper = styled(Data)`
  ${(props) => (props.useOffset ? 'margin-bottom: 25px;' : '')}

  .ant-collapse-header {
    padding: 0 !important;
    background-color: ${COLORS.WHITE} !important;
  }

  .ant-collapse > .ant-collapse-item {
    padding-left: 5px;
    position: relative;
    background-color: ${COLORS.WHITE};
    
  }

  .ant-collapse-item, .ant-collapse {
    border: none !important;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    bottom: -30px !important;
    left: 5px !important;
    top: unset;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${COLORS.PRIMARY};
    font-weight: 600;
    cursor: pointer;

    :hover {
      color: ${COLORS.PRIMARY_P3};
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: unset;
    cursor: default;
  }

  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    border: none;
  }
`;

export const CollapseBtnWrapper = styled.div``;

export const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;

  > div:last-child {
    display: flex;
    align-items: center;
  }
`;

export const TitleStyle = styled.div`
  font-family: AvenirNext;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.25px;
  color: ${(props) => (props.isActivity ? `${COLORS.GREY_G2}` : 'rgba(0, 0, 0, 0.65)')};
`;
export const CreatedDate = styled.div`
  font-size: 12px;
  font-weight: ${(props) => (props.isActivity ? 400 : 500)};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: ${COLORS.GREY_G2};
  white-space: nowrap;
`;

export const NotificationContent = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 100;
  flex-direction: column;
`;

export const NotificationIcon = {
  fontSize: 25,
  color: '#7c98b5',
  marginRight: 20,
};

export const ButtonShowMore = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 10px 7px;
  align-items: center;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  justify-content: center;
  letter-spacing: normal;
  color: ${COLORS.PRIMARY};

  &:hover {
    span:first-child {
      text-decoration: underline;
    }
  }

  &:focus {
    outline: 0;
  }
`;

export const Drawer = styled(DrawerA)`
  .ant-drawer-mask {
    background-color: transparent;
  }
`;

export const NotificationItemIcon = styled.img`
  width: 18px;
`;

export const NotificationWrapper = styled.div``;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${COLORS.PRIMARY};
  border-radius: 50%;
  margin-left: 10px;
`;

export const CountItem = styled.span`
  align-items: center;
  display: flex;
  margin-left: 10px;
  width: 20px;
  font-weight: 500;
  height: 20px;
  font-size: 12px;
  padding: 0px;
  background: rgb(255 255 255 / 25%);
  border-radius: 50%;
  justify-content: center;
`;
