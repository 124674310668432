import get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Button,
} from 'antd';

import { Checkbox } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { allListsSelector } from 'data/selectors/lists';
import { setContactsParams, setSelectedContacts } from 'data/actions/contacts';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import {
  contactsSelector,
  selectedAllContactsSelector,
} from 'data/selectors/contacts';
import {
  decrementList,
  listAddContacts,
  moveContactsToStaticList,
} from 'data/actions/lists';

import ListSelect from 'components/listSelect';
import {
  CloseIcon,
  ModalTitle,
  Label,
  Footer,
} from '../baseModal/styles';
import { CheckboxWrapper } from '../deleteListModal/styles';

const MoveToStaticListModal = () => {
  const dispatch = useDispatch();

  const lists = useSelector(allListsSelector);
  const { visible } = useSelector(modalsSelector);
  const {
    listId,
    setSelectedRowKeys,
    filters,
    search,
    selectAll,
    selectedItems,
  } = useSelector(modalOptionsSelector);
  const tableData = useSelector(contactsSelector);
  const allContactsSelected = useSelector(selectedAllContactsSelector);

  const [selectedLists, setSelectedLists] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [deleteFromCurrent, setDeleteFromCurrentStatus] = useState(true);

  const handleClose = () => dispatch(toggleModal('move_to_static_list', false));

  const refreshContacts = () => {
    dispatch(setContactsParams({
      listId,
      page: 1,
      findCount: true,
      pageSize: tableData.pageSize ?? 25,
      components: ['contactsListTable'],
    }));
  };

  const onSuccess = (data) => {
    if (!data.pending) {
      const countUpdatedItems = get(data, 'countUpdatedItems');
      if (countUpdatedItems) {
        refreshContacts();

        dispatch(toggleMessage('success', { header: 'Contacts were moved!' }));
        dispatch(setSelectedContacts([]));
        const count = selectedItems ? selectedItems.length : lists.find((list) => list._id === listId)?.contactsCount || 0;

        if (deleteFromCurrent) {
          dispatch(decrementList(listId, count));
        }
      }
    }
    if (setSelectedRowKeys) setSelectedRowKeys([]);
  };

  const handleMove = () => {
    if (!deleteFromCurrent) {
      dispatch(listAddContacts({
        listIds: selectedLists,
        contactsIds: selectedItems,
        filters,
        search,
        selectAll,
        onSuccess,
        onFailed: () => dispatch(toggleMessage('error', { header: 'Something went wrong' })),
      }));
    } else {
      dispatch(moveContactsToStaticList({
        listIds: !deleteFromCurrent ? [listId, ...selectedLists] : selectedLists,
        contactsIds: selectedItems,
        filters,
        search,
        selectAll,
        onSuccess,
        onFailed: () => dispatch(toggleMessage('error', { header: 'Something went wrong' })),
      }));
    }
    handleClose();
  };

  const selectedContactsLength = get(selectedItems, 'length', 0);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          {
            selectedContactsLength > 1 || allContactsSelected
              ? `Move ${allContactsSelected ? tableData.totalItems : selectedContactsLength} contacts to a list`
              : 'Move to a list'
          }
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      width={450}
      closable={false}
      footer={false}
      onCancel={handleClose}
    >
      <>
        <Label style={{ marginBottom: '20px', display: 'block' }}>
          {selectedLists.length ? (
            <>
              {selectedContactsLength > 1 || allContactsSelected
                ? `Move ${allContactsSelected ? tableData.totalItems : selectedContactsLength} contacts to this list${selectedLists.length > 1 ? 's' : ''}:`
                : `Move selected contact to this list${selectedLists.length > 1 ? 's' : ''}:`}
            </>
          ) : (
            `Select destination lists, or leave empty to remove selected contact${selectedContactsLength > 1 || allContactsSelected ? 's' : ''} from all lists.`
          )}
        </Label>
        <ListSelect
          value={selectedLists}
          placeholder="Destination lists"
          onSelectedListsChange={setSelectedLists}
          isOpened={isOpened}
          setIsOpened={setIsOpened}
        />
        <CheckboxWrapper>
          <Label>
            <Checkbox
              checked={deleteFromCurrent}
              onChange={(e) => setDeleteFromCurrentStatus(e.target.checked)}
            >
              {`Remove ${!selectedContactsLength || allContactsSelected ? 'all ' : ''}contact${selectedContactsLength > 1 || allContactsSelected ? 's' : ''} from current lists.`}
            </Checkbox>
          </Label>
        </CheckboxWrapper>
      </>
      <Footer>
        <Button
          // data-form={!selectedListId ? 'false' : 'true'}
          type="primary"
          size="large"
          onClick={handleMove}
          // disabled={!selectedListId}
        >
          Move
        </Button>
        <Button size="large" type="normal" onClick={handleClose}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default MoveToStaticListModal;
