import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { toggleMessage } from 'data/actions/message';

const useBulkMessage = () => {
  const dispatch = useDispatch();

  const handleBulk = useCallback(({ fileName, error }) => {
    if (error) {
      dispatch(toggleMessage('error', { text: error, duration: 15 }));
    } else {
      dispatch(toggleMessage('success', { text: `Thank you, verification of ${fileName} started.`, duration: 15 }));
    }
  }, [dispatch]);

  return handleBulk;
};

export default useBulkMessage;
