import thunk from 'redux-thunk';
import { compose, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { REFRESH_TOKEN_PROCESS_DONE } from 'data/types/event.types';

import rootReducer from './reducers/rootReducer';
import createApiMiddleware from './middlewares/api';
import createEventsMiddleware from './middlewares/events';
import createDebounceMiddleware from './middlewares/debounce';
import createCancelTokenMiddleware from './middlewares/cancelToken';
import createRefreshTokenMiddleware from './middlewares/refreshToken';
import createCustomRequestMiddleware from './middlewares/subscription';
import createSubscriptionMiddleware from './middlewares/customRequest';

const refreshTokenEvent = new Event(REFRESH_TOKEN_PROCESS_DONE);

const apiMiddleware = createApiMiddleware(refreshTokenEvent);
const eventsMiddleware = createEventsMiddleware(refreshTokenEvent);
const refreshToken = createRefreshTokenMiddleware(refreshTokenEvent);
const customRequest = createCustomRequestMiddleware();
const debounceMiddleware = createDebounceMiddleware();
const cancelTokenMiddleware = createCancelTokenMiddleware();
const subscriptionMiddleware = createSubscriptionMiddleware();
const composeWithOptions = process.env.NODE_ENV === 'development' ? composeWithDevTools({ trace: true, maxAge: 100, traceLimit: 200 }) : compose;

export default function configureStore(initialState = {}) {
  return createStore(
    rootReducer,
    initialState,
    composeWithOptions(applyMiddleware(thunk, debounceMiddleware, cancelTokenMiddleware, refreshToken, apiMiddleware, eventsMiddleware, customRequest, subscriptionMiddleware)),
  );
}
