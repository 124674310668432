import React, {
  useMemo,
} from 'react';

import useCarousel from 'hooks/useCarousel';
import Jason from 'assets/images/cites/people/Jason.jpeg';
import Oliver from 'assets/images/cites/people/Oliver.jpeg';
import Tammie from 'assets/images/cites/people/Tammie.jpeg';
import g2 from 'assets/images/cites/companies/g2.png';
import capterra from 'assets/images/cites/companies/capterra.png';
import chromeStore from 'assets/images/cites/companies/chrome-store.svg';
import {
  Sites,
  SiteText,
  Container,
  ImageCite,
  PersonName,
  KeyboardArrow,
  SiteContainer,
  SitesContainer,
  PersonPosition,
  PersonContainer,
  ImageCompany,
} from './styles';

const ProductCites = () => {
  const cites = useMemo(() => {
    const items = [
      {
        id: 0,
        name: 'Tammie McDaniel',
        cite: 'I can do a single source or a an open feed on Linkedin on front in - Free as well as in paid platforms. You can build list easily and fast the data accurate and affordable.',
        position: 'Business Advisor',
        img: Tammie,
        company: g2,
        widthRatingCompany: '100px',
      },
      {
        id: 1,
        name: 'Oliver H.',
        cite: 'Very easy to use, intuitive, seamless. The leads list export function is excellent, and has helped with marketing to those who I have already qualified via LinkedIn.',
        position: 'Project Manager',
        img: Oliver,
        company: capterra,
        widthCompany: '140px',
        heightCompany: '30px',
      },
      {
        id: 2,
        name: 'Jason Tissera',
        cite: "Prospecting can be a pain, and the last thing I need as a business owner is something that's complicated and a pain to setup. GetProspect was both easy to setup and easy for my team to understand. We're now building lead lists and growing our business. Thanks!",
        position: 'Co-Founder',
        img: Jason,
        company: chromeStore,
      },
    ];
    return items;
  }, []);

  const { swipeItem } = useCarousel({
    items: cites,
    prefix: 'cite-',
    autoScrollInterval: 30000,
  });

  return (
    <Container>
      <SitesContainer>
        <KeyboardArrow onClick={swipeItem('left')} />
        <Sites>
          {
            cites.map((cite) => (
              <SiteContainer key={cite.id} id={`cite-${cite.id}`}>
                <SiteText>
                  {cite.cite}
                </SiteText>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ImageCite src={cite.img} alt="Cite" />
                    <PersonContainer>
                      <PersonName>{cite.name}</PersonName>
                      <PersonPosition>{cite.position}</PersonPosition>
                    </PersonContainer>
                    <ImageCompany style={{ width: cite.widthCompany, height: cite.heightCompany }} src={cite.company} alt="Company" />
                  </div>
                </div>
              </SiteContainer>
            ))
          }
        </Sites>
        <KeyboardArrow onClick={swipeItem('right')} side="right" />
      </SitesContainer>
    </Container>
  );
};

export default ProductCites;
