import styled from 'styled-components';

import * as CONSTANTS from 'constants/styles';
import greyGridPath from 'assets/images/grey_grid.svg';
import icWorldWideWebGroupPath from 'assets/images/world_wide_web_group.svg';

export const Container = styled.div`
  background: ${CONSTANTS.COLORS.GREY_G5};
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 0 40px;
  z-index: 2;

  &:after {
    z-index: -1;
    position: absolute;
    margin-right: 0;
    margin-top: calc(100vh - 450px);
    margin-left: calc(100% - 300px);
    width: 300px;
    height: 350px;
    content: '';
    background-image: url(${greyGridPath});
  }

  @media (max-width: ${CONSTANTS.TABLET_MAX_WIDTH}px) {
    display: ${(props) => (props.finish ? 'flex' : 'none')};
  }

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const UnconfirmedEmailContainer = styled(Container)`
  &:before {
    z-index: 0;
    position: absolute;
    margin-right: 0;
    margin-top: calc(100vh - 330px);
    margin-left: calc(100% - 360px);
    width: 180px;
    height: 216px;
    content: '';
    background-image: url(${icWorldWideWebGroupPath});


    @media (max-width: ${CONSTANTS.MACBOOK_AIR_MAX_WIDTH}px) {
      bottom: 30px;
    }

    @media (max-width: ${CONSTANTS.HD_MAX_WIDTH}px) {
      bottom: 10px;
      height: 176px;
      bottom: 6px;
    }
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const BackToSiteContainer = styled.div`
  margin-top: 21px;
  width: 100%;
  text-align: right;
  padding-right: 100px;

  @media (max-width: ${CONSTANTS.TABLET_MAX_WIDTH}px) {
    display: ${(props) => (props.finish ? 'none' : 'block')};
  }
`;

export const ContentContainer = styled.div`
  margin-top: 185px;
  margin-left: 55px;
  width: 100%;

  @media (max-width: ${CONSTANTS.MACBOOK_AIR_MAX_WIDTH}px) {
    margin-top: 129px;
  }
`;

export const LoginContentContainer = styled(ContentContainer)`
  margin-left: 27px;

  @media (max-width: ${CONSTANTS.MACBOOK_AIR_MAX_WIDTH}px) {
    margin-top: 89px;
  }
`;

export const Header = styled.span`
  font-weight: 700;
  font-size: 24px;
  color: ${CONSTANTS.COLORS.BLACK_G1};
`;

export const GetProspectCite = styled.span`
  font-weight: 700;
  font-size: 24px;
  color: ${CONSTANTS.COLORS.DARKEST_BLUE};
`;

export const SubHeader = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: ${CONSTANTS.COLORS.GREY_G1};
  margin: 25px 0;
  text-align: left;
  line-height: 20px;
`;

export const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 30px);
  padding-bottom: 30px;
  z-index: 3;

  @media (max-width: ${CONSTANTS.MACBOOK_AIR_MAX_WIDTH}px) {
    padding-bottom: 0px;
    height: auto;
  }
`;

export const BenefitsImage = styled.img`
  width: 310px;
  height: 200px;

  @media (max-width: ${CONSTANTS.MACBOOK_AIR_MAX_WIDTH}px) {
    width: 230px;
    height: auto;
  }

  @media (max-width: ${CONSTANTS.HD_MAX_WIDTH}px) {
    width: 180px;
    height: auto;
  }
`;

export const ChromeIconImage = styled.img`
  width: 268px;
  height: 268px;
  position: absolute;
  bottom: 0;
  z-index: 3;
  @media (max-width: ${CONSTANTS.MACBOOK_AIR_MAX_WIDTH}px) {
    width: 240px;
    height: 240px;
  }

  @media (max-width: ${CONSTANTS.HD_MAX_WIDTH}px) {
    width: 200px;
    height: 200px;
  }
`;

export const UserProfilesImage = styled.img`
  width: 230px;
  height: 248px;
  position: absolute;
  bottom: 60px;
  left: 180px;
  z-index: 2;
  @media (max-width: ${CONSTANTS.MACBOOK_AIR_MAX_WIDTH}px) {
    width: 202px;
    height: 220px;
    bottom: 60px;
  }

  @media (max-width: ${CONSTANTS.HD_MAX_WIDTH}px) {
    width: 162px;
    height: 180px;
    bottom: 20px;
  }
`;

export const FishSignUpImage = styled.img`
  width: 128px;
  height: 186px;
  position: absolute;
  bottom: 197px;
  left: 400px;

  @media (max-width: ${CONSTANTS.MACBOOK_AIR_MAX_WIDTH}px) {
    left: 340px;
    bottom: 140px;
    height: 210px;
    width: 150px;
  }

  @media (max-width: ${CONSTANTS.HD_MAX_WIDTH}px) {
    left: 300px;
    bottom: 100px;
    height: 170px;
    width: 110px;
  }
`;

export const BenefitsContent = styled.div`
  z-index: 10;
`;

export const BenefitsColumnsWrapper = styled.div`
  display: flex;
`;

export const BenefitsColumn = styled.div`
`;

export const BenefitsTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  margin-bottom: 25px;
`;

export const BenefitItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  display: flex;
`;

export const BenefitItemTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: ${CONSTANTS.COLORS.BLACK_G1};
  margin-bottom: 2px;

  @media (max-width: ${CONSTANTS.MACBOOK_AIR_MAX_WIDTH}px) {
    font-size: 14px;
  }
`;

export const BenefitItemText = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: ${CONSTANTS.COLORS.GREY_G2};

  @media (max-width: ${CONSTANTS.MACBOOK_AIR_MAX_WIDTH}px) {
    font-size: 14px;
  }
`;

export const BenefitItemCircle = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${CONSTANTS.COLORS.GREY_G14};
  margin-right: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
