import { Modal } from 'antd';
import React, { useState } from 'react';

import { Button, RadioButton, RadioGroup } from 'components/common';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../../../../modals/baseModal/styles';

const Rating = ({
  min = 1,
  max = 10,
  onNext,
  onClose,
}) => {
  const [rating, setRating] = useState();

  return (
    <Modal
      visible
      title={(
        <ModalTitle>
          How satisfied are you with our product?
          <CloseIcon
            onClick={onClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={onClose}
    >
      <div>
        <RadioGroup value={rating} onChange={(e) => setRating(e.target.value ? Number(e.target.value) : e.target.value)}>
          {
            Array(max).fill(null).map((_, index) => (
              // eslint-disable-next-line
              <RadioButton value={index + min} key={index + min}>{index + min}</RadioButton>
            ))
          }
        </RadioGroup>
        <Footer>
          <Button
            data-form="true"
            type="primary"
            disabled={!rating}
            onClick={() => onNext({ rating })}
          >
            Next
          </Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default Rating;
