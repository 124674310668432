export default class StorageArray {
  /* Options:
    kind - sessionStorage, localStorage and etc.
  */
  constructor(name, options) {
    this.name = name;
    this.options = options;
    if (!options) {
      this.options = {
        kind: localStorage,
      };
    }
  }

  getStorage() {
    return this.options.kind;
  }

  /* fields - array of object { value: any, field: string } */
  findItem(fields) {
    const storage = this.getStorage();
    const raw = storage.getItem(this.name);
    if (!raw) return null;
    try {
      const array = JSON.parse(raw);
      const foundItem = array.find((item) => fields.every(({ field, value }) => item[field] === value));
      return foundItem;
    } catch {
      return null;
    }
  }

  addItem(item) {
    const storage = this.getStorage();
    let raw = storage.getItem(this.name);
    if (!raw) {
      raw = '[]';
    }
    try {
      const array = JSON.parse(raw);
      array.push(item);
      storage.setItem(this.name, JSON.stringify(array));
    // eslint-disable-next-line
    } catch {}
  }

  /* fields - array of object { value: any, field: string } */
  updateItem(fields, item) {
    const storage = this.getStorage();
    const raw = storage.getItem(this.name);
    if (!raw) {
      this.addItem(item);
      return;
    }
    try {
      let array = JSON.parse(raw);
      if (array.length === 0) {
        this.addItem(item);
        return;
      }
      let hasItem = false;
      array = array.map((_item) => {
        if (fields.every(({ field, value }) => _item[field] === value)) {
          hasItem = true;
          return item;
        }
        return _item;
      });
      if (!hasItem) {
        this.addItem(item);
        return;
      }
      storage.setItem(this.name, JSON.stringify(array));
    // eslint-disable-next-line
    } catch {}
  }

  /* fields - array of object { value: any, field: string } */
  removeItem(fields) {
    const storage = this.getStorage();
    const raw = storage.getItem(this.name);
    if (!raw) return;
    try {
      let array = JSON.parse(raw);
      array = array.filter((item) => !fields.every(({ field, value }) => item[field] === value));
      storage.setItem(this.name, JSON.stringify(array));
    // eslint-disable-next-line
    } catch {}
  }
}
