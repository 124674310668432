import styled from 'styled-components';
import * as CONSTANTS from 'constants/styles';
import { Button } from 'components/common';

export const SignUpTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GoogleIconStyle = {
  width: 20,
  height: 20,
};

export const ButtonTextWrapper = styled.span`
  margin-left: 10px;
`;

export const GoogleSignButton = styled(Button)`
  margin-top: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-weight: 500;
  background-color: ${CONSTANTS.COLORS.WHITE};
  border: 1px solid ${CONSTANTS.COLORS.GREY_G4};
  color: ${CONSTANTS.COLORS.BLACK_G1};

  &:hover {
    border: 1px solid ${CONSTANTS.COLORS.PRIMARY};
    color: ${CONSTANTS.COLORS.PRIMARY};
    background-color: ${CONSTANTS.COLORS.WHITE};
  }
`;
