import styled from 'styled-components';

import * as CONSTANTS from 'constants/styles';
import { RadioGroup, Radio } from 'components/common';

export const GroupName = styled.span`
  font-weight: 600;
  color: rgb(27, 28, 29);
  font-size: 13px;
  display: block;
  font-size: 15px;
  padding: 0 7px;
`;

export const FiltersGroup = styled.div`
  margin-top: 15px;
`;

export const BackIcon = { fontSize: 10 };

export const BackButton = styled.button`
  background: none;
  border: none;
  color: #0091ae;
  cursor: pointer;
  display: flex;
  padding: 0 7px;
  align-items: center;
  font-weight: 500;

  span {
    margin-left: 5px;
    line-height: 1;
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }

  &:focus {
    outline: 0;
  }
`;

export const SearchStyles = {
  height: 34,
  padding: '0 15px',
};

export const CollapseContainer = styled.div`
  .ant-collapse {
    height: fit-content;
    background-color: ${CONSTANTS.COLORS.WHITE};
  }

 .ant-collapse.collapsible-container {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px ${CONSTANTS.COLORS.GREY_G4};
    border-radius: 2px;
  }

  .ant-collapse.ant-collapse-icon-position-right {
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  .collapsible-container{
    margin: 15px 0;
  }

  .ant-collapse-content-box {
    padding: 0;
  } 

  .ant-collapse-header {
    font-family: 'AvenirNext';
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43 !important;
    letter-spacing: 0.25px !important;
    color: ${CONSTANTS.COLORS.GREY_G1} !important;

    .ant-tag {
      transition: none;
    }
  }

  .ant-collapse-content {
    border-top: none;
  }

  .ant-collapse-item {
    border-bottom: none;
  }

  .collapsible-container > .ant-collapse-item > div > .material-icons {
    transform: rotate(-90deg) !important;
    left: 10px !important;
    transition: all 0.2s ease;
  }

  .collapsible-container > .ant-collapse-item.ant-collapse-item-active > div > .material-icons {
    transform: rotate(0deg) !important;
    transition: all 0.2s ease;
  }

  .collapsible-container .ant-select-selection__choice {
    display: none;
  }

  .collapsible-container {
    .ant-select-selection .ant-select-selection__placeholder {
      display: block !important;
    }
    .ant-select-focused > .ant-select-selection .ant-select-selection__placeholder {
      display: none !important;
    }
  }

  .ant-spin {
    padding-top: 15px;
  }

  &:first-child {
      .ant-collapse.ant-collapse-icon-position-left.collapsible-container:first-child {
      margin: 0;
    }
  }

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    .ant-collapse.collapsible-container {
      box-shadow: none;
      border: solid 1px #DFE2E5;
      border-radius: 2px;
    }
  }
`;

export const PromptTitle = styled.div`
  font-family: AvenirNext;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.2px;
  color: ${CONSTANTS.COLORS.GREY_G1};
  margin-top: 20px;
  margin-bottom: 5px;
  padding: 0 15px;
`;

export const PromptOption = styled.div`
  font-family: AvenirNext;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: ${CONSTANTS.COLORS.GREY_G1};
  margin: 5px 0;
  padding: 0 15px;
`;

export const FilterItemIcon = styled.img`

`;

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0;
`;

export const ControlButton = styled.a`
  width: 100%;
  font-weight: 600;
  line-height: 1.71;
  text-align: left;
  min-width: 100px;
  color: ${CONSTANTS.COLORS.PRIMARY};

  &:hover {
    color: ${CONSTANTS.COLORS.PRIMARY};
    text-decoration: underline;
  }
`;

export const RadioGroupWrapper = styled(RadioGroup)`
 .ant-radio-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  margin-left: 15px;
  margin-bottom: 15px;
`;

export const InputRadioGroup = styled(RadioGroup)`
  display: inline-flex;
  margin: 0;
  margin-bottom: 5px;
  width: 100%;
  justify-content: space-between;
`;

export const InputRadio = styled(Radio)`
  font-size: 12px;

  label {
    margin: 0;
  }
`;

export const FiltersHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 15px 15px;
  background: ${CONSTANTS.COLORS.WHITE};
  max-height: 50px;
`;

export const FiltersParagraph = styled.div`
  display: flex;
  color: ${CONSTANTS.COLORS.PRIMARY};
  justify-content: right;
  align-items: end;
  padding-left: 20px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px ${CONSTANTS.COLORS.GREY_G4};
  color: ${CONSTANTS.COLORS.BLACK_G1};

  .dropdown-date {
    .ant-select-dropdown-menu-item-active {
      background-color: ${CONSTANTS.COLORS.PRIMARY_P4};
      color: ${CONSTANTS.COLORS.PRIMARY};
    }
  }
`;

export const HeaderText = styled.div`
  font-family: 'AvenirNext';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43 !important;
`;

export const CustomRenderLine = styled.div`
  display: flex;
  align-items: center;
  min-height: 32px;
  padding-left: 10px;
  cursor: pointer;

  span {
    color: ${CONSTANTS.COLORS.GREY_G1};
  }

  :hover {
    background-color: ${CONSTANTS.COLORS.PRIMARY_P4};

    span {
      color: ${CONSTANTS.COLORS.PRIMARY};
    }
  }

  svg {
    margin-right: 0;
  }
`;

export const LabelItem = styled.span`
  margin-left: 6px;
`;

export const CheckboxGroupWrapper = styled.div`
  .ant-checkbox-inner {
    width: 15px;
    height: 15px;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner:after {
      width: 6px;
      height: 10px;
      top: 40%;
      left: 2px;
    }
  }

  .ant-checkbox + span {
    padding-left: 6px;
    font-size: 14px;
  }
`;
