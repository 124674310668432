export const createNote = ({
  source,
  entityId,
  text,
  onSuccess,
  onFailed,
  isSidebar,
}) => (dispatch) => {
  dispatch({
    type: `CREATE_NOTE_FOR_${source.toUpperCase()}`,
    payload: {
      endpoint: 'api/v1/notes',
      method: 'POST',
      body: {
        source,
        entityId,
        text,
      },
      showResponseError: true,
      delayLoad: 0,
    },
    isSidebar,
    onSuccess,
    onFailed,
    components: ['createNoteLoader'],
  });
};
