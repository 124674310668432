import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
} from 'antd';

import { Input, Button } from 'components/common';

import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import {
  listsSelector,
} from 'data/selectors/lists';
import { updateList } from 'data/actions/lists';
import { toggleModal } from 'data/actions/modals';
import { selectFilter } from 'data/actions/contacts';
import { selectedFilterSelector } from 'data/selectors/contacts';
import {
  CloseIcon,
  ModalTitle,
  Label,
  Warning,
  InputStyle,
  Footer,
} from '../baseModal/styles';

const RenameListModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const selectedFilter = useSelector(selectedFilterSelector);
  const { data: lists } = useSelector(listsSelector);
  const modalOptions = useSelector(modalOptionsSelector);

  const listId = get(modalOptions, 'listId', '');
  const currentList = lists.find((item) => item._id === listId);
  const listName = get(currentList, 'name', '');

  const [name, updateName] = useState('');

  useEffect(() => {
    if (listName) updateName(listName);
  }, [listName]);
  const isExist = lists.findIndex((item) => item.name === name);

  const handleRenameList = () => {
    dispatch(toggleModal('rename_list', false));
    dispatch(updateList(listId, { name }));
    if (selectedFilter?.name) {
      dispatch(selectFilter({ ...selectedFilter, name }));
    }
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Rename the list
          <CloseIcon
            onClick={() => dispatch(toggleModal('rename_list', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={() => dispatch(toggleModal('rename_list', false))}
    >
      <>
        <Label>Name*</Label>
        <Input
          autoFocus
          onChange={(e) => {
            updateName(e.target.value);
          }}
          value={name}
          placeholder="Type the filter name..."
          style={InputStyle}
        />

        {isExist >= 0 && name !== listName && (
          <Warning>
            {`${name} already exists. Please choose a new name.`}
          </Warning>
        )}
      </>
      <Footer>
        <Button
          data-form={!name || isExist >= 0 ? 'false' : 'true'}
          type="primary"
          onClick={handleRenameList}
          disabled={!name || isExist >= 0}
        >
          Save
        </Button>
        <Button type="normal" onClick={() => dispatch(toggleModal('rename_list', false))}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default RenameListModal;
