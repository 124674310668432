import { contactsFilterSelector, selectedFilterSelector } from 'data/selectors/contacts';
import {
  deleteContactsFilter,
  renameContactsFilter,
  toggleShowAllSavedFilters,
  cloneContactsFilter,
  selectFilter,
} from 'data/actions/contacts';
import { companiesFilterSelector, selectedCompanyFilterSelector } from 'data/selectors/companies';
import {
  deleteCompaniesFilter,
  renameCompaniesFilter,
  toggleShowAllSavedFilters as companiesToggleShowAllSavedFilters,
  cloneCompaniesFilter,
  selectFilterCompany,
} from 'data/actions/companies';
import {
  contactsFavoriteFiltersSelector,
  companiesFavoriteFiltersSelector,
} from 'data/selectors/user';
import { SOURCE_TYPE } from 'data/types/source.types';

const getFilterActions = (sourceType) => {
  switch (sourceType) {
    case SOURCE_TYPE.COMPANY:
      return {
        filterSelector: companiesFilterSelector,
        favoriteFilterSelector: companiesFavoriteFiltersSelector,
        selectedFilterSelector: selectedCompanyFilterSelector,
        group: 'companiesFavoriteFilters',
        actionDelete: deleteCompaniesFilter,
        actionRename: renameCompaniesFilter,
        actionToggleShowAllSavedFilters: companiesToggleShowAllSavedFilters,
        actionClone: cloneCompaniesFilter,
        selectFilter: selectFilterCompany,
        titleAll: 'All companies',
      };
    case SOURCE_TYPE.CONTACT:
    default:
      return {
        filterSelector: contactsFilterSelector,
        favoriteFilterSelector: contactsFavoriteFiltersSelector,
        selectedFilterSelector,
        group: 'contactsFavoriteFilters',
        actionDelete: deleteContactsFilter,
        actionRename: renameContactsFilter,
        actionToggleShowAllSavedFilters: toggleShowAllSavedFilters,
        actionClone: cloneContactsFilter,
        selectFilter,
        titleAll: 'All contacts',
      };
  }
};

export default getFilterActions;
