import get from 'lodash/get';
import { Drawer } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button, Input,
} from 'components/common';
import useTinyForm from 'hooks/useTinyForm';
import * as CONSTANTS from 'constants/styles';
import { validateUrl } from 'helpers/validate';
import { toggleModal } from 'data/actions/modals';
import { updateContact } from 'data/actions/contacts';
import { modalsSelector } from 'data/selectors/modals';
import { contactPreviewBarButton } from 'data/selectors/loading';
import { contactPrimaryPosition, contactSelector } from 'data/selectors/contacts';
import PrimaryPosition from 'components/entityInfo/contact/editForm/editMainInfo/primaryPosition';
import { TwoItemsContainer, Label, EditWrapper } from 'components/entityInfo/common/styles';
import {
  Footer,
  ButtonStyle,
  BodyStyle,
} from '../../createContactBar/styles';
import { ModalTitle, CloseIcon } from '../../modals/baseModal/styles';
import { HeaderStyle } from '../styles';

const EditContactMainInfoPreviewBar = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const contact = useSelector(contactSelector);
  const loading = useSelector(contactPreviewBarButton);
  const primaryPosition = useSelector(contactPrimaryPosition);

  const closePreviewBar = () => dispatch(toggleModal('edit_contact_preview_bar', false));

  const {
    data,
    errors,
    handleChange,
    handleSubmit,
  } = useTinyForm({
    schema: {
      firstName: {
        value: get(contact, 'properties.firstName.value', ''),
      },
      lastName: {
        value: get(contact, 'properties.lastName.value', ''),
      },
      linkedinUrl: {
        value: get(contact, 'properties.linkedinUrl.value', ''),
        conditions: [
          {
            label: 'Must be valid url',
            condition: useCallback((link) => validateUrl(link, true), []),
          },
        ],
      },
      primaryPosition: {
        value: get(primaryPosition, 'value', ''),
      },
      location: {
        value: get(contact, 'properties.location.value', ''),
      },
    },
    onSubmit: (result) => {
      dispatch(updateContact({
        contactId: contact._id,
        status: 'updated',
        onSuccess: closePreviewBar,
        primaryPosition: {
          value: result.data.primaryPosition,
          options: (primaryPosition?.options || [result.data.primaryPosition]),
        },
        properties: [
          {
            property: 'firstName',
            value: result.data.firstName,
          },
          {
            property: 'lastName',
            value: result.data.lastName,
          },
          {
            property: 'linkedinUrl',
            value: result.data.linkedinUrl,
          },
          {
            property: 'location',
            value: result.data.location,
          },
        ],
      }));
    },
  });

  return (
    <Drawer
      title={(
        <ModalTitle>
          Edit contact
          <CloseIcon
            data-close="true"
            onClick={closePreviewBar}
            className="ic_close"
          />
        </ModalTitle>
      )}
      headerStyle={HeaderStyle}
      bodyStyle={BodyStyle}
      placement="right"
      onClose={closePreviewBar}
      visible={visible}
      width={CONSTANTS.DRAWER_WIDTH}
      closable={false}
      zIndex={CONSTANTS.Z_INDEXES.MOBILE_MODAL}
    >
      <EditWrapper style={{ padding: 0, width: '100%' }}>
        <TwoItemsContainer>
          <div>
            <Label>
              First name
            </Label>
            <Input name="firstName" onChange={handleChange} value={data.firstName} size="default" />
          </div>
          <div>
            <Label>
              Last name
            </Label>
            <Input name="lastName" onChange={handleChange} value={data.lastName} size="default" />
          </div>
        </TwoItemsContainer>
        <div style={{ marginBottom: errors.linkedinUrl ? 10 : 0 }}>
          <Label>
            LinkedIn link
          </Label>
          <Input error={errors.linkedinUrl} name="linkedinUrl" onChange={handleChange} value={data.linkedinUrl} size="default" />
        </div>
        <div>
          <Label>
            Primary position
          </Label>
          <PrimaryPosition value={data.primaryPosition} handleChange={handleChange} />
        </div>
        <div>
          <Label>
            Location
          </Label>
          <Input name="location" onChange={handleChange} value={data.location} size="default" />
        </div>
      </EditWrapper>
      <Footer>
        <Button
          data-form="true"
          type="primary"
          size="large"
          style={ButtonStyle}
          loading={loading}
          onClick={handleSubmit}
        >
          Save
        </Button>
        <Button
          type="normal"
          size="large"
          onClick={closePreviewBar}
        >
          Cancel
        </Button>
      </Footer>
    </Drawer>
  );
};

export default EditContactMainInfoPreviewBar;
