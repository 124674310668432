import React from 'react';
import {
  CustomInputNumber as InputNumber,
} from 'components/commonStyles';

const CustomInputNumber = (props) => (
  <InputNumber InputField={InputNumber} {...props} /> // eslint-disable-line
);

export default CustomInputNumber;
