import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'antd';

import { Select, Option } from 'components/common';
import { updateCurrentList } from 'data/actions/lists';
import { listFiltersSelector } from 'data/selectors/lists';
import {
  MobileContainer,
  SelectContainer,
  SelectStyle,
  OptionStyle,
  IconInfoStyle,
  ListType,
} from './styles';
import PropertiesList from '../properties';
import FiltersList from '../filters';

const MobileListFilter = ({
  currentList,
  match,
}) => {
  const dispatch = useDispatch();
  const filters = useSelector(listFiltersSelector);
  const listId = get(match, 'params.listId', '');

  const [showFilters, toggleShowFilters] = useState(true);
  const dynamic = get(currentList, 'dynamic', null);

  const handleChange = (value) => {
    const isDynamic = value === 'Active list';
    dispatch(updateCurrentList('dynamic', isDynamic));
  };

  const handleDeleteFilter = (filter) => {
    const newFilters = filters.filter((item) => {
      if (filter._id) return item._id !== filter._id;
      return item.property !== filter.property;
    });
    dispatch(updateCurrentList('filters', newFilters));
  };

  return (
    <MobileContainer>
      {listId ? (
        <SelectContainer>
          <ListType>
            {dynamic ? 'Active list' : 'Static list'}
          </ListType>
          <Icon style={IconInfoStyle} type="info-circle" theme="filled" />
        </SelectContainer>
      ) : (
        <SelectContainer>
          <Select
            defaultValue="Active list"
            style={SelectStyle}
            onChange={handleChange}
            size="large"
          >
            <Option style={OptionStyle} value="Active list">Active list</Option>
            <Option style={OptionStyle} value="Static list">Static list</Option>
          </Select>
          <Icon style={IconInfoStyle} type="info-circle" theme="filled" />
        </SelectContainer>
      )}
      {filters.length && showFilters ? (
        <FiltersList
          filters={filters}
          toggleShowFilters={toggleShowFilters}
          handleDeleteFilter={handleDeleteFilter}
          dynamic={dynamic}
          listId={listId}
        />
      ) : (
        <PropertiesList
          filters={filters}
          toggleShowFilters={toggleShowFilters}
          showFilters={showFilters}
        />
      )}
    </MobileContainer>
  );
};

export default withRouter(MobileListFilter);
