export const removeSignature = (content) => {
  const document = new DOMParser().parseFromString(content, 'text/html');
  const signatureElement = document.querySelector('.signature-container');
  if (signatureElement) {
    signatureElement.innerHTML = '';
  }
  const contentWithoutSignature = document.body.innerHTML;
  return contentWithoutSignature;
};

export const getSignature = (account) => {
  const { signature } = account ?? {
    signature: `
      <p>----</p>
      <p>Best regards,</p>
      <p>[Full name]</p>
    `,
  };
  const html = signature || "<p>There isn't a signature. Click to add</p>";
  return html;
};
