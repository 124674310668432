import React from 'react';

import { Icon } from 'components/common';
import useGoogleOauth from 'hooks/useGoogleOauth';
import { Button, Label } from './styles';

const ConnectGoogleButton = ({
  width, disabled, sequenceId, signIn = false, onClick,
}) => {
  const { connectGmailAccount } = useGoogleOauth({ sequenceId });

  return (
    <Button style={{ width }} disabled={disabled} onClick={onClick || connectGmailAccount}>
      <Icon style={{ height: 18, width: 18, marginRight: 15 }} type="ic-google" />
      <Label>
        Sign
        {' '}
        {signIn ? 'in' : 'up'}
        {' '}
        with Google
      </Label>
    </Button>
  );
};

export default ConnectGoogleButton;
