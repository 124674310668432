import { Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createList, setCurrentList } from 'data/actions/lists';
import { toggleModal } from 'data/actions/modals';
import { Button, Input } from 'components/common';
import { toggleMessage } from 'data/actions/message';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { get } from 'lodash';
import {
  CloseIcon,
  ModalTitle,
  Label,
  InputStyle,
  Footer,
  Warning,
} from '../baseModal/styles';

const CreateListModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const options = useSelector(modalOptionsSelector);
  const [name, updateName] = useState('');
  const [newList, setNewList] = useState({});
  const [isExist, toggleIsExist] = useState(false);

  useEffect(() => {
    updateName('');
  }, [visible]);

  useEffect(() => {
    if (newList?._id && options?.history) {
      dispatch(toggleModal('create_list', false));
      dispatch(setCurrentList(newList));
      options.history.push(`/contacts/list/${newList._id}`);
    }
  }, [dispatch, newList, options.history]); // eslint-disable-line

  const onSuccess = (result) => {
    if (get(result, 'isExist', false)) toggleIsExist(true);
    else {
      dispatch(toggleMessage('success', { header: 'List was created!' }));
      if (options?.history) {
        setNewList(result);
      } else dispatch(toggleModal('create_list', false));
    }
  };

  const handleCreateList = () => {
    dispatch(createList({ name, dynamic: false }, onSuccess));
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Create a new list
          <CloseIcon
            onClick={() => dispatch(toggleModal('create_list', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={() => dispatch(toggleModal('create_list', false))}
    >
      <div>
        <>
          <Label>Name*</Label>
          <Input
            autoFocus
            onChange={(e) => {
              updateName(e.target.value.trimStart());
              if (isExist) toggleIsExist(false);
            }}
            value={name}
            placeholder="Type the list name..."
            style={InputStyle}
          />
          {isExist && !newList?._id && (
            <Warning>
              {`List ${name} already exists. Please choose a new name.`}
            </Warning>
          )}
        </>
        <Footer>
          <Button
            data-form={!name || isExist ? 'false' : 'true'}
            type="primary"
            onClick={handleCreateList}
            disabled={!name || isExist}
            data-tour="create-list-tour-step-2"
          >
            Save
          </Button>
          <Button type="normal" onClick={() => dispatch(toggleModal('create_list', false))}>Cancel</Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default withRouter(CreateListModal);
