import React, { useState, useEffect } from 'react';
import {
  Modal, Tree, Icon,
} from 'antd';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';

import { contactsFoldersSelector } from 'data/selectors/lists';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { getFolderById, getAllChildrenIds } from 'helpers/folderTree';
import { moveFolder } from 'data/actions/lists';

import {
  Container,
} from './styles';

import {
  Footer,
  ModalTitle,
  CloseIcon,
  Label,
} from '../baseModal/styles';

const { TreeNode, DirectoryTree } = Tree;

const MoveFolderModal = () => {
  const dispatch = useDispatch();

  const folders = useSelector(contactsFoldersSelector);
  const { visible } = useSelector(modalsSelector);
  const folderId = get(useSelector(modalOptionsSelector), 'folderId');
  const folderRoot = get(folders, '_id', '');

  const [currentFolderId, setFolderId] = useState(null);
  const [allChildrenIds, setAllChildrenIds] = useState([]);
  const [folderName, setFolderName] = useState('');

  const folderToMove = getFolderById(currentFolderId, folders);
  const folderFrom = getFolderById(folderId, folders);

  useEffect(() => {
    setFolderId(get(folderFrom, 'parent') || folderRoot);
    const tree = getFolderById(folderId, folders);
    const folderIds = getAllChildrenIds(tree, [tree.parent, folderId]);
    setFolderName(tree.name);
    setAllChildrenIds(folderIds);
  }, [visible]);  // eslint-disable-line

  const onSelect = (keys) => {
    setFolderId(keys[0]);
  };

  const getFolderName = (folder) => {
    const name = get(folder, 'name') === 'root' || !get(folder, 'name')
      ? 'All lists'
      : get(folder, 'name');
    return name;
  };

  const generateFoldersStructure = (data) => {
    if (data.children) {
      return data.children.map((item) => {
        if (item.children) {
          return (
            <TreeNode disabled={allChildrenIds.includes(item._id || item.parent)} title={item.name} key={item._id}>
              {generateFoldersStructure(item)}
            </TreeNode>
          );
        }
        return (<TreeNode disabled={allChildrenIds.includes(item._id || item.parent)} title={item.name} key={item._id} />);
      });
    }
    return null;
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Move folder
          <CloseIcon
            className="ic_close"
          />
        </ModalTitle>
      )}
      width={500}
      footer={false}
      closable={false}
      onCancel={() => dispatch(toggleModal('move_folder', false))}
    >
      <Container>
        <Label>{`Moving ${folderName}`}</Label>
        <span> from </span>
        <b key="list-folder-from-name">{getFolderName(folderFrom)}</b>
        <span> to </span>
        <b>{getFolderName(folderToMove)}</b>
        <DirectoryTree
          switcherIcon={<span />}
          multiple
          defaultExpandAll
          onSelect={onSelect}
          defaultSelectedKeys={[currentFolderId]}
          selectedKeys={[currentFolderId]}
          expandAction={false}
        >
          <TreeNode
            icon={<Icon type="user" />}
            title="All folders"
            key={folderRoot}
            disabled={allChildrenIds.includes(get(folderToMove, 'parent') || folderRoot)}
          />
          {generateFoldersStructure(folders)}
        </DirectoryTree>
      </Container>
      <Footer>
        <Button
          data-form={!folderId ? 'false' : 'true'}
          type="primary"
          onClick={() => {
            dispatch(toggleModal('move_folder', false));
            dispatch(moveFolder(folderId, currentFolderId));
          }}
          disabled={!folderId}
        >
          Save
        </Button>
        <Button type="default" onClick={() => dispatch(toggleModal('move_folder', false))}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default withRouter(MoveFolderModal);
