import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { deleteAttachmentsFromStep, deleteAttachmentsFromTemplate } from 'data/actions/sequences';
import { deleteFilesFromStepLoaderSelector } from 'data/selectors/loading';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import {
  ModalTitle, CloseIcon, Footer,
} from '../baseModal/styles';

const DeleteFileFromStepModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const {
    stepId,
    version,
    fileName,
    fileId,
    previousModal,
    templateId,
    ...rest
  } = useSelector(modalOptionsSelector);

  const loading = useSelector(deleteFilesFromStepLoaderSelector);

  const handleClose = () => {
    if (previousModal) {
      dispatch(toggleModal(previousModal, true, {
        stepId,
        version,
        templateId,
        ...rest,
      }));
    } else {
      dispatch(toggleModal('delete_file_from_step', false));
    }
  };

  const handleDelete = () => {
    if (templateId) {
      dispatch(deleteAttachmentsFromTemplate({
        templateId,
        attachmentIds: [fileId],
        onSuccess: () => {
          dispatch(toggleMessage('success', {
            header: `File ${fileName} has been deleted`,

          }));
          setImmediate(handleClose);
        },
      }));
    } else {
      dispatch(deleteAttachmentsFromStep({
        stepId,
        attachmentIds: [fileId],
        version,
        onSuccess: () => {
          dispatch(toggleMessage('success', {
            header: `File ${fileName} has been deleted`,
          }));
          setImmediate(handleClose);
        },
      }));
    }
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Delete the file
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        <span>
          Are you sure you want to delete the file
        </span>
        &nbsp;
        <strong>
          {fileName}
        </strong>
        <span>
          ?
        </span>
      </div>
      <Footer>
        <Button
          type="danger"
          size="large"
          data-form="true"
          loading={loading}
          onClick={handleDelete}
        >
          Delete
        </Button>
        <Button
          size="large"
          type="default"
          onClick={handleClose}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default DeleteFileFromStepModal;
