import styled, { keyframes } from 'styled-components';
import { headShake } from 'react-animations';
import {
  DatePicker, Input, Select,
} from 'antd';

import * as CONSTANTS from 'constants/styles';
import {
  TextArea,
  Button,
  Icon,
} from 'components/common';

const headShakeAnimation = keyframes`${headShake}`;

export const Footer = styled.div`
  width: 100%;
  margin-top: auto;
  background-color: #f5f8fa;
  border-top: 1px solid #cbd6e2;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button:disabled {
    background-color: #f7f8fa!important;
    color: #bec4ca!important;
  }

  button {
    text-shadow: none !important;
    box-shadow: none !important;
    line-height: 1.71;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    text-align: center;
    font-style: normal;
    letter-spacing: normal;
  }
`;

export const WhiteButton = styled(Button)`
  color: ${CONSTANTS.COLORS.GREY_G1};
`;

export const BlueButton = styled(Button)`
  letter-spacing: 'normal';
  margin-right: '10px';
`;

export const BodyStyle = (height) => ({
  padding: 0,
  height: `calc(100% - ${height ?? 81}px)`,
  overflow: 'auto',
  flexDirection: 'column',
});

export const IconCaretDown = {
  color: '#7c98b6',
  fontSize: '10px',
};

export const IconSelect = {
  height: 14,
  width: 14,
};

export const IconSelectColumn = {
  marginRight: 15,
  cursor: 'pointer',
};

export const IconSelectAction = {
  marginLeft: 10,
  width: 14,
};

export const CompanyDataValue = styled(Input)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: none;
  outline: none;
  font-family: 'AvenirNext';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${CONSTANTS.COLORS.GREY_G1};
  
  &:hover {
    input {
      color: #1890ff;
    }
    span {
      display: flex!important;
    }
  }

  input {
    box-shadow: none!important;
    outline: none!important;
    border: none;
    cursor: text;
    padding: 0;
    border-color: none;

    &:focus {
      border-bottom: 1px solid ${CONSTANTS.COLORS.PRIMARY} !important;
    }

    &:focus + span .action {
      display: none;
    }
  }

  span.ant-input-suffix {
    right: 0;
    display: none;
  };

  span.ant-input-prefix {
    left: 0;
  }

  .ant-input:not(:first-child) {
    padding-left: 20px;
  }
`;

export const Body = styled.div`
  ${(props) => props.marginBottom && `
    margin-bottom: ${props.marginBottom}px;
  `}

 .ant-collapse {
    background: #ffffff;
    border: none;
  }

  .ant-collapse-item {
    border: none;
  }

  .ant-collapse-item-active .ant-collapse-header {
    border-bottom: 1px solid transparent;
  }

  .ant-collapse-header {
    .ant-collapse-arrow {
      margin-left: 5px;
    }
    
    font-family: 'AvenirNext';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: #4f5964;
    border-bottom: 1px solid ${CONSTANTS.COLORS.GREY_G4};
    background-color: #fff !important;
    padding: 20px 20px 20px 45px!important;
  }

  .ant-collapse-content-box {
    padding-left: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-collapse-content {
    border-top: none;
  }
`;

export const HeaderStyle = {
  backgroundColor: '#2565c7',
  color: '#ffffff',
  fontFamily: 'AvenirNext',
  fontSize: '18px',
  fontWeight: '600',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.67',
  letterSpacing: 'normal',
  borderRadius: '0px',
  height: CONSTANTS.HEADER_HEIGHT,
  borderBottom: 0,
  padding: '17px 20px',
  display: 'flex',
  alignItems: 'center',
};

export const MenuItem = styled.button`
  cursor: pointer;
  color: #33475b;
  display: block;
  min-height: 40px;
  padding: 8px 20px;
  background-color: #fff;
  line-height: 24px;
  text-align: left;
  margin: 0 -16px;
  width: calc(100% + 32px);
  background: no-repeat;
  border: none;
  outline: none;
  min-width: 180px;

  &:hover {
    background-color: ${CONSTANTS.COLORS.BLUE_B7};
  }

  &:disabled {
    opacity: .6;
    cursor: not-allowed;
  }
`;

export const PopoverTitle = styled.a`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #1890ff;
  display: flex;
  align-items: center;

  &:hover {
    & > :first-child {
      text-decoration: underline;
    }
  }
`;

export const Error = styled.div`
  color: red;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 500;
`;

export const CompanyHeader = styled.div`
  display: flex;
  width: 100%;
`;

export const PropertyItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 25px 12px 25px;
  position: relative;

  :last-child {
    padding-bottom: 0;
  }

  .ant-select-selection--single {
    box-shadow: none!important;
    outline: none!important;
    border: none!important;
    cursor: text;
    padding: 0;
    border-color: none;
    cursor: pointer;

    i {
      color: #1890ff;
    }

    .ant-select-selection__rendered {
      margin-left: 0;
    }

    &:focus {
      border: none;
      color: #1890ff;
      outline: none;
      box-shadow: none;
      border-radius: 0;
      border-bottom: solid 1px #1890ff;
    }
  } 

  .ant-input:not(:last-child) {
    padding-right: 0;
  }

  .edit {
      display: none;

      &:hover {
        display: flex;
        cursor: pointer;
      }
    }

  input {
    border: none;
    border-radius: 0;
    border-bottom: ${(props) => (props.isFilledRequired ? `solid 1px ${CONSTANTS.COLORS.RED} !important` : 'none')};
    padding-left: 0px;
    padding-top: 0px;
    padding-right: 0px;

    &:hover + span .edit {
      display: flex;
    }

    &:hover + span  .dropdown {
      display: none;
    }

    &:focus + span .edit {
      background-color: red;
      display: none;
    }
    &:focus + span .dropdown{
      display: flex !important;
    }
    &:not(:focus) + span .dropdown{
      display: none;
    }
    &:hover {
      color: #1890ff;
    }
    &:focus {
      border: none;
      color: #1890ff;
      outline: none;
      box-shadow: none;
      border-radius: 0;
      border-bottom: ${(props) => (props.isFilledRequired ? `solid 1px ${CONSTANTS.COLORS.RED} !important` : 'solid 1px #1890ff')}
    }
  }
`;

export const ModalIcon = styled(Icon)`
  cursor: pointer;
`;

export const Label = styled.label`
  font-family: AvenirNext;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #979fa8;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ButtonStyle = {
  fontWeight: '600',
  fontSize: 14,
};

export const PreviewIconWrapper = styled.div`
  opacity: ${(props) => (props.focused ? 1 : 0)};
  align-self: flex-start;
  margin-top: -2px;
`;

export const PreviewTextAreaWrapper = styled.div`
  position: relative;
  display: flex;
  width: fit-content;
  min-height: 32px;
  
  &:hover {
    ${PreviewIconWrapper} {
      opacity: 1;
    }

    .ant-input {
      color: ${(props) => (props.readOnly ? CONSTANTS.COLORS.GREY_G1 : CONSTANTS.COLORS.PRIMARY)};
      cursor: ${(props) => (props.readOnly ? 'default' : 'edit')}
    }

    a {
      color: ${CONSTANTS.COLORS.PRIMARY};
      cursor: 'default';
    }
  }
  
  ${(props) => props.readOnly && `
    .ant-input {
      border: none;
    }
  `}

   ${(props) => (!props.readOnly && props.focused && props.multipleInput && !props.uncovered ? `
      &:after {
        content: '';
        width: 100%;
        height: 14px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: -webkit-linear-gradient(top,rgba(255,255,255,.2) 0,#fff 100%);
        background-image: linear-gradient(top,rgba(255,255,255,.2) 0,#fff 100%);
        background-image: -moz-linear-gradient(top,rgba(255,255,255,.2) 0,#fff 100%);
        background-image: -o-linear-gradient(top,rgba(255,255,255,.2) 0,#fff 100%);
        background-image: --linear-gradient(top,rgba(255,255,255,.2) 0,#fff 100%);
      }
  ` : '')}

  .animated {
    animation: 1s ${headShakeAnimation};
  }
`;

export const PreviewTextArea = styled(TextArea).attrs(() => ({ autoSize: true }))`
  resize: none;
  border-width: 0;
  border-radius: 0;
  overflow-y: hidden;
  padding: 0;
  max-width: 314px !important;
  font-size: 14px;

  &.ant-input {
    width: 308px;
    padding-left: ${(props) => (props.isblur ? '2px' : '')};
    color: ${(props) => (props.isblur ? 'transparent !important' : CONSTANTS.COLORS.GREY_G1)};
    text-shadow: ${(props) => (props.isblur ? `0 0 4px ${CONSTANTS.COLORS.GREY_G1}` : '')};
    pointer-events: ${(props) => (props.isblur ? 'none' : '')};
    
    &:hover, &:focus {
      border-bottom: ${(props) => (props.readOnly ? '0 !important' : `1px solid ${CONSTANTS.COLORS.PRIMARY} !important`)};
      color: ${(props) => (props.readOnly ? `${CONSTANTS.COLORS.GREY_G1} !important` : `${CONSTANTS.COLORS.PRIMARY} !important`)};
    }
  }
  
  &:focus {
    box-shadow: none;
    border-bottom-width: 1px;
    border-right-width: 0 !important;

    ${(props) => !props.readOnly && `
      &.ant-input {
        color: ${props.isblur ? 'transparent !important' : CONSTANTS.COLORS.PRIMARY};
      }
    `}
  }
  
  &:hover {
    border-right-width: 0 !important;

    ${(props) => !props.readOnly && `
      &.ant-input {
        color: ${props.isblur ? 'transparent !important' : CONSTANTS.COLORS.PRIMARY};
      }
    `}

    ${(props) => props.link && props.readOnly && `
      &.ant-input {
        color: ${CONSTANTS.COLORS.PRIMARY};
        cursor: pointer;
      } 
    `}  
  }
  
  ${(props) => props.focused && !props.uncovered && `
    max-height: 150px !important;
  `}
`;

export const PreviewLink = styled.a`
  cursor: pointer;
  padding-right: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 314px !important;
  color: inherit;
`;

export const PreviewText = styled(Input)`
  font-size: 14px;
  padding: 0;
  width: 308px;
  padding-left: ${(props) => (props.isblur ? '2px' : '')};
  color: ${(props) => (props.isblur ? 'transparent' : CONSTANTS.COLORS.GREY_G1)};
  text-shadow: ${(props) => (props.isblur ? `0 0 4px ${CONSTANTS.COLORS.GREY_G1}` : '')};
  pointer-events: ${(props) => (props.isblur ? 'none' : '')};

  &:hover, &:focus {
    border-bottom: ${(props) => (props.readOnly ? '0 !important' : `1px solid ${CONSTANTS.COLORS.PRIMARY} !important`)};
    color: ${(props) => (props.readOnly ? `${CONSTANTS.COLORS.GREY_G1} !important` : `${CONSTANTS.COLORS.PRIMARY} !important`)};
  }
`;

export const PreviewLoader = styled.div`
  width: 100%;
  height: 100vh;
  display: block;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const TooltipIcon = styled.span`
  border-radius: 50%;
  background-color: #979fa8;
  color: ${CONSTANTS.COLORS.WHITE};
  font-size: 10px;
  font-weight: 600;
  padding: 5px;
  cursor: pointer;
  margin-left: 5px;
`;

export const Date = styled(DatePicker)`
  & i {
    opacity: ${(props) => (props.focused ? '1' : '0')};
  }

  & svg {
    fill: ${CONSTANTS.COLORS.PRIMARY};
  }

  &:hover {
    & i {
      opacity: 1;
    }
  }
`;

export const Selection = styled(Select)`
  width: 100%;
  font-size: 14px;


  & i, svg {
    opacity: ${(props) => (props.focused ? '1' : '0')};
  }

  &:hover {
    & i, svg {
      opacity: 1;
    }
    & .ant-select-selection-selected-value {
      color: ${CONSTANTS.COLORS.PRIMARY};
    }
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  width: 100%;

  &:hover {
    ${PreviewIconWrapper} {
      opacity: 1;
    }
  }
  
  & .ant-select-selection__rendered {
    margin-left: 0;
  }
`;

export const MultipleSelection = styled(Select)`
  width: 308px;
  
  & .ant-select-selection {
    cursor: default;
    border: 0;
    background: ${CONSTANTS.COLORS.WHITE};
    box-shadow: 0 0 black;
  }

  .ant-select-selection__choice {
    margin-right: 8px;
    font-weight: 500;
    border-radius: 6px;
    margin-top: 8px !important;
  }

  & .ant-select-selection__rendered {
    margin-left: 0;

    li {
      &:hover {
        .ant-select-selection__choice__content {
          color: ${(props) => (props.disabled ? CONSTANTS.COLORS.GREY_G1 : CONSTANTS.COLORS.PRIMARY)} !important;
        }
      }
    }
  }

  ${(props) => props.disabled && `
    .ant-select-selection__choice__content {
      color: ${CONSTANTS.COLORS.GREY_G1} !important;
    }
  `}
`;

export const JobTitle = styled.div`
  text-align: center;
`;

export const CompanyHeaderWrapper = styled.div`
`;

export const EmailStatusIconContainer = styled.div`
    margin-right: 10px;
`;

export const SearchPanelWrapper = styled.div`
 .ant-collapse-header {
  padding-left: 20px !important;
 }

 .anticon-loading {
    font-size: 16px;
  }
`;

export const SocialLinksContainer = styled.div`
  display: flex;
`;

export const SocialLink = styled.a`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin-right: 5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #DFE2E5;

  &:hover {
    background: #1890FF;
    border: 1px solid #1890FF;

    svg path  {
      fill: #FFFFFF;
    }
  }
`;

export const DomainAndLinksContainer = styled.div`
  display: flex;
  align-items: flex-end;

  a {
    margin-right: 10px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.div`
  width: 100%;
  color: ${CONSTANTS.COLORS.WHITE};
  font-weight: 600;
  font-size: 18px;
  text-overflow: ellipsis;
  overflow: hidden;            
  white-space: nowrap;
`;

export const TitleWithIcon = styled(Title)`
  display: flex;
  align-items: center;
`;
