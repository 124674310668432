import React from 'react';

import {
  Button,
} from 'antd';

import { Icon } from 'components/common';
import {
  FiltersContainer,
  FilterName,
  FilterOperator,
  FilterItem,
  FilterHeaderOption,
  FilterHeaderContainer,
  AddFilterButton,
  StaticFilterTitle,
} from './styles';

const FiltersList = ({
  filters,
  toggleShowFilters,
  handleDeleteFilter,
  dynamic,
  listId,
}) => {
  const generateOperator = (operator) => {
    switch (operator) {
      case 'EQ':
        return 'is equal to any of';
      case 'NEQ':
        return 'is equal to any of';
      case 'HAS_NOT_PROPERTY':
        return 'is equal to any of';
      case 'HAS_PROPERTY':
        return 'is equal to any of';
      default:
        return 'is equal to any of';
    }
  };
  return (
    <>
      {
        filters.map((item) => (
          <div key={`filter_item_${item.property}`}>
            {listId && !dynamic ? (
              <>
                <StaticFilterTitle>
                  Static list filters can&apos;t be edited once the list is saved
                </StaticFilterTitle>
                <FiltersContainer>
                  <FilterItem>
                    <FilterName>{item.property}</FilterName>
                    <FilterOperator>{generateOperator(item.operator)}</FilterOperator>
                    {
                      !Array.isArray(item.value)
                        ? (<b>{item.value}</b>)
                        : item.value.map((val, index) => <b key={`filter-option-${index + 2}`}>{index === 0 || item.value.length + 1 === index ? val : ` or ${val} `}</b>)
                    }
                  </FilterItem>
                </FiltersContainer>
              </>
            )
              : (
                <>
                  <FiltersContainer>
                    <FilterItem>
                      <FilterName>{item.property}</FilterName>
                      <FilterOperator>{generateOperator(item.operator)}</FilterOperator>
                      {
                        !Array.isArray(item.value)
                          ? (<b>{item.value}</b>)
                          : item.value.map((val, index) => <b key={`filter-option-${index + 2}`}>{index === 0 || item.value.length + 1 === index ? val : ` or ${val} `}</b>)
                      }
                      <Icon type="ic-delete" onClick={() => handleDeleteFilter(item)} />
                    </FilterItem>

                    <FilterHeaderContainer>
                      <FilterHeaderOption>
                        Clone
                      </FilterHeaderOption>
                      <FilterHeaderOption onClick={() => handleDeleteFilter(item)}>
                        Delete
                      </FilterHeaderOption>
                    </FilterHeaderContainer>

                  </FiltersContainer>
                </>
              )}
          </div>
        ))
      }
      {(listId && !dynamic) ? null : (
        <Button
          type="primary"
          block
          style={AddFilterButton}
          onClick={() => toggleShowFilters(false)}
        >
          Add filter
        </Button>
      )}
    </>
  );
};

export default FiltersList;
