import React, { useEffect, useState } from 'react';

import { isNumber } from 'helpers/regularExpressions';
import { Container, Input, ValidationStatus } from './styles';

const CodeInput = ({
  size,
  error,
  updateCode,
  resetCode,
}) => {
  const [code, setCode] = useState(Array(size).fill(''));

  useEffect(() => {
    updateCode(code.join(''));
  }, [code, updateCode]);

  useEffect(() => {
    if (resetCode) setCode(Array(size).fill(''));
  }, [resetCode, size]);

  return (
    <div>
      <Container className="code-input-container">
        {
          code.map((value, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`code-${index}`}>
              <Input
                error={error}
                autoFocus={index === 0}
                id={`code-${index}`}
                autoComplete="off"
                value={value}
                onChange={(e) => {
                  const currentCode = code;
                  if (!e.target.value || (isNumber(e.target?.value) && Number(e.target.value) < 10)) {
                    currentCode[index] = e.target.value ? Number(e.target.value) : e.target.value;
                    const nextCodeDOM = document.querySelector(`#code-${typeof currentCode[index] === 'number' ? index + 1 : index - 1}`);
                    if (nextCodeDOM) nextCodeDOM.focus();
                    setCode([...currentCode]);
                  } else if (isNumber(e.target.value) && e.target.value.length === size) {
                    setCode(e.target.value.split('').map(Number));
                  }
                }}
              />
            </div>
          ))
        }
      </Container>
      <ValidationStatus error={error}>
        {error}
      </ValidationStatus>
    </div>
  );
};

export default CodeInput;
