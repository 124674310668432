import styled from 'styled-components';

export const ProcessedFileContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ImportSearchCloseIcon = {
  marginLeft: 10,
  fontSize: 11,
  color: 'rgb(124, 152, 182)',
  cursor: 'pointer',
};
