import { Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input } from 'components/common';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';

import {
  updateContactsConditions,
  setSelectedContacts,
  setContactsParams,
} from 'data/actions/contacts';
import { closeAnyModal, toggleModal } from 'data/actions/modals';
import { ConfirmationContainer, Container } from './styles';
import {
  ModalTitle, CloseIcon, Footer,
} from '../modals/baseModal/styles';
import { setOperation } from '../../data/actions/storeTriggers';

const DeleteContacts = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const {
    contactId,
    companyId,
    name,
    setSelectedRowKeys,
    operationType = 'delete',
    excludedRowKeys = [],
    onClickYes,
    openSidebarOnClose,
    search,
    filters,
    selectAll,
    itemIds,
    totalItems,
    listId,
  } = useSelector(modalOptionsSelector);

  const [inputValue, setInputValue] = useState();
  const contactsIds = name ? [contactId] : itemIds;
  const contactCount = selectAll ? totalItems - excludedRowKeys.length : contactsIds.length;

  const handleClose = () => {
    dispatch(closeAnyModal());
  };
  const refreshContacts = () => {
    dispatch(setContactsParams({
      listId,
      page: 1,
      findCount: true,
      pageSize: 25,
      components: ['contactsListTable'],
    }));
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger={(operationType !== 'restore')}>
          {name ? `Delete the contact ${name} ?` : `Bulk ${(operationType === 'restore') ? 'restore' : 'delete'} contacts`}
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <Container>
        <div>
          You&#39;re about to
          {' '}
          {(operationType === 'permanent_delete') && 'permanently'}
          {' '}
          {(operationType === 'restore') ? 'restore' : 'delete'}
          {name ? ' this contact' : ` ${contactCount} contacts`}
          {'. '}
          {(operationType !== 'restore') && ((operationType === 'permanent_delete') ? "This action can't be undone." : "Deleted contacts can't be restored after 60 days.")}
        </div>
        {!name && (operationType !== 'restore') && (
          <ConfirmationContainer>
            <b>Type the number of contacts below to delete</b>
            <Input
              value={inputValue}
              placeholder={contactCount}
              onChange={(event) => setInputValue(parseInt(event.target.value, 10) > 0 ? parseInt(event.target.value, 10) : '')}
            />
          </ConfirmationContainer>
        )}
      </Container>
      <Footer>
        <Button
          disabled={!name && (operationType !== 'restore') && (inputValue !== contactCount)}
          data-form="true"
          type={(operationType === 'restore') ? 'primary' : 'danger'}
          size="large"
          onClick={() => {
            if (onClickYes) onClickYes();
            dispatch(updateContactsConditions({
              objectIds: contactsIds,
              excludedIds: excludedRowKeys,
              filters,
              search,
              selectAll,
              deleted: operationType !== 'delete',
              operationType,
              onSuccess: (data) => {
                if (!data.pending) {
                  setSelectedContacts([]);
                  refreshContacts();
                }
                if (setSelectedRowKeys) setSelectedRowKeys([]);
              },
            }));
            dispatch(setOperation({
              type: 'insight',
              source: 'contact',
              from: 'contacts',
              data: {
                selectAll,
                ids: contactsIds,
              },
            }));
            if (companyId) {
              dispatch(toggleModal('company_preview', true, { companyId }));
              return;
            }
            handleClose();
          }}
        >
          {(operationType === 'restore') ? 'Restore' : 'Delete'}
        </Button>
        <Button
          size="large"
          type="default"
          onClick={() => {
            if (openSidebarOnClose && name) {
              if (companyId) {
                dispatch(toggleModal('company_preview', true, { companyId }));
                return;
              }
              dispatch(toggleModal('contact_preview', true, { contactId }));
              return;
            }
            handleClose();
          }}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default DeleteContacts;
