import React, { forwardRef } from 'react';

import { CustomTag as Tag } from 'components/commonStyles';

const CustomTag = forwardRef(({ size = 'large', children, ...props }, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tag ref={ref} size={size} {...props}>
    {children}
  </Tag>
));

export default CustomTag;
