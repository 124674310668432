import get from 'lodash/get';
import { createSelector } from 'reselect';

const baseListsSelector = (state) => get(state, 'lists', {});

export const currentListSelector = createSelector(
  baseListsSelector,
  (lists) => get(lists, 'currentList', {}),
);

const baseFiltersSelector = (state) => get(state, 'lists.currentList.filters', []);
export const listFiltersSelector = createSelector(
  baseFiltersSelector,
  (filters) => filters,
);

export const listsSelector = createSelector(
  baseListsSelector,
  (lists) => get(lists, 'lists', {}),
);

export const listsSizesSelector = createSelector(
  baseListsSelector,
  (lists) => get(lists, 'listsSizes', []),
);

export const contactsFoldersRelationSelector = createSelector(
  baseListsSelector,
  (lists) => get(lists, 'foldersRelation', []),
);

export const contactsFoldersSelector = createSelector(
  baseListsSelector,
  (lists) => get(lists, 'folders', {}),
);

export const staticListsSelector = createSelector(
  baseListsSelector,
  (lists) => get(lists, 'lists.data', []).filter((list) => !list.dynamic),
);

export const selectedListsSelector = createSelector(
  baseListsSelector,
  (lists) => get(lists, 'selectedLists', []),
);

export const initialRequestListsSelector = createSelector(
  baseListsSelector,
  (lists) => get(lists, 'initialRequest', false),
);

export const initialRequestFoldersRelationSelector = createSelector(
  baseListsSelector,
  (lists) => get(lists, 'initialRequestFoldersRelation', false),
);

export const selectAllListsSelector = createSelector(
  baseListsSelector,
  (lists) => get(lists, 'lists.selectAll', false),
);

export const totalItemsListsSelector = createSelector(
  baseListsSelector,
  (lists) => get(lists, 'totalItems', 0),
);

export const deletedListsSelector = createSelector(
  baseListsSelector,
  (lists) => get(lists, 'deletedLists', {}),
);

export const recentListSelector = (listId) => createSelector(
  baseListsSelector,
  (lists) => get(lists, 'recentLists', []).find((list) => list._id === listId),
);

export const recentListsSelector = createSelector(
  baseListsSelector,
  (lists) => get(lists, 'recentLists', []),
);

export const dropdownListsSelector = createSelector(
  baseListsSelector,
  (lists) => get(lists, 'dropdownLists', []),
);

export const allListsSelector = createSelector(
  dropdownListsSelector,
  recentListsSelector,
  listsSelector,
  (dropdownLists, recentLists, lists) => [...get(lists, 'data', []), ...recentLists, ...get(dropdownLists, 'data', [])],
);

export const dashboardListsSelector = createSelector(
  baseListsSelector,
  (lists) => get(lists, 'dashboardLists', []),
);

export const contactListsSelector = createSelector(
  baseListsSelector,
  (lists) => get(lists, 'contactLists', {}),
);
