import styled, { css } from 'styled-components';

import * as CONSTANTS from 'constants/styles';

const resetStylesMixin = css`
  margin: 0;
  max-height: 50vh;
  box-shadow: none;
  border: none;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 824px;
  display: flex;
  flex-direction: column;
  margin: 25px 25px 0 25px;
  box-sizing: border-box;
  justify-content: center;
  border-radius: 2px;
  border: solid 1px #dfe2e5;
  background-color: ${CONSTANTS.COLORS.WHITE};

  ${(props) => props.resetStyles && resetStylesMixin}

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    height: auto !important;
  };

  @media (min-width: ${CONSTANTS.MOBILE_MAX_WIDTH + 1}px) {
    margin-right: 15px !important;
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const EmptyContainerLabel = styled.div`
  display: block;
  height: 30px;
  font-family: 'AvenirNext';
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #071427;
`;

export const EmptyContainerText = styled.p`
  width: ${(props) => (props.textWidth || '347px')};
  height: 40px;
  font-family: 'AvenirNext';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: ${CONSTANTS.COLORS.GREY_G2};
`;

export const EmptyContainerIcon = styled.img`
  width: 167px;
  margin: 40px 90px;
`;
