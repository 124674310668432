import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { subscribeActions, unsubscribeActions } from 'data/actions/common';

const useSubscribeActions = ({ actions, callback, once }, dependencies = []) => {
  const dispatch = useDispatch();
  const paramsRef = useRef({
    actions,
    callback,
    once,
  });

  useEffect(() => {
    paramsRef.current = {
      actions,
      callback,
      once,
    };
  }, [actions, callback, once]);

  useEffect(() => {
    let isCallbackFired = false;
    const params = paramsRef.current;
    const id = Math.random().toString(16);
    dispatch(subscribeActions({
      id,
      actions: params.actions,
      callback: (data, store) => {
        if (params.once) {
          dispatch(unsubscribeActions({ id }));
        }
        params.callback(data, store);
        isCallbackFired = true;
      },
    }));
    return () => {
      if (!isCallbackFired || !params.once) {
        dispatch(unsubscribeActions({ id }));
      }
    };
    // eslint-disable-next-line
  }, [dispatch, ...dependencies]);
};

export default useSubscribeActions;
