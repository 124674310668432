import styled from 'styled-components';

export const Icon = styled.div`
  min-width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: ${(props) => props.fontSize}px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: center;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`;
