import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'components/common';
import { getCards } from 'data/actions/cards';
import { deleteUser } from 'data/actions/user';
import { toggleModal } from 'data/actions/modals';
import { createQuitReport } from 'data/actions/common';
import { existFastSpringCardSelector } from 'data/selectors/cards';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { REASON_TYPE, SUBSCRIPTION_STATUS } from 'data/types/subscription.types';
import useDeleteAccountMessage from 'hooks/messageTemplates/useDeleteAccountMessage';
import { cancelSubscriptionMail, updateSubscriptionStatus } from 'data/actions/subscriptions';
import { analyticsAndDeleteUserLoaderSelector } from 'data/selectors/loading';
import {
  AskText,
  SorryText,
  BlueLabel,
  ReasonRadio,
  ReasonsRadioWrapper,
} from './styles';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../baseModal/styles';

const CancelSubscriptionModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const { deleteAccount } = useSelector(modalOptionsSelector);
  const existFastSpringCard = useSelector(existFastSpringCardSelector);
  const loading = useSelector(analyticsAndDeleteUserLoaderSelector);

  const [reason, setReason] = useState();
  const [labelReason, setLabelReason] = useState();

  const deleteAccountMessage = useDeleteAccountMessage();

  const reasonOptions = useRef([
    { label: 'I finished the work I had to do', value: REASON_TYPE.FINISHED_WORK },
    { label: 'I switched to another tool', value: REASON_TYPE.ANOTHER_TOOL },
    { label: 'Email accuracy is too low', value: REASON_TYPE.POOR_EMAILS_ACCURACY },
    { label: 'Email discovery rate is too low', value: REASON_TYPE.POOR_EMAILS_DISCOVERY_RATE },
    { label: 'Some features are missing', value: REASON_TYPE.MISSING_FEATURES },
    { label: 'Too expensive subscription', value: REASON_TYPE.EXPENSIVE_SUBSCRIPTION },
    { label: "I'm joining another team with a subscription", value: REASON_TYPE.ANOTHER_TEAM },
    { label: 'Other', value: REASON_TYPE.OTHER },
  ]);

  const handleClose = () => {
    dispatch(toggleModal('cancel_subscription', false));
  };

  const handleDeleteAccount = () => {
    dispatch(deleteUser(() => {
      handleClose();
      deleteAccountMessage();
    }));
  };

  const handleCancelSubscription = () => {
    if (reason) {
      dispatch(cancelSubscriptionMail({ reasons: labelReason }));
      dispatch(updateSubscriptionStatus({ status: SUBSCRIPTION_STATUS.DISABLED, reason: labelReason }, () => {
        if (existFastSpringCard) {
          dispatch(getCards());
        }
        handleClose();
      }));
    }
  };

  const openIntercom = () => {
    if (window.Intercom) {
      window.Intercom('showNewMessage');
    }
  };

  const openManagerCancelSubscriptionModal = () => {
    if ([REASON_TYPE.ANOTHER_TEAM].includes(reason)) {
      dispatch(createQuitReport({
        type: reason,
        action: 'cancelSubscription',
        details: {
          deleteAccount,
        },
        onSuccess: () => {
          if (deleteAccount) {
            handleDeleteAccount();
          } else {
            handleCancelSubscription();
          }
        },
      }));
    } else {
      dispatch(toggleModal('manager_cancel_subscription', true, { reason, labelReason, deleteAccount }));
    }
  };

  useEffect(() => {
    setLabelReason(reasonOptions.current.find(({ value }) => value === reason)?.label);
  }, [reason]);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          {deleteAccount ? 'Delete account' : 'Cancel my subscription'}
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        <SorryText>We’re sorry to see you leaving :(</SorryText>
        <AskText>{`To process the ${deleteAccount ? 'deletion' : 'cancellation'}, we need to ask you a few questions. This will help us improve our products.`}</AskText>
        <AskText>{`Why are you ${deleteAccount ? 'deleting' : 'canceling'} your ${deleteAccount ? 'account' : 'subscription'}?`}</AskText>
        <ReasonsRadioWrapper value={reason} onChange={(e) => setReason(e.target?.value)}>
          {
            reasonOptions.current.map((reasonItem) => (
              <ReasonRadio key={reasonItem.value} value={reasonItem.value}>
                {reasonItem.label}
              </ReasonRadio>
            ))
          }
        </ReasonsRadioWrapper>
        <AskText>
          If you have any inquiry,
          {' '}
          <BlueLabel onClick={openIntercom}>
            please contact us via chat.
          </BlueLabel>
        </AskText>
        <Footer>
          <Button
            data-form="true"
            type="danger"
            disabled={!reason}
            onClick={openManagerCancelSubscriptionModal}
            loading={loading}
          >
            {
              deleteAccount ? 'Delete account' : 'Cancel my subscription'
            }
          </Button>
          <Button type="normal" onClick={handleClose}>Back</Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default CancelSubscriptionModal;
