import React, { useState } from 'react';
import ReactPlayer from 'react-player/wistia';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
} from 'antd';

import Loader from 'components/loading';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { IconContainer, VideoContainerStyle } from './styles';

const OnboardingVideoModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { videoLink } = useSelector(modalOptionsSelector);

  const [loading, setLoading] = useState(true);

  return (
    <Modal
      visible={visible}
      className="info_video_modal"
      keyboard
      centered
      width={1000}
      closable
      closeIcon={<IconContainer />}
      footer={null}
      onCancel={() => dispatch(toggleModal('show_info_video', false))}
      bodyStyle={VideoContainerStyle}
    >
      <Loader loading={loading} size="large">
        <ReactPlayer controls url={videoLink} width="100%" height="650px" onReady={() => setLoading(false)} />
      </Loader>
    </Modal>
  );
};

export default OnboardingVideoModal;
