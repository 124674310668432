import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const Container = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 20px;
  }
`;

export const Input = styled.input`
  width: 55px;
  height: 60px;
  border: 1px solid ${COLORS.GREY_G4};
  border-radius: 4px;
  outline: none;
  font-size: 20px;
  text-align: center;

  &:focus {
    border: 1px solid ${COLORS.PRIMARY};
  }

  ${(props) => props.error && `
    border: 1px solid ${COLORS.RED};
    
    &:focus {
      border: 1px solid ${COLORS.RED};
    }
  `}
`;

export const ValidationStatus = styled.div`
  display: ${(props) => (props.error ? 'block' : 'none')};
  color: ${COLORS.RED};
`;
