import { Form } from 'antd';
import styled from 'styled-components';

import * as CONSTANTS from 'constants/styles';

export const InputWrapper = styled.div`
  margin-bottom: 20px;
  ${(props) => props?.bold && `
    font-weight: 700;
  `};
  position: relative;
`;

export const FormWrapper = styled.div``;

export const Row = styled.div`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ForgotPassContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const fieldStyle = {
  marginBottom: '10px',
};

export const AuthForm = styled(Form)`
  width: 400px;
  background-color: #ffffff;
  border: 1px solid transparent;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    width: 100%;
  }

  .google-button-sign-up {
    margin-top: 0 !important;
    margin-bottom: 20px !important;
  }
`;

export const linkStyle = {
  color: CONSTANTS.COLORS.PRIMARY,
  cursor: 'pointer',
  fontWeight: 500,
};

export const formBtnStyle = {
  width: '100%',
  margin: '20px auto',
};

export const loginBtnStyle = {
  width: '100%',
  margin: '0 auto',
};

export const Header = styled.span`
  font-weight: 700;
  font-size: 24px;
  color: ${CONSTANTS.COLORS.BLACK_G1};
`;

export const SubHeader = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: ${CONSTANTS.COLORS.GREY_G1};

  p {
    margin-bottom: 0;
  }
`;
