import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleMessage } from 'data/actions/message';
import { addAttachmentsToStep } from 'data/actions/sequences';
import { selectedSequenceIdSelector } from 'data/selectors/sequences';
import { deleteFilesFromStepLoaderSelector } from 'data/selectors/loading';
import BaseAttachFile from './baseAttachFile';

const AttachFile = ({ stepId, version, countFiles }) => {
  const dispatch = useDispatch();

  const sequenceId = useSelector(selectedSequenceIdSelector);
  const loading = useSelector(deleteFilesFromStepLoaderSelector);

  return (
    <BaseAttachFile
      loading={loading}
      prefix={`sequences/${sequenceId}/`}
      countFiles={countFiles}
      onUploadFiles={(newFiles) => {
        dispatch(addAttachmentsToStep({
          stepId,
          attachments: newFiles,
          version,
          onSuccess: () => {
            dispatch(toggleMessage('success', {
              header: `Files ${newFiles.map(({ name }) => name).join(', ')} have been uploaded successfully`,
            }));
          },
        }));
      }}
    />
  );
};

export default AttachFile;
