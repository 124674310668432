import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';

import { SystemModal } from 'components/commonStyles';
import FastSpringGate from 'components/paymentGate/fastSpring';

import {
  ModalTitle,
  CloseIcon,
} from '../baseModal/styles';

import {
  ContentWrapper,
} from './styles';

const BillingModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const {
    billingCycle,
    plan,
    bulkId,
    fastSpringData,
    forceShowSwitch,
    phoneSearchPlan,
    phoneSearchQuantity,
    selectSubscriptionQuantity,
    payDueTodayPhoneSearch,
  } = useSelector(modalOptionsSelector);

  return (
    <SystemModal
      visible={visible}
      title={(
        <ModalTitle>
          Checkout
          <CloseIcon
            onClick={() => dispatch(toggleModal('billing', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={420}
      footer={false}
      onCancel={() => dispatch(toggleModal('billing', false))}
      bodyStyle={{ padding: 0 }}
    >
      <ContentWrapper>
        <FastSpringGate
          forceShowSwitch={forceShowSwitch}
          fastSpringData={fastSpringData}
          billingCycle={billingCycle}
          plan={plan}
          bulkId={bulkId}
          phoneSearchPlan={phoneSearchPlan}
          phoneSearchQuantity={phoneSearchQuantity}
          selectSubscriptionQuantity={selectSubscriptionQuantity}
          payDueTodayPhoneSearch={payDueTodayPhoneSearch}
        />
      </ContentWrapper>
    </SystemModal>
  );
};

export default BillingModal;
