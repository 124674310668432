import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
} from 'antd';

import { Z_INDEXES } from 'constants/styles';
import { toggleModal } from 'data/actions/modals';
import { modalsSelector } from 'data/selectors/modals';

import {
  MenuContainer,
  Header,
  HeaderIcon,
} from './styles';

const FullScreenMobileModal = ({ type, content }) => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);

  return (
    <Drawer
      title={(
        <Header>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <HeaderIcon size="small" fill="white" type="ic-filter-alt" />
            {'  '}
            Filters
          </div>
          <HeaderIcon type="ic-close" onClick={() => dispatch(toggleModal(type, false))} />
        </Header>
      )}
      closable={false}
      placement="right"
      visible={visible}
      width="100%"
      zIndex={Z_INDEXES.MOBILE_MODAL}
    >
      <MenuContainer>
        {content}
      </MenuContainer>
    </Drawer>
  );
};

export default FullScreenMobileModal;
