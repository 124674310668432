export const transformFilters = (filters) => {
  const companyDomainPropertyIndex = filters.findIndex((item) => item.property === 'company.domain');

  if (companyDomainPropertyIndex > -1 && 'map' in filters[companyDomainPropertyIndex].included && 'map' in filters[companyDomainPropertyIndex].excluded) {
    filters[companyDomainPropertyIndex].included.value = filters[companyDomainPropertyIndex].included.value.map((item) => filters[companyDomainPropertyIndex].included.map.find((innerItem) => innerItem?.printedData === item)?.domain ?? item);
    filters[companyDomainPropertyIndex].excluded.value = filters[companyDomainPropertyIndex].excluded.value.map((item) => filters[companyDomainPropertyIndex].excluded.map.find((innerItem) => innerItem?.printedData === item)?.domain ?? item);
    delete filters[companyDomainPropertyIndex].excluded.map;
    delete filters[companyDomainPropertyIndex].included.map;
  }

  return filters.map((updatedFilter) => {
    const transformData = (dataItem) => {
      let returningData = dataItem;

      if (typeof dataItem === 'string') returningData = dataItem;
      if (typeof dataItem === 'object') {
        if ('file' in dataItem) {
          returningData = {
            file: dataItem.file,
            matching: dataItem.matching,
            printedData: dataItem.printedData,
            count: dataItem.count,
            delimiter: dataItem.delimiter,
          };
        }
      }
      return returningData;
    };
    const updatedIncludes = updatedFilter?.included?.value.map(transformData);
    const updatedExcludes = updatedFilter?.excluded?.value.map(transformData);

    if (updatedIncludes) updatedFilter.included.value = updatedIncludes;
    if (updatedExcludes) updatedFilter.excluded.value = updatedExcludes;
    return updatedFilter;
  });
};
