import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';

import { toggleModal } from 'data/actions/modals';
import { removeStep } from 'data/actions/sequences';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { Container } from './styles';
import {
  ModalTitle, CloseIcon, Footer,
} from '../baseModal/styles';

const DeleteStep = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { stepId } = useSelector(modalOptionsSelector);

  const handleClose = () => dispatch(toggleModal('delete_step', false));
  const handleDelete = () => {
    dispatch(removeStep(stepId));
    dispatch(toggleModal('delete_step', false));
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle danger>
          Delete step
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <Container>
        <div>
          You&#39;re about to delete the step. Deleted step can&#39;t be restored
        </div>

      </Container>
      <Footer>
        <Button
          data-form="true"
          type="danger"
          size="large"
          onClick={handleDelete}
        >
          Delete
        </Button>
        <Button
          size="large"
          type="default"
          onClick={handleClose}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default DeleteStep;
