import styled from 'styled-components';
import { COLORS } from '../../../constants/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  margin-left: -20px;
  margin-right: -20px;
`;

export const Half = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin: 0, 20px, 0, 20px;

  div {
    margin: 5px;
    b {
      font-weight: 500;
      color: ${COLORS.PRIMARY};
    }
    p {
      font-weight: 500;
      color: ${COLORS.PRIMARY};
    }
  }
  margin-left: 20px;
  Button {
    width: 150px;
  }

  .btn-credits {
    background-color: ${COLORS.WHITE};
    color: ${COLORS.PRIMARY};
    border-color: ${COLORS.PRIMARY};
    
    &:hover {
      opacity: 0.75;
    }
  }

  &:first-child {
    padding-right: 40px;
    border-right: 1px solid #dfe3eb;
  }
`;
