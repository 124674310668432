import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
} from 'antd';

import { toggleModal } from 'data/actions/modals';
import { Button } from 'components/common';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';

import { deleteBulk } from 'data/actions/verifications';
import { verificationsBulkSelector } from 'data/selectors/verifications';
import {
  ModalTitle,
  CloseIcon,
  Footer,
  Label,
} from '../baseModal/styles';

const DeleteBulkModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { bulkId } = useSelector(modalOptionsSelector);
  const bulk = useSelector(verificationsBulkSelector(bulkId));
  const [fileName, setFileName] = useState('');

  const handleDelete = () => {
    dispatch(deleteBulk(bulkId));
    dispatch(toggleModal('delete_bulk_verification', false));
  };

  useEffect(() => {
    if (bulk.fileName.length > 25) {
      const firstPart = bulk.fileName.slice(0, 20);
      const secondPart = bulk.fileName.slice(21, bulk.fileName.length);
      setFileName(`${firstPart}...${secondPart.length <= 10 ? secondPart : secondPart.slice(secondPart.length - 10, secondPart.length)}`);
    } else setFileName(bulk.fileName);
  }, [bulk]);

  return (
    <Modal
      centered
      visible={visible}
      title={(
        <ModalTitle danger>
          {`Delete ${fileName}`}
          <CloseIcon
            onClick={() => dispatch(toggleModal('delete_bulk_verification', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      headerStyle={{ background: 'red' }}
      onCancel={() => dispatch(toggleModal('delete_bulk_verification', false))}
      closeIcon={<div />}
    >
      <>
        <Label>
          {`You're about to delete ${fileName} with ${get(bulk, 'fileInfo.total')} emails. This action can't be undone.`}
        </Label>
      </>
      <Footer>
        <Button
          data-form="true"
          type="danger"
          size="large"
          onClick={handleDelete}
        >
          Delete
        </Button>
        <Button
          size="large"
          type="default"
          onClick={() => dispatch(toggleModal('delete_bulk_verification', false))}
        >
          No
        </Button>
      </Footer>
    </Modal>
  );
};

export default DeleteBulkModal;
