const getFolderBreadcrumbs = (folderId, tree, path = [], defaultName = 'All folders') => {
  if (folderId === 'all') return [{ _id: 'all', name: defaultName }];
  if (tree._id === folderId) {
    path.push({ _id: folderId, name: tree.name });
    return path;
  }
  if (tree.children) {
    let result;
    for (let i = 0; !result && i < tree.children.length; i += 1) {
      const currentFolder = !tree.parent ? { _id: 'all', name: defaultName } : { _id: tree._id, name: tree.name };
      result = getFolderBreadcrumbs(folderId, tree.children[i], [...path, currentFolder]);
    }
    return result;
  }
  return false;
};

export default getFolderBreadcrumbs;
