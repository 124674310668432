import get from 'lodash/get';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleModal } from 'data/actions/modals';
import { USER_STATUS } from 'data/types/user.types';
import { checkUserPayment } from 'data/actions/user';
import { accountSelector } from 'data/selectors/user';

const usePreSubscription = () => {
  const dispatch = useDispatch();

  const account = useSelector(accountSelector);

  const handlePreSubscribe = useCallback(({
    billingCycle,
    plan,
    bulkId,
    fastSpringData,
    existsFastSpringCard,
    billingCallback,
    forceShowSwitch,
    phoneSearchPlan,
    phoneSearchQuantity,
    selectSubscriptionQuantity,
    payDueTodayPhoneSearch,
    onSuccess = () => {},
  }) => {
    const showVerifyAccount = () => {
      const phoneSearchName = get(phoneSearchPlan, 'label') ? `Phone ${phoneSearchPlan.label} credits` : undefined;
      dispatch(toggleModal('verify_account', true, {
        billingCycle: get(billingCycle, 'name'),
        plan: get(plan, 'name', phoneSearchName),
        price: get(plan, 'price', get(phoneSearchPlan, 'price', 0)),
        quantity: selectSubscriptionQuantity || phoneSearchQuantity,
      }));
    };

    const showBilling = () => (billingCallback ? billingCallback() : dispatch(toggleModal('billing', true, {
      billingCycle,
      plan,
      bulkId,
      fastSpringData,
      existsFastSpringCard,
      forceShowSwitch,
      phoneSearchPlan,
      phoneSearchQuantity,
      selectSubscriptionQuantity,
      payDueTodayPhoneSearch,
    })));

    const openIntercom = () => {
      if (window.Intercom) {
        window.Intercom('showNewMessage');
      }
    };

    if (get(plan, 'name') === 'CustomPlanRequest') {
      openIntercom();
      return;
    }

    if (account?.status) {
      if (account?.status === USER_STATUS.ALLOWED_PAYMENT) showBilling();
      else showVerifyAccount();
    } else {
      dispatch(checkUserPayment({
        plan: get(plan, '_id'),
        billingCycle: get(billingCycle, '_id'),
        bulkId,
        amount: get(plan, 'price', 0),
        quantity: selectSubscriptionQuantity,
      }, showBilling, showVerifyAccount, onSuccess));
    }
  }, [dispatch, account]);

  return handlePreSubscribe;
};

export default usePreSubscription;
