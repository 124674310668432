import React from 'react';
import { EmptyContainer, EmptyContentContainer, EmptyContentDescription } from './styles';
import Icon from '../../common/icon';

const EmptyFolderPlaceholder = ({ folderName = 'Home', searchValue = '' }) => (
  <EmptyContainer>
    <EmptyContentContainer>
      <Icon type="ic-empty-state" />
      {searchValue ? (
        <EmptyContentDescription>
          Could not find any results of «
          <b>{searchValue}</b>
          » in «
          <b>{folderName}</b>
          » folder
        </EmptyContentDescription>
      ) : (
        <EmptyContentDescription>
          Could not find any results in «
          <b>{folderName}</b>
          » folder
        </EmptyContentDescription>
      )}
    </EmptyContentContainer>
  </EmptyContainer>
);

export default EmptyFolderPlaceholder;
