import styled from 'styled-components';

import {
  COLORS, HEADER_HEIGHT, MOBILE_MAX_WIDTH, TABLET_PORTRAIT_MAX_WIDTH,
} from 'constants/styles';

export const Container = styled.div`
  display: flex;
  width: 100vw;

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    flex-direction: column;

    & > div {
      width: 100vw;
      height: 100%;
      overflow-y: hidden;
    }
  }
`;

export const MainInfoContainer = styled.div`
  width: 420px;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  overflow-y: auto;
  background: ${COLORS.WHITE};
  border-right: 1px solid ${COLORS.GREY_G4};

  @media (max-width: ${TABLET_PORTRAIT_MAX_WIDTH}px) {
    width: 350px;
    min-width: 350px;
  }
`;

export const Label = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.GREY_G2};
  display: block;
  margin-bottom: 5px;
`;

export const EditWrapper = styled.div`
  padding: 15px;
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  input {
    min-width: initial;
  }
`;

export const TwoItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`;

export const ButtonsContainer = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 10px;
`;

export const ValuesContainer = styled.div`
  max-height: 300px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .react-tel-input {
    input {
      height: 32px !important;
      padding: 4px 14px 4px 50px !important;
      font-size: 14px;
    }
  }
`;
