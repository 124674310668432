import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCards } from 'data/actions/cards';
import { toggleModal } from 'data/actions/modals';
import calculatePrice from 'helpers/calculatePrice';
import { createQuitReport } from 'data/actions/common';
import { modalOptionsSelector } from 'data/selectors/modals';
import { existFastSpringCardSelector } from 'data/selectors/cards';
import { deleteUser, updateWorkspaceLimit } from 'data/actions/user';
import transformCancelReasonFlowToText from 'helpers/transformCancelReasonFlowToText';
import { cancelSubscriptionSelector, currentPlanSelector } from 'data/selectors/subscriptions';
import {
  REASON_TYPE,
  SHARED_INFORMATION,
  SUBSCRIPTION_STATUS,
} from 'data/types/subscription.types';
import {
  cancelSubscriptionMail,
  resetCancelSubscription,
  setCancelSubscription,
  updateSubscriptionStatus,
} from 'data/actions/subscriptions';
import { getPlans } from 'data/actions/plans';
import useHubspot from 'hooks/url/useHubspot';
import { toggleMessage } from 'data/actions/message';
import useDeleteAccountMessage from 'hooks/messageTemplates/useDeleteAccountMessage';
import Other from '../common/modals/other';
import PoorEmails from './poorEmails';
import AnotherTool from '../common/modals/anotherTool';
import FinishedWork from './finishedWork';
import MissingFeatures from './missingFeatures';
import ExpensiveSubscription from './expensiveSubscription';
import StepManager from '..';

const ManagerCancelSubscriptionModal = () => {
  const dispatch = useDispatch();

  const { reason, labelReason, deleteAccount } = useSelector(modalOptionsSelector);
  const existFastSpringCard = useSelector(existFastSpringCardSelector);
  const details = useSelector(cancelSubscriptionSelector);
  const currentPlan = useSelector(currentPlanSelector);

  const deleteAccountMessage = useDeleteAccountMessage();
  const { openCancelReasonHubspotCalendar } = useHubspot();

  const reasonMap = {
    [REASON_TYPE.FINISHED_WORK]: FinishedWork,
    [REASON_TYPE.ANOTHER_TOOL]: AnotherTool,
    [REASON_TYPE.POOR_EMAILS_ACCURACY]: PoorEmails,
    [REASON_TYPE.POOR_EMAILS_DISCOVERY_RATE]: PoorEmails,
    [REASON_TYPE.MISSING_FEATURES]: MissingFeatures,
    [REASON_TYPE.EXPENSIVE_SUBSCRIPTION]: ExpensiveSubscription,
    [REASON_TYPE.OTHER]: Other,
  };

  const ReasonComponent = reasonMap[reason] || Fragment;

  const handleClose = () => {
    dispatch(toggleModal('manager_cancel_subscription', false));
    dispatch(resetCancelSubscription());
  };

  const handleCancelSubscription = (params) => {
    if (reason) {
      const {
        needToAct,
        status = SUBSCRIPTION_STATUS.DISABLED,
        period,
      } = params;
      let discountPrice = null;
      if (details.discount) {
        discountPrice = calculatePrice({ planPrice: currentPlan.price, discount: details.discount });
      }
      if (deleteAccount) {
        details.deleteAccount = deleteAccount;
      }
      dispatch(createQuitReport({
        type: reason,
        action: 'cancelSubscription',
        details,
        onSuccess: () => {
          if (needToAct && status === SUBSCRIPTION_STATUS.DISABLED) {
            dispatch(cancelSubscriptionMail({ reasons: labelReason }));
          }
          const isCancelSubscriptionLimit = !!details.sharedInformation;
          if (isCancelSubscriptionLimit) {
            dispatch(updateWorkspaceLimit({ key: 'isCancelSubscriptionLimit', value: isCancelSubscriptionLimit }));
          }
          if (needToAct) {
            if (deleteAccount && params?.status !== 'pause') {
              dispatch(deleteUser(() => {
                handleClose();
                deleteAccountMessage();
              }));
            } else {
              dispatch(updateSubscriptionStatus({
                status,
                reason: labelReason,
                period: period || details?.pausingPeriod,
              }, () => {
                if (existFastSpringCard) {
                  dispatch(getCards());
                }
                handleClose();
              }));
            }
          } else if (details.sharedInformation === SHARED_INFORMATION.BOOKING_DEMO) {
            openCancelReasonHubspotCalendar({
              cancelReason: transformCancelReasonFlowToText(details),
            });
            handleClose();
          } else if (details.sharedInformation === SHARED_INFORMATION.CHAT) {
            window.Intercom('showMessages');
            handleClose();
          } else if (details.discount) {
            dispatch(getPlans());
            dispatch(toggleMessage('success', {
              header: 'Success',
              text: (
                <div>
                  Congratulations! The charge for your next payment will be
                  {' '}
                  <b>
                    {`${discountPrice}$`}
                  </b>
                  <br />
                  No matter how your next subscription is paid, whether you pay manually or charge automatically, the discount will be applied.
                </div>
              ),
            }));
          }
        },
      }));
    }
  };

  return (
    <StepManager
      stepsComponent={ReasonComponent}
      details={details}
      onClose={handleClose}
      onFinish={handleCancelSubscription}
      onStep={(params) => dispatch(setCancelSubscription(params))}
      deleteAccount={deleteAccount}
    />
  );
};

export default ManagerCancelSubscriptionModal;
