import get from 'lodash/get';
import result from 'lodash/result';
import { createPortal } from 'react-dom';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { ConfigProvider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Option } from 'components/common';
import {
  createList,
  setDropdownListsParams,
  unsetDropdownLists,
  setSelectedLists as setSelectedListsState,
} from 'data/actions/lists';
import useOnClickOutside from 'helpers/clickOutside';
import { toggleMessage } from 'data/actions/message';
import { listSelectLoadingSelector } from 'data/selectors/loading';
import ClickAwayListener from 'components/common/clickAwayListener';
import { contactsFoldersRelationSelector, recentListsSelector, dropdownListsSelector } from 'data/selectors/lists';
import Loader from 'components/loading';
import { contactsListsRelationsSelector } from 'data/selectors/contacts';
import { demoUserSelector } from 'data/selectors/user';
import { flattenDeep, uniqBy } from 'lodash';
import {
  Select,
  ListName,
  IconArrow,
  IconCheck,
  ListEmpty,
  IconClear,
  FolderName,
  DropdownWrapper,
  IconCheckWrapper,
} from './styles';

const ListSelect = ({
  size = 'default',
  value = [],
  excludeValue,
  loading = false,
  isOpened,
  suffixIcon,
  maxTagCount = 2,
  placeholder,
  setIsOpened,
  onSelectedListsChange,
  hasNoList = false,
  itemId,
}) => {
  const dispatch = useDispatch();

  const selectRef = useRef();

  const isLoadingLists = useSelector(listSelectLoadingSelector);
  const foldersRelations = useSelector(contactsFoldersRelationSelector);
  const recentLists = useSelector(recentListsSelector);
  const { data: dropdownLists, totalPages, page } = useSelector(dropdownListsSelector);
  const listsRelations = useSelector(contactsListsRelationsSelector);
  const isDemoUser = useSelector(demoUserSelector);
  const [newValue, setNewValue] = useState('');
  const [sortedList, setSortedList] = useState([]);
  const [clickedOutside, setClickedOutside] = useState(false);
  const dropDownRef = useRef();
  const [loadingMore, toggleLoadingMore] = useState(false);
  const [lists, setLists] = useState([]);
  const [lastChildId, setLastChildId] = useState(0);
  const [selectedLists, setSelectedLists] = useState([]);
  const [showCreate, setShowCreate] = useState(false);
  const [unselect, setUnselect] = useState(false);

  const widthSelect = selectRef?.current?.rcSelect?.topCtrlRef?.clientWidth;

  useEffect(() => {
    dispatch(unsetDropdownLists());
  }, [dispatch, recentLists]);

  const handleScrollToTop = () => {
    const scrollableRef = selectRef?.current?.rcSelect?.topCtrlRef;
    if (scrollableRef) {
      scrollableRef.scrollTo(0, -window.innerHeight);
    }
  };

  useEffect(() => {
    if (value.length !== selectedLists.length) {
      const filterExpression = (list) => value.includes(list?._id);
      const filteredLists = dropdownLists.filter(filterExpression).filter((list) => !selectedLists.filter(filterExpression).find((item) => item._id === list._id));
      const filteredListsUniq = uniqBy([...flattenDeep(listsRelations).filter(filterExpression), ...selectedLists.filter(filterExpression), ...filteredLists], '_id');
      setSelectedLists(filteredListsUniq);
    }
  }, [value, listsRelations, dropdownLists]); // eslint-disable-line

  useEffect(() => {
    dispatch(setDropdownListsParams({ disableSearch: true, filters: [{ property: '_id', included: { value, operator: 'NOT_CONTAINS_ANY' } }] }));
  }, [dispatch, value]);

  useEffect(() => {
    const filteredLists = dropdownLists.filter((list) => !selectedLists.find((item) => item._id === list._id) && !excludeValue?.includes(list._id));
    setLists([...selectedLists, ...filteredLists]);
  }, [dropdownLists, selectedLists, excludeValue]);

  useOnClickOutside([dropDownRef], () => {
    setClickedOutside(true);
  });

  const handleChange = (list) => {
    if (isDemoUser) { return; }

    if (!clickedOutside) {
      const newSelectedLists = list.filter((item) => item && item !== 'no-list');
      const isLastNoList = list[list.length - 1] === 'no-list';
      const [listToCreate] = newSelectedLists.filter((item) => !sortedList.some((itemList) => item === itemList._id));
      if (listToCreate && newValue.length) {
        if (!(sortedList.find((listItem) => listItem.name === listToCreate))) {
          dispatch(createList({ name: newValue, dynamic: false }, (createdList) => {
            if (get(createdList, 'isExist', false)) dispatch(toggleMessage('error', { header: `List ${newValue} already exists`, text: 'Please choose a new name.' }));
            else {
              dispatch(toggleMessage('success', { text: `List ${get(createdList, 'name')} was successfully created!` }));
              onSelectedListsChange([...value, get(createdList, '_id')]);
            }
          }));
        } else {
          const selectedListId = (sortedList.find((listItem) => listItem.name === listToCreate))._id;
          if (!selectedLists.find((listItem) => listItem._id === selectedListId)) onSelectedListsChange([...value.filter((item) => item !== selectedListId), selectedListId]);
          else onSelectedListsChange(value.filter((item) => item !== selectedListId));
        }
      } else if (isLastNoList) {
        onSelectedListsChange(['no-list']);
      } else {
        onSelectedListsChange(newSelectedLists);
      }

      setIsOpened(false);
    }
  };

  const showMore = (numbers) => `+${numbers?.length || 0} more`;

  useEffect(() => {
    if (!isOpened || !newValue) handleScrollToTop();
  }, [isOpened, newValue]);

  useEffect(() => {
    const sortedLists = [...lists];
    if (hasNoList) {
      sortedLists.unshift({ _id: 'no-list', name: 'No list' });
    }
    setSortedList(uniqBy(sortedLists, '_id'));
  }, [hasNoList, lists]);

  useEffect(() => {
    setLastChildId(sortedList.length - 1 || 0);
  }, [sortedList]);

  const handleScroll = () => {
    if (sortedList.length && !loadingMore && !loading) {
      const lastElement = document.getElementById(lastChildId);
      const containerTop = dropDownRef.current.getBoundingClientRect().top;
      const lastElementTopPos = lastElement ? (lastElement.getBoundingClientRect().top - containerTop) : 0;
      const containerHeight = dropDownRef.current.getBoundingClientRect().height;
      const newPage = page + 1;

      if ((lastElementTopPos - 15 < containerHeight) && !loadingMore && !newValue.length && ((newPage <= totalPages) || (page === 1))) {
        toggleLoadingMore(true);
        dispatch(setDropdownListsParams({
          page: newPage,
          onSuccess: () => toggleLoadingMore(false),
          onFailed: () => toggleLoadingMore(false),
        }));
      }
    }
  };

  useEffect(() => {
    if (isOpened) setClickedOutside(false);
    if (!isOpened) setNewValue('');
    if (!isOpened && selectRef?.current) selectRef.current.blur();
  }, [isOpened]);

  useEffect(() => {
    const input = selectRef?.current?.rcSelect?.inputRef;
    function changeInput() {
      setNewValue(input?.value);
    }
    function openPopup() {
      setIsOpened(true);
    }

    if (input) {
      input.addEventListener('keyup', changeInput);
      input.addEventListener('focus', openPopup);
    }
    return () => {
      input.removeEventListener('keyup', changeInput);
      input.removeEventListener('focus', openPopup);
    };
  }, [setIsOpened]);

  useEffect(() => {
    if (newValue.length) {
      toggleLoadingMore(true);
      dispatch(setDropdownListsParams({
        searchValue: newValue,
        onSuccess: () => toggleLoadingMore(false),
        onFailed: () => toggleLoadingMore(false),
      }));
    } else dispatch(unsetDropdownLists(true));
  }, [dispatch, newValue]);

  const renderSuffixIcon = () => {
    if (isLoadingLists || loading || loadingMore) {
      return <Loader loading />;
    }
    if (suffixIcon === 'default') {
      return null;
    } if (suffixIcon) {
      return suffixIcon;
    }
    return <IconArrow onClick={() => setIsOpened(!isOpened)} open={isOpened ? 'true' : ''} />;
  };

  const handleClear = () => {
    setIsOpened(false);
    if (newValue.length) dispatch(unsetDropdownLists(true));
  };

  const listOptions = sortedList.map((item, index) => {
    const folderName = get(foldersRelations.find((relation) => get(relation, 'list._id') === item._id), 'folder.name', false);
    return (
      <Option id={index} disabled={loading} key={item._id} className="saved-list">
        <div className="list-container">
          <ListName hasNoList={index === 0 && hasNoList && isOpened ? 'true' : ''}>
            {item.name}
          </ListName>
          &nbsp;
          {folderName && <FolderName>{folderName}</FolderName>}
        </div>
        <IconCheckWrapper>
          <IconCheck />
        </IconCheckWrapper>
      </Option>
    );
  });

  useEffect(() => {
    setShowCreate((!(sortedList.find((list) => list.name === newValue))));
    setUnselect((selectedLists.find((list) => list.name === newValue)));
  }, [newValue, sortedList, selectedLists]);

  useEffect(() => {
    dispatch(setSelectedListsState(sortedList.filter((list) => value.includes(list._id))));
  }, [dispatch, value, sortedList]);

  return (
    <ClickAwayListener onClick={() => setClickedOutside(false)} style={{ width: '100%' }} onClickAway={handleClear}>
      {createPortal(<DropdownWrapper showCreate={showCreate} unselect={unselect} className="save-from-search" />, document.body)}
      <ConfigProvider renderEmpty={() => <ListEmpty>There aren&lsquo;t any list</ListEmpty>}>
        <Select
          id={itemId}
          size={size}
          value={value}
          width={widthSelect}
          suffixIcon={!newValue ? renderSuffixIcon() : <IconClear onClick={handleClear} />}
          ref={selectRef}
          mode="tags"
          maxTagCount={maxTagCount}
          showSearch
          showArrow
          maxTagPlaceholder={showMore}
          placeholder={!placeholder ? 'Type a new list name or find existing' : placeholder}
          onChange={handleChange}
          optionFilterProp="name"
          getPopupContainer={() => document.getElementsByClassName('save-from-search')[0]}
          loading={(isLoadingLists && !widthSelect) || loadingMore}
          filterOption={(input, o) => (
            result(o, 'props.children[0].props.children[0].props.children.toLowerCase', '').indexOf(input?.toLowerCase()) >= 0
          )}
          dropdownRender={(node) => <div onWheel={handleScroll} ref={dropDownRef}>{node}</div>}
          menuItemSelectedIcon={() => ''}
          onFocus={() => setIsOpened(true)}
          open={isOpened}
          disabled={isDemoUser}
        >
          {listOptions}
        </Select>
      </ConfigProvider>
    </ClickAwayListener>
  );
};

export default ListSelect;
