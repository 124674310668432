import React, { forwardRef, useEffect, useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

import * as CONSTANTS from 'constants/styles';
import { ValidationStatus } from 'components/commonStyles';
import Icon from '../icon';
import {
  PhoneInputWrapper,
  CheckContainer,
  SuffixContainer,
} from './styles';

const CustomPhoneInput = forwardRef(({
  autoFocus,
  error,
  success,
  phoneVerification,
  searchPlaceholder,
  styleWrapper = {},
  enableSearch = true,
  disableSearchIcon = true,
  suffix,
  ...props
}, ref) => {
  const phoneRef = useRef();

  let commonStyles = {};

  if (error) {
    commonStyles = {
      borderColor: CONSTANTS.COLORS.RED,
      boxShadow: `0 0 0 2px ${CONSTANTS.COLORS.RED_WITH_OPACITY}`,
    };
  }

  useEffect(() => {
    if (phoneRef.current && ref) ref.current = phoneRef.current;
  }, [ref]);

  return (
    <>
      <PhoneInputWrapper error={error} style={styleWrapper}>
        <PhoneInput
          {...props} // eslint-disable-line
          ref={phoneRef}
          enableSearch={enableSearch}
          disableSearchIcon={disableSearchIcon}
          inputStyle={commonStyles}
          searchPlaceholder={searchPlaceholder ?? 'Country...'}
        />
        <CheckContainer onClick={() => phoneRef.current.numberInputRef.focus()} visible={phoneVerification}>
          <Icon type="ic-check" fill={CONSTANTS.COLORS.GREEN_G5} />
        </CheckContainer>
        {suffix && <SuffixContainer>{suffix()}</SuffixContainer>}
      </PhoneInputWrapper>
      {
        error || success ? (
          <ValidationStatus style={{ position: 'static' }} success={success} error={error}>
            {error || success}
          </ValidationStatus>
        ) : null
      }
    </>
  );
});

export default CustomPhoneInput;
