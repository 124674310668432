import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ModalsController from 'components/modalsController';
import UnconfirmedPhone from 'pages/authorization/unconfirmedPhone';

const PhoneVerificationLayout = () => (
  <>
    <Switch>
      <Route
        path="/phone-confirmation"
        component={UnconfirmedPhone}
      />
      <Redirect to="/phone-confirmation" />
    </Switch>
    <ModalsController />
  </>
);

export default PhoneVerificationLayout;
