/* eslint-disable react/jsx-closing-tag-location */
import React, { useCallback, useState, useEffect } from 'react';
import { Tooltip, Collapse } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';

import { Icon } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { getFullName } from 'helpers/getFullName';
import { accountSelector } from 'data/selectors/user';
import { SEARCH_KIND } from 'constants/searchContent';
import { SOURCE_TYPE } from 'data/types/source.types';
import {
  ActivityDataWrapper,
  CollapseBtnWrapper,
} from '../../../notificationsPreviewBar/styles';
import {
  Field,
  ListText,
  ListLabel,
  ListContainer,
  FieldListContainer,
  FieldDataTitle,
  ListsFieldsWrapper,
  FieldDataWrapper,
  FieldDataDescription,
} from '../../styles';
import SearchFilter from './searchFilter';

const { Panel } = Collapse;
const HEADER_LIMIT = 3;

const SearchData = ({ search, filters }) => {
  const dispatch = useDispatch();

  const account = useSelector(accountSelector);
  const [headerFields, setHeaderFields] = useState([]);
  const [bodyFields, setBodyFields] = useState([]);
  const [togglePanel, setTogglePanel] = useState([]);

  const handleCloseSearchesPreviewBar = useCallback(() => dispatch(toggleModal('searches_preview', false)), [dispatch]);

  useEffect(() => {
    const fieldsData = [];

    if (search.kind === SEARCH_KIND.SEARCH_HISTORY) {
      if (search.listIds?.length > 0) {
        fieldsData.push(
          <Field hasActions key={nanoid()}>
            <FieldListContainer>
              <ListContainer>
                <ListLabel>{search.listIds.length === 1 ? 'List: ' : 'Lists: '}</ListLabel>
                <ListsFieldsWrapper>
                  {
                      search.listIds.map((list) => (
                        <Tooltip key={`search-history-link-${list._id}`} title={list.deleted ? 'List deleted' : ''}>
                          <ListText disabled={list.deleted}>
                            <Link
                              disabled={list.deleted}
                              onClick={handleCloseSearchesPreviewBar}
                              to={`/contacts/list/${list._id}`}
                            >
                              {list.name}
                            </Link>
                          </ListText>
                        </Tooltip>
                      ))
                    }
                </ListsFieldsWrapper>
              </ListContainer>
            </FieldListContainer>
          </Field>,
        );
      }
      fieldsData.push(<Field hasActions key={nanoid()}>
        <FieldDataWrapper>
          <FieldDataTitle>Saved: </FieldDataTitle>
          <FieldDataDescription>{search.saved}</FieldDataDescription>
        </FieldDataWrapper>
      </Field>);

      if (search.limits?.perCompany) {
        fieldsData.push(<Field hasActions key={nanoid()}>
          <FieldDataWrapper>
            <FieldDataTitle>Per company: </FieldDataTitle>
            <FieldDataDescription>{search.limits.perCompany}</FieldDataDescription>
          </FieldDataWrapper>
        </Field>);
      }
      if (search.selected) {
        fieldsData.push(<Field hasActions key={nanoid()}>
          <FieldDataWrapper>
            <FieldDataTitle>Selected: </FieldDataTitle>
            <FieldDataDescription>{search.selected}</FieldDataDescription>
          </FieldDataWrapper>
        </Field>);
      }
      if (search.source === SOURCE_TYPE.CONTACT) {
        fieldsData.push(<Field hasActions key={nanoid()}>
          <FieldDataWrapper>
            <FieldDataTitle>Save existing leads: </FieldDataTitle>
            <FieldDataDescription>{search.saveAdded ? 'Yes' : 'No'}</FieldDataDescription>
          </FieldDataWrapper>
        </Field>);
      }
      if (search.source === SOURCE_TYPE.CONTACT) {
        fieldsData.push(<Field hasActions key={nanoid()}>
          <FieldDataWrapper>
            <FieldDataTitle>Find phones: </FieldDataTitle>
            <FieldDataDescription>{search.savePhoneNumbers ? 'Yes' : 'No'}</FieldDataDescription>
          </FieldDataWrapper>
        </Field>);
      }
      if (search.note) {
        fieldsData.push(<Field hasActions key={nanoid()}>
          <FieldDataWrapper>
            <FieldDataTitle>Notes: </FieldDataTitle>
            <FieldDataDescription>{search.note}</FieldDataDescription>
          </FieldDataWrapper>
        </Field>);
      }
      if (search.user && account._id !== search.user._id) {
        fieldsData.push(<Field hasActions key={nanoid()}>
          <FieldDataWrapper>
            <FieldDataTitle>User: </FieldDataTitle>
            <FieldDataDescription>{getFullName(search.user)}</FieldDataDescription>
          </FieldDataWrapper>
        </Field>);
      }
      fieldsData.push(...filters.map((filter) => <SearchFilter key={`search_${filter.property}`} filter={filter} />));
    } else {
      if (search.saved) {
        fieldsData.push(<Field hasActions key={nanoid()}>
          <FieldDataWrapper>
            <FieldDataTitle>Saved: </FieldDataTitle>
            <FieldDataDescription>{search.saved}</FieldDataDescription>
          </FieldDataWrapper>
        </Field>);
      }
      fieldsData.push(...filters.map((filter, index) => <SearchFilter key={`search_${filter.property}`} filter={filter} hasActions={index === 0 && !search.saved} />));
    }

    setHeaderFields(fieldsData.splice(0, HEADER_LIMIT));
    setBodyFields(fieldsData);
  }, [search, filters, account, handleCloseSearchesPreviewBar]);

  const collapseBtn = useCallback((panelProps) => {
    const { isActive } = panelProps;
    const text = isActive ? 'Less' : 'More';
    return bodyFields?.length ? (
      <CollapseBtnWrapper
        onClick={() => {
          setTogglePanel((prevState) => (prevState.length ? [] : [panelProps.panelKey]));
        }}
      >
        {text}
        <Icon type="ic-arrow-down" size="smallest" fill="primary" />
      </CollapseBtnWrapper>
    ) : null;
  }, [bodyFields]);

  if (!headerFields?.length && !bodyFields?.length) return null;

  return (
    <ActivityDataWrapper useOffset={bodyFields?.length}>
      <Collapse
        activeKey={togglePanel}
        expandIcon={collapseBtn}
      >
        <Panel
          header={(
            <>
              {headerFields}
            </>
          )}
          key={search._id}
        >
          {bodyFields}
        </Panel>
      </Collapse>
    </ActivityDataWrapper>
  );
};

export default SearchData;
