import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import {
  Modal,
} from 'antd';

import { Input, Button } from 'components/common';

import { SOURCE_TYPE } from 'data/types/source.types';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import getFilterActions from 'helpers/getFilterActionsBySourceType';

import {
  CloseIcon,
  ModalTitle,
  Label,
  Warning,
  InputStyle,
  Footer,
} from '../baseModal/styles';

const RenameFilterModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { filterId, sourceType = SOURCE_TYPE.CONTACT } = useSelector(modalOptionsSelector);

  const {
    actionRename,
    filterSelector,
    selectFilter,
    selectedFilterSelector,
  } = getFilterActions(sourceType);

  const selectedFilter = useSelector(selectedFilterSelector);

  const savedFilters = useSelector(filterSelector);

  const currentFilterName = get(savedFilters.find((item) => item._id === filterId), 'name', 'All contacts');
  const [name, updateName] = useState('');
  useEffect(() => updateName(currentFilterName), [currentFilterName]);
  const isExist = () => {
    if (savedFilters.findIndex((item) => item.name === name && item.name !== currentFilterName) > -1 || name === 'All contacts'
    || name === 'All companies') return true;
    return false;
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Rename filter
          <CloseIcon
            onClick={() => dispatch(toggleModal('rename_filter', false))}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={() => dispatch(toggleModal('rename_filter', false))}
    >
      <>
        <Label>Name*</Label>
        <Input
          autoFocus
          onChange={(e) => {
            updateName(e.target.value);
          }}
          value={name}
          placeholder="Type the view name..."
          style={InputStyle}
        />
        {isExist() && (
          <Warning>
            {`${name} already exists. Please choose a new name.`}
          </Warning>
        )}
      </>
      <Footer>
        <Button
          type="primary"
          onClick={() => {
            dispatch(toggleModal('rename_filter', false));
            dispatch(actionRename(name, filterId));
            dispatch(selectFilter({ ...selectedFilter, name }));
          }}
          disabled={!name || currentFilterName === name || isExist()}
          data-form={!name || currentFilterName === name || isExist() ? 'false' : 'true'}
        >
          Save
        </Button>
        <Button type="normal" onClick={() => dispatch(toggleModal('rename_filter', false))}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default RenameFilterModal;
