import styled from 'styled-components';

import { COLORS } from 'constants/styles';
import { Button } from 'components/common';
import { TableContainer } from '../tables/baseTable/styles';

export const Footer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${(props) => (props.white ? COLORS.WHITE : '#f5f8fa')};
  border-top: 1px solid #cbd6e2;
  padding: 20px;
  display: flex;
  justify-content: space-between;

  button:disabled {
    background-color: #f7f8fa !important;
    color: #bec4ca !important;
  }
`;

export const BlueButton = styled(Button)`
  width: 100px;
  text-shadow: none;
  box-shadow: none;
  margin-right: 0px !important;
  margin-left: 15px;
  font-family: 'AvenirNext';
  box-shadow: none !important;
`;

export const WhiteButton = styled(Button)`
  width: 100px;
  text-shadow: none;
  box-shadow: none;
  margin-right: 0px !important;
  font-family: 'AvenirNext';
  color: #4f5964;
  box-shadow: none !important;
`;

export const ModalsBody = styled.div`
  padding: 20px;

  .ant-select-selection:focus {
    box-shadow: none!important;
  }
`;

export const PropertiesContainer = styled.div`
`;

export const TableWrapper = styled(TableContainer)`
  .ant-table-wrapper {
    width: 380px;
  }
  margin-top: 20px;
`;

export const TableSelectPropertiesWrapper = styled(TableWrapper)`
  .ant-table-pagination.ant-pagination {
    display: flex;
    justify-content: center;
    float: inherit;
  }
  .ant-table-row {
    td:nth-child(1) {
      padding-left: 7px !important;
    }
    td:nth-child(2) {
      padding-right: 7px !important;
    }
  }
`;

export const PopupOption = styled.a`
  padding: 10px;
  padding-right: 0;
`;

export const CustomFooterOption = styled.button`
  padding: 10px;
  color: #0091ae;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  height: 0;
  line-height: 1.6;
  box-shadow: none;

  &:hover {
    span {
      text-decoration: underline;
    }
  }
  i {
    margin: 0 5px;
  }
  
`;
export const PropertyLabel = styled.span`
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
  color: #000;
`;

export const ButtonStyle = {
  fontWeight: 600,
  fontSize: 14,
};

export const PropertyItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  input {
    border-radius: 4px;
    border: solid 1px #dfe2e5;
    background-color: #ffffff;
  }
`;

export const DrawerWrapper = styled.div`
  div > .ant-drawer-title {
    color: #ffffff !important;
  }
  .ant-drawer-close {
    height: 76px;
  }
`;

export const Label = styled.span`
  font-family: 'AvenirNext';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4f5964;
  margin-bottom: 5px;
`;

export const OptionsButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

export const StepsContainer = styled.div`
  background-color: #f7f8fa;
  height: 50px;
  border-radius: 2px;
  width: 100%;
  padding: 15px;
  display: flex;
  font-size: 12px;
  font-family: 'AvenirNext';
  line-height: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const StepNumber = styled.span`
  color: #071427;
  font-weight: 600;
  margin-right: 10px;
`;

export const StepTitle = styled.span`
  color: #4f5964;
`;

export const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? COLORS.PRIMARY : COLORS.GREY_G8)};
`;

export const CirclesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 24px;
`;
