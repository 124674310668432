import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { STATUSES } from 'constants/emailStatus';
import { PageSizes } from 'data/types/tableMeta.types';
import { ACCOUNT_EVENTS } from 'data/types/event.types';
import { transformSimilarInsights } from 'helpers/insightHelpers';
import { ActivityType, HistoryType } from 'data/types/history.types';
import { SOURCE_TYPE } from 'data/types/source.types';
import { convertInsightToCompany, convertInsightToContact } from 'helpers/convert';

const initialState = {
  companies: {
    data: [],
    totalItems: 0,
    pageSize: PageSizes.DEFAULT_COMPANIES_PAGE_SIZE,
    page: 1,
    filters: [],
    sort: {
      column: '',
      order: '',
    },
    search: {},
    initialRequest: true,
  },
  insights: {
    data: [],
    totalItems: 0,
    defaultItems: 0,
    pageSize: PageSizes.DEFAULT_COMPANIES_INSIGHTS_PAGE_SIZE,
    page: 1,
    filters: [],
    sort: {
      column: '',
      order: '',
    },
    initialRequest: true,
  },
  suggestedCompanies: [],
  showAllSavedFilters: false,
  companyId: null,
  currentCompany: {},
  currentCompanySidebar: {},
  savedFilters: [],
  selectedCompanies: [],
  selectedInsightCompanies: [],
  excludedCompanies: [],
  blockedCompanies: {
    data: [],
    totalItems: 0,
    pageSize: PageSizes.DEFAULT_BLOCKED_COMPANIES_PAGE_SIZE,
    page: 1,
  },
  selectAllCompanies: {
    selectAll: false,
    filterId: null,
  },
  searchedByName: [],
  selectedFilter: {},
  savingCompaniesPreview: [],
  deletedCompanies: {
    data: [],
    totalItems: 0,
    pageSize: PageSizes.DEFAULT_COMPANIES_PAGE_SIZE,
    page: 1,
    filters: [],
    sort: {
      column: '',
      order: '',
    },
    search: {},
    selectAll: false,
    isChanged: false,
  },
  fastSearchDatabaseCompanies: [],
  popularInsightsCompanies: {
    data: [],
    meta: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_NOTE_FOR_COMPANY': {
      const field = `currentCompany${action.isSidebar ? 'Sidebar' : ''}`;
      if (isEmpty(state[field])) return state;
      return {
        ...state,
        [field]: {
          ...state[field],
          history: [{
            _id: action.payload._id,
            historyType: HistoryType.NOTE,
            type: ActivityType.WROTE_NOTE,
            header: 'Wrote note',
            createdAt: action.payload.createdAt,
            description: action.payload.text,
            author: {
              _id: action.payload.user,
            },
          }, ...(state[field].history || [])],
        },
      };
    }
    case 'GET_ALL_COMPANIES': {
      return {
        ...state,
        companies: {
          ...action.payload,
          initialRequest: false,
        },
      };
    }

    case 'CLEAR_COMPANIES_SEARCH': {
      return {
        ...state,
        companies: {
          ...state.companies,
          search: initialState.companies.search,
        },
      };
    }

    case 'GET_COMPANIES_FILTERS': {
      return {
        ...state,
        savedFilters: [
          {
            _id: 'all',
            name: 'All companies',
            isSystem: true,
            filters: [],
          },
          ...action.payload,
        ],
      };
    }
    case 'SET_COMPANIES_PARAMS': {
      return {
        ...state,
        companies: {
          ...state.companies,
          ...action.data,
        },
      };
    }
    case 'SET_DELETED_COMPANIES_PARAMS': {
      return {
        ...state,
        deletedCompanies: {
          ...state.deletedCompanies,
          ...action.data,
        },
      };
    }
    case 'GET_COMPANIES_COUNT': {
      const { totalItems, totalPages } = action.payload;
      return {
        ...state,
        companies: {
          ...state.companies,
          totalItems,
          totalPages,
        },
      };
    }
    case 'GET_DELETED_COMPANIES_COUNT': {
      const { totalItems, totalPages } = action.payload;
      return {
        ...state,
        deletedCompanies: {
          ...state.deletedCompanies,
          totalItems,
          totalPages,
        },
      };
    }
    case 'SEARCH_COMPANIES': {
      const { data, meta } = action.payload;
      return action.refreshState ? {
        ...state,
        companies: {
          ...state.companies,
          data,
          ...meta,
          initialRequest: false,
        },
      } : { ...state };
    }
    case 'SEARCH_DELETED_COMPANIES': {
      const { data, meta } = action.payload;
      return action.refreshState ? {
        ...state,
        deletedCompanies: {
          ...state.deletedCompanies,
          data,
          ...meta,
        },
      } : { ...state };
    }
    case 'TOGGLE_SHOW_ALL_SAVED_FILTERS': {
      return {
        ...state,
        showAllSavedFilters: action.data.value,
      };
    }
    case 'CREATE_COMPANIES_FILTER': {
      const savedFilters = [...state.savedFilters];
      savedFilters.push(action.payload);
      return {
        ...state,
        savedFilters,
      };
    }
    case 'UPDATE_COMPANIES_LIST': {
      const index = state.savedFilters.findIndex((item) => item._id === action.payload._id);
      const savedFilters = [...state.savedFilters];
      savedFilters[index] = action.payload;
      return {
        ...state,
        savedFilters,
      };
    }
    case 'DELETE_COMPANIES_FILTER': {
      const { filterId } = action;
      const newFilters = state.savedFilters.filter((item) => item._id !== filterId);
      return {
        ...state,
        savedFilters: newFilters,
      };
    }

    case 'UPDATE_COMPANIES_FILTERS': {
      return {
        ...state,
        companies: {
          ...state.companies,
          filters: action.data,
        },
      };
    }
    case 'TOGGLE_SELECT_ALL_COMPANIES': {
      const { value, deleted } = action.data;

      if (deleted) {
        return {
          ...state,
          deletedCompanies: { ...state.deletedCompanies, selectAll: value },
        };
      }

      return {
        ...state,
        selectAllCompanies: { ...state.selectAllCompanies, ...value },
      };
    }
    case 'SET_SELECTED_COMPANIES': {
      return {
        ...state,
        selectedCompanies: [...action.data],
      };
    }
    case 'EXCLUDE_COMPANIES': {
      return {
        ...state,
        excludedCompanies: [...action.data],
      };
    }
    case 'DELETE_COMPANIES': {
      const companies = { ...state.companies };
      const { companiesIds } = action;

      if (state.insights.data.some((item) => companiesIds.includes(item.savedWorkspaceId))) {
        const updatedInsights = state.insights.data.map((item) => {
          if (companiesIds.includes(item.savedWorkspaceId)) {
            item.saved = false;
          }

          return item;
        });

        return {
          ...state,
          companies,
          insights: {
            ...state.insights,
            data: updatedInsights,
            initialRequest: true,
          },
          newInsights: {
            ...state.newInsights,
            initialRequest: true,
          },
          savedInsights: {
            ...state.savedInsights,
            initialRequest: true,
          },
          selectAllContacts: {
            selectAll: false,
            filterId: null,
            listId: null,
          },
          deletedCompanies: {
            ...state.deletedCompanies,
            selectAll: false,
            isChanged: true,
          },
        };
      }

      return {
        ...state,
        companies,
        selectAllCompanies: {
          selectAll: false,
          filterId: null,
        },
        insights: {
          ...state.insights,
          initialRequest: true,
        },
        newInsights: {
          ...state.newInsights,
          initialRequest: true,
        },
        savedInsights: {
          ...state.savedInsights,
          initialRequest: true,
        },
        deletedCompanies: {
          ...state.deletedCompanies,
          selectAll: false,
          isChanged: true,
        },
      };
    }
    case 'EDIT_COMPANIES_PROPERTY': {
      const { itemIds, properties } = action;
      const { pending } = action.payload;
      if (properties?.length > 0) {
        const [{ property, value }] = properties;
        const newProperty = { [property]: { value } };
        return {
          ...state,
          companies: {
            ...state.companies,
            data: pending ? state.companies.data : state.companies.data.map((company) => {
              const newProperties = itemIds.includes(company._id) ? newProperty : null;
              if (!newProperties) {
                return company;
              }
              return {
                ...company,
                properties: {
                  ...company.properties,
                  ...newProperties,
                },
              };
            }),
          },
          selectAllCompanies: {
            selectAll: false,
            filterId: null,
          },
        };
      }
      return state;
    }
    case 'GET_BLOCKED_COMPANIES': {
      const { data, meta } = action.payload;
      return {
        ...state,
        blockedCompanies: { ...state.blockedCompanies, data, ...meta },
      };
    }
    case 'SET_BLOCKED_COMPANIES_PARAMS': {
      return {
        ...state,
        blockedCompanies: { ...state.blockedCompanies, ...action.data },
      };
    }
    case 'DELETE_BLOCKED_COMPANY': {
      const { companyId } = action;
      const newBlockedCompanies = state.blockedCompanies.data.filter((item) => item._id !== companyId);

      return {
        ...state,
        blockedCompanies: {
          ...state.blockedCompanies,
          data: [...newBlockedCompanies],
          totalItems: state.blockedCompanies.totalItems - 1,
        },
      };
    }
    case 'ADD_BLOCKED_COMPANIES': {
      return {
        ...state,
        blockedCompanies: {
          ...state.blockedCompanies,
          data: [...state.blockedCompanies.data, ...action.payload],
          totalItems: state.blockedCompanies.totalItems + action.payload.length,
        },
      };
    }
    case 'SET_COMPANY_ID': {
      const { data } = action;
      return {
        ...state,
        ...data,
      };
    }
    case 'UPDATE_COMPANY': {
      const data = get(action, 'payload.data', {});
      const { _id, properties } = data;
      const newCompanies = { ...state.companies };
      if (_id) {
        const index = newCompanies.data.findIndex((item) => item._id === _id);
        if (index > -1) newCompanies.data[index].properties = properties;
      }
      const updateCompany = (field) => {
        if (state[field]?._id === _id) {
          return {
            ...data,
            history: state[field].history,
            contacts: state[field].contacts,
            competitors: state[field].competitors,
            contactsCount: state[field].contactsCount,
            contactCountries: state[field].contactCountries,
            contactDepartments: state[field].contactDepartments,
            contactPositions: state[field].contactPositions,
            technologies: state[field].technologies,
            employees: state[field].employees,
            savedContacts: state[field].savedContacts,
          };
        }
        return state[field];
      };
      return {
        ...state,
        companies: newCompanies,
        currentCompany: updateCompany('currentCompany'),
        currentCompanySidebar: updateCompany('currentCompanySidebar'),
      };
    }

    case 'ADD_COMPANY': {
      const { data } = action.payload;
      return state.companies.page === 1 ? {
        ...state,
        companies: {
          ...state.companies,
          data: [data, ...state.companies.data].slice(0, state.companies.pageSize),
          totalItems: state.companies.totalItems + 1,
        },
      } : { ...state };
    }
    case 'SEARCH_INSIGHT_COMPANIES': {
      const result = {
        ...state.insights,
        data: action.payload.data,
        totalItems: get(action.payload, 'meta.totalItems', 0),
        defaultItems: get(action.payload, 'meta.totalItems', 0),
        pageSize: get(action.payload, 'meta.pageSize', PageSizes.DEFAULT_INSIGHTS_PAGE_SIZE),
        page: get(action.payload, 'meta.page', 1),
        sort: get(action.payload, 'meta.sort', {}),
        initialRequest: false,
      };

      const tableMeta = localStorage.getItem('tableMeta');
      const parsedData = JSON.parse(tableMeta);
      localStorage.setItem('tableMeta', JSON.stringify({
        ...(parsedData ?? {}),
        companyInsight: {
          pageSize: result.pageSize,
        },
      }));
      return {
        ...state,
        insights: result,
      };
    }
    case 'GET_SAVED_INSIGHT_COMPANIES': {
      const { onlyEnrich } = action;
      if (!onlyEnrich) {
        const savedInsights = get(action, 'payload', []);

        return {
          ...state,
          insights: {
            ...state.insights,
            data: transformSimilarInsights(state.insights, savedInsights),
          },
        };
      }
      return state;
    }
    case 'RENAME_COMPANIES_FILTER': {
      const { _id, name } = action.payload;
      const savedFilters = [...state.savedFilters];
      const newFilters = savedFilters.find((item) => item._id === _id);
      newFilters.name = name;
      return {
        ...state,
        savedFilters,
      };
    }

    case 'CLONE_COMPANIES_FILTER': {
      const savedFilters = [...state.savedFilters];
      savedFilters.push(action.payload);
      return {
        ...state,
        savedFilters,
      };
    }
    case 'GET_COMPANY_BY_ID': {
      return {
        ...state,
        [`currentCompany${action.isSidebar ? 'Sidebar' : ''}`]: action.payload || action.data,
      };
    }
    case 'GET_SUGGESTED_COMPANIES': {
      return {
        ...state,
        suggestedCompanies: action.payload,
      };
    }

    case 'SAVE_COMPANY_FROM_INSIGHTS': {
      const { insightId, payload } = action;
      const { _id } = payload;

      const insights = get(state, 'insights.data', []);
      const newInsights = get(state, 'newInsights.data', []);
      const currentCompanySidebar = { ...get(state, 'currentCompanySidebar', {}) };

      const setSavedStatus = (company) => {
        if (get(company, 'id', get(company, '_id')) === insightId) {
          company.saved = true;
          company.isSaving = false;
          company.savedWorkspaceId = _id;
        }
        return company;
      };

      const dataInsights = insights.map(setSavedStatus);
      const dataNewInsights = newInsights.map(setSavedStatus);
      setSavedStatus(currentCompanySidebar);

      if (get(currentCompanySidebar, 'id', get(currentCompanySidebar, '_id')) === insightId) {
        currentCompanySidebar.history = [{
          _id,
          historyType: HistoryType.ENTITY,
          type: ActivityType.CREATED_ENTITY,
          header: 'Added from search',
          createdAt: new Date().toISOString(),
        }];
      }

      return {
        ...state,
        insights: {
          ...state.insights,
          data: dataInsights,
        },
        newInsights: {
          ...state.newInsights,
          data: dataNewInsights,
        },
        savingCompaniesPreview: initialState.savingCompaniesPreview,
        currentCompanySidebar,
      };
    }
    case 'UPDATE_INSIGHTS_COMPANY_STATUS': {
      const { insightIds, isSaving } = action.data;
      const insights = get(state, 'insights.data', []);
      const newInsights = get(state, 'newInsights.data', []);
      const currentCompanySidebar = get(state, 'currentCompanySidebar', {});

      const setSavedStatus = (company) => {
        if (insightIds.includes(get(company, 'id'))) company.isSaving = isSaving;
        if (insightIds.includes(get(company, '_id'))) company.isSaving = isSaving;
        return company;
      };

      const dataInsights = insights.map(setSavedStatus);
      const dataNewInsights = newInsights.map(setSavedStatus);

      setSavedStatus(currentCompanySidebar);

      return {
        ...state,
        insights: {
          ...state.insights,
          data: dataInsights,
        },
        newInsights: {
          ...state.newInsights,
          data: dataNewInsights,
        },
        savingCompaniesPreview: insightIds,
        currentCompanySidebar,
      };
    }
    case 'BULK_SAVE_CONTACTS_FROM_INSIGHTS': {
      const { insightIds, bulk } = action.data;

      const setSavedStatus = (contactInsight) => {
        if (insightIds.includes(get(contactInsight, 'id'))) {
          contactInsight.saved = true;
          contactInsight.isSaving = false;
          contactInsight.savedWorkspaceId = get(bulk, 'data', []).find((contact) => contact.insightId === contactInsight.id)?._id;
        }
        return contactInsight;
      };

      const { currentCompany } = state;
      if (currentCompany?.employees?.data?.length) {
        const employees = [...currentCompany.employees.data];
        const dataEmployees = employees.map(setSavedStatus);
        currentCompany.employees.data = dataEmployees;
      }

      return {
        ...state,
        currentCompany,
      };
    }
    case 'BULK_SAVE_COMPANIES_FROM_INSIGHTS': {
      const { insightIds, bulk } = action.data;
      const insights = get(state, 'insights.data', []);
      const newInsights = get(state, 'newInsights.data', []);

      const setSavedStatus = (companyInsight) => {
        if (insightIds.includes(get(companyInsight, 'id'))) {
          companyInsight.saved = true;
          companyInsight.isSaving = false;
          companyInsight.savedWorkspaceId = (bulk || []).find((company) => company.insightId === companyInsight.id)?._id;
        }
        return companyInsight;
      };

      const dataInsights = insights.map(setSavedStatus);
      const dataNewInsights = newInsights.map(setSavedStatus);

      return {
        ...state,
        insights: {
          ...state.insights,
          data: dataInsights,
        },
        newInsights: {
          ...state.newInsights,
          data: dataNewInsights,
        },
      };
    }

    case 'SET_SELECTED_INSIGHT_COMPANIES': {
      return {
        ...state,
        selectedInsightCompanies: [...action.data],
      };
    }

    case 'CLEAR_CURRENT_COMPANY': {
      if (get(action, 'data.companyId') === get(state, `currentCompany${action.isSidebar ? 'Sidebar' : ''}._id`)) {
        return {
          ...state,
          [`currentCompany${action.isSidebar ? 'Sidebar' : ''}`]: {},
        };
      }
      return state;
    }

    case 'GET_SUGGESTED_COMPANIES_WITH_NAME': {
      return {
        ...state,
        searchedByName: action.payload,
      };
    }

    case 'GET_SAVED_INSIGHT_COMPETITORS': {
      const field = `currentCompany${action.isSidebar ? 'Sidebar' : ''}`;
      if (isEmpty(state[field])) return state;
      const savedInsights = get(action, 'payload', []);
      return {
        ...state,
        [field]: {
          ...state[field],
          competitors: transformSimilarInsights({ data: state[field].competitors }, savedInsights),
        },
      };
    }

    case 'GET_SAVED_INSIGHT_CONTACTS': {
      const { onlyEnrich } = action;
      if (!onlyEnrich) {
        const savedInsights = get(action, 'payload', []);
        const currentCompanySidebar = { ...get(state, 'currentCompanySidebar', {}) };
        if (currentCompanySidebar.employees?.data) {
          currentCompanySidebar.employees.data = transformSimilarInsights(currentCompanySidebar.employees, savedInsights);
        }
        return {
          ...state,
          currentCompanySidebar,
        };
      }
      return state;
    }

    case 'SELECT_FILTER_COMPANY': {
      return {
        ...state,
        selectedFilter: action.data,
      };
    }

    case 'SET_COMPANIES_INITIAL_REQUEST': {
      const { initialRequest } = action.data;
      return {
        ...state,
        companies: {
          ...state.companies,
          initialRequest,
        },
      };
    }

    case 'SET_INSIGHTS_COMPANIES_INITIAL_REQUEST': {
      const { initialRequest } = action.data;

      return {
        ...state,
        insights: {
          ...state.insights,
          initialRequest,
        },
      };
    }

    case ACCOUNT_EVENTS.ENRICHMENT_COMPANY_EVENT: {
      const payloadData = JSON.parse(action.payload);

      const companiesData = [...state.companies.data];

      if (payloadData?.length) {
        payloadData.forEach((company) => {
          const companyIndex = state.companies.data.findIndex((item) => item._id === company?._id);
          if (companyIndex > -1) {
            companiesData[companyIndex] = company;
          }
        });
      }
      return {
        ...state,
        companies: {
          ...state.companies,
          data: companiesData,
        },
      };
    }
    case 'SAVE_CONTACT_FROM_INSIGHTS': {
      const { insightId, payload } = action;
      const { _id } = payload;

      if (state.currentCompany?.employees?.data) {
        const foundInsightContactIndex = state.currentCompany.employees.data.findIndex((contact) => insightId === contact.id);

        if (foundInsightContactIndex !== -1) {
          const currentCompany = { ...state.currentCompany };
          currentCompany.employees.data[foundInsightContactIndex].isSaving = false;
          currentCompany.employees.data[foundInsightContactIndex].saved = true;
          currentCompany.employees.data[foundInsightContactIndex].savedWorkspaceId = _id;
          currentCompany.employees.data[foundInsightContactIndex].companyRelations = payload.companyRelations;
          return {
            ...state,
            currentCompany,
          };
        }
        return state;
      }
      return state;
    }

    case 'UPDATE_INSIGHTS_CONTACT_STATUS': {
      const { isSaving, insightIds, status = STATUSES.PROCESSING } = action.data;

      if (state.currentCompany?._id && state.currentCompany.employees?.data) {
        const currentCompany = { ...state.currentCompany };
        currentCompany.employees.data = currentCompany.employees.data.map((contact) => {
          if (insightIds.includes(contact.id) || insightIds.includes(contact._id)) {
            return {
              ...contact,
              isSaving,
              companies: (contact.companies || []).map((company) => ({
                ...company,
                email: {
                  status,
                },
              })),
            };
          }
          return contact;
        });
        return {
          ...state,
          currentCompany,
        };
      }
      return state;
    }

    case 'SET_EMAIL_STATUS': {
      const { contactId, companyIndex, status } = action.data;
      const contactIndex = get(state, 'currentCompany.contacts', []).findIndex((contact) => [contact._id, contact.id].includes(contactId));
      if (contactIndex > -1) {
        const { contacts } = state.currentCompany;
        contacts[contactIndex].companies[companyIndex].email = { status };
        return {
          ...state,
          currentCompany: {
            ...state.currentCompany,
            contacts,
          },
        };
      }
      return state;
    }

    case ACCOUNT_EVENTS.CONTACT_EMAIL_STATUS_EVENT: {
      try {
        const payloadData = action.payload ? JSON.parse(action.payload) : [];
        const companyPreviewData = { ...state.currentCompany };

        if (payloadData?.length) {
          if (companyPreviewData.contacts) {
            companyPreviewData.contacts = companyPreviewData.contacts.map((contactInCompany) => {
              const contact = payloadData.find(({ _id }) => _id === contactInCompany._id);
              if (contact) {
                return { ...contactInCompany, companyRelations: contact.companyRelations };
              }
              return contactInCompany;
            });
          }
          if (companyPreviewData.employees?.data) {
            companyPreviewData.employees.data = companyPreviewData.employees.data.map((contactInCompany) => {
              const contactId = contactInCompany.id || contactInCompany._id;
              const contact = payloadData.find(({ insightId }) => contactId === insightId);
              if (contact) {
                return {
                  ...contactInCompany,
                  companyRelations: contactInCompany.companyRelations.map((relationInCompany) => {
                    const insightId = relationInCompany?.company?.id || relationInCompany?.company?._id;
                    const foundCompany = contact.companyRelations.find((relation) => get(relation, 'company.insightId', '') === insightId);
                    if (foundCompany) {
                      return {
                        ...relationInCompany,
                        email: foundCompany.email,
                      };
                    }
                    return relationInCompany;
                  }),
                };
              }
              return contactInCompany;
            });
          }
        }

        return {
          ...state,
          currentCompany: companyPreviewData,
        };
      } catch (err) {
        return state;
      }
    }

    case 'GET_LAST_THREE_CONTACTS': {
      if (isEmpty(state.currentCompanySidebar)) {
        return state;
      }
      const [lastContacts = [], contactsCount = 0] = action?.payload ?? [];
      return {
        ...state,
        currentCompanySidebar: {
          ...state.currentCompanySidebar,
          contactsCount,
          contacts: lastContacts,
        },
      };
    }

    case 'GET_LAST_THREE_INSIGHT_CONTACTS': {
      const { contacts = [], contactsCount } = action.payload;
      return {
        ...state,
        currentCompanySidebar: {
          ...(state.currentCompanySidebar || {}),
          contactsCount,
          contacts,
        },
      };
    }

    case 'UPDATE_CONTACT_COMPANIES_RELATION': {
      const contact = action.payload;

      if (contact && state.currentCompany?.contacts?.length > 0) {
        const currentCompany = { ...state.currentCompany };
        const contactIndex = currentCompany.contacts.findIndex((item) => item._id === contact._id);
        if (contactIndex > -1) {
          if (contact.companyRelations.some((companyRelation) => currentCompany.contacts[contactIndex].companyRelations.some((item) => item._id === companyRelation._id))) {
            currentCompany.contacts[contactIndex].companyRelations = contact.companyRelations;
          } else {
            return state;
          }
        }

        return {
          ...state,
          currentCompany,
        };
      }

      return state;
    }

    case ACCOUNT_EVENTS.NEW_COMPANY_EVENT: {
      const eventCompany = JSON.parse(action.payload);
      const isExist = state.companies.data.find((company) => company._id === eventCompany._id);

      return !isExist ? {
        ...state,
        companies: {
          ...state.companies,
          data: [eventCompany, ...state.companies.data],
        },
      } : { ...state };
    }

    case 'SIGN_OUT':
    case 'SWITCH_WORKSPACE': {
      return initialState;
    }

    case 'FAST_SEARCH_DATABASE_COMPANIES': {
      return {
        ...state,
        fastSearchDatabaseCompanies: action?.payload?.data || [],
      };
    }

    case 'GET_POPULAR_INSIGHTS_COMPANIES': {
      return {
        ...state,
        popularInsightsCompanies: action?.payload,
      };
    }

    case 'GET_COMPANY_HISTORY': {
      const field = `currentCompany${action.isSidebar ? 'Sidebar' : ''}`;
      return {
        ...state,
        [field]: {
          ...state[field],
          history: action.payload,
        },
      };
    }

    case 'GET_INACCURATE_DOMAINS': {
      return {
        ...state,
        inaccurateDomains: action.payload,
      };
    }

    case 'FLAG_INACCURATE_FIELDS': {
      const fields = action.inaccurateFields.filter((item) => item.source === SOURCE_TYPE.COMPANY);
      const { inaccurateDomains = [] } = state;
      const fieldsWithoutDuplicates = [...inaccurateDomains].filter((field) => !fields.some((item) => item.value === field.value));
      if (inaccurateDomains.length === fieldsWithoutDuplicates.length) {
        fieldsWithoutDuplicates.push(...fields);
      }
      return {
        ...state,
        inaccurateDomains: fieldsWithoutDuplicates,
      };
    }

    case 'GET_COMPANY_COMPETITORS': {
      const field = `currentCompany${action.isSidebar ? 'Sidebar' : ''}`;
      return {
        ...state,
        [field]: {
          ...state[field],
          competitors: action.payload,
        },
      };
    }

    case 'GET_COMPANY_CONTACT_COUNTRIES': {
      if (!action.payload) return state;
      const field = `currentCompany${action.isSidebar ? 'Sidebar' : ''}`;
      return {
        ...state,
        [field]: {
          ...state[field],
          contactCountries: action.payload,
        },
      };
    }

    case 'GET_COMPANY_CONTACT_POSITIONS': {
      const field = `currentCompany${action.isSidebar ? 'Sidebar' : ''}`;
      return {
        ...state,
        [field]: {
          ...state[field],
          contactPositions: action.payload,
        },
      };
    }

    case 'GET_COMPANY_CONTACT_DEPARTMENTS': {
      const field = `currentCompany${action.isSidebar ? 'Sidebar' : ''}`;
      return {
        ...state,
        [field]: {
          ...state[field],
          contactDepartments: action.payload,
        },
      };
    }

    case 'GET_CURRENT_COMPANY_TECHNOLOGIES': {
      const field = `currentCompany${action.isSidebar ? 'Sidebar' : ''}`;
      return {
        ...state,
        [field]: {
          ...state[field],
          technologies: action.payload,
        },
      };
    }

    case 'SEARCH_COMPANY_EMPLOYEES': {
      const field = `currentCompany${action.isSidebar ? 'Sidebar' : ''}`;
      return {
        ...state,
        [field]: {
          ...state[field],
          [action.saved ? 'savedContacts' : 'employees']: {
            ...action.payload,
            data: action.saved ? action.payload.data.map((item) => ({ ...item, saved: true })) : action.payload.data.map(convertInsightToContact),
            initialRequest: true,
          },
        },
      };
    }

    case 'SEARCH_PHONE_NUMBER': {
      const field = `currentCompany${action.isSidebar ? 'Sidebar' : ''}`;
      const { contactId, insightId, phone } = action.payload;

      if (!state[field]?.employees?.data?.length) return state;

      return {
        ...state,
        [field]: {
          ...state[field],
          employees: {
            ...state[field].employees,
            data: state[field].employees.data.map((employee) => {
              if ([employee._id, employee.id].includes(insightId) || [employee._id, employee.id].includes(contactId)) {
                return {
                  ...employee,
                  properties: {
                    ...employee.properties,
                    phone: {
                      value: phone,
                    },
                  },
                };
              }
              return employee;
            }),
          },
        },
      };
    }

    case 'GET_COMPANY_KEYWORDS': {
      return {
        ...state,
        keywords: action.payload,
      };
    }

    case 'SEARCH_COMPANY_TECHNOLOGIES': {
      return {
        ...state,
        technologies: action.payload,
      };
    }

    case 'ADD_POPULATED_TECHNOLOGY': {
      const field = `currentCompany${action.isSidebar ? 'Sidebar' : ''}`;
      return {
        ...state,
        [field]: {
          ...state[field],
          technologies: [...state[field].technologies, action.data.technology],
        },
      };
    }

    case 'GET_SAVED_COMPANY_INSIGHTS': {
      if (isEmpty(state.currentCompany)) return state;
      const savedInsights = get(action, 'payload', []);
      const field = `currentCompany${action.isSidebar ? 'Sidebar' : ''}`;
      return {
        ...state,
        [field]: {
          ...(state[field] || {}),
          contacts: transformSimilarInsights({ data: state[field].contacts }, savedInsights),
        },
      };
    }

    case 'GET_INSIGHT_BY_ID': {
      if (action.sourceType !== SOURCE_TYPE.COMPANY) return state;
      return {
        ...state,
        currentCompanySidebar: convertInsightToCompany(action.payload),
      };
    }

    default:
      return state;
  }
};
